import { ADMIN_KEY } from "../../actionTypes";

const storeAdminsKey = (data: boolean) => {
    return {
        type: ADMIN_KEY,
        payload: data,
    };
};

/**
 * Common File redux
 * @param code
 */
export function storeAdminKey<T>(data: boolean) {
    return (dispatch: any) => {
        dispatch(storeAdminsKey(data));
    };
}
