const UploadFileQuery=`
mutation($files: Upload!, $relativePath: String!,$ou:Int,$langId:Int,$role:String) {
  UploadFile(file: $files, relativePath: $relativePath,ou:$ou,langId:$langId,role:$role) {
      
      
        filename
        fileId
        mimetype
        encoding
      
      
  }
}
`;
export default UploadFileQuery;