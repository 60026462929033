import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

export default function OWCircularProgress(props: any) {
    return (
        <CircularProgressbar
            value={props.value}
            text={`${props.value}%`}
            strokeWidth={19}
            styles={{
                // Customize the root svg element
                root: {
                    maxWidth: "120px",
                },
                // Customize the path, i.e. the "completed progress"
                path: {
                    // Path color
                    stroke: `#00AE50`,
                    // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                    strokeLinecap: "butt",
                    // Customize transition animation
                    transition: "stroke-dashoffset 0.5s ease 0s",
                    // Rotate the path
                    //   transform: 'rotate(0.25turn)',
                    transformOrigin: "center center",
                    width: "50px",
                    height: "50px",
                },
                // Customize the circle behind the path, i.e. the "total progress"
                trail: {
                    width: "50px",
                    // Trail color
                    stroke: "#d6d6d6",
                    // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                    strokeLinecap: "butt",
                    // Rotate the trail
                    transform: "rotate(0.25turn)",
                    transformOrigin: "center center",
                },
                // Customize the text
                text: {
                    // Text color
                    fill: "#00AE50",
                    // Text size
                    fontSize: "14px",
                    fontWeight: 700,
                },
                // Customize background - only used when the `background` prop is true
                background: {
                    fill: "#3e98c7",
                },
            }}
        />
    );
}
