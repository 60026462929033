export const VendorRatingLoanIndex = `

query Query ($companyID : String){
    VendorRatingLoanIndex (companyID:$companyID){
        id
        vendorRating
        loanIndex
    }
}`;

export default VendorRatingLoanIndex;
