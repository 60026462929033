import React from "react";
import MainRoutes from "../Routes/MainRoutes";

const ChildComponent = () => {
  return (
      <MainRoutes />
  );
};

export default ChildComponent;
