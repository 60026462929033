const CurrentAssignmentStatusComboQuery = `
    mutation(
        $ou: Int
        $service: String
        $User: String
        $languageId: Int
        $callingScreen: String
        $trantype: String
        $merrorid: String
    ){
        CurrentAssignmentStatusCombo(
            ou: $ou
            service: $service
            User: $User
            languageId: $languageId
            callingScreen: $callingScreen
            trantype: $trantype
            merrorid: $merrorid
        )
        {
            desc
            value
        }
    }
`;

export default CurrentAssignmentStatusComboQuery;
