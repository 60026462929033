import {
    Grid,
    Paper,
    Backdrop,
    CircularProgress,
    InputBase,
} from "@material-ui/core";
import React, { Fragment, useEffect, useState } from "react";
import MUIButton from "Common/Button/Button";
import { connect } from "react-redux";
import useGetPosts from "ReactQuery/reactQuery";
import MuiDatatables from "Common/Mui-DataTables/MuiDatatables";
import { Redirect } from "react-router-dom";
import Dropdown from "Common/Dropdown/Dropdown";
import TextFields from "Common/TextField/HiddenTextField";
import CustomDatePickerBorder from "Common/DatePicker/DatePicker";
import Icons from "Common/Icons/Icons";
import { storePortalKey } from "../../stateManagement/actions/Common/portalKey";
import { storeUserReadOnlyAccess } from "../../stateManagement/actions/Common/readOnlyAccess";
import { storeSelectedCustomer } from "stateManagement/actions/Common/customerDetails";
import { storeSelectedVendor } from "stateManagement/actions/Common/vendorDetails";

import {
    CompanyDetailsTableDataCusQuery,
    CompanyUsersVenQuery,
    contextApiQuery,
    CustomerSearchQuery,
    VendorSearchQuery,
} from "ReactQuery/Query";
import Alert from "Common/Alerts/Alerts";
import { makeStyles, Theme } from "@material-ui/core/styles";
import currencyFormatter from "Common/Utils/currencyFormatter";
// import NoContractSign from "./Images/NoContractSign.svg";
import AdminPic from "./Images/AdminPic.svg";
import { storeBreadcrumbs } from "stateManagement/actions/Common/breadcrumbs";
import HiddenDropdown from "Common/Dropdown/HiddenDropdown";
import HiddenTextField from "Common/TextField/HiddenTextField";
import ContactCard from "./ContactCard";
import { Combo } from "Common/Listedit/HiddenListEdit";
import { storeContextInfo } from "stateManagement/actions/HomePage/storeContext";
import { storeDefaultContext } from "stateManagement/actions/SwitchContext/switchContext";
import { v4 as uuidv4 } from "uuid";
import SearchUsers from "./Images/SearchUsers.svg";

const controlList = [
    {
        desc: "Customer",
        value: "Customer",
    },
    {
        desc: "Vendor",
        value: "Vendor",
    },
];
const useStyles = makeStyles((theme: Theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: "#fff",
    },
}));
interface BodyProps {
    currentContext: any;
    userName: any;
    OriginalUsername: any;
    selectedVendor: any;
    currency: any;
    setBreadcrumbsData: any;
    selectedCustomer: any;
    storePortalKey: any;
    storeUserReadOnlyAccess: any;
    setSelectedVendor: any;
    setSelectedCustomer: any;
    adminKey: any;
    setContextInfo: any;
    setDefaultContext: any;
}

const Body = (props: BodyProps) => {
    const {
        currentContext,
        userName,
        OriginalUsername,
        selectedVendor,
        currency,
        selectedCustomer,
        adminKey,
        setBreadcrumbsData,
        storePortalKey,
        storeUserReadOnlyAccess,
        setSelectedVendor,
        setSelectedCustomer,
        setContextInfo,
        setDefaultContext,
    } = props;

    const {
        data: contData,
        error: contfError,
        isLoading: loading,
        isFetching: fetching,
    } = useGetPosts({
        name: `getcontextApiQuery_one`,
        query: contextApiQuery,
        variables: {},
        retry: true,
    });

    useEffect(() => {
        if (contData) {
            setContextInfo(contData?.contexts);
            setDefaultContext(contData?.contexts?.userDefaults);
        }
    }, [contData]);

    const classes = useStyles();

    const [contractDet, setContractDet] = useState<boolean>(false);

    const [companyUsersDataVen, setCompanyUsersDataVen] = useState([]);
    const [contractSelected, setcontractSelected] = useState();

    const [activeList, setActiveList] = useState(controlList[0].desc);

    // must be removed after asking
    const [Customerdetails, setCustomerdetails] = useState({
        customerName: "",
        customerPhone: "",
        customerMail: "",
        customerCode: "",
    });
    const [vendordetails, setvendordetails] = useState({
        vendorName: "",
        vendorCode: "",
        vendorPhone: "",
        vendorMail: "",
    });

    const handleChangeActive = (e: React.ChangeEvent<{ value: unknown }>) => {
        const name = (e?.target as any).name;
        const value = (e?.target as any).value;

        setActiveList(value);
        if (value === "Customer") {
            setCurrentValues((prevState: any) => ({
                ...prevState,
                custCode: CustomerSearchData?.customerSearch?.detail.reduce(
                    (accumulator: string, element: any) => {
                        if (element[0]?.code === companySelected.value) {
                            accumulator = element[0]?.code;
                        }
                        return accumulator;
                    },
                    CustomerSearchData?.customerSearch?.detail[0][0]?.code,
                ),
                custValue: CustomerSearchData?.customerSearch?.detail.reduce(
                    (accumulator: string, element: any) => {
                        if (element[0]?.code === companySelected.value) {
                            accumulator = element[1]?.desc;
                        }
                        return accumulator;
                    },
                    CustomerSearchData?.customerSearch?.detail[0][0]?.desc,
                ),
            }));
        } else {
            setCurrentValues((prevState: any) => ({
                ...prevState,
                venCode: VendorSearchData?.vendorSearch?.detail.reduce(
                    (accumulator: string, element: any) => {
                        if (element[0]?.code === companySelected.value) {
                            accumulator = element[0]?.code;
                        }
                        return accumulator;
                    },
                    VendorSearchData?.vendorSearch?.detail[0][0]?.code,
                ),
                venValue: VendorSearchData?.vendorSearch?.detail.reduce(
                    (accumulator: string, element: any) => {
                        if (element[0]?.code === companySelected.value) {
                            accumulator = element[1]?.desc;
                        }
                        return accumulator;
                    },
                    VendorSearchData?.vendorSearch?.detail[0][0]?.desc,
                ),
            }));
        }
    };

    const [VenConstData, setVenConstData] = useState([]);
    const [CustConstData, setCustConstData] = useState<any>([]);

    const handleNameSearch = (search: string) => {
        console.log("search", search);
        setCustomerdetails((prevState) => ({
            ...prevState,
            customerName: search,
        }));
        if (activeList === "Customer") {
            setCompanyDetailsTableData(
                (CustConstData as any).filter(
                    (element: any) =>
                        element.Name.toLowerCase().includes(
                            search.toLowerCase(),
                        ) || search === "",
                ),
            );
        } else if (activeList === "Vendor") {
            setCompanyUsersDataVen(
                (VenConstData as any).filter(
                    (element: any) =>
                        element.Name.toLowerCase().includes(
                            search.toLowerCase(),
                        ) || search === "",
                ),
            );
        }
    };

    const handleSearch = () => {
        if (activeList === "Customer") {
            setSearchCustSkip((prev) => !prev);
        } else {
            setSearchVenSkip((prev) => !prev);
        }
        setApplyValues(currentValues);
        // setCompanyDetailsTableData([]);
        // setCompanyUsersDataVen([]);
    };

    const handleListEdit = (key: string, value: string) => {
        let keyName = key;
        let data = {};
        if (key === "cust") {
            keyName = "custCode";
            data = {
                custValue: custList?.detail?.reduce(
                    (accumulator: string, element: any) => {
                        if (element[0]?.code === value) {
                            accumulator = element[0]?.desc;
                        }
                        return accumulator;
                    },
                    "",
                ),
            };
        }
        if (key === "ven") {
            keyName = "venCode";
            data = {
                venValue: venList?.detail?.reduce(
                    (accumulator: string, element: any) => {
                        if (element[0]?.code === value) {
                            accumulator = element[0]?.desc;
                        }
                        return accumulator;
                    },
                    "",
                ),
            };
        }
        setCurrentValues((prevState: any) => ({
            ...prevState,
            ...data,
            [keyName]: value,
        }));
    };

    const [alert, setAlert] = useState(false);
    const [errr, seterr] = useState({
        code: 0,
        msg: "",
    });

    const handleErrorAlert = (code: number, message: string) => {
        Alert({
            statusCode: code,
            message: message,
            willClose: () => {
                setAlert(false);
                seterr({ code: 0, msg: "" });
            },
        });
    };

    const [companySelected, setSelectedCompany] = useState<any>(
        // selectedCompany?.value
        //     ? selectedCompany
        //     :
        {
            desc: "",
            value: "",
        },
    );

    const [custList, setcustList] = useState<any>({
        header: [],
        detail: [],
    });

    const [venList, setvenList] = useState<any>({
        header: [],
        detail: [],
    });

    // need to set inital value later
    const [initial, setInitial] = useState(true);
    const [clientCompOuFetchType, setClientCompOuFetchType] = useState("ufe");

    const [headerValues, setHeaderValues] = useState<any>({
        custCode: [],
        venCode: [],
    });

    const [currentValues, setCurrentValues] = useState<any>({
        custCode: "",
        venCode: "",
        custValue: "",
        venValue: "",
        orderType: "",
    });

    // need to use
    const [applyValues, setApplyValues] = useState<any>({
        custCode: "",
        venCode: "",
        custValue: "",
        venValue: "",
        orderType: "",
    });

    const {
        data: CustomerSearchData,
        error: CustomerSearchDataError,
        isLoading: CustomerSearchDataLoading,
    } = useGetPosts({
        name: `customersearch`,
        query: CustomerSearchQuery,
        variables: {
            entity: "OW_Cust_portal_cust_login",
            ou: currentContext?.ouId,
            service: OriginalUsername,
            User: userName, //"ramcouser",
            languageId: currentContext?.langId,
            customerName: "", //Customerdetails?.customerName,
            customerCode: "", // Customerdetails?.customerCode,
            mobileNumber: "", //Customerdetails?.customerPhone,
            email: "", //Customerdetails?.customerMail,
            merrorid: "0",
        },
    });

    useEffect(() => {
        if (CustomerSearchData?.customerSearch) {
            // setCustomer(CustomerSearchData?.customerSearch);
            setcustList(CustomerSearchData?.customerSearch);

            setHeaderValues((prevState: any) => ({
                ...prevState,
                custCode: CustomerSearchData?.customerSearch?.detail.reduce(
                    (accumulator: string, element: any) => {
                        if (element[0]?.code === companySelected.value) {
                            accumulator = element;
                        }
                        return accumulator;
                    },
                    CustomerSearchData?.customerSearch?.detail[0],
                ),
            }));

            setCurrentValues((prevState: any) => ({
                ...prevState,
                custCode: CustomerSearchData?.customerSearch?.detail.reduce(
                    (accumulator: string, element: any) => {
                        if (element[0]?.code === companySelected.value) {
                            accumulator = element[0]?.code;
                        }
                        return accumulator;
                    },
                    CustomerSearchData?.customerSearch?.detail[0][0]?.code,
                ),
                custValue: CustomerSearchData?.customerSearch?.detail.reduce(
                    (accumulator: string, element: any) => {
                        if (element[0]?.code === companySelected.value) {
                            accumulator = element[1]?.desc;
                        }
                        return accumulator;
                    },
                    CustomerSearchData?.customerSearch?.detail[0][0]?.desc,
                ),
            }));
            if (initial) {
                setApplyValues((prevState: any) => ({
                    ...prevState,
                    custCode: CustomerSearchData?.customerSearch?.detail.reduce(
                        (accumulator: string, element: any) => {
                            if (element[0]?.code === companySelected.value) {
                                accumulator = element[0]?.code;
                            }
                            return accumulator;
                        },
                        CustomerSearchData?.customerSearch?.detail[0][0]?.code,
                    ),
                    custValue:
                        CustomerSearchData?.customerSearch?.detail.reduce(
                            (accumulator: string, element: any) => {
                                if (
                                    element[0]?.code === companySelected.value
                                ) {
                                    accumulator = element[1]?.desc;
                                }
                                return accumulator;
                            },
                            CustomerSearchData?.customerSearch?.detail[0][0]
                                ?.desc,
                        ),
                }));
            }

            // setCustomerSearch(true);
        }
    }, [CustomerSearchData]);

    useEffect(() => {
        if (CustomerSearchDataError && !CustomerSearchDataLoading) {
            const data = JSON.parse(JSON.stringify(CustomerSearchDataError));
            const errmsg = data?.response?.errors[0].message;
            seterr({
                code: 400,
                msg: errmsg,
            });
            // setAlert(true);
        }
    }, [CustomerSearchDataError, CustomerSearchDataLoading]);

    const {
        data: VendorSearchData,
        error: VendorSearchDataError,
        isLoading: VendorSearchDataLoading,
    } = useGetPosts({
        name: `vendorsearch`,
        query: VendorSearchQuery,
        variables: {
            entity: `OW_vendor_portal_vendorlogin`,
            ou: currentContext?.ouId,
            service: "",
            User: userName,
            languageId: currentContext?.langId,
            vendorName: "", // vendordetails?.vendorName,
            vendorCode: "", // vendordetails?.vendorCode,
            mobileNumber: "", // vendordetails?.vendorPhone,
            email: "", //vendordetails?.vendorMail,
            merrorid: "0",
        },
        retry:true
        // skip:
        //     vendorSearch ||
        //     !Boolean(userName) ||
        //     !Boolean(currentContext?.ouId),
    });

    useEffect(() => {
        if (VendorSearchData?.vendorSearch) {
            // setVendor(VendorSearchData?.vendorSearch);
            // setvendorSearch(true);

            setvenList(VendorSearchData?.vendorSearch);

            setHeaderValues((prevState: any) => ({
                ...prevState,
                venCode: VendorSearchData?.vendorSearch?.detail.reduce(
                    (accumulator: string, element: any) => {
                        if (element[0]?.code === companySelected.value) {
                            accumulator = element;
                        }
                        return accumulator;
                    },
                    VendorSearchData?.vendorSearch?.detail[0],
                ),
            }));

            setCurrentValues((prevState: any) => ({
                ...prevState,
                venCode: VendorSearchData?.vendorSearch?.detail.reduce(
                    (accumulator: string, element: any) => {
                        if (element[0]?.code === companySelected.value) {
                            accumulator = element[0]?.code;
                        }
                        return accumulator;
                    },
                    VendorSearchData?.vendorSearch?.detail[0][0]?.code,
                ),
                venValue: VendorSearchData?.vendorSearch?.detail.reduce(
                    (accumulator: string, element: any) => {
                        if (element[0]?.code === companySelected.value) {
                            accumulator = element[1]?.desc;
                        }
                        return accumulator;
                    },
                    VendorSearchData?.vendorSearch?.detail[0][0]?.desc,
                ),
            }));
            if (initial) {
                setApplyValues((prevState: any) => ({
                    ...prevState,
                    venCode: VendorSearchData?.vendorSearch?.detail.reduce(
                        (accumulator: string, element: any) => {
                            if (element[0]?.code === companySelected.value) {
                                accumulator = element[0]?.code;
                            }
                            return accumulator;
                        },
                        VendorSearchData?.vendorSearch?.detail[0][0]?.code,
                    ),
                    venValue: VendorSearchData?.vendorSearch?.detail.reduce(
                        (accumulator: string, element: any) => {
                            if (element[0]?.code === companySelected.value) {
                                accumulator = element[1]?.desc;
                            }
                            return accumulator;
                        },
                        VendorSearchData?.vendorSearch?.detail[0][0]?.desc,
                    ),
                }));
            }
        }
    }, [VendorSearchData]);

    useEffect(() => {
        if (VendorSearchDataError && !VendorSearchDataLoading) {
            const data = JSON.parse(JSON.stringify(VendorSearchDataError));
            const errmsg = data?.response?.errors[0].message;
            seterr({
                code: 400,
                msg: errmsg,
            });
            // setAlert(true);
        }
    }, [VendorSearchDataError, VendorSearchDataLoading]);

    const [companyDetailsTableData, setCompanyDetailsTableData] = useState<any>(
        [],
    );
    const [searchCustSkip, setSearchCustSkip] = useState<boolean>(true);
    const [searchVenSkip, setSearchVenSkip] = useState<boolean>(true);

    const {
        data: CompanyDetailsTableData,
        error: CompanyDetailsTableDataError,
        isLoading: CompanyDetailsTableDataLoading,
    } = useGetPosts({
        name: `CompanyDetailsTableDataCusQuery_${currentValues.custCode}`,
        query: CompanyDetailsTableDataCusQuery,
        variables: {
            entity: "OW_Cust_portal_getcompprfluser",
            ou: currentContext?.ouId,
            service: OriginalUsername,
            user: userName,
            langId: currentContext?.langId,
            customerCode: currentValues.custCode, //selectedCustomer?.customerCode,
            customerName: "",
            merrorid: "",
        },
        skip: searchCustSkip,
    });

    useEffect(() => {
        console.log(
            "CompanyTableDataCus",
            CompanyDetailsTableData?.CompanyTableDataCus,
        );
        // const data = [
        //     {
        //         Name: "Dorothy Golden",
        //         Email: "dgolden@wm.com",
        //         UserName: "C1001570-C101",
        //         Designation: "",
        //         isActive: "Yes",
        //         phone: "(602) 390-0024",
        //         MakePayment: "Yes",
        //         OrderSupplies: "Yes",
        //         Admin: "Yes",
        //         Image: "",
        //     },
        //     {
        //         Name: "OpenworksTestUser",
        //         Email: "testuser@openworksweb.com",
        //         UserName: "C1001570-C2",
        //         Designation: "",
        //         isActive: "Yes",
        //         phone: "(602) 390-0024",
        //         MakePayment: "Yes",
        //         OrderSupplies: "Yes",
        //         Admin: "Yes",
        //         Image: "",
        //     },
        // ];
        if (CompanyDetailsTableData?.CompanyTableDataCus ) {
            setCompanyDetailsTableData(
               CompanyDetailsTableData?.CompanyTableDataCus,
            );

            setCustConstData(
               CompanyDetailsTableData?.CompanyTableDataCus,
            );
            setSearchCustSkip((prev) => !prev);
            // setDisabled(true);
            if (
                CompanyDetailsTableData?.CompanyTableDataCus?.[0]
                    .ReadOnlyAccess === "disable"
            ) {
                // if(data[0].ReadOnlyAccess ==="disable"){
                storeUserReadOnlyAccess(false);
            } else {
                storeUserReadOnlyAccess(true);
            }
        }
    }, [CompanyDetailsTableData]);

    console.log("companyDetailsTableData", companyDetailsTableData);

    useEffect(() => {
        if (CompanyDetailsTableDataError && !CompanyDetailsTableDataLoading) {
            const data = JSON.parse(
                JSON.stringify(CompanyDetailsTableDataError),
            );
            const errmsg = data?.response?.errors[0].message;
            seterr({
                code: 400,
                msg: errmsg,
            });
            setAlert(true);
            setSearchCustSkip((prev) => !prev);
            // storeUserReadOnlyAccess(false)
        }
    }, [CompanyDetailsTableDataError, CompanyDetailsTableDataLoading]);

    const {
        data: CompanyUsersVenData,
        error: CompanyUsersVenDataError,
        isLoading: CompanyUsersVenDataLoading,
    } = useGetPosts({
        name: `CompanyUsersVenData_Query_${currentValues.venCode}`,
        query: CompanyUsersVenQuery,
        variables: {
            entity: "OW_Vend_portal_cmpprfuser",
            ou: currentContext?.ouId,
            service: OriginalUsername,
            vendorCode: currentValues.venCode, //selectedVendor?.supplierCode,
            languageId: currentContext?.langId,
            User: userName ?? selectedVendor?.loginUser,
            vendorName: "", //selectedVendor?.supplierName,
            merrorid: "",
        },
        skip: searchVenSkip,
    });

    useEffect(() => {
        if (CompanyUsersVenData?.CompanyUsersVen) {
            setCompanyUsersDataVen(CompanyUsersVenData?.CompanyUsersVen);
            setVenConstData(CompanyUsersVenData?.CompanyUsersVen);
            setSearchVenSkip((prev) => !prev);
            if (
                CompanyUsersVenData?.CompanyUsersVen?.[0].ReadOnlyAccess ===
                "disable"
            ) {
                storeUserReadOnlyAccess(false);
            } else {
                storeUserReadOnlyAccess(true);
            }

            // storeUserReadOnlyAccess()
        }
    }, [CompanyUsersVenData]);

    useEffect(() => {
        if (CompanyUsersVenDataError && !CompanyUsersVenDataLoading) {
            const data = JSON.parse(JSON.stringify(CompanyUsersVenDataError));
            const errmsg = data?.response?.errors[0].message;
            seterr({
                code: 400,
                msg: errmsg,
            });
            setAlert(true);
            setSearchVenSkip((prev) => !prev);
        }
    }, [CompanyUsersVenDataError, CompanyUsersVenDataLoading]);

    useEffect(() => {
        console.log("currentValues", currentValues);

        // console.log("curre",{
        // userNameEntered: userNameEntered,
        // customerCode: currentValues.custCode,
        // customerName:  (custList?.detail?.find((el:any)=>{
        //     console.log("el",el[1].code===currentValues.code)
        //     if(el[1].code===currentValues.custCode){
        //         return el
        //     }
        // }))?.[1]?.desc
        // ,
        // vendorCode: currentValues.venCode,
        // vendorName: (  venList?.detail?.find((el:any)=>{
        //     if(el[1].code===currentValues.venCode){
        //         return el
        //     }
        // }))?.[1]?.desc
        // ,

        // },)
    }, [currentValues]);

    // need to redirect later
    const [userNameEntered, setUserNameEntered] = useState<any>({
        searchValue: "",
        Username: "",
    });
    const handlePortalChange = (val: any, Username: any) => {
        console.log("activeList", activeList);

        if (activeList === "Customer") {
            storePortalKey(false);

            // setSelectedCustomer(CustomerData?.CustomerData[0]);
        } else {
            storePortalKey(true);
            // setSelectedVendor(VendorData?.VendorData[0]);
        }
        console.log("Inside Usernames", val, Username);
        setUserNameEntered({
            searchValue: val,
            Username: Username,
        });
        setDashboardRedirect(true);
    };

    const [dashboardRedirect, setDashboardRedirect] = useState(false);

    if (dashboardRedirect) {
        return (
            <Redirect
                to={{
                    pathname: "/app/*/Dashboard",
                    state: {
                        userNameEntered: userNameEntered,
                        customerCode: currentValues.custCode,
                        customerName: custList?.detail?.find((el: any) => {
                            console.log(
                                "el",
                                el[1].code === currentValues.code,
                            );
                            if (el[1].code === currentValues.custCode) {
                                return el;
                            }
                        })?.[1]?.desc,
                        vendorCode: currentValues.venCode,
                        vendorName: venList?.detail?.find((el: any) => {
                            if (el[1].code === currentValues.venCode) {
                                return el;
                            }
                        })?.[1]?.desc,
                    },
                }}
            />
        );
    }

    if (contractDet) {
        return (
            <Redirect
                to={{
                    pathname: "/app/*/CONTDETSV",
                    state: {
                        contractSelected: contractSelected,
                    },
                }}
            />
        );
    }

    if (!adminKey) {
        // debugger;
        return <Redirect to="/app/*/Dashboard" />;
    }

    return (
        <>
            {alert ? handleErrorAlert(errr.code, errr.msg) : <></>}
            <Backdrop
                className={classes.backdrop}
                open={
                    CompanyUsersVenDataLoading ||
                    CompanyDetailsTableDataLoading ||
                    CustomerSearchDataLoading ||
                    VendorSearchDataLoading ||
                    loading
                }
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <Grid container spacing={2} alignItems="center">
                <Grid item xs={12} style={{ padding: "2%" }}>
                    <Paper elevation={4}>
                        <Grid
                            container
                            justifyContent="flex-start"
                            // alignItems="center"
                        >
                            <Grid
                                item
                                xs={2}
                                md={2}
                                lg={2}
                                style={{
                                    padding: "3%",
                                    background:
                                        "linear-gradient(180deg, #DFEFFF 0%, #D0E9FF 100%)",
                                }}
                            >
                                <img
                                    src={AdminPic}
                                    alt="admin pic"
                                    style={{
                                        textAlign: "center",
                                    }}
                                />
                            </Grid>
                            <Grid
                                item
                                xs={2}
                                style={{
                                    borderRight: "1px dashed #D5D5D5",
                                    padding: "6vh 2% 0% 2%",
                                }}
                            >
                                <HiddenDropdown
                                    label={"Type"}
                                    id={"type"}
                                    value={activeList}
                                    list={controlList}
                                    handleChange={handleChangeActive}
                                    width={"100%"}
                                />
                            </Grid>
                            <Grid
                                item
                                xs={3}
                                style={{
                                    borderRight: "1px dashed #D5D5D5",
                                    padding: "4.5vh 2% 0% 2%",
                                }}
                            >
                                {activeList === "Vendor" ? (
                                    <Combo
                                        label="Code - Name"
                                        header={venList?.header}
                                        detail={venList?.detail}
                                        handleInput={handleListEdit}
                                        width={"100%"}
                                        value={headerValues.venCode}
                                        minWidth={"400px"}
                                        fetchType={[
                                            clientCompOuFetchType,
                                            setClientCompOuFetchType,
                                        ]}
                                        loading={VendorSearchDataLoading}
                                    />
                                ) : (
                                    <Combo
                                        label="Code - Name"
                                        header={custList?.header}
                                        detail={custList?.detail}
                                        handleInput={handleListEdit}
                                        width={"100%"}
                                        value={headerValues.custCode}
                                        minWidth={"400px"}
                                        fetchType={[
                                            clientCompOuFetchType,
                                            setClientCompOuFetchType,
                                        ]}
                                        loading={CustomerSearchDataLoading}
                                    />
                                )}
                            </Grid>
                            <Grid
                                item
                                xs={3}
                                style={{
                                    borderRight: "1px dashed #D5D5D5",
                                    padding: "4vh 2% 0%",
                                }}
                            >
                                <Grid container spacing={2}>
                                    <Grid
                                        item
                                        xs={12}
                                        className="text-left font-boldest grey"
                                    >
                                        Contact Name
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        style={{
                                            border: "1px solid lightgrey",
                                            textAlign: "left",
                                            padding: "3%",
                                            borderRadius: "20px",
                                           
                                        }}
                                    >
                                        <TextFields
                                            placeHolder="Enter Contact Name..."
                                            label={""}
                                            id={"customerName"}
                                            value={
                                                Customerdetails?.customerName
                                            }
                                            handleChange={(event: any) => {
                                                handleNameSearch(
                                                    event.target.value,
                                                );
                                            }}
                                            width={"100%"}
                                            multiline={false}
                                            rows={0}
                                        />
                                    </Grid>
                                </Grid>

                            </Grid>
                                <Grid
                                    item
                                    xs={2}
                                    style={{
                                        padding: "6vh 2% 0% 2%",
                                        paddingLeft: "4%",
                                    }}
                                >
                                    <MUIButton
                                        label="Search"
                                        type="filled"
                                        font="font-small"
                                        onClick={handleSearch}
                                    />
                                </Grid>
                        </Grid>
                    </Paper>
                </Grid>
                {companyUsersDataVen?.length == 0 &&
                companyDetailsTableData?.length == 0 ? (
                    <Grid item xs={12} style={{ padding: "4%" }}>
                        <img src={SearchUsers} style={{ width: "75vh" }} />
                    </Grid>
                ) : (
                    <Fragment>
                        {activeList === "Vendor" && (
                            <Grid
                                item
                                xs={12}
                                style={{ marginRight: "2%", paddingTop: "2%" }}
                            >
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <Grid
                                            container
                                            spacing={2}
                                            justifyContent="flex-start"
                                        >
                                            <Grid
                                                item
                                                className="grey1 font-boldest font-big "
                                            >
                                                Search Results
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Grid container spacing={2}>
                                            {companyUsersDataVen?.length ? (
                                                companyUsersDataVen.map(
                                                    (el: any) => {
                                                        return (
                                                            <Grid item xs={4}>
                                                                <ContactCard
                                                                    name={
                                                                        el.Name
                                                                    }
                                                                    role={
                                                                        el.role
                                                                    }
                                                                    //no role check
                                                                    mail={
                                                                        el.Email
                                                                    }
                                                                    phoneNo={
                                                                        el.Businessph
                                                                    }
                                                                    Username={
                                                                        el.Username
                                                                    }
                                                                    handlePortalChange={
                                                                        handlePortalChange
                                                                    }
                                                                />
                                                            </Grid>
                                                        );
                                                    },
                                                )
                                            ) : (
                                                //have to update with image for no value
                                                <></>
                                                // <img
                                                //     src={NoContractSign}
                                                //     style={{ paddingTop: "4%" }}
                                                // />
                                            )}
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        )}
                        {activeList === "Customer" && (
                            <Grid
                                item
                                xs={12}
                                style={{ marginRight: "2%", paddingTop: "2%" }}
                            >
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <Grid
                                            container
                                            spacing={2}
                                            justifyContent="flex-start"
                                        >
                                            <Grid
                                                item
                                                className="grey1 font-boldest font-big "
                                            >
                                                Search Results
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Grid container spacing={2}>
                                            {companyDetailsTableData?.length ? (
                                                companyDetailsTableData.map(
                                                    (el: any) => {
                                                        return (
                                                            <Grid item xs={4}>
                                                                <ContactCard
                                                                    name={
                                                                        el.Name
                                                                    }
                                                                    role={
                                                                        el.Designation
                                                                    }
                                                                    mail={
                                                                        el.Email
                                                                    }
                                                                    phoneNo={
                                                                        el.phone
                                                                    }
                                                                    handlePortalChange={
                                                                        handlePortalChange
                                                                    }
                                                                    Username={
                                                                        el.UserName
                                                                    }
                                                                />
                                                            </Grid>
                                                        );
                                                    },
                                                )
                                            ) : (
                                                <></>
                                                //have to update with image for no value

                                                // <img
                                                //     src={NoContractSign}
                                                //     style={{ paddingTop: "4%" }}
                                                // />
                                            )}
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        )}
                    </Fragment>
                )}
            </Grid>
        </>
    );
};

const mapStateToProps = (state: any) => {
    return {
        currentContext: state.context?.userDefaults,
        userName: state.context?.name,
        selectedVendor: state.selectedVendor,
        currency: state.currency,
        selectedCustomer: state.selectedCustomer,
        adminKey: state.adminKey,
        OriginalUsername: state.context?.fullName,
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        setBreadcrumbsData: (data: any) => {
            dispatch(storeBreadcrumbs(data));
        },
        storePortalKey: (data: boolean) => {
            dispatch(storePortalKey(data));
        },
        storeUserReadOnlyAccess: (data: boolean) => {
            dispatch(storeUserReadOnlyAccess(data));
        },

        setSelectedVendor: (data: any) => {
            dispatch(storeSelectedVendor(data));
        },
        setSelectedCustomer: (data: any) => {
            dispatch(storeSelectedCustomer(data));
        },
        setContextInfo: (context: never) => {
            dispatch(storeContextInfo(context));
        },
        setDefaultContext: (data: never) => {
            dispatch(storeDefaultContext(data));
        },
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Body);
