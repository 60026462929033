import {
    Divider,
    FormControl,
    FormControlLabel,
    Grid,
    Radio,
    RadioGroup,
    Typography,
} from "@material-ui/core";
import BackdropLoading from "Common/Backdrop/Backdrop";
import MUIButton from "Common/Button/Button";
import TextFields from "Common/TextField/TextField";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import pendingApprovalPostQuery from "ReactQuery/Query/pendingApprovalPostQuery";
import useGetPosts from "ReactQuery/reactQuery";

const ApprovalVen = (props: any) => {
    const {
        PendingApprovalData,
        approvalKey,
        currentContext,
        selectedVendor,
    } = props;
    console.log("pendingApprovalData", PendingApprovalData);
    const [pendingApprovalData, setpendingApprovalData] = useState(
        PendingApprovalData,
    );
    let [value, setValue] = useState<any>("Pending");
    let [reason, setReason] = useState<any>("");
    const [acceptFlag, setAcceptFlag] = useState(true);
    useEffect(() => {
        if (approvalKey) setValue("accept");
        else setValue("decline");
    }, [approvalKey]);

    const [error, seterr] = useState({
        code: 0,
        msg: "",
    });
    const {
        data: pendingApprovalPostQueryData,
        error: pendingApprovalPostQueryError,
        isLoading: pendingApprovalPostQueryLoading,
    } = useGetPosts({
        name: `pendingApprovalPostQuery`,
        query: pendingApprovalPostQuery,
        variables: {
            ou: currentContext?.ouId,
            service: "",
            user: selectedVendor?.loginUser,
            role: currentContext?.roleName,
            languageId: currentContext?.langId,
            supplierCode: selectedVendor?.supplierCode,
            poNumber: pendingApprovalData?.Offernumber,
            merrorid: "",
        },
        skip: acceptFlag,
        header: {
            ou: currentContext?.ouId,
            role: currentContext?.roleName,
            lang: currentContext?.langId,
        },
    });

    useEffect(() => {
        if (pendingApprovalPostQueryData?.pendingApprovalPostQuery) {
            if (pendingApprovalPostQueryData?.pendingApprovalPostQuery)
                seterr({
                    code:
                        pendingApprovalPostQueryData?.pendingApprovalPostQuery
                            ?.code,
                    msg:
                        pendingApprovalPostQueryData?.pendingApprovalPostQuery
                            ?.data,
                });
        }
    }, [pendingApprovalPostQueryData]);

    useEffect(() => {
        if (pendingApprovalPostQueryError && !pendingApprovalPostQueryLoading) {
            const data = JSON.parse(
                JSON.stringify(pendingApprovalPostQueryError),
            );
            const errmsg = data?.response?.errors[0].message;
            seterr({
                code: 400,
                msg: errmsg,
            });
        }
    }, [pendingApprovalPostQueryError, pendingApprovalPostQueryLoading]);
    const handleRadioChange = (e: React.ChangeEvent<{ value: unknown }>) => {
        setValue((e.target as any)?.value);
    };

    return (
        <Grid
            container
            style={{
                maxWidth: "450px",
                marginLeft: "1%",
                textAlign: "left",
                margin: "4%",
            }}
        >
            <BackdropLoading openStates={pendingApprovalPostQueryLoading} />
            <Grid item xs={6}>
                <Typography className="lightgrey font-smaller margin-top-2-per">
                    Customer
                </Typography>
                <Typography className="grey1 font-boldest font-smaller">
                    {pendingApprovalData?.custname}
                </Typography>
            </Grid>
            <Grid item xs={6}>
                <Typography className="lightgrey font-smaller margin-top-2-per">
                    Offer Date
                </Typography>
                <Typography className="grey1 font-boldest font-smaller">
                    {pendingApprovalData?.Offerdate}
                </Typography>
            </Grid>
            <Grid item xs={6}>
                <Typography className="lightgrey font-smaller margin-top-4-per">
                    Start Date
                </Typography>
                <Typography className="grey1 font-boldest font-smaller">
                    {pendingApprovalData?.startdate}
                </Typography>
            </Grid>
            <Grid item xs={6}>
                <Typography className="lightgrey font-smaller margin-top-4-per">
                    Amount Offered
                </Typography>
                <Typography className="grey1 font-boldest font-smaller">
                    {pendingApprovalData?.amountoffer}
                </Typography>
            </Grid>
            <Grid item xs={6}>
                <Typography className="lightgrey font-smaller margin-top-4-per">
                    Customer Contract
                </Typography>
                <Typography className="grey1 font-boldest font-smaller">
                    {pendingApprovalData?.Customercontract}
                </Typography>
            </Grid>
            <Grid item xs={6}>
                <Typography className="lightgrey font-smaller margin-top-4-per">
                    Building Address
                </Typography>
                <Typography className="grey1 font-boldest font-smaller">
                    {"No corresponding ouput in api"}
                </Typography>
            </Grid>
            <Grid item xs={6}>
                <Typography className="lightgrey font-smaller margin-top-4-per">
                    Approx Sq.Ft
                </Typography>
                <Typography className="grey1 font-boldest font-smaller">
                    {"No corresponding ouput in api"}
                </Typography>
            </Grid>
            <Grid item xs={6}>
                <Typography className="lightgrey font-smaller margin-top-4-per">
                    Type of Service
                </Typography>
                <Typography className="grey1 font-boldest font-smaller">
                    {"No corresponding ouput in api"}
                </Typography>
            </Grid>
            <Grid item xs={6}>
                <Typography className="lightgrey font-smaller margin-top-4-per">
                    Frequency
                </Typography>
                <Typography className="grey1 font-boldest font-smaller">
                    {"No corresponding ouput in api"}
                </Typography>
            </Grid>
            <Grid item xs={6}>
                <Typography className="lightgrey font-smaller margin-top-4-per">
                    Days
                </Typography>
                <Typography className="grey1 font-boldest font-smaller">
                    {"No corresponding ouput in api"}
                </Typography>
            </Grid>
            <Grid item xs={8}>
                <Typography className="lightgrey font-smaller margin-top-4-per">
                    Approval
                </Typography>
                <FormControl component="fieldset">
                    <RadioGroup
                        row
                        aria-label="directInvoice"
                        name="row-radio-buttons-group"
                        value={value}
                        onChange={handleRadioChange}
                    >
                        <FormControlLabel
                            value="accept"
                            control={<Radio />}
                            label="Accept"
                        />
                        <FormControlLabel
                            value="decline"
                            control={<Radio />}
                            label="Decline"
                        />
                    </RadioGroup>
                </FormControl>
            </Grid>
            <Grid item xs={10}>
                <Typography
                    className="lightgrey font-smaller margin-top-4-per"
                    style={{ margin: "5% 0%" }}
                >
                    Reason, if declined
                </Typography>
                <TextFields
                    label="Reason"
                    id="reason"
                    value={reason}
                    handleChange={(e) => {
                        setReason(e.target.value);
                    }}
                    multiline
                    rows={4}
                    width="100%"
                />
            </Grid>
            <Grid item xs={12} style={{ marginTop: "2%" }}>
                {error.code !== 0 ? (
                    <Grid
                        item
                        xs={12}
                        style={{
                            textAlign: "center",
                            padding: "2%",
                        }}
                        className={
                            error.code === 200
                                ? "green-light-background"
                                : "red-light-background"
                        }
                    >
                        <span
                            className={`${
                                error?.code === 200
                                    ? "Success_dot"
                                    : "Failed_dot"
                            }`}
                        ></span>{" "}
                        <b>{error.msg}</b>
                    </Grid>
                ) : (
                    <Grid
                        item
                        xs={12}
                        style={{
                            textAlign: "center",
                            padding: "2%",
                        }}
                    >
                        <span></span> <b></b>
                    </Grid>
                )}
            </Grid>
            <Grid item xs={12} style={{ marginTop: "1%" }}>
                <Divider />
            </Grid>
            <Grid item xs={5}></Grid>
            <Grid item xs={3} style={{ marginTop: "3%" }}>
                <MUIButton
                    label="Reset"
                    type="outlined"
                    font="font-smaller"
                    onClick={() => {
                        if (approvalKey) setValue("accept");
                        else setValue("decline");
                        setReason("");
                    }}
                />
            </Grid>
            <Grid item xs={3} style={{ marginTop: "3%" }}>
                <MUIButton
                    label="Submit"
                    type="filled"
                    font="font-smaller"
                    onClick={() => {
                        if (value === "accept") setAcceptFlag(false);
                        else setAcceptFlag(true);
                    }}
                />
            </Grid>
        </Grid>
    );
};
const mapStateToProps = (state: any) => {
    return {
        currentContext: state.context?.userDefaults,
        userName: state.context?.name,
        selectedVendor: state.selectedVendor,
        currency: state.currency,
    };
};

export default connect(mapStateToProps)(ApprovalVen);
