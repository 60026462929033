const pendingApprovalPostQuery = `
mutation
(
    $ou:Int
    $service:String
    $user:String
    $languageId:Int
    $role:String
    $supplierCode:String
    $merrorid:String
    $poNumber: String

)
    {
        pendingApprovalPostQuery
            (
                ou:$ou
                service:$service
                user: $user
                languageId:$languageId
                role: $role
                supplierCode: $supplierCode
                poNumber: $poNumber
                merrorid: $merrorid
            )
                {
                    status
                    data
                }
    }
`;

export default pendingApprovalPostQuery;
