import React from "react";
import { Button, Paper } from "@material-ui/core";
import "./Button.scss";

interface ButtonProps {
    label: string;
    onClick: any;
    type: string;
    font: string;
}

const MUIButton = (props: ButtonProps) => {
    const { label, onClick, type, font } = props;
    return (
        <Paper
            elevation={type == "filled" ? 1 : 0}
            className={`${type} ${font}`}
            onClick={onClick}
            style={{ textAlign: "center" , minWidth:"50px"}}
        >
            {label}
        </Paper>
    );
};

export default MUIButton;
