const CompanyDetailsEditUserCusQuery = `
    mutation(
        $entity: String
        $ou: Int
        $service: String
        $user: String
        $langId: Int
        $customerCode: String
        $customerName: String
        $name: String
        $merrorid: String
    ){
        CompanyEditUserCus(
            entity: $entity
            ou: $ou
            service: $service
            user: $user
            langId: $langId
            customerCode: $customerCode
            customerName: $customerName
            name: $name
            merrorid: $merrorid
        ){   
            Name
            Email
            UserName
            Designation
            isActive
            MakePayment
            OrderSupplies
            Admin
            Image 
            FirstName
            LastName
            JobTitle
            BusinessPhone
            MobilePhone   
            contactaddid     
        }
    }
`;

export default CompanyDetailsEditUserCusQuery;
