import Dashboard from "Components/Dashboard";
import {
    Apartment,
    Assignment,
    ContactlessTwoTone,
    DashboardOutlined,
    QuestionAnswer,
    ShoppingCart,
    AccountBalance,
    LocalAtm,
    ErrorOutline
} from "@material-ui/icons";
import { MenuCategorySchema } from "./types";
import HomeIcon from "@material-ui/icons/Home";
import OrderSuppliesCus from "Components/OrderSuppliesCus";
import OrderSupplyChild from "Components/OrderSuppliesCus/OrderSupplyChild";
import CompanyProfileCus from "Components/CompanyProfileCus";
import InvoiceDetailsChild from "Components/InvoicesCus/InvoiceDetailsChild";
import ContractsVEN from "Components/ContractsVEN";
import BuildingDetailsCUS from "Components/BuildingCUS/Details";
import TicketsCUS from "Components/TicketsCUS";
import TicketDetails from "Components/TicketsCUS/TicketDetails";
import PendingApproval from "Components/ContractsVEN/PendingApprovalVEN/Body";
import ContractDetailsVEN from "Components/ContractsVEN/ContractDetailsVEN";
import AssignmentDetails from "Components/ContractsVEN/AssignmentDetails";
import PayAdviceVen from "Components/PayAdviceVen";
import FranchiseeChild from "Components/Dashboard/FranchiseeChild";
import FranchiseInsuranceRequirment from "Components/Dashboard/FranchiseInsuranceRequirment";
import QAVisits from "Components/Dashboard/QAVisits";
import Admin from "Components/Admin";
import CalendarScheduleVEN from "Components/CalendarScheduleVEN";
import LoanSummaryVEN from "Components/LoanSummaryVEN";
import LoanDetails from "Components/LoanSummaryVEN/LoanDetails";
import CurrentAssignmentsVEN from "Components/CurrentAssignmentsVEN";
import VendorDetails from "Components/CurrentAssignments/VendorDetails";

const NavMenuSchema: MenuCategorySchema[] = [
    {
        id: 0,
        title: "Dashboard",
        value: "Dashboard",
        subcategories: [],
        route: "Dashboard",
        level: "BPCActivity",
        parent: 0,
        child: Dashboard,
        icon: DashboardOutlined,
    },
    {
        id: 1,
        title: "Current Assignment",
        value: "CurrentAssignment",
        subcategories: [],
        route: "CurrentAssignment",
        level: "BPCActivity",
        parent: 0,
        child: CurrentAssignmentsVEN,
        icon: Assignment,
    },
    {
        id: 2,
        title: "Pay Advice",
        value: "PayAdvice",
        subcategories: [],
        route: "PayAdvice",
        level: "BPCActivity",
        parent: 0,
        child: PayAdviceVen,
        icon: LocalAtm,
    },
    {
        id: 5,
        title: "Tickets",
        value: "Tickets",
        subcategories: [],
        route: "Tickets",
        level: "BPCActivity",
        parent: 0,
        child: TicketsCUS,
        icon: ErrorOutline,
    },
    // {
    //     id: 6,
    //     title: "Order Supplies",
    //     value: "OrderSupplies",
    //     subcategories: [],
    //     route: "OrderSupplies",
    //     level: "BPCActivity",
    //     parent: 0,
    //     child: OrderSuppliesCus,
    //     icon: ShoppingCart,
    // },
    {
        id: 7,
        title: "Company Profile",
        value: "CompanyProfile",
        subcategories: [],
        route: "CompanyProfile",
        level: "BPCActivity",
        parent: 0,
        child: CompanyProfileCus,
        icon: Apartment,
    },
   
    {
        id: 20,
        title: "InvoiceDetails",
        value: "InvoiceDetails",
        subcategories: [],
        route: "InvoiceDet",
        level: "Component",
        parent: 100,
        child: InvoiceDetailsChild,
        icon: HomeIcon,
    },
    {
        id: 21,
        title: "OrderSupply",
        value: "OrderSupply",
        subcategories: [],
        route: "OrderSpDet",
        level: "Component",
        parent: 100,
        child: OrderSupplyChild,
        icon: HomeIcon,
    },

    {
        id: 22,
        title: "Details",
        value: "Details",
        subcategories: [],
        route: "BuildDet",
        level: "Component",
        parent: 100,
        child: BuildingDetailsCUS,
        icon: HomeIcon,
    },
    {
        id: 23,
        title: "TicketDetails",
        value: "TicketDetails",
        subcategories: [],
        icon: QuestionAnswer,
        route: "TICKDET",
        level: "Component",
        parent: 6,
        child: TicketDetails,
    },
    {
        id: 24,
        title: "PendingApproval",
        value: "PendingApproval",
        subcategories: [],
        icon: QuestionAnswer,
        route: "PENDAPPROVAL",
        level: "Component",
        parent: 3,
        child: PendingApproval,
    },
    {
        id: 25,
        title: "ContractDetails",
        value: "ContractDetails",
        subcategories: [],
        route: "CONTDETSV",
        level: "Component",
        parent: 60,
        icon: QuestionAnswer,
        child: ContractDetailsVEN,
    },
    {
        id: 26,
        title: "AssignmentDetails",
        value: "AssignmentDetails",
        subcategories: [],
        route: "AssignmentDetails",
        level: "Component",
        parent: 60,
        icon: QuestionAnswer,
        child: AssignmentDetails,
    },
    {
        id: 27,
        title: "CalendarSchedule",
        value: "CalendarSchedule",
        subcategories: [],
        route: "CalendarSchedule",
        level: "Component",
        parent: 60,
        icon: HomeIcon,
        child: CalendarScheduleVEN,
    },
    {
        id: 28,
        title: "LoanDetails",
        value: "LoanDetails",
        subcategories: [],
        route: "LoanDetails",
        level: "Component",
        parent: 60,
        icon: HomeIcon,
        child: LoanDetails,
    },
    {
        id: 29,
        title: "VendorDetails",
        value: "VendorDetails",
        subcategories: [],
        route: "VendorDetails",
        level: "Component",
        parent: 60,
        icon: HomeIcon,
        child: VendorDetails,
    },
    {
        id: 19,
        title: "FranchiseInsurance",
        value: "FranchiseInsurance",
        subcategories: [],
        route: "FranchiseInsur",
        level: "Component",
        parent: 100,
        child: FranchiseInsuranceRequirment,
        icon: HomeIcon,
    },
    {
        id: 20,
        title: "QAVisits",
        value: "QAVisits",
        subcategories: [],
        route: "QaVisit",
        level: "Component",
        parent: 100,
        child: QAVisits,
        icon: HomeIcon,
    },
    {
        id: 21,
        title: "PayAdvice",
        value: "PayAdvice",
        subcategories: [],
        route: "PayAdvice",
        level: "Component",
        parent: 100,
        child: PayAdviceVen,
        icon: HomeIcon,
    },
    {
        id: 22,
        title: "Franchisee",
        value: "Franchisee",
        subcategories: [],
        route: "Franchisee",
        level: "Component",
        parent: 100,
        child: FranchiseeChild,
        icon: HomeIcon,
    },
    {
        id: 24,
        title: "Admin",
        value: "Admin",
        subcategories: [],
        route: "Admin",
        level: "BPCActivityS",
        parent: 0,
        child: Admin,
        icon: HomeIcon,
        type: "VEN",
    },
];
const LandingPageSchema = [
    {
        code: "dashboard",
        child: Dashboard,
    },
];
export default NavMenuSchema;
export { LandingPageSchema };
