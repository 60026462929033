const VolumeAndAcceptanaceQuery = `
mutation(
    $entity:String
    $ou:Int
    $service:String
    $User:String
    $languageId:Int
    $supplierCode:String
){
    VolumeAndAcceptanace(
        entity:$entity
        ou:$ou
        service:$service
        User:$User
        languageId:$languageId
        supplierCode:$supplierCode
    ){
        assigned,
        owed,
        advol,
        initial,
        replaceable,
        accepted,
        offered,
        refusal,
    } 
}
`;

export default VolumeAndAcceptanaceQuery;
