const FMIRHeaderQuery = `
    mutation(
        $entity: String
        $ou: Int
        $service: String
        $User: String
        $languageId: Int
        $vendorCode: String
        $vendorName: String
        $status: String
        $payAdviceID: String
        $fromDate: String
        $toDate: String
        $merrorid: Int
    ){
        FmirHeader(
            entity: $entity
            ou: $ou
            service: $service
            User: $User
            languageId: $languageId
            vendorCode: $vendorCode
            vendorName: $vendorName
            status: $status
            payAdviceID: $payAdviceID
            fromDate: $fromDate
            toDate: $toDate
            merrorid: $merrorid
        )
        {
            vendorCode
            vendorName
            address
            city
            state
            zip
            title
            note1
            note2
        }
    }
`;

export default FMIRHeaderQuery;
