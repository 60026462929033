import { Grid, Paper, Backdrop, CircularProgress } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import MUIButton from "Common/Button/Button";
import { connect } from "react-redux";
import useGetPosts from "ReactQuery/reactQuery";
import MuiDatatables from "Common/Mui-DataTables/MuiDatatables";
import { Redirect } from "react-router-dom";
import Dropdown from "Common/Dropdown/Dropdown";
import TextFields from "Common/TextField/TextField";
import CustomDatePickerBorder from "Common/DatePicker/DatePicker";
import {
    ContractHeaderQuerySV,
    ContractsQuerySV,
    StatusComboQuery,
} from "ReactQuery/Query";
import Alert from "Common/Alerts/Alerts";
import { makeStyles, Theme } from "@material-ui/core/styles";
import currencyFormatter from "Common/Utils/currencyFormatter";
import NoContractSign from "./Images/NoContractSign.svg";
import { storeBreadcrumbs } from "stateManagement/actions/Common/breadcrumbs";
import dateFormatter from "Common/Utils/dateFormatter";
interface DashCards {
    desc: string;
    value: string;
    visible: boolean;
    frequent: boolean;
    detail: {
        desc: string;
        value: number;
        color: string;
        code: string;
    }[];
}

const useStyles = makeStyles((theme: Theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: "#fff",
    },
}));
interface BodyProps {
    currentContext: any;
    userName: any;
    selectedVendor: any;
    currency: any;
    setBreadcrumbsData: any;
}

const Body = (props: BodyProps) => {
    const {
        currentContext,
        userName,
        selectedVendor,
        currency,
        setBreadcrumbsData,
    } = props;
    setBreadcrumbsData([
        {
            value: "Vendor Contract",
            href: "/app/*/Contracts",
        },
    ]);
    const classes = useStyles();
    const [header, setHeader] = useState<DashCards>();
    const [contractDet, setContractDet] = useState<boolean>(false);
    const [active, setActive] = useState("");
    const [showTable, setShowTable] = useState<boolean>(false);
    const [contractDetailsList, setContractDetailsList] = useState([]);
    const [contractSelected, setcontractSelected] = useState();
    // const [contractToggle, setContractToggle] = useState(true);
    // useEffect(() => {
    //     setContractToggle(false);
    // }, []);

    const handleContractClick = (val: any) => {
        setContractDet(true);
        setcontractSelected(val);
    };
    const [alert, setAlert] = useState(false);
    const [errr, seterr] = useState({
        code: 0,
        msg: "",
    });
    const [statusList, setStatusList] = useState<any>([]);
    const [contractData, setContractData] = useState({
        fromDate: new Date(),
        toDate: new Date(),
        contractno: "",
        status: "",
    });

    const handleErrorAlert = (code: number, message: string) => {
        Alert({
            statusCode: code,
            message: message,
            willClose: () => {
                setAlert(false);
                seterr({ code: 0, msg: "" });
            },
        });
    };

    const {
        data: StatusComboData,
        error: StatusComboDataError,
        isLoading: StatusComboDataLoading,
    } = useGetPosts({
        name: `StatusComboQuery_${selectedVendor?.loginUser}_BPO_Contract`,
        query: StatusComboQuery,
        variables: {
            entity: "ow_getcombo_status",
            ou: currentContext?.ouId,
            service: "",
            user: selectedVendor?.loginUser,
            langId: currentContext?.langId,
            screen: "BPO_Contract",
            tranType: "",
            merrorid: "",
        },
    });

    useEffect(() => {
        if (StatusComboData?.statusCombo) {
            console.log("StatusData", StatusComboData?.statusCombo);
            setStatusList(StatusComboData?.statusCombo);
            setContractData((prevState: any) => {
                return {
                    ...prevState,
                    status: StatusComboData?.statusCombo[0]?.value,
                };
            });
        }
    }, [StatusComboData]);

    useEffect(() => {
        if (StatusComboDataError && !StatusComboDataLoading) {
            const data = JSON.parse(JSON.stringify(StatusComboDataError));
            const errmsg = data?.response?.errors[0].message;
            seterr({
                code: 400,
                msg: errmsg,
            });
            setAlert(true);
        }
    }, [StatusComboDataError, StatusComboDataLoading]);

    const {
        data: ContractHeaderData,
        error: ContractHeaderDataError,
        isLoading: ContractHeaderDataLoading,
    } = useGetPosts({
        name: `ContractHeaderQuerySV_get_data_${selectedVendor?.supplierCode}`,
        query: ContractHeaderQuerySV,
        variables: {
            entity: "OW_Vendor_portal_status",
            ou: currentContext?.ouId,
            service: "",
            User: selectedVendor?.loginUser,
            suppCode: selectedVendor?.supplierCode,
            languageId: currentContext?.langId,
            merrorid: "",
        },
    });

    useEffect(() => {
        if (ContractHeaderData?.GetContractHeaderSV) {
            setHeader(ContractHeaderData?.GetContractHeaderSV);
            setActive(ContractHeaderData?.GetContractHeaderSV?.detail[0]?.desc);
            // setContractToggle(false);
        }
    }, [ContractHeaderData]);

    useEffect(() => {
        if (ContractHeaderDataError && !ContractHeaderDataLoading) {
            const data = JSON.parse(JSON.stringify(ContractHeaderDataError));
            const errmsg = data?.response?.errors[0].message;
            seterr({
                code: 400,
                msg: errmsg,
            });
            setAlert(true);
        }
    }, [ContractHeaderDataError, ContractHeaderDataLoading]);

    const {
        data: ContractsData,
        error: ContractsDataError,
        isLoading: ContractsDataLoading,
    } = useGetPosts({
        name: `ContractsQuerySV_get_data_${contractData?.fromDate}_${contractData?.toDate}_${contractData?.contractno}_${contractData?.status}_${selectedVendor?.supplierCode}_${active}`,
        query: ContractsQuerySV,
        variables: {
            entity: "OW_Vendor_portal_contrHdr",
            ou: currentContext?.ouId,
            service: "",
            languageId: currentContext?.langId,
            User: selectedVendor?.loginUser,
            supplierCode: selectedVendor?.supplierCode,
            suppliername: "",
            status:
                active === header?.detail[0]?.desc
                    ? "AC"
                    : contractData?.status,
            toDate: dateFormatter("sqlDate", contractData?.toDate),
            fromDate: dateFormatter("sqlDate", contractData?.fromDate),
            contractNo: contractData?.contractno,
            merrorid: "",
        },
        // skip: contractToggle,
        skip: active === header?.detail[0]?.desc ? false : !showTable,
    });

    useEffect(() => {
        if (ContractsData?.GetContractsSV) {
            setContractDetailsList(ContractsData?.GetContractsSV);
            // setContractToggle(true);
        }
    }, [ContractsData]);

    useEffect(() => {
        if (ContractsDataError && !ContractsDataLoading) {
            const data = JSON.parse(JSON.stringify(ContractsDataError));
            const errmsg = data?.response?.errors[0].message;
            // seterr({
            //     code: 400,
            //     msg: errmsg,
            // });
            // setAlert(true);
            // setContractToggle(true);
        }
    }, [ContractsDataError, ContractsDataLoading]);

    const contractSchema = [
        {
            name: "Contract",
            Label: "Contract",
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => (
                    <span style={{ color: "white" }}>Contract</span>
                ),
                setCellHeaderProps: () => {
                    return {
                        style: {
                            borderRight: "1px solid #D5D5D5",
                            textAlign: "center",
                        },
                    };
                },
                setCellProps: () => {
                    return {
                        style: {
                            borderRight: "1px solid #D5D5D5",
                            textAlign: "center",
                            padding: "15px 8px !important",
                        },
                    };
                },
                customBodyRender: (
                    value: any,
                    tableMeta: any,
                    updateValue: any,
                ) => {
                    console.log("table data", value, tableMeta.rowData);
                    return (
                        <b
                            className="blue"
                            style={{ cursor: "pointer" }}
                            onClick={() => handleContractClick(value)}
                        >
                            {value}
                        </b>
                    );
                },
            },
        },
        {
            name: "AccountNumber",
            Label: "Account Number",
            type: "string",
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => (
                    <span style={{ color: "white" }}>Account Number</span>
                ),
                setCellHeaderProps: () => {
                    return { style: { borderRight: "1px solid #D5D5D5" } };
                },
                setCellProps: () => {
                    return {
                        style: {
                            borderRight: "1px solid #D5D5D5",
                            textAlign: "center",
                            padding: "15px 8px !important",
                        },
                    };
                },
                customBodyRender: (
                    value: any,
                    tableMeta: any,
                    updateValue: any,
                ) => {
                    console.log("table data", value);
                    return <b style={{ color: "#5C677D" }}>{value}</b>;
                },
            },
        },
        {
            name: "Vendor",
            Label: "Vendor",
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => (
                    <span style={{ color: "white" }}>Vendor</span>
                ),
                setCellHeaderProps: () => {
                    return { style: { borderRight: "1px solid #D5D5D5" } };
                },
                setCellProps: () => {
                    return {
                        style: {
                            borderRight: "1px solid #D5D5D5",
                            textAlign: "center",
                            padding: "15px 8px !important",
                        },
                    };
                },
                customBodyRender: (
                    value: any,
                    tableMeta: any,
                    updateValue: any,
                ) => {
                    console.log("table data", value);
                    return <b style={{ color: "#5C677D" }}>{value}</b>;
                },
            },
        },
        {
            name: "Region",
            Label: "Region",
            type: "string",
            options: {
                filter: true,
                sort: false,
                setCellHeaderProps: () => {
                    return { style: { borderRight: "1px solid #D5D5D5" } };
                },
                setCellProps: () => {
                    return {
                        style: {
                            borderRight: "1px solid #D5D5D5",
                            textAlign: "center",
                            padding: "15px 8px !important",
                        },
                    };
                },
                customHeadLabelRender: () => (
                    <span style={{ color: "white" }}>Region</span>
                ),
                customBodyRender: (
                    value: any,
                    tableMeta: any,
                    updateValue: any,
                ) => {
                    console.log("table data", value);
                    return <b style={{ color: "#5C677D" }}>{value}</b>;
                },
            },
        },
        // {
        //     name: "ContractR",
        //     Label: "Contract Revision",
        //     type: "number",
        //     options: {
        //         customHeadLabelRender: () => (
        //             <span style={{ color: "white" }}>Contract Revision</span>
        //         ),
        //         setCellHeaderProps: () => {
        //             return { style: { borderRight: "1px solid #D5D5D5" } };
        //         },
        //         setCellProps: () => {
        //             return {
        //                 style: {
        //                     borderRight: "1px solid #D5D5D5",
        //                     textAlign: "center",
        //                     padding: "15px 8px !important",
        //                 },
        //             };
        //         },
        //         customBodyRender: (
        //             value: any,
        //             tableMeta: any,
        //             updateValue: any,
        //         ) => {
        //             console.log("table data", value);
        //             return <b style={{ color: "#5C677D" }}>{value}</b>;
        //         },
        //     },
        // },

        {
            name: "PurchaseDate",
            Label: "Purchase Date",
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => (
                    <span style={{ color: "white" }}>Purchase Date</span>
                ),
                setCellHeaderProps: () => {
                    return { style: { borderRight: "1px solid #D5D5D5" } };
                },
                setCellProps: () => {
                    return {
                        style: {
                            borderRight: "1px solid #D5D5D5",
                            padding: "15px 8px !important",
                            textAlign: "center",
                        },
                    };
                },
                customBodyRender: (
                    value: any,
                    tableMeta: any,
                    updateValue: any,
                ) => {
                    console.log("table data", value);
                    return <b style={{ color: "#5C677D" }}>{value}</b>;
                },
            },
        },
        {
            name: "StartEndDate",
            Label: "Start-End Date",
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => (
                    <span style={{ color: "white" }}>Start-End Date</span>
                ),
                setCellHeaderProps: () => {
                    return { style: { borderRight: "1px solid #D5D5D5" } };
                },
                setCellProps: () => {
                    return {
                        style: {
                            borderRight: "1px solid #D5D5D5",
                            padding: "15px 8px !important",
                            textAlign: "center",
                        },
                    };
                },
                customBodyRender: (
                    value: any,
                    tableMeta: any,
                    updateValue: any,
                ) => {
                    console.log("table data", value);
                    return <b style={{ color: "#5C677D" }}>{value}</b>;
                },
            },
        },
        {
            name: "PackagePurchased",
            Label: "Package Purchased",
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => (
                    <span style={{ color: "white" }}>Package Purchased</span>
                ),
                setCellHeaderProps: () => {
                    return { style: { borderRight: "1px solid #D5D5D5" } };
                },
                setCellProps: () => {
                    return {
                        style: {
                            borderRight: "1px solid #D5D5D5",
                            textAlign: "center",
                            padding: "15px 8px !important",
                        },
                    };
                },
                customBodyRender: (
                    value: any,
                    tableMeta: any,
                    updateValue: any,
                ) => {
                    console.log("table data", value);
                    return <b style={{ color: "#5C677D" }}>{value}</b>;
                },
            },
        },
        {
            name: "VolumePurchased",
            Label: "Volume Purchased",
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => (
                    <span style={{ color: "white" }}>Volume Purchased</span>
                ),
                setCellHeaderProps: () => {
                    return { style: { borderRight: "1px solid #D5D5D5" } };
                },
                setCellProps: () => {
                    return {
                        style: {
                            borderRight: "1px solid #D5D5D5",
                            textAlign: "center",
                            padding: "15px 8px !important",
                        },
                    };
                },
                customBodyRender: (
                    value: any,
                    tableMeta: any,
                    updateValue: any,
                ) => {
                    console.log("table data", value);
                    return (
                        <b
                            style={{
                                color: "#5C677D",
                                display: "flex",
                                justifyContent: "flex-end",
                            }}
                        >
                            {currencyFormatter(value, currency)}
                        </b>
                    );
                },
            },
        },
        // {
        //     name: "TotalCost",
        //     Label: "Total Cost",
        //     options: {
        //         filter: true,
        //         sort: false,
        //         customHeadLabelRender: () => (
        //             <span style={{ color: "white" }}>Total Cost</span>
        //         ),
        //         setCellHeaderProps: () => {
        //             return { style: { borderRight: "1px solid #D5D5D5" } };
        //         },
        //         setCellProps: () => {
        //             return {
        //                 style: {
        //                     borderRight: "1px solid #D5D5D5",
        //                     textAlign: "center",
        //                     padding: "15px 8px !important",
        //                 },
        //             };
        //         },
        //         customBodyRender: (
        //             value: any,
        //             tableMeta: any,
        //             updateValue: any,
        //         ) => {
        //             console.log("table data", value);
        //             return (
        //                 <b
        //                     style={{
        //                         color: "#5C677D",
        //                         display: "flex",
        //                         justifyContent: "flex-end",
        //                     }}
        //                 >
        //                     {currencyFormatter(value, currency)}
        //                 </b>
        //             );
        //         },
        //     },
        // },

        {
            name: "TargetFulfillment",
            Label: "Target Fulfillment",
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => (
                    <span style={{ color: "white" }}>Target Fulfillment</span>
                ),
                setCellHeaderProps: () => {
                    return { style: { borderRight: "1px solid #D5D5D5" } };
                },
                setCellProps: () => {
                    return {
                        style: {
                            borderRight: "1px solid #D5D5D5",
                            textAlign: "center",
                            padding: "15px 8px !important",
                        },
                    };
                },
                customBodyRender: (
                    value: any,
                    tableMeta: any,
                    updateValue: any,
                ) => {
                    console.log("table data", value);
                    return (
                        <b
                            style={{
                                color: "#5C677D",
                                display: "flex",
                                justifyContent: "flex-end",
                            }}
                        >
                            {currencyFormatter(value, currency)}
                        </b>
                    );
                },
            },
        },
        {
            name: "VolumeOwned",
            Label: "Volume Owned",
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => (
                    <span style={{ color: "white" }}>Volume Owed</span>
                ),
                setCellHeaderProps: () => {
                    return { style: { borderRight: "1px solid #D5D5D5" } };
                },
                setCellProps: () => {
                    return {
                        style: {
                            borderRight: "1px solid #D5D5D5",
                            padding: "15px 8px !important",
                            textAlign: "center",
                        },
                    };
                },
                customBodyRender: (
                    value: any,
                    tableMeta: any,
                    updateValue: any,
                ) => {
                    console.log("table data", value);
                    return (
                        <b
                            style={{
                                color: "#5C677D",
                                display: "flex",
                                justifyContent: "flex-end",
                            }}
                        >
                            {currencyFormatter(value, currency)}
                        </b>
                    );
                },
            },
        },
    ];

    const handleActive = (index: string) => {
        setActive(index);
        setShowTable(false);
        console.log("index", index);
    };

    const handleChange = (e: any) => {
        setShowTable(false);
        setContractData((prevState) => ({
            ...prevState,
            contractno: e?.target?.value,
        }));
    };

    const handleChangeStatus = (e: any) => {
        setShowTable(false);
        setContractData((prevState) => ({
            ...prevState,
            status: e?.target?.value,
        }));
    };

    const handleDate = (type: string, e: any) => {
        setShowTable(false);
        if (type === "toDate") {
            setContractData((prevstate: any) => ({
                ...prevstate,
                toDate: e,
            }));
        } else if (type === "fromDate") {
            setContractData((prevstate: any) => ({
                ...prevstate,
                fromDate: e,
            }));
        }
    };

    const handleSearch = () => {
        // setContractDetailsList([]);
        // setContractToggle(false);
        setShowTable(true);
    };
    const [pendingApproval, setPendingApproval] = useState(false);

    if (pendingApproval) {
        return (
            <Redirect
                to={{
                    pathname: "/app/*/PENDAPPROVAL",
                }}
            />
        );
    }

    if (contractDet) {
        return (
            <Redirect
                to={{
                    pathname: "/app/*/CONTDETSV",
                    state: {
                        contractSelected: contractSelected,
                    },
                }}
            />
        );
    }

    return (
        <>
            {alert ? handleErrorAlert(errr.code, errr.msg) : <></>}
            <Backdrop
                className={classes.backdrop}
                open={ContractsDataLoading || ContractHeaderDataLoading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <Grid container spacing={0} alignItems="center">
                <Grid item xs={12} sm={12} style={{ paddingTop: "2%" }}>
                    <Grid container spacing={2} alignItems="center">
                        <Grid item sm={9} md={4} lg={5}>
                            <Paper elevation={4}>
                                <Grid container spacing={1}>
                                    <Grid
                                        item
                                        xs={7}
                                        style={{
                                            borderRight: "1px dashed #D5D5D5",
                                        }}
                                    >
                                        <Grid
                                            container
                                            spacing={2}
                                            style={{
                                                padding: "4% 6% 1% 12%",
                                            }}
                                        >
                                            {header?.detail.map((element) => {
                                                return (
                                                    <Grid
                                                        item
                                                        xs={6}
                                                        sm={6}
                                                        style={{
                                                            padding: "3% 5%",
                                                        }}
                                                        className={
                                                            active ===
                                                            element?.desc
                                                                ? `${element?.color}_border_bottom pointer`
                                                                : "pointer"
                                                        }
                                                        onClick={() =>
                                                            handleActive(
                                                                element?.desc,
                                                            )
                                                        }
                                                    >
                                                        <Grid
                                                            container
                                                            spacing={1}
                                                        >
                                                            <Grid
                                                                item
                                                                xs={12}
                                                                sm={12}
                                                            >
                                                                <span
                                                                    className={
                                                                        active ===
                                                                        element?.desc
                                                                            ? `${element?.color} font-biggest font-boldest`
                                                                            : `grey font-biggest font-boldest`
                                                                    }
                                                                >
                                                                    {
                                                                        element?.value
                                                                    }
                                                                </span>
                                                            </Grid>
                                                            <Grid
                                                                item
                                                                xs={12}
                                                                sm={12}
                                                            >
                                                                <span
                                                                    className={
                                                                        active ===
                                                                        element?.code
                                                                            ? `font-boldest font-smallest`
                                                                            : "grey font-smallest"
                                                                    }
                                                                >
                                                                    {element?.desc.toUpperCase()}
                                                                </span>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                );
                                            })}
                                        </Grid>
                                    </Grid>
                                    <Grid
                                        item
                                        xs={4}
                                        style={{
                                            margin: "auto",
                                            display: "flex",
                                            justifyContent: "center",
                                        }}
                                    >
                                        <MUIButton
                                            label="Pending Approvals"
                                            type="filled"
                                            font="font-small"
                                            onClick={() => {
                                                setPendingApproval(true);
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid>

                        {active === header?.detail[1]?.desc && (
                            <Grid item sm={12} md={7}>
                                <Paper elevation={4}>
                                    <Grid
                                        container
                                        spacing={2}
                                        style={{ padding: "1.5%" }}
                                    >
                                        <Grid item xs sm={10}>
                                            <Grid
                                                container
                                                spacing={2}
                                                alignItems="center"
                                            >
                                                <Grid item sm xs={12}>
                                                    <TextFields
                                                        label={"Contract No."}
                                                        id={"contractno"}
                                                        value={
                                                            contractData?.contractno
                                                        }
                                                        handleChange={
                                                            handleChange
                                                        }
                                                        width={"100%"}
                                                        multiline={false}
                                                        rows={0}
                                                    />
                                                </Grid>
                                                <Grid item sm xs={12}>
                                                    <Dropdown
                                                        label={"Status"}
                                                        id={"status"}
                                                        value={
                                                            contractData?.status
                                                        }
                                                        list={statusList}
                                                        handleChange={
                                                            handleChangeStatus
                                                        }
                                                        width={"100%"}
                                                    />
                                                </Grid>
                                                <Grid item sm xs={2}>
                                                    <CustomDatePickerBorder
                                                        label="From Date"
                                                        width="100%"
                                                        value={
                                                            contractData?.fromDate
                                                        }
                                                        onChange={(e) =>
                                                            handleDate(
                                                                "fromDate",
                                                                e,
                                                            )
                                                        }
                                                        format="MM/dd/yyyy"
                                                    />
                                                </Grid>
                                                <Grid item sm xs={2}>
                                                    <CustomDatePickerBorder
                                                        label="To Date"
                                                        width="100%"
                                                        value={
                                                            contractData?.toDate
                                                        }
                                                        onChange={(e) =>
                                                            handleDate(
                                                                "toDate",
                                                                e,
                                                            )
                                                        }
                                                        format="MM/dd/yyyy"
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>

                                        <Grid
                                            item
                                            sm={2}
                                            xs={12}
                                            style={{
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                            }}
                                        >
                                            <MUIButton
                                                label="Search"
                                                type="filled"
                                                font="font-small"
                                                onClick={handleSearch}
                                            />
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </Grid>
                        )}
                    </Grid>
                </Grid>

                {active === header?.detail[0]?.desc && (
                    <Grid
                        item
                        xs={12}
                        style={{ marginRight: "2%", paddingTop: "2%" }}
                    >
                        {contractDetailsList?.length ? (
                            <MuiDatatables
                                tableHeight="320px"
                                header={contractSchema}
                                detail={[
                                    contractDetailsList,
                                    setContractDetailsList,
                                ]}
                            />
                        ) : (
                            <img
                                src={NoContractSign}
                                style={{ paddingTop: "4%", width: "95vh" }}
                            />
                        )}
                    </Grid>
                )}

                {active === header?.detail[1]?.desc && showTable && (
                    <Grid
                        item
                        xs={12}
                        style={{ marginRight: "2%", paddingTop: "2%" }}
                    >
                        {contractDetailsList?.length ? (
                            <MuiDatatables
                                header={contractSchema}
                                detail={[
                                    contractDetailsList,
                                    setContractDetailsList,
                                ]}
                            />
                        ) : (
                            <img
                                src={NoContractSign}
                                style={{ paddingTop: "4%", width: "95vh" }}
                            />
                        )}
                    </Grid>
                )}
            </Grid>
        </>
    );
};

const mapStateToProps = (state: any) => {
    return {
        currentContext: state.context?.userDefaults,
        userName: state.context?.name,
        selectedVendor: state.selectedVendor,
        currency: state.currency,
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        setBreadcrumbsData: (data: any) => {
            dispatch(storeBreadcrumbs(data));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Body);
