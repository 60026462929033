const VendorDetailsMultilineQuery = `
mutation(
    $ou:Int
    $service:String
    $User:String
    $languageId:Int
    $supplierCode:String
    $suppliername:String
    $poNo:String
    $merrorid:String 
){
    VendorDetailsMultiline(
        ou:$ou
        service:$service
        User:$User
        languageId:$languageId
        supplierCode:$supplierCode
        suppliername:$suppliername
        poNo:$poNo
        merrorid:$merrorid
    ){ 
        Contract
        AccountNumber
        Designation
        Region
        ContractR
        PurchaseDate
        StartEndDate
        PackagePurchased
        VolumePurchased
        TotalCost
        TargetFulfillment
        VolumeOwned
    }
}
`;

export default VendorDetailsMultilineQuery;
