import { PORTAL_KEY } from "../../actionTypes";
const initialState = false; //customer Portal
const storePortalKey = (prevState = initialState, action: any) => {
    switch (action.type) {
        case PORTAL_KEY:
            return action.payload;
        default:
            return prevState;
    }
};
export default storePortalKey;
