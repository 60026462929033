import { STORE_PORTAL } from "../../actionTypes";

const initialState = {
    portal: "CMN",
};

const storePortal = (prevState = initialState.portal, action: any) => {
    switch (action.type) {
        case STORE_PORTAL:
            return action.payload;

        default:
            return prevState;
    }
};

export default storePortal;
