import { Grid, Backdrop, CircularProgress } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import MuiDatatables from "Common/Mui-DataTables/MuiDatatables";
import useGetPosts from "ReactQuery/reactQuery";
import { Redirect } from "react-router-dom";
import "Common/Main.scss";
import { connect } from "react-redux";
import { makeStyles, Theme } from "@material-ui/core/styles";
import Alert from "Common/Alerts/Alerts";
import BuildingQueryCUS from "ReactQuery/Query/BuildingQueryCUS";
import NoBuildings from "./Images/no_building.svg";
import currencyFormatter from "Common/Utils/currencyFormatter";
import { storeBreadcrumbs } from "stateManagement/actions/Common/breadcrumbs";

const useStyles = makeStyles((theme: Theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: "#fff",
    },
}));

const Body = (props: any) => {
    const {
        currentContext,
        userName,
        selectedCustomer,
        currency,
        setBreadcrumbsData,
    } = props;
    setBreadcrumbsData([
        {
            value: "Buildings",
            href: "/app/*/Buildings",
        },
    ]);
    const classes = useStyles();
    const [data, setData] = useState<any[]>([]);
    const [toggle, setToggle] = useState(false);
    const [siteId, setSiteId] = useState("");
    const [popoverBlack, setPopoverBlack] = useState(false);
    const [alert, setAlert] = useState(false);
    const [errr, seterr] = useState({
        code: 0,
        msg: "",
    });

    const handleErrorAlert = (code: number, message: string) => {
        Alert({
            statusCode: code,
            message: message,
            willClose: () => {
                setAlert(false);
                seterr({ code: 0, msg: "" });
            },
        });
    };

    const {
        data: BuildingData,
        error: BuildingError,
        isLoading: BuildingLoading,
    } = useGetPosts({
        name: `BuildingQueryCUS_${selectedCustomer?.customerCode}`,
        query: BuildingQueryCUS,
        variables: {
            entity: "OW_Cust_portal_BuildingSmry",
            ou: currentContext?.ouId,
            service: "",
            User: selectedCustomer?.loginUser,
            languageId: currentContext?.langId,
            customerCode: selectedCustomer?.customerCode,
            customerName: "",
            merrorid: "",
        },
    });

    useEffect(() => {
        console.log("BuildingSummary", BuildingData?.BuildingQueryCUS);
        if (BuildingData?.BuildingQueryCUS ) {
            // const BuildingQueryCUS = [
            //     {
            //         Building: "1 & 1 IONOS - New Address",

            //         SiteId: "S0102",
            //         AccNo: "C1000010",
            //         CustLevel: null,
            //         Address: "100 N 18th St,  Suite 400",
            //         Address2: "Philadelphia | Pennsylvania | 19103",
            //         VendorInfo: {
            //             name: null,
            //             company: null,
            //         },
            //         TotalAmt: "3378",
            //         LastQOS: null,
            //     },
            //     {
            //         Building: "WMD",

            //         SiteId: "S0104",
            //         AccNo: "C1000010",
            //         CustLevel: null,
            //         Address: "200 S 18th St,  Suite 400",
            //         Address2: "Philadelphia | Pennsylvania | 19103",
            //         VendorInfo: {
            //             name: null,
            //             company: null,
            //         },
            //         TotalAmt: "3378",
            //         LastQOS: null,
            //     },
            // ];
            // setData(BuildingQueryCUS);
            setData(BuildingData?.BuildingQueryCUS);
        }
    }, [BuildingData]);

    useEffect(() => {
        if (BuildingError && !BuildingLoading) {
            const data = JSON.parse(JSON.stringify(BuildingError));
            const errmsg = data?.response?.errors[0].message;
            seterr({
                code: 400,
                msg: errmsg,
            });
            // setAlert(true);
        }
    }, [BuildingError, BuildingLoading]);

    if (toggle) {
        return (
            <Redirect
                to={{
                    pathname: "/app/*/BuildDet",
                    state: {
                        siteId: siteId,
                    },
                }}
            />
        );
    }

    const handleClick = (value: any) => {
        setSiteId(value);
        setToggle((prevState) => !prevState);
    };

    const BuildingSchema = [
        {
            name: "Building",
            Label: "Building",
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => (
                    <span style={{ color: "white" }}>Building Details</span>
                ),
                setCellHeaderProps: () => {
                    return { style: { borderRight: "1px solid #D5D5D5" } };
                },

                setCellProps: () => {
                    return {
                        style: {
                            borderRight: "1px solid #D5D5D5",

                            textAlign: "center",
                        },
                    };
                },
                customBodyRender: (
                    value: String,
                    tableMeta: any,
                    updateValue: any,
                ) => {
                    console.log("table data", value);
                    const currentrowData = data.find(
                        (row: any) => row?.Building === value,
                    );

                    return (
                        <>
                            <Grid
                                container
                                onClick={() =>
                                    handleClick(currentrowData.SiteId)
                                }
                                style={{ cursor: "pointer" }}
                            >
                                <Grid item xs={12}>
                                    <b className="blue font-bolder">
                                        {value}
                                    </b>
                                    <br />
                                </Grid>
                                {/* <Grid item xs={12}> */}
                                    {/* <b className="grey">
                                        {value.number} | 
                                        {value.city}
                                        
                                    </b> */}
                                {/* </Grid> */}
                            </Grid>
                        </>
                    );
                },
            },
        },
        {
            name: "AccNo",
            Label: "AccNo",
            type: "string",
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => (
                    <span style={{ color: "white" }}>Account No.</span>
                ),
                setCellHeaderProps: () => {
                    return { style: { borderRight: "1px solid #D5D5D5" } };
                },

                setCellProps: () => {
                    return {
                        style: {
                            borderRight: "1px solid #D5D5D5",

                            textAlign: "center",
                        },
                    };
                },
                customBodyRender: (
                    value: any,
                    tableMeta: any,
                    updateValue: any,
                ) => {
                    console.log("all", tableMeta);

                    console.log("table data", value);
                    return <b className="grey2 font-bolder">{value}</b>;
                },
            },
        },
        // {
        //     name: "CustLevel",
        //     Label: "CustLevel",
        //     options: {
        //         filter: true,
        //         sort: false,
        //         customHeadLabelRender: () => (
        //             <span style={{ color: "white" }}>Customer Level</span>
        //         ),
        //         setCellHeaderProps: () => {
        //             return { style: { borderRight: "1px solid #D5D5D5" } };
        //         },

        //         setCellProps: () => {
        //             return {
        //                 style: {
        //                     borderRight: "1px solid #D5D5D5",

        //                     textAlign: "center",
        //                 },
        //             };
        //         },
        //         customBodyRender: (
        //             value: any,
        //             tableMeta: any,
        //             updateValue: any,
        //         ) => {
        //             console.log("table data", value);
        //             return <b className="grey2 font-bolder">{value}</b>;
        //         },
        //     },
        // },
        {
            name: "Address",
            Label: "Address",
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => (
                    <span style={{ color: "white" }}>Address</span>
                ),
                setCellHeaderProps: () => {
                    return { style: { borderRight: "1px solid #D5D5D5" } };
                },

                setCellProps: () => {
                    return {
                        style: {
                            borderRight: "1px solid #D5D5D5",

                            textAlign: "center",
                        },
                    };
                },
                customBodyRender: (
                    value:String,
                    tableMeta: any,
                    updateValue: any,
                ) => {
                    console.log("table data", value);
                    const currentrowData = data.find(
                        (row: any) => row?.Address === value,
                    );
                    return (
                        <>
                            {/* <Grid container>
                            <Grid item xs={12}> */}
                            <b className="grey2 font-bolder">
                                { value}
                            </b>
                            <br />
                            {/* </Grid>
                            <Grid item xs={12}> */}
                            <b className="grey">
                                {/* {value.city} | {value.state} |{" "}
                                    {value.pincode} */}
                                {currentrowData.Address2}
                            </b>
                            {/* </Grid>
                        </Grid> */}
                        </>
                    );
                },
            },
        },
        // {
        //     name: "VendorInfo",
        //     Label: "VendorInfo",
        //     options: {
        //         filter: true,
        //         sort: false,
        //         customHeadLabelRender: () => (
        //             <span style={{ color: "white" }}>Vendor Info</span>
        //         ),
        //         setCellHeaderProps: () => {
        //             return { style: { borderRight: "1px solid #D5D5D5" } };
        //         },

        //         setCellProps: () => {
        //             return {
        //                 style: {
        //                     borderRight: "1px solid #D5D5D5",

        //                     textAlign: "center",
        //                 },
        //             };
        //         },
        //         customBodyRender: (
        //             value: {
        //                 name: string;
        //                 company: string;
        //             },
        //             tableMeta: any,
        //             updateValue: any,
        //         ) => {
        //             console.log("table data", value);
        //             return (
        //                 <Grid container>
        //                     <Grid item xs={12}>
        //                         <b className="grey2 font-bolder">
        //                             {value.name}
        //                         </b>
        //                     </Grid>
        //                     <Grid item xs={12}>
        //                         <b className="grey">{value.company}</b>
        //                     </Grid>
        //                 </Grid>
        //             );
        //         },
        //     },
        // },
        {
            name: "TotalAmt",
            Label: "TotalAmt",
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => (
                    <span style={{ color: "white" }}>Total Amount</span>
                ),
                setCellHeaderProps: () => {
                    return {
                        style: {
                            borderRight: "1px solid #D5D5D5",
                            textAlign: "right",
                        },
                    };
                },

                setCellProps: () => {
                    return {
                        style: {
                            borderRight: "1px solid #D5D5D5",

                            textAlign: "right",
                        },
                    };
                },
                customBodyRender: (
                    value: any,
                    tableMeta: any,
                    updateValue: any,
                ) => {
                    console.log("table data", value);
                    return (
                        <b
                            style={{
                                color: "green",
                                // display: "flex",
                                // justifyContent: "flex-end",
                            }}
                        >
                            {currencyFormatter(value, currency)}
                        </b>
                    );
                },
            },
        },
        // {
        //     name: "LastQOS",
        //     Label: "LastQOS",
        //     options: {
        //         customHeadLabelRender: () => (
        //             <span style={{ color: "white" }}>Last QOS</span>
        //         ),
        //         setCellHeaderProps: () => {
        //             return { style: { borderRight: "1px solid #D5D5D5",textAlign: "center" } };
        //         },

        //         setCellProps: () => {
        //             return {
        //                 style: {
        //                     borderRight: "1px solid #D5D5D5",

        //                     textAlign: "center",
        //                 },
        //             };
        //         },
        //         customBodyRender: (
        //             value: any,
        //             tableMeta: any,
        //             updateValue: any,
        //         ) => {
        //             console.log("table data", value);
        //             return <b className="grey2 font-bolder">{value}</b>;
        //         },
        //     },
        // },
    ];
    return (
        <>
            {alert ? handleErrorAlert(errr.code, errr.msg) : <></>}
            <Backdrop className={classes.backdrop} open={BuildingLoading}>
                <CircularProgress color="inherit" />
            </Backdrop>
            <Backdrop
                className={classes.backdrop}
                open={popoverBlack}
            ></Backdrop>
            {data.length ? (
                <Grid container style={{ marginTop: "2%" }}>
                    <Grid item xs={12}>
                        <MuiDatatables
                            title={""}
                            header={BuildingSchema}
                            detail={[data, setData]}
                            hideAddButton={true}
                            // handleAddSideEffect={handleAddSideEffect}
                            // handleDeleteSideEffect={handleDeleteSideEffect}
                            initRow={false}
                        />
                    </Grid>
                </Grid>
            ) : (
                <img
                    src={NoBuildings}
                    alt="No Buildings"
                    style={{ paddingTop: "4%", width: "95vh" }}
                />
            )}
        </>
    );
};
const mapStateToProps = (state: any) => {
    return {
        currentContext: state.context?.userDefaults,
        userName: state.context?.name,
        selectedCustomer: state.selectedCustomer,
        currency: state.currency,
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        setBreadcrumbsData: (data: any) => {
            dispatch(storeBreadcrumbs(data));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Body);
