const FileUploadcomboQuery = `
    query FileUploadcomboQuery($supplierCode: String!, $callingFrom: String!,$ou:Int,$langId:Int,$role:String){
      FUcombo:GetFileDetailsAndCombo(supplierCode: $supplierCode, callingFrom: $callingFrom,ou:$ou,langId:$langId,role:$role) {
        desc:fileType
        value:fileType
        
      }
    }
`;

export default FileUploadcomboQuery;
