const contractDetailsQuerySV = `
mutation(
    $entity:String
    $ou:Int
    $service:String
    $User:String
    $languageId:Int
    $VendorCode:String
    $Vendorname:String
    $contractno:String
    $merrorid:String
){
    ContractDetailsSV(
        entity:$entity
        ou:$ou
        service:$service
        User:$User
        languageId:$languageId
        VendorCode:$VendorCode
        Vendorname:$Vendorname
        contractno:$contractno
        merrorid:$merrorid
    ){
        PurchaseDate,
        VolumePurchased,
        TotalCost,
        Royalty ,
        InitialCash,
        InitialCashPaymentDate,
        TargetFulfillment,
        VolumeOwed,
    }
}
`;

export default contractDetailsQuerySV;
