import { Grid, Paper } from "@material-ui/core";
import Alert from "Common/Alerts/Alerts";
import BackdropLoading from "Common/Backdrop/Backdrop";
import Icons from "Common/Icons/Icons";
import MuiDatatables from "Common/Mui-DataTables/MuiDatatables";
import SwipeableDrawerComp from "Common/SideDrawer/SideDrawer";
import currencyFormatter from "Common/Utils/currencyFormatter";
import ApprovalVen from "Components/Dashboard/ApprovalVen";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
    pendingApprovalDetailsQuery,
    pendingApprovalHeaderQuery,
} from "ReactQuery/Query";
import useGetPosts from "ReactQuery/reactQuery";
import { storeBreadcrumbs } from "stateManagement/actions/Common/breadcrumbs";
import Sidedraw from "./Sidedraw";

interface DashCards {
    desc: string;
    value: string;
    visible: boolean;
    frequent: boolean;
    detail: {
        desc: string;
        code: string;
        value: number;
        color: string;
    }[];
}

const Body = (props: any) => {
    const {
        currentContext,
        userName,
        selectedVendor,
        currency,
        setBreadcrumbsData,
        userReadOnlyAccess
    } = props;

    setBreadcrumbsData([
        {
            value: "Vendor Contract",
            href: "/app/*/Contracts",
        },
        {
            value: "Pending Approval",
            href: "/app/*/PENDAPPROVAL",
        },
    ]);
    const [header, setHeader] = useState<DashCards>();
    const [active, setActive] = useState({ code: "", desc: "" });
    const [showTable, setShowTable] = useState<boolean>(false);
    const [approvalKey, setApprovalKey] = useState(false);
    const [selectedData, setSelectedData] = useState();

    const handleActive = (element: any) => {
        setActive({ code: element?.code, desc: element?.desc });
        setShowTable(false);
        setPendingTableData([]);
    };
    const [openSideDraw, setOpenSideDraw] = useState(false);
    const [pendingTableData, setPendingTableData] = useState<any[]>([]);

    const pendingApprovalSchema = [
        {
            name: "Offernumber",
            Label: "Offer Number",
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => (
                    <span style={{ color: "white" }}>Offer Number</span>
                ),
                setCellHeaderProps: () => {
                    return { style: { borderRight: "1px solid #D5D5D5" } };
                },
                setCellProps: () => {
                    return {
                        style: {
                            borderRight: "1px solid #D5D5D5",
                            textAlign: "center",
                        },
                    };
                },
                customBodyRender: (
                    value: any,
                    tableMeta: any,
                    updateValue: any,
                ) => {
                    return <b>{value}</b>;
                },
            },
        },
        {
            name: "suppname",
            Label: "Franchisee",

            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => (
                    <span style={{ color: "white" }}>Franchisee</span>
                ),
                setCellHeaderProps: () => {
                    return { style: { borderRight: "1px solid #D5D5D5" } };
                },
                setCellProps: () => {
                    return {
                        style: {
                            borderRight: "1px solid #D5D5D5",
                            textAlign: "center",
                        },
                    };
                },
                customBodyRender: (
                    value: any,
                    tableMeta: any,
                    updateValue: any,
                ) => {
                    return <b>{value}</b>;
                },
            },
        },
        {
            name: "Offerdate",
            Label: "Offer Date",

            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => (
                    <span style={{ color: "white" }}>Offer Date</span>
                ),
                setCellHeaderProps: () => {
                    return { style: { borderRight: "1px solid #D5D5D5" } };
                },
                setCellProps: () => {
                    return {
                        style: {
                            borderRight: "1px solid #D5D5D5",
                            textAlign: "center",
                        },
                    };
                },
                customBodyRender: (
                    value: any,
                    tableMeta: any,
                    updateValue: any,
                ) => {
                    return <b>{value}</b>;
                },
            },
        },
        {
            name: "startdate",
            Label: "Start Date",

            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => (
                    <span style={{ color: "white" }}>Start Date</span>
                ),
                setCellHeaderProps: () => {
                    return { style: { borderRight: "1px solid #D5D5D5" } };
                },
                setCellProps: () => {
                    return {
                        style: {
                            borderRight: "1px solid #D5D5D5",
                            textAlign: "center",
                        },
                    };
                },
                customBodyRender: (
                    value: any,
                    tableMeta: any,
                    updateValue: any,
                ) => {
                    return <b>{value}</b>;
                },
            },
        },
        {
            name: "custname",
            Label: "Customer",

            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => (
                    <span style={{ color: "white" }}>Customer</span>
                ),
                setCellHeaderProps: () => {
                    return { style: { borderRight: "1px solid #D5D5D5" } };
                },
                setCellProps: () => {
                    return {
                        style: {
                            borderRight: "1px solid #D5D5D5",
                            textAlign: "center",
                        },
                    };
                },
                customBodyRender: (
                    value: any,
                    tableMeta: any,
                    updateValue: any,
                ) => {
                    return <b>{value}</b>;
                },
            },
        },
        {
            name: "Customercontract",
            Label: "Customer Contract",

            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => (
                    <span style={{ color: "white" }}>Customer Contract</span>
                ),
                setCellHeaderProps: () => {
                    return { style: { borderRight: "1px solid #D5D5D5" } };
                },
                setCellProps: () => {
                    return {
                        style: {
                            borderRight: "1px solid #D5D5D5",
                            textAlign: "center",
                        },
                    };
                },
                customBodyRender: (
                    value: any,
                    tableMeta: any,
                    updateValue: any,
                ) => {
                    return <b>{value}</b>;
                },
            },
        },
        {
            name: "amountoffer",
            Label: "Amount Offered",

            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => (
                    <span style={{ color: "white" }}>Amount Offered</span>
                ),
                setCellHeaderProps: () => {
                    return { style: { borderRight: "1px solid #D5D5D5" } };
                },
                setCellProps: () => {
                    return {
                        style: {
                            borderRight: "1px solid #D5D5D5",
                            textAlign: "right",
                        },
                    };
                },
                customBodyRender: (
                    value: any,
                    tableMeta: any,
                    updateValue: any,
                ) => {
                    return (
                        <b className="green">
                            {currencyFormatter(value, currency)}
                        </b>
                    );
                },
            },
        },
        {
            name: "",
            Label: "confirm",

            options: {
                customHeadLabelRender: () => (
                    <span style={{ color: "white" }}></span>
                ),
                setCellHeaderProps: () => {
                    return { style: { borderRight: "1px solid #D5D5D5" } };
                },
                setCellProps: () => {
                    return {
                        style: {
                            borderRight: "1px solid #D5D5D5",
                            textAlign: "center",
                        },
                    };
                },
                customBodyRender: (
                    value: any,
                    tableMeta: any,
                    updateValue: any,
                ) => {
                    return (
                        <span
                            className="pointer"
                            onClick={() => {
                                if(userReadOnlyAccess){

                                    setApprovalKey(true);
                                    setSelectedData(
                                        pendingTableData[tableMeta.rowIndex],
                                    );
                                    setOpenSideDraw(true);
                                }
                            }}
                        >
                            <Icons Icon="greenTableTick" />
                        </span>
                    );
                },
            },
        },
        {
            name: "",
            Label: "reject",
            options: {
                customHeadLabelRender: () => (
                    <span style={{ color: "white" }}></span>
                ),
                setCellHeaderProps: () => {
                    return { style: { borderRight: "1px solid #D5D5D5" } };
                },
                setCellProps: () => {
                    return {
                        style: {
                            borderRight: "1px solid #D5D5D5",
                            textAlign: "center",
                        },
                    };
                },
                customBodyRender: (
                    value: any,
                    tableMeta: any,
                    updateValue: any,
                ) => {
                    return (
                        <span
                            className="pointer"
                            onClick={() => {
                                if(userReadOnlyAccess){

                                    setApprovalKey(false);
                                    setSelectedData(
                                        pendingTableData[tableMeta.rowIndex],
                                    );
                                    setOpenSideDraw(true);
                                }
                            }}
                        >
                            <Icons Icon="redTableTick" />
                        </span>
                    );
                },
            },
        },
    ];
    const declinedApprovalSchema = [
        {
            name: "Offernumber",
            Label: "Offer Number",

            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => (
                    <span style={{ color: "white" }}>Offer Number</span>
                ),
                setCellHeaderProps: () => {
                    return { style: { borderRight: "1px solid #D5D5D5" } };
                },
                setCellProps: () => {
                    return {
                        style: {
                            borderRight: "1px solid #D5D5D5",
                            textAlign: "center",
                        },
                    };
                },
                customBodyRender: (
                    value: any,
                    tableMeta: any,
                    updateValue: any,
                ) => {
                    return <b>{value}</b>;
                },
            },
        },
        {
            name: "suppname",
            Label: "Franchisee",

            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => (
                    <span style={{ color: "white" }}>Franchisee</span>
                ),
                setCellHeaderProps: () => {
                    return { style: { borderRight: "1px solid #D5D5D5" } };
                },
                setCellProps: () => {
                    return {
                        style: {
                            borderRight: "1px solid #D5D5D5",
                            textAlign: "center",
                        },
                    };
                },
                customBodyRender: (
                    value: any,
                    tableMeta: any,
                    updateValue: any,
                ) => {
                    return <b>{value}</b>;
                },
            },
        },
        {
            name: "Offerdate",
            Label: "Offer Date",

            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => (
                    <span style={{ color: "white" }}>Offer Date</span>
                ),
                setCellHeaderProps: () => {
                    return { style: { borderRight: "1px solid #D5D5D5" } };
                },
                setCellProps: () => {
                    return {
                        style: {
                            borderRight: "1px solid #D5D5D5",
                            textAlign: "center",
                        },
                    };
                },
                customBodyRender: (
                    value: any,
                    tableMeta: any,
                    updateValue: any,
                ) => {
                    return <b>{value}</b>;
                },
            },
        },
        {
            name: "startdate",
            Label: "Start Date",

            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => (
                    <span style={{ color: "white" }}>Start Date</span>
                ),
                setCellHeaderProps: () => {
                    return { style: { borderRight: "1px solid #D5D5D5" } };
                },
                setCellProps: () => {
                    return {
                        style: {
                            borderRight: "1px solid #D5D5D5",
                            textAlign: "center",
                        },
                    };
                },
                customBodyRender: (
                    value: any,
                    tableMeta: any,
                    updateValue: any,
                ) => {
                    return <b>{value}</b>;
                },
            },
        },
        {
            name: "custname",
            Label: "Customer",

            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => (
                    <span style={{ color: "white" }}>Customer</span>
                ),
                setCellHeaderProps: () => {
                    return { style: { borderRight: "1px solid #D5D5D5" } };
                },
                setCellProps: () => {
                    return {
                        style: {
                            borderRight: "1px solid #D5D5D5",
                            textAlign: "center",
                        },
                    };
                },
                customBodyRender: (
                    value: any,
                    tableMeta: any,
                    updateValue: any,
                ) => {
                    return <b>{value}</b>;
                },
            },
        },
        {
            name: "Customercontract",
            Label: "Customer Contract",

            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => (
                    <span style={{ color: "white" }}>Customer Contract</span>
                ),
                setCellHeaderProps: () => {
                    return { style: { borderRight: "1px solid #D5D5D5" } };
                },
                setCellProps: () => {
                    return {
                        style: {
                            borderRight: "1px solid #D5D5D5",
                            textAlign: "center",
                        },
                    };
                },
                customBodyRender: (
                    value: any,
                    tableMeta: any,
                    updateValue: any,
                ) => {
                    return <b>{value}</b>;
                },
            },
        },
        {
            name: "amountoffer",
            Label: "Amount Offered",

            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => (
                    <span style={{ color: "white" }}>Amount Offered</span>
                ),
                setCellHeaderProps: () => {
                    return { style: { borderRight: "1px solid #D5D5D5" } };
                },
                setCellProps: () => {
                    return {
                        style: {
                            borderRight: "1px solid #D5D5D5",
                            textAlign: "right",
                        },
                    };
                },
                customBodyRender: (
                    value: any,
                    tableMeta: any,
                    updateValue: any,
                ) => {
                    return (
                        <b className="green">
                            {currencyFormatter(value, currency)}
                        </b>
                    );
                },
            },
        },
        {
            name: "desclineReason",
            Label: "Reason",

            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => (
                    <span style={{ color: "white" }}>Reason</span>
                ),
                setCellHeaderProps: () => {
                    return { style: { borderRight: "1px solid #D5D5D5" } };
                },
                setCellProps: () => {
                    return {
                        style: {
                            borderRight: "1px solid #D5D5D5",
                            textAlign: "center",
                        },
                    };
                },
                customBodyRender: (
                    value: any,
                    tableMeta: any,
                    updateValue: any,
                ) => {
                    return <b>{value}</b>;
                },
            },
        },
    ];
    const [alert, setAlert] = useState(false);
    const [errr, seterr] = useState({
        code: 0,
        msg: "",
    });
    const handleErrorAlert = (code: number, message: string) => {
        Alert({
            statusCode: code,
            message: message,
            willClose: () => {
                setAlert(false);
                seterr({ code: 0, msg: "" });
            },
        });
    };
    const {
        data: pendingApprovalHeaderData,
        error: pendingApprovalHeaderError,
        isLoading: pendingApprovalHeaderLoading,
    } = useGetPosts({
        name: "pendingApprovalHeaderQuery",
        query: pendingApprovalHeaderQuery,
        variables: {
            entity: "OW_Vendor_portal_pocount",
            ou: currentContext?.ouId,
            service: "",
            User: selectedVendor?.loginUser,
            languageId: currentContext?.langId,
            vendorCode: selectedVendor?.supplierCode,
            vendorname: selectedVendor?.supplierName,
            merrorid: "",
        },
    });

    useEffect(() => {
        if (pendingApprovalHeaderData?.PendingApprovalHeaderQueryVEN) {
            setHeader(pendingApprovalHeaderData?.PendingApprovalHeaderQueryVEN);
            setActive({
                code:
                    pendingApprovalHeaderData?.PendingApprovalHeaderQueryVEN
                        ?.detail[0]?.code,
                desc:
                    pendingApprovalHeaderData?.PendingApprovalHeaderQueryVEN
                        ?.detail[0]?.desc,
            });
        }
    }, [pendingApprovalHeaderData]);

    useEffect(() => {
        if (pendingApprovalHeaderError && !pendingApprovalHeaderLoading) {
            const data = JSON.parse(JSON.stringify(pendingApprovalHeaderError));
            const errmsg = data?.response?.errors[0].message;
            seterr({
                code: 400,
                msg: errmsg,
            });
            setAlert(true);
        }
    }, [pendingApprovalHeaderError, pendingApprovalHeaderLoading]);

    const {
        data: pendingApprovalDetailsData,
        error: pendingApprovalDetailsError,
        isLoading: pendingApprovalDetailsLoading,
    } = useGetPosts({
        name: `pendingApprovalDetailsQuery_${active.code}`,
        query: pendingApprovalDetailsQuery,
        variables: {
            entity: "OW_Vendor_portal_getpostatus",
            ou: currentContext?.ouId,
            service: "",
            User: selectedVendor?.loginUser,
            languageId: currentContext?.langId,
            vendorCode: selectedVendor?.supplierCode,
            vendorname: selectedVendor?.supplierName,
            status: active.code,
            merrorid: "",
        },
        skip: !active.code,
    });

    useEffect(() => {
        // const PAD=[
        //     {
        //         "Customercontract": null,
        //         "Offerdate": "",
        //         "Offernumber": null,
        //         "amountoffer": null,
        //         "custname": null,
        //         "startdate": "",
        //         "suppname": null,
        //         "declineReason": null,
        //         "custcode": null,
        //         "guid": null,
        //         "status": null,
        //         "supplierCode": null,
        //         "TypeOfService": null
        //     }
        // ]
        if (pendingApprovalDetailsData?.pendingApprovalDetails) {
            
                setPendingTableData(
                    pendingApprovalDetailsData?.pendingApprovalDetails,
                );
        }
    }, [pendingApprovalDetailsData]);


    useEffect(() => {
        if (pendingApprovalDetailsError && !pendingApprovalDetailsLoading) {
            const data = JSON.parse(
                JSON.stringify(pendingApprovalDetailsError),
            );
            const errmsg = data?.response?.errors[0].message;
            // seterr({
            //     code: 400,
            //     msg: errmsg,
            // });
            // setAlert(true);
            //later uncomment it when this sp is working properly check with mohan
        }
    }, [pendingApprovalDetailsError, pendingApprovalDetailsLoading]);

    return (
        <> 
        {alert ? handleErrorAlert(errr.code, errr.msg) : <></>}
        <Grid
            container
            spacing={2}
            alignItems="center"
            style={{ marginTop: "10px" }}
        >
            <BackdropLoading
                openStates={
                    pendingApprovalDetailsLoading ||
                    pendingApprovalHeaderLoading
                }
            />
            
            <Grid item xs={12} sm={4} md={4}>
                <Paper elevation={4}>
                    <Grid
                        container
                        spacing={2}
                        style={{
                            padding: "2% 0% 0% 0%",
                        }}
                        justifyContent="space-around"
                    >
                        {header?.detail.map((element) => {
                            return (
                                <Grid
                                    item
                                    xs={4}
                                    sm={4}
                                    className={
                                        active.desc === element?.desc
                                            ? `${element?.color}_border_bottom pointer`
                                            : "pointer"
                                    }
                                    onClick={() => handleActive(element)}
                                >
                                    <Grid container spacing={1}>
                                        <Grid item xs={12} sm={12}>
                                            <span
                                                className={
                                                    active.desc ===
                                                    element?.desc
                                                        ? `${element?.color} font-biggest font-boldest`
                                                        : `grey font-biggest font-boldest`
                                                }
                                            >
                                                {element?.value}
                                            </span>
                                        </Grid>
                                        <Grid item xs={12} sm={12}>
                                            <span
                                                className={
                                                    active.desc ===
                                                    element?.desc
                                                        ? `font-boldest `
                                                        : "grey "
                                                }
                                            >
                                                {element?.desc}
                                            </span>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            );
                        })}
                    </Grid>
                </Paper>
            </Grid>
            <Grid item xs={12} />
            <Grid item xs={12}>
                <MuiDatatables
                    header={
                        active.code === "P"
                            ? pendingApprovalSchema
                            : declinedApprovalSchema
                    }
                    detail={[pendingTableData, setPendingTableData]}
                    tableHeight="320px"
                    rowsPerPage={10}
                />
            </Grid>
            <SwipeableDrawerComp
                drawerState={[openSideDraw, setOpenSideDraw]}
                header={"Approval"}
                headerColor={"grey2"}
                Child={
                    <ApprovalVen
                        approvalKey={approvalKey}
                        PendingApprovalData={selectedData}
                    />
                }
            />
        </Grid>
        </>
    );
};

const mapStateToProps = (state: any) => {
    return {
        currentContext: state.context?.userDefaults,
        userName: state.context?.name,
        selectedVendor: state.selectedVendor,
        currency: state.currency,
        userReadOnlyAccess: state?.userReadOnlyAccess,
    };
};
const mapDispatchToProps = (dispatch: any) => {
    return {
        setBreadcrumbsData: (data: any) => {
            dispatch(storeBreadcrumbs(data));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Body);
