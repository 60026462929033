const StatusComboQuery = `
    mutation(
        $entity: String
        $ou: Int
        $service: String
        $user: String
        $langId: Int
        $screen: String
        $tranType: String
        $merrorid: String
    ){
        statusCombo(
            entity: $entity
            ou: $ou
            service: $service
            user: $user
            langId: $langId
            screen: $screen
            tranType: $tranType
            merrorid: $merrorid
        )
        {
            desc
            value
        }
    }
`;

export default StatusComboQuery;
