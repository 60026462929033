const invoiceCardHeaderQuery = `
mutation(
    $entity: String
    $ou: Int
    $service: String
    $User: String
    $languageId: Int
    $Status:String
    $customerCode: String
    $customerName: String
    $siteId: String
    $buildName: String
    $InvoiceNo: String
    $merrorid: String
    $contractid:String
    ){
    invoiceContactheadervals(
        entity: $entity
        ou: $ou
        service: $service
        User: $User
        languageId: $languageId
        Status:$Status
        customerCode: $customerCode
        customerName: $customerName
        siteId: $siteId
        buildName: $buildName
        InvoiceNo: $InvoiceNo
        contractid:$contractid
        merrorid: $merrorid
  
    ) {
        InvoiceNo
        ContractID
        Buildingadd{
        Addressdtl
        state
        city
        pin
     }
    
     status
     PONumber 
     InvoiceDate
     DueDate
     TotalAmount
     Balance
     PaymentStatus
        
       
    }
  }
  `;

export default invoiceCardHeaderQuery;