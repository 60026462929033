const InvoiceStatusCUS = `
query Query (
$DocFlag:String,
$DocNoFrom :String,
$DocNoTo:String,
$DocStatusIn:String,
$DrDocCurrencyIn:String,
$DrDocFinanceBookIn:String,
$DrDocGroupIn:String,
$DrDocNoIn:String,
$DrDocTypeIn:String,
$DueAmountFrom:String,
$DueAmountTo:String,
$DueDateFrom:String,
$DueDateTo:String,
$DueFlag:String,
$HBLHAWBNoFrom:String,
$HBLHAWBNoIn:String,
$HBLHAWBNoTo:String,
$InstrumentNo:String,
$LCNumberIn:String,
$MBLMAWBFrom:String,
$MBLMAWBNoIn:String,
$MBLMAWBTo:String,
$OrgUnitIn:String,
$OverduedaysFrom:String,
$OverduedaysTo:String,
$ProjectCodeIn:String,
$ProjectOUIn:String,
$ReceiptCategoryIn:String,
$SalesPersoncodeIn:String,
$SalesPersonNameIn:String,
$AddlFieldIn1:String,
$AddlFieldIn2:String,
$AddlFieldIn3:String,
$AddlFieldIn4:String,
$AddlFieldIn5:String,
$BookingNoFrom:String,
$BookingNoIn:String,
$BookingNoTo:String,
$BusinessUnitIn:String,
$CollectorCode:String,
$CompanyIn:String,
$CrDocCurrencyIn:String,
$CrDocFinanceBookIn:String,
$CrDocGroupIn:String,
$CRDocNoIn:String,
$CrDocTypeIn:String,
$CustAddID:String,
$CustomerCodeIn:String,
$CustomerGroupIn:String,
$Customerhierarchy:String,
$CustomerNameIn:String,
$DocAmountFrom:String,
$DocAmountTo:String,
$DocDateFrom:String,
$DocDateTo:String,


$CustomerCodeFrom:String
$CustomerCodeTo:String

){
    InvoiceStatusCUS(
     



      DocFlag:$DocFlag,
      DocNoFrom :$DocNoFrom 
      DocNoTo:$DocNoTo
      DocStatusIn:$DocStatusIn
      DrDocCurrencyIn:$DrDocCurrencyIn
      DrDocFinanceBookIn:$DrDocFinanceBookIn
      DrDocGroupIn:$DrDocGroupIn
      DrDocNoIn:$DrDocNoIn
      DrDocTypeIn:$DrDocTypeIn
      DueAmountFrom:$DueAmountFrom
      DueAmountTo:$DueAmountTo
      DueDateFrom:$DueDateFrom
      DueDateTo:$DueDateTo
      DueFlag:$DueFlag
      HBLHAWBNoFrom:$HBLHAWBNoFrom
      HBLHAWBNoIn:$HBLHAWBNoIn
      HBLHAWBNoTo:$HBLHAWBNoTo
      InstrumentNo:$InstrumentNo
      LCNumberIn:$LCNumberIn
      MBLMAWBFrom:$MBLMAWBFrom
      MBLMAWBNoIn:$MBLMAWBNoIn
      MBLMAWBTo:$MBLMAWBTo
      OrgUnitIn:$OrgUnitIn
      OverduedaysFrom:$OverduedaysFrom
      OverduedaysTo:$OverduedaysTo
      ProjectCodeIn:$ProjectCodeIn
      ProjectOUIn:$ProjectOUIn
      ReceiptCategoryIn:$ReceiptCategoryIn
      SalesPersoncodeIn:$SalesPersoncodeIn
      SalesPersonNameIn:$SalesPersonNameIn
      AddlFieldIn1:$AddlFieldIn1
      AddlFieldIn2:$AddlFieldIn2
      AddlFieldIn3:$AddlFieldIn3
      AddlFieldIn4:$AddlFieldIn4
      AddlFieldIn5:$AddlFieldIn5
      BookingNoFrom:$BookingNoFrom
      BookingNoIn:$BookingNoIn
      BookingNoTo:$BookingNoTo
      BusinessUnitIn:$BusinessUnitIn
      CollectorCode:$CollectorCode
      CompanyIn:$CompanyIn
      CrDocCurrencyIn:$CrDocCurrencyIn
      CrDocFinanceBookIn:$CrDocFinanceBookIn
      CrDocGroupIn:$CrDocGroupIn
      CRDocNoIn:$CRDocNoIn
      CrDocTypeIn:$CrDocTypeIn
      CustAddID:$CustAddID
      CustomerCodeIn:$CustomerCodeIn
      CustomerGroupIn:$CustomerGroupIn
      Customerhierarchy:$Customerhierarchy
      CustomerNameIn:$CustomerNameIn
      DocAmountFrom:$DocAmountFrom
      DocAmountTo:$DocAmountTo
      DocDateFrom:$DocDateFrom
      DocDateTo:$DocDateTo    

      CustomerCodeFrom:$CustomerCodeFrom
      CustomerCodeTo:$CustomerCodeTo



      

    ) {
       lable
       value
       partVal
       color
       valuedisp
       borderColor
     }
   
  }
  `;

export default InvoiceStatusCUS;
