const Configurations: any = {
    development: {
        getBaseRoute: (): string => "/",
        getWebAppLocation: (): string => "http://localhost:3000",
        getAppAPILocation: (): string =>
            (window as any)["runtimeWebConfig"].REACT_APPAPI_URL,
        getWebAppSessionValidDuration: (): number =>
            (window as any)["runtimeWebConfig"].REACT_CHECK_VALID_SESSION,
        getVendorKey: (): any =>
            (window as any)["runtimeWebConfig"].PORTAL_KEY.vendor,
        getCustomerKey: (): any =>
            (window as any)["runtimeWebConfig"].PORTAL_KEY.customer,
        getAdminKey: (): any =>
            (window as any)["runtimeWebConfig"].PORTAL_KEY.admin,
        getCustomerPortalPipeline: (): string =>
            (window as any)["runtimeWebConfig"].REACT_HUBSPOT_PIPELINE
                ?.customer,
        getVendorPortalPipeline: (): string =>
            (window as any)["runtimeWebConfig"].REACT_HUBSPOT_PIPELINE?.vendor,
    },
    production: {
        getBaseRoute: (): string => "/",
        getWebAppLocation: (): string => window.location.origin,
        getAppAPILocation: (): string =>
            (window as any)["runtimeWebConfig"].REACT_APPAPI_URL,
        getWebAppSessionValidDuration: (): number =>
            (window as any)["runtimeWebConfig"].REACT_CHECK_VALID_SESSION,
        getVendorKey: (): any =>
            (window as any)["runtimeWebConfig"].PORTAL_KEY.vendor,
        getCustomerKey: (): any =>
            (window as any)["runtimeWebConfig"].PORTAL_KEY.customer,
        getAdminKey: (): any =>
            (window as any)["runtimeWebConfig"].PORTAL_KEY.admin,
        getCustomerPortalPipeline: (): string =>
            (window as any)["runtimeWebConfig"].REACT_HUBSPOT_PIPELINE
                ?.customer,
        getVendorPortalPipeline: (): string =>
            (window as any)["runtimeWebConfig"].REACT_HUBSPOT_PIPELINE?.vendor,
    },
};

interface IConfiguration {
    getWebAppLocation: () => string;
    getAppAPILocation: () => string;
    getWebAppSessionValidDuration: () => number;
    getenv: () => string;
    getVendorKey: () => any;
    getCustomerKey: () => any;
    getAdminKey: () => any;
    getCustomerPortalPipeline: () => string;
    getVendorPortalPipeline: () => string;
}

/**
 * Configuration details for the Web application
 */
export default class Configuration implements IConfiguration {
    private config: any;
    environment: any;
    constructor(environment: any) {
        this.config = Configurations[environment];
        this.environment = environment;
    }

    getWebAppLocation = (): string => {
        return this.config.getWebAppLocation();
    };

    getAppAPILocation = (): string => {
        return this.config.getAppAPILocation();
    };

    getWebAppSessionValidDuration = (): number => {
        return this.config.getWebAppSessionValidDuration();
    };

    getenv = () => {
        return this.environment;
    };

    getVendorKey = () => {
        return this.config.getVendorKey();
    };
    getCustomerKey = () => {
        return this.config.getCustomerKey();
    };
    getAdminKey = () => {
        return this.config.getAdminKey();
    };
    static getConfiguration = (): IConfiguration => {
        return _config;
    };
    getCustomerPortalPipeline = (): string => {
        return this.config.getCustomerPortalPipeline();
    };
    getVendorPortalPipeline = (): string => {
        return this.config.getVendorPortalPipeline();
    };
}

let _config: IConfiguration = new Configuration(process.env.NODE_ENV);