import { useLocation } from "react-router";
import { Grid, Paper, Backdrop, CircularProgress } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import MUIButton from "Common/Button/Button";
import ButtonGroups from "Common/ButtonGroup/ButtonGroup";
import { connect } from "react-redux";
import useGetPosts from "ReactQuery/reactQuery";
import AssignmentCards from "./AssigmentRefusal";
import MuiDatatables from "Common/Mui-DataTables/MuiDatatables";
import AmortizationSchema from "./Schema/AmortizationSchema";
import ReplacementSchema from "./Schema/ReplacementSchema";
import Icons from "Common/Icons/Icons";
import { makeStyles, Theme } from "@material-ui/core/styles";
import {
    AssignmentListQuerySV,
    AmorizationListQuerySV,
    RefusalListQuerySV,
    ReplacementListQuerySV,
    contractCardQuerySV,
    contractDetailsQuerySV,
    RefusalCardQuerySV,
} from "ReactQuery/Query";
import Alert from "Common/Alerts/Alerts";
import currencyFormatter from "Common/Utils/currencyFormatter";
import { Redirect } from "react-router-dom";
import { storeBreadcrumbs } from "stateManagement/actions/Common/breadcrumbs";

interface ContractCards {
    ContractID: string;
    Building: string;
    VendorContract: string;
    Revision: number;
    Contract: number;
    StartEndDate: string;
}
interface ContractDet {
    PurchaseDate: string;
    VolumePurchased: number;
    TotalCost: number;
    Royalty: string;
    InitialCash: string;
    InitialCashPaymentDate: string;
    TargetFulfillment: number;
    VolumeOwed: number;
}
interface RefusalCardDet {
    VolumePurchased: number;
    Fulfilled: number;
    TotalAssigned: number;
    TotalAssignedToday: number;
    Currency: string;
    TotalRefusals: number;
    Refused: number;
    TotalRefusedPastThree: number;
    TowardsFulfillment: number;
}
interface AssignmentProps {
    desc: string;
    value: string;
}

const useStyles = makeStyles((theme: Theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: "#fff",
    },
}));
interface BodyProps {
    currentContext: any;
    userName: any;
    selectedVendor: any;
    currency: any;
    setBreadcrumbsData: any;
}
const ContractDetailsSV = (props: BodyProps) => {
    const contractData = useLocation<any>();
    const contractNo: any = contractData?.state?.contractSelected;
    const redirectedFrom: any = contractData?.state?.from ?? false;
    const classes = useStyles();
    const {
        currentContext,
        userName,
        selectedVendor,
        currency,
        setBreadcrumbsData,
    } = props;

    const childbrcrumbs=[
        {
            value: "Current Assignment",
            href: "/app/*/CurrentAssignment",
        },
        {
            value: "Vendor Details",
            href: "/app/*/VendorDetails",
            data:contractData?.state?.poNo ??"",
        },
        {
            value: "Vendor Contract Details",
            href: "/app/*/CONTDETSV",
        },
    ]
    setBreadcrumbsData(
        redirectedFrom==="CurrnAssign"? childbrcrumbs:
       [ {
            value: "Vendor-Contract",
            href: "/app/*/Contracts",
            
        },
        {
            value: "Vendor Contract Details",
            href: "/app/*/CONTDETSV",
        },
    ]);
    const [assignmentDetails, setAssignmentDetails] = useState({
        sideDrawState: false,
        clickedData: "",
    });
    const navlist = [
        {
            desc: "Assignment",
            value: "Assignment",
        },
        {
            desc: "Refusals / Fulfillment",
            value: "Refusals / Fulfillment",
        },
        // {
        //     desc: "Replacement",
        //     value: "Replacement",
        // },
    ];

    const controlList = [
        {
            desc: "General",
            value: "General",
        },
        {
            desc: "Assignment/Refusals/Fulfillment",
            value: "Assignment/Refusals/Fulfillment",
        },
        // {
        //     desc: "Loan Summary",
        //     value: "Amortization",
        // },
    ];
    const RefusalSchema = [
        {
            name: "CustomerContract",
            Label: "CustomerContract",
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => (
                    <span style={{ color: "white" }}>Customer Contract</span>
                ),
                setCellHeaderProps: () => {
                    return { style: { borderRight: "1px solid #D5D5D5" } };
                },
                setCellProps: () => {
                    return {
                        style: {
                            borderRight: "1px solid #D5D5D5",
                            textAlign: "center",
                        },
                    };
                },
                customBodyRender: (
                    value: any,
                    tableMeta: any,
                    updateValue: any,
                ) => {
                    console.log("table data", value, tableMeta.rowData);
                    return <b style={{ color: "#0073E6" }}>{value}</b>;
                },
            },
        },
        {
            name: "OfferDate",
            Label: "OfferDate",
            type: "string",
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => (
                    <span style={{ color: "white" }}>Offer Date</span>
                ),
                setCellHeaderProps: () => {
                    return { style: { borderRight: "1px solid #D5D5D5" } };
                },
                setCellProps: () => {
                    return {
                        style: {
                            borderRight: "1px solid #D5D5D5",
                            textAlign: "center",
                        },
                    };
                },
                customBodyRender: (
                    value: any,
                    tableMeta: any,
                    updateValue: any,
                ) => {
                    console.log("table data", value);
                    return <b style={{ color: "#5C677D" }}>{value}</b>;
                },
            },
        },
        {
            name: "RefusalDate",
            Label: "RefusalDate",
            type: "string",
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => (
                    <span style={{ color: "white" }}>Refusal Date</span>
                ),
                setCellHeaderProps: () => {
                    return { style: { borderRight: "1px solid #D5D5D5" } };
                },
                setCellProps: () => {
                    return {
                        style: {
                            borderRight: "1px solid #D5D5D5",
                            textAlign: "center",
                        },
                    };
                },
                customBodyRender: (
                    value: any,
                    tableMeta: any,
                    updateValue: any,
                ) => {
                    console.log("table data", value);
                    return <b style={{ color: "#5C677D" }}>{value}</b>;
                },
            },
        },

        {
            name: "AmountOffer",
            Label: "AmountOffer",
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => (
                    <span style={{ color: "white" }}> Amount Offer</span>
                ),
                setCellHeaderProps: () => {
                    return { style: { borderRight: "1px solid #D5D5D5" } };
                },
                setCellProps: () => {
                    return {
                        style: {
                            borderRight: "1px solid #D5D5D5",
                            textAlign: "center",
                        },
                    };
                },
                customBodyRender: (
                    value: any,
                    tableMeta: any,
                    updateValue: any,
                ) => {
                    console.log("table data", value);
                    return (
                        <b
                            style={{
                                color: "#00AE50",
                                display: "flex",
                                justifyContent: "flex-end",
                            }}
                        >
                            {currencyFormatter(value, currency)}
                        </b>
                    );
                },
            },
        },
        {
            name: "Fulfillment",
            Label: "Fulfillment",
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => (
                    <span style={{ color: "white" }}> Fulfillment%</span>
                ),
                setCellHeaderProps: () => {
                    return { style: { borderRight: "1px solid #D5D5D5" } };
                },
                setCellProps: () => {
                    return {
                        style: {
                            borderRight: "1px solid #D5D5D5",
                            textAlign: "center",
                        },
                    };
                },
                customBodyRender: (
                    value: any,
                    tableMeta: any,
                    updateValue: any,
                ) => {
                    console.log("table data", value);
                    return (
                        <b
                            style={{
                                color: "#00AE50",
                                display: "flex",
                                justifyContent: "flex-end",
                            }}
                        >
                            {value.toFixed(2)}
                        </b>
                    );
                },
            },
        },
    ];

    const AssignmentSchema = [
        {
            name: "Assignment",
            Label: "Assignment ID",
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => (
                    <span style={{ color: "white" }}>Assignment ID</span>
                ),
                setCellHeaderProps: () => {
                    return { style: { borderRight: "1px solid #D5D5D5" } };
                },
                setCellProps: () => {
                    return {
                        style: {
                            borderRight: "1px solid #D5D5D5",
                            textAlign: "center",
                        },
                    };
                },
                customBodyRender: (
                    value: any,
                    tableMeta: any,
                    updateValue: any,
                ) => {
                    return (
                        <b
                            className="blue"
                            onClick={() => {
                                setAssignmentDetails({
                                    sideDrawState: true,
                                    clickedData: value,
                                });
                            }}
                        >
                            {value}
                        </b>
                    );
                },
            },
        },
        {
            name: "Customer",
            Label: "Customer",
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => (
                    <span style={{ color: "white" }}>Customer</span>
                ),
                setCellHeaderProps: () => {
                    return { style: { borderRight: "1px solid #D5D5D5" } };
                },
                setCellProps: () => {
                    return {
                        style: {
                            borderRight: "1px solid #D5D5D5",
                            textAlign: "center",
                        },
                    };
                },
                customBodyRender: (
                    value: any,
                    tableMeta: any,
                    updateValue: any,
                ) => {
                    return <b className="grey1">{value}</b>;
                },
            },
        },
        {
            name: "BuildingName",
            Label: "Building",
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => (
                    <span style={{ color: "white" }}>Building</span>
                ),
                setCellHeaderProps: () => {
                    return { style: { borderRight: "1px solid #D5D5D5" } };
                },
                setCellProps: () => {
                    return {
                        style: {
                            borderRight: "1px solid #D5D5D5",
                            textAlign: "center",
                        },
                    };
                },
                customBodyRender: (
                    value: any,
                    tableMeta: any,
                    updateValue: any,
                ) => {
                    return (
                        <Grid container>
                            <Grid item xs={12}>
                                <b
                                    style={{ cursor: "pointer" }}
                                    className="blue font-bolder"
                                >
                                    {value.BuildingID}
                                </b>
                            </Grid>
                            <Grid item xs={12}>
                                <b
                                    className="grey"
                                    style={{ wordBreak: "break-word" }}
                                >
                                    {/* {value.SiteID} |  */}
                                    {value.BuildingAddress}
                                </b>
                            </Grid>
                        </Grid>
                    );
                },
            },
        },

        {
            name: "BillingAmount",
            Label: "Billing Amount",
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => (
                    <span style={{ color: "white" }}>Billing Amount</span>
                ),
                setCellHeaderProps: () => {
                    return { style: { borderRight: "1px solid #D5D5D5" } };
                },
                setCellProps: () => {
                    return {
                        style: {
                            borderRight: "1px solid #D5D5D5",
                            textAlign: "left",
                        },
                    };
                },
                customBodyRender: (
                    value: any,
                    tableMeta: any,
                    updateValue: any,
                ) => {
                    return (
                        <b className="green">
                            {currencyFormatter(value, currency)}
                        </b>
                    );
                },
            },
        },
        {
            name: "StartEndDate",
            Label: "Start - End Date",
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => (
                    <span style={{ color: "white" }}>Start - End Date</span>
                ),
                setCellHeaderProps: () => {
                    return { style: { borderRight: "1px solid #D5D5D5" } };
                },
                setCellProps: () => {
                    return {
                        style: {
                            borderRight: "1px solid #D5D5D5",
                            textAlign: "center",
                        },
                    };
                },
                customBodyRender: (
                    value: any,
                    tableMeta: any,
                    updateValue: any,
                ) => {
                    return <b className="grey1">{value} </b>;
                },
            },
        },
        {
            name: "Service",
            Label: "Service",
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => (
                    <span style={{ color: "white" }}>Service</span>
                ),
                setCellHeaderProps: () => {
                    return { style: { borderRight: "1px solid #D5D5D5" } };
                },
                setCellProps: () => {
                    return {
                        style: {
                            borderRight: "1px solid #D5D5D5",
                            textAlign: "center",
                        },
                    };
                },
                customBodyRender: (
                    value: any,
                    tableMeta: any,
                    updateValue: any,
                ) => {
                    return <b className="grey1">{value} </b>;
                },
            },
        },
    ];
    const [selectedAssignment, setSelectedAssignment] = useState(
        navlist[0]?.desc,
    );
    const [AssignmentComp, setAssignmentComp] = useState<AssignmentProps[]>(
        navlist,
    );
    const [activeList, setActiveList] = useState(controlList[0].desc);

    const handleDocList = (listType: string) => {
        console.log("listType", listType);
        setActiveList(listType);
    };

    const [popoverBlack, setPopoverBlack] = useState(false);
    const [alert, setAlert] = useState(false);
    const [errr, seterr] = useState({
        code: 0,
        msg: "",
    });

    const handleErrorAlert = (code: number, message: string) => {
        Alert({
            statusCode: code,
            message: message,
            willClose: () => {
                setAlert(false);
                seterr({ code: 0, msg: "" });
            },
        });
    };

    const [assignmentList, setAssignmentList] = useState([]);

    const {
        data: AssignmentListData,
        error: AssignmentListDataError,
        isLoading: AssignmentListDataLoading,
    } = useGetPosts({
        name: `AssignmentListQuerySV_get_data_${contractNo}`,
        query: AssignmentListQuerySV,
        variables: {
            entity: "OW_Vendor_portal_contrARFAssgn",
            ou: currentContext?.ouId,
            service: "",
            User: selectedVendor?.loginUser,
            supplierCode: selectedVendor?.supplierCode,
            languageId: currentContext?.langId,
            suppliername: "",
            bpono: contractNo,
            merrorid: "",
        },
        skip: !(
            activeList === "Assignment/Refusals/Fulfillment" &&
            selectedAssignment === "Assignment"
        ),
    });

    useEffect(() => {
        if (AssignmentListData?.GetAssignmentListSV) {
            setAssignmentList(AssignmentListData?.GetAssignmentListSV);
        }
    }, [AssignmentListData]);

    useEffect(() => {
        if (AssignmentListDataError && !AssignmentListDataLoading) {
            const data = JSON.parse(JSON.stringify(AssignmentListDataError));
            const errmsg = data?.response?.errors[0].message;
            seterr({
                code: 400,
                msg: errmsg,
            });
            setAlert(true);
        }
    }, [AssignmentListDataError, AssignmentListDataLoading]);

    const [AmorizationList, setAmorizationList] = useState([]);

    const {
        data: AmorizationListData,
        error: AmorizationListDataError,
        isLoading: AmorizationListDataLoading,
    } = useGetPosts({
        name: `AmorizationListQuerySV_get_data_${contractNo}`,
        query: AmorizationListQuerySV,
        variables: {
            entity: "OW_Vendor_Contract_ARF_Amortization",
            ou: currentContext?.ouId,
            service: "",
            User: selectedVendor?.loginUser,
            languageId: currentContext?.langId,
            VendorCode: selectedVendor?.supplierCode,
            Vendorname: selectedVendor?.supplierName,
            contractno: contractNo,
            merrorid: "",
        },
        skip: !(activeList === "Amortization"),
    });

    useEffect(() => {
        if (AmorizationListData?.GetAmortizationListSV) {
            setAmorizationList(AmorizationListData?.GetAmortizationListSV);
        }
    }, [AmorizationListData]);

    useEffect(() => {
        if (AmorizationListDataError && !AmorizationListDataLoading) {
            const data = JSON.parse(JSON.stringify(AmorizationListDataError));
            const errmsg = data?.response?.errors[0].message;
            seterr({
                code: 400,
                msg: errmsg,
            });
            setAlert(true);
        }
    }, [AmorizationListDataError, AmorizationListDataLoading]);

    const [ReplacementList, setReplacementList] = useState([]);

    const {
        data: ReplacementListData,
        error: ReplacementListDataError,
    } = useGetPosts({
        name: `ReplacementListQuerySV_get_data`,
        query: ReplacementListQuerySV,
        variables: {},
        skip: !(
            activeList === "Assignment/Refusals/Fulfillment" &&
            selectedAssignment === "Replacement"
        ),
    });

    useEffect(() => {
        if (ReplacementListData?.GetReplacementListSV) {
            setReplacementList(ReplacementListData?.GetReplacementListSV);
        }
    }, [ReplacementListData]);

    const [RefusalCard, setRefusalCard] = useState<any>();

    const {
        data: RefusalCardData,
        error: RefusalCardDataError,
        isLoading: RefusalCardDataLoading,
    } = useGetPosts({
        name: `RefusalCardQuerySV_get_data_${contractNo}`,
        query: RefusalCardQuerySV,
        variables: {
            entity: "OW_Vendor_Contract_ARF_Refusal",
            ou: currentContext?.ouId,
            service: "",
            User: selectedVendor?.loginUser,
            languageId: currentContext?.langId,
            VendorCode: selectedVendor?.supplierCode,
            Vendorname: selectedVendor?.supplierName,
            contractno: contractNo,
            merrorid: "",
        },
        skip: !(
            activeList === "Assignment/Refusals/Fulfillment" &&
            selectedAssignment === "Refusals / Fulfillment"
        ),
    });

    useEffect(() => {
        if (RefusalCardData?.GetRefusalCardSV) {
            setRefusalCard(RefusalCardData?.GetRefusalCardSV[0]);
        }
    }, [RefusalCardData]);

    useEffect(() => {
        if (RefusalCardDataError && !RefusalCardDataLoading) {
            const data = JSON.parse(JSON.stringify(RefusalCardDataError));
            const errmsg = data?.response?.errors[0].message;
            seterr({
                code: 400,
                msg: errmsg,
            });
            setAlert(true);
        }
    }, [RefusalCardDataError, RefusalCardDataLoading]);

    const [ContactCardList, setContactCardList] = useState<any>();

    const {
        data: ContactCardListData,
        error: ContactCardListDataError,
        isLoading: ContactCardListDataLoading,
    } = useGetPosts({
        name: `ContractCardListQuerySV_get_data_${contractNo}`,
        query: contractCardQuerySV,
        variables: {
            entity: "OW_Vendor_Contract_Details_Header",
            ou: currentContext?.ouId,
            service: "",
            User: selectedVendor?.loginUser,
            languageId: currentContext?.langId,
            VendorCode: selectedVendor?.supplierCode,
            Vendorname: selectedVendor?.supplierName,
            contractno: contractNo,
            merrorid: "",
        },
    });

    useEffect(() => {
        if (ContactCardListData?.ContractCardQuerySV) {
            setContactCardList(ContactCardListData?.ContractCardQuerySV[0]);
        }
    }, [ContactCardListData]);
    useEffect(() => {
        if (ContactCardListDataError && !ContactCardListDataLoading) {
            const data = JSON.parse(JSON.stringify(ContactCardListDataError));
            const errmsg = data?.response?.errors[0].message;
            seterr({
                code: 400,
                msg: errmsg,
            });
            setAlert(true);
        }
    }, [ContactCardListDataError, ContactCardListDataLoading]);

    const [RefusalList, setRefusalList] = useState([]);

    const {
        data: RefusalListData,
        error: RefusalListDataError,
        isLoading: RefusalListDataLoading,
    } = useGetPosts({
        name: `RefusalListQuerySV_get_data_${contractNo}`,
        query: RefusalListQuerySV,
        variables: {
            entity: "OW_Cust_portal_contractrefusallist",
            ou: currentContext?.ouId,
            service: "",
            User: selectedVendor?.loginUser,
            languageId: currentContext?.langId,
            VendorCode: selectedVendor?.supplierCode,
            Vendorname: selectedVendor?.supplierName,
            contractno: contractNo,
            merrorid: "",
        },
        skip: !(
            activeList === "Assignment/Refusals/Fulfillment" &&
            selectedAssignment === "Refusals / Fulfillment"
        ),
    });

    useEffect(() => {
        if (RefusalListData?.GetRefusalListSV) {
            setRefusalList(RefusalListData?.GetRefusalListSV);
        }
    }, [RefusalListData]);

    useEffect(() => {
        if (RefusalListDataError && !RefusalListDataLoading) {
            const data = JSON.parse(JSON.stringify(RefusalListDataError));
            const errmsg = data?.response?.errors[0].message;
            seterr({
                code: 400,
                msg: errmsg,
            });
            setAlert(true);
        }
    }, [RefusalListDataError, RefusalListDataLoading]);

    const [contractDetailsList, setContractDetailsList] = useState<
        ContractDet
    >();

    const {
        data: ContractDetailsListData,
        error: ContractDetailsListDataError,
        isLoading: ContractDetailsListDataLoading,
    } = useGetPosts({
        name: `ContractDetailsListQuerySV_get_data_${contractNo}`,
        query: contractDetailsQuerySV,
        variables: {
            entity: "OW_Vendor_portal_contractdtl_gen",
            ou: currentContext?.ouId,
            service: "",
            User: selectedVendor?.loginUser,
            languageId: currentContext?.langId,
            VendorCode: selectedVendor?.supplierCode,
            Vendorname: selectedVendor?.supplierName,
            contractno: contractNo,
            merrorid: "",
        },
        skip: !(activeList === "General"),
    });

    useEffect(() => {
        if (ContractDetailsListData?.ContractDetailsSV) {
            setContractDetailsList(
                ContractDetailsListData?.ContractDetailsSV[0],
            );
        }
    }, [ContractDetailsListData]);

    useEffect(() => {
        if (ContractDetailsListDataError && !ContractDetailsListDataLoading) {
            const data = JSON.parse(
                JSON.stringify(ContractDetailsListDataError),
            );
            const errmsg = data?.response?.errors[0].message;
            seterr({
                code: 400,
                msg: errmsg,
            });
            setAlert(true);
        }
    }, [ContractDetailsListDataError, ContractDetailsListDataLoading]);

    const handleSelect = (data: any) => {
        setSelectedAssignment(data.desc);
        console.log("data", data);
    };
    if (assignmentDetails?.sideDrawState) {
        return (
            <Redirect
                to={{
                    pathname: "/app/*/AssignmentDetails",
                }}
            />
        );
    }

    return (
        <>
            {alert ? handleErrorAlert(errr.code, errr.msg) : <></>}
            <Backdrop
                className={classes.backdrop}
                open={
                    AssignmentListDataLoading ||
                    AmorizationListDataLoading ||
                    ContractDetailsListDataLoading ||
                    RefusalCardDataLoading ||
                    ContactCardListDataLoading ||
                    RefusalListDataLoading
                }
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <Backdrop
                className={classes.backdrop}
                open={popoverBlack}
            ></Backdrop>

            <Grid
                container
                spacing={2}
                style={{
                    textAlign: "left",
                    margin: "100px !important",
                    marginTop: "10px",
                }}
            >
                <Grid container spacing={2} style={{ textAlign: "left" }}>
                    <Grid item xs={8}>
                        <Paper elevation={4}>
                            <Grid container>
                                <Grid
                                    item
                                    xs={7}
                                    style={{
                                        margin: "0%",
                                        padding: "2%",
                                        borderRight: "1px dashed #C9CDD6",
                                    }}
                                >
                                    <Grid container spacing={1}>
                                        <Grid item xs={12}>
                                            <Grid
                                                container
                                                justifyContent="space-between"
                                                // style={{paddingLeft:"2%"}}
                                            >
                                                <Grid
                                                    item
                                                    className="grey2 font-boldest"
                                                    xs={4}
                                                >
                                                    <Grid container>
                                                        <Grid item xs={2}>
                                                            <Icons Icon="contractDetail" />
                                                        </Grid>
                                                        <Grid
                                                            item
                                                            style={{
                                                                paddingLeft:
                                                                    "2%",
                                                            }}
                                                            xs={9}
                                                        >
                                                            #
                                                            {
                                                                ContactCardList?.ContractID
                                                            }
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={8}>
                                                    <Grid
                                                        container
                                                        justifyContent="flex-end"
                                                    >
                                                        <Grid item xs={1}>
                                                            <Icons Icon="buildingDetail" />
                                                        </Grid>
                                                        <Grid
                                                            item
                                                            xs={9}
                                                            style={{
                                                                paddingLeft:
                                                                    "2%",
                                                            }}
                                                            className="grey2 font-boldest"
                                                        >
                                                            {
                                                                ContactCardList?.Building
                                                            }
                                                        </Grid>
                                                    </Grid>

                                                    {/* {contactName?.contractSelected[2]} */}
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid
                                            item
                                            xs={12}
                                            // style={{ padding: "2%" }}
                                        >
                                            <Grid container>
                                                <Grid item xs={4}>
                                                    <Grid container>
                                                        <Grid item xs={2}>
                                                            <Icons Icon="vendorContract" />
                                                        </Grid>
                                                        <Grid
                                                            item
                                                            xs={9}
                                                            className="grey2"
                                                            style={{
                                                                paddingLeft:
                                                                    "2%",
                                                            }}
                                                        >
                                                            {
                                                                ContactCardList?.VendorContract
                                                            }
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={8}></Grid>
                                            </Grid>

                                            {/* {contactName?.contractSelected[4]} */}
                                        </Grid>
                                        <Grid
                                            item
                                            xs={2}
                                            style={{
                                                display: "flex",
                                                justifyContent: "center",
                                                border: `${
                                                    ContactCardList?.Status.toLowerCase() ===
                                                    "open"
                                                        ? "1px solid #00DA64"
                                                        : "1px solid #DD9200"
                                                }`,
                                                borderRadius: "3px",
                                                background: `${
                                                    ContactCardList?.Status.toLowerCase() ===
                                                    "open"
                                                        ? "#EDFDF2"
                                                        : "#FCF5E9"
                                                }`,
                                                margin: "4px 0 0 4px",
                                            }}
                                        >
                                            {/* <MUIButton
                                                label={"Pending"}
                                                onClick={() => {}}
                                                type="outlined"
                                                font="small"
                                            /> */}
                                            {ContactCardList?.Status}
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid
                                    item
                                    xs={5}
                                    style={{ padding: "2% 2% 2% 3.5%" }}
                                >
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <Grid container spacing={2}>
                                                <Grid item xs={7}>
                                                    <Grid container>
                                                        <Grid
                                                            item
                                                            xs={12}
                                                            className="grey"
                                                        >
                                                            Revision
                                                        </Grid>
                                                        <Grid
                                                            item
                                                            xs={12}
                                                            className="grey2 font-boldest"
                                                        >
                                                            {
                                                                ContactCardList?.Revision
                                                            }
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                {/* <Grid item xs={5}>
                                                    <Grid container>
                                                        <Grid
                                                            item
                                                            xs={12}
                                                            className="grey"
                                                        >
                                                            Contract
                                                        </Grid>
                                                        <Grid
                                                            item
                                                            xs={12}
                                                            className="grey2 font-boldest"
                                                        >
                                                            {
                                                                ContactCardList?.Contract
                                                            }
                                                        </Grid>
                                                    </Grid>
                                                </Grid> */}
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Grid container>
                                                <Grid
                                                    item
                                                    xs={12}
                                                    className="grey"
                                                >
                                                    Start - End Date
                                                </Grid>
                                                <Grid
                                                    item
                                                    xs={12}
                                                    className="grey2 font-boldest"
                                                >
                                                    {
                                                        ContactCardList?.StartEndDate
                                                    }
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                </Grid>

                <Grid container style={{ paddingTop: "2%" }}>
                    <ButtonGroups
                        controlList={controlList}
                        handleDocList={handleDocList}
                        gridSpacing={2}
                        externalActive={activeList}
                    />
                </Grid>

                {activeList === "General" && (
                    <Grid container spacing={2} style={{ paddingTop: "2%" }}>
                        <Grid
                            item
                            xs={12}
                            className="grey2 font-big font-boldest"
                        >
                            Details
                        </Grid>
                        <Grid item xs={7}>
                            <Paper elevation={4}>
                                <Grid container style={{ padding: "2%" }}>
                                    <Grid
                                        item
                                        xs={6}
                                        style={{
                                            margin: "0%",
                                            padding: "2% 3% 2% 2%",
                                            borderRight: "1px dashed #C9CDD6",
                                        }}
                                    >
                                        <Grid container spacing={2}>
                                            <Grid
                                                item
                                                xs={12}

                                                // style={{ padding: "3%" }}
                                            >
                                                <Grid
                                                    container
                                                    justifyContent="space-between"
                                                >
                                                    <Grid item className="grey">
                                                        Purchase Date
                                                    </Grid>
                                                    <Grid
                                                        item
                                                        className="grey2 font-boldest"
                                                    >
                                                        {
                                                            contractDetailsList?.PurchaseDate
                                                        }
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid
                                                item
                                                xs={12}
                                                // style={{ padding: "3%" }}
                                            >
                                                <Grid
                                                    container
                                                    justifyContent="space-between"
                                                >
                                                    <Grid item className="grey">
                                                        Volume Purchased
                                                    </Grid>
                                                    <Grid
                                                        item
                                                        className="grey2 font-boldest"
                                                    >
                                                        {currencyFormatter(
                                                            contractDetailsList?.VolumePurchased,
                                                            currency,
                                                        )}
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            {/* <Grid
                                                item
                                                xs={12}
                                                // style={{ padding: "3%" }}
                                            >
                                                <Grid
                                                    container
                                                    justifyContent="space-between"
                                                >
                                                    <Grid item className="grey">
                                                        {" "}
                                                        Total Cost
                                                    </Grid>
                                                    <Grid
                                                        item
                                                        style={{
                                                            color: "#00AE50",
                                                        }}
                                                        className=" font-boldest"
                                                    >
                                                        {currencyFormatter(
                                                            contractDetailsList?.TotalCost,
                                                            currency,
                                                        )}
                                                    </Grid>
                                                </Grid>
                                            </Grid> */}
                                            <Grid
                                                item
                                                xs={12}
                                                // style={{ padding: "3%" }}
                                            >
                                                <Grid
                                                    container
                                                    justifyContent="space-between"
                                                >
                                                    <Grid item className="grey">
                                                        Royalty %
                                                    </Grid>
                                                    <Grid
                                                        item
                                                        className="grey2 font-boldest"
                                                    >
                                                        {
                                                            contractDetailsList?.Royalty
                                                        }
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid
                                        item
                                        xs={6}
                                        style={{ padding: "2% 2% 2% 3%" }}
                                    >
                                        <Grid container spacing={2}>
                                            {/* <Grid
                                                item
                                                xs={12}
                                                // style={{ padding: "3%" }}
                                            >
                                                <Grid
                                                    container
                                                    justifyContent="space-between"
                                                >
                                                    <Grid item className="grey">
                                                        Initial Cash
                                                    </Grid>
                                                    <Grid
                                                        item
                                                        className="grey2 font-boldest"
                                                    >
                                                        {
                                                            contractDetailsList?.InitialCash
                                                        }
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid
                                                item
                                                xs={12}
                                                // style={{ padding: "3%" }}
                                            >
                                                <Grid
                                                    container
                                                    justifyContent="space-between"
                                                >
                                                    <Grid item className="grey">
                                                        Initial Cash Payment
                                                        Date
                                                    </Grid>
                                                    <Grid
                                                        item
                                                        className="grey2 font-boldest"
                                                    >
                                                        {
                                                            contractDetailsList?.InitialCashPaymentDate
                                                        }
                                                    </Grid>
                                                </Grid>
                                            </Grid> */}
                                            <Grid
                                                item
                                                xs={12}
                                                // style={{ padding: "3%" }}
                                            >
                                                <Grid
                                                    container
                                                    justifyContent="space-between"
                                                >
                                                    <Grid item className="grey">
                                                        Target Fulfillment
                                                    </Grid>
                                                    <Grid
                                                        item
                                                        className="grey2 font-boldest"
                                                    >
                                                        {
                                                            currencyFormatter(contractDetailsList?.TargetFulfillment, currency)
                                                        }
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid
                                                item
                                                xs={12}
                                                // style={{ padding: "3%" }}
                                            >
                                                <Grid
                                                    container
                                                    justifyContent="space-between"
                                                >
                                                    <Grid item className="grey">
                                                        Volume Owed
                                                    </Grid>
                                                    <Grid
                                                        item
                                                        style={{
                                                            color: "#00AE50",
                                                        }}
                                                        className=" font-boldest"
                                                    >
                                                        {currencyFormatter(
                                                            contractDetailsList?.VolumeOwed,
                                                            currency,
                                                        )}
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid>
                    </Grid>
                )}

                {activeList === "Assignment/Refusals/Fulfillment" && (
                    <Grid container spacing={2} style={{ paddingTop: "2%" }}>
                        <Grid item xs={2} style={{ marginTop: "8px" }}>
                            <AssignmentCards
                                data={AssignmentComp}
                                handleSelect={handleSelect}
                            />
                        </Grid>
                        {selectedAssignment === "Assignment" && (
                            <Grid item xs={8} style={{ marginRight: "2%" }}>
                                <MuiDatatables
                                    tableHeight="320px"
                                    header={AssignmentSchema}
                                    detail={[assignmentList, setAssignmentList]}
                                />
                            </Grid>
                        )}

                        {selectedAssignment === "Refusals / Fulfillment" && (
                            <Grid item xs={8} style={{ marginRight: "2%" }}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <Paper elevation={4}>
                                            <Grid
                                                container
                                                style={{ padding: "2%" }}
                                            >
                                                <Grid
                                                    item
                                                    xs={4}
                                                    style={{
                                                        margin: "0%",
                                                        padding: "2%",
                                                        borderRight:
                                                            "1px dashed #C9CDD6",
                                                    }}
                                                >
                                                    <Grid container spacing={2}>
                                                        <Grid
                                                            item
                                                            xs={12}
                                                            // style={{
                                                            //     padding: "3%",
                                                            // }}
                                                        >
                                                            <Grid
                                                                container
                                                                justifyContent="space-between"
                                                            >
                                                                <Grid
                                                                    item
                                                                    className="grey"
                                                                >
                                                                    Volume
                                                                    Purchased
                                                                </Grid>
                                                                <Grid
                                                                    item
                                                                    className="grey2 font-boldest"
                                                                >
                                                                    {currencyFormatter(
                                                                        RefusalCard?.VolumePurchased,
                                                                        currency,
                                                                    )}
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                        <Grid
                                                            item
                                                            xs={12}
                                                            // style={{
                                                            //     padding: "3%",
                                                            // }}
                                                        >
                                                            <Grid
                                                                container
                                                                justifyContent="space-between"
                                                            >
                                                                <Grid
                                                                    item
                                                                    className="grey"
                                                                >
                                                                    Fulfilled
                                                                </Grid>
                                                                <Grid
                                                                    item
                                                                    className="grey2 font-boldest"
                                                                >
                                                                    {
                                                                        currencyFormatter(RefusalCard?.Fulfilled, currency)
                                                                    }
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                        <Grid
                                                            item
                                                            xs={12}
                                                            // style={{
                                                            //     padding: "3%",
                                                            // }}
                                                        >
                                                            <Grid
                                                                container
                                                                justifyContent="space-between"
                                                            >
                                                                <Grid
                                                                    item
                                                                    className="grey"
                                                                >
                                                                    Total
                                                                    Assigned
                                                                </Grid>
                                                                <Grid
                                                                    item
                                                                    className="grey2 font-boldest"
                                                                >
                                                                    {currencyFormatter(
                                                                        RefusalCard?.TotalAssigned,
                                                                        currency,
                                                                    )}
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>

                                                <Grid
                                                    item
                                                    xs={4}
                                                    style={{
                                                        margin: "0%",
                                                        padding: "2%",
                                                        borderRight:
                                                            "1px dashed #C9CDD6",
                                                    }}
                                                >
                                                    <Grid container spacing={2}>
                                                        <Grid
                                                            item
                                                            xs={12}
                                                            // style={{
                                                            //     padding: "3%",
                                                            // }}
                                                        >
                                                            <Grid
                                                                container
                                                                justifyContent="space-between"
                                                            >
                                                                <Grid
                                                                    item
                                                                    className="grey"
                                                                >
                                                                    Total
                                                                    Assigned
                                                                    Today
                                                                </Grid>
                                                                <Grid
                                                                    item
                                                                    style={{
                                                                        color:
                                                                            "#00AE50",
                                                                    }}
                                                                    className="grey2 font-boldest"
                                                                >
                                                                    {currencyFormatter(
                                                                        RefusalCard?.TotalAssignedToday,
                                                                        currency,
                                                                    )}
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                        <Grid
                                                            item
                                                            xs={12}
                                                            // style={{
                                                            //     padding: "3%",
                                                            // }}
                                                        >
                                                            <Grid
                                                                container
                                                                justifyContent="space-between"
                                                            >
                                                                <Grid
                                                                    item
                                                                    className="grey"
                                                                >
                                                                    Currency
                                                                </Grid>
                                                                <Grid
                                                                    item
                                                                    className="grey2 font-boldest"
                                                                >
                                                                    {
                                                                        RefusalCard?.Currency
                                                                    }
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                        <Grid
                                                            item
                                                            xs={12}
                                                            // style={{
                                                            //     padding: "3%",
                                                            // }}
                                                        >
                                                            <Grid
                                                                container
                                                                justifyContent="space-between"
                                                            >
                                                                <Grid
                                                                    item
                                                                    className="grey"
                                                                >
                                                                    Total
                                                                    Refusals
                                                                </Grid>
                                                                <Grid
                                                                    item
                                                                    className="grey2 font-boldest"
                                                                >
                                                                    {currencyFormatter(
                                                                        RefusalCard?.TotalRefusals,
                                                                        currency,
                                                                    )}
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>

                                                <Grid
                                                    item
                                                    xs={4}
                                                    style={{
                                                        padding: "2%",
                                                    }}
                                                >
                                                    <Grid container spacing={2}>
                                                        <Grid item xs={12}>
                                                            <Grid
                                                                container
                                                                justifyContent="space-between"
                                                            >
                                                                <Grid
                                                                    item
                                                                    className="grey"
                                                                >
                                                                    Refused
                                                                </Grid>
                                                                <Grid
                                                                    item
                                                                    className="grey2 font-boldest"
                                                                >
                                                                    {currencyFormatter(
                                                                        RefusalCard?.Refused,
                                                                        currency,
                                                                    )}
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                        <Grid
                                                            item
                                                            xs={12}
                                                            // style={{
                                                            //     padding: "3%",
                                                            // }}
                                                        >
                                                            <Grid
                                                                container
                                                                justifyContent="space-between"
                                                            >
                                                                <Grid
                                                                    item
                                                                    className="grey"
                                                                >
                                                                    Total
                                                                    Refused Past
                                                                    Three
                                                                </Grid>
                                                                <Grid
                                                                    item
                                                                    className="grey2 font-boldest"
                                                                >
                                                                    {currencyFormatter(
                                                                        RefusalCard?.TotalRefusedPastThree,
                                                                        currency,
                                                                    )}
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                        <Grid
                                                            item
                                                            xs={12}
                                                            // style={{
                                                            //     padding: "3%",
                                                            // }}
                                                        >
                                                            <Grid
                                                                container
                                                                justifyContent="space-between"
                                                            >
                                                                <Grid
                                                                    item
                                                                    xs={10}
                                                                >
                                                                    <Grid
                                                                        container
                                                                    >
                                                                        <Grid
                                                                            item
                                                                            xs={
                                                                                12
                                                                            }
                                                                            className="grey"
                                                                        >
                                                                            Total
                                                                            Refused
                                                                            Count
                                                                        </Grid>
                                                                        <Grid
                                                                            item
                                                                            xs={
                                                                                12
                                                                            }
                                                                            className="grey"
                                                                        >
                                                                            Towards
                                                                            Fulfillment
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                                <Grid
                                                                    item
                                                                    xs={2}
                                                                    className="grey2 font-boldest"
                                                                    style={{
                                                                        textAlign:
                                                                            "right",
                                                                    }}
                                                                >
                                                                    {
                                                                        RefusalCard?.TowardsFulfillment
                                                                        
                                                                    }
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Paper>
                                    </Grid>

                                    <Grid item xs={12}>
                                        <Grid container>
                                            <Grid
                                                item
                                                xs={12}
                                                style={{ marginRight: "2%" }}
                                            >
                                                <MuiDatatables
                                                    header={RefusalSchema}
                                                    tableHeight="320px"
                                                    detail={[
                                                        RefusalList,
                                                        setRefusalList,
                                                    ]}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        )}

                        {selectedAssignment === "Replacement" && (
                            <Grid item xs={8} style={{ marginRight: "2%" }}>
                                <MuiDatatables
                                    header={ReplacementSchema}
                                    title={""}
                                    // rowData={checklistDetailsList}
                                    detail={[
                                        ReplacementList,
                                        setReplacementList,
                                    ]}
                                    // handleAddSideEffect={handleAddSideEffect}
                                    // handleDeleteSideEffect={handleDeleteSideEffect}
                                    // handleSelectedSideEffect={handleSelectedSideEffect}
                                    // initRow={true}
                                />
                            </Grid>
                        )}
                    </Grid>
                )}

                {activeList === "Amortization" && (
                    <Grid container spacing={2} style={{ paddingTop: "2%" }}>
                        <Grid item xs={10}>
                            <MuiDatatables
                                header={AmortizationSchema}
                                tableHeight="320px"
                                detail={[AmorizationList, setAmorizationList]}
                            />
                        </Grid>
                    </Grid>
                )}
            </Grid>
        </>
    );
};

const mapStateToProps = (state: any) => {
    return {
        currentContext: state.context?.userDefaults,
        userName: state.context?.name,
        selectedVendor: state.selectedVendor,
        currency: state.currency,
    };
};
const mapDispatchToProps = (dispatch: any) => {
    return {
        setBreadcrumbsData: (data: any) => {
            dispatch(storeBreadcrumbs(data));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ContractDetailsSV);
