const CustomerDataQuery = `
    mutation(
        $entity: String
        $ou: Int
        $service: String
        $user: String
        $langId: Int
        $merrorid: String
    ){
        CustomerData(
            entity: $entity
            ou: $ou
            service: $service
            user: $user
            langId: $langId
            merrorid: $merrorid
        )
        {
            loginUser
            contactId
            customerCode
            admin
            orderSupplies
            hubspotCustomerId
            customerName
        }
    }
`;

export default CustomerDataQuery;
