const CurrentAssignmentMultilineQuery = `
mutation(
  $ou:Int
  $service:String
  $User:String
  $languageId:Int
  $supplierCode:String
  $suppliername:String
  $poNo:String
  $status:String
  $fromDate:String
  $ToDate:String
  $merrorid:String
){
    CurrentAssignmentList(
      ou:$ou
      service:$service
      User:$User
      languageId:$languageId
      supplierCode:$supplierCode
      suppliername:$suppliername
      poNo:$poNo
      merrorid:$merrorid
      status:$status
      fromDate:$fromDate
      ToDate:$ToDate
    ) {
      Assignment_ID:assignmentno,
      Billing_Amount:bill_amt,
      Building:building,
      Customer:customer,
      Service:service,
      Start_End_Date:startenddate,
  }
}
  `;

export default CurrentAssignmentMultilineQuery;
