import {
    Grid,
    Paper,
    Backdrop,
    CircularProgress,
    makeStyles,
    Theme,
} from "@material-ui/core";
import { useEffect, useState } from "react";
import "Common/Main.scss";
import ButtonGroups from "Common/ButtonGroup/ButtonGroup";
import MuiDatatables from "Common/Mui-DataTables/MuiDatatables";
import {
    ContractInvoiceDetailsCusQuery,
    ContractVendorDetailsCusQuery,
    InvoiceContractHeaderDataCus,
} from "ReactQuery/Query";
import useGetPosts from "ReactQuery/reactQuery";
import { connect } from "react-redux";
import Icons from "Common/Icons/Icons";
import SwipeableDrawerComp from "Common/SideDrawer/SideDrawer";
import ContractsChild from "./ContractsChild";
import { useLocation } from "react-router-dom";
import Alert from "Common/Alerts/Alerts";
import currencyFormatter from "Common/Utils/currencyFormatter";
import "Common/Main.scss";
import NoVendors from "./Images/no_vendor.svg";
import NoInvoices from "./Images/No_invoices.svg";
import { storeBreadcrumbs } from "stateManagement/actions/Common/breadcrumbs";

const useStyles = makeStyles((theme: Theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: "#fff",
    },
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
    },
}));

const Body = (props: any) => {
    const classes = useStyles();
    const contractData = useLocation<any>();
    const contractName: any = contractData?.state?.contractSelected || "";
    const siteIdContract: any = contractData?.state?.siteId || "";
    const { currentContext, userName, currency, selectedCustomer, setBreadcrumbsData } = props;
    setBreadcrumbsData([
        {
            value: "Contracts",
            href: "/app/*/Contracts",
        },
        {
            value: "Contract Details",
            href: "/app/*/contractDet",
        },
    ]);

    const controlList = [
        {
            desc: "Service Detail",
            value: "Service Detail",
        },
        {
            desc: "Invoices",
            value: "Invoices",
        },
    ];

    const [activeList, setActiveList] = useState(controlList[0].desc);
    const [alert, setAlert] = useState(false);
    const [errr, seterr] = useState({
        code: 0,
        msg: "",
    });

    const handleErrorAlert = (code: number, message: string) => {
        Alert({
            statusCode: code,
            message: message,
            willClose: () => {
                setAlert(false);
                seterr({ code: 0, msg: "" });
            },
        });
    };

    const handleDocList = (listType: string) => {
        console.log("listType", listType);
        setActiveList(listType);
    };

    const [invoiceTableData, setinvoiceTableData] = useState([]);
    const [vendorTableData, setvendorTableData] = useState([]);

    const [sideScreen, setSideScreen] = useState("");
    const [sideBar, setSideBar] = useState(false);
    const handleSideBar = (screen: string) => {
        setSideBar(!sideBar);
        setSideScreen(screen);
    };
    const [
        InvoiceContractHeaderDataSet,
        setInvoiceContractHeaderDataSet,
    ] = useState<any>();
    const {
        data: InvoiceContractData,
        error: InvoiceContractDataError,
        isLoading: InvoiceContractDataLoading,
    } = useGetPosts({
        name: `InvoiceContractHeaderDataCus_${contractName}_${siteIdContract}`,
        query: InvoiceContractHeaderDataCus,
        variables: {
            entity: "OW_Cust_portal_Cnrct_hdr",
            ou: currentContext?.ouId,
            service: "",
            User: selectedCustomer?.loginUser,
            languageId: currentContext?.langId,
            contractno: contractName,
            conttype: "",
            customerCode: selectedCustomer?.customerCode,
            siteId: siteIdContract,
            buildName: "",
            merrorid: "",
        },
    });

    useEffect(() => {
        console.log(
            "ContractDetailHeader",
            InvoiceContractData?.InvoiceContractDataCus[0],
        );
        if (InvoiceContractData?.InvoiceContractDataCus) {
            setInvoiceContractHeaderDataSet(
                InvoiceContractData?.InvoiceContractDataCus[0],
            );
        }
        console.log(InvoiceContractData?.InvoiceContractDataCus);
    }, [InvoiceContractData]);

    useEffect(() => {
        if (InvoiceContractDataError && !InvoiceContractDataLoading) {
            const data = JSON.parse(JSON.stringify(InvoiceContractDataError));
            const errmsg = data?.response?.errors[0].message;
            seterr({
                code: 400,
                msg: errmsg,
            });
            setAlert(true);
        }
    }, [InvoiceContractDataError, InvoiceContractDataLoading]);

    const {
        data: ContractDetailsInvoicesTableData,
        error: ContractDetailsInvoicesTableDataError,
        isLoading: ContractDetailsInvoicesTableDataLoading,
    } = useGetPosts({
        name: `ContractInvoiceDetailsCusQuery_${contractName}_${siteIdContract}`,
        query: ContractInvoiceDetailsCusQuery,
        variables: {
            entity: "OW_Cust_portal_InvoiceDtls",
            ou: currentContext?.ouId,
            service: "",
            User: selectedCustomer?.loginUser,
            languageId: currentContext?.langId,
            contractno: contractName,
            conttype: "",
            customerCode: selectedCustomer?.customerCode,
            siteId: siteIdContract,
            buildName: "",
            merrorid: "",
        },
    });

    useEffect(() => {
        console.log(
            "ContractInvoiceTableDataCus",
            ContractDetailsInvoicesTableData?.ContractInvoiceTableDataCus,
        );
        if (ContractDetailsInvoicesTableData?.ContractInvoiceTableDataCus) {
            setinvoiceTableData(
                ContractDetailsInvoicesTableData?.ContractInvoiceTableDataCus,
            );
        }
    }, [ContractDetailsInvoicesTableData]);

    useEffect(() => {
        if (
            ContractDetailsInvoicesTableDataError &&
            !ContractDetailsInvoicesTableDataLoading
        ) {
            const data = JSON.parse(
                JSON.stringify(ContractDetailsInvoicesTableDataError),
            );
            const errmsg = data?.response?.errors[0].message;
            seterr({
                code: 400,
                msg: errmsg,
            });
            setAlert(true);
        }
    }, [
        ContractDetailsInvoicesTableDataError,
        ContractDetailsInvoicesTableDataLoading,
    ]);

    const {
        data: ContractDetailsVendorTableData,
        error: ContractDetailsVendorTableDataError,
        isLoading: ContractDetailsVendorTableDataLoading,
    } = useGetPosts({
        name: `ContractVendorDetailsCusQuery_${contractName}_${siteIdContract}`,
        query: ContractVendorDetailsCusQuery,
        variables: {
            entity: "OW_Cust_portal_VendorDtls",
            ou: currentContext?.ouId,
            service: "",
            User: selectedCustomer?.loginUser,
            languageId: currentContext?.langId,
            contractno: contractName,
            conttype: "",
            customerCode: selectedCustomer?.customerCode,
            siteId: siteIdContract,
            buildName: "",
            pono: "",
            merrorid: "",
        },
    });

    useEffect(() => {
        console.log(
            "VendorDetailsTableDataCus",
            ContractDetailsVendorTableData?.VendorDetailsTableDataCus,
        );
        if (ContractDetailsVendorTableData?.VendorDetailsTableDataCus) {
            setvendorTableData(
                ContractDetailsVendorTableData?.VendorDetailsTableDataCus,
            );
        }
    }, [ContractDetailsVendorTableData]);

    useEffect(() => {
        if (
            ContractDetailsVendorTableDataError &&
            !ContractDetailsVendorTableDataLoading
        ) {
            const data = JSON.parse(
                JSON.stringify(ContractDetailsVendorTableDataError),
            );
            const errmsg = data?.response?.errors[0].message;
            seterr({
                code: 400,
                msg: errmsg,
            });
            setAlert(true);
        }
    }, [
        ContractDetailsVendorTableDataError,
        ContractDetailsVendorTableDataLoading,
    ]);

    const ContractDetailsVendorSchema = [
        // {
        //     name: "Vendor",
        //     Label: "Vendor",

        //     options: {
        //         filter: true,
        //         sort: false,
        //         customHeadLabelRender: () => (
        //             <span style={{ color: "white" }}>Vendor</span>
        //         ),
        //         setCellHeaderProps: () => {
        //             return { style: { borderRight: "1px solid #D5D5D5" } };
        //         },
        //         setCellProps: () => {
        //             return {
        //                 style: {
        //                     borderRight: "1px solid #D5D5D5",
        //                     textAlign: "center",
        //                 },
        //             };
        //         },
        //         customBodyRender: (
        //             value: any,
        //             tableMeta: any,
        //             updateValue: any,
        //         ) => {
        //             console.log("table data", value);
        //             return <b style={{ color: "#5C677D" }}>{value}</b>;
        //         },
        //     },
        // },
        // {
        //     name: "ContractNumber",
        //     Label: "ContractNumber",
        //     type: "number",
        //     options: {
        //         filter: true,
        //         sort: false,
        //         customHeadLabelRender: () => (
        //             <span style={{ color: "white" }}>ContractNumber </span>
        //         ),
        //         setCellHeaderProps: () => {
        //             return { style: { borderRight: "1px solid #D5D5D5" } };
        //         },
        //         setCellProps: () => {
        //             return {
        //                 style: {
        //                     borderRight: "1px solid #D5D5D5",
        //                     textAlign: "center",
        //                 },
        //             };
        //         },
        //         customBodyRender: (
        //             value: any,
        //             tableMeta: any,
        //             updateValue: any,
        //         ) => {
        //             console.log("table data", value, tableMeta.rowData);
        //             return <b style={{ color: "#5C677D" }}>{value}</b>;
        //         },
        //     },
        // },
        {
            name: "AccountNumber",
            Label: "AccountNumber",
            type: "number",
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => (
                    <span style={{ color: "white" }}>Account Number</span>
                ),
                setCellHeaderProps: () => {
                    return { style: { borderRight: "1px solid #D5D5D5" } };
                },
                setCellProps: () => {
                    return {
                        style: {
                            borderRight: "1px solid #D5D5D5",
                            textAlign: "center",
                        },
                    };
                },
                customBodyRender: (
                    value: any,
                    tableMeta: any,
                    updateValue: any,
                ) => {
                    console.log("table data", value);
                    return <b style={{ color: "#5C677D" }}>{value}</b>;
                },
            },
        },
        {
            name: "Customer",
            Label: "Customer",
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => (
                    <span style={{ color: "white" }}>Customer</span>
                ),
                setCellHeaderProps: () => {
                    return { style: { borderRight: "1px solid #D5D5D5" } };
                },
                setCellProps: () => {
                    return {
                        style: {
                            borderRight: "1px solid #D5D5D5",
                            textAlign: "center",
                        },
                    };
                },
                customBodyRender: (
                    value: any,
                    tableMeta: any,
                    updateValue: any,
                ) => {
                    console.log("table data", value);
                    return <b style={{ color: "#5C677D" }}>{value}</b>;
                },
            },
        },
        {
            name: "BuildingID",
            Label: "BuildingID",
            type: "string",
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => (
                    <span style={{ color: "white" }}>Building ID</span>
                ),
                setCellHeaderProps: () => {
                    return { style: { borderRight: "1px solid #D5D5D5" } };
                },
                setCellProps: () => {
                    return {
                        style: {
                            borderRight: "1px solid #D5D5D5",
                            textAlign: "center",
                        },
                    };
                },
                customBodyRender: (
                    value: any,
                    tableMeta: any,
                    updateValue: any,
                ) => {
                    console.log("table data", value);
                    return <b style={{ color: "#0073E6" }}>{value}</b>;
                },
            },
        },
        {
            name: "State",
            Label: "State",
            type: "string",
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => (
                    <span style={{ color: "white" }}>State</span>
                ),
                setCellHeaderProps: () => {
                    return { style: { borderRight: "1px solid #D5D5D5" } };
                },
                setCellProps: () => {
                    return {
                        style: {
                            borderRight: "1px solid #D5D5D5",
                            textAlign: "center",
                        },
                    };
                },
                customBodyRender: (
                    value: any,
                    tableMeta: any,
                    updateValue: any,
                ) => {
                    console.log("table data", value);
                    return <b className="grey ">{value}</b>;
                },
            },
        },

        // {
        //     name: "Contracts",
        //     Label: "Contracts",
        //     type: "number",
        //     options: {
        //         filter: true,
        //         sort: false,
        //         customHeadLabelRender: () => (
        //             <span style={{ color: "white" }}>Contracts</span>
        //         ),
        //         setCellHeaderProps: () => {
        //             return { style: { borderRight: "1px solid #D5D5D5" } };
        //         },
        //         setCellProps: () => {
        //             return {
        //                 style: {
        //                     borderRight: "1px solid #D5D5D5",
        //                     textAlign: "center",
        //                 },
        //             };
        //         },
        //         customBodyRender: (
        //             value: any,
        //             tableMeta: any,
        //             updateValue: any,
        //         ) => {
        //             console.log("table data", value);
        //             return <b style={{ color: "#5C677D" }}>{value}</b>;
        //         },
        //     },
        // },
        // {
        //     name: "StartEndDate",
        //     Label: "StartEndDate",
        //     type: "string",
        //     options: {
        //         filter: true,
        //         sort: false,
        //         customHeadLabelRender: () => (
        //             <span style={{ color: "white" }}>Start - End Date</span>
        //         ),
        //         setCellHeaderProps: () => {
        //             return { style: { borderRight: "1px solid #D5D5D5" } };
        //         },
        //         setCellProps: () => {
        //             return {
        //                 style: {
        //                     borderRight: "1px solid #D5D5D5",
        //                     textAlign: "center",
        //                 },
        //             };
        //         },
        //         customBodyRender: (
        //             value: any,
        //             tableMeta: any,
        //             updateValue: any,
        //         ) => {
        //             console.log("table data", value);
        //             return <b style={{ color: "#5C677D" }}>{value}</b>;
        //         },
        //     },
        // },
        {
            name: "ServiceCode",
            Label: "ServiceCode",
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => (
                    <span style={{ color: "white" }}>Service Code</span>
                ),
                setCellHeaderProps: () => {
                    return { style: { borderRight: "1px solid #D5D5D5" } };
                },
                setCellProps: () => {
                    return {
                        style: {
                            borderRight: "1px solid #D5D5D5",
                            textAlign: "center",
                        },
                    };
                },
                customBodyRender: (
                    value: any,
                    tableMeta: any,
                    updateValue: any,
                ) => {
                    console.log("table data", value);
                    return <b style={{ color: "#5C677D" }}>{value}</b>;
                },
            },
        },
        // {
        //     name: "VendorAmountPercent",
        //     Label: "VendorAmountPercent",
        //     options: {
        //         filter: true,
        //         sort: false,
        //         customHeadLabelRender: () => (
        //             <span style={{ color: "white" }}>
        //                 Vendor Amount - Percent
        //             </span>
        //         ),
        //         setCellHeaderProps: () => {
        //             return { style: { borderRight: "1px solid #D5D5D5" } };
        //         },
        //         setCellProps: () => {
        //             return {
        //                 style: {
        //                     borderRight: "1px solid #D5D5D5",
        //                     textAlign: "center",
        //                 },
        //             };
        //         },
        //         customBodyRender: (
        //             value: {
        //                 amount: any;
        //                 percent: any;
        //             },
        //             tableMeta: any,
        //             updateValue: any,
        //         ) => {
        //             console.log("table data", value);
        //             return (
        //                 <Grid container>
        //                     <Grid item xs={12}>
        //                         <b
        //                             style={{
        //                                 color: "#5C677D",
        //                                 display: "flex",
        //                                 justifyContent: "flex-end",
        //                             }}
        //                             // onClick={(value) =>
        //                             //     // handleBuildingClick()
        //                             // }
        //                         >
        //                             {currencyFormatter(value.amount, currency)}
        //                         </b>
        //                     </Grid>
        //                     <Grid item xs={12}>
        //                         <b className="grey ">
        //                             {currencyFormatter(value.percent, currency)}
        //                         </b>
        //                     </Grid>
        //                 </Grid>
        //             );
        //         },
        //     },
        // },
        // {
        //     name: "Replace",
        //     Label: "Replace",
        //     options: {
        //         filter: true,
        //         sort: false,
        //         customHeadLabelRender: () => (
        //             <span style={{ color: "white" }}>Replace</span>
        //         ),
        //         setCellHeaderProps: () => {
        //             return { style: { borderRight: "1px solid #D5D5D5" } };
        //         },
        //         setCellProps: () => {
        //             return {
        //                 style: {
        //                     borderRight: "1px solid #D5D5D5",
        //                     textAlign: "center",
        //                 },
        //             };
        //         },
        //         customBodyRender: (
        //             value: any,
        //             tableMeta: any,
        //             updateValue: any,
        //         ) => {
        //             console.log("table data", value);
        //             return <b style={{ color: "#5C677D" }}>{value}</b>;
        //         },
        //     },
        // },
        {
            name: "firstMonthProrate",
            Label: "firstMonthProrate",
            options: {
                filter: true,

                sort: false,
                customHeadLabelRender: () => (
                    <span style={{ color: "white" }}>First Month Prorate</span>
                ),
                setCellHeaderProps: () => {
                    return { style: { borderRight: "1px solid #D5D5D5" } };
                },
                setCellProps: () => {
                    return {
                        style: {
                            borderRight: "1px solid #D5D5D5",
                            textAlign: "center",
                        },
                    };
                },
                customBodyRender: (
                    value: any,
                    tableMeta: any,
                    updateValue: any,
                ) => {
                    console.log("table data", value);
                    return (
                        <b
                            style={{
                                color: "#5C677D",
                                display: "flex",
                                justifyContent: "flex-end",
                            }}
                        >
                            {currencyFormatter(value, currency)}
                        </b>
                    );
                },
            },
        },
        {
            name: "LastMonthProrate",
            Label: "LastMonthProrate",
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => (
                    <span style={{ color: "white" }}>Last Month Prorate</span>
                ),
                setCellHeaderProps: () => {
                    return { style: { borderRight: "1px solid #D5D5D5" } };
                },
                setCellProps: () => {
                    return {
                        style: {
                            borderRight: "1px solid #D5D5D5",
                            textAlign: "center",
                        },
                    };
                },
                customBodyRender: (
                    value: any,
                    tableMeta: any,
                    updateValue: any,
                ) => {
                    console.log("table data", value);
                    return (
                        <b
                            style={{
                                color: "#5C677D",
                                display: "flex",
                                justifyContent: "flex-end",
                            }}
                        >
                            {currencyFormatter(value, currency)}
                        </b>
                    );
                },
            },
        },
    ];

    const ContractDetailsInvoicesSchema = [
        {
            name: "InvoiceNo",
            Label: "InvoiceNo",
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => (
                    <span style={{ color: "white" }}>Invoice No.</span>
                ),
                setCellHeaderProps: () => {
                    return { style: { borderRight: "1px solid #D5D5D5" } };
                },
                setCellProps: () => {
                    return {
                        style: {
                            borderRight: "1px solid #D5D5D5",
                            textAlign: "center",
                        },
                    };
                },
                customBodyRender: (
                    value: any,
                    tableMeta: any,
                    updateValue: any,
                ) => {
                    console.log("table data", value, tableMeta.rowData);
                    return <b style={{ color: "#0073E6" }}>{value}</b>;
                },
            },
        },
        {
            name: "StatusReason",
            Label: "StatusReason",

            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => (
                    <span style={{ color: "white" }}>Status Reason</span>
                ),
                setCellHeaderProps: () => {
                    return { style: { borderRight: "1px solid #D5D5D5" } };
                },
                setCellProps: () => {
                    return {
                        style: {
                            borderRight: "1px solid #D5D5D5",
                            textAlign: "center",
                        },
                    };
                },
                customBodyRender: (
                    value: any,
                    tableMeta: any,
                    updateValue: any,
                ) => {
                    console.log("table data", value);
                    return <b style={{ color: "#5C677D" }}>{value}</b>;
                },
            },
        },
        {
            name: "TotalAmount",
            Label: "TotalAmount",
            type: "string",
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => (
                    <span style={{ color: "white" }}>Total Amount</span>
                ),
                setCellHeaderProps: () => {
                    return { style: { borderRight: "1px solid #D5D5D5" } };
                },
                setCellProps: () => {
                    return {
                        style: {
                            borderRight: "1px solid #D5D5D5",
                            textAlign: "center",
                        },
                    };
                },
                customBodyRender: (
                    value: any,
                    tableMeta: any,
                    updateValue: any,
                ) => {
                    console.log("table data", value);
                    return (
                        <b
                            style={{
                                display: "flex",
                                justifyContent: "flex-end",
                            }}
                            className="green"
                        >
                            {currencyFormatter(value, currency)}
                        </b>
                    );
                },
            },
        },
        {
            name: "Customer",
            Label: "Customer",
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => (
                    <span style={{ color: "white" }}>Customer</span>
                ),
                setCellHeaderProps: () => {
                    return { style: { borderRight: "1px solid #D5D5D5" } };
                },
                setCellProps: () => {
                    return {
                        style: {
                            borderRight: "1px solid #D5D5D5",
                            textAlign: "center",
                        },
                    };
                },
                customBodyRender: (
                    value: any,
                    tableMeta: any,
                    updateValue: any,
                ) => {
                    console.log("table data", value);
                    return <b style={{ color: "#5C677D" }}>{value}</b>;
                },
            },
        },
        {
            name: "BuildingID",
            Label: "BuildingID",
            type: "string",
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => (
                    <span style={{ color: "white" }}>Building ID</span>
                ),
                setCellHeaderProps: () => {
                    return { style: { borderRight: "1px solid #D5D5D5" } };
                },
                setCellProps: () => {
                    return {
                        style: {
                            borderRight: "1px solid #D5D5D5",
                            textAlign: "center",
                        },
                    };
                },
                customBodyRender: (
                    value: any,
                    tableMeta: any,
                    updateValue: any,
                ) => {
                    console.log("table data", value);
                    return <b style={{ color: "#0073E6" }}>{value}</b>;
                },
            },
        },
        {
            name: "State",
            Label: "State",
            type: "string",
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => (
                    <span style={{ color: "white" }}>State</span>
                ),
                setCellHeaderProps: () => {
                    return { style: { borderRight: "1px solid #D5D5D5" } };
                },
                setCellProps: () => {
                    return {
                        style: {
                            borderRight: "1px solid #D5D5D5",
                            textAlign: "center",
                        },
                    };
                },
                customBodyRender: (
                    value: any,
                    tableMeta: any,
                    updateValue: any,
                ) => {
                    console.log("table data", value);
                    return <b className="grey ">{value}</b>;
                },
            },
        },

        {
            name: "DocDate",
            Label: "DocDate",
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => (
                    <span style={{ color: "white" }}>Doc Date</span>
                ),
                setCellHeaderProps: () => {
                    return { style: { borderRight: "1px solid #D5D5D5" } };
                },
                setCellProps: () => {
                    return {
                        style: {
                            borderRight: "1px solid #D5D5D5",
                            textAlign: "center",
                        },
                    };
                },
                customBodyRender: (
                    value: any,
                    tableMeta: any,
                    updateValue: any,
                ) => {
                    console.log("table data", value);
                    return <b style={{ color: "#5C677D" }}>{value}</b>;
                },
            },
        },

        {
            name: "CreatedOn",
            Label: "CreatedOn",
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => (
                    <span style={{ color: "white" }}>Created On</span>
                ),
                setCellHeaderProps: () => {
                    return { style: { borderRight: "1px solid #D5D5D5" } };
                },
                setCellProps: () => {
                    return {
                        style: {
                            borderRight: "1px solid #D5D5D5",
                            textAlign: "center",
                        },
                    };
                },
                customBodyRender: (
                    value: any,
                    tableMeta: any,
                    updateValue: any,
                ) => {
                    console.log("table data", value);
                    return <b style={{ color: "#5C677D" }}>{value}</b>;
                },
            },
        },
        {
            name: "MonthYear",
            Label: "MonthYear",
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => (
                    <span style={{ color: "white" }}>Month & Year</span>
                ),
                setCellHeaderProps: () => {
                    return { style: { borderRight: "1px solid #D5D5D5" } };
                },
                setCellProps: () => {
                    return {
                        style: {
                            borderRight: "1px solid #D5D5D5",
                            textAlign: "center",
                        },
                    };
                },
                customBodyRender: (
                    value: any,
                    tableMeta: any,
                    updateValue: any,
                ) => {
                    console.log("table data", value);
                    return <b style={{ color: "#5C677D" }}>{value}</b>;
                },
            },
        },
        {
            name: "PastDueDays",
            Label: "PastDueDays",
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => (
                    <span style={{ color: "white" }}>Past Due Days</span>
                ),
                setCellHeaderProps: () => {
                    return { style: { borderRight: "1px solid #D5D5D5" } };
                },
                setCellProps: () => {
                    return {
                        style: {
                            borderRight: "1px solid #D5D5D5",
                            textAlign: "center",
                        },
                    };
                },
                customBodyRender: (
                    value: any,
                    tableMeta: any,
                    updateValue: any,
                ) => {
                    console.log("table data", value);
                    return value?.color ? (
                        <b style={{ color: value?.color }}>{value.days}</b>
                    ) : (
                        <b style={{ color: "#5C677D" }}>{value.days}</b>
                    );
                },
            },
        },
        {
            name: "PaymentStatus",
            Label: "PaymentStatus",
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => (
                    <span style={{ color: "white" }}>Payment Status</span>
                ),
                setCellHeaderProps: () => {
                    return { style: { borderRight: "1px solid #D5D5D5" } };
                },
                setCellProps: () => {
                    return {
                        style: {
                            borderRight: "1px solid #D5D5D5",
                            textAlign: "center",
                        },
                    };
                },
                customBodyRender: (
                    value: {
                        status: string;
                        color: string;
                    },
                    tableMeta: any,
                    updateValue: any,
                ) => {
                    console.log("table data", value);
                    return value.status === "Paid" ? (
                        <>
                            <b className="Completed_dot"></b>
                            <b style={{ color: "#00AE50", padding: "3%" }}>
                                {value.status}
                            </b>
                        </>
                    ) : (
                        <>
                            {value.color == "#00AE50" ? (
                                <b className="Completed_dot"></b>
                            ) : (
                                <b className="Failed_dot"></b>
                            )}
                            <b style={{ color: value.color, padding: "3%" }}>
                                {value.status}
                            </b>
                        </>
                    );
                },
            },
        },
    ];

    return (
        <>
            {alert ? handleErrorAlert(errr.code, errr.msg) : <></>}
            <Backdrop
                className={classes.backdrop}
                open={
                    ContractDetailsVendorTableDataLoading ||
                    InvoiceContractDataLoading
                }
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <Grid
                container
                spacing={2}
                style={{ padding: "0 0 2%", marginTop: "2%" }}
            >
                <Grid item xs={12} md={8}>
                    <Paper elevation={4}>
                        <Grid container className="padding-1-percent">
                            <Grid
                                item
                                xs={8}
                                style={{
                                    padding: "1% 1.5% 1% 1%",
                                    borderRight: "1px dashed #C9CDD6",
                                }}
                            >
                                <Grid
                                    container
                                    className="padding-left-1-per margin-top-1-per"
                                >
                                    <Grid item xs={1}>
                                        <Icons Icon={"Book"} />
                                    </Grid>

                                    <Grid
                                        item
                                        xs={1}
                                        className="padding-left-3-per"
                                    >
                                        {
                                            InvoiceContractHeaderDataSet?.contractno
                                        }
                                    </Grid>

                                    <Grid
                                        item
                                        xs={3}
                                        className="padding-left-3-per"
                                    ></Grid>
                                    <Grid
                                        item
                                        xs={1}
                                        className="padding-left-5-per"
                                    >
                                        <Icons Icon={"buildingContract"} />
                                    </Grid>
                                    <Grid
                                        item
                                        xs={6}
                                        className="padding-left-5-per"
                                    >
                                        {
                                            InvoiceContractHeaderDataSet?.buildingName
                                        }
                                    </Grid>
                                </Grid>
                                <Grid
                                    container
                                    className=" padding-left-1-per margin-top-3-per"
                                >
                                    <Grid item xs={1}>
                                        <Icons Icon={"setting"} />
                                    </Grid>

                                    <Grid
                                        item
                                        xs={11}
                                        className="text-left padding-left-2-per"
                                    >
                                        {InvoiceContractHeaderDataSet?.address}
                                    </Grid>
                                </Grid>
                                <Grid
                                    container
                                    className="padding-left-3-per margin-top-3-per"
                                    justifyContent="space-between"
                                >
                                    <Grid
                                        item
                                        xs={6}
                                        style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            // alignItems: "center",
                                            justifyContent: "center",
                                            border: `${
                                                InvoiceContractHeaderDataSet?.type?.toLowerCase() ===
                                                "active"
                                                    ? "1px solid #00DA64"
                                                    : "1px solid #DD9200"
                                            }`,
                                            borderRadius: "3px",
                                            background: `${
                                                InvoiceContractHeaderDataSet?.type?.toLowerCase() ===
                                                "active"
                                                    ? "#EDFDF2"
                                                    : "#FCF5E9"
                                            }`,
                                            alignItems: "center",
                                        }}
                                    >
                                        {InvoiceContractHeaderDataSet?.type}
                                    </Grid>

                                    <Grid
                                        item
                                        xs={3}
                                        className="font-big Completed"
                                    >
                                        {currencyFormatter(
                                            InvoiceContractHeaderDataSet?.amount,
                                            currency,
                                        )}
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid
                                item
                                xs={4}
                                style={{ padding: "1% 1% 1% 1.5%" }}
                            >
                                <Grid container>
                                    <Grid item xs={6}>
                                        {/* <Grid
                                            container
                                            className="text-left margin-top-1-per"
                                        >
                                            <Grid
                                                item
                                                xs={12}
                                                className="lightgrey"
                                            >
                                                Consolidation Code
                                            </Grid>
                                            <Grid
                                                item
                                                xs={12}
                                                className="margin-top-1-per grey1"
                                            >
                                                {
                                                    InvoiceContractHeaderDataSet?.code
                                                }
                                            </Grid>
                                        </Grid> */}
                                        {InvoiceContractHeaderDataSet?.PoNo ? (
                                            <Grid
                                                container
                                                className="text-left"
                                            >
                                                <Grid
                                                    item
                                                    xs={12}
                                                    className="lightgrey"
                                                >
                                                    PO NO.
                                                </Grid>
                                                <Grid
                                                    item
                                                    xs={12}
                                                    className="margin-top-1-per grey1"
                                                >
                                                    {
                                                        InvoiceContractHeaderDataSet?.PoNo
                                                    }
                                                </Grid>
                                            </Grid>
                                        ) : (
                                            <></>
                                        )}
                                        <Grid
                                            container
                                            className="text-left margin-top-5-per"
                                        >
                                            <Grid
                                                item
                                                xs={12}
                                                className="lightgrey"
                                            >
                                                Total Service Days
                                            </Grid>
                                            <Grid
                                                item
                                                xs={12}
                                                className="margin-top-1-per grey1"
                                            >
                                                {Math.floor(
                                                    InvoiceContractHeaderDataSet?.Length,
                                                )}
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={4}></Grid>

                                    {/* <Grid item xs={5} className="text-left">
                                        <Grid container>
                                            <Grid
                                                item
                                                xs={12}
                                                className="lightgrey"
                                            >
                                                PO NO.
                                            </Grid>
                                            <Grid
                                                item
                                                xs={12}
                                                className="margin-top-1-per grey1"
                                            >
                                                {
                                                    InvoiceContractHeaderDataSet?.PoNo
                                                }
                                            </Grid>
                                        </Grid>
                                    </Grid> */}

                                    {/* <Grid
                                        item
                                        xs={1}
                                        style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            justifyContent: "flex-end",
                                            alignItems: "center",
                                        }}
                                    >
                                        <Grid container>
                                            <Grid item xs={10}></Grid>
                                            <Grid item xs={1}>
                                                <Icons Icon={"penimage"} />
                                            </Grid>
                                        </Grid>
                                    </Grid> */}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>

                <Grid item sm={8} xs={12}>
                    <ButtonGroups
                        controlList={controlList}
                        handleDocList={handleDocList}
                        gridSpacing={2}
                        externalActive={activeList}
                    />
                </Grid>

                {activeList === "Service Detail" ? (
                    <Grid item xs={12}>
                        {vendorTableData?.length ? (
                            <MuiDatatables
                                title={""}
                                hideAddButton={true}
                                header={ContractDetailsVendorSchema}
                                detail={[vendorTableData, setvendorTableData]}
                                // handleAddSideEffect={handleAddSideEffect}
                                // handleDeleteSideEffect={handleDeleteSideEffect}

                                initRow={false}
                            />
                        ) : (
                            <img
                                src={NoVendors}
                                style={{ paddingTop: "4%",width: "95vh" }}
                                alt="No Vendors"
                            />
                        )}
                    </Grid>
                ) : (
                    <Grid item xs={12}>
                        {invoiceTableData?.length ? (
                            <MuiDatatables
                                title={""}
                                hideAddButton={true}
                                header={ContractDetailsInvoicesSchema}
                                detail={[invoiceTableData, setinvoiceTableData]}
                                initRow={false}
                            />
                        ) : (
                            <img
                                src={NoInvoices}
                                style={{ paddingTop: "4%",width: "95vh" }}
                                alt="No Invoices"
                            />
                        )}
                    </Grid>
                )}

                <SwipeableDrawerComp
                    drawerState={[sideBar, setSideBar]}
                    Child={
                        sideScreen === "+ Add User" ? <ContractsChild /> : <></>
                    }
                    header={sideScreen === "+ Add User" ? "Add User" : ""}
                    headerColor={"#5C677D"}
                />
            </Grid>
        </>
    );
};

const mapStateToProps = (state: any) => {
    return {
        currentContext: state.context?.userDefaults,
        selectedCustomer: state.selectedCustomer,
        currency: state.currency,
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        setBreadcrumbsData: (data: any) => {
            dispatch(storeBreadcrumbs(data));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Body);
