import React, { useState, useEffect } from "react";
import { Pie } from "react-chartjs-2";

interface Chartprops {
    Chardata: InvStatus;
    maxWidth?: string;
    maxHeight?: string;
}
interface InvStatus {
    lable: string[];
    partVal: number[];
    value: number[];
    color: string[];
    borderColor: string;
    valuedisp: string[];
}

function InvoiceChart(props: Chartprops) {
    const { Chardata, maxWidth, maxHeight } = props;

    const [ChardataSet, setCharDataSet] = useState({});

    const [chartData, setChartData] = useState<any>(Chardata);

    useEffect(() => {
        setCharDataSet({
            labels: chartData?.lable ? chartData?.lable : [],
            datasets: [
                {
                    label: "Invoices",

                    backgroundColor: chartData?.color ? chartData?.color : [],
                    borderColor: chartData?.borderColor
                        ? chartData?.borderColor
                        : "rgba(125, 133, 151, 1)",
                    borderWidth: 4,
                    data: chartData?.value,
                },
            ],
        });
    }, [Chardata]);

    return (
        <>
            <Pie
                data={ChardataSet}
                style={{
                    maxHeight: maxHeight,
                    maxWidth: maxWidth,
                }}
                options={{
                    responsive: true,
                    plugins: {
                        legend: {
                            display: false,
                            positon: "right",
                            alignment: "start",
                            labels: {
                                color: "rgb(255, 99, 132)",
                                pointStyle: "circle",
                                usePointStyle: true,
                            },
                        },
                    },
                }}
            />
        </>
    );
}

const PieChart = React.memo(InvoiceChart);

export default PieChart;
