import DateFnsUtils from "@date-io/date-fns";
import {
    KeyboardDatePicker,
    MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import "./Datepicker.scss";
import React from "react";

interface Props {
    value: Date | null;
    onChange: (
        date: MaterialUiPickersDate,
        value?: string | null | undefined,
    ) => void;
    width?: string;
    label?: string;
    format?: string;
}

export default function CustomDatePicker(props: Props) {
    const { value, onChange, width, label, format } = props;
    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
                clearable
                variant="inline"
                defaultValue={new Date()}
                value={value}
                onChange={onChange}
                label={label}
                format={format}
                inputVariant="outlined"
                color="secondary"
                style={{ width: width }}
                className="custom-date-picker"
            />
        </MuiPickersUtilsProvider>
    );
}
