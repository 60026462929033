import { STORE_VENDOR_DETAILS } from "../../actionTypes";

const storeVendorDetails = (data: any) => {
  return {
    type: STORE_VENDOR_DETAILS ,
    payload: data,
  };
};

/**
 * Common File redux
 * @param code
 */
export function storeSelectedVendor<T>(data: any) {
  return (dispatch: any) => {
    dispatch(storeVendorDetails(data));
  };
}
