import { InputLabel, MenuItem, Select, FormControl, OutlinedInput } from "@material-ui/core";
import React from "react";
import "./Dropdown.scss";

interface ListProps {
    desc: string | number;
    value: string | number;
}

interface DropdownProps {
    label: string;
    id: string;
    value: string | number;
    list: ListProps[];
    handleChange: (e: React.ChangeEvent<{ value: unknown }>) => void;
    width: string;
}
const Dropdown = (props: DropdownProps) => {
    const { label, id, value, list, handleChange, width } = props;
    const inputLabel = React.useRef(null);
    const [labelWidth, setLabelWidth] = React.useState(0);
    React.useEffect(() => {
        if (inputLabel?.current)
            setLabelWidth((inputLabel.current as any).offsetWidth);
    }, []);
    return (
        <div className="custom-dropdown">
            <FormControl
                id={id}
                variant="outlined"
                color="secondary"
                style={{ width: width }}
            >
                <InputLabel
                    id={id}
                    style={{
                        fontSize: "13px",
                        marginBottom: "2px",
                    }}
                    shrink
                    htmlFor={`outlined-${id}-always-notched`}
                    ref={inputLabel}
                >
                    {label}
                </InputLabel>
                <Select
                    id={id}
                    name={id}
                    value={value}
                    onChange={handleChange}
                    label={label}
                    input={
                        <OutlinedInput
                            notched
                            labelWidth={labelWidth}
                            name={id}
                            id={`outlined-${id}-always-notched`}
                        />
                    }
                    variant="outlined"
                >
                    {list.map((element, index) => {
                        return (
                            <MenuItem value={`${element?.value}`} key={index} >
                                {element?.desc}
                            </MenuItem>
                        );
                    })}
                </Select>
            </FormControl>
        </div>
    );
};

export default Dropdown;
