const AmoritizationQuerySV = `
mutation(
    $entity:String
    $ou:Int
    $service:String
    $User:String
    $languageId:Int
    $VendorCode:String
    $Vendorname:String
    $contractno:String
    $merrorid:String 
){
    GetAmortizationListSV(
        entity:$entity
        ou:$ou
        service:$service
        User:$User
        languageId:$languageId
        VendorCode:$VendorCode
        Vendorname:$Vendorname
        contractno:$contractno
        merrorid:$merrorid
    ){ 
        PaymentNo,    
        DueDate,
        Payment,  
        PrincipalAmount,
        InterestAmount,    
        Balance,   
        Paid, 
        DatePaid,
        VoucherNo,    
        CreatedOn, 
    }
}
`;

export default AmoritizationQuerySV;
