const QAVisits = `
mutation (
    $entity: String
    $ou: Int
    $service: String
    $User: String
    $languageId: Int
   
    $merrorid: String
    $vendorname: String,
    $vendorcode:String,
){

    QAVisits (  
        entity: $entity
        ou: $ou
        service: $service
        User: $User
        languageId: $languageId
       
        merrorid:$merrorid
        vendorname:$vendorname
        vendorcode:$vendorcode
        ){
        Building{
            name
            id
        }
        Address{
            Area
            City
            State
            Pincode
        }
        
   
        PrimaryContact
        AccountManager
        LastInspection
      
        FullfilledValue
        NoofInspections
    }
}`;

export default QAVisits;
