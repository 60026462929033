import { ADMIN_KEY } from "../../actionTypes";
const initialState = false; //customer Portal
const storeAdminKey = (prevState = initialState, action: any) => {
    switch (action.type) {
        case ADMIN_KEY:
            return action.payload;
        default:
            return prevState;
    }
};
export default storeAdminKey;
