import { Grid, Box, Paper, Divider, Button } from "@material-ui/core";
import Alert from "Common/Alerts/Alerts";
import BackdropLoading from "Common/Backdrop/Backdrop";
import MUIButton from "Common/Button/Button";
import Dropdown from "Common/Dropdown/Dropdown";
import Dropzone from "Common/Dropzone/DropZone";
import TextFields from "Common/TextField/TextField";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
    DirectDepositInfoVenQuery,
    FileUploadcomboQuery,
    UploadDocumentQuery,
    UploadFileQuery,
} from "ReactQuery/Query";
import useGetPosts from "ReactQuery/reactQuery";
import { setTimeout } from "timers";
import { log } from "util";

const TypeOfDocListcpy = [
    {
        desc: "W3-document",
        value: "w3-document",
    },
    {
        desc: "Entity Document",
        value: "Entity-Document",
    },
    {
        desc: "Profile Details",
        value: "profile-details",
    },
    {
        desc: "Guidelines Doc",
        value: "test-soc",
    },
];
interface FileWithInvalid extends File {
    invalid: boolean;
}

const UploadDocsSideDraw = (props: any) => {
    const {
        getDepositInfoDatas,
        currentContext,
        selectedVendor,
        supplierCode,
        closeSideBar,
        sidebarstate,
        onSubmitRefetch,
        userReadOnlyAccess
    } = props;

    const [saveDepositinfo, setsaveDepositinfo] = useState<any>(true);
    const [alert, setAlert] = useState(false);
    const [errr, seterr] = useState({
        code: 0,
        msg: "",
    });
    const [mainDocument, setMainDocument] = useState<FileWithInvalid | any>([File]);
    const [TypeOfDocList, setTypeOfDocList] = useState<any[]>([]);
    const [postDirectDepositData, setPostDirectDepositData] = useState<any>({
        ABA: "",
        AccountName: getDepositInfoDatas?.SupplierLegalName,
        AccountNumber: getDepositInfoDatas?.AccountNumber,
        Bank_name: getDepositInfoDatas?.BankName,
        BICNoSwiftNo: getDepositInfoDatas?.SupplierCode,
        BranchName: getDepositInfoDatas?.BankName,
        CityofBank: "",
        Supplier_name: getDepositInfoDatas?.SupplierName,
    });
    const handleErrorAlert = (code: number, message: string) => {
        console.log(code, message);

        Alert({
            statusCode: code,
            message: message,
            willClose: () => {
                seterr({ code: 0, msg: "" });
                onSubmitRefetch((prev:number)=>prev+1)
                setAlert(false);
                closeSideBar(false);
            },
        });
    };

    const [fileUploadData, setFileUploadData] = useState({
        typeOfDoc: "",
        docTitle: "",
        fileName: "",
    });
    const handleChange = (e: any) => {
        // console.log(e.target.name);
        // console.log(e.target.value);

        setFileUploadData((prevState: any) => ({
            ...prevState,
            [e?.target?.name]: e?.target?.value,
        }));
    };

    const handleReset = () => {
        setFileUploadData((prevState: any) => ({
            ...prevState,
            docTitle: "",
            typeOfDoc: TypeOfDocList?.[0]?.value ?? "",
        }));
        setMainDocument([]);
    };

    useEffect(() => {
        // console.log("main",errr);

        seterr({ code: 0, msg: "" });

        setAlert(false);
    }, [sidebarstate, mainDocument, fileUploadData]);
    const {
        data: PostDirectDeposit,
        error: PostDirectDepositError,
        isLoading: PostDirectDepositLoading,
    } = useGetPosts({
        name: "PostDirectDeposit_Query",
        query: DirectDepositInfoVenQuery,
        variables: {
            suppcode: selectedVendor?.supplierName,
            createDepositDirectInfo: {
                ABA: postDirectDepositData?.ABA,
                AccountName: postDirectDepositData?.AccountName,
                AccountNumber: postDirectDepositData?.AccountNumber,
                Bank_name: postDirectDepositData?.Bank_name,
                BICNoSwiftNo: postDirectDepositData?.BICNoSwiftNo,
                BranchName: postDirectDepositData?.BranchName,
                CityofBank: postDirectDepositData?.CityofBank,
                Supplier_name: postDirectDepositData?.Supplier_name,
            },
        },
        header: {
            ou: currentContext?.ouId,
            role: currentContext?.roleName,
            lang: currentContext?.langId,
        },
        skip: saveDepositinfo || true,
    });

    useEffect(() => {
        if (PostDirectDeposit?.DirectDepositInfo) {
            seterr({
                code: 200,
                // msg: `Deposit Details Updated Sucessfully`,
                msg: `${PostDirectDeposit?.DirectDepositInfo.errrormsg} -- ${PostDirectDeposit?.DirectDepositInfo.TranNo}`,
            });
            setsaveDepositinfo(true);
        }
    }, [PostDirectDeposit]);

    useEffect(() => {
        if (PostDirectDepositError && !PostDirectDepositLoading) {
            const data = JSON.parse(JSON.stringify(PostDirectDepositError));
            const errmsg = data?.response?.errors[0].message;
            seterr({
                code: 400,
                msg: errmsg,
            });

            setsaveDepositinfo(true);
        }
    }, [PostDirectDepositError, PostDirectDepositLoading]);

    const {
        data: FileUploadcomboData,
        error: FileUploadcomboError,
        isLoading: FileUploadcomboLoading,
    } = useGetPosts({
        name: `FileUploadcomboQuery${supplierCode}`,
        query: FileUploadcomboQuery,
        variables: {
            // entity: "OW_Cust_portal_getcompprfluser",
            role:currentContext?.roleName,
            langId: currentContext?.langId,
            ou: currentContext?.ouId,
            supplierCode,
            callingFrom: "dropdown_load",
        },
        // skip: portalKey,
    });

    useEffect(() => {
        if (FileUploadcomboData?.FUcombo) {
            console.log(FileUploadcomboData);
            setTypeOfDocList(FileUploadcomboData?.FUcombo ?? []);
            setFileUploadData((prevState: any) => ({
                ...prevState,
                typeOfDoc: FileUploadcomboData?.FUcombo[0]?.value ?? "",
            }));
        }
    }, [FileUploadcomboData]);

    useEffect(() => {
        if (FileUploadcomboError && !FileUploadcomboLoading) {
            const data = JSON.parse(JSON.stringify(FileUploadcomboError));
            const errmsg = data?.response?.errors[0].message;
            seterr({
                code: 400,
                msg: errmsg,
            });
        }
    }, [FileUploadcomboError, FileUploadcomboLoading]);

    const [onUpload, setOnUpload] = useState(true);
    const [serverFileName  , setServerFileName   ] = useState("");
    const {
        data: UploadDocumentData,
        error: UploadDocumentError,
        isLoading: UploadDocumentLoading,
    } = useGetPosts({
        name: `UploadDocumentQuery${supplierCode}_${serverFileName}`,
        query: UploadDocumentQuery,
        variables: {
            // entity: "OW_Cust_portal_getcompprfluser",
            supplierCode,
            callingFrom: "upload",
            fileType: fileUploadData?.typeOfDoc,
            docTitle: fileUploadData?.docTitle,
            fileName: mainDocument?.[0]?.name,
            role:currentContext?.roleName,
            langId: currentContext?.langId,
            ou: currentContext?.ouId,
            serverFileName
        },
        skip: onUpload || !Boolean(serverFileName),
        cacheState:true
        // tryForceFetch: false,
    });

    useEffect(() => {
        if (UploadDocumentData?.uploadDocument && !onUpload) {
            console.log("serverFileName",serverFileName);
            
            console.log(UploadDocumentData);
            closeSideBar(false);
            seterr({
                code: 200,
                msg: UploadDocumentData?.uploadDocument?.message ?? "",
            });
            setAlert(true);
            setOnUpload(true);
        }
    }, [UploadDocumentData]);

    useEffect(() => {
        if (UploadDocumentError && !UploadDocumentLoading) {
            const data = JSON.parse(JSON.stringify(UploadDocumentError));
            const errmsg = data?.response?.errors[0].message;
            console.log(errmsg);
            console.log(UploadDocumentError);

            seterr({
                code: 400,
                msg: errmsg,
            });
            // setAlert(true);
            setOnUpload(true);
            
        }
    }, [UploadDocumentError, UploadDocumentLoading]);
    const [uploadDtatus, setUploadDtatus] = useState(true);
    const {
        data: UploadFileData,
        error: UploadFileError,
        isLoading: UploadFileLoading,
    } = useGetPosts({
        name: `UploadFileQuery${uploadDtatus}_${mainDocument?.[0]?.name}`,
        query: UploadFileQuery,
        variables: {
            // entity: "OW_Cust_portal_getcompprfluser",

            // supplierCode,
            // callingFrom: "upload",
            // fileType: fileUploadData?.typeOfDoc,
            // docTitle: fileUploadData?.docTitle,
            files : mainDocument[0] ,
            relativePath:`doc/eameqp/`,
            lineNo:"1",
            role:currentContext?.roleName,
            langId: currentContext?.langId,
            ou: currentContext?.ouId,
        },
        skip: uploadDtatus ,
        cacheState:true
        // tryForceFetch: false,
    });

    console.log("@@@ upload",mainDocument);
    console.log("@@@ upload",Boolean(mainDocument[0]));
    useEffect(() => {
        console.log(UploadFileData);
        if (UploadFileData?.UploadFile) {
            console.log("fileu",UploadFileData?.UploadFile);
            
            // seterr({
            //     code: 200,
            //     msg: UploadFileData?.uploadDocument?.message ?? "",
            // });
            // setAlert(true);
            // setOnUpload(true);
            setServerFileName(UploadFileData?.UploadFile?.fileId||"")
            setUploadDtatus(true);
            setOnUpload(false);
            
        }
    }, [UploadFileData]);

    useEffect(() => {
        if (UploadFileError && !UploadFileLoading) {
            const data = JSON.parse(JSON.stringify(UploadFileError));
            const errmsg = data?.response?.errors[0].message;
            console.log(errmsg);
            console.log(UploadFileError);

            seterr({
                code: 400,
                msg: errmsg,
            });
            // setAlert(true);
            setOnUpload(true);
            setServerFileName("");
        }
    }, [UploadFileError, UploadFileLoading]);

    // console.log("getDepositInfoDatas", getDepositInfoDatas);
    return (
        <>
            {alert && Boolean(errr.code === 200) ? (
                handleErrorAlert(errr.code, errr.msg)
            ) : (
                <></>
            )}
            <BackdropLoading openStates={PostDirectDepositLoading || UploadFileLoading || FileUploadcomboLoading || UploadDocumentLoading} />

            <Grid
                container
                spacing={2}
                style={{
                    width: "80vh",
                    margin: "1.5% 0% 1% 4%",
                    paddingTop: "2.1%",
                }}
                alignItems="center"
            >
                <Grid item xs={12}>
                    {/* <TextFields
                            label={"Type"}
                            id={"TypeOfDoc"}
                            value={"w3-document"}
                            handleChange={handleChange}
                            width={"90%"}
                            multiline={false}
                            rows={0}
                        /> */}
                    <Dropdown
                        label={"Type"}
                        id={"typeOfDoc"}
                        value={fileUploadData?.typeOfDoc}
                        list={TypeOfDocList}
                        handleChange={handleChange}
                        width={"90%"}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextFields
                        label={"Document Title"}
                        id={"docTitle"}
                        value={fileUploadData?.docTitle}
                        handleChange={handleChange}
                        width={"90%"}
                        multiline={false}
                        rows={0}
                    />
                </Grid>
                <Grid item xs={12}>
                    {/* <Paper variant="outlined" className={"notifPadding"} style={{ backgroundColor:'rgba(247, 249, 251, 0.5)', height: '45vh',width:"90%",border:'1px dashed gray' }}>

                     </Paper> */}
                    <Dropzone
                        dragzoneCaption=""
                        files={[mainDocument, setMainDocument]}
                    />
                </Grid>

                <Grid
                    container
                    style={{
                        height: 125,
                        justifyContent: "center",
                        alignItems: "center",
                        position: "absolute",
                        bottom: 0,
                        right: 0,
                    }}
                >
                    <Grid item xs={12}>
                        {errr.code !== 0 ? (
                            <Grid
                                item
                                xs={12}
                                style={{
                                    textAlign: "center",
                                    padding: "2%",
                                }}
                                className={
                                    errr.code === 200
                                        ? "green-light-background"
                                        : "red-light-background"
                                }
                            >
                                <span
                                    className={`${
                                        errr?.code === 200
                                            ? "Success_dot"
                                            : "Failed_dot"
                                    }`}
                                ></span>{" "}
                                <b>{errr.msg}</b>
                            </Grid>
                        ) : (
                            <Grid
                                item
                                xs={12}
                                style={{
                                    textAlign: "center",
                                    padding: "2%",
                                }}
                            >
                                <span></span> <b></b>
                            </Grid>
                        )}
                    </Grid>
                    <Grid item xs={12}>
                        <Divider variant="middle" />
                    </Grid>
                    <Grid item xs={8}></Grid>
                    <Grid item xs={2}>
                        <MUIButton
                            label="Reset"
                            onClick={()=>{
                                if(userReadOnlyAccess){
                                    handleReset()

                                }
                            }}
                            type="outlined"
                            font="fontMedium"
                        />
                    </Grid>
                    <Grid item xs={2}>
                        {mainDocument?.[0]?.invalid ? (
                            <Button variant="contained" disabled >
                                Submit
                            </Button>
                        ) : (
                            <MUIButton
                                label="Submit"
                                onClick={() => {
                                    
                                    if(userReadOnlyAccess){

                                        setUploadDtatus(false);
                                    }
                                }}
                                type="filled"
                                font="fontMedium"
                            />
                        )}
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};

const mapStateToProps = (state: any) => {
    return {
        currentContext: state.context?.userDefaults,
        userName: state.context?.name,
        selectedCustomer: state.selectedCustomer,
        portalKey: state.portalKey,
        selectedVendor: state.selectedVendor,
        supplierCode: state?.selectedVendor?.supplierCode,
        loginUser: state?.selectedVendor?.loginUser,
        userReadOnlyAccess: state?.userReadOnlyAccess,
    };
};

export default connect(mapStateToProps)(UploadDocsSideDraw);
