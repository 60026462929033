import { useQuery } from "react-query";
import { GraphQLClient, gql } from "graphql-request";
import Configuration from "Configuration";
//import { tranSummaryQuery } from "./Query";
//const API_URL = `https://digitalgarage.ramcoerp.com:4607/customerportaltest/`;
const API_URL = `${Configuration.getConfiguration().getAppAPILocation()}/graphql`;
const graphQLClient = new GraphQLClient(API_URL, {
    headers: {
        authorization: localStorage.getItem("session")
            ? `${localStorage.getItem("session")}`
            : "",
        "x-xsrf-token": localStorage.csrf_token ? localStorage.csrf_token : "",
    },
});
interface GetProps {
    name: string;
    query: string;
    variables: any;
    header?: {
      lang: string;
        ou: string;
        role: string;
    };
    skip ?: boolean;
    retry ?: boolean;
    tryForceFetch?:boolean;
    cacheState?:boolean;
}
interface defaultContextProps {
    langId: string;
    ouId: string;
    ouDescription: string;
    roleName: string;
}
export default function useGetPosts(props: GetProps) {
    const { name, query, variables, header, skip,retry,tryForceFetch,cacheState} = props;
    // console.log("prp",props);
    
    const graphQLClient = new GraphQLClient(API_URL, {
        headers: {
            ...header,
            authorization: localStorage.getItem("session")
                ? `${localStorage.getItem("session")}`
                : "",
            "x-xsrf-token": localStorage.csrf_token
                ? localStorage.csrf_token
                : "",
        },
    });
    return useQuery(
        name,
        async () => {
            const data = await graphQLClient.request(
                gql`
                    ${query}
                `,
                variables,
               
            );
            return data;
        },
        {
            // onError: (err) => {
            //     return err
            // },  
            enabled: skip ? false : true,
            refetchInterval: false,
            refetchIntervalInBackground: false,
            refetchOnMount: false,
            refetchOnWindowFocus: false,
            refetchOnReconnect: false,
            retry: retry ? true : false,
            cacheTime: cacheState ? 0: 0,
            staleTime: tryForceFetch ? 0 : 0,
            // (2*60*60)//if we are  not passinf tryForceFetch  then it is undefined 
            
        },
    );
}
// query ExampleQuery {
//     books {
//       title
//     }
//   }