import { STORE_DASH_DATA } from "../../actionTypes";

const initialState = <any>[] 

const storeDashData = (
  prevState = initialState,
  action: any
) => {
  switch (action.type) {
    case STORE_DASH_DATA:
      return action.payload;
    default:
      return prevState;
  }
};

export default storeDashData;
