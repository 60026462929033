import { Grid, Paper, withStyles } from "@material-ui/core";
import { Rating } from "@material-ui/lab";

interface DashCardProps {
    ratingValue: any;
}
const PerformanceRatingCard = (props: DashCardProps) => {
    const { ratingValue } = props;
    let ratingColor = "#00AE50";
    let customRating = parseInt(ratingValue) / 20;
    if (ratingValue > 95) ratingColor = "#00AE50";
    else if (ratingValue > 90) ratingColor = "#DD9200";
    else if (ratingValue <= 85) ratingColor = "#E01E37";
    console.log("customRating", customRating);
    const GreenRating = withStyles({
        iconFilled: {
            color: ratingColor,
        },
        iconHover: {
            color: ratingColor,
        },
        icon: {
            fontSize: "1.5rem",
        },
    })(Rating);

    return (
        <Paper elevation={3} style={{ minHeight: "150px" }}>
            <Grid container spacing={1}>
                <Grid
                    item
                    xs={12}
                    className="text-left font-boldest"
                    style={{ margin: "2.5% 6%" }}
                >
                    <Grid container spacing={1}>
                        <Grid
                            item
                            className="font-boldest grey1"
                            style={{
                                background: `#FFEDFA`,
                                borderRadius: "5px",
                            }}
                        >
                            {`Vendor Performance Rating`}
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Grid
                        container
                        justifyContent="center"
                        alignItems="center"
                        style={{
                            paddingLeft: "8%",
                            textAlign: "left",
                        }}
                    >
                        <Grid item xs={6}>
                            <Grid
                                container
                                justifyContent="space-evenly"
                                alignItems="center"
                            >
                                <Grid item xs={8}>
                                    <GreenRating
                                        readOnly
                                        precision={0.1}
                                        defaultValue={customRating}
                                        size="large"
                                    />
                                </Grid>
                                <Grid
                                    item
                                    xs={4}
                                    style={{
                                        paddingBottom: "4px",
                                    }}
                                >
                                    {ratingValue ? ratingValue : 0}%
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={6}>
                            <Grid container spacing={0}>
                                <Grid item xs={12}>
                                    <Grid container spacing={2}>
                                        <Grid
                                            item
                                            xs={6}
                                            className="purple"
                                        >{`Good`}</Grid>
                                        <Grid item xs={6} className="green">
                                            {`> 95%`}
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container spacing={2}>
                                        <Grid
                                            item
                                            xs={6}
                                            className="purple"
                                        >{`Concern`}</Grid>
                                        <Grid item xs={6} className="yellow">
                                            {`> 90%`}
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container spacing={2}>
                                        <Grid
                                            item
                                            xs={6}
                                            className="purple"
                                        >{`At Risk`}</Grid>
                                        <Grid item xs={6} className="red">
                                            {`< 85%`}
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Paper>
    );
};

export default PerformanceRatingCard;
