const BuildDetVisitQueryCUS = `
mutation(
    $entity: String
    $ou: Int
    $service: String
    $User: String
    $languageId: Int
    $customerCode: String
    $customerName: String
    $siteId: String
    $buildName: String
    $merrorid: String
){
    BuildDetVisitQueryCUS(
        entity: $entity
        ou: $ou
        service: $service
        User: $User
        languageId: $languageId
        customerCode: $customerCode
        customerName: $customerName
        siteId: $siteId
        buildName: $buildName
        merrorid: $merrorid
    ){
        StatusReason
        CompName
        BuildVisitBy{
            building
            visitedBy
        }
        DateOfVisit
        Vendor
        Note
    }
}`;
export default BuildDetVisitQueryCUS;