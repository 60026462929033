const InvoiceContractHeaderDataCus = `
    mutation(
        $entity: String
        $ou: Int
        $service: String
        $User: String
        $languageId: Int
        $contractno: String
        $conttype: String
        $customerCode: String
        $siteId: String
        $buildName: String
        $merrorid: String
    ){
    InvoiceContractDataCus(
        entity: $entity
        ou: $ou
        service: $service
        User: $User
        languageId: $languageId
        contractno: $contractno
        conttype: $conttype
        customerCode: $customerCode
        siteId: $siteId
        buildName: $buildName
        merrorid: $merrorid
    ) {
        contractno
        buildingName
        address
        amount
        type
        code
        Length
        PoNo
    }
}
`;

export default InvoiceContractHeaderDataCus;
