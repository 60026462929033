import { STORE_CURRENCY } from "../../actionTypes";
const initialState = {
    currency: "USD",
}; 
const storeCurrency = (prevState = initialState.currency, action: any) => {
    switch (action.type) {
        case STORE_CURRENCY:
            return action.payload;
        default:
            return prevState;
    }
};
export default storeCurrency;