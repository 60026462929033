import { Checkbox, FormControlLabel, Grid, Paper } from "@material-ui/core";
import Icons from "Common/Icons/Icons";
import { Fragment } from "react";

export default function AssociateBuildingCard(props: any) {
    const {
        buildingDetails,
        allBuildings,
        setAllBuildings,
        selectAll,
        callingFrom,
        setSelectedBuildings,
        index,
    } = props;

    const handleDeleteItem = (index: number, id: any) => {
        setAllBuildings((prevState: any) =>
            prevState.filter((building: any) => building.id !== id),
        );
        setSelectedBuildings((prevState: any) =>
            prevState.map((building: any) =>
                building.id === id
                    ? { ...building, selected: false }
                    : building,
            ),
        );
    };

    const handleDeleteItemSidedraw = (index: number, id: any) => {
        setAllBuildings((prevState: any) =>
            prevState.map((building: any) =>
                building.id === id ? { ...building, deleted: true } : building,
            ),
        );
        setSelectedBuildings((prevState: any) =>
            prevState.map((building: any) =>
                building.id === id
                    ? { ...building, selected: false }
                    : building,
            ),
        );
    };
    const handleDeleteBuilding = (id: number) => {
        const updatedBuildings = allBuildings.map((building: any) =>
            building.id === id ? { ...building, selected: false } : building,
        );
        setAllBuildings(updatedBuildings);
    };
    function selectBuilding(id: any, buildingArray: any) {
        const updatedBuildings = buildingArray.map((building: any) =>
            building.id === id ? { ...building, selected: true } : building,
        );
        return updatedBuildings;
    }

    return (
        <Paper elevation={5}>
            <Grid
                container
                spacing={1}
                alignItems="center"
                justifyContent="center"
                className={`padding-2-percent ${
                    buildingDetails.selected && "lightBlue-background"
                }`}
            >
                {callingFrom === "sidedraw" && !buildingDetails.selected ? (
                    <Fragment>
                        <Grid item xs={10} className="grey1 text-left">
                            {buildingDetails.buildingName}
                        </Grid>
                        <Grid item xs={1} className="text-right">
                            <span
                                className={`${
                                    buildingDetails.status === "Active"
                                        ? "Success_dot"
                                        : "Failed_dot"
                                }`}
                            ></span>
                        </Grid>
                        <Grid
                            item
                            xs={1}
                            className={`text-right ${
                                buildingDetails.status === "Active"
                                    ? "green"
                                    : "red"
                            }`}
                        >
                            {buildingDetails.status}
                        </Grid>
                    </Fragment>
                ) : (
                    <Fragment>
                        <Grid item xs={8} className="grey1 text-left">
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={buildingDetails.selected}
                                        onChange={(event) => {
                                            if (event.target.checked == true) {
                                                setAllBuildings(
                                                    selectBuilding(
                                                        buildingDetails.id,
                                                        allBuildings,
                                                    ),
                                                );
                                            } else
                                                handleDeleteBuilding(
                                                    buildingDetails.id,
                                                );
                                        }}
                                        color="secondary"
                                    />
                                }
                                label={`${buildingDetails.buildingName}`}
                            />
                        </Grid>
                        <Grid item xs={2} className="text-right">
                            <span
                                className={`${
                                    buildingDetails.status === "Active"
                                        ? "Success_dot"
                                        : "Failed_dot"
                                }`}
                            ></span>
                        </Grid>
                        <Grid
                            item
                            xs={2}
                            className={`text-right ${
                                buildingDetails.status === "Active"
                                    ? "green"
                                    : "red"
                            }`}
                        >
                            {buildingDetails.status}
                        </Grid>
                    </Fragment>
                )}

                <Grid item xs={12} className="text-left">
                    #{buildingDetails.buildingID}
                </Grid>
                <Grid
                    item
                    xs={callingFrom === "sidedraw" ? 11 : 12}
                    className="text-left grey2"
                >
                    {`${
                        buildingDetails.address ? buildingDetails.address : ""
                    } | ${buildingDetails.city ? buildingDetails.city : ""} | ${
                        buildingDetails.state ? buildingDetails.state : ""
                    } | ${
                        buildingDetails.pincode ? buildingDetails.pincode : ""
                    } `}
                </Grid>
                {callingFrom === "sidedraw" && (
                    <Grid
                        item
                        xs={1}
                        className="text-right pointer"
                        onClick={() => {
                            if (callingFrom === "sidedraw") {
                                handleDeleteItemSidedraw(
                                    index,
                                    buildingDetails.id,
                                );
                                if (
                                    allBuildings.filter(
                                        (building: any) => !building.deleted,
                                    ).length == 1
                                ) {
                                    setAllBuildings([]);
                                }
                            } else handleDeleteItem(index, buildingDetails.id);

                            // handleDeleteBuilding(buildingDetails.id);
                        }}
                    >
                        <Icons Icon="deleteicon" />
                    </Grid>
                )}
            </Grid>
        </Paper>
    );
}
