const VendorDataQuery = `
    mutation(
        $entity: String
        $ou: Int
        $service: String
        $user: String
        $langId: Int
        $role: String
        $merrorid: String
    ){
        VendorData(
            entity: $entity
            ou: $ou
            service: $service
            user: $user
            langId: $langId
            role: $role
            merrorid: $merrorid
        )
        {
            admin
            hubspotCustomerId
            loginUser
            orderSupplies
            customerCode
            supplierCode
            supplierName
            suppcategory
        }
    }
`;

export default VendorDataQuery;
