const GetAssociatedBuildingQuery = `
mutation
    (
        $entity: String
        $ou: Int
        $service: String
        $user: String
        $langId: Int
        $customerCode: String
        $customerEmail: String
        $name: String
        $merrorid: String
    )
        {
            GetAssociatedBuilding
                (
                    entity: $entity
                    ou: $ou
                    service: $service
                    user: $user
                    langId: $langId
                    customerCode: $customerCode
                    customerEmail: $customerEmail
                    name: $name
                    merrorid: $merrorid
                )
                    {   
                        id
                        buildingName
                        buildingLevel
                        status
                        buildingID
                        address
                        state
                        city
                        pincode
                        selected
                    }
        }
`;

export default GetAssociatedBuildingQuery;
