const GetAllBuildingQuery = `
mutation
    (
        $entity: String
        $ou: Int
        $service: String
        $user: String
        $langId: Int
        $customerCode: String
        $customerEmail: String
        $deliveryArea: String
        $regOffice: String
        $state: String
        $name: String
        $merrorid: String
    )
        {
            GetAllBuilding
                (
                    entity: $entity
                    ou: $ou
                    service: $service
                    user: $user
                    langId: $langId
                    customerCode: $customerCode
                    customerEmail: $customerEmail
                    deliveryArea: $deliveryArea
                    regOffice: $regOffice
                    state: $state
                    name: $name
                    merrorid: $merrorid
                )
                    {   
                        id
                        buildingName
                        buildingLevel
                        status
                        buildingID
                        address
                        state
                        city
                        pincode
                        selected
                    }
        }
`;

export default GetAllBuildingQuery;
