import { Grid, LinearProgress, Paper, withStyles } from "@material-ui/core";

interface DashCardProps {
    detail: {
        Offeredvalue: number;
        Acceptedvalue: number;
        Refusalvalue: number;
    };
}
const AcceptanceCard = (props: DashCardProps) => {
    const { detail } = props;
    const PurpleLinearProgress = withStyles(() => ({
        root: {
            height: 14,
        },
        bar: {
            backgroundColor: "#745CFF",
        },
    }))(LinearProgress);

    const GreenLinearProgress = withStyles(() => ({
        root: {
            height: 14,
        },
        bar: {
            backgroundColor: "#00AE50",
        },
    }))(LinearProgress);

    const RedLinearProgress = withStyles(() => ({
        root: {
            height: 14,
        },
        bar: {
            backgroundColor: "#E01E37",
        },
    }))(LinearProgress);

    return (
        <Paper elevation={3} style={{ minHeight: "150px" }}>
            <Grid container spacing={1}>
                <Grid
                    item
                    xs={12}
                    className="text-left font-boldest"
                    style={{ margin: "2.5% 6%" }}
                >
                    <Grid container spacing={1}>
                        <Grid
                            item
                            className="font-boldest grey1"
                            style={{
                                background: `#EDEAFF`,
                                borderRadius: "5px",
                            }}
                        >
                            {`Total Acceptance`}
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Grid
                        container
                        justifyContent="center"
                        alignItems="center"
                        style={{
                            paddingLeft: "8%",
                            textAlign: "left",
                        }}
                    >
                        <Grid item xs={3} style={{ color: "#6B58C0" }}>
                            Offered
                        </Grid>
                        <Grid item xs={7}>
                            <PurpleLinearProgress
                                variant="determinate"
                                value={
                                    detail?.Offeredvalue
                                        ? detail?.Offeredvalue
                                        : 0
                                }
                            />
                        </Grid>
                        <Grid item xs={2} className="black font-boldest">
                            {detail?.Offeredvalue ? detail?.Offeredvalue : 0}
                        </Grid>
                        <Grid item xs={3} style={{ color: "#6B58C0" }}>
                            Accepted
                        </Grid>
                        <Grid item xs={7}>
                            <GreenLinearProgress
                                variant="determinate"
                                value={
                                    detail?.Acceptedvalue
                                        ? detail?.Acceptedvalue
                                        : 0
                                }
                            />
                        </Grid>
                        <Grid item xs={2} className="black font-boldest">
                            {detail?.Acceptedvalue ? detail?.Acceptedvalue : 0}
                        </Grid>
                        <Grid item xs={3} style={{ color: "#6B58C0" }}>
                            Refusal
                        </Grid>
                        <Grid item xs={7}>
                            <RedLinearProgress
                                variant="determinate"
                                value={
                                    detail?.Refusalvalue
                                        ? detail?.Refusalvalue
                                        : 0
                                }
                            />
                        </Grid>
                        <Grid item xs={2} className="black font-boldest">
                            {detail?.Refusalvalue ? detail?.Refusalvalue : 0}
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Paper>
    );
};

export default AcceptanceCard;
