import { Grid, Paper } from "@material-ui/core";
import OWCircularProgress from "Common/circularprogress/OWCircularProgress";
import currencyFormatter from "Common/Utils/currencyFormatter";
import Configuration from "Configuration";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { lookup as mimeconverter } from "mime-types";

const LoanSummaryCard = (props: any) => {
    const {
        LoanSummary,
        currency,
        activeList,
        sessionId,
        currentContext,
    } = props;
    let [balanceAmount, setBalanceAmount] = useState(0);
    let [LoanSummaryData, setLoanSummaryData] = useState<any>();
    useEffect(() => {
        if (LoanSummary) {
            setBalanceAmount(
                Math.floor(
                    (LoanSummary?.balanceAmount / LoanSummary?.totalAmount) *
                        100,
                ),
            );
            setLoanSummaryData(LoanSummary);
        }
    }, [LoanSummary]);
    const [fileloading, setFileLoading] = useState<boolean>(false);
    const handleAttachClick = async (fileName: any, relativePath: string) => {
        // handleBackdropClose(true);
        setFileLoading((prev) => !prev);

        const responseFile = await fetch(
            `${Configuration.getConfiguration().getAppAPILocation()}/api/fileManagement/${fileName}/${relativePath}`,
            {
                method: "GET",
                headers: {
                    authorization: sessionId,
                    ou: currentContext.ouId,
                    role: currentContext.roleName,
                    lang: currentContext.langId,
                },
                redirect: "follow",
            },
        );
        if (responseFile.status === 200) {
            const responseBlob = await responseFile.blob();
            const fileblob = responseBlob.slice(
                0,
                responseBlob.size,
                mimeconverter(fileName).toString(),
            );
            console.log("Downoad Blob =>", fileblob);
            const file: any = fileblob as File;
            file.name = fileName;
            console.log("Downoad File =>", file);
            URL.createObjectURL(file);
            setFileLoading((prev) => !prev);

            window.open(URL.createObjectURL(file), "_blank");
            return { fileData: file };
        } else {
            setFileLoading((prev) => !prev);
        }
    };
    return (
        <Paper elevation={3} style={{ minHeight: "127px" }}>
            <Grid container>
                <Grid
                    item
                    xs={12}
                    style={{ padding: "2%" }}
                    className={
                        LoanSummaryData?.fileName
                            ? `text-left font-boldest lavender-background white`
                            : `text-left font-boldest darkBlue-background white`
                    }
                >
                    {LoanSummaryData?.fileName ? (
                        <span
                            className="darkblue text-underline"
                            onClick={(event: any) => {
                                event?.stopPropagation();
                                handleAttachClick(
                                    LoanSummaryData?.fileName,
                                    "doc_ms%2Fgen",
                                );
                            }}
                        >
                            {LoanSummaryData?.loanNo}
                        </span>
                    ) : (
                        <span>{LoanSummaryData?.loanNo}</span>
                    )}
                </Grid>
                <Grid item xs={12} style={{ paddingTop: "2%", padding: "2%" }}>
                    <Grid
                        container
                        spacing={2}
                        justifyContent="flex-end"
                        alignItems="center"
                    >
                        <Grid item xs={3} style={{ marginRight: "20px" }}>
                            <OWCircularProgress value={balanceAmount?balanceAmount:0} />
                        </Grid>
                        <Grid
                            item
                            xs={8}
                            style={
                                activeList === "AC"
                                    ? {
                                          background: "#F1F8FF",
                                          minHeight: "111px",
                                      }
                                    : {
                                          background:
                                              "rgba(237, 253, 242, 0.5)",
                                          minHeight: "111px",
                                      }
                            }
                        >
                            <Grid
                                container
                                style={{
                                    textAlign: "right",
                                }}
                            >
                                <Grid
                                    item
                                    xs={6}
                                    className="grey1 font-smaller"
                                >
                                    Total Amount
                                </Grid>
                                <Grid
                                    item
                                    xs={6}
                                    className="darkblue font-boldest font-bigger"
                                >
                                    {currencyFormatter(
                                        LoanSummaryData?.totalAmount,
                                        currency,
                                    )}
                                </Grid>
                                <Grid
                                    item
                                    xs={6}
                                    className="grey1 font-smaller"
                                >
                                    Paid Amount
                                </Grid>
                                <Grid item xs={6} className="green font-bigger">
                                    {currencyFormatter(
                                        LoanSummaryData?.paidAmount,
                                        currency,
                                    )}
                                </Grid>
                                <Grid
                                    item
                                    xs={6}
                                    className="grey1 font-smaller"
                                >
                                    Balance Amount
                                </Grid>
                                <Grid item xs={6} className="red font-bigger">
                                    {currencyFormatter(
                                        LoanSummaryData?.balanceAmount,
                                        currency,
                                    )}
                                </Grid>
                                <Grid
                                    item
                                    xs={6}
                                    className="grey1 font-smaller"
                                >
                                    No. Of Schedules
                                </Grid>
                                <Grid
                                    item
                                    xs={6}
                                    className="darkBlue font-bigger"
                                >
                                    {LoanSummaryData?.noOfSchedule}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Paper>
    );
};
const mapStateToProps = (state: any) => {
    return {
        currency: state.currency,
        sessionId: state.sessionId,
        currentContext: state.context?.userDefaults,
    };
};
export default connect(mapStateToProps)(LoanSummaryCard);
