const ContractDetailsTableDataCusQuery = `
    mutation(
        $entity:String,
        $ou:Int,
        $service:String,
        $User:String,
        $languageId:Int,
        $customerName:String,
        $customerCode:String,
        $status:String,
        $contractno:String,
        $fromdate:String,
        $todate:String,
        $merrorid:String 
    ){
        ContractTableDataCus(
            entity:$entity,
            ou:$ou,
            service:$service,
            User:$User,
            languageId:$languageId,
            customerName:$customerName,
            customerCode:$customerCode,
            contractno:$contractno,
            fromdate:$fromdate,
            todate:$todate,
            merrorid:$merrorid
            status:$status
        ){
            Region
            ContractID
            Building
            BuildingID
            AccountNumber
            Customer
            BuildingAmount
            Contracts
            Revision
            RevisionStartEnd
            ActiveAssignment
            Status
            siteId
        }
    }
`;
export default ContractDetailsTableDataCusQuery;