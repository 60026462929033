import React from "react";
import {
    Backdrop,
    CircularProgress,
    makeStyles,
    Theme,
} from "@material-ui/core";

interface backDropProps {
    openStates: boolean;
}
const useStyles = makeStyles((theme: Theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: "#fff",
    },
}));
export default function BackdropLoading(props: backDropProps) {
    const { openStates } = props;
    const classes = useStyles();
    return (
        <Backdrop className={classes.backdrop} open={openStates}>
            <CircularProgress color="inherit" />
        </Backdrop>
    );
}
