import React, { useState } from "react";
import clsx from "clsx";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import SwapHorizIcon from "@material-ui/icons/SwapHoriz";
import SettingsIcon from "@material-ui/icons/Settings";
import { createTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/core/styles";
import { ReactElement } from "react";
import NavigationList from "../../layout/MainLayout/Components/NavigationList";
import { Avatar, Grid } from "@material-ui/core";
import { ExitToApp } from "@material-ui/icons";
import { useHistory } from "react-router";
import { connect } from "react-redux";
import Breadcrumbs from "Common/Breadcrumbs/Breadcrumbs";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import Popover from "Common/Popover/Popover";
import Icons from "Common/Icons/Icons";
import navBarMenuLogo from "./navBarMenuLogo.png";
import OpenWorksMain from "./OpenWorksMain.svg";
import CleaningServicesLogo from "./Cleaning_services_logo-removebg-preview.png";

const drawerWidth = 240;

const applicationSettings = [
    {
        name: "Logout",
        icon: ExitToApp,
    },
];

const theme = createTheme({
    palette: {
        primary: {
            main: "#FFFFFF",
        },
        secondary: {
            main: "#0073e6",
        },
    },
});

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
    },
    appBar: {
        zIndex: -1,
        boxShadow: "none",
        transition: theme.transitions.create(["width", "margin"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        // marginLeft: drawerWidth,
        // width: `calc(100% - ${drawerWidth}px)`,
        // transition: theme.transitions.create(["width", "margin"], {
        //     easing: theme.transitions.easing.sharp,
        //     duration: theme.transitions.duration.enteringScreen,
        // }),
    },
    menuButton: {
        marginRight: 36,
    },
    hide: {
        display: "none",
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: "nowrap",
    },
    drawerOpen: {
        width: drawerWidth,
        overflow: "hidden",
        transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerClose: {
        transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: "hidden",
        width: theme.spacing(7) + 1,
        [theme.breakpoints.up("sm")]: {
            width: theme.spacing(9) + 1,
        },
        // [theme.breakpoints.up("md")]: {
        //     width: theme.spacing(9) + 1,
        // },
        // [theme.breakpoints.only("sm")]: {
        //     width: theme.spacing(2),
        //     overflow: "hidden",
        // },
    },
    toolbar: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
    },
    toolbarOpen: {
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
    },
    content: {
        flexGrow: 1,
    },
}));

interface MiniDrawer {
    Child: ReactElement;
    breadcrumb: { value: string; href: string }[];
    openState: [boolean, React.Dispatch<React.SetStateAction<boolean>>];
    userName: string;
    role: any;
    portalKey: boolean;
}

interface PersProps {
    userName: string;
    role: any;
}

const Personalization = (props: PersProps) => {
    const { userName, role } = props;
    const history = useHistory();
    return (
        <Grid
            container
            spacing={2}
            style={{ maxWidth: "200px", margin: "10px 0px" }}
        >
            <ListItem button key={"user"}>
                <ListItemIcon>
                    <Avatar
                        style={{
                            backgroundColor: "#0073e6",
                        }}
                    >
                        {userName ? userName[0]?.toLocaleUpperCase() : ""}
                    </Avatar>
                </ListItemIcon>
                <ListItemText
                    primary={
                        <Grid container spacing={0} alignItems="center">
                            <Grid item xs={12}>
                                <h1>
                                    {userName.charAt(0).toUpperCase() +
                                        userName.slice(1)}
                                </h1>
                            </Grid>
                            {/* <Grid item xs={12}>
                                {role?.roleName
                                    ? role?.roleName.charAt(0).toUpperCase() +
                                      role?.roleName.slice(1)
                                    : ""}{" "}
                                |{" "}
                                {role?.ouDescription ? role?.ouDescription : ""}
                            </Grid> */}
                        </Grid>
                    }
                />
            </ListItem>
            <Divider />
            {applicationSettings.map((element, index) => (
                <ListItem
                    button
                    key={index}
                    onClick={
                        element.name === "Logout"
                            ? () => {
                                  history.push("/logout");
                              }
                            : () => {}
                    }
                >
                    <ListItemIcon>
                        <element.icon />
                    </ListItemIcon>
                    <ListItemText primary={element.name} />
                </ListItem>
            ))}
        </Grid>
    );
};

function MiniDrawer(props: MiniDrawer) {
    const classes = useStyles();
    const { Child, breadcrumb, openState, userName, role, portalKey } = props;
    const history = useHistory();
    const [open, setOpen] = openState; //useState(false);
    const [openDialog, setOpenDialog] = useState(false);

    const handleDrawerOpen = () => {
        if (!open) setOpen(true);
    };

    const handleDrawerClose = () => {
        if (open) setOpen(false);
    };

    const [popoverOpen, setPopoverOpen] = useState(false);
    const [popoverAnchor, setpopoverAnchor] = useState(null);

    const handlePopoverAnchor = (event: any) => {
        if (Boolean(popoverAnchor)) {
            setPopoverOpen(false);
            setpopoverAnchor(null);
            return;
        }
        setpopoverAnchor(event.currentTarget);
        setPopoverOpen(true);
    };

    return (
        <div className={classes.root}>
            <ThemeProvider theme={theme}>
                <Grid
                    item
                    xs={12}
                    style={{
                        minHeight: "80px",
                        backgroundColor: "#F7F9FB",
                    }}
                >
                    <Grid container spacing={0}>
                        <Grid
                            item
                            xs={2}
                            style={{
                                paddingTop: "1%",
                                paddingLeft: "4%",
                                borderRight: "1px solid #C9CDD6",
                            }}
                        >
                            <img
                                src={OpenWorksMain}
                                style={{
                                    maxWidth: "60px",
                                    maxHeight: "60px",
                                }}
                            />
                        </Grid>
                        <Grid
                            item
                            xs={1}
                            style={{ textAlign: "left", padding: "1%" }}
                            className="grey2 font-boldest font-big"
                        >
                            {portalKey
                                ? "Provider Portal (Beta)"
                                : "Customer Portal (Beta)"}
                        </Grid>
                        <Grid item xs={7} />
                        <Grid
                            item
                            xs={1}
                            style={{
                                borderRight: "1px solid #C9CDD6",
                            }}
                        >
                            <Grid
                                container
                                spacing={1}
                                alignItems="center"
                                onClick={handlePopoverAnchor}
                                className="pointer"
                                style={{ marginTop: "10px" }}
                            >
                                <Grid item xs={1} sm={3}></Grid>
                                <Grid item xs={5} sm={4}>
                                    <Avatar
                                        style={{
                                            backgroundColor: "#0073e6",
                                            height: "30px",
                                            width: "30px",
                                        }}
                                    >
                                        {userName
                                            ? userName[0].toLocaleUpperCase()
                                            : ""}
                                    </Avatar>
                                </Grid>
                                <Grid item xs={4} sm={3}>
                                    <ArrowDropDownIcon />
                                    <Popover
                                        open={popoverOpen}
                                        anchorEl={popoverAnchor}
                                        Child={
                                            <Personalization
                                                userName={userName}
                                                role={role}
                                            />
                                        }
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={1}>
                            <img
                                src={OpenWorksMain}
                                style={{
                                    maxWidth: "90px",
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Divider />
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            style={{
                                backgroundColor: "#E9EEF4",
                                minHeight: "26px",
                                textAlign: "left",
                                paddingLeft: "108px",
                                paddingTop: "5px",
                            }}
                        >
                            <Breadcrumbs />
                        </Grid>
                    </Grid>
                </Grid>
                <Drawer
                    variant="permanent"
                    classes={{
                        paper: clsx({
                            [classes.drawerOpen]: open,
                            [classes.drawerClose]: !open,
                        }),
                    }}
                    PaperProps={{
                        elevation: 7,
                    }}
                    style={{
                        backgroundColor: "#33415C",
                        color: "white !important",
                    }}
                >
                    <div
                        className={open ? classes.toolbarOpen : classes.toolbar}
                    >
                        {open ? (
                            <Grid
                                container
                                justifyContent="flex-start"
                                alignItems="center"
                            >
                                <Grid
                                    item
                                    xs={10}
                                    style={{
                                        textAlign: "left",
                                        paddingTop: "3%",
                                        paddingLeft: "1%",
                                    }}
                                >
                                    <img
                                        src={navBarMenuLogo}
                                        style={{
                                            maxWidth: "700px",
                                            maxHeight: "60px",
                                        }}
                                    />
                                </Grid>
                                <Grid
                                    item
                                    xs={1}
                                    style={{
                                        paddingRight: "3%",
                                    }}
                                >
                                    <IconButton
                                        color="inherit"
                                        aria-label="open drawer"
                                        onClick={handleDrawerClose}
                                        edge="start"
                                    >
                                        <Icons Icon="navMenuIn" />
                                    </IconButton>
                                </Grid>
                            </Grid>
                        ) : (
                            <IconButton
                                color="inherit"
                                aria-label="close drawer"
                                onClick={handleDrawerOpen}
                                edge="start"
                                style={{ marginLeft: "7px" }}
                            >
                                <Icons Icon="navMenuOut" />
                            </IconButton>
                        )}
                    </div>
                    <NavigationList
                        handleDrawerClose={handleDrawerClose}
                        handleDrawerOpen={handleDrawerOpen}
                    />
                </Drawer>
                <main className={classes.content} onClick={handleDrawerClose}>
                    <div className={classes.toolbar} />
                    {Child}
                </main>
            </ThemeProvider>
        </div>
    );
}

const mapStateToProps = (state: any) => {
    return {
        supplierCode: state.hiddenSupplier?.supplierCodeOut,
        currentContext: state.context?.userDefaults,
        userName: state.context?.name,
        breadcrumb: state.context?.breadcrumb,
        role: state?.storeRole,
        portalKey: state.portalKey,
    };
};

export default connect(mapStateToProps)(MiniDrawer);
