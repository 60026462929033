import { STORE_CONTEXT_INFO } from "../../actionTypes";

const storeContext = (context: any) => {
  return {
    type: STORE_CONTEXT_INFO,
    payload: context,
  };
};

/**
 * Common File redux
 * @param code
 */
export function storeContextInfo<T>(context : any)  {
  return (dispatch: any)  => {
    dispatch(storeContext(context));
  };
};
