const PayAdviceTableDataVenQuery = `
    mutation(
        $entity: String
        $ouId: Int
        $service: String
        $user: String
        $langId: Int
        $vendorName: String
        $vendorCode: String
        $status: String
        $payAdviceID: String
        $fromDate: String
        $toDate: String
        $merrorid: String
    ){
        PayAdviceTableDataVen(
            entity: $entity,
            ouId: $ouId,
            service: $service
            user: $user
            langId: $langId
            vendorName: $vendorName
            vendorCode: $vendorCode
            status: $status
            payAdviceID: $payAdviceID
            fromDate: $fromDate
            toDate: $toDate
            merrorid: $merrorid
        ){
            PayAdviceId
            DateOfPayAdvice
            NetPay
            GrossPay
            LoanPayment
            Deduction
            print
        }
    }
`;

export default PayAdviceTableDataVenQuery;
