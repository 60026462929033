import React, { useRef, useState, useEffect } from "react";
import "./DropZone.scss";
import { Redirect } from "react-router-dom";
import { Nullable, Undefined } from "global";
import { changeInnerHTML } from "./updateReactRefs";
import { Grid, Icon } from "@material-ui/core";
import UploadSvg from "./UploadSvg";
import Icons from "Common/Icons/Icons";
import pdf from "../DocumentCards/Images/pdf.svg"
import certificate from "../DocumentCards/Images/certificate.svg"
import doc from "../DocumentCards/Images/doc.svg"
import excel from "../DocumentCards/Images/excel.svg"
import image from "../DocumentCards/Images/image.svg"

interface Files extends File {
  invalid?: boolean;
}

interface FilesList {
  readonly length: number;
  item(index: number): Files | null;
  [index: number]: Files;
}

declare var FilesList: {
  prototype: FileList;
  new (): FileList;
};

interface FilesList {
  [Symbol.iterator](): IterableIterator<Files>;
}

interface FileInputRefType {
  files: FilesList;
  click(): any;
}

interface DropzoneProps {
  dragzoneCaption?: string;
  files: any;
}
const Dropzone = (props: DropzoneProps) => {
  const fileInputRef = useRef<
    Nullable<Undefined<FileInputRefType>> & HTMLInputElement
  >(null);
  /*const modalImageRef = useRef<
    DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>
  >();*/
  const modalImageRef = useRef<HTMLDivElement>(null);
  const modalRef = useRef<HTMLDivElement>(null);
  const progressRef = useRef<HTMLDivElement>(null);
  const uploadRef = useRef<HTMLDivElement>(null);
  const uploadModalRef = useRef<HTMLDivElement>(null);
  const [selectedFiles, setSelectedFiles] = useState<any | undefined>([]);
  const [validFiles, setValidFiles] = props.files;
  const [unsupportedFiles, setUnsupportedFiles] = useState<any | undefined>([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [redirect, setRedirect] = useState({ loading: false, data: [] });
  let fileType=validFiles.length>0?validFiles[0]?.name.split(".")[1]:"";
  console.log(fileType);
  
  const source =
  fileType === "pdf"
      ? pdf
      : fileType === "doc"
      ? doc
      : fileType === "txt"
      ? doc
      :["png","jpeg","jpg"].includes(fileType)
      ? image 
      : excel;
const color =
  fileType === "pdf"
      ? "rgba(229, 90, 90, 0.07)"
      : fileType === "doc"
      ? "#F1F9FF"
      : "rgba(41, 194, 127, 0.08)";
  useEffect(() => {
    // TODO - change any to proper types
    // setErrorMessage("");
    let filteredArr = selectedFiles.reduce((acc: any[], current: any) => {
      const x = acc.find((item: any) => item.name === current.name);
      if (!x) {
        return acc.concat([current]);
      } else {
        return acc;
      }
    }, []);
    setValidFiles([...filteredArr]);
  }, [selectedFiles]);

  const dragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
  };

  const dragEnter = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
  };

  const dragLeave = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
  };

  const fileDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    const files = e.dataTransfer.files;
    if (files.length) {
      handleFiles(files);
    }
  };

  const filesSelected = () => {
    if (fileInputRef.current?.files.length) {
      handleFiles(fileInputRef.current?.files);
    }
  };

  const fileInputClicked = () => {
    fileInputRef.current?.click();
  };

  const handleFiles = (files: FilesList) => {
    setErrorMessage("");
    for (let i = 0; i < files.length; i++) {
      if (validateFile(files[i])) {
        setSelectedFiles((prevArray: any) => [ files[i]]);
        setUnsupportedFiles([]);
      } else {
        files[i]["invalid"] = true;
        setSelectedFiles((prevArray: any) => [ files[i]]);
        if (files[i].size > 5242880)//5 * 1024 *1024
          setErrorMessage("Max file size allowed is 5MB");
        else setErrorMessage("File type not permitted");
        setUnsupportedFiles((prevArray: any) => [ files[i]]);
      }
    }
  };

  //TODO find the type of input
  const validateFile = (file: Files) => {
    const validTypes = [
      "application/pdf",
      "image/png",
      "image/jpeg",
      "text/plain",
      "application/vnd.ms-excel",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      "application/msword",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    ];
    if (validTypes.indexOf(file.type) === -1 || file.size > 5242880) {
      return false;
    }
    return true;
  };

  const fileSize = (size: number) => {
    if (size === 0) {
      return "0 Bytes";
    }
    const k = 1024;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
    const i = Math.floor(Math.log(size) / Math.log(k));
    return parseFloat((size / Math.pow(k, i)).toFixed(2)) + " " + sizes[i];
  };

  // const fileType = (fileName: string) => {
  //   return (
  //     fileName.substring(fileName.lastIndexOf(".") + 1, fileName.length) ||
  //     fileName
  //   );
  // };

  const removeFile = (name: string) => {
    setErrorMessage("");
    const index = validFiles.findIndex((e: any) => e.name === name);
    const index2 = selectedFiles.findIndex((e: any) => e.name === name);
    const index3 = unsupportedFiles.findIndex((e: any) => e.name === name);
    validFiles.splice(index, 1);
    selectedFiles.splice(index2, 1);
    setValidFiles([...validFiles]);
    setSelectedFiles([...selectedFiles]);
    if (index3 !== -1) {
      unsupportedFiles.splice(index3, 1);
      setUnsupportedFiles([...unsupportedFiles]);
    }

  };

  const openImageModal = (file: Blob) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
  };

  const closeModal = () => {
    if (null !== modalRef.current) {
      modalRef.current.style.display = "none";
    }
    if (null !== modalImageRef.current) {
      modalImageRef.current.style.backgroundImage = "none";
    }
  };

  /*
  const uploadFiles = async (e: Event) => {
    setLoading(true);
    console.log(e);
    uploadModalRef.current.style.display = "block";
    uploadRef.current.innerHTML = "File(s) Uploading...";
    for (let i = 0; i < validFiles.length; i++) {
      const formData = new FormData();
      formData.append("files", validFiles[i]);

      let dataPayload: FilePayloadType[] = [];
      const reader = new FileReader();
      reader.readAsDataURL(validFiles[i]);
      reader.onload = () => {
        dataPayload.push({ payload: reader.result });
        store.dispatch(storeUploadedFile(reader.result)); //arv
        console.log(dataPayload);
        var config = {
          method: "post",
          url: "http://localhost:3004",
          headers: {
            "Content-Type": "application/json",
          },
          data: dataPayload, //formData,
        };
        console.log(config);
        axios(config)
          .then((data: any, _err: Error) => {
            console.log(data);
            return data;
          })
          .then((data: any) => {
            store.dispatch(storeFileInfo(data.data));
            setLoading(false);
            setRedirect({ loading: true, data: data.data });
          })
          .catch((err: Error) => {
            setLoading(false);
            console.log(err);
          });
      };
      reader.onerror = (error) => console.log(error);
    }
  };
  */

  /**
   * onUploadProgress
   * @param progressEvent
   */
  const onUploadProgress = (progressEvent: {
    loaded: number;
    total: number;
  }) => {
    const uploadPercentage = Math.floor(
      (progressEvent.loaded / progressEvent.total) * 100
    );
    if (null !== progressRef.current) {
      progressRef.current.innerHTML = `${uploadPercentage}%`;
      progressRef.current.style.width = `${uploadPercentage}%`;
    }

    if (uploadPercentage === 100) {
      if (null !== uploadRef.current) {
        changeInnerHTML(uploadRef.current, "File(s) Uploaded");
      }
      validFiles.length = 0;
      setValidFiles([...validFiles]);
      setSelectedFiles([...validFiles]);
      setUnsupportedFiles([...validFiles]);
    }
  };

  const closeUploadModal = () => {
    if (null !== uploadModalRef.current) {
      uploadModalRef.current.style.display = "none";
    }
  };
  console.log("indropzone",validFiles);
  // console.log(validFiles[0].invalid );
  
  if (redirect.loading) return <Redirect to="/order" />;

  return (
    <>
      <div className="container">
     { ((validFiles.length === 0 || validFiles?.[0]?.invalid ) ? <>
      <div
          className="drop-container"
          onDragOver={dragOver}
          onDragEnter={dragEnter}
          onDragLeave={dragLeave}
          onDrop={fileDrop}
          onClick={fileInputClicked}
          style={errorMessage && validFiles?.[0]?.invalid?{border:"1px dashed red"}:{border:"1px dashed #0073E6"}}
        >
          <Grid container spacing={2} className="drop-message">
            <Grid item xs={12} sm={12} className="upload-icon">
              <UploadSvg
                fillColor={
                  typeof validFiles[0] === "undefined"
                    ? "#0073E6"
                    : unsupportedFiles.length>0 || validFiles?.invalid
                    ? "red"
                    : "#1BC54E"
                }
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <span className="upload-message">
                {validFiles.length === 0
                  ? props.dragzoneCaption
                    ? props.dragzoneCaption
                    : "Upload Documents/Images, Less than 5mb"

                  : ""}
              </span>
            </Grid>
          </Grid>
          <input
            ref={fileInputRef}
            className="file-input"
            type="file"
            // multiple
            onChange={filesSelected}
          />
        </div> 
        <div className="file-display-container">  
          {validFiles.map(
            (data: any, i: string | number | null | undefined) => (
              <>
                <Grid
                  container
                  spacing={1}
                  id="file-name-delete"
                  justifyContent="center"
                  style={{maxHeight:"10px"}}
                >
                  <Grid item md={1} sm={1} xs={1}></Grid>
                
                  <Grid item md sm={8} xs={8}>
                    {" "}
                    <span
                      className={`file-name ${
                        data.invalid ? "file-error" : ""
                      }`}
                      onClick={() => removeFile(data.name)}
                    >
                      {data.name+"  "} &nbsp;<Icons Icon={"delete"} info={""} />
                    </span>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                    {data.invalid && (
                      <span className="file-error-message">
                        ({errorMessage})
                      </span>
                    )}
                  </Grid>
                  {/* <Grid item md={3} sm={3} xs={3}>
                    <Icon
                      className="delete"
                      
                    >
                     
                    </Icon>
                  </Grid> */}
                  
                </Grid>
              </>
            )
          )}
        </div></>:<div className="file-display-container">  
          {validFiles.map(
            (data: any, i: string | number | null | undefined) => (
              <>
                <Grid
                  container
                  spacing={1}
                  id="file-name-success"
                  justifyContent="flex-start"
                  alignItems="center"

                  style={{maxHeight:"10px",fontWeight:"bold"}}
                >
                  <Grid item md={2} sm={1} xs={1}><img src={source} style={{ paddingTop: "20%" }} /> </Grid>
                
                  <Grid item md sm={8} xs={8}>
                    
                    <span
                      className={``}
                      onClick={() => removeFile(data.name)}
                    >
                      {data.name+"  "} <Icons Icon={"delete"} info={""} style={{marginLeft:"1rem"}}/>
                    </span>
                  </Grid>
                 
                  
                  
                </Grid>
              </>
            )
          )}
        </div>)}
      </div>



      <div className="modal" ref={modalRef}>
        <div className="overlay"></div>
        <span className="close" onClick={() => closeModal()}>
          X
        </span>
        <div className="modal-image" ref={modalImageRef}></div>
      </div>

      <div className="upload-modal" ref={uploadModalRef}>
        <div className="overlay"></div>
        <div className="close" onClick={() => closeUploadModal()}>
          X
        </div>
        <div className="progress-container">
          <span ref={uploadRef}></span>
          <div className="progress">
            <div className="progress-bar" ref={progressRef}></div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Dropzone;
