import {
    Grid,
    Paper,
    Backdrop,
    CircularProgress,
    makeStyles,
} from "@material-ui/core";
import { useCallback, useEffect, useState } from "react";
import "Common/Main.scss";
import MuiDatatables from "Common/Mui-DataTables/MuiDatatables";
import {
    CompanyDetailsDataCusQuery,
    CompanyDetailsEditUserCusQuery,
    CompanyDetailsPostUserCusQuery,
    CompanyDetailsTableDataCusQuery,
    CompanyProfileVenQuery,
    CompanyUsersVenQuery,
    GetFileDetailsAndComboQuery,
} from "ReactQuery/Query";
import useGetPosts from "ReactQuery/reactQuery";
import { connect } from "react-redux";
import Icons from "Common/Icons/Icons";
import MUIButton from "Common/Button/Button";
import SwipeableDrawerComp from "Common/SideDrawer/SideDrawer";
import { SwitchClassKey, SwitchProps } from "@material-ui/core/Switch";
import { FormControlLabel, Switch, Theme } from "@material-ui/core";
import { withStyles, createStyles } from "@material-ui/styles";
import CompanySideDrawChild from "./CompanySideDrawChild";
import Alert from "Common/Alerts/Alerts";
import CompanySideDrawChildVen from "./CompanySideDrawChildVen";
import GetDepositInfoVenQuery from "ReactQuery/Query/GetDepositInfoVenQuery";
import UploadInfoSideDraw from "./UploadInfoSideDraw";
import UploadDocsSideDraw from "./UploadDocsSideDraw";
import NoUsersProfile from "./Images/NoUsersProfile.svg";
import { storeBreadcrumbs } from "stateManagement/actions/Common/breadcrumbs";
import Customer from "./Images/customer.png";
import ButtonGroups from "Common/ButtonGroup/ButtonGroup";
import DocGroups from "./DocGroups";

const controlList = [
    {
        desc: "Users",
        value: "users",
    },
    {
        desc: "Documents",
        value: "doc",
    },
];

const useStyles = makeStyles((theme: Theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: "#fff",
    },
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
    },
}));

interface Styles extends Partial<Record<SwitchClassKey, string>> {
    focusVisible?: string;
}

interface Props extends SwitchProps {
    classes: Styles;
}

const MSMESwitch = withStyles((theme: Theme) =>
    createStyles({
        root: {
            width: 33,
            height: 18,
            padding: 0,
            margin: theme.spacing(1),
        },
        switchBase: {
            padding: 1,
            "&$checked": {
                transform: "translateX(16px)",
                color: theme.palette.common.white,
                "& + $track": {
                    backgroundColor: "green",
                    opacity: 1,
                    border: "none",
                },
            },
            "&$focusVisible $thumb": {
                color: "green",
                border: "6px solid green",
            },
        },
        thumb: {
            width: 15,
            height: 15,
        },
        track: {
            borderRadius: 30 / 2,
            border: `1px solid ${theme.palette.grey[400]}`,
            backgroundColor: theme.palette.grey[50],
            opacity: 1,
            transition: theme.transitions.create([
                "background-color",
                "border",
            ]),
        },
        checked: {},
        focusVisible: {},
    }),
)(({ classes, ...props }: Props) => {
    return (
        <Switch
            focusVisibleClassName={classes.focusVisible}
            disableRipple
            classes={{
                root: classes.root,
                switchBase: classes.switchBase,
                thumb: classes.thumb,
                track: classes.track,
                checked: classes.checked,
            }}
            {...props}
        />
    );
});

const Body = (props: any) => {
    const {
        portalKey,
        selectedVendor,
        currentContext,
        selectedCustomer,
        setBreadcrumbsData,
        supplierCode,
        userReadOnlyAccess,
    } = props;

    setBreadcrumbsData([
        {
            value: "Company Profile",
            href: "/app/*/CompanyProfile",
        },
    ]);
    const [admin, setAdmin] = useState(false);
    useEffect(() => {
        if (portalKey) {
            setAdmin(
                selectedVendor?.admin.toLowerCase() === "y" ? true : false,
            );
        } else {
            setAdmin(
                selectedCustomer?.admin.toLowerCase() === "y" ? true : false,
            );
        }
    });

    const [CompanyTableDataSchema, setCompanyTableDataSchema] = useState([
        {
            name: "Name",
            Label: "Name",
            type: "string",
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => (
                    <span style={{ color: "white" }}>Name</span>
                ),
                setCellHeaderProps: () => {
                    return { style: { borderRight: "1px solid #D5D5D5",textAlign: "left" } };
                },
                setCellProps: () => {
                    return {
                        style: {
                            borderRight: "1px solid #D5D5D5",
                            textAlign: "left",
                        },
                    };
                },
                customBodyRender: (
                    value: any,
                    tableMeta: any,
                    updateValue: any,
                ) => {
                    console.log("table data", value, tableMeta.rowData);
                    return (
                        <b
                            style={{ color: "grey", cursor: "pointer" }}
                            onClick={() => {
                                // handleSideBar("+ Add User");
                            }}
                        >
                            {value}
                        </b>
                    );
                },
            },
        },
        {
            name: "Email",
            Label: "Email",
            type: "string",
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => (
                    <span style={{ color: "white" }}>Email ID</span>
                ),
                setCellHeaderProps: () => {
                    return { style: { borderRight: "1px solid #D5D5D5",textAlign: "left" } };
                },
                setCellProps: () => {
                    return {
                        style: {
                            borderRight: "1px solid #D5D5D5",
                            textAlign: "left",
                        },
                    };
                },
                customBodyRender: (
                    value: any,
                    tableMeta: any,
                    updateValue: any,
                ) => {
                    console.log("table data", value, tableMeta.rowData);
                    return <b style={{ color: "grey" }}>{value}</b>;
                },
            },
        },

        {
            name: "UserName",
            Label: "UserName",
            type: "string",
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => (
                    <span style={{ color: "white" }}>UserName</span>
                ),
                setCellHeaderProps: () => {
                    return { style: { borderRight: "1px solid #D5D5D5",textAlign: "left" } };
                },
                setCellProps: () => {
                    return {
                        style: {
                            borderRight: "1px solid #D5D5D5",
                            textAlign: "left",
                        },
                    };
                },
                customBodyRender: (
                    value: any,
                    tableMeta: any,
                    updateValue: any,
                ) => {
                    console.log("table data", value);
                    return <b style={{ color: "#5C677D" }}>{value}</b>;
                },
            },
        },
        {
            name: "Designation",
            Label: "Designation",
            type: "string",
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => (
                    <span style={{ color: "white" }}>Designation</span>
                ),
                setCellHeaderProps: () => {
                    return { style: { borderRight: "1px solid #D5D5D5",textAlign: "left" } };
                },
                setCellProps: () => {
                    return {
                        style: {
                            borderRight: "1px solid #D5D5D5",
                            textAlign: "left",
                        },
                    };
                },
                customBodyRender: (
                    value: any,
                    tableMeta: any,
                    updateValue: any,
                ) => {
                    console.log("table data", value);
                    return <b style={{ color: "green" }}>{value}</b>;
                },
            },
        },

        {
            name: "isActive",
            Label: "isActive",
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => (
                    <span style={{ color: "white" }}>Active</span>
                ),
                setCellHeaderProps: () => {
                    return { style: { borderRight: "1px solid #D5D5D5" } };
                },
                setCellProps: () => {
                    return {
                        style: {
                            borderRight: "1px solid #D5D5D5",
                            textAlign: "center",
                        },
                    };
                },
                customBodyRender: (
                    value: any,
                    tableMeta: any,
                    updateValue: any,
                ) => {
                    const handleDataChange = (e: any) => {
                        const value = e.target.checked;
                        const name = e.target.name;
                        handleTableData(tableMeta.rowIndex, name, value);
                    };
                    return (
                        <b style={{ color: "green" }}>
                            {
                                <FormControlLabel
                                    control={
                                        <MSMESwitch
                                            checked={
                                                (value || "")?.toLowerCase() ===
                                                "yes"
                                                    ? true
                                                    : false
                                            }
                                            // onChange={handleDataChange}
                                            // disabled={disabled}
                                            name="isActive"
                                        />
                                    }
                                    label=""
                                />
                            }
                        </b>
                    );
                },
            },
        },
        {
            name: "MakePayment",
            Label: "MakePayment",

            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => (
                    <span style={{ color: "white" }}>Make Payment</span>
                ),
                setCellHeaderProps: () => {
                    return { style: { borderRight: "1px solid #D5D5D5" } };
                },
                setCellProps: () => {
                    return {
                        style: {
                            borderRight: "1px solid #D5D5D5",
                            textAlign: "center",
                        },
                    };
                },
                customBodyRender: (
                    value: any,
                    tableMeta: any,
                    updateValue: any,
                ) => {
                    const handleDataChange = (e: any) => {
                        const value = e.target.checked;
                        const name = e.target.name;
                        handleTableData(tableMeta.rowIndex, name, value);
                    };
                    console.log("table data", value);
                    return (
                        <b style={{ color: "white" }}>
                            <FormControlLabel
                                control={
                                    <MSMESwitch
                                        checked={
                                            value?.toLowerCase() === "yes"
                                                ? true
                                                : false
                                        }
                                        // onChange={handleDataChange}
                                        // disabled={disabled}
                                        name="MakePayment"
                                    />
                                }
                                label=""
                            />
                        </b>
                    );
                },
            },
        },
        {
            name: "OrderSupplies",
            Label: "OrderSupplies",

            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => (
                    <span style={{ color: "white" }}>Order Supplies</span>
                ),
                setCellHeaderProps: () => {
                    return { style: { borderRight: "1px solid #D5D5D5" } };
                },
                setCellProps: () => {
                    return {
                        style: {
                            borderRight: "1px solid #D5D5D5",
                            textAlign: "center",
                        },
                    };
                },
                customBodyRender: (
                    value: any,
                    tableMeta: any,
                    updateValue: any,
                ) => {
                    const handleDataChange = (e: any) => {
                        const value = e.target.checked;
                        const name = e.target.name;
                        handleTableData(tableMeta.rowIndex, name, value);
                    };
                    console.log("table data", value);
                    return (
                        <b style={{ color: "green" }}>
                            {
                                <FormControlLabel
                                    control={
                                        <MSMESwitch
                                            checked={
                                                value?.toLowerCase() === "yes"
                                                    ? true
                                                    : false
                                            }
                                            // onChange={handleDataChange}
                                            // disabled={disabled}
                                            name="OrderSupplies"
                                        />
                                    }
                                    label=""
                                />
                            }
                        </b>
                    );
                },
            },
        },
        {
            name: "Admin",
            Label: "Admin",

            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => (
                    <span style={{ color: "white" }}>Admin</span>
                ),
                setCellHeaderProps: () => {
                    return { style: { borderRight: "1px solid #D5D5D5" } };
                },
                setCellProps: () => {
                    return {
                        style: {
                            borderRight: "1px solid #D5D5D5",
                            textAlign: "center",
                        },
                    };
                },
                customBodyRender: (
                    value: any,
                    tableMeta: any,
                    updateValue: any,
                ) => {
                    const handleDataChange = (e: any) => {
                        const value = e.target.checked;
                        const name = e.target.name;
                        handleTableData(tableMeta.rowIndex, name, value);
                    };
                    console.log("table data", value);
                    return (
                        <b style={{ color: "white" }}>
                            {
                                <FormControlLabel
                                    control={
                                        <MSMESwitch
                                            checked={
                                                value?.toLowerCase() === "yes"
                                                    ? true
                                                    : false
                                            }
                                            // onChange={handleDataChange}
                                            // disabled={disabled}
                                            name="Admin"
                                        />
                                    }
                                    label=""
                                />
                            }
                        </b>
                    );
                },
            },
        },
    ]);
    const [CompanyTableDataVenSchema, setCompanyTableDataVenSchema] = useState([
        {
            name: "Name",
            Label: "Name",
            type: "string",
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => (
                    <span style={{ color: "white" }}>Name</span>
                ),
                setCellHeaderProps: () => {
                    return { style: { borderRight: "1px solid #D5D5D5" ,textAlign: "left"} };
                },
                setCellProps: () => {
                    return {
                        style: {
                            borderRight: "1px solid #D5D5D5",
                            textAlign: "left",
                        },
                    };
                },
                customBodyRender: (
                    value: any,
                    tableMeta: any,
                    updateValue: any,
                ) => {
                    console.log("table data", value, tableMeta.rowData);
                    return (
                        <b
                            style={{ color: "grey", cursor: "pointer" }}
                            onClick={() => {
                                // handleSideBar("+ Add User");
                            }}
                        >
                            {value}
                        </b>
                    );
                },
            },
        },
        {
            name: "Email",
            Label: "Email",
            type: "string",
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => (
                    <span style={{ color: "white" }}>Email ID</span>
                ),
                setCellHeaderProps: () => {
                    return { style: { borderRight: "1px solid #D5D5D5",textAlign: "left" } };
                },
                setCellProps: () => {
                    return {
                        style: {
                            borderRight: "1px solid #D5D5D5",
                            textAlign: "left",
                        },
                    };
                },
                customBodyRender: (
                    value: any,
                    tableMeta: any,
                    updateValue: any,
                ) => {
                    console.log("table data", value, tableMeta.rowData);
                    return <b style={{ color: "grey" }}>{value}</b>;
                },
            },
        },

        {
            name: "Username",
            Label: "Username",
            type: "string",
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => (
                    <span style={{ color: "white" }}>UserName</span>
                ),
                setCellHeaderProps: () => {
                    return { style: { borderRight: "1px solid #D5D5D5",textAlign: "left" } };
                },
                setCellProps: () => {
                    return {
                        style: {
                            borderRight: "1px solid #D5D5D5",
                            textAlign: "left",
                        },
                    };
                },
                customBodyRender: (
                    value: any,
                    tableMeta: any,
                    updateValue: any,
                ) => {
                    console.log("table data", value);
                    return <b style={{ color: "#5C677D" }}>{value}</b>;
                },
            },
        },
        {
            name: "Designation",
            Label: "Designation",
            type: "string",
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => (
                    <span style={{ color: "white" }}>Designation</span>
                ),
                setCellHeaderProps: () => {
                    return { style: { borderRight: "1px solid #D5D5D5",textAlign: "left" } };
                },
                setCellProps: () => {
                    return {
                        style: {
                            borderRight: "1px solid #D5D5D5",
                            textAlign: "left",
                        },
                    };
                },
                customBodyRender: (
                    value: any,
                    tableMeta: any,
                    updateValue: any,
                ) => {
                    console.log("table data", value);
                    return <b style={{ color: "green" }}>{value}</b>;
                },
            },
        },

        {
            name: "isActive",
            Label: "isActive",
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => (
                    <span style={{ color: "white" }}>Active</span>
                ),
                setCellHeaderProps: () => {
                    return { style: { borderRight: "1px solid #D5D5D5" } };
                },
                setCellProps: () => {
                    return {
                        style: {
                            borderRight: "1px solid #D5D5D5",
                            textAlign: "center",
                        },
                    };
                },
                customBodyRender: (
                    value: any,
                    tableMeta: any,
                    updateValue: any,
                ) => {
                    const handleDataChange = (e: any) => {
                        const value = e.target.checked;
                        const name = e.target.name;
                        handleTableData(tableMeta.rowIndex, name, value);
                    };
                    return (
                        <b style={{ color: "green" }}>
                            {
                                <FormControlLabel
                                    control={
                                        <MSMESwitch
                                            checked={
                                                (value || "")?.toLowerCase() ===
                                                "yes"
                                                    ? true
                                                    : false
                                            }
                                            // onChange={handleDataChange}
                                            // disabled={disabled}
                                            name="isActive"
                                        />
                                    }
                                    label=""
                                />
                            }
                        </b>
                    );
                },
            },
        },
        {
            name: "OrderSupplies",
            Label: "OrderSupplies",

            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => (
                    <span style={{ color: "white" }}>Order Supplies</span>
                ),
                setCellHeaderProps: () => {
                    return { style: { borderRight: "1px solid #D5D5D5" } };
                },
                setCellProps: () => {
                    return {
                        style: {
                            borderRight: "1px solid #D5D5D5",
                            textAlign: "center",
                        },
                    };
                },
                customBodyRender: (
                    value: any,
                    tableMeta: any,
                    updateValue: any,
                ) => {
                    const handleDataChange = (e: any) => {
                        const value = e.target.checked;
                        const name = e.target.name;
                        handleTableData(tableMeta.rowIndex, name, value);
                    };
                    console.log("table data", value);
                    return (
                        <b style={{ color: "green" }}>
                            {
                                <FormControlLabel
                                    control={
                                        <MSMESwitch
                                            checked={
                                                value?.toLowerCase() === "yes"
                                                    ? true
                                                    : false
                                            }
                                            // onChange={handleDataChange}
                                            // disabled={disabled}
                                            name="OrderSupplies"
                                        />
                                    }
                                    label=""
                                />
                            }
                        </b>
                    );
                },
            },
        },
        {
            name: "Admin",
            Label: "Admin",

            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => (
                    <span style={{ color: "white" }}>Admin</span>
                ),
                setCellHeaderProps: () => {
                    return { style: { borderRight: "1px solid #D5D5D5" } };
                },
                setCellProps: () => {
                    return {
                        style: {
                            borderRight: "1px solid #D5D5D5",
                            textAlign: "center",
                        },
                    };
                },
                customBodyRender: (
                    value: any,
                    tableMeta: any,
                    updateValue: any,
                ) => {
                    const handleDataChange = (e: any) => {
                        const value = e.target.checked;
                        const name = e.target.name;
                        handleTableData(tableMeta.rowIndex, name, value);
                    };
                    console.log("table data", value);
                    return (
                        <b style={{ color: "white" }}>
                            {
                                <FormControlLabel
                                    control={
                                        <MSMESwitch
                                            checked={
                                                value.toLowerCase() === "yes"
                                                    ? true
                                                    : false
                                            }
                                            // onChange={handleDataChange}
                                            // disabled={disabled}
                                            name="Admin"
                                        />
                                    }
                                    label=""
                                />
                            }
                        </b>
                    );
                },
            },
        },
    ]);

    const [activeList, setActiveList] = useState(controlList[0].value);
    const handleDocList = (listType: string) => {
        setActiveList(listType);
    };
    const [selectedUser , setselectedUser   ] = useState("");
    useEffect(() => {
        if (admin) {
            if (!portalKey) {
                setCompanyTableDataSchema((prevState: any) => [
                    ...prevState,
                    {
                        name: "Image",
                        Label: "Image",

                        options: {
                            filter: true,
                            sort: false,
                            customHeadLabelRender: () => (
                                <span style={{ color: "white" }}></span>
                            ),
                            setCellHeaderProps: () => {
                                return {
                                    style: { borderRight: "1px solid #D5D5D5" },
                                };
                            },
                            setCellProps: () => {
                                return {
                                    style: {
                                        borderRight: "1px solid #D5D5D5",
                                        textAlign: "center",
                                    },
                                };
                            },
                            customBodyRender: (
                                value: any,
                                tableMeta: any,
                                updateValue: any,
                            ) => {
                                console.log("table-data-customer", value,tableMeta);
                                
                                return (
                                    <b
                                        style={{
                                            color: "green",
                                            cursor: "pointer",
                                        }}
                                        onClick={() => {
                                            handleSideBar("User Details");
                                            setCompanyProfileName(
                                                tableMeta?.rowData[0],
                                            );
                                            setselectedUser(tableMeta?.rowData[2])
                                        }}
                                    >
                                        {<Icons Icon={"penimage"} />}
                                    </b>
                                );
                            },
                        },
                    },
                ]);
            } else {
                setCompanyTableDataVenSchema((prevState: any) => [
                    ...prevState,
                    {
                        name: "Image",
                        Label: "Image",

                        options: {
                            filter: true,
                            sort: false,
                            customHeadLabelRender: () => (
                                <span style={{ color: "white" }}></span>
                            ),
                            setCellHeaderProps: () => {
                                return {
                                    style: { borderRight: "1px solid #D5D5D5" },
                                };
                            },
                            setCellProps: () => {
                                return {
                                    style: {
                                        borderRight: "1px solid #D5D5D5",
                                        textAlign: "center",
                                    },
                                };
                            },
                            customBodyRender: (
                                value: any,
                                tableMeta: any,
                                updateValue: any,
                            ) => {
                                console.log("table-data-vendor", value,tableMeta?.rowData[2]);
                                return (
                                    <b
                                        style={{
                                            color: "green",
                                            cursor: "pointer",
                                        }}
                                        onClick={() => {
                                            handleSideBar("User Details");
                                            setCompanyProfileName(
                                                tableMeta?.rowData[0],
                                            );
                                            setselectedUser(tableMeta?.rowData[2])
                                        }}
                                    >
                                        {<Icons Icon={"penimage"} />}
                                    </b>
                                );
                            },
                        },
                    },
                ]);
            }
        }
    }, [admin]);
    const classes = useStyles();
    const [companyProfileData, setcompanyProfileData] = useState<any>();
    const [companyDetailsTableData, setCompanyDetailsTableData] = useState<any>(
        [],
    );
    const [getDepositInfoDatas, setGetDepositInfoDatas] = useState<any>([]);
    const [companyProfileDataVen, setCompanyProfileDataVen] = useState<any>([]);
    const [companyUsersDataVen, setCompanyUsersDataVen] = useState<any>([]);
    const [disabled, setDisabled] = useState(false);
    const [sideScreen, setSideScreen] = useState("");
    const [sideBar, setSideBar] = useState(false);
    const [uploadSideBar, setuploadSideBar] = useState(false);
    const [uploadSideDocs, setUploadSideDocs] = useState(false);
    const [companyProfileName, setCompanyProfileName] = useState<any>("");
    const handleSideBar = (screen: string) => {
        setSideBar(!sideBar);
        setSideScreen(screen);
    };

    const [alert, setAlert] = useState(false);
    const [errr, seterr] = useState({
        code: 0,
        msg: "",
    });
   


    const handleErrorAlert = (code: number, message: string) => {
        Alert({
            statusCode: code,
            message: message,
            willClose: () => {
                setAlert(false);
                seterr({ code: 0, msg: "" });
            },
        });
    };

    const handleTableData = useCallback(
        (rowIndex: number, columnName: string, value: number) => {
            setCompanyDetailsTableData((prevState: any[]) => {
                const newData = [...prevState];
                newData[rowIndex][columnName] = value;
                return newData;
            });
        },
        [],
    );

    // sidedrawer state and handle functions
    const [final, setFinal] = useState<any>();
    const [postSkip, setPostSkip] = useState<any>(true);
    const [data, setData] = useState<any>({
        Name: "",
        UserName: "",
        CustomerName: "",
        FirstName: "",
        LastName: "",
        JobTitle: "",
        Email: "",
        BusinessPhone: "",
        MobilePhone: "",
        isActive: false,
        MakePayment: false,
        OrderSupplies: false,
        Admin: false,
    });

    const handleChangeCheck = (event: React.ChangeEvent<HTMLInputElement>) => {
        setData({
            ...data,
            [event.target.name]: event.target.checked,
        });
    };

    const handleSubmit = () => {
        setFinal(data);
        setPostSkip(false);
        setSideBar(false);
    };

    const handleReset = () => {
        setData({
            Name: "",
            UserName: "",
            CustomerName: "",
            FirstName: "",
            LastName: "",
            JobTitle: "",
            Email: "",
            BusinessPhone: "",
            MobilePhone: "",
            isActive: false,
            MakePayment: false,
            OrderSupplies: false,
            Admin: false,
        });
    };

    const {
        data: CompanyProfileData,
        error: CompanyProfileDataError,
        isLoading: CompanyProfileDataLoading,
    } = useGetPosts({
        name: `CompanyDetailsDataCusQuery_${selectedCustomer?.customerCode}_${selectedCustomer?.loginUser}`,
        query: CompanyDetailsDataCusQuery,
        variables: {
            entity: "OW_Cust_portal_getcustcompprfl",
            ou: currentContext?.ouId,
            service: "",
            user: selectedCustomer?.loginUser,
            langId: currentContext?.langId,
            customerCode: selectedCustomer?.customerCode,
            customerName: "",
            contactId: selectedCustomer?.contactId,
            merrorid: "",
        },
        skip: portalKey,
    });

    useEffect(() => {
        console.log(
            "CompanyCardDataCus",
            CompanyProfileData?.CompanyCardDataCus,
        );
        if (CompanyProfileData?.CompanyCardDataCus) {
            setcompanyProfileData(CompanyProfileData?.CompanyCardDataCus[0]);
        }
    }, [CompanyProfileData]);

    useEffect(() => {
        if (CompanyProfileDataError && !CompanyProfileDataLoading) {
            const data = JSON.parse(JSON.stringify(CompanyProfileDataError));
            const errmsg = data?.response?.errors[0].message;
            seterr({
                code: 400,
                msg: errmsg,
            });
            setAlert(true);
        }
    }, [CompanyProfileDataError, CompanyProfileDataLoading]);
    // Vendor portal

    const {
        data: GetDepositInfoData,
        error: GetDepositInfoDataError,
        isLoading: GetDepositInfoDataLoading,
    } = useGetPosts({
        name: `GetDepositInfoData_Query_${selectedVendor?.loginUser}_${selectedVendor?.supplierCode}`,
        query: GetDepositInfoVenQuery,
        variables: {
            entity: "supplier_portal_direct_deposit_dtl",
            ou: currentContext?.ouId,
            service: "",
            User: selectedVendor?.loginUser,
            languageId: currentContext?.langId,
            vendorCode: selectedVendor?.supplierCode,
            vendorName: "",
            merrorid: "",
        },
        skip: !portalKey,
    });

    useEffect(() => {
        if (GetDepositInfoData?.GetDepositInfo) {
            console.log(
                "GetDepositInfoData",
                GetDepositInfoData?.GetDepositInfo,
            );
            setGetDepositInfoDatas(GetDepositInfoData?.GetDepositInfo[0]);
        }
    }, [GetDepositInfoData]);

    useEffect(() => {
        if (GetDepositInfoDataError && !GetDepositInfoDataLoading) {
            const data = JSON.parse(JSON.stringify(GetDepositInfoDataError));
            const errmsg = data?.response?.errors[0].message;
            seterr({
                code: 400,
                msg: errmsg,
            });
            setAlert(true);
        }
    }, [GetDepositInfoDataError, GetDepositInfoDataLoading]);

    const {
        data: CompanyProfileVenData,
        error: CompanyProfileVenDataError,
        isLoading: CompanyProfileVenDataLoading,
    } = useGetPosts({
        name: `CompanyProfileVenData_VendorPortal_${selectedVendor?.loginUser}_${selectedVendor?.supplierCode}`,
        query: CompanyProfileVenQuery,
        variables: {
            entity: "OW_Vendor_Company_Profile",
            ou: currentContext?.ouId,
            service: "",
            User: selectedVendor?.loginUser,
            languageId: currentContext?.langId,
            vendorCode: selectedVendor?.supplierCode,
            vendorName: "",
            merrorid: "",
        },
        skip: !portalKey,
    });

    useEffect(() => {
        if (CompanyProfileVenData?.CompanyProfileVen) {
            console.log(
                "CompanyProfileVenData",
                CompanyProfileVenData?.CompanyProfileVen[0],
            );
            setCompanyProfileDataVen(
                CompanyProfileVenData?.CompanyProfileVen[0],
            );
        }
    }, [CompanyProfileVenData]);

    useEffect(() => {
        if (CompanyProfileVenDataError && !CompanyProfileVenDataLoading) {
            const data = JSON.parse(JSON.stringify(CompanyProfileVenDataError));
            const errmsg = data?.response?.errors[0].message;
            seterr({
                code: 400,
                msg: errmsg,
            });
            setAlert(true);
        }
    }, [CompanyProfileVenDataError, CompanyProfileVenDataLoading]);

    const {
        data: CompanyUsersVenData,
        error: CompanyUsersVenDataError,
        isLoading: CompanyUsersVenDataLoading,
    } = useGetPosts({
        name: `CompanyUsersVenData_Query_${selectedVendor?.supplierCode}_${selectedVendor?.loginUser}`,
        query: CompanyUsersVenQuery,
        variables: {
            entity: "OW_Vend_portal_cmpprfuser",
            ou: currentContext?.ouId,
            service: "",
            vendorCode: selectedVendor?.supplierCode,
            languageId: currentContext?.langId,
            User: selectedVendor?.loginUser,
            vendorName: selectedVendor?.supplierName,
            merrorid: "",
        },
        skip: !portalKey,
    });

    useEffect(() => {
        if (CompanyUsersVenData?.CompanyUsersVen) {
            setCompanyUsersDataVen(CompanyUsersVenData?.CompanyUsersVen);
        }
    }, [CompanyUsersVenData]);

    useEffect(() => {
        if (CompanyUsersVenDataError && !CompanyUsersVenDataLoading) {
            const data = JSON.parse(JSON.stringify(CompanyUsersVenDataError));
            const errmsg = data?.response?.errors[0].message;
            seterr({
                code: 400,
                msg: errmsg,
            });
            setAlert(true);
        }
    }, [CompanyUsersVenDataError, CompanyUsersVenDataLoading]);

    const {
        data: CompanyDetailsTableData,
        error: CompanyDetailsTableDataError,
        isLoading: CompanyDetailsTableDataLoading,
    } = useGetPosts({
        name: `CompanyDetailsTableDataCusQuery_${selectedCustomer?.loginUser}_${selectedCustomer?.customerCode}`,
        query: CompanyDetailsTableDataCusQuery,
        variables: {
            entity: "OW_Cust_portal_getcompprfluser",
            ou: currentContext?.ouId,
            service: "",
            user: selectedCustomer?.loginUser,
            langId: currentContext?.langId,
            customerCode: selectedCustomer?.customerCode,
            customerName: "",
            merrorid: "",
        },
        skip: portalKey,
    });

    useEffect(() => {
        console.log(
            "CompanyTableDataCus",
            CompanyDetailsTableData?.CompanyTableDataCus,
        );
        // const data=[
        //     {
        //         "Name": "Dorothy Golden",
        //         "Email": "dgolden@wm.com",
        //         "UserName": "C1001570-C101",
        //         "Designation": "",
        //         "isActive": "Yes",
        //         "phone": "(602) 390-0024",
        //         "MakePayment": "Yes",
        //         "OrderSupplies": "Yes",
        //         "Admin": "Yes",
        //         "Image": ""
        //     },
        //     {
        //         "Name": "OpenworksTestUser",
        //         "Email": "testuser@openworksweb.com",
        //         "UserName": "C1001570-C2",
        //         "Designation": "",
        //         "isActive": "Yes",
        //         "phone": "(602) 390-0024",
        //         "MakePayment": "Yes",
        //         "OrderSupplies": "Yes",
        //         "Admin": "Yes",
        //         "Image": ""
        //     },
        //     {
        //         "Name": "OWTechnologyTestTest",
        //         "Email": "trish@owtest.com",
        //         "UserName": "C1001570-C3",
        //         "Designation": "",
        //         "isActive": "Yes",
        //         "phone": "(602) 390-0024",
        //         "MakePayment": "Yes",
        //         "OrderSupplies": "Yes",
        //         "Admin": "Yes",
        //         "Image": ""
        //     }
        // ]
        if (CompanyDetailsTableData?.CompanyTableDataCus) {
            setCompanyDetailsTableData(
                CompanyDetailsTableData?.CompanyTableDataCus,
            );
            setDisabled(true);
        }
    }, [CompanyDetailsTableData]);

    useEffect(() => {
        if (CompanyDetailsTableDataError && !CompanyDetailsTableDataLoading) {
            const data = JSON.parse(
                JSON.stringify(CompanyDetailsTableDataError),
            );
            const errmsg = data?.response?.errors[0].message;
            seterr({
                code: 400,
                msg: errmsg,
            });
            setAlert(true);
        }
    }, [CompanyDetailsTableDataError, CompanyDetailsTableDataLoading]);

    const {
        data: CompanyDetailsPostUser,
        error: CompanyDetailsPostUserError,
        isLoading: CompanyDetailsPostUserLoading,
    } = useGetPosts({
        name: `CompanyDetailsPostUserCusQuery_${selectedCustomer?.loginUser}_${selectedCustomer?.customerCode}`,
        query: CompanyDetailsPostUserCusQuery,
        variables: {
            entity: "OW_Cust_portal_userdtlsmodify",
            ou: currentContext?.ouId,
            service: "",
            user: selectedCustomer?.loginUser,
            langId: currentContext?.langId,
            customerCode: selectedCustomer?.customerCode,
            customerName: final?.CustomerName,
            name: final?.Name,
            Email: final?.Email,
            FirstName: final?.FirstName,
            LastName: final?.LastName,
            JobTitle: final?.JobTitle,
            BusinessPhone: final?.BusinessPhone,
            MobilePhone: final?.MobilePhone,
            isActive: final?.isActive ? "Y" : "N",
            MakePayment: final?.MakePayment ? "Y" : "N",
            OrderSupplies: final?.OrderSupplies ? "Y" : "N",
            Admin: final?.Admin ? "Y" : "N",
            merrorid: "",
        },
        skip: postSkip,
    });

    useEffect(() => {
        if (CompanyDetailsPostUser?.CompanyPostUserCus) {
            // setCompanyDetailsTableData(
            //     CompanyDetailsEditUser?.CompanyEditUserCus,
            // );
        }
    }, [CompanyDetailsPostUser]);

    useEffect(() => {
        if (CompanyDetailsPostUserError && !CompanyDetailsPostUserLoading) {
            const data = JSON.parse(
                JSON.stringify(CompanyDetailsPostUserError),
            );
            const errmsg = data?.response?.errors[0].message;
            seterr({
                code: 400,
                msg: errmsg,
            });
            setAlert(true);
        }
    }, [CompanyDetailsPostUserError, CompanyDetailsPostUserLoading]);

    const [refresh, setRefresh] = useState(0);
    const {
        data: GetFileDetailsdata,
        error: GetFileDetailsError,
        isLoading: GetFileDetailsLoading,
    } = useGetPosts({
        name: `GetFileDetailsAndComboQuery_${supplierCode}_${refresh}`,
        query: GetFileDetailsAndComboQuery,
        variables: {
            // entity: "OW_Cust_portal_getcompprfluser",
        role:currentContext?.roleName,
        langId: currentContext?.langId,
        ou: currentContext?.ouId,
        supplierCode,
        callingFrom:"dsb"  
            
        },
        // skip: portalKey,
        cacheState:true,
        tryForceFetch:true,
    });

    console.log(portalKey);
    console.log("GetFileDetailsdata",GetFileDetailsdata);
    

    return (
        <>
            {alert ? handleErrorAlert(errr.code, errr.msg) : <></>}
            <Backdrop
                className={classes.backdrop}
                open={
                    CompanyDetailsTableDataLoading ||
                    CompanyProfileDataLoading ||
                    CompanyDetailsPostUserLoading ||
                    CompanyUsersVenDataLoading ||
                    CompanyProfileVenDataLoading ||
                    GetDepositInfoDataLoading
                    // ||CompanyDetailsEditUserLoading
                }
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <Grid container spacing={2} style={{ paddingTop: "2%",marginBottom: '4rem' }}>
                <Grid item xs={12} className="font-smaller">
                    <Paper elevation={4}>
                        <Grid container>
                            <Grid
                                item
                                xs={2}
                                style={{ borderRight: "1px dashed #D5D5D5" }}
                            >
                                <Grid container style={{ padding: "2%" }}>
                                    <Grid
                                        item
                                        xs={12}
                                        className="text-left grey"
                                    >
                                        Logo
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        className="text-center grey2 font-bolder"
                                    >
                                        <Paper
                                            variant="outlined"
                                            style={{
                                                maxWidth: "75px",
                                                maxHeight: "75px",
                                                minWidth: "75px",
                                                minHeight: "75px",
                                                borderRadius: "100px",
                                                display: "inline-block",
                                                // border: `${
                                                //     portalKey ? "" : "none"
                                                // }`,
                                            }}
                                        >
                                            <img
                                                src={
                                                    portalKey
                                                        ? companyProfileDataVen?.logourl
                                                        : companyProfileData?.logourl
                                                }
                                                style={{
                                                    display: "inline-block",
                                                    marginTop: "15px",
                                                    maxWidth: "45px",
                                                    maxHeight: "45px",
                                                    minHeight: "45px",
                                                    minWidth: "45px",
                                                }}
                                            />
                                        </Paper>
                                    </Grid>
                                    {/* <Grid item xs={12}>
                                        {portalKey ? (
                                            <img
                                                src={`${companyProfileDataVen?.logourl}`}
                                                style={{
                                                    display: "inline-block",
                                                    marginTop: "15px",
                                                    maxWidth: "45px",
                                                    maxHeight: "45px",
                                                    minHeight: "45px",
                                                    minWidth: "45px",
                                                }}
                                            />
                                        ) : (
                                            <Icons Icon={"companylogo"} />
                                        )}
                                    </Grid> */}
                                </Grid>
                            </Grid>
                            <Grid
                                item
                                xs={portalKey ? 5 : 8}
                                md={portalKey ? 7 : 8}
                                className={
                                    portalKey ? "border_right_dashed" : ""
                                }
                            >
                                <Grid
                                    container
                                    alignItems="center"
                                    justifyContent="space-between"
                                    className={`padding-2-percent text-left`}
                                    spacing={1}
                                >
                                    <Grid
                                        item
                                        xs={12}
                                        md={7}
                                        // style={{ paddingLeft: "2%" }}
                                    >
                                        <Grid container>
                                            <Grid
                                                item
                                                xs={12}
                                                className="grey"
                                                style={{ paddingBottom: "2%" }}
                                            >
                                                {portalKey
                                                    ? "Address Info"
                                                    : "Billing Info"}
                                            </Grid>
                                            <Grid
                                                item
                                                xs={12}
                                                className="font-bolder"
                                            >
                                                {portalKey
                                                    ? companyProfileDataVen?.address
                                                    : companyProfileData
                                                          ?.BillingInfo[0]
                                                          ?.area}
                                            </Grid>
                                            <Grid
                                                item
                                                xs={12}
                                                className="font-bolder"
                                            >
                                                {portalKey &&
                                                companyProfileDataVen?.city &&
                                                companyProfileDataVen?.state &&
                                                companyProfileDataVen?.country
                                                    ? `${companyProfileDataVen?.city}, ${companyProfileDataVen?.state}, ${companyProfileDataVen?.country},`
                                                    : companyProfileData
                                                          ?.BillingInfo[0]
                                                          ?.address}
                                            </Grid>
                                            <Grid
                                                item
                                                xs={12}
                                                className="font-bolder"
                                            >
                                                {portalKey
                                                    ? companyProfileDataVen?.zip
                                                    : companyProfileData
                                                          ?.BillingInfo[0]
                                                          ?.pincode}
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        md={5}
                                        // style={{ paddingLeft: "3%" }}
                                    >
                                        <Grid container>
                                            <Grid
                                                item
                                                xs={12}
                                                className="grey"
                                                style={{ paddingBottom: "2%" }}
                                            >
                                                {portalKey
                                                    ? "Main Contact"
                                                    : "Contact Person"}
                                            </Grid>
                                            <Grid
                                                item
                                                xs={12}
                                                className="font-bolder"
                                            >
                                                {portalKey
                                                    ? companyProfileDataVen?.contactperson
                                                    : companyProfileData
                                                          ?.ContactPerson[0]
                                                          ?.name}
                                            </Grid>
                                            <Grid
                                                item
                                                xs={12}
                                                className="font-bolder blue"
                                            >
                                                {portalKey
                                                    ? companyProfileDataVen?.contactpersonemailaddress
                                                    : companyProfileData
                                                          ?.ContactPerson[0]
                                                          ?.email}
                                            </Grid>
                                            <Grid
                                                item
                                                xs={12}
                                                className="font-bolder"
                                            >
                                                {portalKey
                                                    ? companyProfileDataVen?.contactpersonphonenumber
                                                    : companyProfileData
                                                          ?.ContactPerson[0]
                                                          ?.businessnumber}
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            {portalKey ? (
                                <Grid item xs={5} md={3}>
                                    <Grid
                                        container
                                        justifyContent="center"
                                        alignItems="center"
                                        className="text-center"
                                        style={{
                                            padding: "5%",
                                            // textAlign: "center",
                                        }}
                                        spacing={2}
                                    >
                                        <Grid item xs={12}>
                                            <MUIButton
                                                label="Upload Direct Deposit Information"
                                                onClick={() => {
                                                    

                                                        setuploadSideBar(true);
                                                    
                                                }}
                                                type="filled"
                                                font="fontMedium"
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <MUIButton
                                                label="Download Authorization Form"
                                                onClick={() => {}}
                                                type="outlined"
                                                font="fontMedium"
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            ) : (
                                <></>
                            )}
                        </Grid>
                    </Paper>
                </Grid>
               { portalKey && <Grid item xs={10}>
                    <ButtonGroups
                        controlList={controlList}
                        handleDocList={handleDocList}
                        gridSpacing={2}
                        externalActive={activeList}
                    />
                </Grid>}
                <Grid item xs={10}></Grid>
                <Grid item xs={2} md={2} lg={2} style={{ textAlign: "left" }}>
                    {admin && activeList === "users" ? (
                        <MUIButton
                            label="+ Add User"
                            onClick={() => {
                                if(userReadOnlyAccess){

                                    handleSideBar("Add User");
                                }
                            }}
                            type="transparent"
                            font="fontMedium"
                        />
                    ) : (
                        <></>
                    )}
                </Grid>

                {portalKey && activeList === "users" ? (
                    <Grid item xs={12}>
                        {companyUsersDataVen?.length ? (
                            <MuiDatatables
                                header={CompanyTableDataVenSchema}
                                detail={[
                                    companyUsersDataVen,
                                    setCompanyUsersDataVen,
                                ]}
                            />
                        ) : (
                            <img
                                src={NoUsersProfile}
                                style={{ paddingTop: "4%" }}
                            />
                        )}
                    </Grid>
                ) : activeList === "users" ? (
                    <Grid item xs={12}>
                        {companyDetailsTableData?.length ? (
                            <MuiDatatables
                                header={CompanyTableDataSchema}
                                detail={[
                                    companyDetailsTableData,
                                    setCompanyDetailsTableData,
                                ]}
                            />
                        ) : (
                            <img
                                src={NoUsersProfile}
                                style={{ paddingTop: "4%" }}
                            />
                        )}
                    </Grid>
                ) : (
                    <DocGroups docflag={""} activeList={activeList} FileDetailsCardList={GetFileDetailsdata?.FileCardsList??[]} RefetchAfterDelete={setRefresh}/>
                )}

              { portalKey && activeList !== "users" && <Grid item xs={12} md={12} lg={12}>
                    <Paper
                        className=""
                        style={{
                            position: "fixed",
                            bottom: "0px",
                            left: "0",
                            width: "100%",
                            height: "5rem",
                            padding: "1.5rem 2.5rem",
                            background: '#F7F9FB'

                        }}
                    >
                        <Grid
                            container
                            justifyContent="flex-end"
                            alignItems="flex-end"
                            spacing={0}
                        >
                            <Grid item xs={2}>
                                <MUIButton
                                    label="Upload Document"
                                    onClick={() => {
                                        setUploadSideDocs(true);
                                    }}
                                    type="outlined"
                                    font="fontMedium"
                                />
                            </Grid>
                            {/* <Grid item xs={1}>
                                <MUIButton
                                    label="Save"
                                    onClick={() => {}}
                                    type="filled"
                                    font="fontMedium"
                                />
                            </Grid> */}
                        </Grid>
                    </Paper>
                </Grid>}

                <SwipeableDrawerComp
                    drawerState={[sideBar, setSideBar]}
                    header={
                        sideScreen === "Add User" ? "Add User" : "User Details"
                    }
                    headerColor={"grey2"}
                    // customPadding={{ margin: "4% 3% 1% 3%" }}
                    Child={
                        portalKey ? (
                            <CompanySideDrawChildVen
                                currentContext={currentContext}
                                userDetails={data}
                                companyProfileName={companyProfileName}
                                username={selectedUser}
                                AddUser={sideScreen === "Add User"}
                            />
                        ) : (
                            <CompanySideDrawChild
                                userDetails={data}
                                handleSubmit={handleSubmit}
                                handleReset={handleReset}
                                selectedCustomer={selectedCustomer}
                                currentContext={currentContext}
                                companyProfileName={companyProfileName}
                                companyName={companyDetailsTableData?.custname}
                                sideScreen={sideScreen}
                                username={selectedUser}
                            />
                        )
                    }
                />

                <SwipeableDrawerComp
                    drawerState={[uploadSideBar, setuploadSideBar]}
                    header={"Upload Direct Deposit Information"}
                    headerColor={"grey2"}
                    customPadding={{}}
                    Child={
                        <UploadInfoSideDraw
                            getDepositInfoDatas={getDepositInfoDatas}
                        />
                    }
                />
                <SwipeableDrawerComp
                    drawerState={[uploadSideDocs, setUploadSideDocs]}
                    header={"Upload Documents"}
                    headerColor={"grey2"}
                    customPadding={{}}
                    Child={
                        <UploadDocsSideDraw
                            getDepositInfoDatas={getDepositInfoDatas}
                            closeSideBar={setUploadSideDocs}
                            sidebarstate={uploadSideDocs}
                            onSubmitRefetch={setRefresh}
                        />
                    }
                />
            </Grid>
        </>
    );
};

const mapStateToProps = (state: any) => {
    return {
        currentContext: state.context?.userDefaults,
        userName: state.context?.name,
        selectedCustomer: state.selectedCustomer,
        portalKey: state.portalKey,
        selectedVendor: state.selectedVendor,
        supplierCode: state?.selectedVendor?.supplierCode,
        loginUser: state?.selectedVendor?.loginUser,
        userReadOnlyAccess:state?.userReadOnlyAccess
    };
};
const mapDispatchToProps = (dispatch: any) => {
    return {
        setBreadcrumbsData: (data: any) => {
            dispatch(storeBreadcrumbs(data));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Body);
