import { Grid, Paper } from "@material-ui/core";
import Icons from "Common/Icons/Icons";
import React from "react";

export default function ErrorCard(
    image: string,
    primaryText: string,
    secondaryText: string,
) {
    return (
        <Paper elevation={3} style={{ padding: "3.5%", minWidth: "99%" }}>
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <Icons Icon={image} />
                </Grid>
                <Grid
                    item
                    xs={6}
                    style={{ padding: "8%" }}
                    className="text-left"
                >
                    <Grid container>
                        <Grid
                            item
                            xs={12}
                            className="darkblue"
                            style={{
                                fontSize: "20px",
                                fontWeight: 700,
                            }}
                        >
                            {primaryText}
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            style={{
                                fontSize: "14px",
                                fontWeight: 600,
                            }}
                        >
                            {secondaryText}
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Paper>
    );
}