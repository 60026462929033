const GetFileDetailsAndComboQuery = `
    query GetFileDetailsAndComboQuery($supplierCode: String!, $callingFrom: String!,$ou:Int,$langId:Int,$role:String){
      FileCardsList:GetFileDetailsAndCombo(supplierCode: $supplierCode, callingFrom: $callingFrom,ou:$ou,langId:$langId,role:$role) {
        fileType
        desc:fileDescription
        fileName
        link:filePath
        fileExtension
        serverFileName:addnlParam1
      }
    }
`;

export default GetFileDetailsAndComboQuery;
