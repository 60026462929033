import React from "react";
import TextField from "@material-ui/core/TextField";
import "./TextField.scss";
import { createTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/core/styles";
import Icons from "Common/Icons/Icons";

interface TextFieldProps {
    label: string;
    id: string;
    multiline?: boolean;
    rows?: number;
    type?: string;
    value: string | number;
    handleChange: (e: React.ChangeEvent<{ value: unknown }>) => void;
    width: string;
    placeHolder?:string;
}

const theme = createTheme({
    palette: {
        primary: {
            main: "#0073e6",
        },
        secondary: {
            main: "#0073e6",
        },
    },
    overrides: {
        MuiInputBase: {
            input: { color: "#5C677D" },
        },
        MuiOutlinedInput: {
            input: { padding: "2px !important" },
            notchedOutline: {
                border: "none",
                fontFamily: "OpenSans, sans-serif",
                borderColor: "#fff !important",
            },
        },
    },
});

const HiddenTextField = (props: TextFieldProps) => {
    const {
        label,
        id,
        value,
        handleChange,
        width,
        multiline,
        rows,
        type,
        placeHolder="",
    } = props;

    const handleNumeric = (
        e: React.ChangeEvent<{
            value: any;
        }>,
    ) => {
        if (type === "number") {
            if (e.target.value < 0 || isNaN(parseFloat(e.target.value)))
                e.target.value = "";
            console.log(
                "Numeric Checking",
                e.target.value,
                parseFloat(e.target.value),
            );
            handleChange(e);
        } else handleChange(e);
    };
    return (
        <ThemeProvider theme={theme}>
            <div className="hidden-input-field">
                <TextField
                    label={label}
                    id={id}
                    name={id}
                    value={value}
                    variant="outlined"
                    size="small"
                    type={type}
                    onChange={handleNumeric}
                    multiline={multiline}
                    rows={rows ? rows : 1}
                    style={{ width: width }}
                    color="primary"
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                        inputProps: { min: 0 },
                        endAdornment: <Icons Icon="GreyColorSearchIcon" />,
                    }}
                    placeholder={placeHolder}
                />
            </div>
        </ThemeProvider>
    );
};

export default HiddenTextField;
