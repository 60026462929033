const ReplacementListQuerySV = `
query Query {
    GetReplacementListSV {
        Assignment,
        CustomerContract,
        DateofReplacement,
        Amount,
  }
}
  `;

export default ReplacementListQuerySV;
