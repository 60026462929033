import { STORE_SESSION_ID } from "../actionTypes";

const initialState = {
  sessionId: "",
};

const storeSessionId = (prevState = initialState.sessionId, action: any) => {
  switch (action.type) {
    case STORE_SESSION_ID:
      return action.payload;

    default:
      return prevState;
  }
};

export default storeSessionId;
