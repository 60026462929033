const contextApiQuery = `
query GetContextActivities {
    contexts {
        name
        fullName
        userDefaults {
            langId
            ouId
            ouDescription
            roleName
        }
        roles {
            description
            name
            ous {
                id
                description
            }
        }
    }
}`;

export default contextApiQuery;
