import {
    Backdrop,
    Checkbox,
    Divider,
    Fade,
    Grid,
    makeStyles,
    Modal,
    Paper,
} from "@material-ui/core";
import MUIButton from "Common/Button/Button";
import React, { Fragment, useEffect, useState } from "react";
import TextFields from "Common/TextField/TextField";
import { SwitchClassKey, SwitchProps } from "@material-ui/core/Switch";
import { FormControlLabel, Switch, Theme } from "@material-ui/core";
import { withStyles, createStyles } from "@material-ui/styles";
import BackdropLoading from "Common/Backdrop/Backdrop";
import {
    AssociatedBuildingPostQuery,
    BuildingComboASQuery,
    CompanyDetailsEditUserCusQuery,
    CompanyDetailsPostUserCusQuery,
    GetAllBuildingQuery,
    GetAssociatedBuildingQuery,
} from "ReactQuery/Query";
import useGetPosts from "ReactQuery/reactQuery";
import ButtonGroups from "Common/ButtonGroup/ButtonGroup";
import AddBuildings from "./Images/AddBuildings.svg";
import SearchBuildings from "./Images/SearchBuildings.svg";
import Dropdown from "Common/Dropdown/Dropdown";
import Button from "Common/Button/Button";
import Icons from "Common/Icons/Icons";
import AssociateBuildingCard from "./Cards/AssociateBuildingCard";
import NoBuildings from "./Images/NoBuildings.svg";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { v4 as uuidv4 } from "uuid";
import Alert from "Common/Alerts/Alerts";
import { connect } from "react-redux";
interface Styles extends Partial<Record<SwitchClassKey, string>> {
    focusVisible?: string;
}

interface Props extends SwitchProps {
    classes: Styles;
}

const MSMESwitch = withStyles((theme: Theme) =>
    createStyles({
        root: {
            width: 36,
            height: 20,
            padding: 0,
            margin: theme.spacing(1),
        },
        switchBase: {
            padding: 1,
            "&$checked": {
                transform: "translateX(16px)",
                color: theme.palette.common.white,
                "& + $track": {
                    backgroundColor: "green",
                    opacity: 1,
                    border: "none",
                },
            },
            "&$focusVisible $thumb": {
                color: "green",
                border: "6px solid green",
            },
        },
        thumb: {
            width: 18,
            height: 18,
        },
        track: {
            borderRadius: 24 / 2,
            border: `1px solid ${theme.palette.grey[400]}`,
            backgroundColor: theme.palette.grey[50],
            opacity: 1,
            transition: theme.transitions.create([
                "background-color",
                "border",
            ]),
        },
        checked: {},
        focusVisible: {},
    }),
)(({ classes, ...props }: Props) => {
    console.log(props.name, props.checked);
    return (
        <Switch
            focusVisibleClassName={classes.focusVisible}
            disableRipple
            classes={{
                root: classes.root,
                switchBase: classes.switchBase,
                thumb: classes.thumb,
                track: classes.track,
                checked: classes.checked,
            }}
            {...props}
        />
    );
});

const useStyles = makeStyles((theme) => ({
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        // border: "2px solid #000",
        boxShadow: "4px 6px 4px rgba(2, 62, 125, 0.05)",
        padding: theme.spacing(1, 1, 1, 1),
        maxWidth: "80%",
        maxHeight: "80%",
    },
}));

const CompanySideDrawChild = (props: any) => {
    const {
        // handleReset,
        currentContext,
        selectedCustomer,
        companyProfileName,
        companyName,
        sideScreen,
        userDetails,
        setSideBar,
        RefetchCount,
        selectedVendor,
        username,
        userReadOnlyAccess,
    } = props;
    const initialFormValues = {
        Name: "",
        UserName: "",
        FirstName: "",
        LastName: "",
        JobTitle: "",
        Email: "",
        BusinessPhone: "",
        MobilePhone: "",
        isActive: false,
        MakePayment: false,
        OrderSupplies: false,
        Admin: false,
        CustomerName: "",
        ContactId: "",
    };
    const [refetchCount, setRefetchCount] = useState(0);
    // const [refetchCount, setRefetchCount]=RefetchCount;
    const classes = useStyles();
    const [sideDrawData, setSideDrawData] = useState<any>(initialFormValues);
    const [submit, setSubmit] = useState(true);
    const [buildingSearch, setBuildingSearch] = useState(false);
    const [postResult, setPostResult] = useState({ code: 0, msg: "" });
    const [errr, seterr] = useState({
        code: 0,
        msg: "",
    });
    const [alert, setAlert] = useState(false);
    const [selectAll, setSelectAll] = useState(false);
    const [selectedAll, setSelectedAll] = useState(false);
    const [buildingData, setBuildingData] = useState<any>([]);

    const controlList = [
        {
            desc: "User Profile",
            value: "userProfile",
        },
        {
            desc: "Associated Buildings",
            value: "associatedBuildings",
        },
    ];

    const [activeList, setActiveList] = useState(controlList[0].value);
    const [open, setOpen] = useState(false);
    const [postBuidling, setPostBuidling] = useState(false);
    const [postBuidlingFlag, setPostBuidlingFlag] = useState("SUBMIT");
    const [showBuildings, setShowBuildings] = useState(false);
    const [postCount, setPostCount] = useState(uuidv4());
    const [selectedBuildings, setSelectedBuildings] = useState<any>([]);
    const [comboValues, setComboValues] = useState<any>({
        deliveryArea: [],
        regionalOffice: [],
        state: [],
    });
    const [searchValues, setSearchValues] = useState<any>({
        deliveryArea: "",
        regionalOffice: "",
        state: "",
    });
    const handleReset = () => {
        seterr({
            code: 0,
            msg: "",
        });
        if (sideScreen !== "Add User") {
            setRefetchOnReset((prev) => prev + 1);
        } else {
            setSideDrawData(initialFormValues);
        }
    };

    const handleErrorAlert = (code: number, message: string) => {
        Alert({
            statusCode: code,
            message: message,
            willClose: () => {
                setAlert(false);
                seterr({ code: 0, msg: "" });
                setSideBar(false);
                setRefetchCount((prevcnt: number) => prevcnt + 1);
            },
        });
    };

    const handleChange = (e: React.ChangeEvent<{ value: any }>) => {
        if (errr.code === 400) {
            seterr({
                code: 0,
                msg: "",
            });
        }
        let name = (e?.target as any).name;
        let value = (e?.target as any).value;
        setSideDrawData((prevState: any) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const handleChangeCheck = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSideDrawData((data: any) => ({
            ...data,
            [event.target.name]: event.target.checked,
        }));
    };

    const handleDocList = (listType: string) => {
        console.log("listType", listType);
        setActiveList(listType);
    };

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleResetBuilding = (id: number) => {
        const updatedBuildings = buildingData.map((building: any) => ({
            ...building,
            selected: false,
        }));
        setBuildingData(updatedBuildings);
    };

    function getSelectedBuildings(buildings: any) {
        const selectedBuilding = buildings.filter(
            (building: any) => building.selected,
        );
        return [...selectedBuilding, ...selectedBuildings].map(
            (building: any) =>
                building.selected ? { ...building, selected: false } : building,
        );
    }

    useEffect(() => {
        if (sideScreen === "Add User") {
            handleReset();
            seterr({ code: 0, msg: "" });
        }
    }, []);
    console.log("errr", errr);

    const [refetchOnReset, setRefetchOnReset] = useState(0);
    const {
        data: CompanyDetailsEditUser,
        error: CompanyDetailsEditUserError,
        isLoading: CompanyDetailsEditUserLoading,
    } = useGetPosts({
        name: `CompanyDetailsEditUserCusQuery_${companyProfileName}_${
            username ? username : selectedCustomer?.loginUser
        }_${selectedCustomer?.customerCode}_${refetchOnReset}`,
        query: CompanyDetailsEditUserCusQuery,
        variables: {
            entity: "OW_Cust_portal_userdtlsedit",
            ou: currentContext?.ouId,
            service: "",
            user: username ? username : selectedCustomer?.loginUser,
            langId: currentContext?.langId,
            customerCode: selectedCustomer?.customerCode,
            customerName: "",
            name: companyProfileName,
            merrorid: "",
        },
        skip: sideScreen === "Add User",
    });

    useEffect(() => {
        if (
            CompanyDetailsEditUser?.CompanyEditUserCus &&
            sideScreen !== "Add User"
        ) {
            const tableData = CompanyDetailsEditUser?.CompanyEditUserCus[0];
            setSideDrawData({
                Name: tableData?.Name,
                UserName: tableData?.UserName,
                FirstName: tableData?.FirstName,
                LastName: tableData?.LastName,
                JobTitle: tableData?.JobTitle ?? "",
                Email: tableData?.Email,
                BusinessPhone: tableData?.BusinessPhone,
                MobilePhone: tableData?.MobilePhone,
                isActive:
                    tableData?.isActive &&
                    tableData?.isActive?.toLowerCase() === "yes"
                        ? true
                        : false,
                MakePayment:
                    tableData?.MakePayment?.toLowerCase() === "yes"
                        ? true
                        : false,
                OrderSupplies:
                    tableData?.OrderSupplies?.toLowerCase() === "yes"
                        ? true
                        : false,
                Admin: tableData?.Admin?.toLowerCase() === "yes" ? true : false,
                ContactId: tableData?.contactaddid,
            });
        }
    }, [CompanyDetailsEditUser]);

    useEffect(() => {
        if (
            CompanyDetailsEditUserError &&
            !CompanyDetailsEditUserLoading &&
            sideScreen !== "Add User"
        ) {
            const data = JSON.parse(
                JSON.stringify(CompanyDetailsEditUserError),
            );
            const errmsg = data?.response?.errors[0].message;
            console.log(errmsg);

            seterr({
                code: 400,
                msg: errmsg,
            });
        }
    }, [CompanyDetailsEditUserError, CompanyDetailsEditUserLoading]);

    const {
        data: CompanyDetailsPostUser,
        error: CompanyDetailsPostUserError,
        isLoading: CompanyDetailsPostUserLoading,
    } = useGetPosts({
        name: `CompanyDetailsPostUserCusQuery_${selectedCustomer?.loginUser}_${selectedCustomer?.customerCode}_${refetchCount}`,
        query: CompanyDetailsPostUserCusQuery,
        variables: {
            ActionFlag: sideScreen === "Add User" ? "New" : "Edit",
            entity: "OW_Cust_portal_userdtlsmodify",
            ou: currentContext?.ouId,
            service: "",
            user: selectedCustomer?.loginUser,
            langId: currentContext?.langId,
            customerCode: selectedCustomer?.customerCode,
            customerName: "",
            name: selectedCustomer?.FirstName + sideDrawData?.LastName,
            Email: sideDrawData?.Email,
            ContactId: sideDrawData?.ContactId,
            FirstName: sideDrawData?.FirstName,
            LastName: sideDrawData?.LastName,
            JobTitle: sideDrawData?.JobTitle,
            BusinessPhone: sideDrawData?.BusinessPhone,
            MobilePhone: sideDrawData?.MobilePhone,
            isActive: sideDrawData?.isActive ? "Y" : "N",
            MakePayment: sideDrawData?.MakePayment ? "Y" : "N",
            OrderSupplies: sideDrawData?.OrderSupplies ? "Y" : "N",
            Admin: sideDrawData?.Admin ? "Y" : "N",
            merrorid: "",
        },
        skip: submit,
    });

    useEffect(() => {
        console.log(
            "CompanyEditUserCus",
            CompanyDetailsPostUser?.CompanyPostUserCus,
        );
        if (CompanyDetailsPostUser?.CompanyPostUserCus && !submit) {
            seterr({
                code: 200,
                msg: CompanyDetailsPostUser?.CompanyPostUserCus[0]?.Message,
            });
            setAlert(true);
        }
        setSubmit(true);
    }, [CompanyDetailsPostUser]);

    useEffect(() => {
        if (
            CompanyDetailsPostUserError &&
            !CompanyDetailsPostUserLoading &&
            !submit
        ) {
            const data = JSON.parse(
                JSON.stringify(CompanyDetailsPostUserError),
            );
            const errmsg = data?.response?.errors[0].message;
            console.log(CompanyDetailsPostUserError);
            console.log(errmsg);

            seterr({
                code: 400,
                msg: errmsg,
            });
            setSubmit(true);
        }
    }, [CompanyDetailsPostUserError, CompanyDetailsPostUserLoading]);

    const {
        data: GetAssociatedBuildingData,
        error: GetAssociatedBuildingError,
        isLoading: GetAssociatedBuildingLoading,
    } = useGetPosts({
        name: `GetAssociatedBuilding_${userDetails?.Email}_${selectedCustomer?.customerCode}`,
        query: GetAssociatedBuildingQuery,
        variables: {
            entity: "OW_Cust_portal_as_buil_entity",
            ou: currentContext?.ouId,
            service: "",
            user: selectedCustomer?.loginUser,
            langId: currentContext?.langId,
            customerCode: selectedCustomer?.customerCode,
            customerEmail: sideDrawData?.Email,
            name: companyProfileName,
            merrorid: "",
        },
        skip: !sideDrawData || activeList !== "associatedBuildings",
    });
    useEffect(() => {
        if (GetAssociatedBuildingData?.GetAssociatedBuilding) {
            setSelectedBuildings(
                GetAssociatedBuildingData?.GetAssociatedBuilding,
            );
        }
    }, [GetAssociatedBuildingData]);

    useEffect(() => {
        if (GetAssociatedBuildingError && !GetAssociatedBuildingLoading) {
            const data = JSON.parse(JSON.stringify(GetAssociatedBuildingError));
            const errmsg = data?.response?.errors[0].message;
            // toast.error(errmsg, {
            //     position: "top-center",
            //     autoClose: 2000,
            //     hideProgressBar: false,
            //     closeOnClick: true,
            //     pauseOnHover: true,
            //     draggable: true,
            //     progress: undefined,
            //     theme: "colored",
            // });
        }
    }, [GetAssociatedBuildingError, GetAssociatedBuildingLoading]);

    const {
        data: DeliveryAreaComboData,
        error: DeliveryAreaComboError,
        isLoading: DeliveryAreaComboLoading,
    } = useGetPosts({
        name: `DeliveryAreaCombo_${selectedCustomer?.customerCode}_${selectedCustomer?.loginUser}`,
        query: BuildingComboASQuery,
        variables: {
            entity: "OW_del_reg_state_combo_entity",
            ou: currentContext?.ouId,
            service: "",
            User: selectedCustomer?.loginUser,
            languageId: currentContext?.langId,
            suppliercode: selectedCustomer?.customerCode,
            callingfrom: "DEL_AREA",
            merrorid: "0",
        },
        skip: !sideDrawData || activeList !== "associatedBuildings",
    });
    useEffect(() => {
        if (DeliveryAreaComboData?.BuildingComboAS) {
            setComboValues((prevState: any) => ({
                ...prevState,
                deliveryArea: DeliveryAreaComboData?.BuildingComboAS.map(
                    (element: any) => {
                        return {
                            desc: element.name,
                            value: element.name,
                        };
                    },
                ),
            }));
            setSearchValues((prevState: any) => ({
                ...prevState,
                deliveryArea: DeliveryAreaComboData?.BuildingComboAS[0].name,
            }));
        }
    }, [DeliveryAreaComboData]);

    useEffect(() => {
        if (DeliveryAreaComboError && !DeliveryAreaComboLoading) {
            const data = JSON.parse(JSON.stringify(DeliveryAreaComboError));
            const errmsg = data?.response?.errors[0].message;
            // toast.error(errmsg, {
            //     position: "top-center",
            //     autoClose: 2000,
            //     hideProgressBar: false,
            //     closeOnClick: true,
            //     pauseOnHover: true,
            //     draggable: true,
            //     progress: undefined,
            //     theme: "colored",
            // });
        }
    }, [DeliveryAreaComboError, DeliveryAreaComboLoading]);

    const {
        data: ReginolOfficeComboData,
        error: ReginolOfficeComboError,
        isLoading: ReginolOfficeComboLoading,
    } = useGetPosts({
        name: `ReginolOfficeCombo_${selectedCustomer?.customerCode}_${selectedCustomer?.loginUser}`,
        query: BuildingComboASQuery,
        variables: {
            entity: "OW_del_reg_state_combo_entity",
            ou: currentContext?.ouId,
            service: "",
            User: selectedCustomer?.loginUser,
            languageId: currentContext?.langId,
            suppliercode: selectedCustomer?.customerCode,
            callingfrom: "REG_OFFICE",
            merrorid: "0",
        },
        skip: !sideDrawData || activeList !== "associatedBuildings",
    });
    useEffect(() => {
        if (ReginolOfficeComboData?.BuildingComboAS) {
            setComboValues((prevState: any) => ({
                ...prevState,
                regionalOffice: ReginolOfficeComboData?.BuildingComboAS.map(
                    (element: any) => {
                        return {
                            desc: element.name,
                            value: element.name,
                        };
                    },
                ),
            }));
            setSearchValues((prevState: any) => ({
                ...prevState,
                regionalOffice: ReginolOfficeComboData?.BuildingComboAS[0].name,
            }));
        }
    }, [ReginolOfficeComboData]);

    useEffect(() => {
        if (ReginolOfficeComboError && !ReginolOfficeComboLoading) {
            const data = JSON.parse(JSON.stringify(ReginolOfficeComboError));
            const errmsg = data?.response?.errors[0].message;
            // toast.error(errmsg, {
            //     position: "top-center",
            //     autoClose: 2000,
            //     hideProgressBar: false,
            //     closeOnClick: true,
            //     pauseOnHover: true,
            //     draggable: true,
            //     progress: undefined,
            //     theme: "colored",
            // });
        }
    }, [ReginolOfficeComboError, ReginolOfficeComboLoading]);

    const {
        data: StateComboData,
        error: StateComboError,
        isLoading: StateComboLoading,
    } = useGetPosts({
        name: `StateCombo_${selectedCustomer?.customerCode}_${selectedCustomer?.loginUser}`,
        query: BuildingComboASQuery,
        variables: {
            entity: "OW_del_reg_state_combo_entity",
            ou: currentContext?.ouId,
            service: "",
            User: selectedCustomer?.loginUser,
            languageId: currentContext?.langId,
            suppliercode: selectedCustomer?.customerCode,
            callingfrom: "STATE",
            merrorid: "0",
        },
        skip: !sideDrawData || activeList !== "associatedBuildings",
    });
    useEffect(() => {
        if (StateComboData?.BuildingComboAS) {
            setComboValues((prevState: any) => ({
                ...prevState,
                state: StateComboData?.BuildingComboAS.map((element: any) => {
                    return {
                        desc: element.name,
                        value: element.name,
                    };
                }),
            }));
            setSearchValues((prevState: any) => ({
                ...prevState,
                state: StateComboData?.BuildingComboAS[0].name,
            }));
        }
    }, [StateComboData]);

    useEffect(() => {
        if (StateComboError && !StateComboLoading) {
            const data = JSON.parse(JSON.stringify(StateComboError));
            const errmsg = data?.response?.errors[0].message;
            // toast.error(errmsg, {
            //     position: "top-center",
            //     autoClose: 2000,
            //     hideProgressBar: false,
            //     closeOnClick: true,
            //     pauseOnHover: true,
            //     draggable: true,
            //     progress: undefined,
            //     theme: "colored",
            // });
        }
    }, [StateComboError, StateComboLoading]);

    const {
        data: GetAllBuildingData,
        error: GetAllBuildingError,
        isLoading: GetAllBuildingLoading,
    } = useGetPosts({
        name: `GetAllBuildingData_${postCount}_${userDetails?.Email}_${selectedCustomer?.customerCode}_${searchValues?.deliveryArea}_${searchValues?.regionalOffice}_${searchValues?.state}`,
        query: GetAllBuildingQuery,
        variables: {
            entity: "OW_del_reg_state_search_entity",
            ou: currentContext?.ouId,
            service: "",
            user: selectedCustomer?.loginUser,
            langId: currentContext?.langId,
            customerCode: selectedCustomer?.customerCode,
            customerEmail: sideDrawData?.Email,
            deliveryArea: searchValues?.deliveryArea,
            regOffice: searchValues?.regionalOffice,
            state: searchValues?.state,
            name: companyProfileName,
            merrorid: "",
        },
        skip: !buildingSearch,
    });
    useEffect(() => {
        if (GetAllBuildingData?.GetAllBuilding) {
            setBuildingData(GetAllBuildingData?.GetAllBuilding);
        }
        setBuildingSearch(false);
    }, [GetAllBuildingData]);

    useEffect(() => {
        if (GetAllBuildingError && !GetAllBuildingLoading) {
            const data = JSON.parse(JSON.stringify(GetAllBuildingError));
            const errmsg = data?.response?.errors[0].message;
            // toast.error(errmsg, {
            //     position: "top-center",
            //     autoClose: 2000,
            //     hideProgressBar: false,
            //     closeOnClick: true,
            //     pauseOnHover: true,
            //     draggable: true,
            //     progress: undefined,
            //     theme: "colored",
            // });
            console.log("errmsg", errmsg);
        }
    }, [GetAllBuildingError, GetAllBuildingLoading]);

    const {
        data: AssociatedBuildingPostQueryData,
        error: AssociatedBuildingPostQueryError,
        isLoading: AssociatedBuildingPostQueryLoading,
    } = useGetPosts({
        name: `AssociatedBuildingPostQueryData_${selectedCustomer?.loginUser}_${postCount}_${selectedCustomer?.customerCode}`,
        query: AssociatedBuildingPostQuery,
        variables: {
            entityList: selectedBuildings.map((element: any) => {
                return {
                    customercode: selectedCustomer?.customerCode,
                    userid: sideDrawData?.Email,
                    buildingid: element?.buildingID,
                    acionflag:
                        element?.deleted != true ? postBuidlingFlag : "DELETE",
                };
            }),
            contextUser: selectedCustomer?.loginUser,
        },
        header: {
            ou: currentContext?.ouId,
            role: currentContext?.roleName,
            lang: currentContext?.langId,
        },
        skip: !postBuidling,
    });
    useEffect(() => {
        if (
            AssociatedBuildingPostQueryData?.AssociatedBuildingPost &&
            !AssociatedBuildingPostQueryLoading
        ) {
            if (postBuidlingFlag === "DELETE") setSelectedBuildings([]);
            setPostCount(uuidv4());
            console.log("inside Associated", postCount);
            toast.success(
                AssociatedBuildingPostQueryData?.AssociatedBuildingPost
                    ?.responseMessage,
                {
                    position: "top-center",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                },
            );
            setPostBuidling(false);
        }
    }, [AssociatedBuildingPostQueryData, AssociatedBuildingPostQueryLoading]);

    useEffect(() => {
        if (
            AssociatedBuildingPostQueryError &&
            !AssociatedBuildingPostQueryLoading
        ) {
            const data = JSON.parse(
                JSON.stringify(AssociatedBuildingPostQueryError),
            );
            const errmsg = data?.response?.errors[0].message;
            setPostBuidling(false);
            // toast.error(errmsg, {
            //     position: "top-center",
            //     autoClose: 2000,
            //     hideProgressBar: false,
            //     closeOnClick: true,
            //     pauseOnHover: true,
            //     draggable: true,
            //     progress: undefined,
            //     theme: "colored",
            // });
        }
    }, [AssociatedBuildingPostQueryError, AssociatedBuildingPostQueryLoading]);

    return (
        <>
            {alert && errr.code === 200 ? (
                handleErrorAlert(errr.code, errr.msg)
            ) : (
                <></>
            )}
            <Grid
                container
                spacing={3}
                style={{ maxWidth: "550px", minWidth: "550px" }}
            >
                <BackdropLoading
                    openStates={
                        CompanyDetailsEditUserLoading ||
                        CompanyDetailsPostUserLoading ||
                        GetAssociatedBuildingLoading ||
                        GetAllBuildingLoading
                    }
                />
                {sideScreen !== "Add User" && (
                    <Grid item xs={10} md={12} lg={12}>
                        <ButtonGroups
                            controlList={controlList}
                            handleDocList={handleDocList}
                            gridSpacing={4}
                            externalActive={activeList}
                        />
                    </Grid>
                )}
                <Grid item xs={12}>
                    {activeList === "userProfile" ? (
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <Grid container>
                                    <Grid
                                        item
                                        xs={12}
                                        style={{ color: "#7D8597" }}
                                    >
                                        Company
                                    </Grid>
                                    <Grid item xs={12} className="font-bolder">
                                        {companyName ??
                                            selectedVendor?.supplierName}
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={6}>
                                <TextFields
                                    disable={true}
                                    label="UserName"
                                    id="UserName"
                                    multiline={false}
                                    rows={1}
                                    value={
                                        username
                                            ? username
                                            : sideDrawData?.UserName
                                    }
                                    handleChange={handleChange}
                                    width="90%"
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextFields
                                    label="First Name"
                                    id="FirstName"
                                    multiline={false}
                                    rows={1}
                                    value={sideDrawData?.FirstName}
                                    handleChange={handleChange}
                                    width="90%"
                                />
                            </Grid>

                            <Grid item xs={6}>
                                <TextFields
                                    label="Last Name"
                                    id="LastName"
                                    multiline={false}
                                    rows={1}
                                    value={sideDrawData?.LastName}
                                    handleChange={handleChange}
                                    width="90%"
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextFields
                                    label="Job Title"
                                    id="JobTitle"
                                    multiline={false}
                                    rows={1}
                                    value={sideDrawData?.JobTitle}
                                    handleChange={handleChange}
                                    width="90%"
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextFields
                                    label="Email"
                                    id="Email"
                                    multiline={false}
                                    rows={1}
                                    value={sideDrawData?.Email}
                                    handleChange={handleChange}
                                    width="90%"
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextFields
                                    label="Business Phone"
                                    id="BusinessPhone"
                                    multiline={false}
                                    rows={1}
                                    value={sideDrawData?.BusinessPhone}
                                    handleChange={handleChange}
                                    width="90%"
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextFields
                                    label="Mobile Phone"
                                    id="MobilePhone"
                                    multiline={false}
                                    rows={1}
                                    value={sideDrawData?.MobilePhone}
                                    handleChange={handleChange}
                                    width="90%"
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextFields
                                    disable={true}
                                    label="Contact Id"
                                    id="ContactId"
                                    multiline={false}
                                    rows={1}
                                    value={sideDrawData?.ContactId}
                                    handleChange={handleChange}
                                    width="90%"
                                />
                            </Grid>
                            <Grid item xs={6}></Grid>
                            <Grid item xs={6}>
                                <Grid container>
                                    <Grid item xs={12}>
                                        Active
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormControlLabel
                                            style={{ color: "green" }}
                                            control={
                                                <MSMESwitch
                                                    checked={
                                                        sideDrawData?.isActive
                                                    }
                                                    onChange={handleChangeCheck}
                                                    name="isActive"
                                                />
                                            }
                                            label=""
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={6}>
                                <Grid container>
                                    <Grid item xs={12} className={"text-left"}>
                                        Make Payment
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormControlLabel
                                            style={{ color: "green" }}
                                            control={
                                                <MSMESwitch
                                                    checked={
                                                        sideDrawData?.MakePayment
                                                    }
                                                    onChange={handleChangeCheck}
                                                    name="MakePayment"
                                                />
                                            }
                                            label=""
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid item xs={6}>
                                <Grid container>
                                    <Grid item xs={12}>
                                        Order Supplies
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormControlLabel
                                            style={{ color: "green" }}
                                            control={
                                                <MSMESwitch
                                                    checked={
                                                        sideDrawData?.OrderSupplies
                                                    }
                                                    onChange={handleChangeCheck}
                                                    name="OrderSupplies"
                                                />
                                            }
                                            label=""
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={6}>
                                <Grid container>
                                    <Grid item xs={12} className={"text-left"}>
                                        Admin
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormControlLabel
                                            style={{ color: "green" }}
                                            control={
                                                <MSMESwitch
                                                    checked={
                                                        sideDrawData?.Admin
                                                    }
                                                    onChange={handleChangeCheck}
                                                    name="Admin"
                                                />
                                            }
                                            label=""
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            {errr.code === 400 ? (
                                <Grid
                                    item
                                    xs={12}
                                    style={{
                                        textAlign: "center",
                                        padding: "2%",
                                    }}
                                    className={
                                        // errr.code === 200
                                        //     ? "green-light-background"
                                        //     :
                                        "red-light-background"
                                    }
                                >
                                    <span
                                        className={`${
                                            // errr?.code === 200
                                            //     ? "Success_dot"
                                            //     :
                                            "Failed_dot"
                                        }`}
                                    ></span>{" "}
                                    <b>{errr.msg}</b>
                                </Grid>
                            ) : (
                                <></>
                            )}
                            <Grid item xs={12}>
                                <Divider />
                            </Grid>
                            <Grid item xs={6}></Grid>
                            <Grid item xs={3}>
                                <MUIButton
                                    label="Reset"
                                    onClick={() => {
                                        if (userReadOnlyAccess) {
                                            handleReset();
                                        }
                                    }}
                                    type="filled"
                                    font="fontMedium"
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <MUIButton
                                    label="Submit"
                                    onClick={() => {
                                        if (userReadOnlyAccess) {
                                            setSubmit(false);
                                        }
                                    }}
                                    type="filled"
                                    font="fontMedium"
                                />
                            </Grid>
                        </Grid>
                    ) : (
                        <Fragment>
                            {selectedBuildings.filter(
                                (building: any) => !building.deleted,
                            ).length == 0 ? (
                                <Grid
                                    container
                                    alignItems="center"
                                    justifyContent="center"
                                >
                                    <img
                                        style={{ paddingTop: "20vh" }}
                                        src={AddBuildings}
                                        onClick={()=>{
                                            if (userReadOnlyAccess){

                                                handleOpen()
                                            }
                                        }}
                                        className="pointer"
                                    />
                                </Grid>
                            ) : (
                                <Grid
                                    container
                                    alignItems="center"
                                    justifyContent="space-between"
                                    spacing={2}
                                >
                                    <Grid
                                        item
                                        xs={6}
                                        className="text-left font-bolder"
                                    >
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={selectedAll}
                                                    onChange={(event) => {
                                                        setSelectedAll(
                                                            event.target
                                                                .checked,
                                                        );
                                                        if (
                                                            event.target
                                                                .checked == true
                                                        )
                                                            setSelectedBuildings(
                                                                selectedBuildings.map(
                                                                    (
                                                                        building: any,
                                                                    ) => ({
                                                                        ...building,
                                                                        selected:
                                                                            true,
                                                                    }),
                                                                ),
                                                            );
                                                        else
                                                            setSelectedBuildings(
                                                                selectedBuildings.map(
                                                                    (
                                                                        building: any,
                                                                    ) => ({
                                                                        ...building,
                                                                        selected:
                                                                            false,
                                                                    }),
                                                                ),
                                                            );
                                                    }}
                                                    color="secondary"
                                                />
                                            }
                                            label={`${
                                                selectedBuildings.filter(
                                                    (building: any) =>
                                                        !building.deleted,
                                                ).length
                                            } Buildings`}
                                        />
                                    </Grid>
                                    {selectedAll ? (
                                        <Grid
                                            item
                                            xs={4}
                                            style={{ textAlign: "right" }}
                                        >
                                            <Button
                                                label={"Delete All"}
                                                onClick={() => { 
                                                    if (userReadOnlyAccess){

                                                        setPostBuidlingFlag(
                                                            "DELETE",
                                                        );
                                                        setPostBuidling(true);
                                                    }
                                                }}
                                                type="outlined_red"
                                                font="font-smaller"
                                            />
                                        </Grid>
                                    ) : (
                                        <Grid
                                            item
                                            xs={4}
                                            style={{ textAlign: "right" }}
                                        >
                                            <Button
                                                label={"+ Add Buildings"}
                                                onClick={()=>{
                                                    if (userReadOnlyAccess){

                                                        handleOpen()
                                                    }
                                                }}
                                                type="outlined"
                                                font="font-smaller"
                                            />
                                        </Grid>
                                    )}

                                    <Grid item xs={12}>
                                        <Grid
                                            container
                                            style={{
                                                overflow: "scroll",
                                                maxHeight: "63vh",
                                            }}
                                            spacing={2}
                                            // justifyContent="center"
                                            // alignItems="center"
                                        >
                                            {console.log(
                                                selectedBuildings,
                                                buildingData,
                                            )}
                                            {selectedBuildings.map(
                                                (
                                                    element: any,
                                                    index: number,
                                                ) => {
                                                    if (element.deleted != true)
                                                        return (
                                                            <Grid item xs={12}>
                                                                <AssociateBuildingCard
                                                                    buildingDetails={
                                                                        element
                                                                    }
                                                                    allBuildings={
                                                                        selectedBuildings
                                                                    }
                                                                    setAllBuildings={
                                                                        setSelectedBuildings
                                                                    }
                                                                    selectAll={
                                                                        selectAll
                                                                    }
                                                                    setSelectedBuildings={
                                                                        setBuildingData
                                                                    }
                                                                    callingFrom={
                                                                        "sidedraw"
                                                                    }
                                                                    index={
                                                                        index
                                                                    }
                                                                />
                                                            </Grid>
                                                        );
                                                },
                                            )}
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Grid
                                            container
                                            justifyContent="flex-end"
                                        >
                                            <ToastContainer
                                                position="top-center"
                                                autoClose={5000}
                                                limit={2}
                                                hideProgressBar={false}
                                                newestOnTop={false}
                                                closeOnClick
                                                rtl={false}
                                                pauseOnFocusLoss
                                                draggable
                                                pauseOnHover
                                                theme="colored"
                                            />
                                            <Grid item xs={2}>
                                                <Button
                                                    label={"Reset"}
                                                    onClick={() => {
                                                        if (userReadOnlyAccess){

                                                            setSelectedBuildings(
                                                                [],
                                                            );
                                                        }
                                                    }}
                                                    type="outlined"
                                                    font="font-smaller"
                                                />
                                            </Grid>
                                            <Grid item xs={2}>
                                                <Button
                                                    label={"Submit"}
                                                    onClick={() => {
                                                        if (userReadOnlyAccess){

                                                            setPostBuidlingFlag(
                                                                "SUBMIT",
                                                            );
                                                            setPostBuidling(true);
                                                        }
                                                    }}
                                                    type="filled"
                                                    font="font-smaller"
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            )}
                        </Fragment>
                    )}
                </Grid>
                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    className={classes.modal}
                    open={open}
                    onClose={handleClose}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                    }}
                >
                    <Fade in={open}>
                        <Grid container className={classes.paper} spacing={2}>
                            <ToastContainer
                                position="top-center"
                                autoClose={5000}
                                limit={2}
                                hideProgressBar={false}
                                newestOnTop={false}
                                closeOnClick
                                rtl={false}
                                pauseOnFocusLoss
                                draggable
                                pauseOnHover
                                theme="colored"
                            />
                            <Grid
                                item
                                xs={6}
                                className="font-bolder grey1 text-left"
                            >
                                Associated Buildings
                            </Grid>
                            <Grid
                                item
                                xs={6}
                                onClick={handleClose}
                                className="padding-3-percent text-right"
                            >
                                <Icons Icon={"closeGrey"} info={"default"} />
                            </Grid>
                            <Grid item xs={12}>
                                <Divider />
                            </Grid>
                            <Grid item xs={12}>
                                <Paper elevation={3}>
                                    <Grid
                                        container
                                        spacing={2}
                                        alignItems="center"
                                        justifyContent="flex-start"
                                    >
                                        <Grid item xs={3}>
                                            <Dropdown
                                                label={"Delivery Area"}
                                                id={"deliveryArea"}
                                                value={
                                                    searchValues.deliveryArea
                                                }
                                                list={comboValues.deliveryArea}
                                                handleChange={(event) => {
                                                    setSearchValues(
                                                        (prevState: any) => ({
                                                            ...prevState,
                                                            deliveryArea:
                                                                event.target
                                                                    .value,
                                                        }),
                                                    );
                                                }}
                                                width={"100%"}
                                            />
                                        </Grid>
                                        <Grid item xs={3}>
                                            <Dropdown
                                                label={"Regional Office"}
                                                id={"regionalOffice"}
                                                value={
                                                    searchValues.regionalOffice
                                                }
                                                list={
                                                    comboValues.regionalOffice
                                                }
                                                handleChange={(event) => {
                                                    setSearchValues(
                                                        (prevState: any) => ({
                                                            ...prevState,
                                                            regionalOffice:
                                                                event.target
                                                                    .value,
                                                        }),
                                                    );
                                                }}
                                                width={"100%"}
                                            />
                                        </Grid>
                                        <Grid item xs={3}>
                                            <Dropdown
                                                label={"State"}
                                                id={"state"}
                                                value={searchValues.state}
                                                list={comboValues.state}
                                                handleChange={(event) => {
                                                    setSearchValues(
                                                        (prevState: any) => ({
                                                            ...prevState,
                                                            state: event.target
                                                                .value,
                                                        }),
                                                    );
                                                }}
                                                width={"100%"}
                                            />
                                        </Grid>
                                        <Grid item xs={2}>
                                            <Button
                                                label={"Search"}
                                                onClick={() => {
                                                    setBuildingSearch(true);
                                                    setBuildingData([]);
                                                }}
                                                type="filled"
                                                font="font-smaller"
                                            />
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </Grid>
                            {buildingData?.length === 0 ? (
                                <Fragment>
                                    <Grid
                                        item
                                        xs={12}
                                        style={{ textAlign: "center" }}
                                    >
                                        <img
                                            src={SearchBuildings}
                                            style={{ width: "86vh" }}
                                        />
                                    </Grid>
                                </Fragment>
                            ) : (
                                <Fragment>
                                    <Grid
                                        item
                                        xs={12}
                                        className="text-left font-bolder"
                                    >
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={selectAll}
                                                    onChange={(event) => {
                                                        setSelectAll(
                                                            event.target
                                                                .checked,
                                                        );
                                                        if (
                                                            event.target.checked
                                                        )
                                                            setBuildingData(
                                                                buildingData.map(
                                                                    (
                                                                        building: any,
                                                                    ) => ({
                                                                        ...building,
                                                                        selected:
                                                                            true,
                                                                    }),
                                                                ),
                                                            );
                                                        else
                                                            setBuildingData(
                                                                buildingData.map(
                                                                    (
                                                                        building: any,
                                                                    ) => ({
                                                                        ...building,
                                                                        selected:
                                                                            false,
                                                                    }),
                                                                ),
                                                            );
                                                    }}
                                                    color="secondary"
                                                />
                                            }
                                            label={`${buildingData.length} Buildings Found`}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Grid
                                            container
                                            spacing={2}
                                            style={{
                                                overflow: "scroll",
                                                maxHeight: "40vh",
                                            }}
                                        >
                                            {buildingData?.map(
                                                (element: any) => {
                                                    return (
                                                        <Grid
                                                            item
                                                            xs={6}
                                                            md={4}
                                                            lg={4}
                                                        >
                                                            <AssociateBuildingCard
                                                                buildingDetails={
                                                                    element
                                                                }
                                                                allBuildings={
                                                                    buildingData
                                                                }
                                                                setAllBuildings={
                                                                    setBuildingData
                                                                }
                                                                selectAll={
                                                                    selectAll
                                                                }
                                                                callingFrom={
                                                                    "model"
                                                                }
                                                            />
                                                        </Grid>
                                                    );
                                                },
                                            )}
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Grid
                                            container
                                            justifyContent="flex-end"
                                        >
                                            <Grid item xs={2}>
                                                <Button
                                                    label={"Reset"}
                                                    onClick={
                                                        handleResetBuilding
                                                    }
                                                    type="outlined"
                                                    font="font-smaller"
                                                />
                                            </Grid>
                                            <Grid item xs={2}>
                                                <Button
                                                    label={"Add"}
                                                    onClick={() => {
                                                        console.log(
                                                            getSelectedBuildings(
                                                                buildingData,
                                                            ).length,
                                                        );
                                                        if (
                                                            getSelectedBuildings(
                                                                buildingData,
                                                            ).length !== 0
                                                        ) {
                                                            setOpen(false);
                                                            setShowBuildings(
                                                                true,
                                                            );
                                                            setSelectedBuildings(
                                                                getSelectedBuildings(
                                                                    buildingData,
                                                                ),
                                                            );
                                                        } else {
                                                            toast.warn(
                                                                "To Associate, please pick at least one building.",
                                                                {
                                                                    position:
                                                                        "top-center",
                                                                    autoClose: 3000,
                                                                    hideProgressBar:
                                                                        false,
                                                                    closeOnClick:
                                                                        true,
                                                                    pauseOnHover:
                                                                        true,
                                                                    draggable:
                                                                        true,
                                                                    progress:
                                                                        undefined,
                                                                    theme: "colored",
                                                                },
                                                            );
                                                        }
                                                    }}
                                                    type="filled"
                                                    font="font-smaller"
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Fragment>
                            )}
                        </Grid>
                    </Fade>
                </Modal>
            </Grid>
        </>
    );
};

const mapStateToProps = (state: any) => {
    return {
        currentContext: state.context?.userDefaults,
        userName: state.context?.name,
        selectedCustomer: state.selectedCustomer,
        portalKey: state.portalKey,
        selectedVendor: state.selectedVendor,
        supplierCode: state?.selectedVendor?.supplierCode,
        loginUser: state?.selectedVendor?.loginUser,
        userReadOnlyAccess: state?.userReadOnlyAccess,
    };
};

export default connect(mapStateToProps)(CompanySideDrawChild);
