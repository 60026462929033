import React from "react";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Icons from "Common/Icons/Icons";
import "./ColoredCard.scss";

interface ColoredCardProps {
    desc: string;
    highlight: string;
    color: string;
    border: string;
    Icon: string;
}

const ColoredCard = (props: ColoredCardProps) => {
    const { desc, highlight, color, border, Icon } = props;
    return (
        <Paper variant="outlined" className={border + " notifPadding"}>
            <Grid container spacing={3} alignItems="center">
                <Grid item xs={2} className={color + " boldest"}>
                    {desc}
                </Grid>
                <Grid item xs={10} className={color} justifyContent="flex-end">
                    <Grid
                        container
                        spacing={0}
                        alignItems="center"
                        className="fonts"
                    >
                        <Grid
                            item
                            xs={1}
                            className={color + " textRight"}
                        ></Grid>
                        <Grid
                            item
                            xs={1}
                            className={color + " textRight"}
                            style={{ paddingTop: "0.5%" }}
                        >
                            <Icons Icon={Icon} info={""} />
                        </Grid>
                        <Grid item xs={10} className={color}>
                            <span className={"default textAlignment"}>
                                {highlight}
                            </span>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Paper>
    );
};

export default ColoredCard;
