const QADetVisitQueryCUS = `

mutation(    
    $entity: String
    $ou: Int
    $service: String
    $User: String
    $languageId: Int
   
    $merrorid: String
    $vendorname: String,
    $vendorcode:String,){

    QADetVisitQuery(   
        entity: $entity
        ou: $ou
        service: $service
        User: $User
        languageId: $languageId
       
        merrorid:$merrorid
        vendorname:$vendorname
        vendorcode:$vendorcode
        ){
        Customer
        Building
        VisitedBy
        VisitedOn
        InspectionOrder
        Note
    }
}`;

export default QADetVisitQueryCUS;

