const AssociatedBuildingPostQuery = `
mutation ($entityList: [entityList], $contextUser: String) 
{
    AssociatedBuildingPost(entityList:$entityList,contextUser:$contextUser) 
  {
    responseMessage
  }
}
  `;
export default AssociatedBuildingPostQuery;
