import {
    Grid,
    Paper,
    InputBase,
    Modal,
    Box,
    Typography,
    Divider,
    Icon,
    withStyles,
    Theme,
    Tooltip,
} from "@material-ui/core";
import React, { Fragment, useEffect, useState } from "react";
import Icons from "Common/Icons/Icons";
import MUIButton from "Common/Button/Button";
import TextField from "Common/TextField/TextField";
import { connect } from "react-redux";
import useGetPosts from "ReactQuery/reactQuery";
import MuiDatatables from "Common/Mui-DataTables/MuiDatatables";
import { Redirect } from "react-router-dom";
import Dropdown from "Common/Dropdown/Dropdown";
import TextFields from "Common/TextField/TextField";
import CustomDatePickerBorder from "Common/DatePicker/DatePicker";
import {
    BuildDetQuery,
    TicketDataCUS,
    TicketContactFolDateCUS,
    TicketEngagementDetail,
    // TicketDetailsCUS,
    TicketTableCUS,
    TicketEngagements,
} from "ReactQuery/Query/index";
import { useLocation } from "react-router";
import TicketHeaderCUS from "ReactQuery/Query/TicketHeaderQueryCUS";
import Button from "Common/Button/Button";
import CreateTicketsCUS from "./CreateTicketsCUS";
import SwipeableDrawerComp from "Common/SideDrawer/SideDrawer";
import AddNotes from "./AddNotes";
import { Alert } from "@material-ui/lab";
import Alerts from "Common/Alerts/Alerts";
import Configuration from "Configuration";
import BackdropLoading from "Common/Backdrop/Backdrop";
import NoTicketActivity from "./Images/No_Ticket_Activity.svg";
import { storeBreadcrumbs } from "stateManagement/actions/Common/breadcrumbs";
interface DashCards {
    desc: string;
    value: string;
    visible: boolean;
    frequent: boolean;
    detail: {
        desc: string;
        value: number;
        color: string;
    }[];
}
interface TicketDetailsProps {
    IssueID: string;
    BuildingName: {
        name: string;
        id: string;
    };
    AddressDet: {
        name: string;
        city: string;
        state: string;
        pincode: string;
    };
    status: string;
    process: string;
    priority: string;
    Vendor: string;
    DateofIssue: string;
    AccountManager: string;
    FollowUpDate: string;
    Contact: string;
    TicketType: string;
    CustomerSupportSpecialist: string;
    Subject: string;
    Orgin: string;
}
const controlList = [
    {
        desc: "General",
        value: "General",
    },
    {
        desc: "Assignment/Refusals/Fulfillment",
        value: "Assignment/Refusals/Fulfillment",
    },
    {
        desc: "Amortization",
        value: "Amortization",
    },
];

const HtmlTooltip = withStyles((theme: Theme) => ({
    tooltip: {
        backgroundColor: "white",
        color: "rgba(0, 0, 0, 0.87)",
        fontSize: 11,
        boxShadow: " 0px 8px 20px rgba(0, 0, 0, 0.15)",
        // border: "1px solid grey",
    },
}))(Tooltip);


const data:any []= [
  
    {
        "ActivityType": "INCOMING_EMAIL",
        "Subject": "Re: C1000778 SO126 - VZ4GD",
        "Description": "Re: C1000778 SO126 - VZ4GD",
        "Date": "05/01/2024, 7:11 AM",
        "OWRep": ""
    },
   
    {
        "ActivityType": "CALL",
        "Subject": "Call with Ana Alvizo",
        "Description": "Answered: A 1121 second Incoming call. A recording of the call is <a href='https://calls.kixie.com/be23601f-6404-4a8e-b28a-19bb9255e346.wav'>here</a>. The call was made from +16506002878 to +18886799383",
        "Date": "05/22/2024, 10:52 PM",
        "OWRep": null
    },
    {
        "ActivityType": "INCOMING_EMAIL",
        "Subject": "Re: Open Works / SPR1000032",
        "Description": "Re: Open Works / SPR1000032",
        "Date": "05/22/2024, 10:46 PM",
        "OWRep": null
    },
    {
        "ActivityType": "NOTE",
        "Subject": null,
        "Description": "Incoming Call: Connected<br>reached out about her pay for two tags that she did.<br> Disposition: Connected",
        "Date": "05/22/2024, 10:59 PM",
        "OWRep": null
    }
   
]


const TicketDetails = (props: any) => {
    const {
        currentContext,
        userName,
        customerData,
        portalKey,
        setBreadcrumbsData,
    } = props;
    setBreadcrumbsData([
        {
            value: "Tickets",
            href: "/app/*/Tickets",
        },
        {
            value: "Ticket Details",
            href: "/app/*/TICKDET",
        },
    ]);
    const ticketData = useLocation();
    const stateTicket: any = (ticketData as any)?.state?.contractSelected;
    console.log("Selected Ticket Details", (ticketData as any)?.state);
    console.log("Ticket in the State", JSON.stringify(stateTicket));
    const [header, setHeader] = useState<DashCards>();
    const [TicketDet, setTicketDet] = useState<boolean>(false);
    const [open, setOpen] = useState(false);
    const [openModal, setopenModal] = useState(false);
    const handleClose = () => setopenModal(false);
    const [active, setActive] = useState<string>();
    const [showTable, setShowTable] = useState<boolean>(false);
    const [TicketsTableList, setTicketsTableList] = useState([]);
    const [ticketHubspotData, setTicketHubspotData] = useState<any>({});
    const [buildingRamcoData, setBuildingRamcoData] = useState<any>({});
    const [alert, setAlert] = useState(false);
    const [errr, seterr] = useState({
        code: 0,
        msg: "",
    });
    const [ticketContactHubspotData, setTicketContactHubspotData] = useState<
        any
    >({});
    const [ticketsDetailsList, setTicketsDetailsList] = useState({});
    useEffect(() => {
        setTicketsDetailsList(stateTicket);
    }, [stateTicket]);
    const handleContractClick = (val: any) => {
        setTicketDet(true);
    };
    const handleClick = () => {
        setOpen((prev) => !prev);
    };
    const ModalStyle = {
        position: "absolute" as "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: 600,
        bgcolor: "background.paper",
        borderRadius: "2%",
        p: 4,
    };

    const {
        data: BuildDetData,
        error: BuildDetError,
        isLoading: BuildDetLoading,
    } = useGetPosts({
        name: `BuildDetQuery_${(ticketsDetailsList as any)?.BuildingName?.id}`,
        query: BuildDetQuery,
        variables: {
            entity: "OW_Cust_portal_BuildingHdr",
            ou: currentContext?.ouId,
            service: "hubspotid",
            User: userName,
            languageId: currentContext?.langId,
            customerCode: customerData?.customerCode,
            customerName: customerData?.customerName,
            siteId: (ticketsDetailsList as any)?.BuildingName?.id,
            merrorid: "",
        },
        skip: (ticketsDetailsList as any)?.BuildingName?.id ? false : true,
    });
    useEffect(() => {
        if (BuildDetData?.BuildDetQuery) {
            setBuildingRamcoData(BuildDetData?.BuildDetQuery[0]);
        }
    }, [BuildDetData]);
    useEffect(() => {
        if (BuildDetError && !BuildDetLoading) {
            const data = JSON.parse(JSON.stringify(BuildDetError));
            const errmsg = data?.response?.errors[0].message;
            seterr({
                code: 400,
                msg: errmsg,
            });
            setAlert(true);
        }
    }, [BuildDetError]);

    const {
        data: TicketContactFolDateData,
        error: TicketContactFolDateError,
        isLoading: TicketContactFolDateLoading,
    } = useGetPosts({
        name: `TicketContactFolDate_${
            !portalKey
                ? Configuration.getConfiguration().getCustomerPortalPipeline()
                : Configuration.getConfiguration().getVendorPortalPipeline()
        }_${(ticketsDetailsList as any)?.IssueID}`,
        query: TicketContactFolDateCUS,
        variables: {
            pipeline: !portalKey
                ? Configuration.getConfiguration().getCustomerPortalPipeline()
                : Configuration.getConfiguration().getVendorPortalPipeline(),
            ticketNumber: (ticketsDetailsList as any)?.IssueID,
        },
        skip: (ticketsDetailsList as any)?.IssueID ? false : true,
    });

    const [ticketEngagementList, setTicketEngagementList] = useState([]);
    const {
        data: TicketEngagementData,
        error: TicketEngagementError,
        isLoading: TicketEngagementLoading,
    } = useGetPosts({
        name: `TicketEngagements${
            !portalKey
                ? Configuration.getConfiguration().getCustomerPortalPipeline()
                : Configuration.getConfiguration().getVendorPortalPipeline()
        }_${(ticketsDetailsList as any)?.IssueID}`,
        query: TicketEngagements,
        variables: {
            ticketId: (ticketsDetailsList as any)?.IssueID, //"764764112" //,
        },
        skip: (ticketsDetailsList as any)?.IssueID ? false : true,
    });

    useEffect(() => {
        if (TicketEngagementData?.TicketEngagements) {
            console.log(TicketEngagementData?.TicketEngagements);
            setTicketEngagementList(TicketEngagementData?.TicketEngagements);
        }
    }, [TicketEngagementData]);

    const {
        data: TicketEngagementDetailData,
        error: TicketEngagementDetailError,
        isLoading: TicketEngagementDetailLoading,
    } = useGetPosts({
        name: `TicketEngagementDetail${
            !portalKey
                ? Configuration.getConfiguration().getCustomerPortalPipeline()
                : Configuration.getConfiguration().getVendorPortalPipeline()
        }_${(ticketsDetailsList as any)?.IssueID}`,
        query: TicketEngagementDetail,
        variables: {
            engagementId: ticketEngagementList.map(
                (element: any) => element?.id,
            ), //["12869093065", "12869093065"], //(ticketsDetailsList as any)?.IssueID,
        },
        skip: ticketEngagementList?.length ? false : true,
    });

    useEffect(() => {
        if (TicketEngagementDetailData?.TicketEngagementDetail  || true) {
            console.log(
                "TicketEngagementDetailData",
                TicketEngagementDetailData,
            );
            const filteredData:any =  TicketEngagementDetailData?.TicketEngagementDetail?.filter((details:any)=>details.ActivityType !='NOTE') ?? [];
            // setTicketsTableList(
            //     TicketEngagementDetailData?.TicketEngagementDetail,
            // );
            setTicketsTableList(
                filteredData
            );
        }
    }, [TicketEngagementDetailData]);

    useEffect(() => {
        if (TicketContactFolDateData?.TicketContactFolDateCUS) {
            setTicketContactHubspotData(
                TicketContactFolDateData?.TicketContactFolDateCUS,
            );
        }
    }, [TicketContactFolDateData]);

    useEffect(() => {
        if (TicketContactFolDateError && !TicketContactFolDateLoading) {
            const data = JSON.parse(JSON.stringify(TicketContactFolDateError));
            const errmsg = data?.response?.errors[0].message;
            seterr({
                code: 400,
                msg: errmsg,
            });
            setAlert(true);
        }
    }, [TicketContactFolDateError]);

    const {
        data: TicketHeaderData,
        error: ticketDataError,
        isLoading: ticketHeaderLoading,
    } = useGetPosts({
        name: `ContractsQuery_get_data_${active}_${
            !portalKey
                ? Configuration.getConfiguration().getCustomerPortalPipeline()
                : Configuration.getConfiguration().getVendorPortalPipeline()
        }_${customerData?.hubspotCustomerId}_${
            (ticketsDetailsList as any)?.IssueID
        }`,
        query: TicketDataCUS,
        variables: {
            companyId: customerData?.hubspotCustomerId,
            pipelineId: !portalKey
                ? Configuration.getConfiguration().getCustomerPortalPipeline()
                : Configuration.getConfiguration().getVendorPortalPipeline(),
            customerCode: customerData?.customerCode,
            ou: currentContext?.ouId,
            service: "",
            user: customerData?.loginUser,
            langId: currentContext?.langId,
            role: currentContext?.roleName,
            status: active?.toLowerCase(),
            ticketNumber: (ticketsDetailsList as any)?.IssueID,
        },
        skip: (ticketsDetailsList as any)?.IssueID ? false : true,
    });

    useEffect(() => {
        if (TicketHeaderData?.TicketDataCUS) {
            setHeader(TicketHeaderData?.TicketDataCUS);
            setTicketHubspotData(TicketHeaderData?.TicketDataCUS[0]);
            console.log(
                "TicketHeaderData?.TicketHeaderCUS[0]",
                TicketHeaderData?.TicketDataCUS[0],
            );
        }
    }, [TicketHeaderData]);

    useEffect(() => {
        if (ticketDataError && !ticketHeaderLoading) {
            const data = JSON.parse(JSON.stringify(ticketDataError));
            const errmsg = data?.response?.errors[0].message;
            seterr({
                code: 400,
                msg: errmsg,
            });
            setAlert(true);
        }
    }, [ticketDataError]);

    const TicketScheme = [
        {
            name: "ActivityType",
            Label: "ActivityType",
            type: "string",
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => (
                    <span style={{ color: "white" }}>Activity Type</span>
                ),
                setCellHeaderProps: () => {
                    return { style: { borderRight: "1px solid #D5D5D5" } };
                },
                setCellProps: () => {
                    return {
                        style: {
                            borderRight: "1px solid #D5D5D5",
                            textAlign: "center",
                            // width: "10%",
                        },
                    };
                },
                customBodyRender: (
                    value: any,
                    tableMeta: any,
                    updateValue: any,
                ) => {
                    console.log("table data", value);
                    return (
                        <b
                            style={{ color: "#5C677D" }}
                            onClick={() =>
                                handleContractClick(tableMeta.rowData)
                            }
                        >
                            {value}
                        </b>
                    );
                },
            },
        },
        {
            name: "Subject",
            Label: "Subject",
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => (
                    <span style={{ color: "white" }}>Subject</span>
                ),
                setCellHeaderProps: () => {
                    return { style: { borderRight: "1px solid #D5D5D5" } };
                },
                setCellProps: () => {
                    return {
                        style: {
                            borderRight: "1px solid #D5D5D5",
                            textAlign: "center",
                        },
                    };
                },
                customBodyRender: (
                    value: any,
                    tableMeta: any,
                    updateValue: any,
                ) => {
                    console.log("table data", value);
                    return (
                        <b
                            style={{ color: "#5C677D" }}
                            onClick={() =>
                                handleContractClick(tableMeta.rowData)
                            }
                        >
                            {value}
                        </b>
                    );
                },
            },
        },
        {
            name: "Description",
            Label: "Description",
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => (
                    <span style={{ color: "white" }}>Description </span>
                ),
                setCellHeaderProps: () => {
                    return { style: { borderRight: "1px solid #D5D5D5" } };
                },
                setCellProps: () => {
                    return {
                        style: {
                            borderRight: "1px solid #D5D5D5",
                            textAlign: "left",
                            marginLeft: "12%",
                        },
                    };
                },
                customBodyRender: (
                    value: any,
                    tableMeta: any,
                    updateValue: any,
                ) => {
                    if (value) {
                        if (value?.length <= 305)
                            return (
                                <div
                                    className="padding-2-percent"
                                    dangerouslySetInnerHTML={{ __html: value }}
                                />
                            );
                        else
                            return (
                                <HtmlTooltip
                                    title={
                                        <div
                                            className="padding-2-percent"
                                            dangerouslySetInnerHTML={{
                                                __html: value,
                                            }}
                                        />
                                    }
                                >
                                    <div
                                        className="padding-2-percent grey1 pointer"
                                        dangerouslySetInnerHTML={{
                                            __html:
                                                value?.slice(0, 150) +
                                                " ..... see more",
                                        }}
                                    />
                                </HtmlTooltip>
                            );
                    }
                },
            },
        },
        {
            name: "Date",
            Label: "Date",
            type: "string",
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => (
                    <span style={{ color: "white" }}> Date</span>
                ),
                setCellHeaderProps: () => {
                    return { style: { borderRight: "1px solid #D5D5D5" } };
                },
                setCellProps: () => {
                    return {
                        style: {
                            borderRight: "1px solid #D5D5D5",
                            textAlign: "center",
                            // width: "10%",
                        },
                    };
                },
                customBodyRender: (
                    value: any,
                    tableMeta: any,
                    updateValue: any,
                ) => {
                    console.log("table data", value);
                    return <b style={{ color: "#5C677D" }}>{value}</b>;
                },
            },
        },
        {
            name: "OWRep",
            Label: "OWRep",
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => (
                    <span style={{ color: "white" }}>OW Rep </span>
                ),
                setCellHeaderProps: () => {
                    return { style: { borderRight: "1px solid #D5D5D5" } };
                },
                setCellProps: () => {
                    return {
                        style: {
                            borderRight: "1px solid #D5D5D5",
                            textAlign: "left",
                        },
                    };
                },
                customBodyRender: (
                    value: any,
                    tableMeta: any,
                    updateValue: any,
                ) => {
                    console.log("table data", value);
                    return <b style={{ color: "#5C677D" }}>{value}</b>;
                },
            },
        },
    ];

    const handleModal = () => {
        setopenModal(true);
    };

    const handleErrorAlert = (code: number, message: string) => {
        Alerts({
            statusCode: code,
            message: message,
            willClose: () => {
                setAlert(false);
                seterr({ code: 0, msg: "" });
            },
        });
    };

    return (
        <>
            {alert ? handleErrorAlert(errr.code, errr.msg) : <></>}
            <BackdropLoading
                openStates={
                    BuildDetLoading ||
                    TicketContactFolDateLoading ||
                    ticketHeaderLoading
                }
            />
            <Grid
                container
                spacing={3}
                style={{ textAlign: "left", paddingTop: "2%" }}
                alignItems="center"
            >
                <Grid item xs={12}>
                    <Paper elevation={4}>
                        <Grid
                            container
                            spacing={2}
                            alignItems="center"
                            justifyContent="center"
                        >
                            <Grid
                                item
                                xs={4}
                                style={{
                                    paddingLeft: "2%",
                                    borderRight: "1px dashed #C9CDD6",
                                }}
                            >
                                <Grid
                                    container
                                    alignItems="center"
                                    justifyContent="center"
                                    spacing={1}
                                    style={{ padding: "2%" }}
                                >
                                    <Grid item xs={1}>
                                        <Icons Icon="ticket" />
                                    </Grid>
                                    <Grid item xs={5}>
                                        {ticketHubspotData?.IssueID}
                                    </Grid>
                                    <Grid item xs={1}>
                                        <Icons Icon="BuildPic" />
                                    </Grid>
                                    <Grid item xs={5}>
                                        {`${
                                            ticketHubspotData?.BuildingName?.id
                                        } - ${
                                            ticketHubspotData?.BuildingName
                                                ?.name
                                                ? ticketHubspotData
                                                      ?.BuildingName?.name
                                                : ""
                                        }`}
                                    </Grid>
                                    {buildingRamcoData?.Address?.Area ? (
                                        <Fragment>
                                            <Grid item xs={1}>
                                                <Icons Icon="location" />
                                            </Grid>
                                            <Grid item xs={11}>
                                                {`${
                                                    buildingRamcoData?.Address
                                                        ?.Area
                                                } | ${
                                                    buildingRamcoData?.Address
                                                        ?.City
                                                        ? buildingRamcoData
                                                              ?.Address?.City
                                                        : ""
                                                } | ${
                                                    buildingRamcoData?.Address
                                                        ?.State
                                                        ? buildingRamcoData
                                                              ?.Address?.State
                                                        : ""
                                                } | ${
                                                    buildingRamcoData?.Address
                                                        ?.Pincode
                                                        ? buildingRamcoData
                                                              ?.Address?.Pincode
                                                        : ""
                                                }`}
                                            </Grid>
                                        </Fragment>
                                    ) : (
                                        <Grid item xs={12}></Grid>
                                    )}

                                    <Grid
                                        item
                                        xs={2}
                                        style={
                                            ticketHubspotData?.Stage?.color ===
                                            "success"
                                                ? {
                                                      backgroundColor:
                                                          "#EDFDF2",
                                                      padding: "3%",
                                                      textAlign: "center",
                                                      border:
                                                          "1px solid #00DA64",
                                                      fontWeight: "bolder",
                                                      color: "#33415C",
                                                      borderRadius: "3px",
                                                  }
                                                : {
                                                      backgroundColor:
                                                          "#FF6D6D0D",
                                                      padding: "3%",
                                                      textAlign: "center",
                                                      border:
                                                          "1px solid #00DA64",
                                                      fontWeight: "bolder",
                                                      color: "#33415C",
                                                      borderRadius: "3px",
                                                  }
                                        }
                                    >
                                        {ticketHubspotData?.Stage?.desc}
                                    </Grid>
                                    <Grid
                                        item
                                        xs={5}
                                        style={
                                            ticketHubspotData?.Status?.color ===
                                            "green"
                                                ? {
                                                      backgroundColor:
                                                          "#F5FAFF",
                                                      padding: "3%",
                                                      textAlign: "center",
                                                      border:
                                                          "1px solid #0073E6",
                                                      fontWeight: "bolder",
                                                      color: "#33415C",
                                                      margin: "9px",
                                                      borderRadius: "3px",
                                                  }
                                                : {
                                                      backgroundColor:
                                                          "#FF6D6D0D",
                                                      padding: "3%",
                                                      textAlign: "center",
                                                      border:
                                                          "1px solid #00DA64",
                                                      fontWeight: "bolder",
                                                      color: "#33415C",
                                                      margin: "9px",
                                                      borderRadius: "3px",
                                                  }
                                        }
                                    >
                                        {ticketHubspotData?.Status?.desc}
                                    </Grid>
                                    <Grid
                                        item
                                        xs={1}
                                        style={{
                                            paddingTop: "1%",
                                            textAlign: "center",
                                        }}
                                    >
                                        <Icons Icon="priority1" />
                                    </Grid>
                                    <Grid
                                        item
                                        xs={1}
                                        className="font-bolder red font-big"
                                    >
                                        {ticketHubspotData?.priority}
                                    </Grid>
                                    <Grid
                                        item
                                        xs={2}
                                        className="text-right"
                                        onClick={handleModal}
                                    >
                                        <Icons Icon="description" />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={8} style={{ padding: "2%" }}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={2}>
                                                <Grid container>
                                                    <Grid
                                                        item
                                                        xs={12}
                                                        className="grey"
                                                    >
                                                        Account Manager
                                                    </Grid>
                                                    <Grid
                                                        item
                                                        xs={12}
                                                        className="grey2 font-boldest"
                                                    >
                                                        {
                                                            buildingRamcoData?.AccountManager1
                                                        }
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={3}>
                                                <Grid container>
                                                    <Grid
                                                        item
                                                        xs={12}
                                                        className="grey"
                                                    >
                                                        Contact
                                                    </Grid>
                                                    <Grid
                                                        item
                                                        xs={12}
                                                        className="grey2 font-boldest"
                                                    >
                                                        {
                                                            ticketContactHubspotData?.contactName
                                                        }
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={3}>
                                                <Grid container>
                                                    <Grid
                                                        item
                                                        xs={12}
                                                        className="grey"
                                                    >
                                                        Customer Support
                                                        Specialist
                                                    </Grid>
                                                    <Grid
                                                        item
                                                        xs={12}
                                                        className="grey2 font-boldest"
                                                    >
                                                        {
                                                            ticketHubspotData?.Owner
                                                        }
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={1}>
                                                <Grid container>
                                                    <Grid
                                                        item
                                                        xs={12}
                                                        className="grey"
                                                    >
                                                        Orgin
                                                    </Grid>
                                                    <Grid
                                                        item
                                                        xs={12}
                                                        className="grey2 font-boldest"
                                                    >
                                                        {
                                                            ticketHubspotData?.Source
                                                        }
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={2}>
                                                <Grid container>
                                                    <Grid
                                                        item
                                                        xs={12}
                                                        className="grey"
                                                    >
                                                        Date of Issue
                                                    </Grid>
                                                    <Grid
                                                        item
                                                        xs={12}
                                                        className="grey2 font-boldest"
                                                    >
                                                        {
                                                            ticketHubspotData?.CreatedOn
                                                        }
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={3}>
                                                <Grid container>
                                                    <Grid
                                                        item
                                                        xs={12}
                                                        className="grey"
                                                    >
                                                        Follow up date
                                                    </Grid>
                                                    <Grid
                                                        item
                                                        xs={12}
                                                        className="grey2 font-boldest"
                                                    >
                                                        {
                                                            ticketContactHubspotData?.followUpDate
                                                        }
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={3}>
                                                <Grid container>
                                                    <Grid
                                                        item
                                                        xs={12}
                                                        className="grey"
                                                    >
                                                        Ticket Type
                                                    </Grid>
                                                    <Grid
                                                        item
                                                        xs={12}
                                                        className="grey2 font-boldest"
                                                    >
                                                        {
                                                            ticketHubspotData?.IssueType
                                                        }
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={4}>
                                                <Grid container>
                                                    <Grid
                                                        item
                                                        xs={12}
                                                        className="grey"
                                                    >
                                                        Subject
                                                    </Grid>
                                                    <Grid
                                                        item
                                                        xs={12}
                                                        className="grey2 font-boldest"
                                                    >
                                                        {
                                                            ticketHubspotData?.Subject
                                                        }
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            {/* <Grid item xs={1}>
                                            <Grid container>
                                                <Grid item xs={12}></Grid>
                                                <Grid
                                                    item
                                                    xs={12}
                                                    style={{
                                                        paddingTop: "60%",
                                                    }}
                                                >
                                                    
                                                    <Icons Icon="editPic"></Icons>
                                                </Grid>
                                            </Grid>
                                        </Grid> */}
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            {TicketsTableList?.length ? (
                                <MuiDatatables
                                    title={""}
                                    header={TicketScheme}
                                    detail={[
                                        TicketsTableList,
                                        setTicketsTableList,
                                    ]}
                                    initRow={false}
                                    hideAddButton={true}
                                    tableHeight={"320px"}
                                />
                            ) : (
                                <img
                                    src={NoTicketActivity}
                                    style={{ paddingTop: "4%" }}
                                />
                            )}
                        </Grid>
                        <Grid item xs={3}>
                            <Grid container>
                                <Grid item xs={6}>
                                    {/* <MUIButton
                                            label={"+Add Note"}
                                            onClick={handleClick}
                                            type="outlined"
                                            font="font-smaller"
                                        /> */}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    {/* </Grid> */}
                    {/* </Grid> */}
                </Grid>
                <SwipeableDrawerComp
                    drawerState={[open, setOpen]}
                    header={"Add Notes"}
                    headerColor={"grey2"}
                    Child={<AddNotes />}
                />
                <Modal
                    open={openModal}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={ModalStyle}>
                        <Grid container>
                            <Grid item xs={10}>
                                <Typography
                                    id="modal-modal-title"
                                    variant="h6"
                                    component="h2"
                                    style={{ fontWeight: 700 }}
                                >
                                    Description
                                </Typography>
                            </Grid>
                            <Grid
                                item
                                xs={1}
                                onClick={handleClose}
                                style={{
                                    textAlign: "right",
                                    paddingRight: "1%",
                                }}
                                className="pointer"
                            >
                                <Icons Icon={"closeGrey"} info={"default"} />
                            </Grid>
                        </Grid>
                        <Typography
                            id="modal-modal-description"
                            style={{ padding: "5%" }}
                        >
                            {ticketHubspotData?.content}
                        </Typography>
                    </Box>
                </Modal>
            </Grid>
        </>
    );
};

const mapStateToProps = (state: any) => {
    return {
        currentContext: state.context?.userDefaults,
        userName: state.context.name,
        customerData: state?.portalKey
            ? state.selectedVendor
            : state?.selectedCustomer,
        portalKey: state?.portalKey,
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        setBreadcrumbsData: (data: any) => {
            dispatch(storeBreadcrumbs(data));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(TicketDetails);
