import { STORE_CONTEXT_INFO } from "../actionTypes";

const initialState = {
  sessionId: "",
  context: {},
};

const storeContext = (prevState = initialState.context, action: any) => {
  switch (action.type) {
    case STORE_CONTEXT_INFO:
      return action.payload;

    default:
      return prevState;
  }
};

export default storeContext;
