const InvoiceDetailsChildTableDataCusQuery = `
    mutation(
        $entity: String
        $ou: Int
        $service: String
        $User: String
        $languageId: Int
        $Status:String
        $customerCode: String
        $customerName: String
        $siteId: String
        $buildName: String
        $InvoiceNo: String
        $merrorid: String
        $contractid:String
    ){
        InvoiceChildTableDataCus(
            entity: $entity
            ou: $ou
            service: $service
            User: $User
            languageId: $languageId
            Status:$Status
            customerCode: $customerCode
            customerName: $customerName
            siteId: $siteId
            buildName: $buildName
            InvoiceNo: $InvoiceNo
            contractid:$contractid
            merrorid: $merrorid
        ){
            AssignmnetNumber
                    CustomerContract
                    State
                    BuildingDetails
                    StartEndDate
                    ServiceCode{
                        name
                        service
                    }
                    AmountQty{
                        amount
                        quantity
                        cost
                    }
        }
    }
`;

export default InvoiceDetailsChildTableDataCusQuery;