import React, { useEffect } from "react";
import axios from "axios";
import { connect } from "react-redux";
import { storeContextInfo } from "stateManagement/actions/HomePage/storeContext";
import { storeSessionId } from "stateManagement/actions/LoginPage/storeSessionId";
import { storeDefaultContext } from "stateManagement/actions/SwitchContext/switchContext";
import Configuration from "Configuration";
import loadGif from "Common/Images/Loading.gif";
import {
    Backdrop,
    CircularProgress,
    makeStyles,
    Theme,
} from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: "#fff",
    },
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
    },
}));

function LogoutPage(props: any) {
    const { session, storeSessionId, storeContextInfo } = props;
    const [logout, setLogout] = React.useState(false);
    const classes = useStyles();
    useEffect(() => {
        axios(
            `${Configuration.getConfiguration().getAppAPILocation()}/api/auth/logout?sId=${session}`,
        )
            .then(function (response: any) {
                setLogout(true);
                storeSessionId("");
                storeContextInfo("");
                storeDefaultContext("");
                console.log(response);
                localStorage.removeItem("state");
                window.location.assign(response.data.LogoutRvw);
            })
            .catch(function (error) {
                console.error(error);
            });
    }, []);

    return (
        <Backdrop
            style={{
                zIndex: 1,
                color: "#fff",
            }}
            open={true}
        >
            <CircularProgress color="inherit" />
        </Backdrop>
    );
}

const mapStateToProps = (state: any) => {
    return {
        session: state.sessionId,
    };
};
const mapDispatchToProps = (dispatch: any) => {
    return {
        storeSessionId: (context: never) => {
            dispatch(storeSessionId(context));
        },
        storeContextInfo: (context: never) => {
            dispatch(storeContextInfo(context));
        },
        setDefaultContext: (data: never) => {
            dispatch(storeDefaultContext(data));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(LogoutPage);
