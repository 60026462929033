const BuildingComboCUS = `
 query Query(
    $ou: Int
    $service: String
    $user: String
    $langId: Int
    $role: String
    $customerCode: String
    $buildingId: String
 ){
    BuildingComboCUS(ou: $ou
        service: $service
        user: $user
        langId: $langId
        role: $role
        customerCode: $customerCode
        buildingId: $buildingId ){
        desc
        value
        hubspotId
        phoneNo
    }
}`;

export default BuildingComboCUS;
