import { Grid, Paper, Backdrop, CircularProgress } from "@material-ui/core";
import { makeStyles, Theme } from "@material-ui/core/styles";
import React, { useEffect, useState } from "react";
import MUIButton from "Common/Button/Button";
import { connect } from "react-redux";
import useGetPosts from "ReactQuery/reactQuery";
import MuiDatatables from "Common/Mui-DataTables/MuiDatatables";
import { Redirect } from "react-router-dom";
import Dropdown from "Common/Dropdown/Dropdown";
import TextFields from "Common/TextField/TextField";
import CustomDatePickerBorder from "Common/DatePicker/DatePicker";
import {
    ContractDetailsTableDataCusQuery,
    ContractHeaderDataCusQuery,
    StatusComboQuery,
} from "ReactQuery/Query/index";
import Alert from "Common/Alerts/Alerts";
import currencyFormatter from "Common/Utils/currencyFormatter";
import "Common/Main.scss";
import NoContracts from "./Images/Contract_sign.svg";
import { storeBreadcrumbs } from "stateManagement/actions/Common/breadcrumbs";
import dateFormatter from "Common/Utils/dateFormatter";

interface DashCards {
    desc: string;
    value: string;
    visible: boolean;
    frequent: boolean;
    detail: {
        desc: string;
        value: number;
        color: string;
    }[];
}

const useStyles = makeStyles((theme: Theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: "#fff",
    },
}));

interface BodyProps {
    currentContext: any;
    userName: any;
    selectedCustomer: any;
    currency: any;
    setBreadcrumbsData: any;
}

const Body = (props: BodyProps) => {
    const [header, setHeader] = useState<DashCards>();
    const classes = useStyles();
    const { currentContext, selectedCustomer, currency, setBreadcrumbsData } =
        props;
    setBreadcrumbsData([
        {
            value: "Contracts",
            href: "/app/*/Contracts",
        },
    ]);
    const [statusList, setStatusList] = useState<any>([]);
    const [contractData, setContractData] = useState({
        fromDate: new Date(),
        toDate: new Date(),
        contractno: "",
        status: "",
    });

    const [active, setActive] = useState<string>("");

    const [showTable, setShowTable] = useState<boolean>(false);
    const [contractTablelist, setContractTableList] = useState<any>([]);
    const [alert, setAlert] = useState(false);
    const [errr, seterr] = useState({
        code: 0,
        msg: "",
    });

    const [contractDet, setContractDet] = useState<boolean>(false);
    const [contractSelected, setContractSelected] = useState<any>("");
    //const [contractToggle, setContractToggle] = useState(true);
    const [index, setIndex] = useState<any>(0);

    const handleDate = (type: string, e: any) => {
        setShowTable(false);
        if (type === "toDate") {
            setContractData((prevstate: any) => ({
                ...prevstate,
                toDate: e,
            }));
        } else if (type === "fromDate") {
            setContractData((prevstate: any) => ({
                ...prevstate,
                fromDate: e,
            }));
        }
    };

    const {
        data: ContractHeaderData,
        error: ContractHeaderDataError,
        isLoading: ContractHeaderDataLoading,
    } = useGetPosts({
        name: `ContractHeaderDataCusQuery_${selectedCustomer?.customerCode}`,
        query: ContractHeaderDataCusQuery,
        variables: {
            entity: "OW_Cust_portal_status",
            ou: currentContext?.ouId,
            service: "",
            User: selectedCustomer?.loginUser,
            languageId: currentContext?.langId,
            customerCode: selectedCustomer?.customerCode,
            merrorid: "",
        },
    });

    useEffect(() => {
        if (ContractHeaderData?.ContractHeaderDataCus) {
            setHeader(ContractHeaderData?.ContractHeaderDataCus);
            setActive(
                ContractHeaderData?.ContractHeaderDataCus?.detail[0]?.desc,
            );
            //setContractToggle(false);
        }
    }, [ContractHeaderData]);

    useEffect(() => {
        if (ContractHeaderDataError && !ContractHeaderDataLoading) {
            const data = JSON.parse(JSON.stringify(ContractHeaderDataError));
            const errmsg = data?.response?.errors[0].message;
            seterr({
                code: 400,
                msg: errmsg,
            });
            setAlert(true);
        }
    }, [ContractHeaderDataError, ContractHeaderDataLoading]);

    const {
        data: StatusComboData,
        error: StatusComboDataError,
        isLoading: StatusComboDataLoading,
    } = useGetPosts({
        name: `StatusComboQuery_${selectedCustomer?.loginUser}_Contract`,
        query: StatusComboQuery,
        variables: {
            entity: "ow_getcombo_status",
            ou: currentContext?.ouId,
            service: "",
            user: selectedCustomer?.loginUser,
            langId: currentContext?.langId,
            screen: "Contract",
            tranType: "",
            merrorid: "",
        },
    });

    useEffect(() => {
        if (StatusComboData?.statusCombo) {
            console.log("StatusData", StatusComboData?.statusCombo);
            setStatusList(StatusComboData?.statusCombo);
            setContractData((prevState: any) => {
                return {
                    ...prevState,
                    status: StatusComboData?.statusCombo[0]?.value,
                };
            });
        }
    }, [StatusComboData]);

    useEffect(() => {
        if (StatusComboDataError && !StatusComboDataLoading) {
            const data = JSON.parse(JSON.stringify(StatusComboDataError));
            const errmsg = data?.response?.errors[0].message;
            seterr({
                code: 400,
                msg: errmsg,
            });
            setAlert(true);
        }
    }, [StatusComboDataError, StatusComboDataLoading]);

    const {
        data: ContractDetailsTableData,
        error: ContractDetailsTableDataError,
        isLoading: ContractDetailsTableDataLoading,
    } = useGetPosts({
        name: `ContractDetailsTableDataCusQuery_${contractData?.fromDate}_${contractData?.toDate}_${contractData?.contractno}_${contractData?.status}_${active}_${selectedCustomer?.customerCode}`,
        query: ContractDetailsTableDataCusQuery, //ContractHeaderQuery,
        variables: {
            entity: "OW_Cust_portal_cntct_dtls",
            ou: currentContext?.ouId,
            service: "",
            User: selectedCustomer?.loginUser,
            languageId: currentContext?.langId,
            customerName: "",
            customerCode: selectedCustomer?.customerCode,
            status:
                active === header?.detail[0]?.desc
                    ? "AC"
                    : contractData?.status,
            contractno: contractData?.contractno,
            fromdate: dateFormatter("sqlDate", contractData?.fromDate),
            todate: dateFormatter("sqlDate", contractData?.toDate),
            merrorid: "",
        },
        skip: active === header?.detail[0]?.desc ? false : !showTable,
    });

    useEffect(() => {
        if (ContractDetailsTableData?.ContractTableDataCus ) {
            // const data =[ {
            //     Region: "Tulsa sjdhbsjdhbckd",
            //     ContractID: "CC409344",
            //     Building: "13720 E 46th St N",
            //     BuildingID: "Quarry Landfill",
            //     AccountNumber: "C1001570",
            //     Customer: null,
            //     BuildingAmount: "749",
            //     Contracts: "1",
            //     Revision: "2",
            //     RevisionStartEnd: "11/01/2023 - 10/31/2025",
            //     ActiveAssignment: null,
            //     Status: "Active",
            //     siteId: "S0117",
            // },{
            //     Region: "Tulsa1",
            //     ContractID: "CC409344",
            //     Building: "13720 E 46th St N",
            //     BuildingID: "Quarry Landfill",
            //     AccountNumber: "C1001570",
            //     Customer: null,
            //     BuildingAmount: "74",
            //     Contracts: "1",
            //     Revision: "2",
            //     RevisionStartEnd: "11/01/2023 - 10/31/2025",
            //     ActiveAssignment: null,
            //     Status: "Active",
            //     siteId: "S0117",
            // }];
            setContractTableList(
                ContractDetailsTableData?.ContractTableDataCus,
            );
            //setContractToggle(true);
        }
    }, [ContractDetailsTableData]);

    useEffect(() => {
        if (ContractDetailsTableDataError && !ContractDetailsTableDataLoading) {
            const data = JSON.parse(
                JSON.stringify(ContractDetailsTableDataError),
            );
            const errmsg = data?.response?.errors[0].message;
            seterr({
                code: 400,
                msg: errmsg,
            });
            // setAlert(true);
            //setContractToggle(true);
        }
    }, [ContractDetailsTableDataError, ContractDetailsTableDataLoading]);

    const ContractTableSchema = [
        {
            name: "Region",
            Label: "Region",

            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => (
                    <span style={{ color: "white"}}>Region</span>
                ),
                setCellHeaderProps: () => {
                    return { style: { borderRight: "1px solid #D5D5D5",textAlign: "left" } };
                },
                setCellProps: () => {
                    return {
                        style: {
                            borderRight: "1px solid #D5D5D5",
                            textAlign: "left",
                        },
                    };
                },
                customBodyRender: (
                    value: any,
                    tableMeta: any,
                    updateValue: any,
                ) => {
                    console.log("table data", value);
                    return <b style={{ color: "#5C677D" }}>{value}</b>;
                },
            },
        },
        {
            name: "ContractID",
            Label: "ContractID",
            type: "number",
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => (
                    <span style={{ color: "white" }}>Contract ID</span>
                ),
                setCellHeaderProps: () => {
                    return { style: { borderRight: "1px solid #D5D5D5", textAlign: "left" } };
                },
                setCellProps: () => {
                    return {
                        style: {
                            borderRight: "1px solid #D5D5D5",
                            textAlign: "left",
                        },
                    };
                },
                customBodyRender: (
                    value: any,
                    tableMeta: any,
                    updateValue: any,
                ) => {
                    console.log("table data", value, tableMeta.rowData);
                    return (
                        <b
                            style={{ color: "blue", cursor: "pointer" }}
                            onClick={() =>
                                handleContractClick(value, tableMeta?.rowIndex)
                            }
                        >
                            {value}
                        </b>
                    );
                },
            },
        },
        {
            name: "BuildingID",
            Label: "BuildingID",
            type: "string",
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => (
                    <span style={{ color: "white"}}>Building ID</span>
                ),
                setCellHeaderProps: () => {
                    return { style: { borderRight: "1px solid #D5D5D5", textAlign: "left" } };
                },
                setCellProps: () => {
                    return {
                        style: {
                            borderRight: "1px solid #D5D5D5",
                            textAlign: "left",
                        },
                    };
                },

                customBodyRender: (
                    value:string,
                    tableMeta: any,
                    updateValue: any,
                ) => {
                    console.log("table data", value);
                    return <b style={{ color: "#0073E6" }}>{value}</b>;
                },
            },
        },
        {
            name: "Building",
            Label: "Building",
            type: "string",
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => (
                    <span style={{ color: "white" }}>Building</span>
                ),
                setCellHeaderProps: () => {
                    return { style: { borderRight: "1px solid #D5D5D5", textAlign: "left" } };
                },
                setCellProps: () => {
                    return {
                        style: {
                            borderRight: "1px solid #D5D5D5",
                            textAlign: "left",
                            // padding: '6px 100px !important'
                        },
                    };
                },

                customBodyRender: (
                    value:string,
                    tableMeta: any,
                    updateValue: any,
                ) => {
                    console.log("table data", value);
                    return <b className="grey ">{value}</b>;
                },
            },
        },

        {
            name: "AccountNumber",
            Label: "AccountNumber",
            type: "number",
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => (
                    <span style={{ color: "white" }}>Account Number</span>
                ),
                setCellHeaderProps: () => {
                    return { style: { borderRight: "1px solid #D5D5D5", textAlign: "left" } };
                },
                setCellProps: () => {
                    return {
                        style: {
                            borderRight: "1px solid #D5D5D5",
                            textAlign: "left",
                            
                        },
                    };
                },
                customBodyRender: (
                    value: any,
                    tableMeta: any,
                    updateValue: any,
                ) => {
                    console.log("table data", value);
                    return <b style={{ color: "#5C677D" }}>{value}</b>;
                },
            },
        },
        // {
        //     name: "Customer",
        //     Label: "Customer",
        //     options: {
        //         filter: true,
        //         sort: false,
        //         customHeadLabelRender: () => (
        //             <span style={{ color: "white" }}>Customer</span>
        //         ),
        //         setCellHeaderProps: () => {
        //             return { style: { borderRight: "1px solid #D5D5D5" } };
        //         },
        //         setCellProps: () => {
        //             return {
        //                 style: {
        //                     borderRight: "1px solid #D5D5D5",
        //                     textAlign: "center",
        //                 },
        //             };
        //         },
        //         customBodyRender: (
        //             value: any,
        //             tableMeta: any,
        //             updateValue: any,
        //         ) => {
        //             console.log("table data", value);
        //             return <b style={{ color: "#5C677D" }}>{value}</b>;
        //         },
        //     },
        // },
        {
            name: "BuildingAmount",
            Label: "BuildingAmount",
            type: "number",
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => (
                    <span style={{ color: "white" }}>Building Amount</span>
                ),
                setCellHeaderProps: () => {
                    return { style: { borderRight: "1px solid #D5D5D5", textAlign: "right" } };
                },
                setCellProps: () => {
                    return {
                        style: {
                            borderRight: "1px solid #D5D5D5",
                            textAlign: "right",
                            
                        },
                    };
                },
                customBodyRender: (
                    value: any,
                    tableMeta: any,
                    updateValue: any,
                ) => {
                    console.log("table data", value);
                    return (
                        <b
                            // style={{
                            //     display: "flex",
                            //     justifyContent: "flex-end",

                            // }}
                            className="green font-boldest"
                        >
                            {currencyFormatter(value, currency)}
                        </b>
                    );
                },
            },
        },
        // {
        //     name: "Revision",
        //     Label: "Revision",
        //     options: {
        //         filter: true,
        //         sort: false,
        //         customHeadLabelRender: () => (
        //             <span style={{ color: "white" }}>Revision</span>
        //         ),
        //         setCellHeaderProps: () => {
        //             return { style: { borderRight: "1px solid #D5D5D5" } };
        //         },
        //         setCellProps: () => {
        //             return {
        //                 style: {
        //                     borderRight: "1px solid #D5D5D5",
        //                     textAlign: "center",
        //                 },
        //             };
        //         },
        //         customBodyRender: (
        //             value: any,
        //             tableMeta: any,
        //             updateValue: any,
        //         ) => {
        //             console.log("table data", value);
        //             return <b style={{ color: "#5C677D" }}>{value}</b>;
        //         },
        //     },
        // },
        // {
        //     name: "RevisionStartEnd",
        //     Label: "RevisionStartEnd",
        //     options: {
        //         filter: true,
        //         sort: false,
        //         customHeadLabelRender: () => (
        //             <span style={{ color: "white" }}>Revision Start - End</span>
        //         ),
        //         setCellHeaderProps: () => {
        //             return { style: { borderRight: "1px solid #D5D5D5" } };
        //         },
        //         setCellProps: () => {
        //             return {
        //                 style: {
        //                     borderRight: "1px solid #D5D5D5",
        //                     textAlign: "center",
        //                 },
        //             };
        //         },
        //         customBodyRender: (
        //             value: any,
        //             tableMeta: any,
        //             updateValue: any,
        //         ) => {
        //             console.log("table data", value);
        //             return <b style={{ color: "#5C677D" }}>{value}</b>;
        //         },
        //     },
        // },
        // {
        //     name: "ActiveAssignment",
        //     Label: "ActiveAssignment",
        //     options: {
        //         filter: true,
        //         sort: false,
        //         customHeadLabelRender: () => (
        //             <span style={{ color: "white" }}>Active Assignment</span>
        //         ),
        //         setCellHeaderProps: () => {
        //             return { style: { borderRight: "1px solid #D5D5D5" } };
        //         },
        //         setCellProps: () => {
        //             return {
        //                 style: {
        //                     borderRight: "1px solid #D5D5D5",
        //                     textAlign: "center",
        //                 },
        //             };
        //         },
        //         customBodyRender: (
        //             value: any,
        //             tableMeta: any,
        //             updateValue: any,
        //         ) => {
        //             console.log("table data", value);
        //             return <b style={{ color: "#5C677D" }}>{value}</b>;
        //         },
        //     },
        // },
        {
            name: "Status",
            Label: "Status",
            options: {
                filter: true,

                sort: false,
                customHeadLabelRender: () => (
                    <span style={{ color: "white", textAlign: "left", }}>Status</span>
                ),
                setCellHeaderProps: () => {
                    return { style: { borderRight: "1px solid #D5D5D5" } };
                },
                setCellProps: () => {
                    return {
                        style: {
                            borderRight: "1px solid #D5D5D5",
                            textAlign: "center",
                        },
                    };
                },
                customBodyRender: (
                    value: any,
                    tableMeta: any,
                    updateValue: any,
                ) => {
                    console.log("table data", value);
                    return value === "Active" ? (
                        <>
                            <b className="Completed_dot"></b>
                            <b style={{ color: "#00AE50", padding: "3%" }}>
                                {value}
                            </b>
                        </>
                    ) : (
                        <>
                            <b className="Failed_dot"></b>
                            <b style={{ color: "#E01E37", padding: "3%" }}>
                                {value}
                            </b>
                        </>
                    );
                },
            },
        },
    ];

    const handleActive = (index: string) => {
        setActive(index);
        setShowTable(false);
        console.log("index", index);
    };

    const handleErrorAlert = (code: number, message: string) => {
        Alert({
            statusCode: code,
            message: message,
            willClose: () => {
                setAlert(false);
                seterr({ code: 0, msg: "" });
            },
        });
    };

    const handleChange = (e: any) => {
        setShowTable(false);
        setContractData((prevState) => ({
            ...prevState,
            contractno: e?.target?.value,
        }));
    };

    const handleChangeStatus = (e: any) => {
        setShowTable(false);
        setContractData((prevState) => ({
            ...prevState,
            status: e?.target?.value,
        }));
    };
    const handleSearch = () => {
        // setContractTableList([]);
        // setContractToggle(false);
        setShowTable(true);
    };

    const handleContractClick = (val: any, index: any) => {
        setContractDet(true);
        setContractSelected(val);
        setIndex(index);
    };

    if (contractDet) {
        return (
            <Redirect
                to={{
                    pathname: "/app/*/contractDet",
                    state: {
                        contractSelected: contractSelected,
                        siteId: contractTablelist[index]?.siteId,
                    },
                }}
            />
        );
    }

    return (
        <>
            {alert ? handleErrorAlert(errr.code, errr.msg) : <></>}
            <Backdrop
                className={classes.backdrop}
                open={ContractDetailsTableDataLoading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <Grid container spacing={2} alignItems="center">
                <Grid item xs={12} sm={12} style={{ paddingTop: "2%" }}>
                    <Grid container spacing={2} alignItems="center">
                        <Grid item xs={3} md={3}>
                            <Paper elevation={4}>
                                <Grid
                                    container
                                    spacing={2}
                                    style={{
                                        padding: "5% 0% 0% 0%",
                                    }}
                                    justifyContent="space-around"
                                >
                                    <Grid
                                        item
                                        xs={12}
                                        style={{
                                            padding: "1%",
                                            margin: "0%",
                                            // borderRight: "1px dashed #C9CDD6",
                                        }}
                                    >
                                        <Grid
                                            container
                                            spacing={1}
                                            style={{
                                                padding: "1% 0% 0% 0%",
                                            }}
                                            justifyContent="space-around"
                                        >
                                            {header?.detail.map((element) => {
                                                return (
                                                    <Grid
                                                        item
                                                        xs={4}
                                                        sm={4}
                                                        className={
                                                            active ===
                                                            element?.desc
                                                                ? `${element?.color}_border_bottom pointer`
                                                                : "pointer"
                                                        }
                                                        onClick={() =>
                                                            handleActive(
                                                                element?.desc,
                                                            )
                                                        }
                                                    >
                                                        <Grid
                                                            container
                                                            spacing={1}
                                                        >
                                                            <Grid
                                                                item
                                                                xs={12}
                                                                sm={12}
                                                            >
                                                                <span
                                                                    className={
                                                                        active ===
                                                                        element?.desc
                                                                            ? `${element?.color} font-biggest font-boldest`
                                                                            : `grey font-biggest font-boldest`
                                                                    }
                                                                >
                                                                    {
                                                                        element?.value
                                                                    }
                                                                </span>
                                                            </Grid>
                                                            <Grid
                                                                item
                                                                xs={12}
                                                                sm={12}
                                                            >
                                                                <span
                                                                    className={
                                                                        active ===
                                                                        element?.desc
                                                                            ? `font-boldest font-smallest`
                                                                            : "grey font-smallest"
                                                                    }
                                                                >
                                                                    {
                                                                        element?.desc
                                                                    }
                                                                </span>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                );
                                            })}
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid>

                        {active === header?.detail[1]?.desc && (
                            <Grid item sm={9} md={8}>
                                <Paper elevation={4}>
                                    <Grid
                                        container
                                        spacing={2}
                                        style={{ padding: "1.5%" }}
                                    >
                                        <Grid item xs sm={10}>
                                            <Grid
                                                container
                                                spacing={2}
                                                alignItems="center"
                                            >
                                                <Grid item sm xs={12}>
                                                    {/* <Grid item xs sm={5}> */}
                                                    <TextFields
                                                        label={"Contract No."}
                                                        id={"contractno"}
                                                        value={
                                                            contractData?.contractno
                                                        }
                                                        handleChange={
                                                            handleChange
                                                        }
                                                        width={"100%"}
                                                        multiline={false}
                                                        rows={0}
                                                        curvedBorder={false}
                                                    />
                                                </Grid>
                                                <Grid item sm xs={12}>
                                                    <Dropdown
                                                        label={"Status"}
                                                        id={"status"}
                                                        value={
                                                            contractData?.status
                                                        }
                                                        list={statusList}
                                                        handleChange={
                                                            handleChangeStatus
                                                        }
                                                        width={"100%"}
                                                    />
                                                </Grid>
                                                <Grid item sm xs={2}>
                                                    <CustomDatePickerBorder
                                                        label="From Date"
                                                        width="100%"
                                                        value={
                                                            contractData?.fromDate
                                                        }
                                                        onChange={(e) =>
                                                            handleDate(
                                                                "fromDate",
                                                                e,
                                                            )
                                                        }
                                                        format="MM/dd/yyyy"
                                                    />
                                                </Grid>
                                                <Grid item sm xs={2}>
                                                    <CustomDatePickerBorder
                                                        label="To Date"
                                                        width="100%"
                                                        value={
                                                            contractData?.toDate
                                                        }
                                                        onChange={(e) =>
                                                            handleDate(
                                                                "toDate",
                                                                e,
                                                            )
                                                        }
                                                        format="MM/dd/yyyy"
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>

                                        <Grid
                                            item
                                            sm={2}
                                            xs={12}
                                            style={{
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                            }}
                                        >
                                            <MUIButton
                                                label="Search"
                                                type="filled"
                                                font="font-small"
                                                onClick={handleSearch}
                                            />
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </Grid>
                        )}
                    </Grid>
                </Grid>

                {active === header?.detail[0]?.desc && (
                    <Grid
                        item
                        xs={12}
                        style={{ marginRight: "2%", paddingTop: "2%" }}
                    >
                        {contractTablelist.length ? (
                            <MuiDatatables
                                header={ContractTableSchema}
                                title={""}
                                hideAddButton={true}
                                detail={[
                                    contractTablelist,
                                    setContractTableList,
                                ]}
                            />
                        ) : (
                            <img
                                src={NoContracts}
                                alt="No Contracts"
                                style={{ paddingTop: "4%", width: "95vh" }}
                            />
                        )}
                    </Grid>
                )}

                {showTable && active === header?.detail[1]?.desc && (
                    <Grid
                        item
                        xs={12}
                        style={{ marginRight: "2%", paddingTop: "2%" }}
                    >
                        {contractTablelist.length ? (
                            <MuiDatatables
                                header={ContractTableSchema}
                                title={""}
                                hideAddButton={true}
                                detail={[
                                    contractTablelist,
                                    setContractTableList,
                                ]}
                            />
                        ) : (
                            <img
                                src={NoContracts}
                                alt="No Contracts"
                                style={{ paddingTop: "4%" }}
                            />
                        )}
                    </Grid>
                )}
            </Grid>
        </>
    );
};

const mapStateToProps = (state: any) => {
    return {
        currentContext: state.context?.userDefaults,
        userName: state.context?.name,
        selectedCustomer: state.selectedCustomer,
        currency: state.currency,
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        setBreadcrumbsData: (data: any) => {
            dispatch(storeBreadcrumbs(data));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Body);
