import { useEffect, useState } from "react";
import { Grid, Paper, Backdrop, CircularProgress } from "@material-ui/core";
import useGetPosts from "ReactQuery/reactQuery";
import { QAHeader, QAVisitsTable } from "ReactQuery/Query";
import MuiDatatables from "Common/Mui-DataTables/MuiDatatables";
import { connect } from "react-redux";
import { makeStyles, Theme } from "@material-ui/core/styles";
import Alert from "Common/Alerts/Alerts";
import Icons from "Common/Icons/Icons";
import { SwitchClassKey, SwitchProps } from "@material-ui/core/Switch";
import NoVisits from "./Images/NoVisits.svg";
interface Styles extends Partial<Record<SwitchClassKey, string>> {
    focusVisible?: string;
}

interface QADet {
    Building: {
        name: String;
        id: String;
    };
    Address: {
        Area: String;
        City: String;
        State: String;
        Pincode: Number;
    };

    PrimaryContact: String;
    AccountManager: String;
    LastInspection: String;
    FullfilledValue: String;
    NoofInspections: String;
}

const QAVisits = (props: any) => {
    const useStyles = makeStyles((theme: Theme) => ({
        backdrop: {
            zIndex: theme.zIndex.drawer + 1,
            color: "#fff",
        },
        root: {
            flexGrow: 1,
            backgroundColor: theme.palette.background.paper,
        },
    }));

    const { currentContext, selectedVendor, currency } = props;
    const classes = useStyles();

    const [QAVisitdet, setQAVisits] = useState<QADet[]>([]);
    const [QAvisitsInfo, setQAvisitsInfo] = useState<any>([]);

    const [popoverBlack, setPopoverBlack] = useState(false);

    const [alert, setAlert] = useState(false);
    const [errr, seterr] = useState({
        code: 0,
        msg: "",
    });

    const handleErrorAlert = (code: number, message: string) => {
        Alert({
            statusCode: code,
            message: message,
            willClose: () => {
                setAlert(false);
                seterr({ code: 0, msg: "" });
            },
        });
    };

    const {
        data: QAVisitsData,
        error: QAVisitsError,
        isLoading: QAVisitsLoading,
    } = useGetPosts({
        name: "QAVisits",
        query: QAHeader,
        variables: {
            entity: "OW_Vendor_QAvist",
            ou: currentContext?.ouId,
            service: "",
            User: selectedVendor?.loginUser,
            languageId: currentContext?.langId,
            vendorcode: selectedVendor?.supplierCode,
            merrorid: "",
            vendorname: "",
        },
    });
    useEffect(() => {
        if (QAVisitsData?.QAVisits) {
            setQAVisits(QAVisitsData?.QAVisits);
        }
    }, [QAVisitsData]);

    useEffect(() => {
        if (QAVisitsError && !QAVisitsLoading) {
            const data = JSON.parse(JSON.stringify(QAVisitsError));
            const errmsg = data?.response?.errors[0].message;
            seterr({
                code: 400,
                msg: errmsg,
            });
            setAlert(true);
        }
    }, [QAVisitsError, QAVisitsLoading]);

    const {
        data: QAVisitsTableData,
        error: QAVisitsTableError,
        isLoading: QAVisitsTableLoading,
    } = useGetPosts({
        name: `QAVisitsTableQuery`,
        query: QAVisitsTable,
        variables: {
            entity: "OW_Vendor_portal_getqa_visits",
            ou: currentContext?.ouId,
            service: "",
            User: selectedVendor?.loginUser,
            languageId: currentContext?.langId,
            vendorcode: selectedVendor?.supplierCode,
            merrorid: "",
            vendorname: "",
        },
    });
    useEffect(() => {
        if (QAVisitsTableData?.QADetVisitQuery) {
            setQAvisitsInfo(QAVisitsTableData?.QADetVisitQuery);
        }
    }, [QAVisitsTableData]);

    useEffect(() => {
        if (QAVisitsTableError && !QAVisitsTableLoading) {
            const data = JSON.parse(JSON.stringify(QAVisitsTableError));
            const errmsg = data?.response?.errors[0].message;
            seterr({
                code: 400,
                msg: errmsg,
            });
            setAlert(true);
        }
    }, [QAVisitsTableError, QAVisitsTableLoading]);

    const createHeader = (header: string, desc: string) => {
        const headerTag = header;
        const descTag = desc;
        return (
            <Grid container>
                <Grid item xs={12} className="grey">
                    {headerTag}
                </Grid>
                <Grid item xs={12}>
                    {descTag}
                </Grid>
            </Grid>
        );
    };

    const QAVisitSchema = [
        {
            name: "Customer",
            Label: "Customer",
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => (
                    <span style={{ color: "white" }}>Customer</span>
                ),
                setCellHeaderProps: () => {
                    return { style: { borderRight: "1px solid #D5D5D5" } };
                },

                setCellProps: () => {
                    return {
                        style: {
                            borderRight: "1px solid #D5D5D5",

                            textAlign: "center",
                        },
                    };
                },
                customBodyRender: (
                    value: any,
                    tableMeta: any,
                    updateValue: any,
                ) => {
                    console.log("table data", value);
                    return <b className="grey2 font-bolder">{value}</b>;
                },
            },
        },
        {
            name: "Building",
            Label: "Building",
            type: "string",
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => (
                    <span style={{ color: "white" }}> Building</span>
                ),
                setCellHeaderProps: () => {
                    return { style: { borderRight: "1px solid #D5D5D5" } };
                },

                setCellProps: () => {
                    return {
                        style: {
                            borderRight: "1px solid #D5D5D5",

                            textAlign: "center",
                        },
                    };
                },
                customBodyRender: (
                    value: any,
                    tableMeta: any,
                    updateValue: any,
                ) => {
                    console.log("table data", value);
                    return <b className="blue font-bolder">{value}</b>;
                },
            },
        },
        {
            name: "VisitedBy",
            Label: "VisitedBy",
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => (
                    <span style={{ color: "white" }}> Visited By</span>
                ),
                setCellHeaderProps: () => {
                    return { style: { borderRight: "1px solid #D5D5D5" } };
                },

                setCellProps: () => {
                    return {
                        style: {
                            borderRight: "1px solid #D5D5D5",

                            textAlign: "center",
                        },
                    };
                },
                customBodyRender: (
                    value: {
                        building: string;
                        visitedBy: string;
                    },
                    tableMeta: any,
                    updateValue: any,
                ) => {
                    console.log("table data", value);
                    return (
                        <Grid container>
                            <Grid item xs={12}>
                                <b className="grey">{value}</b>
                            </Grid>
                        </Grid>
                    );
                },
            },
        },
        {
            name: "VisitedOn",
            Label: "VisitedOn",
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => (
                    <span style={{ color: "white" }}>Visited On</span>
                ),
                setCellHeaderProps: () => {
                    return { style: { borderRight: "1px solid #D5D5D5" } };
                },

                setCellProps: () => {
                    return {
                        style: {
                            borderRight: "1px solid #D5D5D5",

                            textAlign: "center",
                        },
                    };
                },
                customBodyRender: (
                    value: any,
                    tableMeta: any,
                    updateValue: any,
                ) => {
                    console.log("table data", value);
                    return <b className="grey2 font-bolder">{value}</b>;
                },
            },
        },
        {
            name: "InspectionOrder",
            Label: "InspectionOrder",
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => (
                    <span style={{ color: "white" }}>Inspection Order</span>
                ),
                setCellHeaderProps: () => {
                    return { style: { borderRight: "1px solid #D5D5D5" } };
                },

                setCellProps: () => {
                    return {
                        style: {
                            borderRight: "1px solid #D5D5D5",

                            textAlign: "center",
                        },
                    };
                },
                customBodyRender: (
                    value: any,
                    tableMeta: any,
                    updateValue: any,
                ) => {
                    console.log("table data", value);
                    return <b className="grey2 font-bolder">{value}</b>;
                },
            },
        },
        {
            name: "Note",
            Label: "Note",
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => (
                    <span style={{ color: "white" }}>Note</span>
                ),
                setCellHeaderProps: () => {
                    return { style: { borderRight: "1px solid #D5D5D5" } };
                },

                setCellProps: () => {
                    return {
                        style: {
                            borderRight: "1px solid #D5D5D5",

                            textAlign: "center",
                        },
                    };
                },
                customBodyRender: (
                    value: any,
                    tableMeta: any,
                    updateValue: any,
                ) => {
                    console.log("table data", value);
                    return <b className="grey2 font-bolder">{value}</b>;
                },
            },
        },
    ];

    return (
        <>
            {alert ? handleErrorAlert(errr.code, errr.msg) : <></>}
            <Backdrop
                className={classes.backdrop}
                open={QAVisitsTableLoading || QAVisitsLoading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <Backdrop
                className={classes.backdrop}
                open={popoverBlack}
            ></Backdrop>
            <Grid
                container
                spacing={2}
                style={{ padding: "0 0 2%", marginTop: "2%" }}
            >
                <Grid item xs={10}>
                    <Paper elevation={4}>
                        {QAVisitdet.map(
                            (
                                element: {
                                    Building: {
                                        name: String;
                                        id: String;
                                    };
                                    Address: {
                                        Area: String;
                                        City: String;
                                        State: String;
                                        Pincode: Number;
                                    };

                                    PrimaryContact: String;
                                    AccountManager: String;
                                    LastInspection: String;
                                    FullfilledValue: String;
                                    NoofInspections: String;
                                },
                                index: any,
                            ) => (
                                <Grid container>
                                    <Grid
                                        item
                                        xs={5}
                                        className="border_right_dashed"
                                    >
                                        <Grid
                                            container
                                            justifyContent="center"
                                            className="text-left"
                                            spacing={2}
                                            style={{ padding: "5%" }}
                                        >
                                            <Grid item xs={6}>
                                                <Grid container>
                                                    <Grid item xs={1}>
                                                        <Icons Icon="Book" />
                                                    </Grid>
                                                    <Grid
                                                        item
                                                        xs={10}
                                                        style={{
                                                            paddingLeft: "5%",
                                                        }}
                                                    >
                                                        #{element.Building.id}
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Grid container>
                                                    <Grid item xs={1}>
                                                        <Icons Icon="BuildPic" />
                                                    </Grid>
                                                    <Grid item xs={11}>
                                                        {element.Building.name}
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12} />
                                            <Grid item xs={1}>
                                                <Icons Icon="location" />
                                            </Grid>
                                            <Grid
                                                item
                                                xs={11}
                                                className="grey text-left"
                                            >
                                                {element.Address.Area} |
                                                {element.Address.City} |
                                                {element.Address.State}|
                                                {element.Address.Pincode}
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={7}>
                                        <Grid
                                            container
                                            className="text-left"
                                            spacing={2}
                                            style={{ padding: "3%" }}
                                        >
                                            <Grid item xs={4}>
                                                {createHeader(
                                                    "Account Manager",
                                                    element.AccountManager as string,
                                                )}
                                            </Grid>
                                            <Grid item xs={4}>
                                                {createHeader(
                                                    "Primary Contact",
                                                    element.PrimaryContact as string,
                                                )}
                                            </Grid>
                                            <Grid item xs={4}></Grid>
                                            <Grid item xs={4}>
                                                {createHeader(
                                                    "Last Inspection",
                                                    element.LastInspection as string,
                                                )}
                                            </Grid>
                                            <Grid item xs={4}>
                                                {createHeader(
                                                    " No.of Inspections",
                                                    element.NoofInspections as string,
                                                )}
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            ),
                        )}
                    </Paper>
                </Grid>
                <Grid item xs={12}></Grid>
                <Grid item xs={12}>
                    {QAvisitsInfo?.length ? (
                        <MuiDatatables
                            header={QAVisitSchema}
                            detail={[QAvisitsInfo, setQAvisitsInfo]}
                            tableHeight="320px"
                        />
                    ) : (
                        <img
                            src={NoVisits}
                            alt="No Quality Assurance Inspections"
                            style={{ paddingTop: "4%" }}
                        />
                    )}
                </Grid>
            </Grid>
        </>
    );
};
const mapStateToProps = (state: any) => {
    return {
        currentContext: state.context?.userDefaults,
        userName: state.context?.name,
        selectedCustomer: state.selectedCustomer,
        selectedVendor: state.selectedVendor,
        currency: state.currency,
    };
};
export default connect(mapStateToProps)(QAVisits);
