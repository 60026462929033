import { SET_TERMS_AND_CONDITIONS } from "../../actionTypes";

const storeTermsConditions = (data: any) => {
    return {
        type: SET_TERMS_AND_CONDITIONS,
        payload: data,
    };
};

/**
 * Common File redux
 * @param code
 */
export function storeTermsConditionsInfo<T>(data: any) {
    return (dispatch: any) => {
        dispatch(storeTermsConditions(data));
    };
}
