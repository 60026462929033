import { STORE_SELECTED_COMPANY } from "../actionTypes";

const initialState = {
  data: { desc : "", value : ""},
};

const storeSelectedCompany = (prevState = initialState.data, action: any) => {
  switch (action.type) {
    case STORE_SELECTED_COMPANY:
      return action.payload;

    default:
      return prevState;
  }
};

export default storeSelectedCompany;
