import { Grid, Paper } from "@material-ui/core";
import Icons from "Common/Icons/Icons";
import MuiDatatables from "Common/Mui-DataTables/MuiDatatables";
import currencyFormatter from "Common/Utils/currencyFormatter";
import React from "react";
import { connect } from "react-redux";

const AssignmentDetails = (props: any) => {
    const { currentContext, userName, selectedVendor, currency } = props;
    const AssignmentDetailsSchema = [
        {
            name: "Account Number",
            Label: "Account Number",
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => (
                    <span style={{ color: "white" }}>Account Number</span>
                ),
                setCellHeaderProps: () => {
                    return { style: { borderRight: "1px solid #D5D5D5" } };
                },
                setCellProps: () => {
                    return {
                        style: {
                            borderRight: "1px solid #D5D5D5",
                            textAlign: "center",
                        },
                    };
                },
                customBodyRender: (
                    value: any,
                    tableMeta: any,
                    updateValue: any,
                ) => {
                    return <b className="grey1">{value}</b>;
                },
            },
        },
        {
            name: "Customer",
            Label: "Customer",
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => (
                    <span style={{ color: "white" }}>Customer</span>
                ),
                setCellHeaderProps: () => {
                    return { style: { borderRight: "1px solid #D5D5D5" } };
                },
                setCellProps: () => {
                    return {
                        style: {
                            borderRight: "1px solid #D5D5D5",
                            textAlign: "center",
                        },
                    };
                },
                customBodyRender: (
                    value: any,
                    tableMeta: any,
                    updateValue: any,
                ) => {
                    return <b className="grey1">{value}</b>;
                },
            },
        },
        {
            name: "Replacable",
            Label: "Replacable",
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => (
                    <span style={{ color: "white" }}>Replacable</span>
                ),
                setCellHeaderProps: () => {
                    return { style: { borderRight: "1px solid #D5D5D5" } };
                },
                setCellProps: () => {
                    return {
                        style: {
                            borderRight: "1px solid #D5D5D5",
                            textAlign: "center",
                        },
                    };
                },
                customBodyRender: (
                    value: any,
                    tableMeta: any,
                    updateValue: any,
                ) => {
                    return <b className="grey1">{value}</b>;
                },
            },
        },
        {
            name: "First Month Proration",
            Label: "First Month Proration",
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => (
                    <span style={{ color: "white" }}>
                        First Month Proration
                    </span>
                ),
                setCellHeaderProps: () => {
                    return { style: { borderRight: "1px solid #D5D5D5" } };
                },
                setCellProps: () => {
                    return {
                        style: {
                            borderRight: "1px solid #D5D5D5",
                            textAlign: "right",
                        },
                    };
                },
                customBodyRender: (
                    value: any,
                    tableMeta: any,
                    updateValue: any,
                ) => {
                    return (
                        <b className="green">
                            {currencyFormatter(value, currency)}
                        </b>
                    );
                },
            },
        },
        {
            name: "Start - End Date",
            Label: "Start - End Date",
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => (
                    <span style={{ color: "white" }}>Start - End Date</span>
                ),
                setCellHeaderProps: () => {
                    return { style: { borderRight: "1px solid #D5D5D5" } };
                },
                setCellProps: () => {
                    return {
                        style: {
                            borderRight: "1px solid #D5D5D5",
                            textAlign: "center",
                        },
                    };
                },
                customBodyRender: (
                    value: any,
                    tableMeta: any,
                    updateValue: any,
                ) => {
                    return <b className="grey1">{value}</b>;
                },
            },
        },
        {
            name: "Available Days",
            Label: "Available Days",
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => (
                    <span style={{ color: "white" }}>Available Days</span>
                ),
                setCellHeaderProps: () => {
                    return { style: { borderRight: "1px solid #D5D5D5" } };
                },
                setCellProps: () => {
                    return {
                        style: {
                            borderRight: "1px solid #D5D5D5",
                            textAlign: "center",
                        },
                    };
                },
                customBodyRender: (
                    value: any,
                    tableMeta: any,
                    updateValue: any,
                ) => {
                    return <b className="yellow">{value}</b>;
                },
            },
        },
    ];
    return (
        <Grid container spacing={2}>
            {/* <Grid
                container
                spacing={2}
                style={{ paddingTop: "4%", textAlign: "left" }}
            >
                <Grid item xs={8}>
                    <Paper elevation={1}>
                        <Grid container>
                            <Grid
                                item
                                xs={7}
                                style={{
                                    margin: "0%",
                                    padding: "2%",
                                    borderRight: "1px dashed #C9CDD6",
                                }}
                            >
                                <Grid container spacing={1}>
                                    <Grid item xs={12}>
                                        <Grid
                                            container
                                            justifyContent="space-between"
                                        >
                                            <Grid
                                                item
                                                className="grey2 font-boldest"
                                                xs={4}
                                            >
                                                <Grid container>
                                                    <Grid item xs={2}>
                                                        <Icons Icon="contractDetail" />
                                                    </Grid>
                                                    <Grid
                                                        item
                                                        style={{
                                                            paddingLeft: "2%",
                                                        }}
                                                        xs={9}
                                                    >
                                                        {
                                                            ContactCardList?.ContractID
                                                        }
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid
                                                item
                                                className="grey2 font-boldest"
                                                xs={8}
                                            >
                                                <Grid
                                                    container
                                                    justifyContent="flex-end"
                                                >
                                                    <Grid item xs={1}>
                                                        <Icons Icon="buildingDetail" />
                                                    </Grid>
                                                    <Grid
                                                        item
                                                        xs={9}
                                                        style={{
                                                            paddingLeft: "2%",
                                                        }}
                                                    >
                                                        {" "}
                                                        {
                                                            ContactCardList?.Building
                                                        }
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Grid container>
                                            <Grid item xs={4}>
                                                <Grid container>
                                                    <Grid item xs={2}>
                                                        <Icons Icon="setting" />
                                                    </Grid>
                                                    <Grid
                                                        item
                                                        xs={9}
                                                        className="grey2"
                                                        style={{
                                                            paddingLeft: "2%",
                                                        }}
                                                    >
                                                        {
                                                            ContactCardList?.VendorContract
                                                        }
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={8}></Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid
                                        item
                                        xs={2}
                                        style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            border: `${
                                                ContactCardList?.Status.toLowerCase() ===
                                                "open"
                                                    ? "1px solid #00DA64"
                                                    : "1px solid #DD9200"
                                            }`,
                                            borderRadius: "3px",
                                            background: `${
                                                ContactCardList?.Status.toLowerCase() ===
                                                "open"
                                                    ? "#EDFDF2"
                                                    : "#FCF5E9"
                                            }`,
                                            margin: "4px 0 0 4px",
                                        }}
                                    >
                                        {ContactCardList?.Status}
                                    </Grid>
                                    <Grid item xs={8} />
                                    <Grid item xs={2}>
                                        {currencyFormatter(
                                            ContactCardList?.currency,
                                            currency,
                                        )}
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid
                                item
                                xs={5}
                                style={{ padding: "2% 2% 2% 3.5%" }}
                            >
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={7}>
                                                <Grid container>
                                                    <Grid
                                                        item
                                                        xs={12}
                                                        className="grey"
                                                    >
                                                        Contract Number
                                                    </Grid>
                                                    <Grid
                                                        item
                                                        xs={12}
                                                        className="grey2 font-boldest"
                                                    >
                                                        {
                                                            ContactCardList?.Revision
                                                        }
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={5}>
                                                <Grid container>
                                                    <Grid
                                                        item
                                                        xs={12}
                                                        className="grey"
                                                    >
                                                        Amount
                                                    </Grid>
                                                    <Grid
                                                        item
                                                        xs={12}
                                                        className="grey2 font-boldest"
                                                    >
                                                        {currencyFormatter(
                                                            ContactCardList?.currency,
                                                            currency,
                                                        )}
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
            </Grid> */}
            {/* <Grid item xs={8}>
                <MuiDatatables
                    header={AssignmentDetailsSchema}
                    detail={[assignmentList, setAssignmentList]}
                />
            </Grid> */}
        </Grid>
    );
};

const mapStateToProps = (state: any) => {
    return {
        currentContext: state.context?.userDefaults,
        userName: state.context?.name,
        selectedVendor: state.selectedVendor,
        currency: state.currency,
    };
};

export default connect(mapStateToProps)(AssignmentDetails);
