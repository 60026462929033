import { Grid, Paper } from "@material-ui/core";
import Alert from "Common/Alerts/Alerts";
import BackdropLoading from "Common/Backdrop/Backdrop";
import MUIButton from "Common/Button/Button";
import Dropdown from "Common/Dropdown/Dropdown";
import MuiDatatables from "Common/Mui-DataTables/MuiDatatables";
import TextFields from "Common/TextField/TextField";
import currencyFormatter from "Common/Utils/currencyFormatter";
import { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import {
    CurrentAssignmentMultilineQuery,
    CurrentAssignmentStatusComboQuery,
    CurrentAssignmentStatusQuery,
} from "ReactQuery/Query";
import useGetPosts from "ReactQuery/reactQuery";
import CustomDatePickerBorder from "Common/DatePicker/DatePicker";
import { v4 as uuid } from "uuid";
import { Redirect } from "react-router-dom";
import { storeBreadcrumbs } from "stateManagement/actions/Common/breadcrumbs";

const Body = (props: any) => {
    const {
        currency,
        currentContext,
        selectedVendor,
        setBreadcrumbsData,
        subContractorKey,
    } = props;
    setBreadcrumbsData([
        {
            value: "Current Assignments",
            href: "/app/*/CurrentAssignment",
        },
    ]);
    const [alert, setAlert] = useState(false);
    const [errr, seterr] = useState({
        code: 0,
        msg: "",
    });
    const [header, setHeader] = useState<any>();
    const [active, setActive] = useState<string>("");
    const [vendorDetails, setVendorDetails] = useState<any>({
        redirect: false,
        clickedValue: "",
    });
    const [showTable, setShowTable] = useState<boolean>(true);
    const [statusList, setStatusList] = useState<any>([]);
    const [archivedAssignments, setArchivedAssignments] = useState({
        assignmentNo: "",
        status: "",
        statusCombo: "",
        fromDate: null,
        toDate: null,
    });
    const [
        currentAssignmentMultiline,
        setCurrentAssignmentMultiline,
    ] = useState<any>([]);
    const handleSearch = () => {
        setMultilineSearchCount((prev) => prev + 1);
        setMultilineSearch(true);
        setShowTable(true);
        setCurrentAssignmentMultiline([]);
    };
    const handleErrorAlert = (code: number, message: string) => {
        Alert({
            statusCode: code,
            message: message,
            willClose: () => {
                setAlert(false);
                seterr({ code: 0, msg: "" });
            },
        });
    };

    const handleActive = (index: string) => {
        if (index === header?.detail[0]?.desc) {
            setArchivedAssignments({
                assignmentNo: "",
                status:
                    CurrentAssignmentStatusData?.CurrentAssignmentStatus
                        .detail[0]?.status,
                statusCombo: "",
                fromDate: null,
                toDate: null,
            });
        } else if (index === header?.detail[1]?.desc) {
            setArchivedAssignments({
                assignmentNo: "",
                statusCombo:
                    CurrentAssignmentStatusComboData
                        ?.CurrentAssignmentStatusCombo[0]?.value,
                status:
                    CurrentAssignmentStatusComboData
                        ?.CurrentAssignmentStatusCombo[0]?.value,
                fromDate: null,
                toDate: null,
            });
        }
        setActive(index);
        setShowTable(false);
        setMultilineSearch(true);
        setMultilineSearchCount((prev) => prev + 1);
    };

    const AssignmentSchema = [
        {
            name: "Assignment_ID",
            Label: "Assignment ID",
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => (
                    <span style={{ color: "white" }}>Assignment ID</span>
                ),
                setCellHeaderProps: () => {
                    return { style: { borderRight: "1px solid #D5D5D5" } };
                },
                setCellProps: () => {
                    return {
                        style: {
                            borderRight: "1px solid #D5D5D5",
                            textAlign: "center",
                        },
                    };
                },
                customBodyRender: (
                    value: any,
                    tableMeta: any,
                    updateValue: any,
                ) => {
                    return (
                        <b
                            className="blue pointer"
                            onClick={() => {
                                setVendorDetails({
                                    redirect: true,
                                    clickedValue: value,
                                });
                            }}
                        >
                            {value}
                        </b>
                    );
                },
            },
        },
        {
            name: "Customer",
            Label: "Customer",
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => (
                    <span style={{ color: "white" }}>Customer</span>
                ),
                setCellHeaderProps: () => {
                    return { style: { borderRight: "1px solid #D5D5D5" } };
                },
                setCellProps: () => {
                    return {
                        style: {
                            borderRight: "1px solid #D5D5D5",
                            textAlign: "left",
                        },
                    };
                },
                customBodyRender: (
                    value: any,
                    tableMeta: any,
                    updateValue: any,
                ) => {
                    return <b className="grey1">{value}</b>;
                },
            },
        },
        {
            name: "Building",
            Label: "Building",
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => (
                    <span style={{ color: "white" }}>Building</span>
                ),
                setCellHeaderProps: () => {
                    return { style: { borderRight: "1px solid #D5D5D5" } };
                },
                setCellProps: () => {
                    return {
                        style: {
                            borderRight: "1px solid #D5D5D5",
                            textAlign: "left",
                        },
                    };
                },
                customBodyRender: (
                    value: any,
                    tableMeta: any,
                    updateValue: any,
                ) => {
                    return <b className="grey1">{value}</b>;
                },
            },
        },

        {
            name: "Billing_Amount",
            Label: "Billing Amount",
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () =>
                    subContractorKey ? (
                        <span style={{ color: "white" }}>Net Monthly Amount</span>
                    ) : (
                        <span style={{ color: "white" }}>Billing Amount</span>
                    ),
                setCellHeaderProps: () => {
                    return { style: { borderRight: "1px solid #D5D5D5" } };
                },
                setCellProps: () => {
                    return {
                        style: {
                            borderRight: "1px solid #D5D5D5",
                            textAlign: "right",
                        },
                    };
                },
                customBodyRender: (
                    value: any,
                    tableMeta: any,
                    updateValue: any,
                ) => {
                    return (
                        <b className="green">
                            {currencyFormatter(value, currency)}
                        </b>
                    );
                },
            },
        },
        {
            name: "Start_End_Date",
            Label: "Start - End Date",
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => (
                    <span style={{ color: "white" }}>Start - End Date</span>
                ),
                setCellHeaderProps: () => {
                    return { style: { borderRight: "1px solid #D5D5D5" } };
                },
                setCellProps: () => {
                    return {
                        style: {
                            borderRight: "1px solid #D5D5D5",
                            textAlign: "center",
                        },
                    };
                },
                customBodyRender: (
                    value: any,
                    tableMeta: any,
                    updateValue: any,
                ) => {
                    return <b className="grey1">{value} </b>;
                },
            },
        },
        {
            name: "Service",
            Label: "Service",
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => (
                    <span style={{ color: "white" }}>Service</span>
                ),
                setCellHeaderProps: () => {
                    return { style: { borderRight: "1px solid #D5D5D5" } };
                },
                setCellProps: () => {
                    return {
                        style: {
                            borderRight: "1px solid #D5D5D5",
                            textAlign: "left",
                        },
                    };
                },
                customBodyRender: (
                    value: any,
                    tableMeta: any,
                    updateValue: any,
                ) => {
                    return <b className="grey1">{value} </b>;
                },
            },
        },
    ];
    const {
        data: CurrentAssignmentStatusData,
        error: CurrentAssignmentStatusDataError,
        isLoading: CurrentAssignmentStatusDataLoading,
    } = useGetPosts({
        name: `CurrentAssignmentStatusQuery_${selectedVendor?.supplierCode}`,
        query: CurrentAssignmentStatusQuery,
        variables: {
            ou: currentContext?.ouId,
            service: "",
            User: selectedVendor?.loginUser,
            languageId: currentContext?.langId,
            supplierCode: selectedVendor?.supplierCode,
            merrorid: "",
        },
    });

    useEffect(() => {
        if (CurrentAssignmentStatusData?.CurrentAssignmentStatus) {
            setHeader(CurrentAssignmentStatusData?.CurrentAssignmentStatus);
            setActive(
                CurrentAssignmentStatusData?.CurrentAssignmentStatus.detail[0]
                    ?.desc,
            );
            setArchivedAssignments((prevState) => ({
                ...prevState,
                status:
                    CurrentAssignmentStatusData?.CurrentAssignmentStatus
                        .detail[0]?.status,
            }));
        }
    }, [CurrentAssignmentStatusData]);

    useEffect(() => {
        if (
            CurrentAssignmentStatusDataError &&
            !CurrentAssignmentStatusDataLoading
        ) {
            const data = JSON.parse(
                JSON.stringify(CurrentAssignmentStatusDataError),
            );
            const errmsg = data?.response?.errors[0].message;
            seterr({
                code: 400,
                msg: errmsg,
            });
            setAlert(true);
        }
    }, [CurrentAssignmentStatusDataError, CurrentAssignmentStatusDataLoading]);

    const {
        data: CurrentAssignmentStatusComboData,
        error: CurrentAssignmentStatusComboDataError,
        isLoading: CurrentAssignmentStatusComboDataLoading,
    } = useGetPosts({
        name: `CurrentAssignmentStatusComboQuery_Assignment_${selectedVendor?.loginUser}`,
        query: CurrentAssignmentStatusComboQuery,
        variables: {
            ou: currentContext?.ouId,
            service: "",
            User: selectedVendor?.loginUser,
            languageId: currentContext?.langId,
            callingScreen: "PO",
            trantype: "",
            merrorid: "",
        },
    });

    useEffect(() => {
        if (CurrentAssignmentStatusComboData?.CurrentAssignmentStatusCombo) {
            setStatusList(
                CurrentAssignmentStatusComboData?.CurrentAssignmentStatusCombo,
            );
            setArchivedAssignments((prevState: any) => {
                return {
                    ...prevState,
                    statusCombo:
                        CurrentAssignmentStatusComboData
                            ?.CurrentAssignmentStatusCombo[0]?.value,
                };
            });
        }
    }, [CurrentAssignmentStatusComboData]);

    useEffect(() => {
        if (
            CurrentAssignmentStatusComboDataError &&
            !CurrentAssignmentStatusComboDataLoading
        ) {
            const data = JSON.parse(
                JSON.stringify(CurrentAssignmentStatusComboDataError),
            );
            const errmsg = data?.response?.errors[0].message;
            seterr({
                code: 400,
                msg: errmsg,
            });
            setAlert(true);
        }
    }, [
        CurrentAssignmentStatusComboDataError,
        CurrentAssignmentStatusComboDataLoading,
    ]);

    const [multilineSearch, setMultilineSearch] = useState(false);
    const [multilineSearchvount, setMultilineSearchCount] = useState(0);

    const {
        data: CurrentAssignmentMultilineQueryData,
        error: CurrentAssignmentMultilineQueryDataError,
        isLoading: CurrentAssignmentMultilineQueryDataLoading,
    } = useGetPosts({
        name: `CurrentAssignmentMultilineQuery_${multilineSearchvount}_${selectedVendor?.supplierCode}`,
        query: CurrentAssignmentMultilineQuery,
        variables: {
            ou: currentContext?.ouId,
            service: "",
            User: selectedVendor?.loginUser,
            languageId: currentContext?.langId,
            supplierCode: selectedVendor?.supplierCode,
            suppliername: selectedVendor?.supplierName,
            poNo: archivedAssignments?.assignmentNo,
            status: archivedAssignments?.status,
            fromDate: archivedAssignments?.fromDate,
            ToDate: archivedAssignments?.toDate,
            merrorid: "",
        },
        skip:
            active === header?.detail[0]?.desc && multilineSearch
                ? false
                : !showTable,
    });

    useEffect(() => {
        console.log(
            "CurrentAssignmentMultilineQueryData",
            CurrentAssignmentMultilineQueryData,
        );
        if (CurrentAssignmentMultilineQueryData?.CurrentAssignmentList) {
            setCurrentAssignmentMultiline(
                CurrentAssignmentMultilineQueryData?.CurrentAssignmentList,
            );
            setShowTable(true);
            setMultilineSearch(false);
        }
    }, [
        CurrentAssignmentMultilineQueryData,
        CurrentAssignmentMultilineQueryDataLoading,
    ]);

    useEffect(() => {
        if (
            CurrentAssignmentMultilineQueryDataError &&
            !CurrentAssignmentMultilineQueryDataLoading
        ) {
            const data = JSON.parse(
                JSON.stringify(CurrentAssignmentMultilineQueryDataError),
            );
            const errmsg = data?.response?.errors[0].message;
            seterr({
                code: 400,
                msg: errmsg,
            });
            setAlert(true);
        }
        setMultilineSearch(false);
    }, [
        CurrentAssignmentMultilineQueryDataError,
        CurrentAssignmentMultilineQueryDataLoading,
    ]);

    if (vendorDetails?.redirect) {
        return (
            <Redirect
                to={{
                    pathname: "/app/*/VendorDetails",
                    state: {
                        poNoSelected: vendorDetails?.clickedValue,
                        from :"CurrnAssign"
                    },
                }}
            />
        );
    }
    return (
        <>
            {alert ? handleErrorAlert(errr.code, errr.msg) : <></>}
            <BackdropLoading
                openStates={
                    CurrentAssignmentStatusDataLoading ||
                    CurrentAssignmentMultilineQueryDataLoading ||
                    CurrentAssignmentStatusComboDataLoading
                }
            />
            <Grid
                container
                spacing={3}
                style={{
                    margin: "100px !important",
                    marginTop: "10px",
                }}
            >
                <Grid item xs={12} sm={12} style={{ paddingTop: "2%" }}>
                    <Grid container spacing={2} alignItems="center">
                        <Grid item xs={12} sm={4} md={3}>
                            <Paper elevation={4}>
                                <Grid
                                    container
                                    spacing={2}
                                    style={{
                                        padding: "5% 0% 0% 0%",
                                    }}
                                    justifyContent="space-around"
                                >
                                    <Grid
                                        item
                                        xs={12}
                                        style={{
                                            padding: "1%",
                                            margin: "0%",
                                        }}
                                    >
                                        <Grid
                                            container
                                            spacing={1}
                                            style={{
                                                padding: "1% 0% 0% 0%",
                                            }}
                                            justifyContent="space-around"
                                        >
                                            {header?.detail.map(
                                                (element: any) => {
                                                    return (
                                                        <Grid
                                                            item
                                                            xs={4}
                                                            sm={4}
                                                            className={
                                                                active ===
                                                                element?.desc
                                                                    ? `${element?.color}_border_bottom pointer`
                                                                    : "pointer"
                                                            }
                                                            onClick={() =>
                                                                handleActive(
                                                                    element?.desc,
                                                                )
                                                            }
                                                        >
                                                            <Grid
                                                                container
                                                                spacing={1}
                                                            >
                                                                <Grid
                                                                    item
                                                                    xs={12}
                                                                    sm={12}
                                                                >
                                                                    <span
                                                                        className={
                                                                            active ===
                                                                            element?.desc
                                                                                ? `${element?.color} font-biggest font-boldest`
                                                                                : `grey font-biggest font-boldest`
                                                                        }
                                                                    >
                                                                        {
                                                                            element?.value
                                                                        }
                                                                    </span>
                                                                </Grid>
                                                                <Grid
                                                                    item
                                                                    xs={12}
                                                                    sm={12}
                                                                >
                                                                    <span
                                                                        className={
                                                                            active ===
                                                                            element?.desc
                                                                                ? `font-boldest font-smallest`
                                                                                : "grey font-smallest"
                                                                        }
                                                                    >
                                                                        {
                                                                            element?.desc
                                                                        }
                                                                    </span>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    );
                                                },
                                            )}
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid>

                        {active === header?.detail[1]?.desc && (
                            <Grid item sm={12} md={8}>
                                <Paper elevation={4}>
                                    <Grid
                                        container
                                        spacing={2}
                                        style={{ padding: "1.5%" }}
                                    >
                                        <Grid item xs sm={10}>
                                            <Grid
                                                container
                                                spacing={2}
                                                alignItems="center"
                                            >
                                                <Grid item sm xs={12}>
                                                    <TextFields
                                                        label={"Assignment No."}
                                                        id={"assignmentNo"}
                                                        value={
                                                            archivedAssignments?.assignmentNo
                                                        }
                                                        handleChange={(
                                                            e: any,
                                                        ) => {
                                                            setArchivedAssignments(
                                                                (
                                                                    prevState,
                                                                ) => ({
                                                                    ...prevState,
                                                                    assignmentNo:
                                                                        e
                                                                            ?.target
                                                                            ?.value,
                                                                }),
                                                            );
                                                        }}
                                                        width={"100%"}
                                                        multiline={false}
                                                        rows={0}
                                                    />
                                                </Grid>
                                                <Grid item sm xs={12}>
                                                    <Dropdown
                                                        label={"Status"}
                                                        id={"status"}
                                                        value={
                                                            archivedAssignments?.statusCombo
                                                        }
                                                        list={statusList}
                                                        handleChange={(
                                                            e: any,
                                                        ) => {
                                                            setArchivedAssignments(
                                                                (
                                                                    prevState,
                                                                ) => ({
                                                                    ...prevState,
                                                                    statusCombo:
                                                                        e
                                                                            ?.target
                                                                            ?.value,
                                                                    status:
                                                                        e
                                                                            ?.target
                                                                            ?.value,
                                                                }),
                                                            );
                                                        }}
                                                        width={"100%"}
                                                    />
                                                </Grid>
                                                <Grid item sm xs={2}>
                                                    <CustomDatePickerBorder
                                                        label="From Date"
                                                        width="100%"
                                                        value={
                                                            archivedAssignments?.fromDate
                                                                ? archivedAssignments?.fromDate
                                                                : new Date()
                                                        }
                                                        onChange={(e: any) => {
                                                            setArchivedAssignments(
                                                                (
                                                                    prevState: any,
                                                                ) => {
                                                                    if (
                                                                        prevState.toDate ===
                                                                        null
                                                                    )
                                                                        return {
                                                                            ...prevState,
                                                                            fromDate: e,
                                                                            toDate: new Date(),
                                                                        };
                                                                    else
                                                                        return {
                                                                            ...prevState,
                                                                            fromDate: e,
                                                                        };
                                                                },
                                                            );
                                                        }}
                                                        format="MM/dd/yyyy"
                                                    />
                                                </Grid>
                                                <Grid item sm xs={2}>
                                                    <CustomDatePickerBorder
                                                        label="To Date"
                                                        width="100%"
                                                        value={
                                                            archivedAssignments?.toDate
                                                                ? archivedAssignments?.toDate
                                                                : new Date()
                                                        }
                                                        onChange={(e: any) => {
                                                            setArchivedAssignments(
                                                                (
                                                                    prevState: any,
                                                                ) => {
                                                                    if (
                                                                        prevState.fromDate ===
                                                                        null
                                                                    )
                                                                        return {
                                                                            ...prevState,
                                                                            toDate: e,
                                                                            fromDate: new Date(),
                                                                        };
                                                                    else
                                                                        return {
                                                                            ...prevState,
                                                                            toDate: e,
                                                                        };
                                                                },
                                                            );
                                                        }}
                                                        format="MM/dd/yyyy"
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>

                                        <Grid
                                            item
                                            sm={2}
                                            xs={12}
                                            style={{
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                            }}
                                        >
                                            <MUIButton
                                                label="Search"
                                                type="filled"
                                                font="font-small"
                                                onClick={handleSearch}
                                            />
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </Grid>
                        )}
                    </Grid>
                </Grid>
                {showTable ? (
                    <Grid
                        item
                        xs={12}
                        style={{ marginRight: "2%", marginTop: "10px" }}
                    >
                        <MuiDatatables
                            tableHeight="320px"
                            header={AssignmentSchema}
                            detail={[
                                currentAssignmentMultiline,
                                setCurrentAssignmentMultiline,
                            ]}
                        />
                    </Grid>
                ) : (
                    <Fragment></Fragment>
                )}
            </Grid>
        </>
    );
};
const mapStateToProps = (state: any) => {
    return {
        currency: state.currency,
        currentContext: state.context?.userDefaults,
        selectedVendor: state.selectedVendor,
        subContractorKey: state.subContractorKey,
    };
};
const mapDispatchToProps = (dispatch: any) => {
    return {
        setBreadcrumbsData: (data: any) => {
            dispatch(storeBreadcrumbs(data));
        },
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Body);
