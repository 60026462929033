import { Divider, Grid } from "@material-ui/core";
import MUIButton from "Common/AttachmentButton/AttachmentButton";
import Button from "Common/Button/Button";
import Icons from "Common/Icons/Icons";
import React, { useEffect, useState } from "react";
import TextFields from "Common/TextField/TextField";
import useGetPosts from "ReactQuery/reactQuery";
// import addAddressDetailsQuery from "ReactQuery/Query/AddAddressDetailsQuery";
import { storeSupplier } from "stateManagement/actions/HomePage/storeHiddenSupplier";
import { connect } from "react-redux";
import { Combo } from "Common/Listedit/Listedit";
import Dropdown from "Common/Dropdown/Dropdown";
import { BuildingComboCUS, CreateTicketCUS } from "ReactQuery/Query";
import { create } from "domain";
import TicketPropertyCUS from "ReactQuery/Query/TicketPropertyCUS";
import Configuration from "Configuration";

interface IndexProps {
    currentContext: any;
    setSupplier: any;
    currentData?: any;
    cityComboData?: any;
    stateComboData?: any;
    countryCombo?: any;
    customerData?: any;
    portalKey?: boolean;
}

interface CreateTicket {
    buildingId: string;
    ticketType: string;
    contactPerson: string;
    phoneNumber: string;
    subject: string;
    description: string;
}

interface BuildingCombo {
    desc: string;
    hubspotId: string;
    phoneNo: string;
    value: string;
}

interface TicketCombo {
    desc: string;
    value: string;
}

const CreateticketsSidedraw = (props: IndexProps) => {
    const { currentContext, customerData, portalKey } = props;

    const [Create, setCreate] = useState<boolean>(true);

    const [createTicket, setcreateTicket] = useState<CreateTicket>({
        buildingId: "",
        ticketType: "",
        contactPerson: "",
        phoneNumber: "",
        subject: "",
        description: "",
    });

    const [buildingCombo, setBuildingCombo] = useState<[BuildingCombo] | []>(
        [],
    );

    const [ticketTypeCombo, setTicketTypeCombo] = useState<[TicketCombo] | []>(
        [],
    );

    const [error, setError] = useState({ code: 0, msg: "" });

    const handleDocList = (e: React.ChangeEvent<{ value: unknown }>) => {
        const name = (e?.target as any).name;
        const value = (e?.target as any).value;
        console.log("Testing phoneNo Load", name, value);
        setcreateTicket((prevstate: any) => {
            let changedVal = { [name]: value };
            if (name === "buildingId") {
                let phnNo = buildingCombo?.find(
                    (inner: any) => inner.value === value,
                )?.phoneNo;
                (changedVal as any)["phoneNumber"] =
                    phnNo === null ? "" : phnNo;
            }
            return {
                ...prevstate,
                ...changedVal,
            };
        });
    };

    const handleSubmit = () => {
        setError({ code: 0, msg: "" });
        console.log("data-state", createTicket);
        setCreate(false);
    };

    const {
        data: CreateTicketsListData,
        error: CreateTicketsListDataError,
        isLoading: CreateTicketsListDataLoading,
    } = useGetPosts({
        name: `CreateTicketCUS_get_data`,
        query: CreateTicketCUS,
        variables: {
            customerCode: customerData?.hubspotCustomerId,
            vendorName:portalKey
            ? customerData?.supplierName
            : customerData?.customerName,

            buildingId: buildingCombo.find(
                (element: any) => element?.value === createTicket.buildingId,
            )?.hubspotId,
            ticketType: createTicket.ticketType,
            contactPerson: createTicket.contactPerson,
            phoneNumber: createTicket.phoneNumber,
            subject: createTicket.subject,
            description: createTicket.description,
            pipeline: !portalKey
                ? Configuration.getConfiguration().getCustomerPortalPipeline()
                : Configuration.getConfiguration().getVendorPortalPipeline(),
        },
        skip: Create,
    });

    useEffect(() => {
        if (CreateTicketsListData?.CreateTicketCUS) {
            setError({
                code: 200,
                msg: CreateTicketsListData?.CreateTicketCUS?.msg,
            });
            setCreate(true);
        }
    }, [CreateTicketsListData]);

    useEffect(() => {
        if (CreateTicketsListDataError && !CreateTicketsListDataLoading) {
            const data = JSON.parse(JSON.stringify(CreateTicketsListDataError));
            const errmsg = data?.response?.errors[0].message;
            setError({
                code: 400,
                msg: errmsg,
            });
            setCreate(true);
        }
    }, [CreateTicketsListDataError]);

    const {
        data: BuildingComboListData,
        error: BuildingComboListDataError,
        isLoading: BuildingComboLoading,
    } = useGetPosts({
        name: `BuildingIdCombo`,
        query: BuildingComboCUS,
        variables: {
            customerCode: customerData?.customerCode,
            buildingId: "",
            ou: currentContext?.ouId,
            service: "",
            user: customerData?.loginUser,
            langId: currentContext?.langId,
            role: currentContext?.roleName,
        },
    });

    useEffect(() => {
        if (BuildingComboListData?.BuildingComboCUS) {
            setBuildingCombo(BuildingComboListData?.BuildingComboCUS);
            setcreateTicket((prevState) => ({
                ...prevState,
                buildingId: BuildingComboListData?.BuildingComboCUS[0]?.value,
                phoneNumber:
                    BuildingComboListData?.BuildingComboCUS[0]?.phoneNo,
            }));
        }
    }, [BuildingComboListData]);

    const {
        data: TicketPropertyComboListData,
        error: TicketPropertyDataError,
        isLoading: TicketPropertyLoading,
    } = useGetPosts({
        name: `TicketPropertyCombo`,
        query: TicketPropertyCUS,
        variables: {
            propertyCode: portalKey
                ? "vendor_ticket_type"
                : "customer_ticket_type",
        },
    });

    useEffect(() => {
        if (TicketPropertyComboListData?.TicketPropertyComboCUS) {
            setTicketTypeCombo(
                TicketPropertyComboListData?.TicketPropertyComboCUS,
            );
            setcreateTicket((prevState) => ({
                ...prevState,
                ticketType:
                    TicketPropertyComboListData?.TicketPropertyComboCUS[0]
                        ?.value,
            }));
        }
    }, [TicketPropertyComboListData]);

    useEffect(() => {
        if (CreateTicketsListData?.CreateTicketCUS) {
            console.log(CreateTicketsListData?.CreateTicketCUS?.msg);
        }
        setCreate(true);
    }, [CreateTicketsListData]);

    useEffect(() => {
        setCreate(true);
    }, [CreateTicketsListDataError, CreateTicketsListDataLoading]);

    return (
        <>
            <Grid
                container
                spacing={3}
                style={{
                    maxWidth: "500px",
                    paddingLeft: "2%",
                    marginTop: "1%",
                    marginRight: "1%",
                }}
                alignItems="center"
            >
                <Grid item xs={6}>
                    <Grid
                        item
                        xs={12}
                        className="font-smallest grey"
                        style={{ paddingLeft: "1%" }}
                    >
                        {portalKey ? "Vendor Name" : "Customer Name"}
                    </Grid>
                    <Grid item xs={12} style={{ paddingLeft: "1%" }}>
                        {portalKey
                            ? customerData?.supplierName
                            : customerData?.customerName}
                    </Grid>
                </Grid>
                <Grid item xs={6}>
                    <Dropdown
                        label={"Building"}
                        id={"buildingId"}
                        value={createTicket.buildingId}
                        list={buildingCombo}
                        handleChange={handleDocList}
                        width={"100%"}
                    />
                </Grid>
                <Grid item xs={6}>
                    <Dropdown
                        label={"Ticket Type"}
                        id={"ticketType"}
                        value={createTicket.ticketType}
                        list={ticketTypeCombo}
                        handleChange={handleDocList}
                        width={"100%"}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextFields
                        label="PhoneNumber"
                        id="phoneNumber"
                        multiline={false}
                        rows={1}
                        value={createTicket.phoneNumber}
                        handleChange={() => {}}
                        width="100%"
                    />
                </Grid>

                <Grid item xs={12}>
                    <TextFields
                        label="Subject"
                        id="subject"
                        multiline={false}
                        rows={1}
                        value={createTicket.subject}
                        handleChange={handleDocList}
                        width="100%"
                    />
                </Grid>
                <Grid item xs={12} sm={12}>
                    <TextFields
                        label="Description"
                        id="description"
                        multiline={true}
                        rows={5}
                        value={createTicket.description}
                        handleChange={handleDocList}
                        width="100%"
                    />
                </Grid>
                <Grid item xs={12}>
                    {error.code !== 0 ? (
                        <Grid
                            item
                            xs={12}
                            style={{ textAlign: "center", padding: "2%" }}
                            className={
                                error.code === 200
                                    ? "green-light-background"
                                    : "red-light-background"
                            }
                        >
                            <span
                                className={`${
                                    error?.code === 200
                                        ? "Success_dot"
                                        : "Failed_dot"
                                }`}
                            ></span>{" "}
                            <b>{error.msg}</b>
                        </Grid>
                    ) : (
                        <Grid
                            item
                            xs={12}
                            style={{ textAlign: "center", padding: "2%" }}
                        >
                            <span></span> <b></b>
                        </Grid>
                    )}
                </Grid>

                <Grid container style={{ paddingTop: "20%" }}></Grid>
                <Grid item xs={12} sm={12}>
                    <Divider />
                    <Grid container style={{ paddingTop: "5%" }}>
                        <Grid item xs={6}></Grid>
                        <Grid item xs={3}>
                            <Button
                                label={"Reset"}
                                type="outlined"
                                font={""}
                                onClick={() => {
                                    setcreateTicket({
                                        buildingId: "",
                                        ticketType: "",
                                        contactPerson: "",
                                        phoneNumber: "",
                                        subject: "",
                                        description: "",
                                    });
                                    setError({ code: 0, msg: "" });
                                }}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <Button
                                label={"Submit"}
                                type={"filled"}
                                font={""}
                                onClick={handleSubmit}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};
const mapStateToProps = (state: any) => {
    return {
        currentContext: state.context?.userDefaults,
        userName: state.context.name,
        customerData: state?.portalKey
            ? state?.selectedVendor
            : state?.selectedCustomer,
        portalKey: state?.portalKey,
    };
};
const mapDispatchToProps = (dispatch: any) => {
    return {
        setSupplier: (data: any) => {
            dispatch(storeSupplier(data));
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(CreateticketsSidedraw);
