const SaleOrderGenerationPostQuery = `
mutation 
    (
        $SALEORDERDTL: [SALEORDERDTLDataSchema],
        $SOTCDDTL: [SOTCDDTLDataSchema],
        $SALEORDERHDR: SALEORDERHDRSchema,
    ) 
        {
            SaleOrderGenerationPostQuery
                (
                    SALEORDERDTL:$SALEORDERDTL, SOTCDDTL:$SOTCDDTL,SALEORDERHDR:$SALEORDERHDR
                ) 
                    {
                        Error_msg
                        Tran_no
                    }
        }
  `;

export default SaleOrderGenerationPostQuery;
