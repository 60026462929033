import Dashboard from "Components/Dashboard";
import {
    Apartment,
    Assignment,
    ContactlessTwoTone,
    // Contacts,
    DashboardOutlined,
    Phone,
    QuestionAnswer,
    Receipt,
} from "@material-ui/icons";
import { MenuCategorySchema } from "./types";
import HomeIcon from "@material-ui/icons/Home";
import BugReportIcon from "@material-ui/icons/BugReport";
import ShoppingBasketIcon from "@material-ui/icons/ShoppingBasket";
import OrderSuppliesCus from "Components/OrderSuppliesCus";
import OrderSupplyChild from "Components/OrderSuppliesCus/OrderSupplyChild";
import CompanyProfileCus from "Components/CompanyProfileCus";
import InvoicesCus from "Components/InvoicesCus";
import InvoiceDetailsChild from "Components/InvoicesCus/InvoiceDetailsChild";
import ContractsCus from "Components/ContractsCus";
import BuildingCUS from "Components/BuildingCUS";
import BuildingDetailsCUS from "Components/BuildingCUS/Details";
import TicketsCUS from "Components/TicketsCUS";
import TicketDetails from "Components/TicketsCUS/TicketDetails";
import FranchiseInsuranceRequirment from "Components/Dashboard/FranchiseInsuranceRequirment";
import QAVisits from "Components/Dashboard/QAVisits";
import PayAdviceVen from "Components/PayAdviceVen";
import FranchiseeChild from "Components/Dashboard/FranchiseeChild";
import FranchiseStatementVen from "Components/FranchiseStatementVen";
import PendingApproval from "Components/ContractsVEN/PendingApprovalVEN/Body";
import ContractsChild from "Components/ContractsCus/ContractsChild";
import BusinessIcon from "@material-ui/icons/Business";
import ReceiptIcon from "@material-ui/icons/Receipt";
import Admin from "Components/Admin";
import CalendarScheduleVEN from "Components/CalendarScheduleVEN";

const NavMenuSchema: MenuCategorySchema[] = [
    {
        id: 2,
        title: "Dashboard",
        value: "Dashboard",
        subcategories: [],
        route: "Dashboard",
        level: "BPCActivity",
        parent: 0,
        child: Dashboard,
        icon: DashboardOutlined,
        type: "CMN",
    },
    {
        id: 3,
        title: "Contracts",
        value: "ContractsCus",
        subcategories: [],
        route: "Contracts",
        level: "BPCActivity",
        parent: 0,
        child: ContractsCus,
        icon: HomeIcon,
        type: "CUS",
    },
    {
        id: 4,
        title: "Details",
        value: "Details",
        subcategories: [],
        route: "contractDet",
        level: "Component",
        parent: 100,
        child: ContractsChild,
        icon: HomeIcon,
    },
    {
        id: 5,
        title: "Buildings",
        value: "Buildings",
        subcategories: [],
        route: "Buildings",
        level: "BPCActivity",
        parent: 0,
        child: BuildingCUS,
        icon: Apartment,
        type: "CUS",
    },
    // {
    //     id: 6,
    //     title: "Details",
    //     value: "Details",
    //     subcategories: [],
    //     route: "BuildDetail",
    //     level: "Component",
    //     parent: 100,
    //     child:BuildingDetailsCUS,
    //     icon: HomeIcon,
    // },
    // {
    //     id: 6,
    //     title: "FranchiseStatement",
    //     value: "FranchiseStatement",
    //     subcategories: [],
    //     route: "FranchiseStatement",
    //     level: "BPCActivity",
    //     parent: 0,
    //     child: FranchiseStatementVen,
    //     icon: HomeIcon,
    //     type: "CUS",
    // },
    {
        id: 7,
        title: "Invoices",
        value: "InvoicesCus",
        subcategories: [],
        route: "Invoices",
        level: "BPCActivity",
        parent: 0,
        child: InvoicesCus,
        icon: ReceiptIcon,
        type: "CUS",
    },
    {
        id: 8,
        title: "Details",
        value: "Details",
        subcategories: [],
        route: "InvoiceDet",
        level: "Component",
        parent: 100,
        child: InvoiceDetailsChild,
        icon: HomeIcon,
    },
    {
        id: 9,
        title: "Tickets",
        value: "Tickets",
        subcategories: [],
        route: "Tickets",
        level: "BPCActivity",
        parent: 0,
        child: TicketsCUS,
        icon: BugReportIcon,
        type: "CUS",
    },
    // {
    //     id: 10,
    //     title: "Order Supplies",
    //     value: "OrderSuppliesCus",
    //     subcategories: [],
    //     route: "OrderSupplies",
    //     level: "BPCActivity",
    //     parent: 0,
    //     child: OrderSuppliesCus,
    //     icon: ShoppingBasketIcon,
    //     type: "CUS",
    // },
    {
        id: 11,
        title: "Details",
        value: "Details",
        subcategories: [],
        route: "OrderSpDet",
        level: "Component",
        parent: 100,
        child: OrderSupplyChild,
        icon: HomeIcon,
    },
    {
        id: 12,
        title: "Company Profile",
        value: "CompanyProfileCus",
        subcategories: [],
        route: "CompanyProfile",
        level: "BPCActivity",
        parent: 0,
        child: CompanyProfileCus,
        icon: BusinessIcon,
        type: "CUS",
    },
    {
        id: 20,
        title: "Details",
        value: "Details",
        subcategories: [],
        route: "BuildDet",
        level: "Component",
        parent: 100,
        child: BuildingDetailsCUS,
        icon: HomeIcon,
    },
    {
        id: 18,
        title: "TicketDetails",
        value: "TicketDetails",
        subcategories: [],
        icon: QuestionAnswer,
        route: "TICKDET",
        level: "Component",
        parent: 6,
        child: TicketDetails,
    },
    {
        id: 19,
        title: "Franchise",
        value: "Franchise",
        subcategories: [],
        route: "FranchiseInsur",
        level: "Component",
        parent: 100,
        child: FranchiseInsuranceRequirment,
        icon: HomeIcon,
    },
    {
        id: 20,
        title: "QAVisits",
        value: "QAVisits",
        subcategories: [],
        route: "QaVisit",
        level: "Component",
        parent: 100,
        child: QAVisits,
        icon: HomeIcon,
    },
    {
        id: 21,
        title: "PayAdvice",
        value: "PayAdvice",
        subcategories: [],
        route: "PayAdvice",
        level: "Component",
        parent: 100,
        child: PayAdviceVen,
        icon: HomeIcon,
    },
    {
        id: 22,
        title: "Franchisee",
        value: "Franchisee",
        subcategories: [],
        route: "Franchisee",
        level: "Component",
        parent: 100,
        child: FranchiseeChild,
        icon: HomeIcon,
    },
    {
        id: 23,
        title: "PendingApproval",
        value: "PendingApproval",
        subcategories: [],
        icon: QuestionAnswer,
        route: "PENDAPPROVAL",
        level: "Component",
        parent: 3,
        child: PendingApproval,
    },
    {
        id: 24,
        title: "Admin",
        value: "Admin",
        subcategories: [],
        route: "Admin",
        level: "BPCActivityS",
        parent: 0,
        child: Admin,
        icon: HomeIcon,
        type: "CUS",
    },
    {
        id: 27,
        title: "CalendarSchedule",
        value: "CalendarSchedule",
        subcategories: [],
        route: "CalendarSchedule",
        level: "Component",
        parent: 60,
        icon: HomeIcon,
        child: CalendarScheduleVEN,
    },
];
const LandingPageSchema = [
    {
        code: "dashboard",
        child: Dashboard,
    },
];
export default NavMenuSchema;
export { LandingPageSchema };
