const CompanyDetailsTableDataCusQuery = `
    mutation(
        $entity: String
        $ou: Int
        $service: String
        $user: String
        $langId: Int
        $customerCode: String
        $customerName: String
        $merrorid: String
    ){
        CompanyTableDataCus(
            entity: $entity
            ou: $ou
            service: $service
            user: $user
            langId: $langId
            customerCode: $customerCode
            customerName: $customerName
            merrorid: $merrorid
        ){   
            Name
            Email
            UserName
            Designation
            isActive
            phone
            MakePayment
            OrderSupplies
            Admin
            Image 
            ReadOnlyAccess
        }
    }
`;

export default CompanyDetailsTableDataCusQuery;
