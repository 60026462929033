const contractCardQuerySV = `
mutation(
    $entity:String
    $ou:Int
    $service:String
    $User:String
    $languageId:Int
    $VendorCode:String
    $Vendorname:String
    $contractno:String
    $merrorid:String
){
    ContractCardQuerySV(
        entity:$entity
        ou:$ou
        service:$service
        User:$User
        languageId:$languageId
        VendorCode:$VendorCode
        Vendorname:$Vendorname
        contractno:$contractno
        merrorid:$merrorid
    ){
        ContractID,
        Building,
        VendorContract,
        Status    
        Revision,
        Contract,
        StartEndDate,
    } 
}
  `;

export default contractCardQuerySV;
