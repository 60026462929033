import { STORE_CUSTOMER_DETAILS } from "../actionTypes";

const initialState = {
    data: {
        admin: "",
        hubspotCustomerId: "",
        loginUser: "",
        contactId: "",
        customerCode: "",
        orderSupplies: "",
    },
};

const storeSelectedCustomer = (prevState = initialState.data, action: any) => {
    switch (action.type) {
        case STORE_CUSTOMER_DETAILS:
            return action.payload;

        default:
            return prevState;
    }
};

export default storeSelectedCustomer;
