import { STORE_BREADCRUMBS } from "../../actionTypes";

const initialState = null;

const storeBreadcrumbs = (prevState = initialState, action: any) => {
    console.log("payload", action.payload);
    switch (action.type) {
        case STORE_BREADCRUMBS:
            return action.payload;

        default:
            return prevState;
    }
};

export default storeBreadcrumbs;
