import { Divider, Grid } from "@material-ui/core";
import Button from "Common/Button/Button";
import React, { useState } from "react";
import TextFields from "Common/TextField/TextField";
import { storeSupplier } from "stateManagement/actions/HomePage/storeHiddenSupplier";
import { connect } from "react-redux";
import Dropdown from "Common/Dropdown/Dropdown";

interface IndexProps {
    currentContext: any;
    setSupplier: any;
    supplierCode: string;
    currentData?: any;
    cityComboData?: any;
    stateComboData?: any;
    countryCombo?: any;
}
interface EditDet {
    BuildingContractNo: string;
    Building: string;
    City: string;
    PinCode: string;
    PrimaryContact: string;
    AccountManager: string;
    LastInspection: string;
    InspectionFrequency: string;
    SquareFootage: string;
    MonthlyBilling: string;
}

const EditDetailsSidedraw = (props: IndexProps) => {
    const {
        currentContext,
        supplierCode,
        currentData,
        countryCombo,
        stateComboData,
        cityComboData,
    } = props;
    const [attached, setAttached] = useState(true);

    const handleAttachment = () => {
        console.log("clicked");
        setAttached((prevState: any) => !prevState);
    };

    // const [addaddressdata, setAddAddressdata] = useState<any>({
    //     BuildingContractNo: "",
    //     Building: "",
    //     City: "",
    //     PinCode: "",
    //     PrimaryContact: "",
    //     AccountManager: "",
    //     LastInspection: "",
    // });

    const [Edit, setEdit] = useState<boolean>(false);

    const [EditDetail, setEditDetail] = useState<EditDet>({
        BuildingContractNo: "",
        Building: "",
        City: "",
        PinCode: "",
        PrimaryContact: "",
        AccountManager: "",
        LastInspection: "",
        InspectionFrequency: "",
        SquareFootage: "",
        MonthlyBilling: "",
    });

    const handleDocList = (e: React.ChangeEvent<{ value: unknown }>) => {
        const name = (e?.target as any).name;
        const value = (e?.target as any).value;
        setEditDetail((prevstate: any) => ({
            ...prevstate,
            [name]: value,
        }));
    };

    const handleSubmit = () => {
        console.log("data-state", EditDetail);
        setEdit((prev) => !prev);
    };

    const controlList = [
        {
            desc: "General",
            value: "General",
        },
        {
            desc: "Assignment/Refusals/Fulfillment",
            value: "Assignment/Refusals/Fulfillment",
        },
        {
            desc: "Amortization",
            value: "Amortization",
        },
    ];

    const handleChange = (e: any) => {
        console.log(e.target);
    };

    return (
        <>
            <Grid
                container
                spacing={3}
                style={{
                    maxWidth: "450px",
                    paddingLeft: "2%",
                    marginTop: "1%",
                }}
                alignItems="center"
            >
                <Grid item xs={11} sm={11}>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <TextFields
                                label={"BuildingContractNo"}
                                id={"BuildingContractNo"}
                                value={EditDetail.BuildingContractNo}
                                handleChange={handleDocList}
                                width={"100%"}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextFields
                                label={"Building"}
                                id={"Building"}
                                value={EditDetail.Building}
                                handleChange={handleDocList}
                                width={"100%"}
                            />
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={11} sm={11}>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <TextFields
                                label={"City"}
                                id={"City"}
                                value={EditDetail.City}
                                handleChange={handleDocList}
                                width={"100%"}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextFields
                                label="PinCode"
                                id="PinCode"
                                multiline={false}
                                rows={1}
                                value={EditDetail.PinCode}
                                handleChange={handleDocList}
                                width="100%"
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={11} sm={11}>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <TextFields
                                label="PrimaryContact"
                                id="PrimaryContact"
                                multiline={false}
                                rows={1}
                                value={EditDetail.PrimaryContact}
                                handleChange={handleDocList}
                                width="100%"
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextFields
                                label="AccountManager"
                                id="AccountManager"
                                multiline={false}
                                rows={1}
                                value={EditDetail.AccountManager}
                                handleChange={handleDocList}
                                width="100%"
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={11} sm={11}>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <TextFields
                                label="LastInspection"
                                id="LastInspection"
                                multiline={false}
                                value={EditDetail.LastInspection}
                                handleChange={handleDocList}
                                width="100%"
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Dropdown
                                label={"InspectionFrequency"}
                                id={"InspectionFrequency"}
                                value={EditDetail.InspectionFrequency}
                                list={controlList}
                                handleChange={handleDocList}
                                width={"100%"}
                            />
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={11} sm={11}>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <TextFields
                                label="SquareFootage"
                                id="SquareFootage"
                                multiline={false}
                                rows={1}
                                value={EditDetail.SquareFootage}
                                handleChange={handleDocList}
                                width="100%"
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextFields
                                label="MonthlyBilling"
                                id="MonthlyBilling"
                                multiline={false}
                                rows={1}
                                value={EditDetail.MonthlyBilling}
                                handleChange={handleDocList}
                                width="100%"
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container style={{ paddingTop: "50%" }}></Grid>

                <Grid item xs={12} sm={11}>
                    <Divider />
                    <Grid container style={{ paddingTop: "5%" }}>
                        <Grid item xs={6}></Grid>
                        <Grid item xs={3}>
                            <Button
                                label={"Reset"}
                                type="outlined"
                                font={""}
                                onClick={() => {}}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <Button
                                label={"Submit"}
                                type={"filled"}
                                font={""}
                                onClick={handleSubmit}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};
const mapStateToProps = (state: any) => {
    return {
        supplierCode: state.hiddenSupplier?.supplierCodeOut,
        storeCompanies: state.storeCompanies,
        storeOus: state.storeOus,
        currentContext: state.context?.userDefaults,
        userName: state.context.name,
    };
};
const mapDispatchToProps = (dispatch: any) => {
    return {
        setSupplier: (data: any) => {
            dispatch(storeSupplier(data));
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(EditDetailsSidedraw);
