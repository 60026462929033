import { READ_ONLY_ACCESS } from "../../actionTypes";

const storeReadOnlyAccess = (data: boolean) => {
    return {
        type: READ_ONLY_ACCESS,
        payload: data,
    };
};

/**
 * Common File redux
 * @param code
 */
export function storeUserReadOnlyAccess<T>(data: boolean) {
    return (dispatch: any) => dispatch(storeReadOnlyAccess(data));
  
}
