const TicketDataCUS = `
query Query($companyId : String, $pipelineId : String,$ou: Int
    $service: String
    $user: String
    $langId: Int
    $role: String
    $customerCode: String
    $status : String
    $ticketNumber : String
    ) {
    TicketDataCUS(companyId : $companyId, pipelineId: $pipelineId,ou: $ou
        service: $service
        user: $user
        langId: $langId
        role: $role
        customerCode: $customerCode, 
        status : $status, 
        ticketNumber : $ticketNumber) {
        
        IssueID
        BuildingName
        IssueType
        CreatedOn
        FollowUpDate
        Owner
        Subject
        priority
        content
        Status
        StatusColor
        Source
Stage{    
    desc
    color
        }
        
     }
   
  }
  `;

export default TicketDataCUS;
