import { PORTAL_KEY } from "../../actionTypes";

const storePortalsKey = (data: boolean) => {
    return {
        type: PORTAL_KEY,
        payload: data,
    };
};

/**
 * Common File redux
 * @param code
 */
export function storePortalKey<T>(data: boolean) {
    return (dispatch: any) => {
        dispatch(storePortalsKey(data));
    };
}
