import React, { useEffect, useState } from "react";
import { Grid, Paper, Backdrop, CircularProgress } from "@material-ui/core";
import { Alert as Alerts } from "@material-ui/lab";
import useGetPosts from "ReactQuery/reactQuery";
import {
    BuildDetContractCUSQuery,
    BuildingComboCUS,
    CompanyDetailsTableDataCusQuery,
    TicketBuildingDataCUS,
} from "ReactQuery/Query";
import ButtonGroups from "Common/ButtonGroup/ButtonGroup";
import MuiDatatables from "Common/Mui-DataTables/MuiDatatables";
import VisitSchema from "./Schemas/Visits";
import { Redirect, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import { makeStyles, Theme } from "@material-ui/core/styles";
import Alert from "Common/Alerts/Alerts";
import Icons from "Common/Icons/Icons";
import BuildDetQueryCUS from "ReactQuery/Query/BuildDetQueryCUS";
// import TicketsScheme from "./Schemas/TicketsScheme";
import TicketDetailsBuildCUS from "ReactQuery/Query/TicketDetailsBuildCUS";
import BuildDetVisitQueryCUS from "ReactQuery/Query/BuildDetVisitQueryCUS";
import SwipeableDrawerComp from "Common/SideDrawer/SideDrawer";
import EditDetails from "./EditDetails";
import { SwitchClassKey, SwitchProps } from "@material-ui/core/Switch";
import { FormControlLabel, Switch } from "@material-ui/core";
import { withStyles, createStyles } from "@material-ui/styles";
import CompanySideDrawChild from "Components/CompanyProfileCus/CompanySideDrawChild";
import NoVisits from "./Images/NoVisits.svg";
import NoContacts from "./Images/NoContacts.svg";
import Configuration from "Configuration";
import NoContractSign from "../ContractsVEN/Images/NoContractSign.svg";
import NoTicketSign from "../TicketsCUS/Images/No_Ticket_Activity.svg";
import { storeBreadcrumbs } from "stateManagement/actions/Common/breadcrumbs";
interface Styles extends Partial<Record<SwitchClassKey, string>> {
    focusVisible?: string;
}
interface Props extends SwitchProps {
    classes: Styles;
}
interface Props extends SwitchProps {
    classes: Styles;
}
interface buildDet {
    Building: {
        name: String;
        id: String;
    };
    Address: {
        Area: String;
        City: String;
        State: String;
        Pincode: Number;
    };
    Status: String;
    PrimaryContact: String;
    AccountManager: String;
    LastInspection: String;
    InspectionFrequency: String;
    SquareFootage: String;
    MonthlyBilling: String;
}

const useStyles = makeStyles((theme: Theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: "#fff",
    },
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
    },
}));

const BuildingDetailsCUS = (props: any) => {
    const redirectData = useLocation<any>();
    const siteId = redirectData?.state?.siteId;

    const {
        currentContext,
        userName,
        selectedCustomer,
        portalKey,
        selectedVendor,
        setBreadcrumbsData
    } = props;
    setBreadcrumbsData([
        {
            value: "Buildings",
            href: "/app/*/Buildings",
        },
        {
            value: "Building Details",
            href: "/app/*/BuildDet",
        },
    ]);
    const classes = useStyles();
    const [TicketDet, setTicketDet] = useState<boolean>(false);
    const [contractSelected, setcontractSelected] = useState("");
    const controlList = [
        {
            desc: "Contracts",
            value: "Contracts",
        },
        {
            desc: "Tickets",
            value: "Tickets",
        },
        // {
        //     desc: "QA Inspections",
        //     value: "QA Inspections",
        // },
        {
            desc: "Contacts",
            value: "Contacts",
        },
    ];

    const handleContractClick = (val: any) => {
        let data = ticketsDetailsList[val];
        setcontractSelected(data);
        console.log("Selected Contract Value", data);
        setTicketDet(true);
    };

    const TicketsScheme = [
        {
            name: "IssueID",
            Label: "IssueID",
            type: "string",
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => (
                    <span style={{ color: "white" }}>Issue ID</span>
                ),
                setCellHeaderProps: () => {
                    return { style: { borderRight: "1px solid #D5D5D5" } };
                },
                setCellProps: () => {
                    return {
                        style: {
                            borderRight: "1px solid #D5D5D5",
                            textAlign: "center",
                            cursor: "pointer",
                        },
                    };
                },
                customBodyRender: (
                    value: any,
                    tableMeta: any,
                    updateValue: any,
                ) => {
                    console.log("table data", value);
                    return (
                        <b
                            className="blue"
                            onClick={
                                () => handleContractClick(tableMeta?.rowIndex)
                                // handleContractClick(tableMeta.rowData)
                            }
                        >
                            {value}
                        </b>
                    );
                },
            },
        },

        {
            name: "IssueType",
            Label: "IssueType",
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => (
                    <span style={{ color: "white" }}>Issue Type</span>
                ),
                setCellHeaderProps: () => {
                    return { style: { borderRight: "1px solid #D5D5D5" } };
                },
                setCellProps: () => {
                    return {
                        style: {
                            borderRight: "1px solid #D5D5D5",
                            textAlign: "center",
                        },
                    };
                },
                customBodyRender: (
                    value: any,
                    tableMeta: any,
                    updateValue: any,
                ) => {
                    console.log("table data", value, tableMeta.rowData);
                    return <b style={{ color: "#5C677D" }}>{value}</b>;
                },
            },
        },
        {
            name: "CreatedOn",
            Label: "CreatedOn",
            type: "string",
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => (
                    <span style={{ color: "white" }}>Created On</span>
                ),
                setCellHeaderProps: () => {
                    return { style: { borderRight: "1px solid #D5D5D5" } };
                },
                setCellProps: () => {
                    return {
                        style: {
                            borderRight: "1px solid #D5D5D5",
                            textAlign: "center",
                        },
                    };
                },
                customBodyRender: (
                    value: any,
                    tableMeta: any,
                    updateValue: any,
                ) => {
                    console.log("table data", value);
                    return <b style={{ color: "#5C677D" }}>{value}</b>;
                },
            },
        },
        {
            name: "FollowUpDate",
            Label: "FollowUpDate",
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => (
                    <span style={{ color: "white" }}>Follow Up Date</span>
                ),
                setCellHeaderProps: () => {
                    return { style: { borderRight: "1px solid #D5D5D5" } };
                },
                setCellProps: () => {
                    return {
                        style: {
                            borderRight: "1px solid #D5D5D5",
                            textAlign: "center",
                        },
                    };
                },
                customBodyRender: (
                    value: any,
                    tableMeta: any,
                    updateValue: any,
                ) => {
                    console.log("table data", value);
                    return <b style={{ color: "#5C677D" }}>{value}</b>;
                },
            },
        },
        {
            name: "Owner",
            Label: "Owner",
            type: "string",
            options: {
                filter: true,
                sort: false,
                setCellHeaderProps: () => {
                    return { style: { borderRight: "1px solid #D5D5D5" } };
                },
                setCellProps: () => {
                    return {
                        style: {
                            borderRight: "1px solid #D5D5D5",
                            textAlign: "center",
                        },
                    };
                },
                customHeadLabelRender: () => (
                    <span style={{ color: "white" }}>Owner </span>
                ),
                customBodyRender: (
                    value: any,
                    tableMeta: any,
                    updateValue: any,
                ) => {
                    console.log("table data", value);
                    return <b style={{ color: "#5C677D" }}>{value}</b>;
                },
            },
        },

        {
            name: "Subject",
            Label: "Subject",
            type: "string",
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => (
                    <span style={{ color: "white" }}>Subject</span>
                ),
                setCellHeaderProps: () => {
                    return { style: { borderRight: "1px solid #D5D5D5" } };
                },
                setCellProps: () => {
                    return {
                        style: {
                            borderRight: "1px solid #D5D5D5",
                            textAlign: "center",
                        },
                    };
                },
                customBodyRender: (
                    value: any,
                    tableMeta: any,
                    updateValue: any,
                ) => {
                    console.log("table data", value);
                    return <b style={{ color: "#5C677D" }}>{value}</b>;
                },
            },
        },

        {
            name: "Status",
            Label: "Status",
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => (
                    <span style={{ color: "white" }}>Status</span>
                ),
                setCellHeaderProps: () => {
                    return { style: { borderRight: "1px solid #D5D5D5" } };
                },
                setCellProps: () => {
                    return {
                        style: {
                            borderRight: "1px solid #D5D5D5",
                            textAlign: "center",
                        },
                    };
                },
                customBodyRender: (
                    value: any,
                    tableMeta: any,
                    updateValue: any,
                ) => {
                    console.log("table data", value);
                    return (
                        <>
                            {/* <b className="Completed_dot"></b> */}
                            <b
                                style={
                                    value?.color === "green"
                                        ? {
                                              backgroundColor: "#EDFDF2",
                                              padding: "0% 30%",
                                              color: "#16ba4a",
                                          }
                                        : {
                                              backgroundColor: "#FF6D6D0D",
                                              padding: "0% 5%",
                                              color: "red",
                                          }
                                }
                            >
                                {value?.desc}
                            </b>
                        </>
                    );
                },
            },
        },
        {
            name: "priority",
            Label: "Priority",
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => (
                    <span style={{ color: "white" }}>Priority</span>
                ),
                setCellHeaderProps: () => {
                    return { style: { borderRight: "1px solid #D5D5D5" } };
                },
                setCellProps: () => {
                    return {
                        style: {
                            borderRight: "1px solid #D5D5D5",
                            textAlign: "center",
                        },
                    };
                },
                customBodyRender: (
                    value: any,
                    tableMeta: any,
                    updateValue: any,
                ) => {
                    console.log("table data", value);
                    return value === "Priority1" ? (
                        <>
                            <b>
                                <Icons Icon="priority1"></Icons>
                            </b>
                            <b style={{ color: "#00AE50", padding: "3%" }}>
                                {value}
                            </b>
                        </>
                    ) : value === "Priority2" ? (
                        <>
                            <b>
                                {" "}
                                <Icons Icon="priority2"></Icons>
                            </b>
                            <b style={{ color: "#996500", padding: "3%" }}>
                                {value}
                            </b>
                        </>
                    ) : (
                        <>
                            <b className=""></b>
                            <b style={{ color: "#996500", padding: "3%" }}>
                                {value}
                            </b>
                        </>
                    );
                },
            },
        },
    ];

    const [buildDet, setBuildDet] = useState<buildDet[]>([]);
    const [contractInfo, setcontractInfo] = useState<any>([]);
    const [companyDetailsTableData, setCompanyDetailsTableData] = useState<any>(
        [],
    );
    const [visitsInfo, setvisitsInfo] = useState([]);
    const [activeList, setActiveList] = useState(controlList[0].desc);
    const [toggle, setToggle] = useState(false);
    const [popoverBlack, setPopoverBlack] = useState(false);
    const [alert, setAlert] = useState(false);
    const [errr, seterr] = useState({
        code: 0,
        msg: "",
    });
    const [toggleSwitchBtn, setToggleSwitchBtn] = useState(true);
    const [openAdduser, setOpenAdduser] = useState(false);
    const [companyProfile, setCompanyProfile] = useState();
    const [openSideDraw, setOpenSideDraw] = useState<boolean>(false);
    const [CompInfo, setCompInfo] = useState<any>([]);

    const handleChange = (
        rowIndex: number,
        columnName: string,
        value: boolean,
    ) => {
        setCompInfo((prevState: any) => {
            const newData = [...prevState];
            newData[rowIndex][columnName] = !newData[rowIndex][columnName];
            return newData;
        });
        console.log("==================>", CompInfo);
    };
    const {
        data: CompanyDetailsTableData,
        error: CompanyDetailsTableDataError,
        isLoading: CompanyDetailsTableDataLoading,
    } = useGetPosts({
        name: `CompanyDetailsTableDataCusQuery_${selectedCustomer?.customerCode}`,
        query: CompanyDetailsTableDataCusQuery,
        variables: {
            entity: "OW_Cust_portal_getcompprfluser",
            ou: currentContext?.ouId,
            service: "",
            user: selectedCustomer?.loginUser,
            langId: currentContext?.langId,
            customerCode: selectedCustomer?.customerCode,
            customerName: "",
            merrorid: "",
        },
    });

    useEffect(() => {
        console.log(
            "CompanyTableDataCus",
            CompanyDetailsTableData?.CompanyTableDataCus,
        );
        if (CompanyDetailsTableData?.CompanyTableDataCus) {
            setCompanyDetailsTableData(
                CompanyDetailsTableData?.CompanyTableDataCus,
            );
            // setDisabled(true);
        }
    }, [CompanyDetailsTableData]);

    useEffect(() => {
        if (CompanyDetailsTableDataError && !CompanyDetailsTableDataLoading) {
            const data = JSON.parse(
                JSON.stringify(CompanyDetailsTableDataError),
            );
            const errmsg = data?.response?.errors[0].message;
            seterr({
                code: 400,
                msg: errmsg,
            });
            // setAlert(true);
        }
    }, [CompanyDetailsTableDataError, CompanyDetailsTableDataLoading]);
    const handleErrorAlert = (code: number, message: string) => {
        Alert({
            statusCode: code,
            message: message,
            willClose: () => {
                setAlert(false);
                seterr({ code: 0, msg: "" });
            },
        });
    };

    const handleDocList = (listType: string) => {
        console.log("listType", listType);
        setActiveList(listType);
    };

    const {
        data: BuildDetData,
        error: BuildDetError,
        isLoading: BuildDetLoading,
    } = useGetPosts({
        name: `BuildDetQueryCUS_${siteId}`,
        query: BuildDetQueryCUS,
        variables: {
            entity: "OW_Cust_portal_BuildingHdr",
            ou: currentContext?.ouId,
            service: "",
            User: selectedCustomer?.loginUser,
            languageId: currentContext?.langId,
            customerCode: selectedCustomer?.customerCode,
            customerName: "",
            siteId: siteId,
            merrorid: "",
        },
    });

    const {
        data: ticketDetailsBuild,
        error: ticketDetailsBuildError,
        isLoading: ticketDetailsBuildLoading,
    } = useGetPosts({
        name: `TicketBuildingDataCUS${siteId}`,
        query: TicketBuildingDataCUS,
        variables: {
            ou: currentContext?.ouId,
            service: selectedCustomer?.loginUser,
            user: userName,
            langId: currentContext?.langId,
            role: currentContext?.roleName,
            customerCode: selectedCustomer?.customerCode,
            companyId: selectedCustomer?.hubspotCustomerId,
            pipelineId: Configuration.getConfiguration().getCustomerPortalPipeline(),
            buildingId: siteId,
        },
        // skip: ""
    });

    //TicketBuildingDataCUS

    useEffect(() => {
        if (BuildDetData?.BuildDetQueryCUS) {
            setBuildDet(BuildDetData?.BuildDetQueryCUS);
        }
    }, [BuildDetData]);
    useEffect(() => {
        if (BuildDetError && !BuildDetLoading) {
            const data = JSON.parse(JSON.stringify(BuildDetError));
            const errmsg = data?.response?.errors[0].message;
            seterr({
                code: 400,
                msg: errmsg,
            });
            // setAlert(true);
        }
    }, [BuildDetError, BuildDetLoading]);

    const {
        data: BuildDetContractCUSData,
        error: BuildDetContractCUSError,
        isLoading: BuildDetContractCUSLoading,
    } = useGetPosts({
        name: `BuildDetContractCUSQuery_${siteId}`,
        query: BuildDetContractCUSQuery,
        variables: {
            entity: "OW_Cust_portal_buildCntcts",
            ou: currentContext?.ouId,
            service: "",
            User: selectedCustomer?.loginUser,
            languageId: currentContext?.langId,
            customerCode: selectedCustomer?.customerCode,
            customerName: "",
            siteId: siteId,
            buildName: "",
            contractNo: "",
            merrorid: "",
        },
    });
    useEffect(() => {
        if (BuildDetContractCUSData?.BuildDetContractCUSQuery) {
            setcontractInfo(BuildDetContractCUSData?.BuildDetContractCUSQuery);
        }
    }, [BuildDetContractCUSData]);

    useEffect(() => {
        if (BuildDetContractCUSError && !BuildDetContractCUSLoading) {
            const data = JSON.parse(JSON.stringify(BuildDetContractCUSError));
            const errmsg = data?.response?.errors[0].message;
            seterr({
                code: 400,
                msg: errmsg,
            });
            // setAlert(true);
        }
    }, [BuildDetContractCUSError, BuildDetContractCUSLoading]);

    const [ticketsDetailsList, setTicketsDetailsList] = useState([]);
    // const {
    //     data: ticketDetailsBuild,
    //     error: ticketDetailsBuildError,
    //     isLoading: ticketDetailsBuildLoading,
    // } = useGetPosts({
    //     name: `TicketDetailsBuildCUS`,
    //     query: TicketDetailsBuildCUS,
    //     variables: {},
    // });

    useEffect(() => {
        if (ticketDetailsBuild?.TicketBuildingDataCUS) {
            console.log(
                "ticketDeta",
                ticketDetailsBuild?.TicketBuildingDataCUS,
            );
            setTicketsDetailsList(ticketDetailsBuild?.TicketBuildingDataCUS);
        }
    }, [ticketDetailsBuild]);

    useEffect(() => {
        if (ticketDetailsBuildError && !ticketDetailsBuildLoading) {
            const data = JSON.parse(JSON.stringify(ticketDetailsBuildError));
            const errmsg = data?.response?.errors[0].message;
            seterr({
                code: 400,
                msg: errmsg,
            });
            // setAlert(true);
        }
    }, [ticketDetailsBuildError, ticketDetailsBuildLoading]);
    const [hubspotBuildingId, setHubspotBuildingId] = useState("");
    const {
        data: BuildingComboListData,
        error: BuildingComboListDataError,
        isLoading: BuildingComboLoading,
    } = useGetPosts({
        name: `BuildingIdCombo_orderSupplies`,
        query: BuildingComboCUS,
        variables: {
            customerCode: portalKey
                ? selectedVendor?.supplierCode
                : selectedCustomer?.customerCode,
            ou: currentContext?.ouId,
            service: "",
            user: portalKey
                ? selectedVendor?.loginUser
                : selectedCustomer?.loginUser,
            langId: currentContext?.langId,
            role: currentContext?.roleName,
            buildingId: siteId,
        },
    });

    useEffect(() => {
        if (BuildingComboListData?.BuildingComboCUS) {
            setHubspotBuildingId(
                BuildingComboListData?.BuildingComboCUS[0]?.hubspotId,
            );
        }
    }, [BuildingComboListData]);

    const {
        data: BuildDetVisitCUSData,
        error: BuildDetVisitCUSError,
        isLoading: BuildDetVisitCUSLoading,
    } = useGetPosts({
        name: `BuildDetVisitCUSQuery_${siteId}`,
        query: BuildDetVisitQueryCUS,
        variables: {
            entity: "OW_Cust_portal_BuildingVisits",
            ou: currentContext?.ouId,
            service: "",
            User: selectedCustomer?.loginUser,
            languageId: currentContext?.langId,
            customerCode: selectedCustomer?.customerCode,
            customerName: "",
            siteId: siteId,
            buildName: "",
            merrorid: "",
        },
    });

    useEffect(() => {
        if (BuildDetVisitCUSData?.BuildDetVisitQueryCUS) {
            setvisitsInfo(BuildDetVisitCUSData?.BuildDetVisitQueryCUS);
        }
    }, [BuildDetVisitCUSData]);

    useEffect(() => {
        if (BuildDetVisitCUSError && !BuildDetVisitCUSLoading) {
            const data = JSON.parse(JSON.stringify(BuildDetVisitCUSError));
            const errmsg = data?.response?.errors[0].message;
            seterr({
                code: 400,
                msg: errmsg,
            });
            // setAlert(true);
        }
    }, [BuildDetVisitCUSError, BuildDetVisitCUSLoading]);

    if (toggle) {
        return (
            <Redirect
                to={{
                    pathname: "/app/*/contractDet",
                    state: {
                        contractSelected: contractSelected,
                    },
                }}
            />
        );
    }

    if (TicketDet) {
        return (
            <Redirect
                to={{
                    pathname: "/app/*/TICKDET",
                    state: {
                        contractSelected: contractSelected,
                    },
                }}
            />
        );
    }

    const handleClick = (value: any) => {
        setcontractSelected(value);
        setToggle((prevState) => !prevState);
    };
    const ContractSchema = [
        {
            name: "ContactID",
            Label: "ContactID",
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => (
                    <span style={{ color: "white" }}>Contract ID</span>
                ),
                setCellHeaderProps: () => {
                    return { style: { borderRight: "1px solid #D5D5D5" } };
                },
                setCellProps: () => {
                    return {
                        style: {
                            borderRight: "1px solid #D5D5D5",
                            textAlign: "center",
                        },
                    };
                },
                customBodyRender: (
                    value: any,
                    tableMeta: any,
                    updateValue: any,
                ) => {
                    console.log("table data", value);
                    return (
                        <b
                            className="blue font-bolder"
                            style={{ cursor: "pointer" }}
                            onClick={() => handleClick(value)}
                        >
                            {value}
                        </b>
                    );
                },
            },
        },
        // {
        //     name: "Contracts",
        //     Label: "Contracts",
        //     options: {
        //         filter: true,
        //         sort: false,
        //         customHeadLabelRender: () => (
        //             <span style={{ color: "white" }}>Contracts</span>
        //         ),
        //         setCellHeaderProps: () => {
        //             return { style: { borderRight: "1px solid #D5D5D5" } };
        //         },
        //         setCellProps: () => {
        //             return {
        //                 style: {
        //                     borderRight: "1px solid #D5D5D5",
        //                     textAlign: "center",
        //                 },
        //             };
        //         },
        //         customBodyRender: (
        //             value: any,
        //             tableMeta: any,
        //             updateValue: any,
        //         ) => {
        //             console.log("table data", value);
        //             return <b className="grey2 font-bolder">{value}</b>;
        //         },
        //     },
        // },
        // {
        //     name: "Revision",
        //     Label: "Revision",
        //     options: {
        //         filter: true,
        //         sort: false,
        //         customHeadLabelRender: () => (
        //             <span style={{ color: "white" }}>Revision</span>
        //         ),
        //         setCellHeaderProps: () => {
        //             return { style: { borderRight: "1px solid #D5D5D5" } };
        //         },
        //         setCellProps: () => {
        //             return {
        //                 style: {
        //                     borderRight: "1px solid #D5D5D5",
        //                     textAlign: "center",
        //                 },
        //             };
        //         },
        //         customBodyRender: (
        //             value: any,
        //             tableMeta: any,
        //             updateValue: any,
        //         ) => {
        //             console.log("table data", value);
        //             return <b className="grey2 font-bolder">{value}</b>;
        //         },
        //     },
        // },
        {
            name: "RevisionStartEnd",
            Label: "RevisionStartEnd",
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => (
                    <span style={{ color: "white" }}>Contract Period</span>
                ),
                setCellHeaderProps: () => {
                    return { style: { borderRight: "1px solid #D5D5D5" } };
                },
                setCellProps: () => {
                    return {
                        style: {
                            borderRight: "1px solid #D5D5D5",
                            textAlign: "center",
                        },
                    };
                },
                customBodyRender: (
                    value: any,
                    tableMeta: any,
                    updateValue: any,
                ) => {
                    console.log("table data", value);
                    return <b className="grey2 font-bolder">{value}</b>;
                },
            },
        },
        {
            name: "TotalAmt",
            Label: "TotalAmt",
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => (
                    <span style={{ color: "white" }}>Total Amount</span>
                ),
                setCellHeaderProps: () => {
                    return { style: { borderRight: "1px solid #D5D5D5" } };
                },
                setCellProps: () => {
                    return {
                        style: {
                            borderRight: "1px solid #D5D5D5",
                            textAlign: "center",
                        },
                    };
                },
                customBodyRender: (
                    value: any,
                    tableMeta: any,
                    updateValue: any,
                ) => {
                    console.log("table data", value);
                    return <b className="green font-bolder">{value}</b>;
                },
            },
        },
        {
            name: "Type",
            Label: "Type",
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => (
                    <span style={{ color: "white" }}>Type</span>
                ),
                setCellHeaderProps: () => {
                    return { style: { borderRight: "1px solid #D5D5D5" } };
                },
                setCellProps: () => {
                    return {
                        style: {
                            borderRight: "1px solid #D5D5D5",
                            textAlign: "center",
                        },
                    };
                },
                customBodyRender: (
                    value: any,
                    tableMeta: any,
                    updateValue: any,
                ) => {
                    console.log("table data", value);
                    return <b className="grey2 font-bolder">{value}</b>;
                },
            },
        },
        // {
        //     name: "ServiceCode",
        //     Label: "ServiceCode",
        //     options: {
        //         filter: true,
        //         sort: false,
        //         customHeadLabelRender: () => (
        //             <span style={{ color: "white" }}>Service Code</span>
        //         ),
        //         setCellHeaderProps: () => {
        //             return { style: { borderRight: "1px solid #D5D5D5" } };
        //         },
        //         setCellProps: () => {
        //             return {
        //                 style: {
        //                     borderRight: "1px solid #D5D5D5",
        //                     textAlign: "center",
        //                 },
        //             };
        //         },
        //         customBodyRender: (
        //             value: any,
        //             tableMeta: any,
        //             updateValue: any,
        //         ) => {
        //             console.log("table data", value);
        //             return <b className="grey2 font-bolder">{value}</b>;
        //         },
        //     },
        // },
        {
            name: "Desc",
            Label: "Desc",
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => (
                    <span style={{ color: "white" }}>Description</span>
                ),
                setCellHeaderProps: () => {
                    return { style: { borderRight: "1px solid #D5D5D5" } };
                },
                setCellProps: () => {
                    return {
                        style: {
                            borderRight: "1px solid #D5D5D5",
                            textAlign: "center",
                        },
                    };
                },
                customBodyRender: (
                    value: any,
                    tableMeta: any,
                    updateValue: any,
                ) => {
                    console.log("table data", value);
                    return <b className="grey2 font-bolder">{value}</b>;
                },
            },
        },
        {
            name: "PONumConsolidation",
            Label: "PONumConsolidation",
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => (
                    <span style={{ color: "white" }}>
                        PO Number
                    </span>
                ),
                setCellHeaderProps: () => {
                    return { style: { borderRight: "1px solid #D5D5D5" } };
                },
                setCellProps: () => {
                    return {
                        style: {
                            borderRight: "1px solid #D5D5D5",
                            textAlign: "center",
                        },
                    };
                },
                customBodyRender: (
                    value: {
                        poNum: string;
                        consolidation: string;
                    },
                    tableMeta: any,
                    updateValue: any,
                ) => {
                    console.log("table data", value);
                    return <b className="grey2 font-bolder">{value.poNum}</b>;
                    // return (
                    //     <Grid container>
                    //         <Grid item xs={12}>
                    //             <b className="grey2 font-bolder">
                    //                 {value.poNum}
                    //             </b>
                    //         </Grid>
                    //         <Grid item xs={12}>
                    //             <b className="grey">{value.consolidation}</b>
                    //         </Grid>
                    //     </Grid>
                    // );
                },
            },
        },
    ];
    const handleEdit = () => setOpenSideDraw((prev) => !prev);
    const handleChangeCheck = (event: React.ChangeEvent<HTMLInputElement>) => {
        setToggleSwitchBtn(true);
    };
    const MSMESwitch = withStyles((theme: Theme) =>
        createStyles({
            root: {
                width: 38,
                height: 23,
                padding: 0,
                margin: theme.spacing(1),
            },
            switchBase: {
                padding: 1,
                "&$checked": {
                    transform: "translateX(16px)",
                    color: theme.palette.common.white,
                    "& + $track": {
                        backgroundColor: "green",
                        opacity: 1,
                        border: "none",
                    },
                },
                "&$focusVisible $thumb": {
                    color: "green",
                    border: "6px solid green",
                },
            },
            thumb: {
                width: 20,
                height: 20,
            },
            track: {
                borderRadius: 24 / 2,
                border: `1px solid ${theme.palette.grey[400]}`,
                backgroundColor: theme.palette.grey[50],
                opacity: 1,
                transition: theme.transitions.create([
                    "background-color",
                    "border",
                ]),
            },
            checked: {},
            focusVisible: {},
        }),
    )(({ classes, ...props }: Props) => {
        return (
            <Switch
                focusVisibleClassName={classes.focusVisible}
                disableRipple
                classes={{
                    root: classes.root,
                    switchBase: classes.switchBase,
                    thumb: classes.thumb,
                    track: classes.track,
                    checked: classes.checked,
                }}
                {...props}
            />
        );
    });
    const CompanyTableDataSchema = [
        {
            name: "Name",
            Label: "Name",
            type: "string",
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => (
                    <span style={{ color: "white" }}>Name</span>
                ),
                setCellHeaderProps: () => {
                    return { style: { borderRight: "1px solid #D5D5D5" } };
                },
                setCellProps: () => {
                    return {
                        style: {
                            borderRight: "1px solid #D5D5D5",
                            textAlign: "center",
                        },
                    };
                },
                customBodyRender: (
                    value: any,
                    // tableMeta: any,
                    updateValue: any,
                ) => {
                    console.log("table data", value);
                    return (
                        <b
                            style={{ color: "blue" }}
                            onClick={() => {
                                setOpenAdduser((prev) => !prev);
                                setCompanyProfile(value);
                            }}
                        >
                            {value}
                        </b>
                    );
                },
            },
        },
        {
            name: "EmailID",
            Label: "EmailID",
            type: "string",
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => (
                    <span style={{ color: "white" }}>Email ID</span>
                ),
                setCellHeaderProps: () => {
                    return { style: { borderRight: "1px solid #D5D5D5" } };
                },
                setCellProps: () => {
                    return {
                        style: {
                            borderRight: "1px solid #D5D5D5",
                            textAlign: "center",
                        },
                    };
                },
                customBodyRender: (
                    value: any,
                    tableMeta: any,
                    updateValue: any,
                ) => {
                    console.log("table data", value, tableMeta.rowData);
                    return <b style={{ color: "grey" }}>{value}</b>;
                },
            },
        },
        {
            name: "UserName",
            Label: "UserName",
            type: "string",
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => (
                    <span style={{ color: "white" }}>UserName</span>
                ),
                setCellHeaderProps: () => {
                    return { style: { borderRight: "1px solid #D5D5D5" } };
                },
                setCellProps: () => {
                    return {
                        style: {
                            borderRight: "1px solid #D5D5D5",
                            textAlign: "center",
                        },
                    };
                },
                customBodyRender: (
                    value: any,
                    tableMeta: any,
                    updateValue: any,
                ) => {
                    console.log("table data", value);
                    return <b style={{ color: "#5C677D" }}>{value}</b>;
                },
            },
        },
        {
            name: "Designation",
            Label: "Designation",
            type: "string",
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => (
                    <span style={{ color: "white" }}>Designation</span>
                ),
                setCellHeaderProps: () => {
                    return { style: { borderRight: "1px solid #D5D5D5" } };
                },
                setCellProps: () => {
                    return {
                        style: {
                            borderRight: "1px solid #D5D5D5",
                            textAlign: "center",
                        },
                    };
                },
                customBodyRender: (
                    value: any,
                    tableMeta: any,
                    updateValue: any,
                ) => {
                    console.log("table data", value);
                    return <b style={{ color: "green" }}>{value}</b>;
                },
            },
        },
        // {
        //     name: "isActive",
        //     Label: "isActive",
        //     options: {
        //         filter: true,
        //         sort: false,
        //         customHeadLabelRender: () => (
        //             <span style={{ color: "white" }}>isActive?</span>
        //         ),
        //         setCellHeaderProps: () => {
        //             return { style: { borderRight: "1px solid #D5D5D5" } };
        //         },
        //         setCellProps: () => {
        //             return {
        //                 style: {
        //                     borderRight: "1px solid #D5D5D5",
        //                     textAlign: "center",
        //                 },
        //             };
        //         },
        //         customBodyRender: (
        //             value: boolean,
        //             tableMeta: any,
        //             updateValue: any,
        //         ) => {
        //             const handleDataChange = (e: any) => {
        //                 const value = e.target.value;
        //                 const name = e.target.name;
        //                 handleChange(tableMeta.rowIndex, name, value);
        //             };
        //             console.log("table data", value);
        //             return (
        //                 <>
        //                     <b style={{ color: "white" }}>
        //                         <FormControlLabel
        //                             control={
        //                                 <MSMESwitch
        //                                     checked={value}
        //                                     // onChange={handleDataChange}
        //                                     name="isActive"
        //                                 />
        //                             }
        //                             label=""
        //                         />
        //                     </b>
        //                 </>
        //             );
        //         },
        //     },
        // },
        // {
        //     name: "MakePayment",
        //     Label: "MakePayment",
        //     options: {
        //         filter: true,
        //         sort: false,
        //         customHeadLabelRender: () => (
        //             <span style={{ color: "white" }}>Make Payment?</span>
        //         ),
        //         setCellHeaderProps: () => {
        //             return { style: { borderRight: "1px solid #D5D5D5" } };
        //         },
        //         setCellProps: () => {
        //             return {
        //                 style: {
        //                     borderRight: "1px solid #D5D5D5",
        //                     textAlign: "center",
        //                 },
        //             };
        //         },
        //         customBodyRender: (
        //             value: boolean,
        //             tableMeta: any,
        //             updateValue: any,
        //         ) => {
        //             const handleDataChange = (e: any) => {
        //                 const value = e.target.value;
        //                 const name = e.target.name;
        //                 handleChange(tableMeta.rowIndex, name, value);
        //             };
        //             console.log("table data", value);
        //             return (
        //                 <>
        //                     <b style={{ color: "white" }}>
        //                         <FormControlLabel
        //                             control={
        //                                 <MSMESwitch
        //                                     checked={value}
        //                                     // onChange={handleDataChange}
        //                                     name="MakePayment"
        //                                 />
        //                             }
        //                             label=""
        //                         />
        //                     </b>
        //                 </>
        //             );
        //         },
        //     },
        // },
        // {
        //     name: "OrderSupplies",
        //     Label: "OrderSupplies",
        //     type: "boolean",
        //     options: {
        //         filter: true,
        //         sort: false,
        //         customHeadLabelRender: () => (
        //             <span style={{ color: "white" }}>Order Supplies?</span>
        //         ),
        //         setCellHeaderProps: () => {
        //             return { style: { borderRight: "1px solid #D5D5D5" } };
        //         },
        //         setCellProps: () => {
        //             return {
        //                 style: {
        //                     borderRight: "1px solid #D5D5D5",
        //                     textAlign: "center",
        //                 },
        //             };
        //         },
        //         customBodyRender: (
        //             value: boolean,
        //             tableMeta: any,
        //             updateValue: any,
        //         ) => {
        //             const handleDataChange = (e: any) => {
        //                 const value = e.target.value;
        //                 const name = e.target.name;
        //                 handleChange(tableMeta.rowIndex, name, value);
        //             };
        //             console.log("table data", value);
        //             return (
        //                 <>
        //                     <b style={{ color: "white" }}>
        //                         <FormControlLabel
        //                             control={
        //                                 <MSMESwitch
        //                                     checked={value}
        //                                     // onChange={handleDataChange}
        //                                     name="OrderSupplies"
        //                                 />
        //                             }
        //                             label=""
        //                         />
        //                     </b>
        //                 </>
        //             );
        //         },
        //     },
        // },
        // {
        //     name: "Admin",
        //     Label: "Admin",
        //     options: {
        //         filter: true,
        //         sort: false,
        //         customHeadLabelRender: () => (
        //             <span style={{ color: "white" }}>Admin?</span>
        //         ),
        //         setCellHeaderProps: () => {
        //             return { style: { borderRight: "1px solid #D5D5D5" } };
        //         },
        //         setCellProps: () => {
        //             return {
        //                 style: {
        //                     borderRight: "1px solid #D5D5D5",
        //                     textAlign: "center",
        //                 },
        //             };
        //         },
        //         customBodyRender: (
        //             value: boolean,
        //             tableMeta: any,
        //             updateValue: any,
        //         ) => {
        //             const handleDataChange = (e: any) => {
        //                 const value = e.target.value;
        //                 const name = e.target.name;
        //                 handleChange(tableMeta.rowIndex, name, value);
        //             };
        //             console.log("table data", value);
        //             return (
        //                 <>
        //                     <b style={{ color: "white" }}>
        //                         <FormControlLabel
        //                             control={
        //                                 <MSMESwitch
        //                                     checked={value}
        //                                     // onChange={handleDataChange}
        //                                     name="Admin"
        //                                 />
        //                             }
        //                             label=""
        //                         />
        //                     </b>
        //                 </>
        //             );
        //         },
        //     },
        // },
    ];
    return (
        <>
            {alert ? handleErrorAlert(errr.code, errr.msg) : <></>}
            <Backdrop
                className={classes.backdrop}
                open={
                    BuildDetLoading ||
                    BuildDetContractCUSLoading ||
                    BuildDetVisitCUSLoading ||
                    ticketDetailsBuildLoading
                }
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <Grid container style={{ padding: "2% 0 2%" }}>
                <Grid item xs={12}>
                    <Paper elevation={4} variant="outlined">
                        {buildDet.map(
                            (
                                element: {
                                    Building: {
                                        name: String;
                                        id: String;
                                    };
                                    Address: {
                                        Area: String;
                                        City: String;
                                        State: String;
                                        Pincode: any;
                                    };
                                    Status: String;
                                    PrimaryContact: String;
                                    AccountManager: String;
                                    LastInspection: String;
                                    InspectionFrequency: String;
                                    SquareFootage: String;
                                    MonthlyBilling: String;
                                },
                                index: any,
                            ) => (
                                <Grid
                                    container
                                    direction="row"
                                    spacing={2}
                                    className="padding-2-percent"
                                >
                                    <Grid
                                        item
                                        xs={6}
                                        md={4}
                                        style={{
                                            borderRight:
                                                "0.2px dashed lightgray",
                                        }}
                                    >
                                        <Grid container spacing={1}>
                                            <Grid item xs={12}>
                                                <Grid container>
                                                    {/* <Grid
                                                        item
                                                        xs={4}
                                                        className="grey2 text-left font-boldest"
                                                    >
                                                        <Grid container>
                                                            <Grid item>
                                                                <Icons Icon="Book"></Icons>
                                                            </Grid>
                                                            <Grid
                                                                item
                                                                style={{
                                                                    paddingLeft:
                                                                        "3%",
                                                                }}
                                                            >
                                                                {
                                                                    element
                                                                        .Building
                                                                        .id
                                                                }
                                                            </Grid>
                                                        </Grid>
                                                    </Grid> */}
                                                    <Grid
                                                        item
                                                        xs={8}
                                                        className="grey2 font-boldest text-right"
                                                    >
                                                        <Grid
                                                            container
                                                            alignItems="center"
                                                        >
                                                            <Grid item>
                                                                <Icons Icon="BuildPic"></Icons>
                                                            </Grid>
                                                            <Grid
                                                                item
                                                                style={{
                                                                    paddingLeft:
                                                                        "2%",
                                                                }}
                                                            >
                                                                {
                                                                    element
                                                                        .Building
                                                                        .name
                                                                }
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Grid container>
                                                    <Grid
                                                        item
                                                        xs={12}
                                                        className="grey text-left font-big"
                                                    >
                                                        <Grid container>
                                                            <Grid item>
                                                                <Icons Icon="location"></Icons>
                                                            </Grid>
                                                            <Grid
                                                                item
                                                                style={{
                                                                    paddingLeft:
                                                                        "5%",
                                                                }}
                                                            >
                                                                {
                                                                    element
                                                                        .Address
                                                                        .Area
                                                                }{" "}
                                                                |
                                                                {
                                                                    element
                                                                        .Address
                                                                        .City
                                                                }{" "}
                                                                |
                                                                {
                                                                    element
                                                                        .Address
                                                                        .State
                                                                }{" "}
                                                                |
                                                                {
                                                                    element?.Address?.Pincode
                                                                }
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Grid
                                                    container
                                                    justifyContent="flex-start"
                                                >
                                                    <Grid
                                                        item
                                                        xs={4}
                                                        style={{
                                                            display: "flex",
                                                            flexDirection:
                                                                "column",
                                                            // alignItems: "center",
                                                            justifyContent:
                                                                "center",
                                                            border: `${
                                                                element?.Status?.toLowerCase() ===
                                                                "active"
                                                                    ? "1px solid #00DA64"
                                                                    : "1px solid #DD9200"
                                                            }`,
                                                            borderRadius: "3px",
                                                            background: `${
                                                                element?.Status?.toLowerCase() ===
                                                                "active"
                                                                    ? "#EDFDF2"
                                                                    : "#FCF5E9"
                                                            }`,
                                                            alignItems:
                                                                "center",
                                                        }}
                                                    >
                                                        {element?.Status}
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid
                                        item
                                        xs={6}
                                        md={8}
                                        style={{ paddingLeft: "2%" }}
                                    >
                                        <Grid container spacing={1}>
                                            <Grid item xs={12}>
                                                <Grid container>
                                                    <Grid item xs={12} sm={3}>
                                                        <Grid container>
                                                            <Grid
                                                                item
                                                                xs={12}
                                                                className="text-left grey"
                                                            >
                                                                Primary Contact
                                                            </Grid>
                                                            <Grid
                                                                item
                                                                xs={12}
                                                                className="grey2 font-boldest text-left"
                                                            >
                                                                {
                                                                    element.PrimaryContact
                                                                }
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item xs={12} sm={3}>
                                                        <Grid container>
                                                            <Grid
                                                                item
                                                                xs={12}
                                                                className="text-left grey"
                                                            >
                                                                Account Manager
                                                            </Grid>
                                                            <Grid
                                                                item
                                                                xs={12}
                                                                className="text-left grey2 font-boldest"
                                                            >
                                                                {
                                                                    element.AccountManager
                                                                }
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item xs={12} sm={3}>
                                                        {/* <Grid container>
                                                            <Grid
                                                                item
                                                                xs={12}
                                                                className="text-left grey"
                                                            >
                                                                Last Inspection
                                                            </Grid>
                                                            <Grid
                                                                item
                                                                xs={12}
                                                                className="text-left grey2 font-boldest"
                                                            >
                                                                {
                                                                    element.LastInspection
                                                                }
                                                            </Grid>
                                                        </Grid> */}
                                                    </Grid>
                                                    {/* <Grid item xs={12} sm={3}>
                                                        <Grid container>
                                                            <Grid
                                                                item
                                                                xs={12}
                                                                className="text-left grey"
                                                            >
                                                                Inspection
                                                                Frequency
                                                            </Grid>
                                                            <Grid
                                                                item
                                                                xs={12}
                                                                className="text-left grey2 font-boldest"
                                                            >
                                                                {
                                                                    element.InspectionFrequency
                                                                }
                                                            </Grid>
                                                        </Grid>
                                                    </Grid> */}
                                                </Grid>
                                            </Grid>
                                            {/* <Grid item xs={12}>
                                                <Grid container>
                                                    <Grid item xs={12} sm={3}>
                                                        <Grid container>
                                                            <Grid
                                                                item
                                                                xs={12}
                                                                className="text-left grey"
                                                            >
                                                                Square Footage
                                                            </Grid>
                                                            <Grid
                                                                item
                                                                xs={12}
                                                                className="text-left grey2 font-boldest"
                                                            >
                                                                {
                                                                    element.SquareFootage
                                                                }
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item xs={12} sm={3}>
                                                        <Grid container>
                                                            <Grid
                                                                item
                                                                xs={12}
                                                                className="text-left grey"
                                                            >
                                                                Monthly Billing
                                                            </Grid>
                                                            <Grid
                                                                item
                                                                xs={12}
                                                                className="text-left grey2 font-boldest"
                                                            >
                                                                {
                                                                    element.MonthlyBilling
                                                                }
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid> */}
                                        </Grid>
                                    </Grid>
                                </Grid>
                            ),
                        )}
                    </Paper>
                </Grid>
            </Grid>
            <Grid container style={{ padding: "0 0 2%" }}>
                <Grid item xs={9} md={6}>
                    <ButtonGroups
                        controlList={controlList}
                        handleDocList={handleDocList}
                        gridSpacing={2}
                        externalActive={activeList}
                    />
                </Grid>
            </Grid>
            {activeList === "Contracts" ? (
                contractInfo.length ? (
                    <MuiDatatables
                        title={""}
                        hideAddButton={true}
                        header={ContractSchema}
                        detail={[contractInfo, setcontractInfo]}
                        initRow={false}
                    />
                ) : (
                    <img src={NoContractSign} style={{ paddingTop: "4%",width: "95vh" }} />
                )
            ) : activeList === "Tickets" ? (
                ticketsDetailsList?.length  ?
                <MuiDatatables
                    title={""}
                    hideAddButton={true}
                    header={TicketsScheme}
                    detail={[ticketsDetailsList, setTicketsDetailsList]}
                    initRow={false}
                /> :  (
                    <img src={NoTicketSign} style={{ paddingTop: "4%",width: "95vh" }} />
                )
            ) : activeList === "QA Inspections" ? (
                <Grid item xs={12}>
                    {visitsInfo?.length ? (
                        <MuiDatatables
                            title={""}
                            hideAddButton={true}
                            header={VisitSchema}
                            detail={[visitsInfo, setvisitsInfo]}
                            initRow={false}
                        />
                    ) : (
                        <img
                            src={NoVisits}
                            alt="No Quality Assurance Inspections"
                            style={{ paddingTop: "4%",width: "95vh" }}
                        />
                    )}
                </Grid>
            ) : (
                <>
                    <Grid item xs={12}>
                        {companyDetailsTableData?.length ? (
                            <MuiDatatables
                                title={""}
                                hideAddButton={true}
                                header={CompanyTableDataSchema}
                                detail={[
                                    companyDetailsTableData,
                                    setCompanyDetailsTableData,
                                ]}
                                initRow={false}
                            />
                        ) : (
                            <img
                                src={NoContacts}
                                alt="No Contacts"
                                style={{ paddingTop: "4%",width: "95vh" }}
                            />
                        )}

                        {/* <Divider />
                        <Grid item xs={12}>
                            <Grid container justifyContent="flex-end">
                                <Grid item xs={2} style={{ padding: "2%" }}>
                                    <MUIButton
                                        label={"Export to Excel"}
                                        onClick={() => {}}
                                        type={"filled"}
                                        font={"small"}
                                    />
                                </Grid>
                            </Grid>
                        </Grid> */}
                    </Grid>
                </>
            )}
            <SwipeableDrawerComp
                drawerState={[openSideDraw, setOpenSideDraw]}
                header={"Edit Details"}
                headerColor={"grey2"}
                Child={<EditDetails />}
            />
            <SwipeableDrawerComp
                drawerState={[openAdduser, setOpenAdduser]}
                header={"User Details"}
                headerColor={"grey2"}
                Child={
                    <CompanySideDrawChild
                        sideDrawData={[companyProfile, setCompanyProfile]}
                    />
                }
            />
        </>
    );
};
const mapStateToProps = (state: any) => {
    return {
        currentContext: state.context?.userDefaults,
        userName: state.context?.name,
        selectedCustomer: state.selectedCustomer,
        selectedVendor: state?.selectedVendor,
        portalKey: state.portalKey,
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        setBreadcrumbsData: (data: any) => {
            dispatch(storeBreadcrumbs(data));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(BuildingDetailsCUS);
