import React, { useState, useEffect } from "react";
import Paper from "@material-ui/core/Paper";
import { Alert } from "@material-ui/lab";
import "./Carousel.scss";
// import Small from "Common/ErrorSvgs/Small";
// import Skeleton from "@material-ui/lab/Skeleton/Skeleton";
import { Grid } from "@material-ui/core";
import Icons from "Common/Icons/Icons";
import { Redirect } from "react-router";
import ErrorCard from "Components/Dashboard/DashbordCards/ErrorCard";
interface CarouselProps {
    ticketdetails: {
        ticketno: string;
        buildAdd: {
            name: string;
            id: string;
        };
        dateCre: string;
        notes: string;
        status: string;
    }[];
    errorMsg?: any;
    switchRoute: (e: string, det: any) => void;
}
const TicketCarousel = (props: any) => {
    const { ticketdetails, errorMsg, switchRoute } = props;
    const [active, setActive] = useState(0);
    const [redirect, setRedirect] = useState(false);
    const handleActive = (index: number) => {
        setActive(index);
    };
    useEffect(() => {
        if (ticketdetails.length) {
            const timer = setInterval(() => {
                setActive((prevState) => {
                    if (prevState + 1 === ticketdetails.length) {
                        return 0;
                    } else {
                        return prevState + 1;
                    }
                });
            }, 3000);
            return () => {
                clearInterval(timer);
            };
        }
    }, [ticketdetails]);

    if (redirect) {
        return (
            <Redirect
                to={{
                    pathname: "/app/*/TICKDET",
                    state: {
                        contractSelected: ticketdetails[active],
                    },
                }}
            />
        );
    }
    return (
        <>
            {ticketdetails?.length > 0 ? (
                <>
                    {
                        <>
                            <Grid item xs={12}>
                                <Grid
                                    container
                                    spacing={2}
                                    style={{ marginBottom: "3%" }}
                                >
                                    <Grid
                                        item
                                        xs={6}
                                        className="text-left font-boldest font-bigger"
                                        style={{ padding: "3% 1.5%" }}
                                    >
                                        Ticket Status ({ticketdetails?.length})
                                    </Grid>
                                    <Grid
                                        item
                                        xs={6}
                                        className="text-right font-boldest font-biggest "
                                        style={{ marginLeft: "-35px" }}
                                    >
                                        {ticketdetails.map(
                                            (element: any, index: any) => {
                                                return (
                                                    <span
                                                        className={`dotcolor ${
                                                            index === active
                                                                ? "dotgrey"
                                                                : ""
                                                        }`}
                                                        onClick={() =>
                                                            handleActive(index)
                                                        }
                                                    ></span>
                                                );
                                            },
                                        )}
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Paper
                                elevation={4}
                                    onClick={() => {
                                        setRedirect((prevState) => !prevState);
                                    }}
                                    // style={{
                                    //     minWidth: "300px",
                                    //     minHeight: "150px",
                                    //     maxWidth: "300px",
                                    //     maxHeight: "150px",
                                    // }}
                                    className="pointer"
                                >
                                    {ticketdetails[active] ? (
                                        <Grid container spacing={2}>
                                            {/* <Paper
                                            elevation={4}
                                                style={{
                                                    minWidth: "480px",
                                                    minHeight: "160px",
                                                    maxWidth: "480px",
                                                    maxHeight: "160px",
                                                }}
                                            > */}
                                                <Grid
                                                    container
                                                    spacing={2}
                                                    style={{ padding: "5%" }}
                                                >
                                                    <Grid item xs={12}>
                                                        <Grid container>
                                                            <Grid item xs={6}>
                                                                <Grid container>
                                                                    <Grid
                                                                        item
                                                                        xs={2}
                                                                    >
                                                                        <Icons Icon="ticket" />
                                                                    </Grid>
                                                                    <Grid
                                                                        item
                                                                        xs={10}
                                                                        className="text-left"
                                                                    >
                                                                        {
                                                                            ticketdetails[
                                                                                active
                                                                            ]
                                                                                ?.IssueID
                                                                        }
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                            <Grid item xs={6}>
                                                                <Grid container>
                                                                    <Grid
                                                                        item
                                                                        xs={2}
                                                                    >
                                                                        <Icons
                                                                            Icon={
                                                                                "BuildPic"
                                                                            }
                                                                        />
                                                                    </Grid>
                                                                    <Grid item>
                                                                        {
                                                                            ticketdetails[
                                                                                active
                                                                            ]
                                                                                .BuildingName
                                                                                ?.name
                                                                        }
                                                                        |{" "}
                                                                        {
                                                                            ticketdetails[
                                                                                active
                                                                            ]
                                                                                .BuildingName
                                                                                ?.id
                                                                        }
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Grid container>
                                                            <Grid item xs={6}>
                                                                <Grid container>
                                                                    <Grid
                                                                        item
                                                                        xs={2}
                                                                    >
                                                                        <Icons Icon="calenderPic" />
                                                                    </Grid>
                                                                    <Grid item>
                                                                        {
                                                                            ticketdetails[
                                                                                active
                                                                            ]
                                                                                .CreatedOn
                                                                        }
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Grid container>
                                                            <Grid item xs={7}>
                                                                <Grid container>
                                                                    <Grid
                                                                        item
                                                                        xs={2}
                                                                    >
                                                                        <Icons Icon="tickNotes" />
                                                                    </Grid>
                                                                    <Grid
                                                                        item
                                                                        xs={10}
                                                                        className="text-left"
                                                                    >
                                                                        {
                                                                            ticketdetails[
                                                                                active
                                                                            ]
                                                                                ?.Subject
                                                                        }
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                            <Grid
                                                                item
                                                                xs={3}
                                                            ></Grid>
                                                            <Grid item xs={2}>
                                                                {ticketdetails[
                                                                    active
                                                                ]?.Stage?.desc?.toLowerCase() ==
                                                                "open" ? (
                                                                    <Alert
                                                                        severity="success"
                                                                        icon={
                                                                            false
                                                                        }
                                                                        variant="outlined"
                                                                        style={{
                                                                            backgroundColor:
                                                                                "#EDFDF2",
                                                                            padding:
                                                                                "0% 25%",
                                                                            color:
                                                                                "#33415C",
                                                                        }}
                                                                    >
                                                                        {
                                                                            ticketdetails[
                                                                                active
                                                                            ]
                                                                                ?.Stage
                                                                                ?.desc
                                                                        }
                                                                    </Alert>
                                                                ) : (
                                                                    <Alert
                                                                        severity="error"
                                                                        icon={
                                                                            false
                                                                        }
                                                                        variant="outlined"
                                                                        style={{
                                                                            backgroundColor:
                                                                                "#FF6D6D0D",
                                                                            padding:
                                                                                "0% 20%",
                                                                            color:
                                                                                "#33415C",
                                                                        }}
                                                                    >
                                                                        {
                                                                            ticketdetails[
                                                                                active
                                                                            ]
                                                                                ?.Stage
                                                                                ?.desc
                                                                        }
                                                                    </Alert>
                                                                )}
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            {/* </Paper> */}
                                        </Grid>
                                    ) : (
                                        <></>
                                    )}
                                </Paper>
                            </Grid>
                        </>
                    }
                </>
            ) : (
                <>
                    <Grid
                        item
                        xs={12}
                        className="text-left font-boldest font-bigger"
                        style={{ padding: "3% 1%" }}
                    >
                        Ticket Status (0)
                    </Grid>
                    <Grid item xs={12} style={{ paddingTop: "2%" }} />
                    {ErrorCard(
                        "noTicketsError",
                        "Yay!",
                        "You have not faced any issues, So no tickets yet.",
                    )}
                </>
            )}
        </>
    );
};
export default TicketCarousel;
