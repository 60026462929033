import { useRef, useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import { BryntumCalendar, BryntumButton } from "@bryntum/calendar-react";
import "@bryntum/calendar/calendar.material.css";
import SwipeableDrawerComp from "Common/SideDrawer/SideDrawer";
import BackdropLoading from "Common/Backdrop/Backdrop";
import "react-loading-skeleton/dist/skeleton.css";
import useGetPosts from "ReactQuery/reactQuery";
import { connect } from "react-redux";
import dateFormatter from "Common/Utils/dateFormatter";
import HiddenDropdown from "Common/Dropdown/HiddenDropdownBlue";
import {
    BuildingComboCalendarQuery,
    CalendarViewDataQuery,
} from "ReactQuery/Query";
import Alert from "Common/Alerts/Alerts";
import CalendarDataCard from "./CalendarDataCard";
import { storeBreadcrumbs } from "stateManagement/actions/Common/breadcrumbs";

const Body = (props) => {
    const {
        currentContext,
        userName,
        selectedCustomer,
        portalKey,
        customerData,
        setBreadcrumbsData,
    } = props;
    setBreadcrumbsData([
        {
            value: "Calendar Schedule",
            href: "/app/*/CalendarSchedule",
        },
    ]);
    const calendarRef = useRef(null);
    const [eventRendered, setEventRendered] = useState(false);
    const [mode, setMode] = useState(portalKey ? "Week" : "Month");

    const [selectedBuilding, setSelectedBuilding] = useState("");
    const [buildingList, setBuildingList] = useState([{ desc: "", value: "" }]);
    const [selectedCustomerCombo, setSelectedCustomerCombo] = useState("");
    const [CustomerList, setCustomerList] = useState([{ desc: "", value: "" }]);
    const [calendarData, setCalendarData] = useState([]);
    const [apiData, setApiData] = useState({
        inputdate: "",
        callingtab: "WEEK",
        callingtype: "COMMON_SUM",
        sch_week: "",
        sch_month: "",
    });
    const [alert, setAlert] = useState(false);
    const [errr, seterr] = useState({
        code: 0,
        msg: "",
    });
    const [sidebarData, setSidebarData] = useState();
    const [sideBar, setSideBar] = useState(false);
    const handleErrorAlert = (code, message) => {
        Alert({
            statusCode: code,
            message: message,
            willClose: () => {
                setAlert(false);
                seterr({ code: 0, msg: "" });
            },
        });
    };
    useEffect(() => {
        setCalendarData([]);
    }, [mode]);

    const {
        data: BuildingComboData,
        error: BuildingComboDataError,
        isLoading: BuildingComboDataLoading,
    } = useGetPosts({
        name: `BuildingComboCalendar`,
        query: BuildingComboCalendarQuery,
        variables: {
            entity: "ow_get_assgn_cust_buil_lod_api_entity",
            ou: currentContext?.ouId,
            service: "",
            User: userName,
            languageId: currentContext?.langId,
            suppliercode: portalKey
                ? customerData?.supplierCode
                : selectedCustomer?.customerCode,
            callingfrom: "BUILDING",
            merrorid: "0",
        },
    });
    useEffect(() => {
        if (BuildingComboData?.BuildingComboCalendar) {
            setSelectedBuilding(
                BuildingComboData?.BuildingComboCalendar[0].buildingname,
            );
            setBuildingList(
                BuildingComboData?.BuildingComboCalendar.map((element) => {
                    return {
                        desc: element.buildingname,
                        value: element.buildingname,
                    };
                }),
            );
        }
    }, [BuildingComboData]);

    useEffect(() => {
        if (BuildingComboDataError && !BuildingComboDataLoading) {
            const data = JSON.parse(JSON.stringify(BuildingComboDataError));
            const errmsg = data?.response?.errors[0].message;
            seterr({
                code: 400,
                msg: errmsg,
            });
            setAlert(true);
        }
    }, [BuildingComboDataError, BuildingComboDataLoading]);

    const {
        data: CustomerComboData,
        error: CustomerComboDataError,
        isLoading: CustomerComboDataLoading,
    } = useGetPosts({
        name: `CustomerComboCalendar`,
        query: BuildingComboCalendarQuery,
        variables: {
            entity: "ow_get_assgn_cust_buil_lod_api_entity",
            ou: currentContext?.ouId,
            service: "",
            User: userName,
            languageId: currentContext?.langId,
            suppliercode: portalKey
                ? customerData?.supplierCode
                : selectedCustomer?.customerCode,
            callingfrom: "Customer",
            merrorid: "0",
        },
    });

    useEffect(() => {
        if (CustomerComboData?.BuildingComboCalendar) {
            setSelectedCustomerCombo(
                CustomerComboData?.BuildingComboCalendar[0].buildingname,
            );
            setCustomerList(
                CustomerComboData?.BuildingComboCalendar.map((element) => {
                    return {
                        desc: element.buildingname,
                        value: element.buildingname,
                    };
                }),
            );
        }
    }, [CustomerComboData]);

    useEffect(() => {
        if (CustomerComboDataError && !CustomerComboDataLoading) {
            const data = JSON.parse(JSON.stringify(CustomerComboDataError));
            const errmsg = data?.response?.errors[0].message;
            seterr({
                code: 400,
                msg: errmsg,
            });
            setAlert(true);
        }
    }, [CustomerComboDataError, CustomerComboDataLoading]);

    const {
        data: CalendarViewData,
        error: CalendarViewDataError,
        isLoading: CalendarViewDataLoading,
    } = useGetPosts({
        name: `CalendarViewCalendar_${selectedBuilding}_${apiData.inputdate}_${
            apiData.callingtab
        }_${apiData.callingtype}_${apiData.sch_week}_${apiData.sch_month}_${
            portalKey
                ? customerData?.supplierCode
                : selectedCustomer?.customerCode
        }_${userName}`,
        query: CalendarViewDataQuery,
        variables: {
            entity:portalKey? "ow_get_cust_cal_sch_api_entity": "ow_get_cal_sch_api_entity",
            ou: currentContext?.ouId,
            service: "",
            User: userName,
            languageId: currentContext?.langId,
            suppliercode: portalKey
                ? customerData?.supplierCode
                : selectedCustomer?.customerCode,
            custname: selectedCustomerCombo,
            buildingname: selectedBuilding,
            itemdesc: "",
            inputdate: apiData.inputdate
                ? apiData.inputdate
                : dateFormatter("sqlDate", new Date()),
            callingtab: apiData.callingtab,
            callingtype: apiData.callingtype,
            sch_week: apiData.sch_week,
            sch_month: apiData.sch_month,
            merrorid: "0",
        },
    });

    useEffect(() => {
        if (CalendarViewData?.CalendarView) {
            console.log(`Building`, CalendarViewData?.CalendarView);
            if (apiData.callingtype === "COMMON_SUM")
                setCalendarData(
                    CalendarViewData?.CalendarView.map((element, index) => {
                        return {
                            id: element.id,
                            removeField: "time",
                            startDate: element?.startDate,
                            endDate: element?.endDate,
                            // allDay: true,
                            name: `${element.count_service} -- ${element.buildingname} \n${element.customername}`,
                            resourceId: element.customername,
                            eventColor: "violet",
                        };
                    }),
                );
            else if (apiData.callingtype === "MONTH_SUM")
                setCalendarData(
                    CalendarViewData?.CalendarView.map((element) => {
                        return {
                            id: element.id,
                            startDate: element.endDate,
                            endDate: element.endDate,
                            name: `${element.count_service} -- ${element.buildingname}`,
                            resourceId: element.customername,
                            eventColor: "violet",
                        };
                    }),
                );
            else if (apiData.callingtype === "DAY_SUM")
                setCalendarData(
                    CalendarViewData?.CalendarView.map((element, index) => {
                        console.log("inside day", element);
                        return {
                            id: element.id,
                            startDate: element.startDate,
                            endDate: element.endDate,
                            // allDay: true,
                            name: `${element.itemdesc} | ${element.buildingname}`,
                            resourceId: element.buildingname,
                            eventColor: "violet",
                        };
                    }),
                );
            else if (
                apiData.callingtype === "DAY_DET" ||
                apiData.callingtype === "WEEK_DET"
            ) {
                setSidebarData((prevState) => ({
                    ...prevState,
                    clickedData: CalendarViewData?.CalendarView,
                }));
                setSideBar(true);
            }
        }
    }, [CalendarViewData]);

    useEffect(() => {
        if (CalendarViewDataError && !CalendarViewDataLoading) {
            const data = JSON.parse(JSON.stringify(CalendarViewDataError));
            const errmsg = data?.response?.errors[0].message;
            // seterr({
            //     code: 400,
            //     msg: errmsg,
            // });
            // setAlert(true);
            console.log("error in calender",errmsg);
        }
    }, [CalendarViewDataError, CalendarViewDataLoading]);

    const handleButtonClick = (mode, callingtab, callingtype) => {
        calendarRef.current.calendarInstance.mode = mode;

        setApiData((prevState) => ({
            ...prevState,
            inputdate: dateFormatter(
                "sqlDate",
                new Date(calendarRef.current.calendarInstance.date),
            ),
            callingtab: callingtab,
            callingtype: callingtype,
        }));
    };

    const weekNumber = (currentDate) => {
        var startDate = new Date(currentDate.getFullYear(), 0, 1);
        var days = Math.floor(
            (currentDate - startDate) / (24 * 60 * 60 * 1000),
        );
        var weekNumber = Math.ceil(days / 7);
        return `WEEK ${weekNumber}`;
    };
    const getYear = (currentDate) => {
        var monthNames = [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
        ];
        var year = currentDate.getFullYear();
        var month = monthNames[currentDate.getMonth()];
        return `${month} ${year}`;
    };
    useEffect(() => {
        const timeAxisElements = document?.getElementsByClassName(
            "b-timeaxis-container",
        );
        for (let i = 0; i < timeAxisElements.length; i++) {
            timeAxisElements[i].style.visibility = "hidden";
        }
        const eventHeaderElements = document?.getElementsByClassName(
            "b-event-time",
        );
        if (mode === "Month") {
            const weekNumElements = document?.getElementsByClassName(
                "b-week-num",
            );
            for (let i = 0; i < weekNumElements.length; i++) {
                weekNumElements[i].style.visibility = "hidden";
            }
        }
        setTimeout(() => {
            for (let i = 0; i < eventHeaderElements.length; i++) {
                eventHeaderElements[i].style.display = "none";
            }
        }, 100);
    }, [CalendarViewDataLoading, mode]);
    const calendarConfig = {
        sidebar: null,
        allowOverlay: false,
        modeDefaults: {
            // eventHeight: 550,
        },
        readOnly: true,
        mode: portalKey ? "week" : "month",
        modes: {
            agenda: null,
            year: null,
            day: {
                eventRenderer({ eventRecord, resourceRecord, tplData }) {
                    setEventRendered(true);
                },
            },
            week: {
                eventRenderer({ eventRecord, resourceRecord, tplData }) {
                    setEventRendered(true);
                },
            },
        },
        tbar: {
            items: {
                dayMode: {
                    type: "widget",
                    weight: 100,

                    html: (
                        <BryntumButton
                            cls={mode === "Day" ? "b-raised" : ""}
                            text={"day"}
                            onClick={(event) => {
                                setMode("Day");
                                handleButtonClick("day", "DAY", "DAY_SUM");
                            }}
                        />
                    ),
                },
                weekMode: {
                    type: "widget",
                    weight: 100,
                    html: (
                        <BryntumButton
                            cls={mode === "Week" ? "b-raised" : ""}
                            text={"Week"}
                            onClick={(event) => {
                                setMode("Week");
                                handleButtonClick("week", "WEEK", "COMMON_SUM");
                            }}
                        />
                    ),
                },
                calendarMode: {
                    type: "widget",
                    weight: 100,
                    html: (
                        <BryntumButton
                            cls={mode === "Month" ? "b-raised" : ""}
                            text={"month"}
                            onClick={(event) => {
                                setMode("Month");
                                handleButtonClick(
                                    "month",
                                    "WEEK",
                                    "COMMON_SUM",
                                );
                            }}
                        />
                    ),
                },
                prevButton: {
                    onClick: "up.shiftPrevious",
                    cls: "b-cal-nav-item b-borderless b-transparent",
                    icon: "b-icon-previous",
                    weight: 300,
                    ref: "prevButton",
                },
                nextButton: {
                    onClick: "up.shiftNext",
                    cls: "b-cal-nav-item b-borderless b-transparent",
                    icon: "b-icon-next",
                    weight: 400,
                    ref: "nextButton",
                },
                viewDescription: {
                    cls: "b-calendar-view-desc",
                    type: "widget",
                    flex: "",
                    html: " ",
                    overflowable: "none",
                    weight: 500,
                    ref: "viewDescription",
                },
                spacer: {
                    type: "widget",
                    cls: "b-toolbar-fill",
                    weight: 600,
                    ref: "spacer",
                },
                CustomerID: {
                    type: "widget",
                    html: (
                        <HiddenDropdown
                            label={""}
                            id={"buildingID"}
                            value={selectedCustomerCombo}
                            list={CustomerList}
                            handleChange={(event) => {
                                setSelectedCustomerCombo(event.target.value);
                            }}
                            width={"100%"}
                        />
                    ),
                },
                buildingID: {
                    type: "widget",
                    html: (
                        <HiddenDropdown
                            label={""}
                            id={"buildingID"}
                            value={selectedBuilding}
                            list={buildingList}
                            handleChange={(event) => {
                                setSelectedBuilding(event.target.value);
                            }}
                            width={"100%"}
                        />
                    ),
                },
            },
        },
        features: {
            eventTooltip: {
                disabled: true,
                tools: { delete: false },
            },
            eventEdit: {
                disabled: true,
            },
        },
        SyncOnload: true,
    };

    return (
        <Grid container style={{ minWidth: "1200px" }}>
            {alert ? handleErrorAlert(errr.code, errr.msg) : <></>}
            <BackdropLoading
                openStates={
                    CalendarViewDataLoading ||
                    BuildingComboDataLoading ||
                    CustomerComboDataLoading
                }
            />
            <Grid item xs={12}>
                <BryntumCalendar
                    ref={calendarRef}
                    timeAxis={false}
                    timeRange={false}
                    events={calendarData}
                    {...calendarConfig}
                    maxHeight="650px"
                    onEventClick={(renderData) => {
                        setSidebarData((prevState) => ({
                            ...prevState,
                            clickedDate: dateFormatter(
                                "sqlDate",
                                new Date(renderData.date),
                            ),
                        }));
                        if (mode === "Day") {
                            setApiData((prevState) => ({
                                ...prevState,
                                callingtab: "DAY",
                                callingtype: "DAY_DET",
                                sch_week: "",
                                inputdate: dateFormatter(
                                    "sqlDate",
                                    new Date(renderData.date),
                                ),
                            }));
                        } else {
                            setApiData((prevState) => ({
                                ...prevState,
                                callingtab: "WEEK",
                                callingtype: "WEEK_DET",
                                sch_week: weekNumber(new Date(renderData.date)),
                                inputdate: dateFormatter(
                                    "sqlDate",
                                    new Date(renderData.date),
                                ),
                            }));
                        }
                    }}
                    onDayNumberClick={(renderData) => {
                        console.log("onDayNumberClick", renderData.date);
                        setMode("Day");
                        setApiData((prevState) => ({
                            ...prevState,
                            inputdate: dateFormatter(
                                "sqlDate",
                                new Date(renderData.date),
                            ),
                            callingtab: "DAY",
                            callingtype: "DAY_SUM",
                        }));
                    }}
                />
            </Grid>
            <SwipeableDrawerComp
                drawerState={[sideBar, setSideBar]}
                header={sidebarData?.clickedDate}
                headerColor={"grey2"}
                customPadding={{ margin: "4% 3% 1% 3%" }}
                Child={
                    <CalendarDataCard clickedData={sidebarData?.clickedData} />
                }
            />
        </Grid>
    );

    // return <img src={WIP} style={{ maxWidth: "100%", marginTop: "30px " }} />;
};
const mapStateToProps = (state) => {
    return {
        currentContext: state.context?.userDefaults,
        userName: state.context?.name,
        selectedCustomer: state.selectedCustomer,
        customerData: state.portalKey
            ? state?.selectedVendor
            : state?.selectedCustomer,
        portalKey: state.portalKey,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        setBreadcrumbsData: (data) => {
            dispatch(storeBreadcrumbs(data));
        },
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Body);
