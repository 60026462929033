import { Divider, Grid, Paper } from "@material-ui/core";
import Icons from "Common/Icons/Icons";
import { useEffect, useState } from "react";

interface DetArr {
    desc: string;
    value: string;
}

interface AssignmentCardsProps {
    data: DetArr[];
    // setAssignmentSelected: React.Dispatch<React.SetStateAction<string>>;
    handleSelect: (data: any) => void;
}

const AssignmentCards = (props: AssignmentCardsProps) => {
    const { data, handleSelect } = props;
    // setAssignmentSelected,
    const [active, setActive] = useState("");
    const [detail, setDetail] = useState<DetArr[]>([]);

    useEffect(() => {
        if (data?.length) {
            setDetail(data);
            setActive(data[0]?.value);
        }
    }, [data]);

    return (
        <Paper elevation={4} style={{ minHeight: "100%", maxHeight: "100%" }}>
            <Grid
                container
                spacing={3}
                alignItems="center"
                className="text-left font-boldest"
                style={{ marginBottom: "100%", padding: "10% 2%" }}
            >
                {/* <Grid
                    item
                    xs={1}
                    sm={1}
                    className="margin-top-10-per padding-left-15-per"
                >
                    <Icons Icon="building" />
                </Grid> */}
                {/* <Grid item xs={8} sm={8} className="margin-top-10-per ">
                    <span style={{ textAlign: "left" }}>Client Companies</span>
                </Grid> */}
                {/* <Grid item xs={12} sm={12}>
                    <Divider /> 
                </Grid> */}
                {detail.map((element: DetArr, index: number) => {
                    return (
                        <Grid
                            item
                            xs={12}
                            sm={12}
                            className={`padding-left-15-per pointer`}
                            key={`${index}_${element?.value}`}
                        >
                            <span
                                className={`${
                                    active === element?.value
                                        ? "card-active"
                                        : ""
                                }`}
                                onClick={() => {
                                    setActive(element?.value);
                                    // setAssignmentSelected(element?.value);
                                    handleSelect(element);
                                }}
                            >
                                {" "}
                                {element?.desc}
                            </span>
                        </Grid>
                    );
                })}
            </Grid>
        </Paper>
    );
};

export default AssignmentCards;
