import { Grid } from "@material-ui/core";
import MUIButton from "Common/Button/Button";
import Dropdown from "Common/Dropdown/Dropdown";
import React, { useEffect, useState } from "react";
import useGetPosts from "ReactQuery/reactQuery";
import FranchiseCard from "./FranchiseCard";

const FranchiseeChild = () => {
    const [franchiseDocument,setFranchiseDocument] = useState<any>([{
        name:"Finance Statement",
        date: "March 2020"
    }])
    const controlList = [
        {
            desc: "AU",
            value: "AU",
        },
        {
            desc: "Assignment/Refusals/Fulfillment",
            value: "Assignment/Refusals/Fulfillment",
        },
        {
            desc: "Amortization",
            value: "Amortization",
        },
    ];

    
    
    const {
        data: FranchiseeDocumentData,
        error: FranchiseeDocumentDataError,
        isLoading: FranchiseeDocumentDataLoading,
    } = useGetPosts({
        name: "FranchiseeDocumentData",
        query: "FranchiseeDocumentData",
        variables: {
            
        },
    });
    useEffect(() => {
        if (FranchiseeDocumentData?.DeductionList) {
            setFranchiseDocument(FranchiseeDocumentData?.DeductionList);
        }
    }, [FranchiseeDocumentData]);
    
    const [downloadfranchiseData, setDownloadFranchiseData] = useState<any>({
        year: "",
        month: "",
    });
    const handleChange = (e: any) => {
        const name = e.target?.name;
        const value = e.target?.value;
        setDownloadFranchiseData((prevState: any) => ({
            ...prevState,
            [name]: value,
        }));
    };
    return (
        <Grid container xs={12} spacing={2} justifyContent="center" style={{ minWidth: "450px",marginTop:'2%' }}>
            <Grid item xs={4}>
                <Dropdown
                    label={"Year"}
                    id={"year"}
                    value={downloadfranchiseData?.year}
                    list={controlList}
                    handleChange={handleChange}
                    width={"100%"}
                />
            </Grid>
            <Grid item xs={4}>
                <Dropdown
                    label={"Month"}
                    id={"month"}
                    value={downloadfranchiseData?.month}
                    list={controlList}
                    handleChange={handleChange}
                    width={"100%"}
                />
            </Grid>
            <Grid item xs={3}>
                <MUIButton
                    label={"Download"}
                    onClick={() => {}}
                    type="filled"
                    font="font-smaller font-bold"
                />
            </Grid>
            <Grid item xs={12}>
                {
                    franchiseDocument?.map((ele:any)=>{
                        return (
                            <FranchiseCard {...ele}/>
                        )
                    })
                }
            </Grid>
        </Grid>
    );
};

export default FranchiseeChild;
