import { useEffect, useState } from "react";
import { Route, Switch } from "react-router-dom";
// import NavMenuSchema from "../subComponents/NavMenuSchema";
import { MenuCategorySchema } from "../subComponents/types";
import { connect } from "react-redux";
// import ApplicationLayout from "../Components/ApplicationLayout";
import NavMenuSchemaCUS from "../subComponents/NavMenuSchema";
import NavMenuSchemaVEN from "../subComponents/NavMenuSchemaVEN";

const Category = ({ match }: any) => {
    let portalKey: any;
    const [navMenuSchema, setNavMenuSchema] = useState(NavMenuSchemaCUS);
    if (JSON.parse(localStorage.getItem("state") || "")) {
        portalKey = JSON.parse(localStorage.getItem("state") || "")?.portalKey;
    }
    useEffect(() => {
        // if (portalKey) {
            // {
                setNavMenuSchema(
                    portalKey ? NavMenuSchemaVEN : NavMenuSchemaCUS,
                );
                console.log("portalKey in main route", portalKey);
            // }
        // }
    }, [portalKey]);

    const category = navMenuSchema.find((category: MenuCategorySchema) => {
        return match.params.catRoute === category.route;
    });

    return (
        <>
            {/* {match.isExact && <>{category?.child ? <category.child /> : <></>}</>} */}
            {category?.child ? <category.child /> : <></>}
            <Switch>
                <Route path={`${match.path}/:catRoute`} component={Category} />
            </Switch>
        </>
    );
};

/**
 * Main route to render route paths for nav bar menu listss
 */

const Header = () => {
    return <></>;
};
const MainRoutes = (props: any) => {
    // const { session } = props;
    // const [redirect, setRedirect] = useState(false);
    // const [login, setLogin] = useState(session ? false : true);

    return (
        <Switch>
            <Route exact path={`/app`} component={Header}></Route>
            <Route path={`/:catRoute`} component={Category}></Route>
        </Switch>
    );
};
const mapStateToProps = (state: any) => {
    return {
        portalKey: state.portalKey,
    };
};
export default connect(mapStateToProps)(MainRoutes);
