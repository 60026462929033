import { Grid } from "@material-ui/core";

import DocumentCardsCert from "Common/DocumentCards/DocumentCardsCert";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
// import { guidanceDocQuery } from "ReactQuery/Query";
import useGetPosts from "ReactQuery/reactQuery";
import Configuration from "Configuration";
import ColoredCard from "Common/DocumentCards/ColoredCard";
import { lookup as mimeconverter } from "mime-types";
interface FilesProps {
    fileType: string;
    desc: string;
    fileName: string;
    fileExtension: string;
    link?: string;
    serverFileName?:String;
  
}

// interface DocGroupsProps {
//   docList: FilesProps[];
//   info: {
//     desc: string;
//     color: string;
//     border: string;
//   };
// }

interface DocGroupsProps {
    activeList: string;
    sessionId:any;
    currentContext:any;
    docflag: string;
    FileDetailsCardList:[any];
    RefetchAfterDelete?:any;
}



const DocGroups = (props: DocGroupsProps) => {
    const { activeList,  docflag,FileDetailsCardList,currentContext,sessionId,RefetchAfterDelete } =
        props;
        const [docList, setDocList] = useState<FilesProps[]>([]);
    useEffect(() => {
        setDocList(FileDetailsCardList)
    }, [FileDetailsCardList]);
    const [info, setInfo] = useState({
        desc: "",
        color: "",
        border: "",
    });
    const [fileLoading, setFileLoading] = useState(false);
    const handleDownload = async (fileName: any, relativePath: string,preView:boolean) => {
        // handleBackdropClose(true);
        setFileLoading((prev) => !prev);

        const responseFile = await fetch(
            `${Configuration.getConfiguration().getAppAPILocation()}/api/fileManagement/${fileName}/${relativePath}`,
            {
                method: "GET",
                headers: {
                    authorization: sessionId,
                    ou: currentContext.ouId,
                    role: currentContext.roleName,
                    lang: currentContext.langId,
                },
                redirect: "follow",
            },
        );
        if (responseFile.status === 200) {
            const responseBlob = await responseFile.blob();
            const fileblob = responseBlob.slice(
                0,
                responseBlob.size,
                mimeconverter(fileName).toString(),
            );
            console.log("Downoad Blob =>", fileblob);
            const file: any = fileblob as File;
            file.name = fileName;
            console.log("Downoad File =>", file);
            if(preView){

                URL.createObjectURL(file);
                setFileLoading((prev) => !prev);
                window.open(URL.createObjectURL(file), "_blank");
            }else{
                // Create a temporary anchor element
                const anchor = document.createElement('a');
                anchor.href = URL.createObjectURL(file);
                anchor.download = fileName; // Set the file name for download
                anchor.click(); // Trigger the click event to initiate download
            }


            return { fileData: file };
        } else {
            setFileLoading((prev) => !prev);
        }
    };

    // const { data: guideData, error: guideError } = useGetPosts({
        
    //     name: `getGuidanceDocs_${activeList}_${}_${}_${docflag}`,
    //     query:"" || "guidanceDocQuery",
    //     variables: {
          
    //         docflag: docflag,
    //     },
    //     header: {
    //         lang: defaultContext?.langId,
    //         ou: defaultContext?.ouId,
    //         role: defaultContext?.roleName,
    //     },
    //     skip:true,
    // });

    // useEffect(() => {
    //     //     if (guideData?.GetFFStmtDetails) {
    //     //         setDocList(guideData?.GetFFStmtDetails?.docs);
    //     //         setInfo(guideData?.GetFFStmtDetails?.info);

    //     // }
    //     if (guideData?.fFStmntDetails) {
    //         let docs = guideData?.fFStmntDetails?.fFSttmtDlt?.map(
    //             (element: any) => {
    //                 return {
    //                     fileName: element.ar_adextrachar1.split(".")[0],
    //                     fileType: element.ar_adextrachar1.split(".")[1],
    //                     link: element.ar_adextrachar1,
    //                     desc: element.ar_adextrachar2,
    //                 };
    //             },
    //         );
    //         setDocList(docs);
    //         let info = {
    //             desc: "",
    //             color: "",
    //             border: "",
    //         };
    //         setInfo(info);
    //     }
    // }, [guideData]);



    return (
        <>
            <Grid item xs={12}>
                <ColoredCard
                    desc={`${docList.length} Documents`}
                    highlight={info.desc}
                    color={info.color}
                    border={info.border}
                    // Icon={"download"}
                    Icon={""}
                />
            </Grid>
            {docList.map((element: FilesProps, index: number) => {
                return (
                    <Grid item xs={12} sm={6}>
                        <DocumentCardsCert
                            fileType={element.fileExtension}
                            desc={element.fileType}
                            fileName={element.fileName}
                            // link={element?.link}
                            download={handleDownload}
                            serverFileName={element?.serverFileName}
                            RefetchAfterDelete={RefetchAfterDelete}
                        />
                    </Grid>
                );
            })}
        </>
    );
};

const mapStateToProps = (state: any) => {
    return {
        defaultContext: state?.defaultContext,
        currentContext: state.context?.userDefaults,
        sessionId: state.sessionId,
    };
};

export default connect(mapStateToProps)(DocGroups);

// export default DocGroups;
