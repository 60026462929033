const OrderSuppliesDetailsTableDataQuery = `
query Query(
     $ouId:Int,
     $service:String
     $user:String
     $langId:Int,
     $orderNo:String
){
OrderSuppliesDetailsTableDataCus(
     ouId:$ouId,
     service:$service
     user:$user
     langId:$langId,
     orderNo:$orderNo
){
ItemCode
Description
UM
Amount
Quantity
OAmount
Actions
}
}
`;

export default OrderSuppliesDetailsTableDataQuery;
