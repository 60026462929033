const ContractsQuerySV = `
mutation(
  $entity: String
  $ou: Int
  $service: String
  $User: String
  $languageId: Int
  $supplierCode:String
  $suppliername:String
  $status:String
  $merrorid:String 
  $toDate: String
  $fromDate: String
  $contractNo: String
){
    GetContractsSV(
      
      entity:$entity
      ou:$ou
      service:$service
      User:$User
      languageId:$languageId
      supplierCode:$supplierCode
      suppliername:$suppliername
      status:$status
       merrorid:$merrorid 
       toDate: $toDate
  fromDate: $fromDate
  contractNo: $contractNo
    ) {
        Contract
        AccountNumber
        Vendor
        Region
        ContractR
        PurchaseDate
        StartEndDate
        PackagePurchased
        VolumePurchased
        TotalCost
        TargetFulfillment
        VolumeOwned
  }
}
  `;

export default ContractsQuerySV;
