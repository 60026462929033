import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import "@sweetalert2/theme-material-ui/material-ui.css";
import "./Alert.scss"
const AlertSwal = withReactContent(Swal);
interface AlertProps {
    fileName: string;
    
    onConfirm?: () => void;
    willClose?: () => void;
}
const MLActionAlert = (props: AlertProps) => {
    const {fileName,onConfirm} = props;
    const icon = "warning"
    const title ='Are you sure !';
    const text = `You want to Delete ${fileName?? ""} ?`;
    const handleConfirm=()=>{
      if (onConfirm) {
        onConfirm();
      }
      Swal.fire(
        'Deleted!',
        'File has been deleted successfully.',
        'success'
        )
    }
    const renderedAlert = AlertSwal.fire({
        icon,
        title,
        text,
        showCancelButton: true,
        confirmButtonColor: '#d33',
        cancelButtonColor: '#3085d6',
        confirmButtonText: 'Yes',
        cancelButtonText:"No",
        customClass:{
          confirmButton:"btn-white",
          cancelButton:"btn-white"
        },
        willClose: props.willClose,
        

    }).then((result) => {

      if (result.isConfirmed) {
        handleConfirm();
      }
      });
    return <>{renderedAlert}</>;
};
export default MLActionAlert;