import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import Loading from "Common/Loading";
import Configuration from "Configuration";
import {
    Backdrop,
    CircularProgress,
    makeStyles,
    Theme,
} from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: "#fff",
    },
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
    },
}));

class LoginPage extends Component<any, any> {
    formRef: React.RefObject<unknown>;
    constructor(props: any) {
        super(props);
        this.state = {
            message: "",
            messageType: "success",
            form: {},
            loading: false,
            defaultTenant: "",
            session: props.session,
            redirectToApp: false,
        };

        this.formRef = React.createRef();
    }

    componentDidMount() {
        if (!this.state.session || typeof this.state.session === "undefined") {
            const link = `${Configuration.getConfiguration().getAppAPILocation()}/api/auth/login`;
            // setTimeout(() => {
            //   window.location.assign(link);
            // }, 2000);
            window.location.assign(link);
        } else {
            this.setState({ redirectToApp: true });
        }
    }

    render() {
        if (this.state.redirectToApp)
            //<Redirect to={`/home?sId=${this.state.session}`} />;
            return <Redirect to="/app/*/Dashboard" />;
        return (
            <Backdrop
                style={{
                    zIndex: 1,
                    color: "#fff",
                }}
                open={true}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        );
    }
}

//Redux
const mapStateToProps = (state: any) => {
    return {
        session: state.sessionId,
    };
};

export default connect(mapStateToProps)(LoginPage);
