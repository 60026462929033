import { Grid, Box, Paper, Divider } from "@material-ui/core";
import Alert from "Common/Alerts/Alerts";
import BackdropLoading from "Common/Backdrop/Backdrop";
import MUIButton from "Common/Button/Button";
import TextFields from "Common/TextField/TextField";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { DirectDepositInfoVenQuery } from "ReactQuery/Query";
import useGetPosts from "ReactQuery/reactQuery";

const UploadInfoSideDraw = (props: any) => {
    const { getDepositInfoDatas, currentContext, selectedVendor,userReadOnlyAccess } = props;
    const [isEdit, setIsEdit] = useState<any>(false);
    const [saveDepositinfo, setsaveDepositinfo] = useState<any>(true);
    const [alert, setAlert] = useState(false);
    const [errr, seterr] = useState({
        code: 0,
        msg: "",
    });
    const [postDirectDepositData, setPostDirectDepositData] = useState<any>({
        ABA: "",
        AccountName: getDepositInfoDatas?.SupplierLegalName,
        AccountNumber: getDepositInfoDatas?.AccountNumber,
        Bank_name: getDepositInfoDatas?.BankName,
        BICNoSwiftNo: getDepositInfoDatas?.SupplierCode,
        BranchName: getDepositInfoDatas?.BankName,
        CityofBank: "",
        Supplier_name: getDepositInfoDatas?.SupplierName,
    });

    const handleChange = (e: any) => {
        setPostDirectDepositData((prevState: any) => ({
            ...prevState,
            [e?.target?.name]: e?.target?.value,
        }));
    };

    const {
        data: PostDirectDeposit,
        error: PostDirectDepositError,
        isLoading: PostDirectDepositLoading,
    } = useGetPosts({
        name: "PostDirectDeposit_Query",
        query: DirectDepositInfoVenQuery,
        variables: {
            suppcode: selectedVendor?.supplierName,
            createDepositDirectInfo: {
                ABA: postDirectDepositData?.ABA,
                AccountName: postDirectDepositData?.AccountName,
                AccountNumber: postDirectDepositData?.AccountNumber,
                Bank_name: postDirectDepositData?.Bank_name,
                BICNoSwiftNo: postDirectDepositData?.BICNoSwiftNo,
                BranchName: postDirectDepositData?.BranchName,
                CityofBank: postDirectDepositData?.CityofBank,
                Supplier_name: postDirectDepositData?.Supplier_name,
            },
        },
        header: {
            ou: currentContext?.ouId,
            role: currentContext?.roleName,
            lang: currentContext?.langId,
        },
        skip: saveDepositinfo,
    });

    useEffect(() => {
        if (PostDirectDeposit?.DirectDepositInfo) {
            seterr({
                code: 200,
                // msg: `${PostDirectDeposit?.DirectDepositInfo.errrormsg} -- ${PostDirectDeposit?.DirectDepositInfo.TranNo}`,
               msg: `Deposit Details Updated Sucessfully`,
            });
            setsaveDepositinfo(true);
        }
    }, [PostDirectDeposit]);

    useEffect(() => {
        if (PostDirectDepositError && !PostDirectDepositLoading) {
            const data = JSON.parse(JSON.stringify(PostDirectDepositError));
            const errmsg = data?.response?.errors[0].message;
            seterr({
                code: 400,
                msg: errmsg,
            });

            setsaveDepositinfo(true);
        }
    }, [PostDirectDepositError, PostDirectDepositLoading]);

    console.log("getDepositInfoDatas", getDepositInfoDatas);
    return (
        <>
            <BackdropLoading openStates={PostDirectDepositLoading} />
            {!isEdit ? (
                <Paper elevation={4} style={{ backgroundColor: "#f2f2f3" }}>
                    <Grid
                        container
                        style={{
                            minWidth: "550px",
                            maxWidth: "550px",
                            padding: "4%",
                        }}
                    >
                        <Grid item xs={12}>
                            <Grid container spacing={2}>
                                <Grid item xs={4}>
                                    <Box className="grey font-smaller">
                                        Supplier Code
                                    </Box>
                                    <Box className="font-smaller font-bolder">
                                        {getDepositInfoDatas?.SupplierCode}
                                    </Box>
                                </Grid>
                                <Grid item xs={4}>
                                    <Box className="grey font-smaller">
                                        Supplier Name
                                    </Box>
                                    <Box className="font-smaller font-bolder">
                                        {getDepositInfoDatas?.SupplierName}
                                    </Box>
                                </Grid>
                                <Grid item xs={4}>
                                    <Box className="grey font-smaller">
                                        Bank Name
                                    </Box>
                                    <Box className="font-smaller font-bolder">
                                        {getDepositInfoDatas?.BankName}
                                    </Box>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} style={{ marginTop: "4%" }}>
                            <Grid container spacing={2}>
                                <Grid item xs={4}>
                                    <Box className="grey font-smaller">
                                        Account Name
                                    </Box>
                                    <Box className="font-smaller font-bolder">
                                        {getDepositInfoDatas?.SupplierLegalName}
                                    </Box>
                                </Grid>
                                <Grid item xs={4}>
                                    <Box className="grey font-smaller">
                                        Account Number
                                    </Box>
                                    <Box className="font-smaller font-bolder">
                                        {selectedVendor?.admin.toLowerCase() ===
                                        "y"
                                            ? getDepositInfoDatas?.AccountNumber
                                            : `${getDepositInfoDatas?.AccountNumber?.substring(
                                                  0,
                                                  getDepositInfoDatas
                                                      ?.AccountNumber?.length -
                                                      4,
                                              ).replace(
                                                  /\d/g,
                                                  "*",
                                              )}${getDepositInfoDatas?.AccountNumber?.substring(
                                                  getDepositInfoDatas
                                                      ?.AccountNumber?.length -
                                                      4,
                                              )}`}
                                    </Box>
                                </Grid>
                                <Grid item xs={4}></Grid>
                            </Grid>
                        </Grid>

                        <Grid
                            container
                            style={{
                                height: 125,
                                justifyContent: "center",
                                alignItems: "center",
                                position: "absolute",
                                bottom: 0,
                                right: 0,
                            }}
                        >
                            <Grid item xs={12}>
                                {errr.code !== 0 ? (
                                    <Grid
                                        item
                                        xs={12}
                                        style={{
                                            textAlign: "center",
                                            padding: "2%",
                                        }}
                                        className={
                                            errr.code === 200
                                                ? "green-light-background"
                                                : "red-light-background"
                                        }
                                    >
                                        <span
                                            className={`${
                                                errr?.code === 200
                                                    ? "Success_dot"
                                                    : "Failed_dot"
                                            }`}
                                        ></span>{" "}
                                        <b>{errr.msg}</b>
                                    </Grid>
                                ) : (
                                    <Grid
                                        item
                                        xs={12}
                                        style={{
                                            textAlign: "center",
                                            padding: "2%",
                                        }}
                                    >
                                        <span></span> <b></b>
                                    </Grid>
                                )}
                            </Grid>
                            {selectedVendor?.admin.toLowerCase() === "y" && (
                                <>
                                    <Grid item xs={12}>
                                        <Divider variant="middle" />
                                    </Grid>
                                    <Grid item xs={10}></Grid>
                                    <Grid item xs={2}>
                                        <MUIButton
                                            label="Edit"
                                            onClick={() => {
                                                if(userReadOnlyAccess){

                                                    setIsEdit(true);
                                                }
                                            }}
                                            type="filled"
                                            font="fontMedium"
                                        />
                                    </Grid>
                                </>
                            )}
                        </Grid>
                    </Grid>
                </Paper>
            ) : (
                <Grid
                    container
                    spacing={2}
                    style={{
                        maxWidth: "550px",
                        margin: "1.5% 0% 0.5% 1%",
                        paddingTop: "2.1%",
                    }}
                >
                    <Grid item xs={6}>
                        <TextFields
                            label={"Supplier Code"}
                            id={"BICNoSwiftNo"}
                            value={postDirectDepositData?.BICNoSwiftNo}
                            handleChange={handleChange}
                            width={"90%"}
                            multiline={false}
                            rows={0}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextFields
                            label={"Supplier Name"}
                            id={"Supplier_name"}
                            value={postDirectDepositData?.Supplier_name}
                            handleChange={handleChange}
                            width={"90%"}
                            multiline={false}
                            rows={0}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextFields
                            label={"Bank Name"}
                            id={"Bank_name"}
                            value={postDirectDepositData?.Bank_name}
                            handleChange={handleChange}
                            width={"90%"}
                            multiline={false}
                            rows={0}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextFields
                            label={"Account Name"}
                            id={"AccountName"}
                            value={postDirectDepositData?.AccountName}
                            handleChange={handleChange}
                            width={"90%"}
                            multiline={false}
                            rows={0}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextFields
                            label={"Account Number"}
                            id={"AccountNumber"}
                            value={postDirectDepositData?.AccountNumber}
                            handleChange={handleChange}
                            width={"90%"}
                            multiline={false}
                            rows={0}
                        />
                    </Grid>
                    <Grid
                        container
                        style={{
                            height: 125,
                            justifyContent: "center",
                            alignItems: "center",
                            position: "absolute",
                            bottom: 0,
                            right: 0,
                        }}
                    >
                        <Grid item xs={12}>
                            {errr.code !== 0 ? (
                                <Grid
                                    item
                                    xs={12}
                                    style={{
                                        textAlign: "center",
                                        padding: "2%",
                                    }}
                                    className={
                                        errr.code === 200
                                            ? "green-light-background"
                                            : "red-light-background"
                                    }
                                >
                                    <span
                                        className={`${
                                            errr?.code === 200
                                                ? "Success_dot"
                                                : "Failed_dot"
                                        }`}
                                    ></span>{" "}
                                    <b>{errr.msg}</b>
                                </Grid>
                            ) : (
                                <Grid
                                    item
                                    xs={12}
                                    style={{
                                        textAlign: "center",
                                        padding: "2%",
                                    }}
                                >
                                    <span></span> <b></b>
                                </Grid>
                            )}
                        </Grid>
                        <Grid item xs={12}>
                            <Divider variant="middle" />
                        </Grid>
                        <Grid item xs={10}></Grid>
                        <Grid item xs={2}>
                            <MUIButton
                                label="Save"
                                onClick={() => {
                                    setsaveDepositinfo(false);
                                }}
                                type="filled"
                                font="fontMedium"
                            />
                        </Grid>
                    </Grid>
                </Grid>
            )}
        </>
    );
};

const mapStateToProps = (state: any) => {
    return {
        currentContext: state.context?.userDefaults,
        userName: state.context?.name,
        selectedCustomer: state.selectedCustomer,
        portalKey: state.portalKey,
        selectedVendor: state.selectedVendor,
        supplierCode: state?.selectedVendor?.supplierCode,
        loginUser: state?.selectedVendor?.loginUser,
        userReadOnlyAccess: state?.userReadOnlyAccess,
    };
};

export default connect(mapStateToProps)(UploadInfoSideDraw);
