import { READ_ONLY_ACCESS } from "../../actionTypes";
const initialState = true; //customer Portal
const storeReadOnlyAccess = (prevState = initialState, action: any) => {
    switch (action.type) {
        case READ_ONLY_ACCESS:
            return action.payload;
        default:
            return prevState;
    }
};
export default storeReadOnlyAccess;
