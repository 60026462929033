import { STORE_USER_ROLE } from "../../actionTypes";

const storeSelectedConext = (data: any) => {
    return {
        type: STORE_USER_ROLE,
        payload: data,
    };
};

/**
 * Common File redux
 * @param code
 */
export function storeDefaultContext<T>(data: any) {
    return (dispatch: any) => {
        dispatch(storeSelectedConext(data));
    };
}
