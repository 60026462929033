const VendorHeaderDetailsQuery = `
mutation(
    $entity:String
    $ou:Int
    $service:String
    $User:String
    $languageId:Int
    $supplierCode:String
    $suppliername:String
    $poNo:String
    $merrorid:String 
){
    VendorHeaderDetails(
        entity:$entity
        ou:$ou
        service:$service
        User:$User
        languageId:$languageId
        supplierCode:$supplierCode
        suppliername:$suppliername
        poNo:$poNo
        merrorid:$merrorid
    ){ 
        assignmentno,
      bill_amt,
      building,
      customer,
      service,
      startenddate,
    }
}
`;

export default VendorHeaderDetailsQuery;
