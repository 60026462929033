import React, { useState, useEffect } from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import NavComponentCUS from "../subComponents/NavMenuSchema";
import NavMenuSchemaVEN from "../subComponents/NavMenuSchemaVEN";
import { List, ListItem, ListItemIcon, Grid } from "@material-ui/core";
import { useHistory, useRouteMatch } from "react-router-dom";
import { connect } from "react-redux";
import { storeBreadcrumbs } from "../../../stateManagement/actions/Common/breadcrumbs";
import BasicPopover from "./Pop";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import NavMenuSchemaSUBCON from "../subComponents/NavMenuSchemaSUBCON";

const useStyles = makeStyles({
    list: {
        width: 230,
    },
    fullList: {
        width: "auto",
    },
});
function TemporaryDrawer(props: any) {
    const {
        setBreadcrumbsData,
        portalKey,
        adminKey,
        handleDrawerClose,
        handleDrawerOpen,
        subContractorKey,
    } = props;
    const [navComponentCategories, setNavComponentCategories] = useState<any>(
        NavComponentCUS,
    );
    const classes = useStyles();
    const [arr, setarr] = useState([] as any);
    const [activeTxtId, setActiveTxtId] = useState(1000000012121111);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const handleClick = (event: any, text: number) => {
        console.log("Active Text", text, activeTxtId);
        if (text === activeTxtId || text == 22) {
            setActiveTxtId(-1);
            setAnchorEl(null);
            return;
        } else {
            setActiveTxtId(text);
        }
        setAnchorEl(event.currentTarget);
    };

    useEffect(() => {
        if (portalKey)
            if (subContractorKey)
                setNavComponentCategories(NavMenuSchemaSUBCON);
            else setNavComponentCategories(NavMenuSchemaVEN);
        else if (!portalKey) setNavComponentCategories(NavComponentCUS);
    }, [portalKey, subContractorKey]);

    useEffect(() => {
        setarr(
            navComponentCategories.filter((element: any, index: any) => {
                if (element.level === "BPC" || element.level === "BPCActivity")
                    if (adminKey) return element;
                    else if (element.value !== "InvoicesCus") return element;
            }),
        );
        // }
    }, [navComponentCategories]);

    const [data, setData] = useState([] as any);

    const Lists = (path: string, url: string) => {
        const history = useHistory();
        const clickListener = (
            text: number,
            level: string,
            breadcrumb: any,
            e: any,
        ) => {
            setActiveTxtId(text);
            if (breadcrumb !== undefined) {
                setBreadcrumbsData(breadcrumb);
                console.log("nav menu", breadcrumb);
            }
            if (level !== "Component") {
                const subMenuObj = navComponentCategories.filter(
                    (element: any) => element.parent === text,
                );
                setData(subMenuObj);
            }
        };
        const listNav = (text: any) => {
            const Icon = text.icon;
            return (
                <ListItem
                    button
                    key={text.id}
                    onClick={(e) => handleClick(e, text.id)}
                    style={
                        activeTxtId === text.id
                            ? {
                                  color: "#0073e6",
                                  maxWidth: "240px",
                                  marginTop: "7%",
                              }
                            : { maxWidth: "240px", marginTop: "7%" }
                    }
                >
                    <ListItemIcon
                        onClick={(e) => {
                            e.stopPropagation();
                            handleDrawerOpen();
                        }}
                        style={{ marginLeft: "2.75%" }}
                    >
                        <Icon
                            color={activeTxtId === text.id ? "secondary" : ""}
                            style={{ fontSize: 25 }}
                        />
                    </ListItemIcon>
                    <Grid
                        container
                        spacing={1}
                        alignItems="center"
                        onClick={
                            text.level === "BPCActivity"
                                ? () => {
                                      console.log(
                                          "history Path--------",
                                          url,
                                          path.replace("*/", ""),
                                      );
                                      setData([]);
                                      if (text?.breadcrumb !== undefined) {
                                          setBreadcrumbsData(text?.breadcrumb);
                                          console.log(
                                              "nav menu",
                                              text?.breadcrumb,
                                          );
                                      }
                                      console.log(
                                          "history push",
                                          text?.subRoute
                                              ? `${path.replace("*/", "")}/${
                                                    text.route
                                                }/${text?.subRoute}`
                                              : `${path.replace("*/", "")}/${
                                                    text.route
                                                }`,
                                      );
                                      history.push(
                                          text?.subRoute
                                              ? `${path.replace("*/", "")}/${
                                                    text.route
                                                }/${text?.subRoute}`
                                              : `${path.replace("*/", "")}/${
                                                    text.route
                                                }`,
                                      );
                                      handleDrawerClose();
                                  }
                                : (e) =>
                                      clickListener(
                                          text.id,
                                          text.level,
                                          text.breadcrumb,
                                          e,
                                      )
                        }
                    >
                        <Grid item xs={11} sm={11} style={{ marginTop: "1%" }}>
                            {" "}
                            <span style={{ fontSize: "12px" }}>
                                <b> {text.title}</b>
                            </span>
                        </Grid>
                        <Grid item xs={1} sm={1}>
                            {text.level !== "BPCActivity" && (
                                <ArrowRightIcon
                                    color={
                                        activeTxtId === text.id
                                            ? "primary"
                                            : "inherit"
                                    }
                                />
                            )}
                        </Grid>
                    </Grid>
                    <BasicPopover
                        anchors={[anchorEl, setAnchorEl]}
                        data={data}
                        handleDrawerClose={handleDrawerClose}
                        clickListener={clickListener}
                    />
                </ListItem>
            );
        };

        return (
            <div className={clsx(classes.list)} role="presentation">
                <List>
                    {arr.map((text: any, index: number) => {
                        return listNav(text);
                    })}
                </List>
            </div>
        );
    };
    const { path, url } = useRouteMatch();
    return (
        <div>
            <React.Fragment key={"right"}>{Lists(path, url)}</React.Fragment>
        </div>
    );
}
const mapDispatchToProps = (dispatch: any) => {
    return {
        setBreadcrumbsData: (data: any) => {
            dispatch(storeBreadcrumbs(data));
        },
    };
};
const mapStateToProps = (state: any) => {
    return {
        storeRole: state.storeRole,
        portalKey: state.portalKey,
        adminKey: state.adminKey,
        subContractorKey: state.subContractorKey,
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(TemporaryDrawer);
