const GetItemSearchComboQuery = `
 query Query
    (
        $ou: Int
        $service: String
        $user: String
        $langId: Int
        $itemCode: String
        $variantCode: String
        $itemType: String
        $itemDesc: String
        $errorid: String
    )
        {
            GetItemSearchComboQuery
                (
                    ou: $ou
                    service: $service
                    user: $user
                    langId: $langId
                    itemCode: $itemCode
                    variantCode: $variantCode 
                    itemType: $itemType 
                    itemDesc: $itemDesc 
                    errorid: $errorid 
                )
                    {
                        header 
                            {
                                code
                                desc
                                api
                            }
                        detail 
                            {
                                desc
                                code
                                    {
                                        costingMethod
                                        itemCodeImageName
                                        itemaccountgroup
                                        itemcategorycode
                                        itemcode
                                        itemdesc
                                        itemtype
                                        itemvariantdesc
                                        manufacturinguom
                                        planningtype
                                        purchaseleadtime1
                                        purchaseuom
                                        salesuom
                                        stdcost
                                        stdpurchaseprice
                                        stdsupplier
                                        stockuom
                                        variantcode
                                        variantdesc
                                        itemCodeImageCode
                                    }
                            }
                    }
        }
`;

export default GetItemSearchComboQuery;
