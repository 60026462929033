const loanBalanceDetailsQuery = `
mutation(
    $entity:String
    $ou:Int
    $service:String
    $User:String
    $languageId:Int
    $supplierCode:String
    $callingfrom:String
    $status:String
    $loanNo:String
){
    loanBalanceDetails(
        entity:$entity
        ou:$ou
        service:$service
        User:$User
        languageId:$languageId
        supplierCode:$supplierCode
        callingfrom:$callingfrom
        status:$status
        loanNo:$loanNo
    ){
        scheduleNo,
        PurchasedDate:datePaid,
        EffectiveDate:paidOn,
        PaymentNo:voucherNo,
        TransAmt:payment
        dueDate,
        principleAmt,
        interestAmount,
        balance,
        paidStatus,
        loandesc,
    } 
}
`;

export default loanBalanceDetailsQuery;
