import { STORE_COMMON_VALUES } from "../../actionTypes";

const setCommonValues = (data: any) => {
    return {
        type: STORE_COMMON_VALUES,
        payload: data,
    };
};

/**
 * Common File redux
 * @param code
 */
export function storeCommonValues<T>(data: any) {
    return (dispatch: any) => {
        dispatch(setCommonValues(data));
    };
}
