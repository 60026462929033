import React, { useEffect, useState } from "react";
import "./ButtonGroup.scss";
import Paper from "@material-ui/core/Paper";
import Grid, { GridSize } from "@material-ui/core/Grid";

interface controlListProp {
    desc: string;
    value: string;
}

interface ButtonGroupProps {
    controlList: controlListProp[];
    handleDocList: (listType: string) => void;
    gridSpacing: any;
    externalActive?: string;
}
const ButtonGroups = (props: ButtonGroupProps) => {
    const { controlList, handleDocList, gridSpacing, externalActive } = props;
    const [active, setActive] = useState<number>(0);

    const handleClick = (index: number) => {
        setActive(index);
    };
    useEffect(() => {
        if (externalActive) {
            const activeList = controlList.findIndex(
                (element) => element?.value === externalActive,
            );
            setActive(activeList);
        }
    }, [externalActive]);
    return (
        <Grid
            container
            spacing={0}
            alignItems="center"
            className="font-smaller text-center pointer"
            style={{ minWidth: "190px" }}
        >
            {controlList.map((element: controlListProp, index: number) => {
                return (
                    <Grid item xs={(gridSpacing + 1)} md={gridSpacing}>
                        <Paper
                            variant="outlined"
                            className={
                                active === index
                                    ? "active-button"
                                    : "not-active-button"
                            }
                            onClick={() => {
                                handleClick(index);
                                handleDocList(element.value);
                            }}
                            style={{
                                padding: "3% 3% 3% 1%",
                                border: "white",
                            }}
                        >
                            <b>{element.desc}</b>
                        </Paper>
                    </Grid>
                );
            })}
        </Grid>
    );
};

export default ButtonGroups;
