const TicketContactFolDateCUS = `
query Query($pipeline : String, $ticketNumber : String
    ) {
        TicketContactFolDateCUS(pipeline : $pipeline, ticketNumber: $ticketNumber) {
            contactId
            contactName
            followUpDate
            followupMeetingLink
     }
   
  }
  `;

export default TicketContactFolDateCUS;
