import { STORE_COMMON_VALUES } from "../../actionTypes";

const initialState = null;

const storeCommonValues = (prevState = initialState, action: any) => {
    switch (action.type) {
        case STORE_COMMON_VALUES:
            return action.payload;

        default:
            return prevState;
    }
};

export default storeCommonValues;
