import { Grid, Paper } from "@material-ui/core";
import currencyFormatter from "Common/Utils/currencyFormatter";
import React, { useEffect, useState } from "react";
import MuiDatatables from "Common/Mui-DataTables/MuiDatatables";
import useGetPosts from "ReactQuery/reactQuery";
import { FMIRHeaderQuery, FMIRTableQuery } from "ReactQuery/Query";
import { connect } from "react-redux";
import Icons from "Common/Icons/Icons";
import BackdropLoading from "Common/Backdrop/Backdrop";

const ViewFmir = (props: any) => {
    const { currentContext, currency, selectedVendor, active } = props;
    const [data, setData] = useState<any>([]);
    const [hdata, setHeaderData] = useState<any>([]);
    const FMIRSchema = [
        {
            name: "customerID",
            Label: "customerID",
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => (
                    <span style={{ color: "white" }}>Customer ID</span>
                ),
                setCellHeaderProps: () => {
                    return { style: { borderRight: "1px solid #D5D5D5" } };
                },
                setCellProps: () => {
                    return {
                        style: {
                            borderRight: "1px solid #D5D5D5",
                            textAlign: "center",
                        },
                    };
                },
                customBodyRender: (
                    value: any,
                    tableMeta: any,
                    updateValue: any,
                ) => {
                    console.log("table data", value);
                    return <b style={{ color: "#5C677D" }}>{value}</b>;
                },
            },
        },
        {
            name: "customerName",
            Label: "customerName",
            type: "number",
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => (
                    <span style={{ color: "white" }}>Customer Name</span>
                ),
                setCellHeaderProps: () => {
                    return { style: { borderRight: "1px solid #D5D5D5" } };
                },
                setCellProps: () => {
                    return {
                        style: {
                            borderRight: "1px solid #D5D5D5",
                            textAlign: "center",
                        },
                    };
                },
                customBodyRender: (
                    value: any,
                    tableMeta: any,
                    updateValue: any,
                ) => {
                    console.log("table data", value, tableMeta.rowData);
                    return <b style={{ color: "#5C677D" }}>{value}</b>;
                },
            },
        },
        {
            name: "building",
            Label: "building",
            type: "number",
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => (
                    <span style={{ color: "white" }}>Building</span>
                ),
                setCellHeaderProps: () => {
                    return { style: { borderRight: "1px solid #D5D5D5" } };
                },
                setCellProps: () => {
                    return {
                        style: {
                            borderRight: "1px solid #D5D5D5",
                            textAlign: "center",
                        },
                    };
                },
                customBodyRender: (
                    value: any,
                    tableMeta: any,
                    updateValue: any,
                ) => {
                    console.log("table data", value);
                    return (
                        <b
                            style={{
                                color: "#5C677D",
                            }}
                        >
                            {value}
                        </b>
                    );
                },
            },
        },
        {
            name: "servicedesc",
            Label: "servicedesc",
            type: "number",
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => (
                    <span style={{ color: "white" }}>Service Code</span>
                ),
                setCellHeaderProps: () => {
                    return { style: { borderRight: "1px solid #D5D5D5" } };
                },
                setCellProps: () => {
                    return {
                        style: {
                            borderRight: "1px solid #D5D5D5",
                            textAlign: "center",
                        },
                    };
                },
                customBodyRender: (
                    value: any,
                    tableMeta: any,
                    updateValue: any,
                ) => {
                    console.log("table data", value);
                    return (
                        <b
                            style={{
                                color: "#5C677D",
                            }}
                        >
                            {value}
                        </b>
                    );
                },
            },
        },
        {
            name: "dates",
            Label: "dates",
            type: "number",
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => (
                    <span style={{ color: "white" }}>From - To Date</span>
                ),
                setCellHeaderProps: () => {
                    return { style: { borderRight: "1px solid #D5D5D5" } };
                },
                setCellProps: () => {
                    return {
                        style: {
                            borderRight: "1px solid #D5D5D5",
                            textAlign: "center",
                        },
                    };
                },
                customBodyRender: (
                    value: any,
                    tableMeta: any,
                    updateValue: any,
                ) => {
                    console.log("table data", value);
                    return (
                        <b
                            style={{
                                color: "#5C677D",
                            }}
                        >
                            {value}
                        </b>
                    );
                },
            },
        },
        {
            name: "bill",
            Label: "bill",
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => (
                    <span style={{ color: "white" }}>
                        Standard Monthly Billing
                    </span>
                ),
                setCellHeaderProps: () => {
                    return { style: { borderRight: "1px solid #D5D5D5" } };
                },
                setCellProps: () => {
                    return {
                        style: {
                            borderRight: "1px solid #D5D5D5",
                            textAlign: "center",
                        },
                    };
                },
                customBodyRender: (
                    value: any,
                    tableMeta: any,
                    updateValue: any,
                ) => {
                    console.log("table data", value);
                    return (
                        <b
                            style={{
                                color: "#5C677D",
                                display: "flex",
                                justifyContent: "flex-end",
                            }}
                        >
                            {currencyFormatter(value, currency)}
                        </b>
                    );
                },
            },
        },

        {
            name: "amount",
            Label: "amount",
            type: "number",
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => (
                    <span style={{ color: "white" }}>
                        Amount This Month After Adjustments
                    </span>
                ),
                setCellHeaderProps: () => {
                    return { style: { borderRight: "1px solid #D5D5D5" } };
                },
                setCellProps: () => {
                    return {
                        style: {
                            borderRight: "1px solid #D5D5D5",
                            textAlign: "center",
                        },
                    };
                },
                customBodyRender: (
                    value: any,
                    tableMeta: any,
                    updateValue: any,
                ) => {
                    console.log("table data", value);
                    return (
                        <b
                            style={{
                                color: "#5C677D",
                                display: "flex",

                                justifyContent: "flex-end",
                            }}
                        >
                            {currencyFormatter(value, currency)}
                        </b>
                    );
                },
            },
        },
    ];

    const {
        data: FMIRData,
        error: FMIRTableError,
        isLoading: FMIRTableLoading,
    } = useGetPosts({
        name: `FMIRTableQuery`,
        query: FMIRTableQuery,
        variables: {
            entity: "ow_getcust_monthlybilling_adj_dtl_entity",
            ou: currentContext?.ouId,
            service: "",
            User: selectedVendor?.loginUser,
            languageId: currentContext?.langId,
            vendorName: selectedVendor?.supplierName,
            vendorCode: selectedVendor?.supplierCode,
            status: active,
            payAdviceID: "",
            fromDate: "",
            toDate: "",
            merrorid: 0,
        },
    });

    useEffect(() => {
        if (FMIRData?.FmirTable) {
            setData(FMIRData?.FmirTable);
        }
    }, [FMIRData]);

    const {
        data: FMIRHeaderData,
        error: FMIRHeaderError,
        isLoading: FMIRHeaderLoading,
    } = useGetPosts({
        name: `FMIRHeaderQuery`,
        query: FMIRHeaderQuery,
        variables: {
            entity: "ow_getcust_monthlybilling_adj_entity",
            ou: currentContext?.ouId,
            service: "",
            User: selectedVendor?.loginUser,
            languageId: currentContext?.langId,
            vendorName: selectedVendor?.supplierName,
            vendorCode: selectedVendor?.supplierCode,
            status: active,
            payAdviceID: "",
            fromDate: "",
            toDate: "",
            merrorid: 0,
        },
    });

    useEffect(() => {
        if (FMIRHeaderData?.FmirHeader[0]) {
            setHeaderData(FMIRHeaderData?.FmirHeader[0]);
        }
    }, [FMIRHeaderData]);

    return (
        <Grid
            container
            spacing={2}
            alignItems="center"
            style={{ marginLeft: "1%", maxWidth: "1100px", paddingTop: "2%" }}
        >
            <BackdropLoading
                openStates={FMIRTableLoading || FMIRHeaderLoading}
            />
            <Grid item xs={12}>
                <Paper elevation={4}>
                    <Grid container className="padding-1-percent">
                        <Grid
                            item
                            xs={5}
                            style={{
                                padding: "1% 1.5% 1% 1%",
                                borderRight: "1px dashed #C9CDD6",
                            }}
                        >
                            <Grid
                                container
                                className="padding-left-1-per margin-top-1-per"
                            >
                                <Grid item xs={1}>
                                    <Icons Icon={"Book"} />
                                </Grid>

                                <Grid
                                    item
                                    xs={1}
                                    className="padding-left-3-per"
                                >
                                    {hdata?.vendorCode}
                                </Grid>

                                <Grid
                                    item
                                    xs={3}
                                    className="padding-left-3-per"
                                ></Grid>
                                <Grid
                                    item
                                    xs={1}
                                    className="padding-left-5-per"
                                >
                                    <Icons Icon={"building"} />
                                </Grid>
                                <Grid
                                    item
                                    xs={6}
                                    className="padding-left-5-per"
                                >
                                    {hdata?.vendorName}
                                </Grid>
                            </Grid>
                            <Grid
                                container
                                className=" padding-left-1-per margin-top-3-per"
                            >
                                <Grid item xs={1}>
                                    <Icons Icon={"locationDetail"} />
                                </Grid>

                                <Grid
                                    item
                                    xs={11}
                                    className="text-left padding-left-2-per"
                                >
                                    {hdata?.address}
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid
                            item
                            xs={7}
                            style={{
                                display: "flex",
                                alignItems: "center",
                                padding: "1% 1% 1% 1.5%",
                            }}
                        >
                            <Grid container>
                                <Grid item xs={12} className="red">
                                    {hdata?.title}
                                </Grid>
                                <Grid item xs={12}>
                                    {hdata?.note1}
                                </Grid>
                                <Grid item xs={12}>
                                    {hdata?.note2}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
            <Grid item xs={12}>
                <div
                    style={{
                        fontWeight: 700,
                        fontSize: "13px",
                        lineHeight: "18px",
                        color: "#33415C",
                        margin: "24px 0 16px 0",
                    }}
                >
                    Assignments
                </div>
            </Grid>
            <Grid item xs={12} style={{ marginRight: "2%", paddingTop: "2%" }}>
                <MuiDatatables
                    header={FMIRSchema}
                    title={""}
                    detail={[data, setData]}
                />
            </Grid>
        </Grid>
    );
};

const mapStateToProps = (state: any) => {
    return {
        currentContext: state.context?.userDefaults,
        userName: state.context?.name,
        selectedCustomer: state.selectedCustomer,
        supplierCode: state.hiddenSupplier?.supplierCodeOut,
        selectedVendor: state?.selectedVendor,
        currency: state.currency,
        sessionId: state.sessionId,
    };
};

export default connect(mapStateToProps)(ViewFmir);
