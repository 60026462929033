const pendingApprovalDetailsQuery = `
mutation
(
  $entity:String
  $ou:Int
  $service:String
  $User:String
  $languageId:Int
  $vendorName:String
  $vendorCode:String
  $status:String
  $merrorid:String
)
    {
    pendingApprovalDetails
        (
            entity:$entity
            ou:$ou
            service:$service
            User:$User
            languageId:$languageId
            vendorName:$vendorName
            vendorCode:$vendorCode
            status:$status
            merrorid:$merrorid
        ) 
            {
                Customercontract
                Offerdate
                Offernumber
                amountoffer
                custname
                startdate
                suppname
                declineReason
                custcode
                guid
                status
                supplierCode
                TypeOfService
            }
    }
  `;

export default pendingApprovalDetailsQuery;
