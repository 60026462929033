import { SUBCONTRACTOR_KEY } from "../../actionTypes";
const initialState = false; //not a subcontractor
const storeSubcontractorKey = (prevState = initialState, action: any) => {
    switch (action.type) {
        case SUBCONTRACTOR_KEY:
            return action.payload;
        default:
            return prevState;
    }
};
export default storeSubcontractorKey;
