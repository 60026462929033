const CommonAPIQuery = `
 query Query
    (
        $ou: Int
        $user: String
        $langId: Int
        $portalCode: String
    )
        {
            CommonAPIQuery
                (
                    ou: $ou
                    user: $user
                    langId: $langId
                    portalCode: $portalCode 
                )
                    {
                        carriercode
                        currency
                        customercode
                        folder
                        lcapplicable
                        numbering_type
                        ord_type
                        paytermcode
                        sale_type
                        saleschannel
                        salesperson 
                        shippingpoint
                        shiptoaddid
                        shiptocustomer
                        so_freight_bill
                        trans_mode
                        fbid
                    }
        }
`;

export default CommonAPIQuery;
