import { Divider, Grid } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import TextFields from "Common/TextField/TextField";
import { SwitchClassKey, SwitchProps } from "@material-ui/core/Switch";
import { FormControlLabel, Switch, Theme } from "@material-ui/core";
import { withStyles, createStyles } from "@material-ui/styles";
import BackdropLoading from "Common/Backdrop/Backdrop";
import {
    CompanyDetailsEditUserCusQuery,
    CompanyPostUserVenQuery,
} from "ReactQuery/Query";
import useGetPosts from "ReactQuery/reactQuery";
import { v4 as uuidv4 } from "uuid";
import { connect } from "react-redux";
import MUIButton from "Common/Button/Button";
import Alert from "Common/Alerts/Alerts";
interface Styles extends Partial<Record<SwitchClassKey, string>> {
    focusVisible?: string;
}

interface Props extends SwitchProps {
    classes: Styles;
}

const MSMESwitch = withStyles((theme: Theme) =>
    createStyles({
        root: {
            width: 33,
            height: 18,
            padding: 0,
            margin: theme.spacing(1),
        },
        switchBase: {
            padding: 1,
            "&$checked": {
                transform: "translateX(16px)",
                color: theme.palette.common.white,
                "& + $track": {
                    backgroundColor: "green",
                    opacity: 1,
                    border: "none",
                },
            },
            "&$focusVisible $thumb": {
                color: "green",
                border: "6px solid green",
            },
        },
        thumb: {
            width: 15,
            height: 15,
        },
        track: {
            borderRadius: 30 / 2,
            border: `1px solid ${theme.palette.grey[400]}`,
            backgroundColor: theme.palette.grey[50],
            opacity: 1,
            transition: theme.transitions.create([
                "background-color",
                "border",
            ]),
        },
        checked: {},
        focusVisible: {},
    }),
)(({ classes, ...props }: Props) => {
    console.log(props.name, props.checked);
    return (
        <Switch
            focusVisibleClassName={classes.focusVisible}
            disableRipple
            classes={{
                root: classes.root,
                switchBase: classes.switchBase,
                thumb: classes.thumb,
                track: classes.track,
                checked: classes.checked,
            }}
            {...props}
        />
    );
});

const CompanySideDrawChildVen = (props: any) => {
    const {
        selectedVendor,
        userReadOnlyAccess,
        currentContext,
        companyProfileName,
        userDetails,
        dashboardSideDraw,
        AddUser,
        setSideBar,
        setRefetchCount,
        username
    } = props;

    const [sideDrawData, setSideDrawData] = useState<any>([]);
    const [count, setCount] = useState(0);
    const handleChange = (e: React.ChangeEvent<{ value: any }>) => {
        if (errr.code === 400) {
            seterr({
                code: 0,
                msg: "",
            });
        }
        let name = (e?.target as any).name;
        let value = (e?.target as any).value;
        setSideDrawData((prevState: any) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const handleChangeCheck = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSideDrawData((data: any) => ({
            ...data,
            [event.target.name]: event.target.checked,
        }));
    };

    const [addUserSkip, setAddUserSkip] = useState(true);
    const [alert, setAlert] = useState(false);
    const [errr, seterr] = useState({
        code: 0,
        msg: "",
    });

    const handleErrorAlert = (code: number, message: string) => {
        Alert({
            statusCode: code,
            message: message,
            willClose: () => {
                setAlert(false);
                seterr({ code: 0, msg: "" });
                setSideBar(false);
                setRefetchCount((prevcnt: number) => prevcnt + 1);
            },
        });
    };

    const handleReset = () => {
        if (AddUser) {
            setSideDrawData({
                Name: "",
                UserName: "",
                CustomerName: "",
                FirstName: "",
                LastName: "",
                JobTitle: "",
                Email: "",
                BusinessPhone: "",
                MobilePhone: "",
                isActive: false,
                // MakePayment: flase,
                OrderSupplies: false,
                Admin: false,
            });
        } else {
            setCount((prev) => prev + 1);
        }
        seterr({
            code: 0,
            msg: "",
        });
    };

    // useEffect(() => {
    //     handleReset()
    // }, [AddUser]);

    const {
        data: CompanyProfileDataVen,
        error: CompanyProfileDataVenError,
        isLoading: CompanyProfileDataVenLoading,
    } = useGetPosts({
        name: `CompanyProfileAddUser_${count}`,
        query: CompanyPostUserVenQuery,
        variables: {
            supplierCode: selectedVendor?.supplierCode,
            supplierName: selectedVendor?.supplierName,
            createUsers: {
                ActionFlag: AddUser ? "New" : "Edit",
                admin: sideDrawData?.Admin ? "Y" : "N",
                bphone: sideDrawData?.BusinessPhone,
                contactperson: sideDrawData?.FirstName
                    ? sideDrawData?.FirstName
                    : "" + sideDrawData?.LastName
                    ? sideDrawData?.LastName
                    : "",
                email: sideDrawData?.Email,
                firstname: sideDrawData?.FirstName,
                isactive: sideDrawData?.isActive ? "Y" : "N",
                jobtitle: sideDrawData?.JobTitle,
                lastname: sideDrawData?.LastName,
                mphone: sideDrawData?.MobilePhone,
                ordersupp: sideDrawData?.OrderSupplies ? "Y" : "N",
                portaluser: sideDrawData?.Name ? "Y" : "N",
                username: sideDrawData?.FirstName
                    ? sideDrawData?.FirstName
                    : "" + sideDrawData?.LastName
                    ? sideDrawData?.LastName
                    : "",
            },
        },
        header: {
            ou: currentContext?.ouId,
            role: currentContext?.roleName,
            lang: currentContext?.langId,
        },
        skip: addUserSkip,
    });

    useEffect(() => {
        if (CompanyProfileDataVen?.CompanyAddUserVen && !addUserSkip) {
            seterr({
                code: 200,
                msg: `${CompanyProfileDataVen?.CompanyAddUserVen.message}`,
            });
            setAddUserSkip(true);
            setAlert(true);
        }
    }, [CompanyProfileDataVen]);
    useEffect(() => {
        if (CompanyProfileDataVenError && !CompanyProfileDataVenLoading) {
            const data = JSON.parse(JSON.stringify(CompanyProfileDataVenError));
            const errmsg = data?.response?.errors[0].message;
            seterr({
                code: 200,
                msg: errmsg,
            });

            setAddUserSkip(true);
            // setAlert(true);
        }
    }, [CompanyProfileDataVenError, CompanyProfileDataVenLoading]);

    const {
        data: CompanyDetailsEditUser,
        error: CompanyDetailsEditUserError,
        isLoading: CompanyDetailsEditUserLoading,
    } = useGetPosts({
        name: `CompanyDetailsEditUserCusQuery_${companyProfileName}_${count}`,
        query: CompanyDetailsEditUserCusQuery,
        variables: {
            entity: "OW_Cust_portal_userdtlsedit",
            ou: currentContext?.ouId,
            service: "",
            user: username ? username : selectedVendor?.loginUser,
            langId: currentContext?.langId,
            customerCode: selectedVendor?.supplierCode,
            customerName: "",
            name: companyProfileName,
            merrorid: "",
        },
        skip: AddUser || companyProfileName === "" || dashboardSideDraw,
    });

    useEffect(() => {
        if (CompanyDetailsEditUser?.CompanyEditUserCus && !AddUser) {
            const tableData = CompanyDetailsEditUser?.CompanyEditUserCus[0];
            setSideDrawData({
                Name: tableData?.Name,
                UserName: tableData?.UserName,
                FirstName: tableData?.FirstName,
                LastName: tableData?.LastName,
                JobTitle: tableData?.JobTitle,
                Email: tableData?.Email,
                BusinessPhone: tableData?.BusinessPhone,
                MobilePhone: tableData?.MobilePhone,
                isActive:
                    tableData?.isActive &&
                    tableData?.isActive?.toLowerCase() === "yes"
                        ? true
                        : false,
                MakePayment:
                    tableData?.MakePayment?.toLowerCase() === "yes"
                        ? true
                        : false,
                OrderSupplies:
                    tableData?.OrderSupplies?.toLowerCase() === "yes"
                        ? true
                        : false,
                Admin: tableData?.Admin?.toLowerCase() === "yes" ? true : false,
                contactaddid: tableData?.contactaddid,
            });
        }
    }, [CompanyDetailsEditUser]);

    useEffect(() => {
        if (CompanyDetailsEditUserError && !CompanyDetailsEditUserLoading) {
            const data = JSON.parse(
                JSON.stringify(CompanyDetailsEditUserError),
            );
            const errmsg = data?.response?.errors[0].message;
            seterr({
                code: 400,
                msg: errmsg,
            });
        }
    }, [CompanyDetailsEditUserError, CompanyDetailsEditUserLoading]);

    return (
        <>
            {alert && errr.code === 200 ? (
                handleErrorAlert(errr.code, errr.msg)
            ) : (
                <></>
            )}
            <BackdropLoading
                openStates={
                    CompanyProfileDataVenLoading ||
                    CompanyDetailsEditUserLoading
                }
            />
            <Grid
                container
                spacing={3}
                style={{
                    maxWidth: "550px",
                }}
            >
                <Grid item xs={12}>
                    <Grid container>
                        <Grid item xs={12} className="grey">
                            Company
                        </Grid>
                        <Grid item xs={12} className="font-bolder">
                            {selectedVendor?.supplierName}
                        </Grid>
                    </Grid>
                </Grid>
                {/* <Grid item xs={6}>
                <TextFields
                    label="UserName"
                    id="UserName"
                    value={sideDrawData?.UserName}
                    handleChange={handleChange}
                    width="90%"
                />
            </Grid> */}
                <Grid item xs={6}>
                    <TextFields
                        label="First Name"
                        id="FirstName"
                        value={sideDrawData?.FirstName}
                        handleChange={handleChange}
                        width="90%"
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextFields
                        label="Last Name"
                        id="LastName"
                        multiline={false}
                        rows={1}
                        value={sideDrawData?.LastName}
                        handleChange={handleChange}
                        width="90%"
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextFields
                        label="Job Title"
                        id="JobTitle"
                        multiline={false}
                        rows={1}
                        value={sideDrawData?.JobTitle}
                        handleChange={handleChange}
                        width="90%"
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextFields
                        label="Email"
                        id="Email"
                        multiline={false}
                        rows={1}
                        value={sideDrawData?.Email}
                        handleChange={handleChange}
                        width="90%"
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextFields
                        label="Business Phone"
                        id="BusinessPhone"
                        multiline={false}
                        rows={1}
                        value={sideDrawData?.BusinessPhone}
                        handleChange={handleChange}
                        width="90%"
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextFields
                        label="Mobile Phone"
                        id="MobilePhone"
                        multiline={false}
                        rows={1}
                        value={sideDrawData?.MobilePhone}
                        handleChange={handleChange}
                        width="90%"
                    />
                </Grid>
                <Grid item xs={6}>
                    <Grid container style={{ paddingLeft: "7%" }}>
                        <Grid item xs={12} className="grey">
                            Active
                        </Grid>
                        <Grid item xs={12}>
                            <FormControlLabel
                                style={{ color: "green" }}
                                control={
                                    <MSMESwitch
                                        checked={
                                            sideDrawData?.isActive
                                                ? true
                                                : false
                                        }
                                        onChange={handleChangeCheck}
                                        name="isActive"
                                    />
                                }
                                label=""
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={6}>
                    <Grid container style={{ paddingLeft: "7%" }}>
                        <Grid item xs={12} className="grey">
                            Admin
                        </Grid>
                        <Grid item xs={12}>
                            <FormControlLabel
                                style={{ color: "green" }}
                                control={
                                    <MSMESwitch
                                        checked={
                                            sideDrawData?.Admin ? true : false
                                        }
                                        onChange={handleChangeCheck}
                                        name="Admin"
                                    />
                                }
                                label=""
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={6}>
                    <Grid container style={{ paddingLeft: "7%" }}>
                        <Grid item xs={12} className="grey">
                            Order Supplies
                        </Grid>
                        <Grid item xs={12}>
                            <FormControlLabel
                                style={{ color: "green" }}
                                control={
                                    <MSMESwitch
                                        checked={
                                            sideDrawData?.OrderSupplies
                                                ? true
                                                : false
                                        }
                                        onChange={handleChangeCheck}
                                        name="OrderSupplies"
                                    />
                                }
                                label=""
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    {errr.code === 400 ? (
                        <Grid
                            item
                            xs={12}
                            style={{
                                textAlign: "center",
                                padding: "2%",
                            }}
                            className={
                                // errr.code === 200
                                //     ? "green-light-background"
                                //     :
                                "red-light-background"
                            }
                        >
                            <span
                                className={`${
                                    // errr?.code === 200
                                    //     ? "Success_dot"
                                    //     :
                                    "Failed_dot"
                                }`}
                            ></span>{" "}
                            <b>{errr.msg}</b>
                        </Grid>
                    ) : (
                        <></>
                    )}
                </Grid>

                <Grid item xs={12} style={{ marginTop: "3%" }}>
                    <Divider />
                </Grid>
                <Grid item xs={6}></Grid>
                <Grid item xs={3}>
                    <MUIButton
                        label="Reset"
                        onClick={() => {
                            if (userReadOnlyAccess) {
                                handleReset();
                            }
                        }}
                        type="outlined"
                        font="fontMedium"
                    />
                </Grid>
                <Grid item xs={3}>
                    <MUIButton
                        label="Submit"
                        onClick={() => {
                            if (userReadOnlyAccess) {
                                setAddUserSkip(false);
                            }
                        }}
                        type="filled"
                        font="fontMedium"
                    />
                </Grid>
            </Grid>
        </>
    );
};
const mapStateToProps = (state: any) => {
    return {
        selectedVendor: state.selectedVendor,
        userReadOnlyAccess: state?.userReadOnlyAccess,
    };
};
export default connect(mapStateToProps)(CompanySideDrawChildVen);
