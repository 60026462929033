import { Grid, Paper, Backdrop, CircularProgress } from "@material-ui/core";
import { makeStyles, Theme } from "@material-ui/core/styles";
import React, { useEffect, useState } from "react";
import MUIButton from "Common/Button/Button";
import { connect } from "react-redux";
import useGetPosts from "ReactQuery/reactQuery";
import MuiDatatables from "Common/Mui-DataTables/MuiDatatables";
import { Redirect } from "react-router-dom";
import Dropdown from "Common/Dropdown/Dropdown";
import TextFields from "Common/TextField/TextField";
import CustomDatePickerBorder from "Common/DatePicker/DatePicker";
import { lookup as mimeconverter } from "mime-types";
import {
    InvoiceHeaderDetailsCusQuery,
    InvoiceStatusCUS,
    InvoiceTableDataCusQuery,
    StatusComboQuery,
} from "ReactQuery/Query/index";
import Alert from "Common/Alerts/Alerts";
import Icons from "Common/Icons/Icons";
import PieChart from "Common/Charts/PieChart";
import currencyFormatter from "Common/Utils/currencyFormatter";
import "Common/Main.scss";
import NoInvoices from "./Images/No_invoices.svg";
import { storeBreadcrumbs } from "stateManagement/actions/Common/breadcrumbs";
import Configuration from "Configuration";
import dateFormatter from "Common/Utils/dateFormatter";

interface DashCards {
    desc: string;
    value: string;
    visible: boolean;
    frequent: boolean;
    detail: {
        desc: string;
        value: number;
        color: string;
    }[];
}

interface InvStatus {
    lable: string[];
    partVal: number[];
    value: number[];
    color: string[];
    borderColor: string;
    valuedisp: string[];
}

interface InvStatusDet {
    lable: string;
    value: number;
    partVal: number;
    color: string;
    valuedisp: string;
    borderColor: string;
}

const useStyles = makeStyles((theme: Theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: "#fff",
    },
}));

interface BodyProps {
    currentContext: any;
    userName: any;
    selectedCustomer: any;
    currency: any;
    sessionId: string;
    setBreadcrumbsData: any;
}

const Body = (props: BodyProps) => {
    const [header, setHeader] = useState<DashCards>();
    const classes = useStyles();
    const {
        currentContext,
        selectedCustomer,
        currency,
        setBreadcrumbsData,
        sessionId,
    } = props;
    setBreadcrumbsData([
        {
            value: "Invoices",
            href: "/app/*/Invoices",
        },
    ]);
    const [statusList, setStatusList] = useState<any>([]);
    const [contractData, setContractData] = useState({
        fromDate: new Date(),
        toDate: new Date(),
        contractno: "",
        status: "",
    });

    const [active, setActive] = useState<string>("");

    const [total, setTotal] = useState({
        accountedFor: 0,
        accountedVal: "0",
    });

    const [invoiceStatusDataobj, setInvoiceStatusdataobj] = useState<InvStatus>(
        {
            lable: ["With In Due Date", "Past Due"],
            partVal: [0, 0],
            value: [0, 0],
            color: ["rgb(54, 162, 235)", "rgb(255, 99, 132)"],
            borderColor: "rgb(151,157,172)",
            valuedisp: ["0", "0"],
        },
    );

    const [invoiceStatusDatasetarr, setInvoiceStatusDatasetarr] = useState<
        InvStatusDet[]
    >([]);

    const [showTable, setShowTable] = useState<boolean>(false);

    const [invoiceTableDataList, setinvoiceTableDataList] = useState([]);
    const [alert, setAlert] = useState(false);
    const [errr, seterr] = useState({
        code: 0,
        msg: "",
    });
    const [invoiceDet, setInvoiceDet] = useState<boolean>(false);
    const [contractDet, setContractDet] = useState<boolean>(false);
    const [invoiceSelected, setInvoiceSelected] = useState();
    const [contractSelected, setContractSelected] = useState();
    //const [invoiceToggle, setInvoiceToggle] = useState(true);

    const handleredirect = (val: any) => {
        setInvoiceSelected(val);
        setInvoiceDet((prev) => !prev);
    };

    const handleContractClick = (value: any) => {
        setContractSelected(value);
        setContractDet((prev) => !prev);
    };

    const handleDate = (type: string, e: any) => {
        setShowTable(false);
        if (type === "toDate") {
            setContractData((prevstate: any) => ({
                ...prevstate,
                toDate: e,
            }));
        } else if (type === "fromDate") {
            setContractData((prevstate: any) => ({
                ...prevstate,
                fromDate: e,
            }));
        }
    };

    const {
        data: StatusComboData,
        error: StatusComboDataError,
        isLoading: StatusComboDataLoading,
    } = useGetPosts({
        name: `StatusComboQuery_${selectedCustomer?.loginUser}_CustInvoice`,
        query: StatusComboQuery,
        variables: {
            entity: "ow_getcombo_status",
            ou: currentContext?.ouId,
            service: "",
            user: selectedCustomer?.loginUser,
            langId: currentContext?.langId,
            screen: "CustInvoice",
            tranType: "",
            merrorid: "",
        },
    });

    useEffect(() => {
        if (StatusComboData?.statusCombo) {
            console.log("StatusData", StatusComboData?.statusCombo);
            setStatusList(StatusComboData?.statusCombo);
            setContractData((prevState: any) => {
                return {
                    ...prevState,
                    status: StatusComboData?.statusCombo[0]?.value,
                };
            });
        }
    }, [StatusComboData]);

    useEffect(() => {
        if (StatusComboDataError && !StatusComboDataLoading) {
            const data = JSON.parse(JSON.stringify(StatusComboDataError));
            const errmsg = data?.response?.errors[0].message;
            seterr({
                code: 400,
                msg: errmsg,
            });
            setAlert(true);
        }
    }, [StatusComboDataError, StatusComboDataLoading]);

    const {
        data: InvoiceTableData,
        error: InvoiceTableDataError,
        isLoading: InvoiceTableDataLoading,
    } = useGetPosts({
        name: `InvoiceTableDataCusQuery_${contractData?.status}_${contractData?.fromDate}_${contractData?.toDate}_${contractData?.contractno}_${active}_${selectedCustomer?.customerCode}`,
        query: InvoiceTableDataCusQuery,
        variables: {
            entity: "OW_Cust_portal_InvSummary",
            ou: currentContext?.ouId,
            service: "",
            User: selectedCustomer?.loginUser,
            languageId: currentContext?.langId,
            Status:
                active === header?.detail[0]?.desc
                    ? "AC"
                    : contractData?.status,
            customerCode: selectedCustomer?.customerCode,
            customerName: "",
            siteId: "",
            buildName: "",
            contractNo: "",
            invoiceNo: contractData?.contractno,
            fromDate: dateFormatter("sqlDate", contractData?.fromDate),
            toDate: dateFormatter("sqlDate", contractData?.toDate),
            merrorid: "",
        },
        skip: active === header?.detail[0]?.desc ? false : !showTable,
    });

    useEffect(() => {
        if (InvoiceTableData?.InvoiceTableDataCus) {
            // const data:any =[
            //     {
            //         "InvoiceNo": "COBI1058792",
            //         "ContractDetails":"Regular Invoice - Net 30",
            //         //  {
            //         //     "contractnumber": "Regular Invoice",
            //         //     "name": "Net 30"
            //         // },
            //         "TotalAmount": 1824.12,
            //         "BalanceDue": 1824.12,
            //         "PaymentStatus":"Not Paid",
            //         //  {
            //         //     "status": "Not Paid",
            //         //     "color": "#E01E37"
            //         // },
            //         "DocDate": "06/01/2024",
            //         "PastDueDate": 10,
            //         // {
            //         //     "days": 10,
            //         //     "color": "#E01E37"
            //         // },
            //         "print": "COBI1058792.pdf"
            //     },
            //     {
            //         "InvoiceNo": "COBI1060853",
            //         "ContractDetails": "Regular Invoice - Net 30",
            //         // {
            //         //     "contractnumber": "Regular Invoice",
            //         //     "name": "Net 30"
            //         // },
            //         "TotalAmount": 1824.12,
            //         "BalanceDue": 1824.12,
            //         "PaymentStatus": "Not Paid",
            //         // {
            //         //     "status": "Not Paid",
            //         //     "color": "#00AE50"
            //         // },
            //         "DocDate": "07/01/2024",
            //         "PastDueDate":-20,
            //         //  {
            //         //     "days": -20,
            //         //     "color": "#00AE50"
            //         // },
            //         "print": "COBI1060853.pdf"
            //     }
            // ]
            setinvoiceTableDataList(InvoiceTableData?.InvoiceTableDataCus);
            //setInvoiceToggle(true);
        }
    }, [InvoiceTableData]);

    useEffect(() => {
        if (InvoiceTableDataError && !InvoiceTableDataLoading) {
            const data = JSON.parse(JSON.stringify(InvoiceTableDataError));
            const errmsg = data?.response?.errors[0].message;
            seterr({
                code: 400,
                msg: errmsg,
            });
            setAlert(true);
            //setInvoiceToggle(true);
        }
    }, [InvoiceTableDataError, InvoiceTableDataLoading]);

    const {
        data: InvoiceHeaderData,
        error: InvoiceHeaderDataError,
        isLoading: InvoiceHeaderDataLoading,
    } = useGetPosts({
        name: "InvoiceHeaderDetailsCusQuery",
        query: InvoiceHeaderDetailsCusQuery,
        variables: {
            entity: "OW_Cust_portal_InvStatus",
            ou: currentContext?.ouId,
            service: "",
            User: selectedCustomer?.loginUser,
            languageId: currentContext?.langId,
            customerName: "",
            customerCode: selectedCustomer?.customerCode,
            merrorid: "",
        },
    });

    useEffect(() => {
        if (InvoiceHeaderData?.InvoiceHeaderDetailsCus) {
            setHeader(InvoiceHeaderData?.InvoiceHeaderDetailsCus);
            setActive(
                InvoiceHeaderData?.InvoiceHeaderDetailsCus?.detail[0]?.desc,
            );
            //setInvoiceToggle(false);
        }
    }, [InvoiceHeaderData]);

    useEffect(() => {
        if (InvoiceHeaderDataError && !InvoiceHeaderDataLoading) {
            const data = JSON.parse(JSON.stringify(InvoiceHeaderDataError));
            const errmsg = data?.response?.errors[0].message;
            seterr({
                code: 400,
                msg: errmsg,
            });
            setAlert(true);
        }
    }, [InvoiceHeaderDataError, InvoiceHeaderDataLoading]);

    const {
        data: InvoiceStatusData,
        error: InvoiceStatusDataError,
        isLoading: InvoiceStatusDataLoading,
    } = useGetPosts({
        name: `InvoiceStatusCUS`,
        query: InvoiceStatusCUS,
        variables: {
            DocFlag: "Debit",
            DocNoFrom: "",
            DocNoTo: "",
            DocStatusIn: "",
            DrDocCurrencyIn: "",
            DrDocFinanceBookIn: "",
            DrDocGroupIn: "",
            DrDocNoIn: "",
            DrDocTypeIn: "Order Invoice",
            DueAmountFrom: "",
            DueAmountTo: "",
            DueDateFrom: "",
            DueDateTo: "",
            DueFlag: "",
            HBLHAWBNoFrom: "",
            HBLHAWBNoIn: "",
            HBLHAWBNoTo: "",
            InstrumentNo: "",
            LCNumberIn: "",
            MBLMAWBFrom: "",
            MBLMAWBNoIn: "",
            MBLMAWBTo: "",
            OrgUnitIn: "",
            OverduedaysFrom: "",
            OverduedaysTo: "",
            ProjectCodeIn: "",
            ProjectOUIn: "",
            ReceiptCategoryIn: "",
            SalesPersoncodeIn: "",
            SalesPersonNameIn: "",
            AddlFieldIn1: "",
            AddlFieldIn2: "",
            AddlFieldIn3: "",
            AddlFieldIn4: "",
            AddlFieldIn5: "",
            BookingNoFrom: "",
            BookingNoIn: "",
            BookingNoTo: "",
            BusinessUnitIn: "",
            CollectorCode: "",
            CompanyIn: "",
            CrDocCurrencyIn: "",
            CrDocFinanceBookIn: "",
            CrDocGroupIn: "",
            CRDocNoIn: "",
            CrDocTypeIn: "",
            CustAddID: "",
            CustomerCodeIn: selectedCustomer?.customerCode,
            CustomerGroupIn: "",
            Customerhierarchy: "",
            CustomerNameIn: "",
            DocAmountFrom: "",
            DocAmountTo: "",
            DocDateFrom: "",
            DocDateTo: "",
            CustomerCodeFrom: "",
            CustomerCodeTo: "",
        },
        header: {
            ou: currentContext?.ouId,
            role: currentContext?.roleName,
            lang: currentContext?.langId,
        },
    });

    useEffect(() => {
        if (InvoiceStatusData?.InvoiceStatusCUS) {
            setInvoiceStatusDatasetarr(InvoiceStatusData?.InvoiceStatusCUS);
        }
    }, [InvoiceStatusData]);

    useEffect(() => {
        if (InvoiceStatusDataError && !InvoiceStatusDataLoading) {
            const data = JSON.parse(JSON.stringify(InvoiceStatusDataError));
            const errmsg = data?.response?.errors[0].message;
            seterr({
                code: 400,
                msg: errmsg,
            });
            setAlert(true);
        }
    }, [InvoiceStatusDataError, InvoiceStatusDataLoading]);

    useEffect(() => {
        invoiceStatusDatasetarr?.map((el) => {
            // if (el.lable === "pastdue") {
            //     setInvoiceStatusdataobj((prev) => {
            //         let newinv = { ...prev };

            //         // newinv.color[1] = el.color;
            //         newinv.partVal[1] = el.partVal;
            //         newinv.value[1] = el.value;
            //         newinv.valuedisp[1] = el.valuedisp;

            //         return newinv;
            //     });
            // } else if (el.lable === "withinduedate") {
            //     // } else if (el.lable === "notdue") {
            //     setInvoiceStatusdataobj((prev) => {
            //         let newinv = { ...prev };
            //         newinv.lable[0] = el.lable;
            //         // newinv.color[0] = el.color;
            //         newinv.partVal[0] = el.partVal;
            //         newinv.value[0] = el.value;
            //         newinv.valuedisp[0] = el.valuedisp;

            //         return newinv;
            //     });
            // } else if (el.lable === "totalinvoice") {
            //     setTotal((prev: any) => {
            //         return {
            //             accountedFor: el.partVal,
            //             accountedVal: el.valuedisp,
            //         };
            //     });
            // }
            // if (el.lable === "pastdue") {
            if (el.lable === "duethisweek") {
                setInvoiceStatusdataobj((prev) => {
                    let newinv = { ...prev };

                    // newinv.color[1] = el.color;
                    newinv.partVal[1] = el.partVal;
                    newinv.value[1] = el.value;
                    newinv.valuedisp[1] = el.valuedisp;

                    return newinv;
                });
                //} else if (el.lable === "withinduedate") {
            } else if (el.lable === "notdue") {
                setInvoiceStatusdataobj((prev) => {
                    let newinv = { ...prev };
                    newinv.lable[0] = el.lable;
                    // newinv.color[0] = el.color;
                    newinv.partVal[0] = el.partVal;
                    newinv.value[0] = el.value;
                    newinv.valuedisp[0] = el.valuedisp;

                    return newinv;
                });
                //} else if (el.lable === "totalinvoice") {
            } else if (el.lable === "all") {
                setTotal((prev: any) => {
                    return {
                        accountedFor: el.partVal,
                        accountedVal: el.valuedisp,
                    };
                });
            }
        });
    }, [invoiceStatusDatasetarr, InvoiceStatusData]);

    console.log("Timecc", invoiceStatusDataobj);
    useEffect(() => {
        console.log("showTable", showTable);
        console.log("active", active);
    }, [showTable, active]);
    const [fileloading, setFileLoading] = useState<boolean>(false);

    const handleAttachClick = async (fileName: any, relativePath: string) => {
        // handleBackdropClose(true);
        setFileLoading((prev) => !prev);

        const responseFile = await fetch(
            `${Configuration.getConfiguration().getAppAPILocation()}/api/fileManagement/${fileName}/${relativePath}`,
            {
                method: "GET",
                headers: {
                    authorization: sessionId,
                    ou: currentContext.ouId,
                    role: currentContext.roleName,
                    lang: currentContext.langId,
                },
                redirect: "follow",
            },
        );
        if (responseFile.status === 200) {
            const responseBlob = await responseFile.blob();
            const fileblob = responseBlob.slice(
                0,
                responseBlob.size,
                mimeconverter(fileName).toString(),
            );
            console.log("Downoad Blob =>", fileblob);
            const file: any = fileblob as File;
            file.name = fileName;
            console.log("Downoad File =>", file);
            URL.createObjectURL(file);
            setFileLoading((prev) => !prev);

            window.open(URL.createObjectURL(file), "_blank");
            return { fileData: file };
        } else {
            setFileLoading((prev) => !prev);
        }
    };

    const InvoiceTableDataSchema = [
        {
            name: "InvoiceNo",
            Label: "InvoiceNo",
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => (
                    <span style={{ color: "white" }}>Invoice No.</span>
                ),
                setCellHeaderProps: () => {
                    return { style: { borderRight: "1px solid #D5D5D5" } };
                },
                setCellProps: () => {
                    return {
                        style: {
                            borderRight: "1px solid #D5D5D5",
                            textAlign: "center",
                        },
                    };
                },
                customBodyRender: (
                    value: any,
                    tableMeta: any,
                    updateValue: any,
                ) => {
                    
                    return (
                        <b
                            style={{ color: "blue", cursor: "pointer" }}
                            onClick={() => handleredirect(value)}
                        >
                            {value}
                        </b>
                    );
                },
            },
        },

        {
            name: "ContractDetails",
            Label: "ContractDetails",
            type: "string",
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => (
                    <span style={{ color: "white" }}>Contract Details</span>
                ),
                setCellHeaderProps: () => {
                    return { style: { borderRight: "1px solid #D5D5D5" } };
                },
                setCellProps: () => {
                    return {
                        style: {
                            borderRight: "1px solid #D5D5D5",
                            textAlign: "center",
                        },
                    };
                },
                customBodyRender: (
                    value: any,
                    tableMeta: any,
                    updateValue: any,
                ) => {
                    console.log("table data", value);
                    const contractnumber=value.split("-")[0];
                    const name=value.split("-")[1];
                    return (
                        <Grid container>
                            <Grid item xs={12}>
                                <b
                                    style={{
                                        color: "#0073E6",
                                        cursor: "pointer",
                                    }}
                                    onClick={() => handleContractClick({contractnumber,name})}
                                >
                                    {/* {value?.contractnumber} */}
                                    {contractnumber}
                                </b>
                            </Grid>
                            <Grid item xs={12}>
                                <b className="grey ">{name}</b>
                            </Grid>
                        </Grid>
                    );
                },
            },
        },

        {
            name: "TotalAmount",
            Label: "TotalAmount",
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => (
                    <span style={{ color: "white" }}>Total Amount</span>
                ),
                setCellHeaderProps: () => {
                    return { style: { borderRight: "1px solid #D5D5D5" } };
                },
                setCellProps: () => {
                    return {
                        style: {
                            borderRight: "1px solid #D5D5D5",
                            textAlign: "center",
                        },
                    };
                },
                customBodyRender: (
                    value: any,
                    tableMeta: any,
                    updateValue: any,
                ) => {
                    console.log("table data", value);
                    return (
                        <b
                            style={{
                                color: "#5C677D",
                                display: "flex",
                                justifyContent: "flex-end",
                            }}
                        >
                            {currencyFormatter(value, currency)}
                        </b>
                    );
                },
            },
        },
        {
            name: "BalanceDue",
            Label: "BalanceDue",
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => (
                    <span style={{ color: "white" }}>Balance Due</span>
                ),
                setCellHeaderProps: () => {
                    return { style: { borderRight: "1px solid #D5D5D5" } };
                },
                setCellProps: () => {
                    return {
                        style: {
                            borderRight: "1px solid #D5D5D5",
                            textAlign: "center",
                        },
                    };
                },
                customBodyRender: (
                    value: any,
                    tableMeta: any,
                    updateValue: any,
                ) => {
                    console.log("table data", value);
                    return (
                        <b
                            style={{
                                display: "flex",
                                justifyContent: "flex-end",
                            }}
                            className="green"
                        >
                            {currencyFormatter(value, currency)}
                            {/* {value} */}
                        </b>
                    );
                },
            },
        },
        {
            name: "PaymentStatus",
            Label: "PaymentStatus",
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => (
                    <span style={{ color: "white" }}>Payment Status</span>
                ),
                setCellHeaderProps: () => {
                    return { style: { borderRight: "1px solid #D5D5D5" } };
                },
                setCellProps: () => {
                    return {
                        style: {
                            borderRight: "1px solid #D5D5D5",
                            textAlign: "center",
                        },
                    };
                },
                customBodyRender: (
                    value: any,
                    tableMeta: any,
                    updateValue: any,
                ) => {
                    console.log("table data", value);
                    console.log("table meta", tableMeta);
                    
                    const PS=tableMeta?.rowData[6];
                    const color = PS<0 ? "#00AE50" : "#E01E37";
                    return value === "Paid" ? (
                        <>
                            <b className="Completed_dot"></b>
                            <b style={{ color: "#00AE50", padding: "3%" }}>
                                {value}
                            </b>
                        </>
                    ) : (
                        <>
                            {color == "#00AE50" ? (
                                <b className="Completed_dot"></b>
                            ) : (
                                <b className="Failed_dot"></b>
                            )}
                            <b style={{ color, padding: "3%" }}>
                                {value}
                            </b>
                        </>
                    );
                },
            },
        },

        {
            name: "DocDate",
            Label: "DocDate",
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => (
                    <span style={{ color: "white" }}>Invoice Date</span>
                ),
                setCellHeaderProps: () => {
                    return { style: { borderRight: "1px solid #D5D5D5" } };
                },
                setCellProps: () => {
                    return {
                        style: {
                            borderRight: "1px solid #D5D5D5",
                            textAlign: "center",
                        },
                    };
                },
                customBodyRender: (
                    value: any,
                    tableMeta: any,
                    updateValue: any,
                ) => {
                    console.log("table data", value);
                    return <b style={{ color: "#5C677D" }}>{value}</b>;
                },
            },
        },

        {
            name: "PastDueDate",
            Label: "PastDueDate",
            type: "string",
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => (
                    <span style={{ color: "white" }}>Past Due Days</span>
                ),
                setCellHeaderProps: () => {
                    return { style: { borderRight: "1px solid #D5D5D5" } };
                },
                setCellProps: () => {
                    return {
                        style: {
                            borderRight: "1px solid #D5D5D5",
                            textAlign: "center",
                        },
                    };
                },

                customBodyRender: (
                    value: any,
                    tableMeta: any,
                    updateValue: any,
                ) => {
                    console.log("table data", value);
                    // return <b style={{ color: "#5C677D" }}>{value}</b>;
                    // return value?.days < 0 ? (
                    //     <b style={{ color: value?.color }}>{value.days}</b>
                    // ) : (
                    // return <b style={{ color: value?.color}}>{value.days}</b>
                    return <b style={{ color : (value<0 ? "#00AE50" : "#E01E37")}}>{value}</b>
                    
                },
            },
        },
        {
            name: "print",
            Label: "print",
            type: "string",
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => (
                    <span style={{ color: "white" }}>Print</span>
                ),
                setCellHeaderProps: () => {
                    return { style: { borderRight: "1px solid #D5D5D5" } };
                },
                setCellProps: () => {
                    return {
                        style: {
                            borderRight: "1px solid #D5D5D5",
                            textAlign: "center",
                        },
                    };
                },
                customBodyRender: (
                    value: any,
                    tableMeta: any,
                    updateValue: any,
                ) => {
                    console.log("table data", value);
                    return (
                        <b
                            style={{ color: "#0073E6", cursor: "pointer" }}
                            onClick={() => {
                                // handleAttachClick(value, "doc_ms%2Fnot");
                                handleAttachClick(value, "cobi%2Finvoicepdf");
                            }}
                        >
                            {<Icons Icon={"printerImage"} />}
                        </b>
                    );
                },
            },
        },
    ];

    const handleActive = (index: string) => {
        setActive(index);
        setShowTable(false);
        console.log("index", index);
    };

    const handleErrorAlert = (code: number, message: string) => {
        Alert({
            statusCode: code,
            message: message,
            willClose: () => {
                setAlert(false);
                seterr({ code: 0, msg: "" });
            },
        });
    };

    const handleChange = (e: any) => {
        setShowTable(false);
        setContractData((prevState) => ({
            ...prevState,
            contractno: e?.target?.value,
        }));
    };

    const handleChangeStatus = (e: any) => {
        setShowTable(false);
        setContractData((prevState) => ({
            ...prevState,
            status: e?.target?.value,
        }));
    };
    const handleSearch = () => {
        //setinvoiceTableDataList([]);
        //setInvoiceToggle(false);
        setShowTable(true);
    };

    if (invoiceDet) {
        return (
            <Redirect
                to={{
                    pathname: "/app/*/InvoiceDet",
                    state: {
                        invoiceSelected: invoiceSelected,
                    },
                }}
            />
        );
    }

    if (contractDet) {
        return (
            <Redirect
                to={{
                    pathname: "/app/*/contractDet",
                    state: {
                        contractSelected: contractSelected,
                    },
                }}
            />
        );
    }

    return (
        <>
            {alert ? handleErrorAlert(errr.code, errr.msg) : <></>}
            <Backdrop
                className={classes.backdrop}
                open={
                    InvoiceTableDataLoading ||
                    InvoiceHeaderDataLoading ||
                    InvoiceStatusDataLoading ||
                    fileloading
                }
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <Grid
                container
                spacing={2}
                alignItems="center"
                style={{ paddingTop: "3%" }}
            >
                <Grid item xs={12} sm={12}>
                    <Grid
                        container
                        spacing={2}
                        alignItems={
                            active === header?.detail[1]?.desc
                                ? "center"
                                : "flex-end"
                        }
                        justifyContent={
                            active === header?.detail[0]?.desc
                                ? "space-between"
                                : "flex-start"
                        }
                    >
                        <Grid item xs={5}  md={3}>
                            <Paper elevation={4}>
                                <Grid
                                    container
                                    spacing={2}
                                    style={{
                                        padding: "5% 0% 0% 0%",
                                    }}
                                    justifyContent="space-around"
                                >
                                    {header?.detail.map((element, index) => {
                                        return (
                                            <Grid
                                                item
                                                xs={4}
                                                sm={4}
                                                className={
                                                    active === element?.desc
                                                        ? `${element?.color}_border_bottom pointer`
                                                        : "pointer"
                                                }
                                                onClick={() =>
                                                    handleActive(element?.desc)
                                                }
                                            >
                                                <Grid container spacing={1}>
                                                    <Grid item xs={12} sm={12}>
                                                        <span
                                                            className={
                                                                active ===
                                                                element?.desc
                                                                    ? `${element?.color} font-biggest font-boldest`
                                                                    : `grey font-biggest font-boldest`
                                                            }
                                                        >
                                                            {element?.value}
                                                        </span>
                                                    </Grid>
                                                    <Grid item xs={12} sm={12}>
                                                        <span
                                                            className={
                                                                active ===
                                                                element?.desc
                                                                    ? `font-boldest font-smallest`
                                                                    : "grey font-smallest"
                                                            }
                                                        >
                                                            {element?.desc}
                                                        </span>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        );
                                    })}
                                </Grid>
                            </Paper>
                        </Grid>

                        {active === header?.detail[1]?.desc && (
                            <Grid item  md={9} xs={7}>
                                <Paper elevation={4}>
                                    <Grid container style={{ padding: "2.3%" }}>
                                        <Grid item xs sm={10} style={{}}>
                                            <Grid
                                                container
                                                spacing={2}
                                                alignItems="center"
                                            >
                                                <Grid
                                                    item
                                                    sm
                                                    xs={12}
                                                    style={{}}
                                                >
                                                    <TextFields
                                                        label={"Invoice No."}
                                                        id={"contractno"}
                                                        value={
                                                            contractData?.contractno
                                                        }
                                                        handleChange={
                                                            handleChange
                                                        }
                                                        width={"100%"}
                                                        multiline={false}
                                                        rows={0}
                                                    />
                                                </Grid>
                                                <Grid
                                                    item
                                                    sm
                                                    xs={12}
                                                    style={{}}
                                                >
                                                    <Dropdown
                                                        label={"Status"}
                                                        id={"status"}
                                                        value={
                                                            contractData?.status
                                                        }
                                                        list={statusList}
                                                        handleChange={
                                                            handleChangeStatus
                                                        }
                                                        width={"100%"}
                                                    />
                                                </Grid>
                                                <Grid item sm xs={2} style={{}}>
                                                    <CustomDatePickerBorder
                                                        label="From Date"
                                                        width="100%"
                                                        value={
                                                            contractData?.fromDate
                                                        }
                                                        onChange={(e) =>
                                                            handleDate(
                                                                "fromDate",
                                                                e,
                                                            )
                                                        }
                                                        format="MM/dd/yyyy"
                                                    />
                                                </Grid>
                                                <Grid item sm xs={2} style={{}}>
                                                    <CustomDatePickerBorder
                                                        label="To Date"
                                                        width="100%"
                                                        value={
                                                            contractData?.toDate
                                                        }
                                                        onChange={(e) =>
                                                            handleDate(
                                                                "toDate",
                                                                e,
                                                            )
                                                        }
                                                        format="MM/dd/yyyy"
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>

                                        <Grid
                                            item
                                            sm={2}
                                            xs={12}
                                            style={{
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                            }}
                                        >
                                            <MUIButton
                                                label="Search"
                                                type="filled"
                                                font="font-small"
                                                onClick={handleSearch}
                                            />
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </Grid>
                        )}

                        {active === header?.detail[0].desc && (
                            <Grid
                                item
                                xs={6}
                                style={{
                                    marginTop: "-1.5%",
                                    marginRight: "-1%",
                                }}
                            >
                                <Grid container spacing={2}>
                                    <Paper
                                        style={{
                                            height: "100%",
                                            width: "100%",
                                        }}
                                    >
                                        <Grid item xs={12}>
                                            <Grid container>
                                                <Grid
                                                    item
                                                    xs={3}
                                                    style={{
                                                        padding: "5% 0 5% 5%",
                                                        display: "flex",
                                                        justifyContent:
                                                            "center",
                                                    }}
                                                >
                                                    <PieChart
                                                        Chardata={
                                                            invoiceStatusDataobj
                                                        }
                                                        maxWidth="90px"
                                                        maxHeight="90px"
                                                    />
                                                </Grid>
                                                <Grid
                                                    item
                                                    xs={9}
                                                    style={{
                                                        padding: "5%",
                                                        margin: "auto",
                                                    }}
                                                >
                                                    <Grid
                                                        container
                                                        justifyContent="center"
                                                    >
                                                        {
                                                            <Grid item xs={4}>
                                                                <Grid
                                                                    container
                                                                    justifyContent="center"
                                                                    alignItems="center"
                                                                >
                                                                    <Grid
                                                                        item
                                                                        className={`dot `}
                                                                    ></Grid>
                                                                    <Grid
                                                                        item
                                                                        xs={12}
                                                                        style={{
                                                                            padding:
                                                                                "2%",
                                                                        }}
                                                                    >
                                                                        Total
                                                                        Invoice
                                                                    </Grid>
                                                                    <Grid
                                                                        item
                                                                        xs={12}
                                                                    >
                                                                        <Grid
                                                                            container
                                                                            alignItems="center"
                                                                            justifyContent="center"
                                                                        >
                                                                            <Grid
                                                                                item
                                                                            >
                                                                                {invoiceStatusDatasetarr?.length ===
                                                                                1
                                                                                    ? invoiceStatusDatasetarr[0]
                                                                                          .partVal
                                                                                    : total?.accountedFor}
                                                                            </Grid>
                                                                            <Grid
                                                                                item
                                                                            >
                                                                                <Icons Icon="smallArrowRight" />
                                                                            </Grid>
                                                                            <Grid
                                                                                item
                                                                                style={{
                                                                                    color:
                                                                                        "grey",
                                                                                }}
                                                                            >
                                                                                {" "}
                                                                                {invoiceStatusDatasetarr?.length ===
                                                                                1
                                                                                    ? invoiceStatusDatasetarr[0]
                                                                                          .valuedisp
                                                                                    : total?.accountedVal}
                                                                            </Grid>
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        }

                                                        <Grid item xs={4}>
                                                            <Grid
                                                                container
                                                                justifyContent="center"
                                                                alignItems="center"
                                                            >
                                                                <Grid
                                                                    item
                                                                    className="Completed_dot"
                                                                ></Grid>
                                                                <Grid
                                                                    item
                                                                    xs={12}
                                                                    style={{
                                                                        padding:
                                                                            "2%",
                                                                    }}
                                                                >
                                                                    Within Due
                                                                    Date
                                                                </Grid>
                                                                <Grid
                                                                    item
                                                                    xs={12}
                                                                >
                                                                    <Grid
                                                                        container
                                                                        alignItems="center"
                                                                        justifyContent="center"
                                                                    >
                                                                        <Grid
                                                                            item
                                                                            style={{
                                                                                color: `${invoiceStatusDataobj?.color[0]}`,
                                                                            }}
                                                                        >
                                                                            {" "}
                                                                            {
                                                                                invoiceStatusDataobj
                                                                                    .partVal[0]
                                                                            }
                                                                        </Grid>
                                                                        <Grid
                                                                            item
                                                                        >
                                                                            <Icons Icon="smallArrowRight" />
                                                                        </Grid>
                                                                        <Grid
                                                                            item
                                                                            style={{
                                                                                color: `${invoiceStatusDataobj?.color[0]}`,
                                                                            }}
                                                                        >
                                                                            {" "}
                                                                            {
                                                                                invoiceStatusDataobj
                                                                                    .valuedisp[0]
                                                                            }
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                        <Grid item xs={4}>
                                                            <Grid
                                                                container
                                                                justifyContent="center"
                                                                alignItems="center"
                                                            >
                                                                <Grid
                                                                    item
                                                                    className="Failed_dot"
                                                                ></Grid>
                                                                <Grid
                                                                    item
                                                                    xs={12}
                                                                    style={{
                                                                        padding:
                                                                            "2%",
                                                                    }}
                                                                >
                                                                    Past Due
                                                                </Grid>
                                                                <Grid
                                                                    item
                                                                    xs={12}
                                                                >
                                                                    <Grid
                                                                        container
                                                                        alignItems="center"
                                                                        justifyContent="center"
                                                                    >
                                                                        <Grid
                                                                            item
                                                                            style={{
                                                                                color: `${invoiceStatusDataobj?.color[1]}`,
                                                                            }}
                                                                        >
                                                                            {
                                                                                invoiceStatusDataobj
                                                                                    .partVal[1]
                                                                            }
                                                                        </Grid>
                                                                        <Grid
                                                                            item
                                                                        >
                                                                            <Icons Icon="smallArrowRight" />
                                                                        </Grid>
                                                                        <Grid
                                                                            item
                                                                            style={{
                                                                                color: `${invoiceStatusDataobj?.color[1]}`,
                                                                            }}
                                                                        >
                                                                            {" "}
                                                                            {
                                                                                invoiceStatusDataobj
                                                                                    .valuedisp[1]
                                                                            }
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Paper>
                                </Grid>
                            </Grid>
                        )}
                    </Grid>
                </Grid>

                {active === header?.detail[0]?.desc && (
                    <Grid item xs={12} style={{ paddingTop: "2%" }}>
                        {invoiceTableDataList.length ? (
                            <MuiDatatables
                                hideAddButton={true}
                                header={InvoiceTableDataSchema}
                                detail={[
                                    invoiceTableDataList,
                                    setinvoiceTableDataList,
                                ]}
                            />
                        ) : (
                            <img
                                src={NoInvoices}
                                alt="No Invoices"
                                style={{ paddingTop: "4%" ,width: "95vh"}}
                            />
                        )}
                    </Grid>
                )}
                {showTable && active === header?.detail[1]?.desc && (
                    <Grid item xs={12} style={{ paddingTop: "2%" }}>
                        {invoiceTableDataList.length ? (
                            <MuiDatatables
                                hideAddButton={true}
                                header={InvoiceTableDataSchema}
                                detail={[
                                    invoiceTableDataList,
                                    setinvoiceTableDataList,
                                ]}
                            />
                        ) : (
                            <img
                                src={NoInvoices}
                                alt="No Invoices"
                                style={{ paddingTop: "4%",width: "95vh" }}
                            />
                        )}
                    </Grid>
                )}
            </Grid>
        </>
    );
};

const mapStateToProps = (state: any) => {
    return {
        currentContext: state.context?.userDefaults,
        userName: state.context?.name,
        selectedCustomer: state.selectedCustomer,
        currency: state.currency,
        sessionId: state.sessionId,
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        setBreadcrumbsData: (data: any) => {
            dispatch(storeBreadcrumbs(data));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Body);
