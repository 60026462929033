import { Grid, Paper, Typography } from "@material-ui/core";
import Icons from "Common/Icons/Icons";
import React from "react";

const FranchiseCard = (props: any) => {
    const { name, date } = props;
    return (
        <Grid>
            <Paper elevation={4}>
                <Grid container xs={12} spacing={2} style={{padding:'2%',marginTop:'2%'}}>
                    <Grid item xs={2}>
                        <Icons Icon="excelIcon"></Icons>
                    </Grid>
                    <Grid item xs={4}>
                        <Grid container>
                            <Grid item xs={12}>
                                <Typography className="font-boldest font-big">
                                    {name}
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography className="grey font-smaller">
                                    {date}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={5}></Grid>
                    <Grid item xs={1} style={{display:'flex',alignItems:'center'}}>
                        <Icons Icon="download"></Icons>
                    </Grid>
                </Grid>
            </Paper>
        </Grid>
    );
};

export default FranchiseCard;
