import { Grid, Paper } from "@material-ui/core";
import Alert from "Common/Alerts/Alerts";
import BackdropLoading from "Common/Backdrop/Backdrop";
import Icons from "Common/Icons/Icons";
import MuiDatatables from "Common/Mui-DataTables/MuiDatatables";
import currencyFormatter from "Common/Utils/currencyFormatter";
import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import { Redirect, useLocation } from "react-router-dom";
import {
    VendorDetailsMultilineQuery,
    VendorHeaderDetailsQuery,
} from "ReactQuery/Query";
import useGetPosts from "ReactQuery/reactQuery";
import { storeBreadcrumbs } from "stateManagement/actions/Common/breadcrumbs";
import NoContractSign from "../ContractsVEN/Images/NoContractSign.svg";

const VendorDetails = (props: any) => {
    const {
        currency,
        currentContext,
        selectedVendor,
        setBreadcrumbsData,
    } = props;
    setBreadcrumbsData([
        {
            value: "Current Assignment",
            href: "/app/*/CurrentAssignment",
        },
        {
            value: "Vendor Details",
            href: "/app/*/VendorDetails",
        },
    ]);
    const assignmentData = useLocation<any>();
    const poNo: any = assignmentData?.state?.poNoSelected || assignmentData?.state?.data;
    const from: any = assignmentData?.state?.from ?? "";
    const [contractDet, setContractDet] = useState<boolean>(false);
    const [contractSelected, setcontractSelected] = useState();
    const [alert, setAlert] = useState(false);
    const [errr, seterr] = useState({
        code: 0,
        msg: "",
    });
    const handleContractClick = (val: any) => {
        setContractDet(true);
        setcontractSelected(val);
    };
    const handleErrorAlert = (code: number, message: string) => {
        Alert({
            statusCode: code,
            message: message,
            willClose: () => {
                setAlert(false);
                seterr({ code: 0, msg: "" });
            },
        });
    };
    const contractSchema = [
        {
            name: "Contract",
            Label: "Contract",
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => (
                    <span style={{ color: "white" }}>Contract</span>
                ),
                setCellHeaderProps: () => {
                    return {
                        style: {
                            borderRight: "1px solid #D5D5D5",
                            textAlign: "center",
                        },
                    };
                },
                setCellProps: () => {
                    return {
                        style: {
                            borderRight: "1px solid #D5D5D5",
                            textAlign: "center",
                            padding: "15px 8px !important",
                        },
                    };
                },
                customBodyRender: (
                    value: any,
                    tableMeta: any,
                    updateValue: any,
                ) => {
                    console.log("table data", value, tableMeta.rowData);
                    return (
                        <b
                            className="blue"
                            style={{ cursor: "pointer" }}
                            onClick={() => handleContractClick(value)}
                        >
                            {value}
                        </b>
                    );
                },
            },
        },
        {
            name: "AccountNumber",
            Label: "Account Number",
            type: "string",
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => (
                    <span style={{ color: "white" }}>Account Number</span>
                ),
                setCellHeaderProps: () => {
                    return { style: { borderRight: "1px solid #D5D5D5" } };
                },
                setCellProps: () => {
                    return {
                        style: {
                            borderRight: "1px solid #D5D5D5",
                            textAlign: "center",
                            padding: "15px 8px !important",
                        },
                    };
                },
                customBodyRender: (
                    value: any,
                    tableMeta: any,
                    updateValue: any,
                ) => {
                    console.log("table data", value);
                    return <b style={{ color: "#5C677D" }}>{value}</b>;
                },
            },
        },
        {
            name: "Region",
            Label: "Region",
            type: "string",
            options: {
                filter: true,
                sort: false,
                setCellHeaderProps: () => {
                    return { style: { borderRight: "1px solid #D5D5D5" } };
                },
                setCellProps: () => {
                    return {
                        style: {
                            borderRight: "1px solid #D5D5D5",
                            textAlign: "center",
                            padding: "15px 8px !important",
                        },
                    };
                },
                customHeadLabelRender: () => (
                    <span style={{ color: "white" }}>Region</span>
                ),
                customBodyRender: (
                    value: any,
                    tableMeta: any,
                    updateValue: any,
                ) => {
                    console.log("table data", value);
                    return <b style={{ color: "#5C677D" }}>{value}</b>;
                },
            },
        },
        {
            name: "Designation",
            Label: "Designation",
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => (
                    <span style={{ color: "white" }}>Designation</span>
                ),
                setCellHeaderProps: () => {
                    return { style: { borderRight: "1px solid #D5D5D5" } };
                },
                setCellProps: () => {
                    return {
                        style: {
                            borderRight: "1px solid #D5D5D5",
                            textAlign: "center",
                            padding: "15px 8px !important",
                        },
                    };
                },
                customBodyRender: (
                    value: any,
                    tableMeta: any,
                    updateValue: any,
                ) => {
                    console.log("table data", value);
                    return <b style={{ color: "#5C677D" }}>{value}</b>;
                },
            },
        },

        {
            name: "ContractR",
            Label: "Contract Revision",
            type: "number",
            options: {
                customHeadLabelRender: () => (
                    <span style={{ color: "white" }}>Contract Revision</span>
                ),
                setCellHeaderProps: () => {
                    return { style: { borderRight: "1px solid #D5D5D5" } };
                },
                setCellProps: () => {
                    return {
                        style: {
                            borderRight: "1px solid #D5D5D5",
                            textAlign: "center",
                            padding: "15px 8px !important",
                        },
                    };
                },
                customBodyRender: (
                    value: any,
                    tableMeta: any,
                    updateValue: any,
                ) => {
                    console.log("table data", value);
                    return <b style={{ color: "#5C677D" }}>{value}</b>;
                },
            },
        },
        {
            name: "PurchaseDate",
            Label: "Purchase Date",
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => (
                    <span style={{ color: "white" }}>Purchase Date</span>
                ),
                setCellHeaderProps: () => {
                    return { style: { borderRight: "1px solid #D5D5D5" } };
                },
                setCellProps: () => {
                    return {
                        style: {
                            borderRight: "1px solid #D5D5D5",
                            padding: "15px 8px !important",
                            textAlign: "center",
                        },
                    };
                },
                customBodyRender: (
                    value: any,
                    tableMeta: any,
                    updateValue: any,
                ) => {
                    console.log("table data", value);
                    return <b style={{ color: "#5C677D" }}>{value}</b>;
                },
            },
        },
        {
            name: "StartEndDate",
            Label: "Start-End Date",
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => (
                    <span style={{ color: "white" }}>Start-End Date</span>
                ),
                setCellHeaderProps: () => {
                    return { style: { borderRight: "1px solid #D5D5D5" } };
                },
                setCellProps: () => {
                    return {
                        style: {
                            borderRight: "1px solid #D5D5D5",
                            padding: "15px 8px !important",
                            textAlign: "center",
                        },
                    };
                },
                customBodyRender: (
                    value: any,
                    tableMeta: any,
                    updateValue: any,
                ) => {
                    console.log("table data", value);
                    return <b style={{ color: "#5C677D" }}>{value}</b>;
                },
            },
        },
        {
            name: "PackagePurchased",
            Label: "Package Purchased",
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => (
                    <span style={{ color: "white" }}>Package Purchased</span>
                ),
                setCellHeaderProps: () => {
                    return { style: { borderRight: "1px solid #D5D5D5" } };
                },
                setCellProps: () => {
                    return {
                        style: {
                            borderRight: "1px solid #D5D5D5",
                            textAlign: "center",
                            padding: "15px 8px !important",
                        },
                    };
                },
                customBodyRender: (
                    value: any,
                    tableMeta: any,
                    updateValue: any,
                ) => {
                    console.log("table data", value);
                    return (
                        <b
                            style={{
                                color: "#5C677D",
                                display: "flex",
                                justifyContent: "flex-end",
                            }}
                        >
                            {value ? currencyFormatter(value, currency) : "---"}
                        </b>
                    );
                },
            },
        },
        {
            name: "VolumePurchased",
            Label: "Volume Purchased",
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => (
                    <span style={{ color: "white" }}>Volume Purchased</span>
                ),
                setCellHeaderProps: () => {
                    return { style: { borderRight: "1px solid #D5D5D5" } };
                },
                setCellProps: () => {
                    return {
                        style: {
                            borderRight: "1px solid #D5D5D5",
                            textAlign: "center",
                            padding: "15px 8px !important",
                        },
                    };
                },
                customBodyRender: (
                    value: any,
                    tableMeta: any,
                    updateValue: any,
                ) => {
                    console.log("table data", value);
                    return (
                        <b
                            style={{
                                color: "#5C677D",
                                display: "flex",
                                justifyContent: "flex-end",
                            }}
                        >
                            {value ? currencyFormatter(value, currency) : "---"}
                        </b>
                    );
                },
            },
        },
        {
            name: "TotalCost",
            Label: "Total Cost",
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => (
                    <span style={{ color: "white" }}>Total Cost</span>
                ),
                setCellHeaderProps: () => {
                    return { style: { borderRight: "1px solid #D5D5D5" } };
                },
                setCellProps: () => {
                    return {
                        style: {
                            borderRight: "1px solid #D5D5D5",
                            textAlign: "center",
                            padding: "15px 8px !important",
                        },
                    };
                },
                customBodyRender: (
                    value: any,
                    tableMeta: any,
                    updateValue: any,
                ) => {
                    console.log("table data", value);
                    return (
                        <b
                            style={{
                                color: "#5C677D",
                                display: "flex",
                                justifyContent: "flex-end",
                            }}
                        >
                            {value ? currencyFormatter(value, currency) : "---"}
                        </b>
                    );
                },
            },
        },

        {
            name: "TargetFulfillment",
            Label: "Target Fulfillment",
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => (
                    <span style={{ color: "white" }}>Target Fulfillment</span>
                ),
                setCellHeaderProps: () => {
                    return { style: { borderRight: "1px solid #D5D5D5" } };
                },
                setCellProps: () => {
                    return {
                        style: {
                            borderRight: "1px solid #D5D5D5",
                            textAlign: "center",
                            padding: "15px 8px !important",
                        },
                    };
                },
                customBodyRender: (
                    value: any,
                    tableMeta: any,
                    updateValue: any,
                ) => {
                    console.log("table data", value);
                    return (
                        <b
                            style={{
                                color: "#5C677D",
                                display: "flex",
                                justifyContent: "flex-end",
                            }}
                        >
                            {currencyFormatter(value, currency)}
                        </b>
                    );
                },
            },
        },
        {
            name: "VolumeOwned",
            Label: "Volume Owned",
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => (
                    <span style={{ color: "white" }}>Volume Owed</span>
                ),
                setCellHeaderProps: () => {
                    return { style: { borderRight: "1px solid #D5D5D5" } };
                },
                setCellProps: () => {
                    return {
                        style: {
                            borderRight: "1px solid #D5D5D5",
                            padding: "15px 8px !important",
                            textAlign: "center",
                        },
                    };
                },
                customBodyRender: (
                    value: any,
                    tableMeta: any,
                    updateValue: any,
                ) => {
                    console.log("table data", value);
                    return (
                        <b
                            style={{
                                color: "#5C677D",
                                display: "flex",
                                justifyContent: "flex-end",
                            }}
                        >
                            {currencyFormatter(value, currency)}
                        </b>
                    );
                },
            },
        },
    ];
    const [headerDetails, setHeaderDetails] = useState<any>();
    const {
        data: VendorHeaderDetailsQueryData,
        error: VendorHeaderDetailsQueryDataError,
        isLoading: VendorHeaderDetailsQueryDataLoading,
    } = useGetPosts({
        name: `VendorHeaderDetailsQuery_${poNo}_${selectedVendor?.supplierCode}`,
        query: VendorHeaderDetailsQuery,
        variables: {
            ou: currentContext?.ouId,
            service: "",
            User: selectedVendor?.loginUser,
            languageId: currentContext?.langId,
            supplierCode: selectedVendor?.supplierCode,
            suppliername: selectedVendor?.supplierName,
            poNo: poNo,
            merrorid: "",
        },
        skip: !poNo,
    });

    useEffect(() => {
        if (VendorHeaderDetailsQueryData?.VendorHeaderDetails) {
            setHeaderDetails(
                VendorHeaderDetailsQueryData?.VendorHeaderDetails[0],
            );
        }
    }, [VendorHeaderDetailsQueryData]);

    useEffect(() => {
        if (
            VendorHeaderDetailsQueryDataError &&
            !VendorHeaderDetailsQueryDataLoading
        ) {
            const data = JSON.parse(
                JSON.stringify(VendorHeaderDetailsQueryDataError),
            );
            const errmsg = data?.response?.errors[0].message;
            seterr({
                code: 400,
                msg: errmsg,
            });
            setAlert(true);
        }
    }, [
        VendorHeaderDetailsQueryDataError,
        VendorHeaderDetailsQueryDataLoading,
    ]);
    const [multilineDetails, setMultilineDetails] = useState<any>();
    const {
        data: VendorMultilineDetailsQueryData,
        error: VendorMultilineDetailsQueryDataError,
        isLoading: VendorMultilineDetailsQueryDataLoading,
    } = useGetPosts({
        name: `VendorDetailsMultilineQuery_${poNo}`,
        query: VendorDetailsMultilineQuery,
        variables: {
            ou: currentContext?.ouId,
            service: "",
            User: selectedVendor?.loginUser,
            languageId: currentContext?.langId,
            supplierCode: selectedVendor?.supplierCode,
            suppliername: selectedVendor?.supplierName,
            poNo: poNo,
            merrorid: "",
        },
        skip: !poNo,
    });

    useEffect(() => {
        if (VendorMultilineDetailsQueryData?.VendorDetailsMultiline) {
            setMultilineDetails(
                VendorMultilineDetailsQueryData?.VendorDetailsMultiline,
            );
        }
    }, [VendorMultilineDetailsQueryData]);

    useEffect(() => {
        if (
            VendorMultilineDetailsQueryDataError &&
            !VendorMultilineDetailsQueryDataLoading
        ) {
            const data = JSON.parse(
                JSON.stringify(VendorMultilineDetailsQueryDataError),
            );
            const errmsg = data?.response?.errors[0].message;
            seterr({
                code: 400,
                msg: errmsg,
            });
            setAlert(true);
        }
    }, [
        VendorMultilineDetailsQueryDataError,
        VendorMultilineDetailsQueryDataLoading,
    ]);
    if (contractDet) {
        return (
            <Redirect
                to={{
                    pathname: "/app/*/CONTDETSV",
                    state: {
                        contractSelected: contractSelected,
                        from:from,
                        poNo,
                    },
                }}
            />
        );
    }
    return (
        <>
            {alert ? handleErrorAlert(errr.code, errr.msg) : <></>}
            <BackdropLoading
                openStates={
                    VendorHeaderDetailsQueryDataLoading ||
                    VendorMultilineDetailsQueryDataLoading
                }
            />
            <Grid
                container
                spacing={2}
                style={{
                    textAlign: "left",
                    margin: "100px !important",
                    marginTop: "8px",
                }}
            >
                <Grid item xs={10}>
                    <Paper elevation={4}>
                        <Grid container>
                            <Grid
                                item
                                xs={7}
                                style={{
                                    margin: "0%",
                                    padding: "2%",
                                    borderRight: "1px dashed #C9CDD6",
                                }}
                            >
                                <Grid container spacing={1}>
                                    <Grid item xs={12}>
                                        <Grid
                                            container
                                            justifyContent="space-between"
                                        >
                                            <Grid
                                                item
                                                className="grey1 font-boldest"
                                                xs={4}
                                            >
                                                <Grid container>
                                                    <Grid item xs={2}>
                                                        <Icons Icon="contractDetail" />
                                                    </Grid>
                                                    <Grid
                                                        item
                                                        style={{
                                                            paddingLeft: "2%",
                                                        }}
                                                        xs={9}
                                                    >
                                                        #
                                                        {
                                                            headerDetails?.assignmentno
                                                        }
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={8}>
                                                <Grid
                                                    container
                                                    justifyContent="flex-end"
                                                >
                                                    <Grid item xs={1}>
                                                        <Icons Icon="buildingDetail" />
                                                    </Grid>
                                                    <Grid
                                                        item
                                                        xs={9}
                                                        style={{
                                                            paddingLeft: "2%",
                                                        }}
                                                        className="grey1 font-boldest"
                                                    >
                                                        {
                                                            headerDetails?.building
                                                        }
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Grid container>
                                            <Grid item xs={4}>
                                                <Grid container>
                                                    <Grid item xs={2}>
                                                        <Icons Icon="vendorContract" />
                                                    </Grid>
                                                    <Grid
                                                        item
                                                        xs={9}
                                                        className="grey2"
                                                        style={{
                                                            paddingLeft: "2%",
                                                        }}
                                                    >
                                                        {
                                                            headerDetails?.customer
                                                        }
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid
                                                item
                                                xs={8}
                                                className="text-right green font-bolder"
                                            >
                                                {currencyFormatter(
                                                    headerDetails?.bill_amt,
                                                    currency,
                                                )}
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid
                                item
                                xs={5}
                                style={{ padding: "2% 2% 2% 3.5%" }}
                            >
                                <Grid container spacing={2}>
                                    <Grid item xs={6}>
                                        <Grid container>
                                            <Grid item xs={12} className="grey">
                                                Start - End Date
                                            </Grid>
                                            <Grid
                                                item
                                                xs={12}
                                                className="grey1 font-boldest"
                                            >
                                                {headerDetails?.startenddate}
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Grid container>
                                            <Grid item xs={12} className="grey">
                                                Service
                                            </Grid>
                                            <Grid
                                                item
                                                xs={12}
                                                className="grey1 font-boldest"
                                            >
                                                {headerDetails?.service}
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
                <Grid
                    item
                    xs={12}
                    style={{ marginRight: "2%", paddingTop: "1%" }}
                >
                    {multilineDetails?.length ? (
                        <MuiDatatables
                            header={contractSchema}
                            detail={[multilineDetails, setMultilineDetails]}
                        />
                    ) : (
                        <img
                            src={NoContractSign}
                            style={{ paddingTop: "4%" }}
                        />
                    )}
                </Grid>
            </Grid>
        </>
    );
};
const mapStateToProps = (state: any) => {
    return {
        currency: state.currency,
        currentContext: state.context?.userDefaults,
        selectedVendor: state.selectedVendor,
    };
};
const mapDispatchToProps = (dispatch: any) => {
    return {
        setBreadcrumbsData: (data: any) => {
            dispatch(storeBreadcrumbs(data));
        },
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(VendorDetails);
