import { POST_LOGIN_TENANT_DETAILS } from "../../actionTypes";

const postTenantDetails = (data: any) => {
    return {
        type: POST_LOGIN_TENANT_DETAILS,
        payload: data,
    };
};

/**
 * Common File redux
 * @param code
 */
export function postLoginTenantDetails<T>(data: any) {
    return (dispatch: any) => {
        dispatch(postTenantDetails(data));
    };
}
