const LoanBalanceSummaryQuery = `
mutation(
    $entity:String
    $ou:Int
    $service:String
    $User:String
    $languageId:Int
    $supplierCode:String
    $callingfrom:String
    $status:String
){
    loanBalanceSummary(
        entity:$entity
        ou:$ou
        service:$service
        User:$User
        languageId:$languageId
        supplierCode:$supplierCode
        callingfrom:$callingfrom
        status:$status
    ){
        loanNo,
        totalAmount,
        paidAmount,
        balanceAmount,
        noOfSchedule,
        totActLoan,
        fileName,
        status
    } 
}
`;

export default LoanBalanceSummaryQuery;
