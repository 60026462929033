import { Grid, Paper } from "@material-ui/core";
import { useEffect, useState } from "react";
import useGetPosts from "ReactQuery/reactQuery";
import {
    BuildingQueryCUS,
    ContractHeaderDataCusQuery,
    CustomerDataQuery,
    InvoiceHeaderDetailsCusQuery,
    InvoiceStatusCUS,
    LoanBalanceSummaryQuery,
    OrderSuppliesHeaderCusQuery,
    PayAdviceHeaderDataVenQuery,
    pendingApprovalDetailsQuery,
    ServiceProviderCountQuery,
    TicketDataCUS,
    VendorDataQuery,
    VendorRatingLoanIndex,
    VolumeAndAcceptanaceQuery,
} from "ReactQuery/Query";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import Alert from "Common/Alerts/Alerts";
import TicketCarouselSubcon from "Common/Carousel/TicketCarouselSubcon";
import { storePortal } from "stateManagement/actions/Common/storePortal";
import CreateTicketsCUS from "Components/TicketsCUS/CreateTicketsCUS";
import SwipeableDrawerComp from "Common/SideDrawer/SideDrawer";
import CompanySideDrawChildVen from "Components/CompanyProfileCus/CompanySideDrawChildVen";
import CompanySideDrawChild from "Components/CompanyProfileCus/CompanySideDrawChild";
import OrderSuppliesDetails from "Components/OrderSuppliesCus/OrderSuppliesDetails";
import DeductionList from "./DeductionList";
import FranchiseeChild from "./FranchiseeChild";
import { storeSelectedVendor } from "stateManagement/actions/Common/vendorDetails";
import CommonAPIQuery from "ReactQuery/Query/CommonAPIQuery";
import { storeCommonValues } from "stateManagement/actions/Common/commonAPIValues";
import { storeCurrency } from "stateManagement/actions/Common/currency";
import Configuration from "Configuration";
import { storeSelectedCustomer } from "stateManagement/actions/Common/customerDetails";
import { storeBreadcrumbs } from "stateManagement/actions/Common/breadcrumbs";
import { storeContextInfo } from "stateManagement/actions/HomePage/storeContext";
import { useLocation } from "react-router-dom";
import DashCards from "./DashbordCards/DashCards";
import AcceptanceCard from "./DashbordCards/AcceptanceCard";
import PerformanceRatingCard from "./DashbordCards/PerformanceRatingCard";
import LoanBalanceCard from "./DashbordCards/LoanBalanceCard";
import LoanIndexCard from "./DashbordCards/LoanIndexCard";
import CalendarSchedule from "./Images/CalendarSchedule.svg";
import ComingSoon from "./Images/ComingSoon.svg";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import TicketCarousel from "Common/Carousel/TicketCarousel";
import PendingApproval from "./PendingApprovalVen/PendingApproval";
import TextFields from "Common/TextField/TextField";
import BackdropLoading from "Common/Backdrop/Backdrop";
import Assigned from "./Images/Assigned.svg";
import CalendarWIP from "./Images/CalendarWIP.svg";
import CalendarWIPimageonly from "./Images/CalendarWIPimageonly.svg";
import Owned from "./Images/Owned.svg";
import InitVolume from "./Images/InitVolume.svg";
import AdvlVolume from "./Images/AdvlVolume.svg";
import ReplaceableVolume from "./Images/ReplaceableVolume.svg";
import PieChart from "Common/Charts/PieChart";
import Icons from "Common/Icons/Icons";
import MUIButton from "Common/Button/Button";
import HiddenTextField from "Common/TextField/HiddenTextField";
import { storeSubcontractorKey } from "stateManagement/actions/Common/subContractorKey";
import Calendar from "Components/CalendarScheduleVEN/Body";

interface InvStatus {
    lable: string[];
    partVal: number[];
    value: number[];
    color: string[];
    borderColor: string;
    valuedisp: string[];
}
interface InvStatusDet {
    lable: string;
    value: number;
    partVal: number;
    color: string;
    valuedisp: string;
    borderColor: string;
}
interface BodyProps {
    currentContext: any;
    userName: any;
    setContext: any;
    setSelectedCustomer: any;
    selectedCustomer: any;
    setSelectedVendor: any;
    portalKey: any;
    customerData: any;
    supplierCode: any;
    setCommonValues: any;
    setCurrency: any;
    setBreadcrumbsData: any;
    adminKey: any;
    context: any;
    setSubContractorKey: any;
    subContractorKey: any;
    userReadOnlyAccess:Boolean
}
const Body = (props: BodyProps) => {
    const {
        currentContext,
        userName,
        setContext,
        selectedCustomer,
        setSelectedCustomer,
        setSelectedVendor,
        customerData,
        portalKey,
        setCommonValues,
        setCurrency,
        setBreadcrumbsData,
        adminKey,
        context,
        setSubContractorKey,
        subContractorKey,
        userReadOnlyAccess
    } = props;

    setBreadcrumbsData([]);
    const adminValues = useLocation<any>();
    const [redirected, setredirected] = useState(adminValues);
    useEffect(() => {
        setredirected(
            adminValues.state
                ? adminValues
                : JSON.parse(localStorage.getItem("RedirectedState") as any),
        );
    }, []);
    // const redirected =
    console.log(
        "Redirected State",
        redirected?.state,
        JSON.parse(localStorage.getItem("RedirectedState") as any),
    );

    useEffect(() => {
        if (redirected?.state)
            localStorage.setItem("RedirectedState", JSON.stringify(redirected));
    }, [redirected?.state]);

    useEffect(() => {
        console.log(
            "User Check ==>",
            redirected?.state?.userNameEntered,
            context,
            {
                ...context,
                name: redirected?.state?.userNameEntered?.Username,
            },
        );
        if (adminKey) {
            if (!portalKey) {
                setContext({
                    ...context,
                    name: redirected?.state?.userNameEntered?.Username,
                });
            } else {
                setContext({
                    ...context,
                    name: redirected?.state?.userNameEntered?.Username,
                });
            }
        }
    }, [redirected?.state]);

    const [quickViewData, setQuickViewData] = useState("");
    const [contractDet, setContractDet] = useState<boolean>(false);
    const [routeName, setRouteName] = useState("");
    const [redir, setRedir] = useState(false);
    const [calendarRedirect, setCalendarRedirect] = useState(false);
    const [loanSummaryRedirect, setLoanSummaryRedirect] = useState(false);
    const SwitchRoute = (routeName: string, detail: any) => {
        console.log("route", routeName);
        setRouteName(routeName);
        setRedir(true);
        setTickDet(detail);
    };
    const [ticketCardData, setTicketCardData] = useState<any>([]);
    const [sideDrawdataset, setSideDrawdataset] = useState();
    const [alert, setAlert] = useState(false);
    const [errr, seterr] = useState({
        code: 0,
        msg: "",
    });
    const handleErrorAlert = (code: number, message: string) => {
        Alert({
            statusCode: code,
            message: message,
            willClose: () => {
                setAlert(false);
                seterr({ code: 0, msg: "" });
            },
        });
    };
    const handleClick = () => {
        setOpen((prev) => !prev);
    };
    //     contracts: 0,
    //     buildings: 0,
    //     invoices: 0,
    //     tickets: 0,
    //     orderSupply: 0,
    // });

    // const [total, setTotal] = useState({
    //     accountedFor: 0,
    //     accountedVal: "0",
    // });

    // const [invoiceStatusDataobj, setInvoiceStatusdataobj] = useState<InvStatus>(
    //     {
    //         lable: ["With In Due Date", "Past Due"],
    //         partVal: [0, 0],
    //         value: [0, 0],
    //         color: ["rgb(54, 162, 235)", "rgb(255, 99, 132)"],
    //         borderColor: "rgb(151,157,172)",
    //         valuedisp: ["0", "0"],
    //     },
    // );
    // const VendorNav = [
    //     {
    //         title: "Service Provider Contract",
    //         status: "Active",
    //         count: dashboardCountVen?.serviceProviderCount,
    //         iconDisp: "coloredContracts",
    //         color: "#FF6D6D",
    //         backgroundColor: "#FFF8F8",
    //         routename: "Contracts",
    //         code: "serviceProviderCount",
    //     },
    //     {
    //         title: "Pay Advice",
    //         status: "Active",
    //         count: dashboardCountVen?.payAdvice,
    //         iconDisp: "currencyIcon",
    //         color: "#FF6D6D",
    //         backgroundColor: "#FFF8F8",
    //         routename: "PayAdvice",
    //         code: "payAdvice",
    //     },
    //     // {
    //     //     title: "Franchise Statement",
    //     //     status: "Last 90 Days",
    //     //     count: dashboardCountVen?.franchiseStatement,
    //     //     iconDisp: "statementIcon",
    //     //     color: "#FF6D6D",
    //     //     backgroundColor: "#FFF8F8",
    //     //     routename: "Franchisee",
    //     // },
    //     {
    //         title: "Tickets",
    //         status: "Last 90 Days",
    //         count: dashboardCountVen?.tickets,
    //         iconDisp: "coloredTickets",
    //         color: "#00A0D6",
    //         backgroundColor: "#F2FBFE",
    //         routename: "Tickets",
    //         code: "tickets",
    //     },
    //     {
    //         title: "Order Supplies",
    //         status: "Last 90 Days",
    //         count: dashboardCountVen?.orderSupply,
    //         iconDisp: "coloredOrders",
    //         color: "#DD9200",
    //         backgroundColor: "#FDFAF2",
    //         routename: "OrderSupplies",
    //         code: "orderSupply",
    //     },
    // ];

    // const [invoiceStatusDatasetarr, setInvoiceStatusDatasetarr] = useState<
    //     InvStatusDet[]
    // >([]);

    const [contextValues, setContextValues] = useState({
        loginUser: "",
        portalCode: "",
        customerCode: "",
        admin: false,
        orderSupplies: false,
        commonAPIToggle: true,
    });
    const {
        data: CustomerData,
        error: CustomerDataError,
        isLoading: CustomerDataLoading,
    } = useGetPosts({
        name: `CustomerDataQuery_${redirected?.state?.userNameEntered?.Username}_${userName}`,
        query: CustomerDataQuery,
        variables: {
            entity: "OW_Cust_portal_userlogin",
            ou: currentContext?.ouId,
            service: "",
            user: adminKey
                ? redirected?.state?.userNameEntered?.Username
                : userName,
            langId: currentContext?.langId,
            merrorid: "",
        },
        skip: !Boolean(currentContext?.ouId) || portalKey,
    });

    useEffect(() => {
        if (CustomerData?.CustomerData.length > 0) {
            setSelectedCustomer(CustomerData?.CustomerData[0]);
            setContextValues({
                loginUser: CustomerData?.CustomerData[0]?.loginUser,
                portalCode: CustomerData?.CustomerData[0]?.companyCode,
                admin:
                    CustomerData?.CustomerData[0]?.admin.toLowerCase() === "y"
                        ? true
                        : false,
                orderSupplies:
                    CustomerData?.CustomerData[0]?.orderSupplies.toLowerCase() ===
                    "y"
                        ? true
                        : false,
                commonAPIToggle: false,
                customerCode: "",
            });
        }
    }, [CustomerData, setSelectedCustomer]);

    useEffect(() => {
        const eventHeaderElements = document?.getElementsByClassName(
            "b-event-time",
        );
        setTimeout(() => {
            for (let i = 0; i < eventHeaderElements.length; i++) {
                (eventHeaderElements[i] as any).style.display = "none";
            }
        }, 1000);
    }, []);

    useEffect(() => {
        if (CustomerDataError && !CustomerDataLoading) {
            const data = JSON.parse(JSON.stringify(CustomerDataError));
            const errmsg = data?.response?.errors[0].message;
            seterr({
                code: 400,
                msg: errmsg,
            });
            setAlert(true);
        }
    }, [CustomerDataError, CustomerDataLoading]);

    const [vendorData, setVendorData] = useState<any>();
    console.log("inside vendor", redirected?.state?.userNameEntered?.Username);
    const {
        data: VendorData,
        error: VendorDataError,
        isLoading: VendorDataLoading,
    } = useGetPosts({
        name: `VendorDataQuery_${redirected?.state?.userNameEntered?.Username}_${userName}`,
        query: VendorDataQuery,
        variables: {
            entity: "OW_portal_vendorportallogin",
            ou: currentContext?.ouId,
            service: "",
            user: adminKey
                ? redirected?.state?.userNameEntered?.Username
                : userName,
            langId: currentContext?.langId,
            role: currentContext?.roleName,
            merrorid: "",
        },
        skip: !Boolean(currentContext?.ouId) || !portalKey,
    });

    useEffect(() => {
        if (VendorData?.VendorData.length > 0) {
            console.log("Inside vendor data", VendorData?.VendorData[0]);
            setSelectedVendor(VendorData?.VendorData[0]);
            setVendorData(VendorData?.VendorData[0]);
            setContextValues({
                loginUser: VendorData?.VendorData[0]?.loginUser,
                portalCode: VendorData?.VendorData[0]?.supplierCode,
                customerCode: VendorData?.VendorData[0]?.customerCode,
                admin:
                    VendorData?.VendorData[0]?.admin.toLowerCase() === "y"
                        ? true
                        : false,
                orderSupplies:
                    VendorData?.VendorData[0]?.orderSupplies.toLowerCase() ===
                    "y"
                        ? true
                        : false,
                commonAPIToggle: false,
            });
            setSubContractorKey(
                VendorData?.VendorData[0]?.suppcategory.toLowerCase() ===
                    "subcontractor"
                    ? true
                    : false,
            );
        }
    }, [VendorData]);

    useEffect(() => {
        if (VendorDataError && !VendorDataLoading) {
            const data = JSON.parse(JSON.stringify(VendorDataError));
            const errmsg = data?.response?.errors[0].message;
            // seterr({
            //     code: 400,
            //     msg: errmsg,
            // });
            // setAlert(true);
        }
    }, [VendorDataError, VendorDataLoading]);

    // const {
    //     data: InvoiceStatusData,
    //     error: InvoiceStatusDataError,
    //     isLoading: InvoiceStatusDataLoading,
    // } = useGetPosts({
    //     name: `InvoiceStatusCUS`,
    //     query: InvoiceStatusCUS,
    //     variables: {
    //         DocFlag: "Debit",
    //         DocNoFrom: "",
    //         DocNoTo: "",
    //         DocStatusIn: "",
    //         DrDocCurrencyIn: "",
    //         DrDocFinanceBookIn: "",
    //         DrDocGroupIn: "",
    //         DrDocNoIn: "",
    //         DrDocTypeIn: "Order Invoice",
    //         DueAmountFrom: "",
    //         DueAmountTo: "",
    //         DueDateFrom: "",
    //         DueDateTo: "",
    //         DueFlag: "",
    //         HBLHAWBNoFrom: "",
    //         HBLHAWBNoIn: "",
    //         HBLHAWBNoTo: "",
    //         InstrumentNo: "",
    //         LCNumberIn: "",
    //         MBLMAWBFrom: "",
    //         MBLMAWBNoIn: "",
    //         MBLMAWBTo: "",
    //         OrgUnitIn: "",
    //         OverduedaysFrom: "",
    //         OverduedaysTo: "",
    //         ProjectCodeIn: "",
    //         ProjectOUIn: "",
    //         ReceiptCategoryIn: "",
    //         SalesPersoncodeIn: "",
    //         SalesPersonNameIn: "",
    //         AddlFieldIn1: "",
    //         AddlFieldIn2: "",
    //         AddlFieldIn3: "",
    //         AddlFieldIn4: "",
    //         AddlFieldIn5: "",
    //         BookingNoFrom: "",
    //         BookingNoIn: "",
    //         BookingNoTo: "",
    //         BusinessUnitIn: "",
    //         CollectorCode: "",
    //         CompanyIn: "",
    //         CrDocCurrencyIn: "",
    //         CrDocFinanceBookIn: "",
    //         CrDocGroupIn: "",
    //         CRDocNoIn: "",
    //         CrDocTypeIn: "",
    //         CustAddID: "",
    //         CustomerCodeIn: selectedCustomer?.customerCode,
    //         CustomerGroupIn: "",
    //         Customerhierarchy: "",
    //         CustomerNameIn: "",
    //         DocAmountFrom: "",
    //         DocAmountTo: "",
    //         DocDateFrom: "",
    //         DocDateTo: "",

    //         CustomerCodeFrom: "",
    //         CustomerCodeTo: "",
    //     },
    //     skip: selectedCustomer?.customerCode ? false : true,
    //     header: {
    //         ou: currentContext?.ouId,
    //         role: currentContext?.roleName,
    //         lang: currentContext?.langId,
    //     },
    // });

    // useEffect(() => {
    //     if (InvoiceStatusData?.InvoiceStatusCUS) {
    //         setInvoiceStatusDatasetarr(InvoiceStatusData?.InvoiceStatusCUS);
    //     }
    // }, [InvoiceStatusData]);
    // useEffect(() => {
    //     if (InvoiceStatusDataError && !InvoiceStatusDataLoading) {
    //         const data = JSON.parse(JSON.stringify(InvoiceStatusDataError));
    //         const errmsg = data?.response?.errors[0].message;
    //         seterr({
    //             code: 400,
    //             msg: errmsg,
    //         });
    //         setAlert(true);
    //     }
    // }, [InvoiceStatusDataError, InvoiceStatusDataLoading]);

    // useEffect(() => {
    //     invoiceStatusDatasetarr?.map((el) => {
    //         // if (el.lable === "pastdue") {
    //         if (el.lable === "duethisweek") {
    //             setInvoiceStatusdataobj((prev) => {
    //                 let newinv = { ...prev };

    //                 // newinv.color[1] = el.color;
    //                 newinv.partVal[1] = el.partVal;
    //                 newinv.value[1] = el.value;
    //                 newinv.valuedisp[1] = el.valuedisp;

    //                 return newinv;
    //             });
    //             //} else if (el.lable === "withinduedate") {
    //         } else if (el.lable === "notdue") {
    //             setInvoiceStatusdataobj((prev) => {
    //                 let newinv = { ...prev };
    //                 newinv.lable[0] = el.lable;
    //                 // newinv.color[0] = el.color;
    //                 newinv.partVal[0] = el.partVal;
    //                 newinv.value[0] = el.value;
    //                 newinv.valuedisp[0] = el.valuedisp;

    //                 return newinv;
    //             });
    //             //} else if (el.lable === "totalinvoice") {
    //         } else if (el.lable === "all") {
    //             setTotal((prev: any) => {
    //                 return {
    //                     accountedFor: el.partVal,
    //                     accountedVal: el.valuedisp,
    //                 };
    //             });
    //         }
    //     });
    // }, [invoiceStatusDatasetarr, InvoiceStatusData]);

    //Common API
    const [currency, setAPICurrency] = useState();
    const {
        data: CommonAPIQueryData,
        error: CommonAPIQueryError,
        isLoading: CommonAPIQueryLoading,
    } = useGetPosts({
        name: "CommonAPIQuery_",
        query: CommonAPIQuery,
        variables: {
            ou: currentContext?.ouId,
            user: contextValues?.loginUser,
            langId: currentContext?.langId,
            portalCode: portalKey
                ? customerData?.supplierCode
                : selectedCustomer?.customerCode,
        },
        skip: contextValues.commonAPIToggle,
    });

    useEffect(() => {
        if (CommonAPIQueryData?.CommonAPIQuery) {
            setCommonValues(CommonAPIQueryData?.CommonAPIQuery);
            const currency = CommonAPIQueryData?.CommonAPIQuery?.currency;
            setCurrency(currency ? currency : "USD");
            setAPICurrency(currency ? currency : "USD");
        }
    }, [CommonAPIQueryData, setSelectedCustomer]);

    const [addUser, setAdduser] = useState(false);
    const [deductionDrawer, setdeductionDrawer] = useState(false);
    const [franchiseStatementDrawer, setfranchiseStatementDrawer] = useState(
        false,
    );
    const [newSupp, setNewSupp] = useState(false);
    const [open, setOpen] = useState<boolean>(false);

    // const {
    //     data: ContractHeaderData,
    //     error: ContractHeaderDataError,
    //     isLoading: ContractHeaderDataLoading,
    // } = useGetPosts({
    //     name: "ContractHeaderDataCusQuery_DashBoard",
    //     query: ContractHeaderDataCusQuery,
    //     variables: {
    //         entity: "OW_Cust_portal_status",
    //         ou: currentContext?.ouId,
    //         service: "",
    //         User: selectedCustomer?.loginUser,
    //         languageId: currentContext?.langId,
    //         customerCode: selectedCustomer?.customerCode,
    //         merrorid: "0",
    //     },
    //     skip: selectedCustomer?.customerCode ? false : true,
    // });

    // useEffect(() => {
    //     if (ContractHeaderData?.ContractHeaderDataCus) {
    //         setDashboardCount((prev: any) => ({
    //             ...prev,
    //             contracts:
    //                 ContractHeaderData?.ContractHeaderDataCus?.detail[0]?.value,
    //         }));
    //     }
    // }, [ContractHeaderData]);

    // useEffect(() => {
    //     if (ContractHeaderDataError && !ContractHeaderDataLoading) {
    //         const data = JSON.parse(JSON.stringify(ContractHeaderDataError));
    //         const errmsg = data?.response?.errors[0].message;
    //         seterr({
    //             code: 400,
    //             msg: errmsg,
    //         });
    //         setAlert(true);
    //     }
    // }, [ContractHeaderDataError, ContractHeaderDataLoading]);

    // const {
    //     data: InvoiceHeaderData,
    //     error: InvoiceHeaderDataError,
    //     isLoading: InvoiceHeaderDataLoading,
    // } = useGetPosts({
    //     name: "InvoiceHeaderDetailsCusQuery_Dashboard",
    //     query: InvoiceHeaderDetailsCusQuery,
    //     variables: {
    //         entity: "OW_Cust_portal_InvStatus",
    //         ou: currentContext?.ouId,
    //         service: "",
    //         User: selectedCustomer.loginUser,
    //         languageId: currentContext?.langId,
    //         customerName: "",
    //         customerCode: selectedCustomer?.customerCode,
    //         merrorid: "",
    //     },
    //     skip: selectedCustomer?.customerCode ? false : true,
    // });

    // useEffect(() => {
    //     if (InvoiceHeaderData?.InvoiceHeaderDetailsCus) {
    //         setDashboardCount((prev: any) => ({
    //             ...prev,
    //             invoices:
    //                 InvoiceHeaderData?.InvoiceHeaderDetailsCus?.detail[0]
    //                     ?.value,
    //         }));
    //     }
    // }, [InvoiceHeaderData]);

    // useEffect(() => {
    //     if (InvoiceHeaderDataError && !InvoiceHeaderDataLoading) {
    //         const data = JSON.parse(JSON.stringify(InvoiceHeaderDataError));
    //         const errmsg = data?.response?.errors[0].message;
    //         seterr({
    //             code: 400,
    //             msg: errmsg,
    //         });
    //         setAlert(true);
    //     }
    // }, [InvoiceHeaderDataError, InvoiceHeaderDataLoading]);

    // const {
    //     data: BuildingData,
    //     error: BuildingError,
    //     isLoading: BuildingLoading,
    // } = useGetPosts({
    //     name: "BuildingQueryCUS_dashboard",
    //     query: BuildingQueryCUS,
    //     variables: {
    //         entity: "OW_Cust_portal_BuildingSmry",
    //         ou: currentContext?.ouId,
    //         service: "",
    //         User: selectedCustomer?.loginUser,
    //         languageId: currentContext?.langId,
    //         customerCode: selectedCustomer?.customerCode,
    //         customerName: "",
    //         merrorid: "",
    //     },
    //     skip: selectedCustomer?.customerCode ? false : true,
    // });

    // useEffect(() => {
    //     if (BuildingData?.BuildingQueryCUS) {
    //         setDashboardCount((prev: any) => ({
    //             ...prev,
    //             buildings: BuildingData?.BuildingQueryCUS?.length,
    //         }));
    //     }
    // }, [BuildingData]);

    // useEffect(() => {
    //     if (BuildingError && !BuildingLoading) {
    //         const data = JSON.parse(JSON.stringify(BuildingError));
    //         const errmsg = data?.response?.errors[0].message;
    //         seterr({
    //             code: 400,
    //             msg: errmsg,
    //         });
    //         setAlert(true);
    //     }
    // }, [BuildingError, BuildingLoading]);

    // const {
    //     data: ServiceProvider,
    //     error: ServiceProviderError,
    //     isLoading: ServiceProviderLoading,
    // } = useGetPosts({
    //     name: "ServiceProviderCusQuery_DashBoard",
    //     query: ServiceProviderCountQuery,
    //     variables: {
    //         entity: "OW_portal_servprovidercontractcount",
    //         ou: currentContext?.ouId,
    //         service: "",
    //         user: selectedCustomer?.loginUser,
    //         langId: currentContext?.langId,
    //         supplierCode: vendorData?.supplierCode,
    //         merrorid: "",
    //     },
    //     skip: contextValues.commonAPIToggle || !portalKey,
    // });

    // useEffect(() => {
    //     if (ServiceProvider?.ServiceProviderCount) {
    //         setDashboardCountVen((prev: any) => ({
    //             ...prev,
    //             serviceProviderCount:
    //                 ServiceProvider?.ServiceProviderCount[0]?.contractcount,
    //         }));
    //     }
    // }, [ServiceProvider]);

    // useEffect(() => {
    //     if (ServiceProviderError && !ServiceProviderLoading) {
    //         const data = JSON.parse(JSON.stringify(ServiceProviderError));
    //         const errmsg = data?.response?.errors[0].message;
    //         seterr({
    //             code: 400,
    //             msg: errmsg,
    //         });
    //         setAlert(true);
    //     }
    // }, [ServiceProviderError, ServiceProviderLoading]);

    // const {
    //     data: PayAdviceHeaderData,
    //     error: PayAdviceHeaderDataError,
    //     isLoading: PayAdviceHeaderDataLoading,
    // } = useGetPosts({
    //     name: `PayAdviceHeaderDataVenQuery_${customerData?.supplierCode}`,
    //     query: PayAdviceHeaderDataVenQuery,
    //     variables: {
    //         entity: "OW_vendor_pay_advice_status",
    //         ou: currentContext?.ouId,
    //         service: "",
    //         User: customerData?.loginUser,
    //         langId: currentContext?.langId,
    //         vendorName: customerData?.supplierName,
    //         vendorCode: customerData?.supplierCode,
    //         merrorid: "",
    //     },
    // });

    // useEffect(() => {
    //     if (PayAdviceHeaderData?.PayAdviceHeaderDataVen) {
    //         setDashboardCountVen((prev: any) => ({
    //             ...prev,
    //             payAdvice:
    //                 PayAdviceHeaderData?.PayAdviceHeaderDataVen?.detail[0]
    //                     ?.value,
    //         }));
    //     }
    // }, [PayAdviceHeaderData]);

    // useEffect(() => {
    //     if (PayAdviceHeaderDataError && !PayAdviceHeaderDataLoading) {
    //         const data = JSON.parse(JSON.stringify(PayAdviceHeaderDataError));
    //         const errmsg = data?.response?.errors[0].message;
    //         seterr({
    //             code: 400,
    //             msg: errmsg,
    //         });
    //         setAlert(true);
    //     }
    // }, [PayAdviceHeaderDataError, PayAdviceHeaderDataLoading]);

    // const {
    //     data: OrderSuppliesHeaderData,
    //     error: OrderSuppliesHeaderDataError,
    //     isLoading: OrderSuppliesHeaderDataLoading,
    // } = useGetPosts({
    //     name: "OrderSuppliesHeaderCusQuery_Dashboard",
    //     query: OrderSuppliesHeaderCusQuery,
    //     variables: {
    //         langId: currentContext?.langId,
    //         ouId: currentContext?.ouId,
    //         roleName: currentContext?.roleName,
    //         service: "",
    //         userName: portalKey ? vendorData?.loginUser : userName,
    //         customerCode: portalKey
    //             ? vendorData?.customerCode
    //             : selectedCustomer?.customerCode,
    //     },
    //     skip: contextValues.commonAPIToggle,
    // });

    // useEffect(() => {
    //     if (OrderSuppliesHeaderData?.OrderSuppliesHeaderCus) {
    //         setDashboardCount((prev: any) => ({
    //             ...prev,
    //             orderSupply:
    //                 OrderSuppliesHeaderData?.OrderSuppliesHeaderCus?.detail[0]
    //                     ?.value,
    //         }));
    //         setDashboardCountVen((prev: any) => ({
    //             ...prev,
    //             orderSupply:
    //                 OrderSuppliesHeaderData?.OrderSuppliesHeaderCus?.detail[0]
    //                     ?.value,
    //         }));
    //     }
    // }, [OrderSuppliesHeaderData]);

    // useEffect(() => {
    //     if (OrderSuppliesHeaderDataError && !OrderSuppliesHeaderDataLoading) {
    //         const data = JSON.parse(
    //             JSON.stringify(OrderSuppliesHeaderDataError),
    //         );
    //         const errmsg = data?.response?.errors[0].message;
    //         seterr({
    //             code: 400,
    //             msg: errmsg,
    //         });
    //         setAlert(true);
    //     }
    // }, [OrderSuppliesHeaderDataError, OrderSuppliesHeaderDataLoading]);

    const {
        data: ticketData,
        error: ticketDataError,
        isLoading: ticketDataLoading,
    } = useGetPosts({
        name: `TicketData_countquery_dashboard${customerData?.hubspotCustomerId}`,
        query: TicketDataCUS,
        variables: {
            companyId: customerData?.hubspotCustomerId,
            pipelineId: !portalKey
                ? Configuration.getConfiguration().getCustomerPortalPipeline()
                : Configuration.getConfiguration().getVendorPortalPipeline(),
            customerCode: customerData?.customerCode,
            ou: currentContext?.ouId,
            service: "",
            user: customerData?.loginUser,
            langId: currentContext?.langId,
            role: currentContext?.roleName,
            status: "open",
            ticketNumber: "",
        },
        skip: customerData?.hubspotCustomerId ? false : true,
    });
    useEffect(() => {
        if (ticketData?.TicketDataCUS) {
            setTicketCardData((ticketData?.TicketDataCUS).slice(0, 3));
            // setDashboardCount((prev: any) => ({
            //     ...prev,
            //     tickets: ticketData?.TicketDataCUS?.length,
            // }));
            // setDashboardCountVen((prev: any) => ({
            //     ...prev,
            //     tickets: ticketData?.TicketDataCUS?.length,
            // }));
        }
    }, [ticketData]);

    useEffect(() => {
        if (ticketDataError && !ticketDataLoading) {
            const data = JSON.parse(JSON.stringify(ticketDataError));
            const errmsg = data?.response?.errors[0].message;
            // seterr({
            //     code: 400,
            //     msg: errmsg,
            // });
            // setAlert(true);
        }
    }, [ticketDataError, ticketDataLoading]);

    const [pendingCardData, setPendingCardData] = useState<any>();
    const {
        data: pendingApprovalDetailsData,
        error: pendingApprovalDetailsError,
        isLoading: pendingApprovalDetailsLoading,
    } = useGetPosts({
        name: `pendingApprovalDetailsQuery_${vendorData?.supplierCode}_${vendorData?.loginUser}`,
        query: pendingApprovalDetailsQuery,
        variables: {
            entity: "OW_Vendor_portal_getpostatus",
            ou: currentContext?.ouId,
            service: "",
            User: vendorData?.loginUser,
            languageId: currentContext?.langId,
            vendorCode: vendorData?.supplierCode,
            vendorName: vendorData?.supplierName,
            status: "",
            merrorid: "",
        },
        skip: contextValues.commonAPIToggle || !portalKey || subContractorKey,
    });
    console.log("pendingApprovalDetails", pendingCardData);
    useEffect(() => {
        // const PAD=[
        //     {
        //         "Customercontract": null,
        //         "Offerdate": "",
        //         "Offernumber": null,
        //         "amountoffer": null,
        //         "custname": null,
        //         "startdate": "",
        //         "suppname": null,
        //         "declineReason": null,
        //         "custcode": null,
        //         "guid": null,
        //         "status": null,
        //         "supplierCode": null,
        //         "TypeOfService": null
        //     }
        // ]
        if (pendingApprovalDetailsData?.pendingApprovalDetails ) {
            
                setPendingCardData(
                    pendingApprovalDetailsData?.pendingApprovalDetails,
                );
        }
    }, [pendingApprovalDetailsData]);

    useEffect(() => {
        if (pendingApprovalDetailsError && !pendingApprovalDetailsLoading) {
            const data = JSON.parse(
                JSON.stringify(pendingApprovalDetailsError),
            );
            const errmsg = data?.response?.errors[0].message;
            // seterr({
            //     code: 400,
            //     msg: errmsg,
            // });
            // setAlert(true);
            //later uncomment it when this sp is working properly check with mohan
        }
    }, [pendingApprovalDetailsError, pendingApprovalDetailsLoading]);

    const [LoanSummary, setLoanSummary] = useState();
    const {
        data: LoanSummaryData,
        error: LoanSummaryDataError,
        isLoading: LoanSummaryDataLoading,
    } = useGetPosts({
        name: `loanBalanceSummary-DSB_${vendorData?.supplierCode}`,
        query: LoanBalanceSummaryQuery,
        variables: {
            entity: "ow_getcurr_loan_bal_summ_entity",
            ou: currentContext?.ouId,
            service: "",
            User: vendorData?.loginUser,
            languageId: currentContext?.langId,
            supplierCode: vendorData?.supplierCode,
            callingfrom: "DSB",
            status: "",
        },
        skip: contextValues.commonAPIToggle || !portalKey || subContractorKey,
    });

    useEffect(() => {
        if (LoanSummaryData?.loanBalanceSummary) {
            console.log("LoanSummaryData", LoanSummaryData?.loanBalanceSummary);
            setLoanSummary(LoanSummaryData?.loanBalanceSummary);
        }
    }, [LoanSummaryData]);

    useEffect(() => {
        if (LoanSummaryDataError && !LoanSummaryDataLoading) {
            const data = JSON.parse(JSON.stringify(LoanSummaryDataError));
            const errmsg = data?.response?.errors[0].message;
            seterr({
                code: 400,
                msg: errmsg,
            });
            setAlert(true);
        }
    }, [LoanSummaryDataError, LoanSummaryDataLoading]);

    const [
        VolumeAndAcceptanaceSummary,
        setVolumeAndAcceptanaceSummary,
    ] = useState<any>();
    const {
        data: VolumeAndAcceptanaceData,
        error: VolumeAndAcceptanaceDataError,
        isLoading: VolumeAndAcceptanaceDataLoading,
    } = useGetPosts({
        name: `VolumeAndAcceptanaceQuery-DSB_${vendorData?.supplierCode}_${vendorData?.loginUser}`,
        query: VolumeAndAcceptanaceQuery,
        variables: {
            entity: "ow_get_bpo_totvol_api_entity",
            ou: currentContext?.ouId,
            service: "",
            User: vendorData?.loginUser,
            languageId: currentContext?.langId,
            supplierCode: vendorData?.supplierCode,
        },
        skip: contextValues.commonAPIToggle || !portalKey,
    });

    useEffect(() => {
        if (VolumeAndAcceptanaceData?.VolumeAndAcceptanace) {
            console.log(
                "VolumeAndAcceptanaceData",
                VolumeAndAcceptanaceData?.VolumeAndAcceptanace,
            );
            setVolumeAndAcceptanaceSummary(
                VolumeAndAcceptanaceData?.VolumeAndAcceptanace,
            );
        }
    }, [VolumeAndAcceptanaceData]);

    useEffect(() => {
        if (VolumeAndAcceptanaceDataError && !VolumeAndAcceptanaceDataLoading) {
            const data = JSON.parse(
                JSON.stringify(VolumeAndAcceptanaceDataError),
            );
            const errmsg = data?.response?.errors[0].message;
            seterr({
                code: 400,
                msg: errmsg,
            });
            setAlert(true);
        }
    }, [VolumeAndAcceptanaceDataError, VolumeAndAcceptanaceDataLoading]);

    const [RatingLoanIndex, setRatingLoanIndex] = useState<any>();
    const {
        data: VendorRatingLoanIndexData,
        error: VendorRatingLoanIndexError,
        isLoading: VendorRatingLoanIndexLoading,
    } = useGetPosts({
        name: `VendorRatingLoanIndex${
            !portalKey
                ? Configuration.getConfiguration().getCustomerPortalPipeline()
                : Configuration.getConfiguration().getVendorPortalPipeline()
        }_${customerData?.hubspotCustomerId}`,
        query: VendorRatingLoanIndex,
        variables: {
            companyID: customerData?.hubspotCustomerId,
        },
        skip: customerData?.hubspotCustomerId ? false : true,
    });
    useEffect(() => {
        if (VendorRatingLoanIndexData?.VendorRatingLoanIndex) {
            console.log(
                "VendorRatingLoanIndexData",
                VendorRatingLoanIndexData?.VendorRatingLoanIndex,
            );
            setRatingLoanIndex(
                VendorRatingLoanIndexData?.VendorRatingLoanIndex,
            );
        }
    }, [VendorRatingLoanIndexData]);

    useEffect(() => {
        if (VendorRatingLoanIndexError && !VendorRatingLoanIndexLoading) {
            const data = JSON.parse(JSON.stringify(VendorRatingLoanIndexError));
            const errmsg = data?.response?.errors[0].message;
            seterr({
                code: 400,
                msg: errmsg,
            });
            setAlert(true);
        }
    }, [VendorRatingLoanIndexError, VendorRatingLoanIndexLoading]);

    //customer portal
    const [invoiceStatusDatasetarr, setInvoiceStatusDatasetarr] = useState<
        InvStatusDet[]
    >([]);
    const [invoiceStatusDataobj, setInvoiceStatusdataobj] = useState<InvStatus>(
        {
            lable: ["With In Due Date", "Past Due"],
            partVal: [0, 0],
            value: [0, 0],
            color: ["rgb(54, 162, 235)", "rgb(255, 99, 132)"],
            borderColor: "rgb(151,157,172)",
            valuedisp: ["0", "0"],
        },
    );
    const [total, setTotal] = useState({
        accountedFor: 0,
        accountedVal: "0",
    });
    const {
        data: InvoiceStatusData,
        error: InvoiceStatusDataError,
        isLoading: InvoiceStatusDataLoading,
    } = useGetPosts({
        name: `InvoiceStatusCUS_${selectedCustomer?.customerCode}`,
        query: InvoiceStatusCUS,
        variables: {
            DocFlag: "Debit",
            DocNoFrom: "",
            DocNoTo: "",
            DocStatusIn: "",
            DrDocCurrencyIn: "",
            DrDocFinanceBookIn: "",
            DrDocGroupIn: "",
            DrDocNoIn: "",
            DrDocTypeIn: "Order Invoice",
            DueAmountFrom: "",
            DueAmountTo: "",
            DueDateFrom: "",
            DueDateTo: "",
            DueFlag: "",
            HBLHAWBNoFrom: "",
            HBLHAWBNoIn: "",
            HBLHAWBNoTo: "",
            InstrumentNo: "",
            LCNumberIn: "",
            MBLMAWBFrom: "",
            MBLMAWBNoIn: "",
            MBLMAWBTo: "",
            OrgUnitIn: "",
            OverduedaysFrom: "",
            OverduedaysTo: "",
            ProjectCodeIn: "",
            ProjectOUIn: "",
            ReceiptCategoryIn: "",
            SalesPersoncodeIn: "",
            SalesPersonNameIn: "",
            AddlFieldIn1: "",
            AddlFieldIn2: "",
            AddlFieldIn3: "",
            AddlFieldIn4: "",
            AddlFieldIn5: "",
            BookingNoFrom: "",
            BookingNoIn: "",
            BookingNoTo: "",
            BusinessUnitIn: "",
            CollectorCode: "",
            CompanyIn: "",
            CrDocCurrencyIn: "",
            CrDocFinanceBookIn: "",
            CrDocGroupIn: "",
            CRDocNoIn: "",
            CrDocTypeIn: "",
            CustAddID: "",
            CustomerCodeIn: selectedCustomer?.customerCode,
            CustomerGroupIn: "",
            Customerhierarchy: "",
            CustomerNameIn: "",
            DocAmountFrom: "",
            DocAmountTo: "",
            DocDateFrom: "",
            DocDateTo: "",

            CustomerCodeFrom: "",
            CustomerCodeTo: "",
        },
        skip: selectedCustomer?.customerCode ? false : true,
        header: {
            ou: currentContext?.ouId,
            role: currentContext?.roleName,
            lang: currentContext?.langId,
        },
    });

    useEffect(() => {
        if (InvoiceStatusData?.InvoiceStatusCUS) {
            setInvoiceStatusDatasetarr(InvoiceStatusData?.InvoiceStatusCUS);
        }
    }, [InvoiceStatusData]);
    useEffect(() => {
        if (InvoiceStatusDataError && !InvoiceStatusDataLoading) {
            const data = JSON.parse(JSON.stringify(InvoiceStatusDataError));
            const errmsg = data?.response?.errors[0].message;
            seterr({
                code: 400,
                msg: errmsg,
            });
            setAlert(true);
        }
    }, [InvoiceStatusDataError, InvoiceStatusDataLoading]);

    useEffect(() => {
        invoiceStatusDatasetarr?.map((el) => {
            // if (el.lable === "pastdue") {
            if (el.lable === "duethisweek") {
                setInvoiceStatusdataobj((prev) => {
                    let newinv = { ...prev };

                    // newinv.color[1] = el.color;
                    newinv.partVal[1] = el.partVal;
                    newinv.value[1] = el.value;
                    newinv.valuedisp[1] = el.valuedisp;

                    return newinv;
                });
                //} else if (el.lable === "withinduedate") {
            } else if (el.lable === "notdue") {
                setInvoiceStatusdataobj((prev) => {
                    let newinv = { ...prev };
                    newinv.lable[0] = el.lable;
                    // newinv.color[0] = el.color;
                    newinv.partVal[0] = el.partVal;
                    newinv.value[0] = el.value;
                    newinv.valuedisp[0] = el.valuedisp;

                    return newinv;
                });
                //} else if (el.lable === "totalinvoice") {
            } else if (el.lable === "all") {
                setTotal((prev: any) => {
                    return {
                        accountedFor: el.partVal,
                        accountedVal: el.valuedisp,
                    };
                });
            }
        });
    }, [invoiceStatusDatasetarr, InvoiceStatusData]);
    // const CusList = [
    //     {
    //         title: "Contracts",
    //         status: "Active",
    //         count: dashboardCount?.contracts,
    //         iconDisp: "coloredContracts",
    //         color: "#FF6D6D",
    //         backgroundColor: "#FFF8F8",
    //         routename: "Contracts",
    //         code: "contracts",
    //     },
    //     {
    //         title: "Buildings",
    //         status: "Active",
    //         count: dashboardCount?.buildings,
    //         iconDisp: "coloredBuilding",
    //         color: "#7660F5",
    //         backgroundColor: "#F8F7FF",
    //         routename: "Buildings",
    //         code: "buildings",
    //     },
    //     {
    //         title: "Invoices",
    //         status: "Last 90 Days",
    //         count: dashboardCount?.invoices,
    //         iconDisp: "coloredInvoices",
    //         color: "#00AE50",
    //         backgroundColor: "#F6FEF8",
    //         routename: "Invoices",
    //         code: "invoices",
    //     },
    //     {
    //         title: "Tickets",
    //         status: "Last 90 Days",
    //         count: dashboardCount?.tickets,
    //         iconDisp: "coloredTickets",
    //         color: "#00A0D6",
    //         backgroundColor: "#F2FBFE",
    //         routename: "Tick",
    //         code: "tickets",
    //     },
    //     {
    //         title: "Order Supplies",
    //         status: "Last 90 Days",
    //         count: dashboardCount?.orderSupply,
    //         iconDisp: "coloredOrders",
    //         color: "#DD9200",
    //         backgroundColor: "#FDFAF2",
    //         routename: "OrderSupplies",
    //         code: "orderSupply",
    //     },
    // ];

    // const [NavList, setNavList] = useState(CusList);

    // useEffect(() => {
    //     if (portalKey) setNavList(VendorNav);
    // }, [portalKey]);

    // const handleClick = () => {
    //     setOpen((prev) => !prev);
    // };

    const [tickdet, setTickDet] = useState("");
    if (redir) {
        return (
            <Redirect
                to={{
                    pathname: `/app/*/${routeName}`,
                    state: { tickdet },
                }}
            />
        );
    }

    if (calendarRedirect) {
        return (
            <Redirect
                to={{
                    pathname: `/app/*/CalendarSchedule`,
                }}
            />
        );
    }

    if (contractDet) {
        return (
            <Redirect
                to={{
                    pathname: portalKey
                        ? "/app/*/CONTDETSV"
                        : "/app/*/contractDet",
                    state: {
                        contractSelected: quickViewData,
                    },
                }}
            />
        );
    }

    if (loanSummaryRedirect) {
        return (
            <Redirect
                to={{
                    pathname: "/app/*/LoansVEN",
                }}
            />
        );
    }
    return (
        <>
            {alert ? handleErrorAlert(errr.code, errr.msg) : <></>}
            <BackdropLoading
                openStates={
                    CustomerDataLoading ||
                    VendorDataLoading ||
                    CommonAPIQueryLoading ||
                    VolumeAndAcceptanaceDataLoading ||
                    pendingApprovalDetailsLoading ||
                    ticketDataLoading
                }
            />
            {portalKey ? (
                subContractorKey ? (
                    <Grid container spacing={3} style={{ marginTop: "10px" }}>
                        <Grid item xs={6}>
                            <Grid container spacing={2} alignItems="center">
                                <Grid
                                    item
                                    xs={6}
                                    className="text-left font-bigger font-boldest"
                                >
                                    Service Calendar
                                </Grid>
                                <Grid
                                    item
                                    xs={6}
                                    style={{ textAlign: "right" }}
                                >
                                    <div
                                        className="pointer"
                                        onClick={() => {
                                            setCalendarRedirect(true);
                                        }}
                                    >
                                        <Icons Icon={"redirectIcon"} />
                                    </div>
                                </Grid>
                                <Grid
                                    item
                                    xs={12}
                                    className="grey-scroll"
                                    style={{ overflow: "auto" }}
                                >
                                    <Calendar />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={6}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Grid container>
                                        <TicketCarouselSubcon
                                            ticketdetails={ticketCardData}
                                            switchRoute={SwitchRoute}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <DashCards
                                        header={
                                            subContractorKey
                                                ? "Total Volume"
                                                : "Total Recurring Volume"
                                        }
                                        headerColor="#E9FFE8"
                                        currency={currency}
                                        detail={[
                                            {
                                                desc: "Assigned",
                                                value:
                                                    VolumeAndAcceptanaceSummary?.assigned,
                                                color: "brown",
                                                icon: <img src={Assigned} />,
                                            },
                                        ]}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <PerformanceRatingCard
                                        ratingValue={
                                            RatingLoanIndex?.vendorRating
                                        }
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                ) : (
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={8}>
                            <Grid container spacing={2}>
                                <Grid
                                    item
                                    xs={12}
                                    className="text-left font-boldest font-big"
                                    style={{ padding: "3% 1.5%" }}
                                >
                                    Overview
                                </Grid>
                                <Grid item xs={6}>
                                    <DashCards
                                        header="Total Recurring Volume"
                                        headerColor="#E9FFE8"
                                        currency={currency}
                                        detail={[
                                            {
                                                desc: "Assigned",
                                                value:
                                                    VolumeAndAcceptanaceSummary?.assigned,
                                                color: "brown",
                                                icon: <img src={Assigned} />,
                                            },
                                        ]}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    {/* <Paper
                                        elevation={3}
                                        style={{ minHeight: "calc(100% + 2px)" }}
                                    >
                                        <img
                                            style={{ paddingTop: "3%" }}
                                            src={ComingSoon}
                                            alt="Coming Soon"
                                        />
                                    </Paper> */}
                                    <DashCards
                                        header="Volume Owed"
                                        headerColor="#E9FBFF"
                                        currency={currency}
                                        detail={[
                                            {
                                                desc: "Initial",
                                                value:
                                                    VolumeAndAcceptanaceSummary?.initial,
                                                color: "cyan",
                                                icon: <img src={InitVolume} />,
                                            },
                                            // {
                                            //     desc: "Advol.",
                                            //     value:
                                            //         VolumeAndAcceptanaceSummary?.advol,
                                            //     color: "cyan",
                                            //     icon: <img src={AdvlVolume} />,
                                            // },
                                            {
                                                desc: "Replaceable",
                                                value:
                                                    VolumeAndAcceptanaceSummary?.replaceable,
                                                color: "cyan",
                                                icon: (
                                                    <img
                                                        src={ReplaceableVolume}
                                                    />
                                                ),
                                            },
                                        ]}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <AcceptanceCard
                                        detail={{
                                            Offeredvalue:
                                                VolumeAndAcceptanaceSummary?.offered,
                                            Acceptedvalue:
                                                VolumeAndAcceptanaceSummary?.accepted,
                                            Refusalvalue:
                                                VolumeAndAcceptanaceSummary?.refusal,
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <PerformanceRatingCard
                                        ratingValue={
                                            RatingLoanIndex?.vendorRating
                                        }
                                    />
                                </Grid>
                                <Grid
                                    item
                                    xs={6}
                                    className="pointer"
                                    onClick={() => {
                                        setLoanSummaryRedirect(true);
                                    }}
                                >
                                    <LoanBalanceCard
                                        LoanSummary={LoanSummary}
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <LoanIndexCard
                                        loanIndex={RatingLoanIndex?.loanIndex}
                                    />
                                </Grid>

                                <Grid
                                    item
                                    xs={3}
                                    className="pointer"
                                    onClick={() => {
                                        setCalendarRedirect(true);
                                    }}
                                >
                                    <Paper
                                        elevation={3}
                                        style={{
                                            minHeight: "calc(100% + 10px)",
                                        }}
                                    >
                                        <Grid
                                            container
                                            spacing={1}
                                            alignItems="center"
                                            justifyContent="center"
                                            style={{ paddingTop: "5%" }}
                                        >
                                            <Grid item xs={12}>
                                                <img
                                                    src={CalendarSchedule}
                                                    // style={{
                                                    //     transform: "scale(1.5)",
                                                    // }}
                                                />
                                            </Grid>
                                            <Grid
                                                item
                                                xs={9}
                                                className="blue font-bolder"
                                            >
                                                Calendar Schedule
                                            </Grid>
                                            <Grid item xs={3}>
                                                <ArrowForwardIosIcon fontSize="small" />
                                            </Grid>
                                        </Grid>
                                    </Paper>
                                </Grid>
                                {/* <Grid item xs={6}>
                                    <Grid container spacing={2}>
                                      
                                    </Grid>
                                </Grid> */}
                            </Grid>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Grid container spacing={1}>
                                <Grid item xs={12}>
                                    <Grid
                                        container
                                        style={{ paddingTop: "1%" }}
                                    >
                                        <TicketCarousel
                                            ticketdetails={ticketCardData}
                                            switchRoute={SwitchRoute}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container>
                                        <PendingApproval
                                            PendingApprovalData={
                                                pendingCardData
                                            }
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container>
                                        <Grid
                                            item
                                            xs={12}
                                            className="text-left font-boldest font-big"
                                            style={{ padding: "1% 1.5%" }}
                                        >
                                            Quick View
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Paper
                                                elevation={4}
                                                style={{ padding: "2%" }}
                                            >
                                                <Grid
                                                    container
                                                    justifyContent="flex-start"
                                                    alignItems="center"
                                                    spacing={2}
                                                >
                                                    <Grid item xs={4}>
                                                        Contract No.
                                                    </Grid>
                                                    <Grid item xs={6} >
                                                        <TextFields
                                                            label={""}
                                                            id={"contractNo"}
                                                            value={
                                                                quickViewData
                                                            }
                                                            handleChange={(
                                                                e: any,
                                                            ) => {
                                                                setQuickViewData(
                                                                    e.target
                                                                        .value,
                                                                );
                                                            }}
                                                            width={"100%"}
                                                            curvedBorder={true}
                                                            placeholder={"Enter Contract No..."}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={2}>
                                                        <Paper
                                                            className="filled"
                                                            elevation={1}
                                                            style={{
                                                                textAlign:
                                                                    "center",
                                                                maxHeight:
                                                                    "50px",
                                                            }}
                                                            onClick={() => {
                                                                setContractDet(
                                                                    true,
                                                                );
                                                            }}
                                                        >
                                                            <ArrowForwardIcon />
                                                        </Paper>
                                                    </Grid>
                                                </Grid>
                                            </Paper>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                )
            ) : (
                <Grid container spacing={3} style={{ marginTop: "10px" }}>
                    <Grid item xs={12} md={5} lg={5}>
                        <Grid container spacing={2}>
                            <Grid
                                item
                                xs={6}
                                className="text-left font-bigger font-boldest"
                            >
                                Service Calendar
                            </Grid>
                            <Grid item xs={6} style={{ textAlign: "right" }}>
                                <div
                                    className="pointer"
                                    onClick={() => {
                                        setCalendarRedirect(true);
                                    }}
                                >
                                    <Icons Icon={"redirectIcon"} />
                                </div>
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                className="grey-scroll"
                                style={{ overflow: "auto" }}
                            >
                                <Calendar />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={6} md={4}>
                        <Grid container>
                            <Grid item xs={12}>
                                <Grid
                                    container
                                    spacing={2}
                                    style={{ marginBottom: "4%" }}
                                >
                                    <Grid
                                        item
                                        xs={6}
                                        className="text-left font-boldest font-bigger"
                                    >
                                        Invoice Status
                                    </Grid>
                                    <Grid
                                        item
                                        xs={6}
                                        className="text-right font-boldest font-bigger "
                                        style={{ marginLeft: "-35px" }}
                                    >
                                        {[1, 2, 3].map((element, index) => {
                                            return (
                                                <span
                                                    className={`grey2 dot`}
                                                ></span>
                                            );
                                        })}
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                onClick={() => {
                                    SwitchRoute("Invoices", "");
                                }}
                            >
                                <Grid container spacing={2}>
                                    <Paper
                                        elevation={4}
                                        // style={{
                                        //     // minWidth: "480px",
                                        //     minHeight: "177px",
                                        //     // maxWidth: "480px",
                                        //     maxHeight: "177px",
                                        // }}
                                    >
                                        <Grid item xs={12}>
                                            <Grid
                                                container
                                                justifyContent="center"
                                                alignItems="center"
                                            >
                                                <Grid
                                                    item
                                                    xs={6}
                                                    style={{
                                                        padding: "4% 2% 1%",
                                                        display: "flex",
                                                        // justifyContent:
                                                        //     "center",
                                                    }}
                                                >
                                                    <PieChart
                                                        Chardata={
                                                            invoiceStatusDataobj
                                                        }
                                                        maxWidth="150px"
                                                        maxHeight="150px"
                                                    />
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Grid
                                                        container
                                                        justifyContent="center"
                                                        // style={{
                                                        //     padding: "20% 5%",
                                                        // }}
                                                    >
                                                        <Grid item xs={12}>
                                                            <Grid
                                                                container
                                                                justifyContent="flex-start"
                                                                alignItems="center"
                                                            >
                                                                <span
                                                                    className={`dot `}
                                                                ></span>
                                                                <span
                                                                    style={{
                                                                        padding:
                                                                            "2%",
                                                                    }}
                                                                >
                                                                    Total
                                                                    Invoice{" "}
                                                                    <b>
                                                                        {invoiceStatusDatasetarr?.length ===
                                                                        1
                                                                            ? invoiceStatusDatasetarr[0]
                                                                                  .partVal
                                                                            : total?.accountedFor}
                                                                    </b>
                                                                </span>
                                                                <span>
                                                                    <Icons Icon="smallArrowRight" />
                                                                </span>
                                                                <span
                                                                    style={{
                                                                        color:
                                                                            "grey",
                                                                    }}
                                                                >
                                                                    {invoiceStatusDatasetarr?.length ===
                                                                    1
                                                                        ? invoiceStatusDatasetarr[0]
                                                                              .valuedisp
                                                                        : total?.accountedVal}
                                                                </span>
                                                            </Grid>
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <Grid
                                                                container
                                                                justifyContent="flex-start"
                                                                alignItems="center"
                                                            >
                                                                <span className="Completed_dot"></span>
                                                                <span
                                                                    style={{
                                                                        padding:
                                                                            "2%",
                                                                    }}
                                                                >
                                                                    Within Due
                                                                    Date{" "}
                                                                    <b
                                                                        style={{
                                                                            color: `${invoiceStatusDataobj?.color[0]}`,
                                                                        }}
                                                                    >
                                                                        {
                                                                            invoiceStatusDataobj
                                                                                .partVal[0]
                                                                        }
                                                                    </b>
                                                                </span>
                                                                <span>
                                                                    <Icons Icon="smallArrowRight" />
                                                                </span>
                                                                <span
                                                                    style={{
                                                                        color: `${invoiceStatusDataobj?.color[0]}`,
                                                                    }}
                                                                >
                                                                    {
                                                                        invoiceStatusDataobj
                                                                            .valuedisp[0]
                                                                    }
                                                                </span>
                                                            </Grid>
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <Grid
                                                                container
                                                                justifyContent="flex-start"
                                                                alignItems="center"
                                                            >
                                                                <span className="Failed_dot"></span>
                                                                <span
                                                                    style={{
                                                                        padding:
                                                                            "2%",
                                                                    }}
                                                                >
                                                                    Past Due{" "}
                                                                    <b
                                                                        style={{
                                                                            color: `${invoiceStatusDataobj?.color[1]}`,
                                                                        }}
                                                                    >
                                                                        {
                                                                            invoiceStatusDataobj
                                                                                .partVal[1]
                                                                        }
                                                                    </b>
                                                                </span>
                                                                <span>
                                                                    <Icons Icon="smallArrowRight" />
                                                                </span>
                                                                <span
                                                                    style={{
                                                                        color: `${invoiceStatusDataobj?.color[1]}`,
                                                                    }}
                                                                >
                                                                    {
                                                                        invoiceStatusDataobj
                                                                            .valuedisp[1]
                                                                    }
                                                                </span>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Paper>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container style={{ paddingTop: "10%" }}>
                                    <TicketCarousel
                                        ticketdetails={ticketCardData}
                                        switchRoute={SwitchRoute}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={6} md={3}>
                        <Grid container>
                            <Grid item xs={12}>
                                <Grid
                                    container
                                    spacing={1}
                                    justifyContent="flex-start"
                                    alignItems="center"
                                >
                                    <Grid
                                        item
                                        xs={12}
                                        className="text-left font-bigger font-boldest"
                                        style={{ marginBottom: "1%" }}
                                    >
                                        Quick Links
                                    </Grid>
                                    <Grid
                                        item
                                        xs={5}
                                        className="text-left"
                                        style={{ marginTop: "4% !important" }}
                                    >
                                        <MUIButton
                                            label={"Create Ticket"}
                                            onClick={()=>{
                                                if(userReadOnlyAccess){

                                                    handleClick()
                                                }
                                            }}
                                            type="transparent"
                                            font="font-smaller font-bold"
                                        />
                                    </Grid>
                                    {/* this was commented as the functionality is not implemented  {contextValues?.admin ? (
                                        <Grid
                                            item
                                            xs={4}
                                            style={{
                                                marginTop: "4% !important",
                                            }}
                                        >
                                            <MUIButton
                                                label={"Add User"}
                                                onClick={() => {
                                                    setAdduser((prev) => !prev);
                                                }}
                                                type="transparent"
                                                font="font-smaller font-bold"
                                            />
                                        </Grid>
                                    ) : (
                                        <></>
                                    )} */}
                                    {/* {contextValues?.orderSupplies ? (
                                        <Grid item xs={7} className="text-left">
                                            <MUIButton
                                                label={"New Supply Order"}
                                                onClick={() => {
                                                    setNewSupp((prev) => !prev);
                                                }}
                                                type="transparent"
                                                font="font-smaller font-bold"
                                            />
                                        </Grid>
                                    ) : (
                                        <></>
                                    )} */}

                                    <Grid item xs={5} className="text-left">
                                        <MUIButton
                                            label={"View Profile"}
                                            onClick={() => {
                                                SwitchRoute(
                                                    "CompanyProfile",
                                                    "",
                                                );
                                            }}
                                            type="transparent"
                                            font="font-smaller font-bold"
                                        />
                                    </Grid>

                                    <Grid
                                        item
                                        xs={12}
                                        style={{ marginTop: "8%" }}
                                    >
                                        <Grid container spacing={2}>
                                            <Grid
                                                item
                                                xs={12}
                                                className="text-left font-boldest font-bigger"
                                            >
                                                Quick View
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Paper
                                                    elevation={4}
                                                    style={{ padding: "5%" }}
                                                >
                                                    <Grid container>
                                                        <Grid
                                                            item
                                                            xs={12}
                                                            className="grey"
                                                            style={{
                                                                fontSize: '16px',
                                                                textAlign:
                                                                    "left",
                                                                padding: "4%",
                                                            }}
                                                        >
                                                            Contract No.
                                                        </Grid>
                                                        <Grid
                                                            item
                                                            xs={12}
                                                            style={{
                                                                border: '1px solid lightgrey',
                                                                textAlign:
                                                                    "left",
                                                                padding: "4%",
                                                                borderRadius: '20px'
                                                            }}
                                                        >
                                                            <HiddenTextField
                                                                label={""}
                                                                id={
                                                                    "contractNo"
                                                                }
                                                                value={
                                                                    quickViewData
                                                                }
                                                                
                                                                handleChange={(
                                                                    e: any,
                                                                ) => {
                                                                    setQuickViewData(
                                                                        e.target
                                                                            .value,
                                                                    );
                                                                }}
                                                                width={"100%"}
                                                                placeHolder={"Enter Contract No...."}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <Grid
                                                                container
                                                                style={{
                                                                    margin:
                                                                        "4% 0%",
                                                                }}
                                                            >
                                                                <Grid
                                                                    item
                                                                    xs={7}
                                                                ></Grid>
                                                                <Grid
                                                                    item
                                                                    xs={5}
                                                                    style={{
                                                                        textAlign:
                                                                            "right",
                                                                    }}
                                                                >
                                                                    <MUIButton
                                                                        label={
                                                                            "View Details"
                                                                        }
                                                                        onClick={() => {
                                                                            setContractDet(
                                                                                true,
                                                                            );
                                                                        }}
                                                                        type="outlined"
                                                                        font="font-smaller font-bold"
                                                                    />
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Paper>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            )}

            <SwipeableDrawerComp
                drawerState={[open, setOpen]}
                header={"Create Tickets"}
                headerColor={"grey2"}
                Child={<CreateTicketsCUS />}
            />
            <SwipeableDrawerComp
                drawerState={[addUser, setAdduser]}
                header={"Add User"}
                headerColor={"grey2"}
                Child={
                    portalKey ? (
                        <CompanySideDrawChildVen
                            sideDrawData={[sideDrawdataset, setSideDrawdataset]}
                            dashboardSideDraw={true}
                        />
                    ) : (
                        <CompanySideDrawChild
                            sideDrawData={[sideDrawdataset, setSideDrawdataset]}
                        />
                    )
                }
            />
            <SwipeableDrawerComp
                drawerState={[newSupp, setNewSupp]}
                header={"New Supply Order"}
                headerColor={"grey2"}
                Child={
                    <OrderSuppliesDetails
                        combovalue={""}
                        combolist={[]}
                        handleCustomerChange={function (e: any): void {
                            throw new Error("Function not implemented.");
                        }}
                        loading={undefined}
                        error={undefined}
                        detaildata={[]}
                    />
                }
            />
            <SwipeableDrawerComp
                drawerState={[deductionDrawer, setdeductionDrawer]}
                header={"Deduction List"}
                headerColor={"grey2"}
                Child={<DeductionList />}
                customPadding={{}}
            />

            <SwipeableDrawerComp
                drawerState={[
                    franchiseStatementDrawer,
                    setfranchiseStatementDrawer,
                ]}
                header={"Franchisee Document"}
                headerColor={"grey2"}
                Child={<FranchiseeChild />}
            />
        </>
    );
};
const mapStateToProps = (state: any) => {
    return {
        currentContext: state.context?.userDefaults,
        userName: state.context?.name,
        portalKey: state.portalKey,
        selectedCustomer: state.selectedCustomer,
        supplierCode: state.hiddenSupplier?.supplierCodeOut,
        customerData: state.portalKey
            ? state?.selectedVendor
            : state?.selectedCustomer,
        adminKey: state.adminKey,
        context: state.context,
        subContractorKey: state.subContractorKey,
        userReadOnlyAccess: state?.userReadOnlyAccess,
    };
};
const mapDispatchToProps = (dispatch: any) => {
    return {
        setContext: (data: any) => {
            dispatch(storeContextInfo(data));
        },
        setSelectedVendor: (data: any) => {
            dispatch(storeSelectedVendor(data));
        },
        setSelectedCustomer: (data: any) => {
            dispatch(storeSelectedCustomer(data));
        },
        setPortal: (data: any) => {
            dispatch(storePortal(data));
        },
        setCommonValues: (data: any) => {
            dispatch(storeCommonValues(data));
        },
        setCurrency: (data: any) => {
            dispatch(storeCurrency(data));
        },
        setBreadcrumbsData: (data: any) => {
            dispatch(storeBreadcrumbs(data));
        },
        setSubContractorKey: (data: any) => {
            dispatch(storeSubcontractorKey(data));
        },
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Body);
