const OrderSuppliesDetailsCardDataQuery = `
query Query(
    $ouId: Int, $service: String, $userName: String, $langId: Int, $order: String
){
OrderSuppliesDetailsCardDataCus(
    ouId: $ouId, service: $service, userName: $userName, langId: $langId, order: $order
){
podate
supplyAmount
Orderdata{
orderno
shippingAddress
status
}
}
}
`;

export default OrderSuppliesDetailsCardDataQuery;
