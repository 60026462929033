const PayAdviceHeaderDataVenQuery = `
    mutation(
        $entity: String
        $ou: Int
        $service: String
        $User: String
        $langId: Int
        $vendorName: String
        $vendorCode: String
        $merrorid: String
    ){
        PayAdviceHeaderDataVen(
            entity: $entity
            ou: $ou
            service: $service
            User: $User
            langId: $langId
            vendorName: $vendorName
            vendorCode: $vendorCode
            merrorid: $merrorid
        ){
            desc
            frequent
            value
            visible
            detail {
                desc
                value
                color
                code
            }
        }
    }
`;

export default PayAdviceHeaderDataVenQuery;


