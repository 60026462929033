import {
    Backdrop,
    CircularProgress,
    Divider,
    Grid,
    makeStyles,
    Paper,
    Theme,
} from "@material-ui/core";
import currencyFormatter from "Common/Utils/currencyFormatter";

import Icons from "Common/Icons/Icons";
import React, { useEffect, useState } from "react";
import useGetPosts from "ReactQuery/reactQuery";
import { storeSupplier } from "stateManagement/actions/HomePage/storeHiddenSupplier";
import { connect } from "react-redux";

import { DetAddr } from "ReactQuery/Query";
import Alert from "Common/Alerts/Alerts";
import BackdropLoading from "Common/Backdrop/Backdrop";

interface Buildaddr {
    Building: {
        name: string;
        id: string;
    };
    Address: {
        Area: string;
        City: string;
        State: string;
        Pincode: Number;
    };
}

const DeductionListSidedraw = (props: any) => {
    const { currentContext, selectedVendor, currency } = props;

    const [buildAddr, setBuildAddr] = useState<Buildaddr[]>();

    const [deduction, setDeduction] = useState<any>();

    const useStyles = makeStyles((theme: Theme) => ({
        backdrop: {
            zIndex: theme.zIndex.drawer + 1,
            color: "#fff",
        },
        root: {
            flexGrow: 1,
            backgroundColor: theme.palette.background.paper,
        },
    }));
    const classes = useStyles();
    const [alert, setAlert] = useState(false);
    const [errr, seterr] = useState({
        code: 0,
        msg: "",
    });

    const handleErrorAlert = (code: number, message: string) => {
        Alert({
            statusCode: code,
            message: message,
            willClose: () => {
                setAlert(false);
                seterr({ code: 0, msg: "" });
            },
        });
    };

    const {
        data: DetAddrData,
        error: DetAddrError,
        isLoading: DetAddrLoading,
    } = useGetPosts({
        name: "DetAddr",
        query: DetAddr,
        variables: {
            entity: "OW_Vendor_Portal_Deductions",
            ou: currentContext?.ouId,
            service: "",
            User: selectedVendor?.loginUser,
            languageId: currentContext?.langId,
            vendorcode: selectedVendor?.supplierCode,
            vendorname: selectedVendor?.supplierName,
            merrorid: "",
        },
    });
    useEffect(() => {
        if (DetAddrData?.DetAddr) {
            setBuildAddr(DetAddrData?.DetAddr);
            setDeduction(DetAddrData?.DetAddr[0]?.Body);
        }
    }, [DetAddrData]);
    console.log("detuctio", deduction);

    useEffect(() => {
        if (DetAddrError && !DetAddrLoading) {
            const data = JSON.parse(JSON.stringify(DetAddrError));
            const errmsg = data?.response?.errors[0].message;
            seterr({
                code: 400,
                msg: errmsg,
            });
            setAlert(true);
        }
    }, [DetAddrError, DetAddrLoading]);

    return (
        <Grid
            container
            spacing={2}
            style={{
                minWidth: "550px",
                maxWidth: "550px",
            }}
        >
            <BackdropLoading openStates={DetAddrLoading} />
            <Grid item xs={12}>
                <Paper elevation={4} style={{ backgroundColor: "#f2f2f3" }}>
                    <Grid
                        container
                        style={{
                            minWidth: "550px",
                            maxWidth: "550px",
                            padding: "4%",
                        }}
                    >
                        {buildAddr?.map((element: any) => (
                            <Grid item xs={12}>
                                <Grid
                                    container
                                    justifyContent="center"
                                    className="text-left"
                                    spacing={1}
                                >
                                    <Grid item xs={6}>
                                        <Grid container>
                                            <Grid item xs={1}>
                                                <Icons Icon="Book" />
                                            </Grid>
                                            <Grid
                                                item
                                                xs={10}
                                                style={{
                                                    paddingLeft: "5%",
                                                }}
                                            >
                                                #{element.Building.id}
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Grid container>
                                            <Grid item xs={1}>
                                                <Icons Icon="BuildPic" />
                                            </Grid>
                                            <Grid item xs={11}>
                                                {element.Building.name}
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={1}>
                                        <Icons Icon="location" />
                                    </Grid>
                                    <Grid
                                        item
                                        xs={11}
                                        className="grey text-left"
                                    >
                                        {element.Address.Area} |
                                        {element.Address.City} |
                                        {element.Address.State}|
                                        {element.Address.Pincode}
                                    </Grid>
                                </Grid>
                            </Grid>
                        ))}
                    </Grid>
                </Paper>
            </Grid>

            <Grid item xs={12} sm={12} style={{ padding: "4%" }}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Paper variant="outlined">
                            <Grid
                                container
                                spacing={2}
                                style={{ padding: "5%" }}
                            >
                                <Grid
                                    item
                                    xs={6}
                                    className="font-bold font-big darkblue"
                                >
                                    Advertising Fee
                                </Grid>
                                <Grid
                                    item
                                    xs={6}
                                    className="font-bolder font-big text-right"
                                    style={{ color: "#E01E37" }}
                                >
                                    {currencyFormatter(
                                        deduction?.AdvertisingFee,
                                        currency,
                                    )}
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                    <Grid item xs={12}>
                        <Paper variant="outlined">
                            <Grid
                                container
                                spacing={2}
                                style={{ padding: "5%" }}
                            >
                                <Grid
                                    item
                                    xs={6}
                                    className="font-bold font-big darkblue"
                                >
                                    Non-entity Fee
                                </Grid>
                                <Grid
                                    item
                                    xs={6}
                                    className="font-bolder font-big text-right"
                                    style={{ color: "#E01E37" }}
                                >
                                    {currencyFormatter(
                                        deduction?.NonEntityFee,
                                        currency,
                                    )}
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} style={{ marginTop: "35%" }}>
                <Divider />
            </Grid>
            <Grid
                item
                xs={12}
                className="text-left"
                style={{ paddingLeft: "4%" }}
            >
                <span className="font-bold font-big grey2">
                    Total Deduction Amount:
                </span>
                <span>{"  "}</span>
                <span
                    className="font-bolder font-big text-right"
                    style={{ color: "#E01E37" }}
                >
                    {currencyFormatter(
                        deduction?.TotalDeductionAmount,
                        currency,
                    )}
                </span>
            </Grid>
        </Grid>
    );
};

const mapStateToProps = (state: any) => {
    return {
        supplierCode: state.hiddenSupplier?.supplierCodeOut,
        storeCompanies: state.storeCompanies,
        storeOus: state.storeOus,
        currentContext: state.context?.userDefaults,
        userName: state.context.name,
        currency: state.currency,
        selectedVendor: state.selectedVendor,
    };
};
const mapDispatchToProps = (dispatch: any) => {
    return {
        setSupplier: (data: any) => {
            dispatch(storeSupplier(data));
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(DeductionListSidedraw);
