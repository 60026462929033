import {
    Grid,
    Paper,
    Backdrop,
    CircularProgress,
    makeStyles,
    Theme,
} from "@material-ui/core";
import { useEffect, useState } from "react";
import "Common/Main.scss";
import ButtonGroups from "Common/ButtonGroup/ButtonGroup";
import MuiDatatables from "Common/Mui-DataTables/MuiDatatables";

import { OrderSuppliesDetailsTableDataQuery } from "ReactQuery/Query";
import useGetPosts from "ReactQuery/reactQuery";
import { storeSupplier } from "stateManagement/actions/HomePage/storeHiddenSupplier";
import { connect } from "react-redux";
import Icons from "Common/Icons/Icons";
import MUIButton from "Common/Button/Button";
import Button from "Common/Button/Button";
import SwipeableDrawerComp from "Common/SideDrawer/SideDrawer";
import OrderSuppliesDetails from "./OrderSuppliesDetails";
import OrderSuppliesDetailsCardDataQuery from "ReactQuery/Query/OrderSuppliesDetailsCardDataQuery";
import currencyFormatter from "Common/Utils/currencyFormatter";
import { useLocation } from "react-router";

const useStyles = makeStyles((theme: Theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: "#fff",
    },
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
    },
}));

const OrderSupplyChild = (props: any) => {
    const orderData = useLocation();
    const stateOrder: any = (orderData as any)?.state?.orderSelected;
    console.log("stateOrder====>", orderData?.state);
    const {
        currentContext,
        userName,
        selectedCustomer,
        supplierCode,
        currency,
    } = props;
    const [popoverBlack, setPopoverBlack] = useState(false);
    const [alert, setAlert] = useState(false);
    const [errr, seterr] = useState({
        code: 0,
        msg: "",
    });
    const classes = useStyles();

    const [OrdersupplyTableData, setOrdersupplyTableData] = useState([]);
    const [orderChildHeaderData, setorderChildHeaderData] = useState<any>();
    const [sideScreen, setSideScreen] = useState("");
    const [sideBar, setSideBar] = useState(false);
    const handleSideBar = (screen: string) => {
        setSideBar(!sideBar);
        setSideScreen(screen);
    };

    const OrderSuppliesDetailsSchema = [
        {
            name: "ItemCode",
            Label: "ItemCode",
            type: "number",
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => (
                    <span style={{ color: "white" }}>Item Code</span>
                ),
                setCellHeaderProps: () => {
                    return { style: { borderRight: "1px solid #D5D5D5" } };
                },
                setCellProps: () => {
                    return {
                        style: {
                            borderRight: "1px solid #D5D5D5",
                            textAlign: "center",
                        },
                    };
                },
                customBodyRender: (
                    value: any,
                    tableMeta: any,
                    updateValue: any,
                ) => {
                    console.log("table data", value);
                    return <b style={{ color: "blue" }}>{value}</b>;
                },
            },
        },
        {
            name: "Description",
            Label: "Description",
            type: "string",
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => (
                    <span style={{ color: "white" }}>Description</span>
                ),
                setCellHeaderProps: () => {
                    return { style: { borderRight: "1px solid #D5D5D5" } };
                },
                setCellProps: () => {
                    return {
                        style: {
                            borderRight: "1px solid #D5D5D5",
                            textAlign: "center",
                        },
                    };
                },
                customBodyRender: (
                    value: any,
                    tableMeta: any,
                    updateValue: any,
                ) => {
                    console.log("table data", value, tableMeta.rowData);
                    return <b style={{ color: "grey" }}>{value}</b>;
                },
            },
        },

        {
            name: "UM",
            Label: "UM",
            type: "string",
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => (
                    <span style={{ color: "white" }}>U/M</span>
                ),
                setCellHeaderProps: () => {
                    return { style: { borderRight: "1px solid #D5D5D5" } };
                },
                setCellProps: () => {
                    return {
                        style: {
                            borderRight: "1px solid #D5D5D5",
                            textAlign: "center",
                        },
                    };
                },
                customBodyRender: (
                    value: any,
                    tableMeta: any,
                    updateValue: any,
                ) => {
                    console.log("table data", value);
                    return <b style={{ color: "#5C677D" }}>{value}</b>;
                },
            },
        },
        {
            name: "Amount",
            Label: "Amount",
            type: "number",
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => (
                    <span style={{ color: "white" }}>Amount</span>
                ),
                setCellHeaderProps: () => {
                    return { style: { borderRight: "1px solid #D5D5D5" } };
                },
                setCellProps: () => {
                    return {
                        style: {
                            borderRight: "1px solid #D5D5D5",
                            textAlign: "center",
                        },
                    };
                },
                customBodyRender: (
                    value: any,
                    tableMeta: any,
                    updateValue: any,
                ) => {
                    console.log("table data", value);
                    return (
                        <b
                            style={{
                                // color: "green",
                                display: "flex",
                                justifyContent: "flex-end",
                            }}
                        >
                            {currencyFormatter(value, currency)}
                        </b>
                    );
                },
            },
        },
        {
            name: "Quantity",
            Label: "Quantity",
            type: "number",
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => (
                    <span style={{ color: "white" }}>Quantity</span>
                ),
                setCellHeaderProps: () => {
                    return { style: { borderRight: "1px solid #D5D5D5" } };
                },
                setCellProps: () => {
                    return {
                        style: {
                            borderRight: "1px solid #D5D5D5",
                            textAlign: "center",
                        },
                    };
                },
                customBodyRender: (
                    value: any,
                    tableMeta: any,
                    updateValue: any,
                ) => {
                    console.log("table data", value);
                    return (
                        <b
                            style={{
                                // color: "green",
                                display: "flex",
                                justifyContent: "flex-end",
                            }}
                        >
                            {value}
                        </b>
                    );
                },
            },
        },
        {
            name: "OAmount",
            Label: "OAmount",
            type: "number",
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => (
                    <span style={{ color: "white" }}>Total Amount</span>
                ),
                setCellHeaderProps: () => {
                    return { style: { borderRight: "1px solid #D5D5D5" } };
                },
                setCellProps: () => {
                    return {
                        style: {
                            borderRight: "1px solid #D5D5D5",
                            textAlign: "center",
                        },
                    };
                },
                customBodyRender: (
                    value: any,
                    tableMeta: any,
                    updateValue: any,
                ) => {
                    console.log("table data", value);
                    return (
                        <b
                            style={{
                                color: "green",
                                display: "flex",
                                justifyContent: "flex-end",
                            }}
                        >
                            {currencyFormatter(value, currency)}
                        </b>
                    );
                },
            },
        },
        // {
        //     name: "Actions",
        //     Label: "Actions",
        //     options: {
        //         filter: true,
        //         sort: false,
        //         customHeadLabelRender: () => (
        //             <span style={{ color: "white" }}>Actions</span>
        //         ),
        //         setCellHeaderProps: () => {
        //             return { style: { borderRight: "1px solid #D5D5D5" } };
        //         },
        //         setCellProps: () => {
        //             return {
        //                 style: {
        //                     borderRight: "1px solid #D5D5D5",
        //                     textAlign: "center",
        //                 },
        //             };
        //         },
        //         customBodyRender: (
        //             value: any,
        //             tableMeta: any,
        //             updateValue: any,
        //         ) => {
        //             console.log("table data", value);
        //             return (
        //                 <b style={{ color: "green" }}>
        //                     {<Icons Icon={"deleteicon"} />}
        //                 </b>
        //             );
        //         },
        //     },
        // },
    ];

    const {
        data: OrderSupplieschildHeaderdata,
        error: OrderSupplieschildHeaderdataError,
        isLoading: OrderSupplieschildHeaderdataLoading,
    } = useGetPosts({
        name: `OrderSuppliesDetailsCardDataQuery_${stateOrder}`,
        query: OrderSuppliesDetailsCardDataQuery,
        variables: {
            ouId: currentContext?.ouId,
            service: "",
            userName: userName,
            langId: currentContext?.langId,
            order: stateOrder,
        },
    });
    useEffect(() => {
        if (OrderSupplieschildHeaderdata?.OrderSuppliesDetailsCardDataCus[0]) {
            setorderChildHeaderData(
                OrderSupplieschildHeaderdata
                    ?.OrderSuppliesDetailsCardDataCus[0],
            );
        }
    }, [OrderSupplieschildHeaderdata]);

    useEffect(() => {
        if (
            OrderSupplieschildHeaderdataError &&
            !OrderSupplieschildHeaderdataLoading
        ) {
            const data = JSON.parse(
                JSON.stringify(OrderSupplieschildHeaderdataError),
            );
            const errmsg = data?.response?.errors[0].message;
            seterr({
                code: 400,
                msg: errmsg,
            });
            setAlert(true);
        }
    }, [
        OrderSupplieschildHeaderdataError,
        OrderSupplieschildHeaderdataLoading,
    ]);

    const {
        data: orderSuppliesDetailsTableData,
        error: orderSuppliesDetailsTableDataError,
        isLoading: orderSuppliesDetailsTableDataLoading,
    } = useGetPosts({
        name: `OrderSuppliesDetailsTableDataQuery_${stateOrder}`,
        query: OrderSuppliesDetailsTableDataQuery,
        variables: {
            ouId: currentContext?.ouId,
            service: "",
            user: userName,
            langId: currentContext?.langId,
            orderNo: stateOrder,
        },
    });

    useEffect(() => {
        if (orderSuppliesDetailsTableData?.OrderSuppliesDetailsTableDataCus) {
            setOrdersupplyTableData(
                orderSuppliesDetailsTableData?.OrderSuppliesDetailsTableDataCus,
            );
        }
    }, [orderSuppliesDetailsTableData]);

    useEffect(() => {
        if (
            orderSuppliesDetailsTableDataError &&
            !orderSuppliesDetailsTableDataLoading
        ) {
            const data = JSON.parse(
                JSON.stringify(orderSuppliesDetailsTableDataError),
            );
            const errmsg = data?.response?.errors[0].message;
            seterr({
                code: 400,
                msg: errmsg,
            });
            setAlert(true);
        }
    }, [
        orderSuppliesDetailsTableDataError,
        orderSuppliesDetailsTableDataLoading,
    ]);

    return (
        <>
            <Backdrop
                className={classes.backdrop}
                open={orderSuppliesDetailsTableDataLoading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <Grid
                container
                spacing={2}
                style={{ padding: "1% 0 2%" }}
            >
                <Grid item xs={9}>
                    <Paper variant="outlined" elevation={4}>
                        <Grid container>
                            <Grid item xs={5}>
                                <Grid container>
                                    <Grid
                                        item
                                        xs={1}
                                        className="margin-top-5-per"
                                    >
                                        <Icons Icon={"carriertray"} />
                                    </Grid>

                                    <Grid
                                        item
                                        xs={3}
                                        style={{ display: "flex" }}
                                        className="text-left margin-top-5-per grey1"
                                    >
                                        <b>
                                            {" "}
                                            {"#"}{" "}
                                            {
                                                orderChildHeaderData?.Orderdata
                                                    ?.orderno
                                            }
                                        </b>
                                    </Grid>
                                    <Grid item xs={7}></Grid>
                                </Grid>

                                <Grid container>
                                    <Grid
                                        item
                                        xs={1}
                                        className="margin-top-5-per"
                                    >
                                        <Icons Icon={"location"} />
                                    </Grid>
                                    <Grid
                                        item
                                        xs={6}
                                        className=" text-left margin-top-5-per grey1"
                                    >
                                        {
                                            orderChildHeaderData?.Orderdata
                                                ?.shippingAddress
                                        }
                                    </Grid>
                                    <Grid item xs={1}></Grid>
                                    <Grid item xs={4}>
                                        <button
                                            style={{
                                                border: "1% green",
                                                color: "green",
                                            }}
                                        >
                                            {
                                                orderChildHeaderData?.Orderdata
                                                    ?.status
                                            }
                                        </button>
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid container xs={1}>
                                <Icons Icon={"Line"} />
                            </Grid>

                            <Grid item xs={3}>
                                <Grid
                                    container
                                    className="text-left margin-top-10-per"
                                >
                                    <Grid item xs={3}>
                                        PO Date
                                    </Grid>
                                    <Grid item xs={2}></Grid>

                                    <Grid item xs={5}>
                                        Supplier Amount
                                    </Grid>
                                    <Grid item xs={2}></Grid>
                                </Grid>
                                <Grid
                                    container
                                    className="text-left margin-top-1-per"
                                >
                                    <Grid item xs={3}>
                                        <b>{orderChildHeaderData?.podate}</b>
                                    </Grid>
                                    <Grid item xs={2}></Grid>

                                    <Grid item xs={5}>
                                        {currencyFormatter(
                                            orderChildHeaderData?.supplyAmount,
                                            currency,
                                        )}
                                    </Grid>

                                    <Grid item xs={2}></Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>

                <Grid item xs={12} style={{ marginTop: "1%" }}>
                    <MuiDatatables
                        title={""}
                        header={OrderSuppliesDetailsSchema}
                        detail={[OrdersupplyTableData, setOrdersupplyTableData]}
                        initRow={false}
                    />
                </Grid>
                <SwipeableDrawerComp
                    drawerState={[sideBar, setSideBar]}
                    header={"New Supply Order"}
                    headerColor={"grey2"}
                    Child={<OrderSuppliesDetails />}
                />
            </Grid>
        </>
    );
};

const mapStateToProps = (state: any) => {
    return {
        currentContext: state.context?.userDefaults,
        selectedCustomer: state.selectedCustomer,
        supplierCode: state.hiddenSupplier?.supplierCodeOut,
        currency: state.currency,
        userName: state?.context?.name,
    };
};

export default connect(mapStateToProps)(OrderSupplyChild);
