import { STORE_HIDDEN_SUPPLIER } from "../../actionTypes";
const storeHiddenSupplier = (data: any) => {
    return {
        type: STORE_HIDDEN_SUPPLIER,
        payload: data,
    };
};
/**
 * Common File redux
 * @param code
 */
export function storeSupplier<T>(data: any) {
    return (dispatch: any) => {
        dispatch(storeHiddenSupplier(data));
    };
}