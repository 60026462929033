const VendorSearchQuery = `
mutation(
    $entity:String,
    $ou:Int,
    $service:String,
    $User:String,
    $languageId:Int,
    $vendorName:String,
    $vendorCode:String,
    $mobileNumber:String,
    $email:String,
    $merrorid:String
){
    vendorSearch(
        entity:$entity,
        ou:$ou,
        service:$service,
        User:$User,
        languageId:$languageId,
        vendorName:$vendorName,
        vendorCode:$vendorCode,
        mobileNumber:$mobileNumber,
        email:$email,
        merrorid:$merrorid
    ) {
        header {
            code
            desc
            api
          }
          detail {
            desc
          code
          }
    }
}`;

export default VendorSearchQuery;
