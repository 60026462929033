import { STORE_PORTAL } from "../../actionTypes";

const portalData = (data: any) => {
    return {
        type: STORE_PORTAL,
        payload: data,
    };
};

/**
 * Common File redux
 * @param code
 */
export function storePortal<T>(data: any) {
    return (dispatch: any) => {
        dispatch(portalData(data));
    };
}
