import { Grid, Paper } from "@material-ui/core";
import OWCircularProgress from "Common/circularprogress/OWCircularProgress";
import currencyFormatter from "Common/Utils/currencyFormatter";
import { useEffect, useState } from "react";
import { connect } from "react-redux";

const LoanBalanceCard = (props: any) => {
    const { LoanSummary, currency } = props;

    let [balanceAmount, setBalanceAmount] = useState(0);
    let [LoanSummaryData, setLoanSummaryData] = useState<any>();
    useEffect(() => {
        if (LoanSummary) {
            setBalanceAmount(
                Math.floor(
                    (LoanSummary[0]?.balanceAmount /
                        LoanSummary[0]?.totalAmount) *
                        100,
                ),
            );
            setLoanSummaryData(LoanSummary[0]);
        }
    }, [LoanSummary]);
    return (
        <Paper elevation={3} style={{ minHeight: "calc(100% + 10px)" }}>
            <Grid container spacing={1}>
                <Grid
                    item
                    xs={12}
                    className="text-left font-boldest"
                    style={{ margin: "2.5% 6%" }}
                >
                    <Grid container spacing={1}>
                        <Grid
                            item
                            className="font-boldest grey1"
                            style={{
                                background: `#FFEDFA`,
                                borderRadius: "5px",
                            }}
                        >
                            {`Current Loan Balance`}
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Grid
                        container
                        spacing={2}
                        justifyContent="flex-end"
                        alignItems="center"
                    >
                        <Grid item xs={3} style={{textAlign:"left"}}>
                            <OWCircularProgress value={balanceAmount?balanceAmount:0} />
                        </Grid>
                        <Grid item xs={8}>
                            <Grid
                                container
                                spacing={1}
                                style={{
                                    textAlign: "right",
                                    paddingRight: "4%",
                                }}
                            >
                                <Grid
                                    item
                                    xs={6}
                                    className="chocolate-brown font-smaller"
                                >
                                    Total Active Loans
                                </Grid>
                                <Grid
                                    item
                                    xs={6}
                                    className="chocolate-brown font-boldest"
                                >
                                    {LoanSummaryData?.totActLoan
                                        ? LoanSummaryData?.totActLoan
                                        : 0}
                                </Grid>
                                <Grid
                                    item
                                    xs={6}
                                    className="chocolate-brown font-smaller"
                                >
                                    Total Amount
                                </Grid>
                                <Grid
                                    item
                                    xs={6}
                                    className="darkblue font-boldest"
                                >
                                    {LoanSummaryData?.totalAmount
                                        ? currencyFormatter(
                                              LoanSummaryData?.totalAmount,
                                              currency,
                                          )
                                        : 0}
                                </Grid>
                                <Grid
                                    item
                                    xs={6}
                                    className="chocolate-brown font-smaller"
                                >
                                    Paid Amount
                                </Grid>
                                <Grid item xs={6} className="green">
                                    {LoanSummaryData?.paidAmount
                                        ? currencyFormatter(
                                              LoanSummaryData?.paidAmount,
                                              currency,
                                          )
                                        : 0}
                                </Grid>
                                <Grid
                                    item
                                    xs={6}
                                    className="chocolate-brown font-smaller"
                                >
                                    Balance Amount
                                </Grid>
                                <Grid item xs={6} className="red">
                                    {LoanSummaryData?.balanceAmount
                                        ? currencyFormatter(
                                              LoanSummaryData?.balanceAmount,
                                              currency,
                                          )
                                        : 0}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Paper>
    );
};
const mapStateToProps = (state: any) => {
    return {
        currency: state.currency,
    };
};
export default connect(mapStateToProps)(LoanBalanceCard);
