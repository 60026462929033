import { Grid, Paper } from "@material-ui/core";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import useGetPosts from "ReactQuery/reactQuery";
import { WorkerCompensationVEN } from "ReactQuery/Query/index";
import Alert from "Common/Alerts/Alerts";
import BackdropLoading from "Common/Backdrop/Backdrop";
import FranchiseInsuranceImage from "./Images/Franchise_Insurance.svg";

interface IndexProps {
    currentContext: any;
    selectedVendor: any;
}
interface WorkerCompensation {
    WorkersCompansationApplicable: string;
    WorkersCompansationExpirationDate: string;
    LiabilityInsuranceApplicable: string;
    LiabilityInsuranceExpirationDate: string;
    RegistrationNumber: string;
    NumberValidTill: string;
}

const FranchiseInsuranceRequirment = (props: IndexProps) => {
    const { currentContext, selectedVendor } = props;
    const [workerComp, setworkerComp] = useState<WorkerCompensation>();
    const [alert, setAlert] = useState(false);
    const [errr, seterr] = useState({
        code: 0,
        msg: "",
    });
    const handleErrorAlert = (code: number, message: string) => {
        Alert({
            statusCode: code,
            message: message,
            willClose: () => {
                setAlert(false);
                seterr({ code: 0, msg: "" });
            },
        });
    };

    const {
        data: workerCompensationData,
        error: workerCompensationDataError,
        isLoading: workerCompensationDataLoading,
    } = useGetPosts({
        name: `WorkerCompensationVEN`,
        query: WorkerCompensationVEN,
        variables: {
            entity: "OW_Vendor_portal_getinsurence",
            ou: currentContext?.ouId,
            service: "",
            languageId: currentContext?.langId,
            User: selectedVendor?.loginUser,
            vendorcode: selectedVendor?.supplierCode,
            merrorid: "",
            vendorname: selectedVendor?.supplierName,
        },
    });
    useEffect(() => {
        if (workerCompensationData?.WorkerCompensationVEN) {
            setworkerComp(workerCompensationData?.WorkerCompensationVEN);
        }
    }, [workerCompensationData]);

    useEffect(() => {
        if (workerCompensationDataError && !workerCompensationDataLoading) {
            const data = JSON.parse(
                JSON.stringify(workerCompensationDataError),
            );
            const errmsg = data?.response?.errors[0].message;
            seterr({
                code: 400,
                msg: errmsg,
            });
            setAlert(true);
        }
    }, [workerCompensationDataError, workerCompensationDataLoading]);

    return (
        <Grid container spacing={3} style={{ marginTop: "3%" }}>
            {alert ? handleErrorAlert(errr.code, errr.msg) : <></>}
            <BackdropLoading openStates={workerCompensationDataLoading} />
            <Grid item xs={5}>
                <Grid container spacing={2}>
                    <Grid item xs={12} className="text-left font-boldest">
                        Workers Compensation
                    </Grid>
                    <Grid item xs={12}>
                        <Paper
                            elevation={4}
                            style={{ borderLeft: "3px solid #0073e6" }}
                        >
                            <Grid
                                container
                                spacing={1}
                                style={{ padding: "4%" }}
                            >
                                <Grid item xs={10} className="grey text-left">
                                    Workers Compensation Applicable
                                </Grid>
                                <Grid item xs={2}>
                                    {workerComp?.WorkersCompansationApplicable}
                                </Grid>
                                <Grid item xs={10} className="grey text-left">
                                    Workers Compensation Expiration Date
                                </Grid>
                                <Grid item xs={2}>
                                    {
                                        workerComp?.WorkersCompansationExpirationDate
                                    }
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={5}>
                <Grid container spacing={2}>
                    <Grid item xs={12} className="text-left font-boldest">
                        Liability Insurance
                    </Grid>
                    <Grid item xs={12}>
                        <Paper
                            elevation={4}
                            style={{ borderLeft: "3px solid #0073e6" }}
                        >
                            <Grid
                                container
                                spacing={1}
                                style={{ padding: "4%" }}
                            >
                                <Grid item xs={10} className="grey text-left">
                                    Liability Insurance Applicable
                                </Grid>
                                <Grid item xs={2}>
                                    {workerComp?.LiabilityInsuranceApplicable}
                                </Grid>
                                <Grid item xs={10} className="grey text-left">
                                    Liability Insurance Expiration Date
                                </Grid>
                                <Grid item xs={2}>
                                    {
                                        workerComp?.LiabilityInsuranceExpirationDate
                                    }
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                </Grid>
            </Grid>

            <Grid item xs={5}>
                <Grid container spacing={2}>
                    <Grid item xs={12} className="text-left font-boldest">
                        State Registration Number
                    </Grid>
                    <Grid item xs={12}>
                        <Paper
                            elevation={4}
                            style={{ borderLeft: "3px solid #0073e6" }}
                        >
                            <Grid
                                container
                                spacing={1}
                                style={{ padding: "4%" }}
                            >
                                <Grid item xs={10} className="grey text-left">
                                    Registration Number
                                </Grid>
                                <Grid item xs={2}>
                                    {workerComp?.RegistrationNumber}
                                </Grid>
                                <Grid item xs={10} className="grey text-left">
                                    Workers Compansation Expiration Date
                                </Grid>
                                <Grid item xs={2}>
                                    {workerComp?.NumberValidTill}
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                </Grid>
            </Grid>
            {/* <Grid item xs={1} style={{ maxWidth: "10%", marginLeft: "30%" }}>
                <img src={FranchiseInsuranceImage} />
            </Grid> */}
        </Grid>
    );
};

const mapStateToProps = (state: any) => {
    return {
        supplierCode: state.hiddenSupplier?.supplierCodeOut,
        storeCompanies: state.storeCompanies,
        storeOus: state.storeOus,
        currentContext: state.context?.userDefaults,
        userName: state.context.name,
        selectedVendor: state.selectedVendor,
    };
};

export default connect(mapStateToProps)(FranchiseInsuranceRequirment);
