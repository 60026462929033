const CurrentAssignmentStatusQuery = `
mutation(
    $ou:Int
    $service:String
    $User:String
    $languageId:Int
    $supplierCode:String
    $merrorid:String 
){
    CurrentAssignmentStatus(
        ou:$ou
        service:$service
        User:$User
        languageId:$languageId
        supplierCode:$supplierCode
        merrorid:$merrorid
    ){ 
        desc
        frequent
        value
        visible
        detail {
            desc
            value
            status
            color
        }
    }
}
`;

export default CurrentAssignmentStatusQuery;
