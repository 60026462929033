import {
    Grid,
    Paper,
    Backdrop,
    CircularProgress,
    Popover,
} from "@material-ui/core";
import { makeStyles, Theme } from "@material-ui/core/styles";
import React, { useEffect, useState } from "react";
import MUIButton from "Common/Button/Button";
import { connect } from "react-redux";
import useGetPosts from "ReactQuery/reactQuery";
import MuiDatatables from "Common/Mui-DataTables/MuiDatatables";
import Dropdown from "Common/Dropdown/Dropdown";
import TextFields from "Common/TextField/TextField";
import CustomDatePickerBorder from "Common/DatePicker/DatePicker";
import {
    PayAdviceHeaderDataVenQuery,
    PayAdviceTableDataVenQuery,
    StatusComboQuery,
} from "ReactQuery/Query/index";
import Alert from "Common/Alerts/Alerts";
import SubconFMIR from "./SubconFMIR.svg";
import currencyFormatter from "Common/Utils/currencyFormatter";
import { storeBreadcrumbs } from "stateManagement/actions/Common/breadcrumbs";
import dateFormatter from "Common/Utils/dateFormatter";
import Icons from "Common/Icons/Icons";
import Configuration from "Configuration";
import { lookup as mimeconverter } from "mime-types";
import SwipeableDrawerComp from "Common/SideDrawer/SideDrawer";
import ViewFmir from "./ViewFmir";

interface DashCards {
    desc: string;
    value: string;
    visible: boolean;
    frequent: boolean;
    detail: {
        desc: string;
        value: number;
        color: string;
    }[];
}

const useStyles = makeStyles((theme: Theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: "#fff",
    },
}));

interface BodyProps {
    currentContext: any;
    userName: any;
    selectedCustomer: any;
    supplierCode: any;
    selectedVendor: any;
    currency: any;
    setBreadcrumbsData: any;
    sessionId: any;
    subContractorKey: any;
}

const Body = (props: BodyProps) => {
    const [header, setHeader] = useState<DashCards>();
    const classes = useStyles();
    const {
        currentContext,
        supplierCode,
        selectedVendor,
        currency,
        setBreadcrumbsData,
        sessionId,
        subContractorKey,
    } = props;
    setBreadcrumbsData([
        {
            value: "Pay Advice",
            href: "/app/*/PayAdvice",
        },
    ]);
    const [statusList, setStatusList] = useState<any>([]);
    const [contractData, setContractData] = useState({
        fromDate: new Date(),
        toDate: new Date(),
        contractno: "",
        status: "",
    });

    const [active, setActive] = useState<string>("");
    const [showTable, setShowTable] = useState<boolean>(false);
    const [search, setSearch] = useState<boolean>(true);
    const [searchCount, setSearchCount] = useState(0);
    const [payadviceData, setPayadviceData] = useState([]);
    const [alert, setAlert] = useState(false);
    const [errr, seterr] = useState({
        code: 0,
        msg: "",
    });
    const [sideBar, setSideBar] = useState(false);
    const handleSideBar = (screen: string) => {
        setSideBar(!sideBar);
    };

    const handleDate = (type: string, e: any) => {
        setShowTable(false);
        if (type === "toDate") {
            setContractData((prevstate: any) => ({
                ...prevstate,
                toDate: e,
            }));
        } else if (type === "fromDate") {
            setContractData((prevstate: any) => ({
                ...prevstate,
                fromDate: e,
            }));
        }
    };

    const {
        data: StatusComboData,
        error: StatusComboDataError,
        isLoading: StatusComboDataLoading,
    } = useGetPosts({
        name: `StatusComboQuery_${selectedVendor?.loginUser}_PayAdvice`,
        query: StatusComboQuery,
        variables: {
            entity: "ow_getcombo_status",
            ou: currentContext?.ouId,
            service: "",
            user: selectedVendor?.loginUser,
            langId: currentContext?.langId,
            screen: "PayAdvice",
            tranType: "",
            merrorid: "",
        },
    });

    useEffect(() => {
        if (StatusComboData?.statusCombo) {
            console.log("StatusData", StatusComboData?.statusCombo);
            setStatusList(StatusComboData?.statusCombo);
            setContractData((prevState: any) => {
                return {
                    ...prevState,
                    status: StatusComboData?.statusCombo[0]?.value,
                };
            });
        }
    }, [StatusComboData]);

    useEffect(() => {
        if (StatusComboDataError && !StatusComboDataLoading) {
            const data = JSON.parse(JSON.stringify(StatusComboDataError));
            const errmsg = data?.response?.errors[0].message;
            seterr({
                code: 400,
                msg: errmsg,
            });
            setAlert(true);
        }
    }, [StatusComboDataError, StatusComboDataLoading]);

    const {
        data: PayAdviceHeaderData,
        error: PayAdviceHeaderDataError,
        isLoading: PayAdviceHeaderDataLoading,
    } = useGetPosts({
        name: `PayAdviceHeaderDataVenQuery_${selectedVendor?.supplierCode}`,
        query: PayAdviceHeaderDataVenQuery,
        variables: {
            entity: "OW_vendor_pay_advice_status",
            ou: currentContext?.ouId,
            service: "",
            User: selectedVendor?.loginUser,
            langId: currentContext?.langId,
            vendorName: selectedVendor?.supplierName,
            vendorCode: selectedVendor?.supplierCode,
            merrorid: "",
        },
    });

    useEffect(() => {
        if (PayAdviceHeaderData?.PayAdviceHeaderDataVen) {
            setHeader(PayAdviceHeaderData?.PayAdviceHeaderDataVen);
            setActive(
                PayAdviceHeaderData?.PayAdviceHeaderDataVen?.detail[0]?.desc,
            );
        }
    }, [PayAdviceHeaderData]);

    useEffect(() => {
        if (PayAdviceHeaderDataError && !PayAdviceHeaderDataLoading) {
            const data = JSON.parse(JSON.stringify(PayAdviceHeaderDataError));
            const errmsg = data?.response?.errors[0].message;
            seterr({
                code: 400,
                msg: errmsg,
            });
            setAlert(true);
        }
    }, [PayAdviceHeaderDataError, PayAdviceHeaderDataLoading]);

    const {
        data: PayAdviceTableData,
        error: PayAdviceTableDataError,
        isLoading: PayAdviceTableDataLoading,
    } = useGetPosts({
        name: `PayAdviceTableDataVenQuery_${active}_${selectedVendor?.supplierCode}_${contractData?.fromDate}_${contractData?.toDate}_${contractData?.contractno}_${contractData?.status}`,
        query: PayAdviceTableDataVenQuery,
        variables: {
            entity: "OW_vendor_pay_advice",
            ouId: currentContext?.ouId,
            service: "",
            user: selectedVendor?.loginUser,
            langId: currentContext?.langId,
            vendorName: selectedVendor?.supplierName,
            vendorCode: selectedVendor?.supplierCode,
            status:
                active === header?.detail[0]?.desc
                    ? "AC"
                    : contractData?.status,
            payAdviceID: contractData?.contractno,
            fromDate: dateFormatter("sqlDate", contractData?.fromDate),
            toDate: dateFormatter("sqlDate", contractData?.toDate),
            merrorid: "",
        },
        skip: active === header?.detail[0]?.desc ? false : !showTable,
    });

    useEffect(() => {
        if (PayAdviceTableData?.PayAdviceTableDataVen) {
            setPayadviceData(PayAdviceTableData?.PayAdviceTableDataVen);
        }
    }, [PayAdviceTableData]);

    const [fileloading, setFileLoading] = useState<boolean>(false);

    const handleAttachClick = async (fileName: any, relativePath: string) => {
        // handleBackdropClose(true);
        setFileLoading((prev) => !prev);

        const responseFile = await fetch(
            `${Configuration.getConfiguration().getAppAPILocation()}/api/fileManagement/${fileName}/${relativePath}`,
            {
                method: "GET",
                headers: {
                    authorization: sessionId,
                    ou: currentContext.ouId,
                    role: currentContext.roleName,
                    lang: currentContext.langId,
                },
                redirect: "follow",
            },
        );
        if (responseFile.status === 200) {
            const responseBlob = await responseFile.blob();
            const fileblob = responseBlob.slice(
                0,
                responseBlob.size,
                mimeconverter(fileName).toString(),
            );
            console.log("Downoad Blob =>", fileblob);
            const file: any = fileblob as File;
            file.name = fileName;
            console.log("Downoad File =>", file);
            URL.createObjectURL(file);
            setFileLoading((prev) => !prev);

            window.open(URL.createObjectURL(file), "_blank");
            return { fileData: file };
        } else {
            setFileLoading((prev) => !prev);
        }
    };

    useEffect(() => {
        if (PayAdviceTableDataError && !PayAdviceTableDataLoading) {
            const data = JSON.parse(JSON.stringify(PayAdviceTableDataError));
            const errmsg = data?.response?.errors[0].message;
            seterr({
                code: 400,
                msg: errmsg,
            });
            setAlert(true);
        }
    }, [PayAdviceTableDataError, PayAdviceTableDataLoading]);

    const PayAdviceTableSchema = [
        {
            name: "PayAdviceId",
            Label: "PayAdviceId",

            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => (
                    <span style={{ color: "white" }}>Pay Advice Id</span>
                ),
                setCellHeaderProps: () => {
                    return { style: { borderRight: "1px solid #D5D5D5" } };
                },
                setCellProps: () => {
                    return {
                        style: {
                            borderRight: "1px solid #D5D5D5",
                            textAlign: "center",
                        },
                    };
                },
                customBodyRender: (
                    value: any,
                    tableMeta: any,
                    updateValue: any,
                ) => {
                    console.log("table data", value);
                    return <b style={{ color: "#5C677D" }}>{value}</b>;
                },
            },
        },
        {
            name: "DateOfPayAdvice",
            Label: "DateOfPayAdvice",
            type: "number",
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => (
                    <span style={{ color: "white" }}>Date Of Pay Advice</span>
                ),
                setCellHeaderProps: () => {
                    return { style: { borderRight: "1px solid #D5D5D5" } };
                },
                setCellProps: () => {
                    return {
                        style: {
                            borderRight: "1px solid #D5D5D5",
                            textAlign: "center",
                        },
                    };
                },
                customBodyRender: (
                    value: any,
                    tableMeta: any,
                    updateValue: any,
                ) => {
                    console.log("table data", value, tableMeta.rowData);
                    return <b style={{ color: "blue" }}>{value}</b>;
                },
            },
        },

        {
            name: "NetPay",
            Label: "NetPay",
            type: "number",
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => (
                    <span style={{ color: "white" }}>Net Pay</span>
                ),
                setCellHeaderProps: () => {
                    return { style: { borderRight: "1px solid #D5D5D5" } };
                },
                setCellProps: () => {
                    return {
                        style: {
                            borderRight: "1px solid #D5D5D5",
                            textAlign: "center",
                        },
                    };
                },
                customBodyRender: (
                    value: any,
                    tableMeta: any,
                    updateValue: any,
                ) => {
                    console.log("table data", value);
                    return (
                        <b
                            style={{
                                // color: "#5C677D",
                                display: "flex",

                                justifyContent: "flex-end",
                            }}
                            className="green"
                        >
                            {currencyFormatter(value, currency)}
                        </b>
                    );
                },
            },
        },
        {
            name: "GrossPay",
            Label: "GrossPay",
            type: "number",
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => (
                    <span style={{ color: "white" }}>Gross Pay</span>
                ),
                setCellHeaderProps: () => {
                    return { style: { borderRight: "1px solid #D5D5D5" } };
                },
                setCellProps: () => {
                    return {
                        style: {
                            borderRight: "1px solid #D5D5D5",
                            textAlign: "center",
                        },
                    };
                },
                customBodyRender: (
                    value: any,
                    tableMeta: any,
                    updateValue: any,
                ) => {
                    console.log("table data", value);
                    return (
                        <b
                            style={{
                                color: "#5C677D",
                                display: "flex",

                                justifyContent: "flex-end",
                            }}
                        >
                            {currencyFormatter(value, currency)}
                        </b>
                    );
                },
            },
        },
        {
            name: "LoanPayment",
            Label: "LoanPayment",
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => (
                    <span style={{ color: "white" }}>Loan Payment</span>
                ),
                setCellHeaderProps: () => {
                    return { style: { borderRight: "1px solid #D5D5D5" } };
                },
                setCellProps: () => {
                    return {
                        style: {
                            borderRight: "1px solid #D5D5D5",
                            textAlign: "center",
                        },
                    };
                },
                customBodyRender: (
                    value: any,
                    tableMeta: any,
                    updateValue: any,
                ) => {
                    console.log("table data", value);
                    return (
                        <b
                            style={{
                                // color: "#5C677D",
                                display: "flex",

                                justifyContent: "flex-end",
                            }}
                            className={value<0 ? "red" : ""}
                        >
                            {currencyFormatter(value, currency)}
                        </b>
                    );
                },
            },
        },

        {
            name: "Deduction",
            Label: "Deduction",
            type: "number",
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => (
                    <span style={{ color: "white" }}>Deduction</span>
                ),
                setCellHeaderProps: () => {
                    return { style: { borderRight: "1px solid #D5D5D5" } };
                },
                setCellProps: () => {
                    return {
                        style: {
                            borderRight: "1px solid #D5D5D5",
                            textAlign: "center",
                        },
                    };
                },
                customBodyRender: (
                    value: any,
                    tableMeta: any,
                    updateValue: any,
                ) => {
                    console.log("table data", value);
                    return (
                        <b
                            style={{
                                
                                display: "flex",

                                justifyContent: "flex-end",
                            }}
                            className={value<0 ? "red" : ""}
                        >
                            {currencyFormatter(value, currency)}
                        </b>
                    );
                },
            },
        },
        {
            name: "print",
            Label: "print",
            type: "string",
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => (
                    <span style={{ color: "white" }}>Print</span>
                ),
                setCellHeaderProps: () => {
                    return { style: { borderRight: "1px solid #D5D5D5" } };
                },
                setCellProps: () => {
                    return {
                        style: {
                            borderRight: "1px solid #D5D5D5",
                            textAlign: "center",
                        },
                    };
                },
                customBodyRender: (
                    value: any,
                    tableMeta: any,
                    updateValue: any,
                ) => {
                    console.log("table data", value);
                    return (
                        <b
                            style={{ color: "#0073E6", cursor: "pointer" }}
                            onClick={() => {
                                // handleAttachClick(value, "doc_ms%2Fnot");
                                handleAttachClick(
                                    value,
                                    subContractorKey
                                        ? "PayAdvise%2Fsubcontractor"
                                        : "PayAdvise%2FFranchise",
                                );
                            }}
                        >
                            {<Icons Icon={"printerImage"} />}
                        </b>
                    );
                },
            },
        },
    ];
    const PayAdviceTableSubConSchema = [
        {
            name: "PayAdviceId",
            Label: "PayAdviceId",

            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => (
                    <span style={{ color: "white" }}>Pay Advice Id</span>
                ),
                setCellHeaderProps: () => {
                    return { style: { borderRight: "1px solid #D5D5D5" } };
                },
                setCellProps: () => {
                    return {
                        style: {
                            borderRight: "1px solid #D5D5D5",
                            textAlign: "center",
                        },
                    };
                },
                customBodyRender: (
                    value: any,
                    tableMeta: any,
                    updateValue: any,
                ) => {
                    console.log("table data", value);
                    return <b style={{ color: "#5C677D" }}>{value}</b>;
                },
            },
        },
        {
            name: "DateOfPayAdvice",
            Label: "DateOfPayAdvice",
            type: "number",
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => (
                    <span style={{ color: "white" }}>Date Of Pay Advice</span>
                ),
                setCellHeaderProps: () => {
                    return { style: { borderRight: "1px solid #D5D5D5" } };
                },
                setCellProps: () => {
                    return {
                        style: {
                            borderRight: "1px solid #D5D5D5",
                            textAlign: "center",
                        },
                    };
                },
                customBodyRender: (
                    value: any,
                    tableMeta: any,
                    updateValue: any,
                ) => {
                    console.log("table data", value, tableMeta.rowData);
                    return <b style={{ color: "blue" }}>{value}</b>;
                },
            },
        },

        {
            name: "NetPay",
            Label: "NetPay",
            type: "number",
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => (
                    <span style={{ color: "white" }}>Net Pay</span>
                ),
                setCellHeaderProps: () => {
                    return { style: { borderRight: "1px solid #D5D5D5" } };
                },
                setCellProps: () => {
                    return {
                        style: {
                            borderRight: "1px solid #D5D5D5",
                            textAlign: "center",
                        },
                    };
                },
                customBodyRender: (
                    value: any,
                    tableMeta: any,
                    updateValue: any,
                ) => {
                    console.log("table data", value);
                    return (
                        <b
                            style={{
                                color: "#5C677D",
                                display: "flex",

                                justifyContent: "flex-end",
                            }}
                        >
                            {currencyFormatter(value, currency)}
                        </b>
                    );
                },
            },
        },
        {
            name: "Deduction",
            Label: "Deduction",
            type: "number",
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => (
                    <span style={{ color: "white" }}>Deduction</span>
                ),
                setCellHeaderProps: () => {
                    return { style: { borderRight: "1px solid #D5D5D5" } };
                },
                setCellProps: () => {
                    return {
                        style: {
                            borderRight: "1px solid #D5D5D5",
                            textAlign: "center",
                        },
                    };
                },
                customBodyRender: (
                    value: any,
                    tableMeta: any,
                    updateValue: any,
                ) => {
                    console.log("table data", value);
                    return (
                        <b
                            style={{
                                color: "#5C677D",
                                display: "flex",

                                justifyContent: "flex-end",
                            }}
                        >
                            {currencyFormatter(value, currency)}
                        </b>
                    );
                },
            },
        },
        {
            name: "print",
            Label: "print",
            type: "string",
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => (
                    <span style={{ color: "white" }}>Print</span>
                ),
                setCellHeaderProps: () => {
                    return { style: { borderRight: "1px solid #D5D5D5" } };
                },
                setCellProps: () => {
                    return {
                        style: {
                            borderRight: "1px solid #D5D5D5",
                            textAlign: "center",
                        },
                    };
                },
                customBodyRender: (
                    value: any,
                    tableMeta: any,
                    updateValue: any,
                ) => {
                    console.log("table data", value);
                    return (
                        <b
                            style={{ color: "#0073E6", cursor: "pointer" }}
                            onClick={() => {
                                // handleAttachClick(value, "doc_ms%2Fnot");
                                handleAttachClick(
                                    value,
                                    subContractorKey
                                        ? "PayAdvise%2Fsubcontractor"
                                        : "PayAdvise%2FFranchise",
                                );
                            }}
                        >
                            {<Icons Icon={"printerImage"} />}
                        </b>
                    );
                },
            },
        },
    ];
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        event.preventDefault();
        setAnchorEl(event.currentTarget);
    };
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

    const handleClose = () => {
        setAnchorEl(null);
    };
    let open = Boolean(anchorEl);
    const handleActive = (index: string) => {
        setActive(index);
        setShowTable(false);
        console.log("index", index);
    };

    const handleErrorAlert = (code: number, message: string) => {
        Alert({
            statusCode: code,
            message: message,
            willClose: () => {
                setAlert(false);
                seterr({ code: 0, msg: "" });
            },
        });
    };

    const handleChange = (e: any) => {
        setShowTable(false);
        setContractData((prevState) => ({
            ...prevState,
            contractno: e?.target?.value,
        }));
    };

    const handleChangeStatus = (e: any) => {
        setShowTable(false);
        setContractData((prevState) => ({
            ...prevState,
            status: e?.target?.value,
        }));
    };
    const handleSearch = () => {
        setShowTable(true);
        setSearch(false);
        setSearchCount((prevState) => prevState + 1);
    };

    return (
        <>
            {alert ? handleErrorAlert(errr.code, errr.msg) : <></>}
            <Backdrop
                className={classes.backdrop}
                open={
                    PayAdviceTableDataLoading ||
                    PayAdviceHeaderDataLoading ||
                    StatusComboDataLoading
                }
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <Grid
                container
                spacing={2}
                alignItems="center"
                style={{ paddingTop: "2%" }}
            >
                <Grid
                    item
                    xs={9}
                    md={active === header?.detail[0]?.desc ? 5 : 4}
                    lg={active === header?.detail[0]?.desc ? 5 : 3}
                >
                    <Paper elevation={4}>
                        <Grid container spacing={1}>
                            <Grid
                                item
                                xs={active === header?.detail[0]?.desc ? 7 : 12}
                                style={{
                                    borderRight:
                                        active === header?.detail[0]?.desc
                                            ? "1px dashed #C9CDD6"
                                            : "none",
                                }}
                            >
                                <Grid
                                    container
                                    spacing={2}
                                    style={{
                                        padding: "4% 6% 1% 12%",
                                    }}
                                >
                                    {header?.detail.map((element) => {
                                        return (
                                            <Grid
                                                item
                                                xs={6}
                                                sm={6}
                                                style={{
                                                    padding: "3% 5%",
                                                }}
                                                className={
                                                    active === element?.desc
                                                        ? `${element?.color}_border_bottom pointer`
                                                        : "pointer"
                                                }
                                                onClick={() =>
                                                    handleActive(element?.desc)
                                                }
                                            >
                                                <Grid container spacing={1}>
                                                    <Grid item xs={12} sm={12}>
                                                        <span
                                                            className={
                                                                active ===
                                                                element?.desc
                                                                    ? `${element?.color} font-biggest font-boldest`
                                                                    : `grey font-biggest font-boldest`
                                                            }
                                                        >
                                                            {element?.value}
                                                        </span>
                                                    </Grid>
                                                    <Grid item xs={12} sm={12}>
                                                        <span
                                                            className={
                                                                active ===
                                                                element?.desc
                                                                    ? `font-boldest font-smallest`
                                                                    : "grey font-smallest"
                                                            }
                                                        >
                                                            {element?.desc.toUpperCase()}
                                                        </span>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        );
                                    })}
                                </Grid>
                            </Grid>
                            {active === header?.detail[0]?.desc && (
                                <Grid
                                    item
                                    xs={4}
                                    style={{
                                        margin: "auto",
                                        display: "flex",
                                        justifyContent: "center",
                                    }}
                                >
                                    <MUIButton
                                        label="View FMIR"
                                        onClick={(event: any) => {
                                            console.log("subContractorKey",subContractorKey);
                                            
                                            if (subContractorKey  ) {
                                                handleClick(event);
                                            } else {
                                                handleSideBar(
                                                    "New Supply Order",
                                                );
                                            }
                                        }}
                                        type="filled"
                                        font="fontMedium"
                                    />
                                </Grid>
                            )}
                        </Grid>
                    </Paper>
                </Grid>
                {active === header?.detail[1]?.desc && (
                    <Grid item sm={12} md={9}>
                        <Paper elevation={4}>
                            <Grid
                                container
                                spacing={2}
                                style={{ padding: "1.3%" }}
                            >
                                <Grid item xs={2}>
                                    <TextFields
                                        label={"Pay Advice ID."}
                                        id={"contractno"}
                                        value={contractData?.contractno}
                                        handleChange={handleChange}
                                        width={"100%"}
                                        multiline={false}
                                        rows={0}
                                        curvedBorder={false}
                                        // placeholder={"Enter PayAdvice ID.."}
                                    />
                                </Grid>
                                <Grid item xs={2}>
                                    <Dropdown
                                        label={"Status"}
                                        id={"status"}
                                        value={contractData?.status}
                                        list={statusList}
                                        handleChange={handleChangeStatus}
                                        width={"100%"}
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <CustomDatePickerBorder
                                        label="From Date"
                                        width="100%"
                                        value={contractData?.fromDate}
                                        onChange={(e) =>
                                            handleDate("fromDate", e)
                                        }
                                        format="MM/dd/yyyy"
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <CustomDatePickerBorder
                                        label="To Date"
                                        width="100%"
                                        value={contractData?.toDate}
                                        onChange={(e) =>
                                            handleDate("toDate", e)
                                        }
                                        format="MM/dd/yyyy"
                                    />
                                </Grid>
                                <Grid item xs={2}>
                                    <MUIButton
                                        label="Search"
                                        type="filled"
                                        font="font-small"
                                        onClick={handleSearch}
                                    />
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                )}
                {active === header?.detail[0]?.desc && (
                    <Grid
                        item
                        xs={12}
                        style={{ marginRight: "2%", paddingTop: "2%" }}
                    >
                        <MuiDatatables
                            header={
                                subContractorKey
                                    ? PayAdviceTableSubConSchema
                                    : PayAdviceTableSchema
                            }
                            detail={[payadviceData, setPayadviceData]}
                        />
                    </Grid>
                )}
                {showTable && active === header?.detail[1]?.desc && (
                    <Grid
                        item
                        xs={12}
                        style={{ marginRight: "2%", paddingTop: "2%" }}
                    >
                        <MuiDatatables
                            header={
                                subContractorKey
                                    ? PayAdviceTableSubConSchema
                                    : PayAdviceTableSchema
                            }
                            title={""}
                            detail={[payadviceData, setPayadviceData]}
                        />
                    </Grid>
                )}
            </Grid>
            <SwipeableDrawerComp
                drawerState={[sideBar, setSideBar]}
                header={"View FMIR"}
                headerColor={"grey2"}
                Child={<ViewFmir active={active} />}
            />
            <Popover
                open={open}
                // anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: "center",
                    horizontal: "center",
                }}
                transformOrigin={{
                    vertical: "center",
                    horizontal: "center",
                }}
            >
                <Grid
                    container
                    alignItems="center"
                    justifyContent="center"
                    // spacing={2}
                    style={{
                        minHeight: "205px",
                        minWidth: "439px",
                        textAlign: "center",
                    }}
                >
                    <Grid item xs={12}>
                        <img src={SubconFMIR} style={{ scale: "1.6" }} />
                    </Grid>
                    <Grid item xs={12} className="font-bolder grey1 font-big">
                        "Please inquire to become a franchise for additional
                        permissions."
                    </Grid>
                </Grid>
            </Popover>
        </>
    );
};

const mapStateToProps = (state: any) => {
    return {
        currentContext: state.context?.userDefaults,
        userName: state.context?.name,
        selectedCustomer: state.selectedCustomer,
        supplierCode: state.hiddenSupplier?.supplierCodeOut,
        selectedVendor: state?.selectedVendor,
        currency: state.currency,
        sessionId: state.sessionId,
        subContractorKey: state.subContractorKey,
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        setBreadcrumbsData: (data: any) => {
            dispatch(storeBreadcrumbs(data));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Body);
