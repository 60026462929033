import { makeStyles } from "@material-ui/core/styles";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import Divider from "@material-ui/core/Divider";
import { ReactElement, useEffect } from "react";
import { Grid } from "@material-ui/core";
import Icons from "../Icons/Icons";

const useStyles = makeStyles({
    list: {
        width: 250,
    },
    fullList: {
        width: "auto",
    },
});

interface SwipeableDrawerProps {
    Child: ReactElement;
    drawerState: [boolean, (state: boolean) => void];
    header: string;
    headerColor: string;
    customPadding?: any;
}

export default function SwipeableDrawerComp(props: SwipeableDrawerProps) {
    const classes = useStyles();
    const { drawerState, header, Child, headerColor, customPadding } = props;
    const [state, setState] = drawerState;
     console.log(props);
     
    const toggleDrawer = () => {
        setState(!state);
    };

   

    return (
        <div className="sideDraw">
            <SwipeableDrawer
                anchor={"right"}
                open={state}
                onClose={toggleDrawer}
                onOpen={toggleDrawer}
                style={{ maxHeight: "100%", width: "100%" }}
                className="hide-overflow-x"
                hysteresis={1}
            >
                <Grid container spacing={1} alignItems="center">
                    <Grid item xs={11} style={{ paddingLeft: "3%" }}>
                        <h3
                            className={`font-biggest font-boldest ${headerColor}`}
                        >
                            {header}
                        </h3>
                    </Grid>

                    <Grid
                        item
                        xs={1}
                        onClick={toggleDrawer}
                        style={{ textAlign: "right", paddingRight: "3%" }}
                        className="pointer"
                    >
                        <Icons Icon={"closeGrey"} info={"default"} />
                    </Grid>
                    <Grid item xs={12}>
                        <Divider />
                    </Grid>
                </Grid>
                <Grid
                    container
                    spacing={1}
                    style={
                        customPadding
                            ? customPadding
                            : { margin: "1.5% 0% 0.5% 1%" }
                    }
                >
                    <Grid item xs={12}>
                        {Child}
                    </Grid>
                </Grid>
            </SwipeableDrawer>
        </div>
    );
}
