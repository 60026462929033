const CompanyDetailsPostUserCusQuery = `
    mutation(
        $ActionFlag:String
        $entity: String
        $ou: Int
        $service: String
        $user: String
        $langId: Int
        $customerCode: String
        $customerName: String
        $name: String
        $Email: String
        $ContactId: String
        $FirstName: String
        $LastName: String
        $JobTitle: String
        $BusinessPhone: String
        $MobilePhone: String
        $isActive: String
        $MakePayment: String
        $OrderSupplies: String
        $Admin: String
        $merrorid: String
    ){
        CompanyPostUserCus(
            ActionFlag:$ActionFlag
            entity: $entity
            ou: $ou
            service: $service
            user: $user
            langId: $langId
            customerCode: $customerCode
            customerName: $customerName
            name: $name
            Email: $Email
            ContactId: $ContactId
            FirstName: $FirstName
            LastName: $LastName
            JobTitle: $JobTitle
            BusinessPhone: $BusinessPhone
            MobilePhone: $MobilePhone
            isActive: $isActive
            MakePayment: $MakePayment
            OrderSupplies: $OrderSupplies
            Admin: $Admin
            merrorid: $merrorid
        ){   
            Name
            Email
            ContactId
            UserName
            Designation
            isActive
            MakePayment
            OrderSupplies
            Admin
            Image 
            FirstName
            LastName
            JobTitle
            BusinessPhone
            MobilePhone    
            Message    
        }
    }
`;

export default CompanyDetailsPostUserCusQuery;
