import { Grid, Paper } from "@material-ui/core";
import currencyFormatter from "Common/Utils/currencyFormatter";
import { Fragment } from "react";
import No_Data_Available from "../Images/No_Data_Available.svg";

interface Detail {
    desc: string;
    value: any;
    color: string;
    icon?: any;
}
interface DashCardProps {
    header: string;
    headerColor: string;
    detail?: Detail[];
    currency: any;
}
const DashCards = (props: DashCardProps) => {
    const { header, detail, headerColor, currency } = props;
    const gridSpacing = detail?.length === 3 ? 4 : 5;

    return (
        <Fragment>
            {detail ? (
                <Paper elevation={3} style={{ minHeight: "127px" }}>
                    <Grid container spacing={1}>
                        <Grid
                            item
                            xs={12}
                            className="text-left font-boldest"
                            style={{ margin: "3% 5%" }}
                        >
                            <Grid container spacing={1}>
                                <Grid
                                    item
                                    // xs={4}
                                    className="font-boldest grey1"
                                    style={{
                                        background: `${headerColor}`,
                                        borderRadius: "5px",
                                    }}
                                >
                                    {header}
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container spacing={1} justifyContent="center">
                                {detail.map(
                                    (element: Detail, index: number) => {
                                        return (
                                            <>
                                                <Grid
                                                    item
                                                    xs={gridSpacing}
                                                    sm={gridSpacing}
                                                >
                                                    <Grid
                                                        container
                                                        spacing={1}
                                                        className="margin-bottom-10-per"
                                                    >
                                                        <Grid
                                                            item
                                                            xs={12}
                                                            sm={12}
                                                            className={`font-boldest black`}
                                                            style={{
                                                                fontSize: 17,
                                                            }}
                                                        >
                                                            {element.value
                                                                ? currencyFormatter(
                                                                      element.value,
                                                                      currency,
                                                                  )
                                                                : currencyFormatter(
                                                                      0,
                                                                      currency,
                                                                  )}
                                                        </Grid>
                                                        <Grid
                                                            item
                                                            xs={12}
                                                            sm={12}
                                                            className={`font-smallest ${element.color}`}
                                                        >
                                                            <Grid
                                                                container
                                                                justifyContent="center"
                                                            >
                                                                <Grid
                                                                    item
                                                                    xs={
                                                                        detail.length ===
                                                                        3
                                                                            ? 2
                                                                            : 1
                                                                    }
                                                                >
                                                                    {
                                                                        element?.icon
                                                                    }
                                                                </Grid>
                                                                <Grid
                                                                    item
                                                                    xs={
                                                                        element
                                                                            ?.desc
                                                                            ?.length <=
                                                                        8
                                                                            ? 5
                                                                            : 6
                                                                    }
                                                                    style={{
                                                                        fontSize:
                                                                            "11px",
                                                                        fontWeight:
                                                                            "bolder",
                                                                    }}
                                                                >
                                                                    {
                                                                        element.desc
                                                                    }
                                                                    {element
                                                                        ?.desc
                                                                        ?.length <=
                                                                    13 ? (
                                                                        <br />
                                                                    ) : (
                                                                        ""
                                                                    )}
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </>
                                        );
                                    },
                                )}
                            </Grid>
                        </Grid>
                    </Grid>
                </Paper>
            ) : (
                <Paper elevation={3} style={{ minHeight: "127px" }}>
                    <img src="No_Data_Available" />
                </Paper>
            )}
        </Fragment>
    );
};

export default DashCards;
