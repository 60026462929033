import Application from "layout/Application";
import LoginPage from "layout/LoginPage";
import PostLogin from "layout/PostLogin";
import LogoutPage from "layout/LogoutPage";
import React, { Component } from "react";
import { Route, useLocation } from "react-router-dom";
import "./App.scss";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import Configuration from "./Configuration";
const queryClient = new QueryClient();

const useQuery = () => {
    return new URLSearchParams(useLocation().search);
};

const Routes = () => {
    let query = useQuery();
    return (
        // <React.Fragment>
        //     <Route exact path="/" render={() => <LoginPage />} />
        //     <Route exact path="/login" render={() => <LoginPage />} />
        //     <QueryClientProvider client={queryClient}>
        //         <Route
        //             exact
        //             path="/postlogin"
        //             render={() => <PostLogin sId={query.get("sId")} />}
        //         />
        //         <Route path="/app/*" render={() => <Application />} />
        //         <ReactQueryDevtools initialIsOpen={false} />
        //     </QueryClientProvider>
        //     <Route path="/Logout" render={() => <LogoutPage />} />
        // </React.Fragment>
        <React.Fragment>
            <Route exact path="/login" render={() => <LoginPage />} />
            <Route path="/Logout" render={() => <LogoutPage />} />
            {/* <Route
            path="/MultipleSessions"
            render={() => <MultipleSessions />}
        />        */}
            <QueryClientProvider client={queryClient}>
                <Route
                    path="/postlogin"
                    render={() => <PostLogin sId={query.get("sId")} />}
                />
                <Route path="/app/*" render={() => <Application />} />
                <ReactQueryDevtools initialIsOpen={false} />
            </QueryClientProvider>
            <Route exact path="/" render={() => <LoginPage />} />
        </React.Fragment>
    );
};

export default class App extends Component {
    render() {
        return (
            <div>
                <Routes />
            </div>
        );
    }
}
