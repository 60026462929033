import { STORE_BREADCRUMBS } from "../../actionTypes";

const breadcrumbData = (data: any) => {
  return {
    type: STORE_BREADCRUMBS,
    payload: data,
  };
};

/**
 * Common File redux
 * @param code
 */
export function storeBreadcrumbs<T>(data: any) {
  return (dispatch: any) => {
    dispatch(breadcrumbData(data));
  };
}
