const GetDepositInfoVenQuery = `
mutation(
$entity: String
$ou: Int
$service: String
$User: String
$languageId: Int
$vendorCode: String
$vendorName: String
$merrorid: String
){
GetDepositInfo(
entity: $entity
ou: $ou
service: $service
User: $User
languageId: $languageId
vendorCode: $vendorCode
vendorName: $vendorName
merrorid: $merrorid
){   
AccountNumber
BankName
BicSwiftNo
BranchName
CityOfBank
IbanNo
RecordId
SupplierCode
SupplierLegalName
SupplierName
}
}
`;

export default GetDepositInfoVenQuery;
