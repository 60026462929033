import { Grid, Paper } from "@material-ui/core";
import Icons from "Common/Icons/Icons";
import SwipeableDrawerComp from "Common/SideDrawer/SideDrawer";
import currencyFormatter from "Common/Utils/currencyFormatter";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import "../../../Common/Main.scss";
import ApprovalVen from "../ApprovalVen";
import ErrorCard from "../DashbordCards/ErrorCard";
import FranchiseeChild from "../FranchiseeChild";

const PendingApproval = (props: any) => {
    const { PendingApprovalData, currency,userReadOnlyAccess } = props;

    const [openApprovalDrawer, setOpenApprovalDrawer] = useState<any>(false);
    const [arrayKey, setarrayKey] = useState<number>(0);
    const [approvalKey, setApprovalKey] = useState(false);
    function getWeeksDiff(startDate: any, endDate: any) {
        const startingDate = new Date(startDate);
        const endingDate = new Date(endDate);
        const msInWeek = 1000 * 60 * 60 * 24 * 7;
        const result = Math.round(
            (Math.abs(((endingDate as any) - (startingDate as any)) as any) /
                msInWeek) as any,
        );

        if (result === 1) return "A week ago.";
        else if (result < 1) return "Few days ago.";
        else if (result > 1) return `${result} weeks ago`;
        else return "";
    }

    useEffect(() => {
        if (PendingApprovalData?.length) {
            const timer = setInterval(() => {
                setarrayKey((prevState) => {
                    if (prevState === 2) {
                        console.log("Start over");
                        return 0;
                    } else {
                        console.log("prevState + 1", prevState);
                        return prevState + 1;
                    }
                });
            }, 3000);
            return () => {
                clearInterval(timer);
            };
        }
    }, [PendingApprovalData]);

    return (
        <>
            {(PendingApprovalData?.length > 0  && PendingApprovalData?.TypeOfService !=="NA")? (
                <>
                    <Grid container>
                        <Grid item lg={12} style={{ paddingBottom: "2%" }}>
                            <Grid container>
                                <Grid
                                    item
                                    xs={6}
                                    className="text-left font-boldest font-big"
                                    style={{ padding: "2% 1.5%" }}
                                >
                                    {`Pending Approval (${PendingApprovalData?.length})`}
                                </Grid>
                                <Grid
                                    item
                                    lg={6}
                                    className="text-right font-boldest"
                                >
                                    {[1, 2, 3].map((element, index) => {
                                        return (
                                            <span
                                                onClick={() => {
                                                    setarrayKey(index);
                                                }}
                                                className={`dotcolor ${
                                                    index === arrayKey
                                                        ? "dotgrey"
                                                        : ""
                                                }`}
                                            ></span>
                                        );
                                    })}
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item lg={12}>
                            <Paper elevation={4}>
                                <Grid container justifyContent="flex-start">
                                    <Grid
                                        item
                                        lg={10}
                                        style={{ padding: "5%" }}
                                    >
                                        <Grid
                                            container
                                            spacing={2}
                                            justifyContent="center"
                                        >
                                            <Grid item lg={12}>
                                                <Grid container>
                                                    <Grid
                                                        item
                                                        sm={12}
                                                        lg={6}
                                                        style={{
                                                            paddingLeft: "2%",
                                                        }}
                                                    >
                                                        <Grid
                                                            container
                                                            justifyContent="center"
                                                            className="text-left"
                                                        >
                                                            <Grid item lg={1}>
                                                                <Icons Icon="userIcon" />
                                                            </Grid>
                                                            <Grid
                                                                item
                                                                lg={5}
                                                                className="font-bolder"
                                                            >
                                                                {
                                                                    PendingApprovalData[
                                                                        arrayKey
                                                                    ]
                                                                        ?.Offernumber
                                                                }
                                                            </Grid>
                                                            <Grid
                                                                item
                                                                lg={1}
                                                                className="grey"
                                                            >
                                                                |
                                                            </Grid>
                                                            <Grid
                                                                item
                                                                lg={5}
                                                                className="grey"
                                                            >
                                                                {
                                                                    PendingApprovalData[
                                                                        arrayKey
                                                                    ]
                                                                        ?.Customercontract
                                                                }
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item sm={12} lg={6}>
                                                        <Grid
                                                            container
                                                            justifyContent="center"
                                                            className="text-left"
                                                        >
                                                            <Grid
                                                                item
                                                                lg={1}
                                                                style={{
                                                                    paddingLeft:
                                                                        "3%",
                                                                }}
                                                            >
                                                                <Icons Icon="BuildPic" />
                                                            </Grid>
                                                            <Grid
                                                                item
                                                                lg={11}
                                                                style={{
                                                                    paddingLeft:
                                                                        "7%",
                                                                }}
                                                            >
                                                                {
                                                                    PendingApprovalData[
                                                                        arrayKey
                                                                    ]?.custname
                                                                }
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item lg={12}>
                                                <Grid container>
                                                    <Grid
                                                        item
                                                        sm={12}
                                                        lg={6}
                                                        style={{
                                                            paddingLeft: "2%",
                                                        }}
                                                    >
                                                        <Grid
                                                            container
                                                            justifyContent="center"
                                                            className="text-left"
                                                        >
                                                            <Grid
                                                                item
                                                                sm={2}
                                                                lg={1}
                                                            >
                                                                <Icons Icon="calenderPic" />
                                                            </Grid>
                                                            <Grid
                                                                item
                                                                sm={10}
                                                                lg={11}
                                                            >
                                                                {
                                                                    PendingApprovalData[
                                                                        arrayKey
                                                                    ]?.Offerdate
                                                                }
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item sm={12} lg={6}>
                                                        <Grid
                                                            container
                                                            justifyContent="center"
                                                            className="text-left"
                                                        >
                                                            <Grid
                                                                item
                                                                sm={2}
                                                                lg={1}
                                                                style={{
                                                                    paddingTop:
                                                                        ".5%",
                                                                }}
                                                            >
                                                                <Icons Icon="timingIcon" />
                                                            </Grid>
                                                            <Grid
                                                                item
                                                                sm={10}
                                                                lg={11}
                                                                className="grey"
                                                            >
                                                                {getWeeksDiff(
                                                                    PendingApprovalData[
                                                                        arrayKey
                                                                    ]
                                                                        ?.startdate,
                                                                    new Date(),
                                                                )}
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item lg={12}>
                                                <Grid
                                                    container
                                                    justifyContent="center"
                                                >
                                                    <Grid
                                                        item
                                                        sm={7}
                                                        lg={4}
                                                        className="grey"
                                                    >
                                                        Amount Offered
                                                    </Grid>
                                                    <Grid
                                                        item
                                                        sm={5}
                                                        lg={8}
                                                        className="text-left green font-biggest"
                                                    >
                                                        {currencyFormatter(
                                                            PendingApprovalData[
                                                                arrayKey
                                                            ]?.amountoffer,
                                                            currency,
                                                        )}
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                    <Grid
                                        item
                                        lg={2}
                                        style={{ textAlign: "left" }}
                                    >
                                        <Grid
                                            container
                                            className="pointer"
                                            style={{
                                                textAlign: "center",
                                            }}
                                        >
                                            <Grid
                                                item
                                                lg={12}
                                                style={{
                                                    background: "#00AE50B2",
                                                    padding: "35%",
                                                }}
                                                onClick={() => {
                                                    if(userReadOnlyAccess){

                                                        setOpenApprovalDrawer(true);
                                                        setApprovalKey(true);
                                                    }
                                                }}
                                            >
                                                <Icons Icon="whiteTick" />
                                            </Grid>
                                            <Grid
                                                item
                                                lg={12}
                                                style={{
                                                    background: "#E01E37B2",
                                                    padding: "32%",
                                                }}
                                                onClick={() => {
                                                    if(userReadOnlyAccess){

                                                        setOpenApprovalDrawer(true);
                                                        setApprovalKey(false);
                                                    }
                                                }}
                                            >
                                                <Icons Icon="whiteCross" />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid>
                    </Grid>
                    <SwipeableDrawerComp
                        drawerState={[
                            openApprovalDrawer,
                            setOpenApprovalDrawer,
                        ]}
                        header={"Approval"}
                        headerColor={"grey2"}
                        Child={
                            <ApprovalVen
                                approvalKey={approvalKey}
                                PendingApprovalData={
                                    PendingApprovalData[arrayKey]
                                }
                            />
                        }
                    />
                </>
            ) : (
                <>
                    <Grid
                        item
                        xs={6}
                        className="text-left font-boldest font-big"
                        style={{ padding: "2% 1.5%" }}
                    >
                        Pending Approval (0)
                    </Grid>
                    <Grid item xs={12} style={{ paddingTop: "2%" }} />
                    {ErrorCard(
                        "NoApprovalsError",
                        "Yay!",
                        "No Approvals Pending, List is all-clear!",
                    )}
                </>
            )}
        </>
    );
};

const mapStateToProps = (state: any) => {
    return {
        currency: state.currency,
        userReadOnlyAccess: state?.userReadOnlyAccess,
    };
};

export default connect(mapStateToProps)(PendingApproval);
