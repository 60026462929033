

const AddNotesCUS = `
query Query (
 
 $Subject:String,
 $Description:String){
 AddNotesCUS(

     
        Subject:$Subject,
        Description:$Description,

    ) {
    msg
    }
  }
  `;

export default AddNotesCUS;
