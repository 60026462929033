const CompanyProfileVenQuery = `
mutation(
$entity: String
$ou: Int
$service: String
$User: String
$languageId: Int
$vendorCode: String
$vendorName: String
$merrorid: String
){
CompanyProfileVen(
entity: $entity
ou: $ou
service: $service
User: $User
languageId: $languageId
vendorCode: $vendorCode
vendorName: $vendorName
merrorid: $merrorid
){   
address
city
contactperson
contactpersonemailaddress
contactpersonphonenumber
country
logo
logourl
state
vendorcode
vendorname
zip
}
}
`;

export default CompanyProfileVenQuery;
