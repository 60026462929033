const CompanyPostUserVenQuery = `
mutation(
$supplierCode: String
$supplierName: String
$createUsers: createUsers
){
CompanyAddUserVen(
supplierCode: $supplierCode
supplierName: $supplierName
createUsers: $createUsers
){
admin
bphone
contactperson
email
firstname
isactive
jobtitle
lastname
mphone
ordersupp
portaluser
username
message
}
}
`;

export default CompanyPostUserVenQuery;