import * as React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Popover from "@material-ui/core/Popover";
import { Grid } from "@material-ui/core"
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import { Link, useHistory, useRouteMatch } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    typography: {
        padding: theme.spacing(2),
    },
}));

export default function BasicPopover(props) {
    const classes = useStyles();
    const { anchors, data,handleDrawerClose , clickListener} = props;

    const [anchorEl, setAnchorEl] = anchors; //React.useState(null);

    // const handleClick = (event) => {
    //   setAnchorEl(event.currentTarget);
    // };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? "simple-popover" : undefined;
    const { path, url } = useRouteMatch();
    const history = useHistory();
    if (data.length > 0)
    return (
        <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
                vertical: "center",
                horizontal: "right",
            }}
            transformOrigin={{
                vertical: "center",
                horizontal: "left",
            }}
            PaperProps={{
                style: {
                    backgroundColor: "white",
                    border: "1px solid #F1F1F1",
                    boxShadow: "none",
                    borderRadius: 0,
                },
            }}
        >
            {/* <Box
                sx={
                    {
                        // position: "relative",
                        // marginLeft:"100px",
                        // mt: "100px",
                        //   // ml:"10px",
                        //   marginLeft:"10px",
                        //   "&::before": {
                        //     backgroundColor: "red",
                        //     content: '""',
                        //     display: "block",
                        //     position: "absolute",
                        //     width: 12,
                        //     height: 12,
                        //     top: 22,
                        //     transform: "rotate(135deg)",
                        //     left: "calc(4% - 16px)",
                        //     zIndex:100
                        //   }
                    }
                }
            /> */}
            <Grid container spacing={2} alignItems="center" style={{maxWidth:"150px", margin:"10px 10px 10px 0px",}}>
                {data.map((element) => {
                    return (
                        <Grid item xs={12} className="popoverMenu" >
                           <span style={{paddingLeft :"10%", cursor: "pointer"}} 
                             onClick={(e) => {
                                console.log(
                                    "Path--------",
                                    url,
                                    path.replace("*/", ""),
                                );
                                clickListener(
                                    element.id,
                                    element.level,
                                    element.breadcrumb,
                                      e)
                                history.push(
                                    element?.subRoute
                                        ? `${path.replace("*/", "")}/${
                                            element.route
                                          }/${element?.subRoute}`
                                        : `${path.replace("*/", "")}/${
                                            element.route
                                          }`,
                                );
                                handleDrawerClose()
                            }}
                           >{element?.title}</span> 
                        </Grid>
                    );
                })}
            </Grid>

            {/* <Typography sx={{ p: 2, backgroundColor: "white" }}>
         {data.map(element => {
           return (<>
           
           <br />
           </>)
         })}
        </Typography> */}
        </Popover>
    );

    return <></>
}
