import { Divider, Grid } from "@material-ui/core";
import MUIButton from "Common/AttachmentButton/AttachmentButton";
import Button from "Common/Button/Button";
import Icons from "Common/Icons/Icons";
import React, { useEffect, useState } from "react";
import TextFields from "Common/TextField/TextField";
import useGetPosts from "ReactQuery/reactQuery";
// import addAddressDetailsQuery from "ReactQuery/Query/AddAddressDetailsQuery";
import { storeSupplier } from "stateManagement/actions/HomePage/storeHiddenSupplier";
import { connect } from "react-redux";
import { Combo } from "Common/Listedit/Listedit";
import Dropdown from "Common/Dropdown/Dropdown";
import { AddNotesCUS } from "ReactQuery/Query";
import { create } from "domain";

interface IndexProps {
    currentContext: any;
    setSupplier: any;
    supplierCode: string;
    currentData?: any;
    cityComboData?: any;
    stateComboData?: any;
    countryCombo?: any;
}
interface controlListProp {
    desc: string;
    value: string;
}

interface AddNotes {
 
    Subject: string;
    Description: string;
}

const AddNotesSidedraw = (props: IndexProps) => {
    const {
        currentContext,
        supplierCode,
        currentData,
        countryCombo,
        stateComboData,
        cityComboData,
    } = props;
    const [attached, setAttached] = useState(true);

    const handleAttachment = () => {
        console.log("clicked");
        setAttached((prevState) => !prevState);
    };

    // const [addaddressdata, setAddAddressdata] = useState<any>({
    //     CustomerName: "",
    //     Building: "",
    //     Severity: "",
    //     ContactPerson: "",
    //     PhoneNumber: "",
    //     Subject: "",
    //     Description: "",
    // });

    const [Create, setCreate] = useState<boolean>(false);

    const [AddNotes, setAddNotes] = useState<AddNotes>({
      
        Subject: "",
        Description: "",
    });

    const handleDocList = (e: React.ChangeEvent<{ value: unknown }>) => {
        const name = (e?.target as any).name;
        const value = (e?.target as any).value;
        setAddNotes((prevstate: any) => ({
            ...prevstate,
            [name]: value,
        }));
    };

    /* 
    const handleDocList = (e: React.ChangeEvent<{ value: unknown }>) => {
      console.log('id-name',e);
  };
   */

    const handleSubmit = () => {
        console.log("data-state", AddNotes);
        setCreate((prev) => !prev);
    };

    const controlList = [
        {
            desc: "General",
            value: "General",
        },
        {
            desc: "Assignment/Refusals/Fulfillment",
            value: "Assignment/Refusals/Fulfillment",
        },
        {
            desc: "Amortization",
            value: "Amortization",
        },
    ];

    const handleChange = (e: any) => {
        console.log(e.target);
    };
    const {
        data: AddNotessListData,
        error: AddNotessListDataError,
        isLoading: AddNotessListDataLoading,
    } = useGetPosts({
        name: `AddNotesCUS_get_data`,
        query: AddNotesCUS,
        variables: {
          
            Subject: AddNotes.Subject,
            Description: AddNotes.Description,
        },
        skip: Create,
    });

    useEffect(() => {
        if (AddNotessListData?.AddNotesCUS) {
            console.log(AddNotessListData?.AddNotesCUS?.msg);
        }
    }, [AddNotessListData]);

    return (
        <>
            <Grid
                container
                spacing={3}
                style={{
                    maxWidth: "450px",
                    paddingLeft: "2%",
                    marginTop: "1%",
                }}
                alignItems="center"
            >
                <Grid item xs={11} sm={11}>
                    <TextFields
                        label="Subject"
                        id="Subject"
                    
                        rows={3}
                        value={AddNotes.Subject}
                        handleChange={handleDocList}
                        width="100%"
                    />
                </Grid>

                <Grid item xs={11} sm={11}>
                    <TextFields
                        label="Description"
                        id="Description"
                        multiline={true}
                        rows={3}
                        value={AddNotes.Description}
                        handleChange={handleDocList}
                        width="100%"
                    />
                </Grid>
                <Grid container style={{ paddingTop: "70%" }}></Grid>

                <Grid item xs={12} sm={11}>
                
                    <Grid container style={{ paddingTop: "5%" }}>
                        <Grid item xs={6}></Grid>
                        <Grid item xs={3}>
                            <Button
                                label={"Reset"}
                                type="outlined"
                                font={""}
                                onClick={() => {}}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <Button
                                label={"Submit"}
                                type={"filled"}
                                font={""}
                                onClick={handleSubmit}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};
const mapStateToProps = (state: any) => {
    return {
        supplierCode: state.hiddenSupplier?.supplierCodeOut,
        storeCompanies: state.storeCompanies,
        storeOus: state.storeOus,
        currentContext: state.context?.userDefaults,
        userName: state.context.name,
    };
};
const mapDispatchToProps = (dispatch: any) => {
    return {
        setSupplier: (data: any) => {
            dispatch(storeSupplier(data));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddNotesSidedraw);
