const TicketEngagementDetail = `
    query Query($engagementId : [String]){
        TicketEngagementDetail(engagementId : $engagementId ){
            ActivityType
Subject
Description
Date
OWRep
        }
    }
`;

export default TicketEngagementDetail;
