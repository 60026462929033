import { Button, Container, Grid } from "@material-ui/core";
import { createTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/core/styles";
import axios from "axios";
import NavBar from "Common/NavBar/NavBar";
import Configuration from "Configuration";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import ChildComponent from "./ChildComponent";
import sessionsSVG from "./Images/session.svg";

import {
    Backdrop,
    CircularProgress,
    makeStyles,
    Theme,
} from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: "#fff",
    },
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
    },
}));

const ApplicationLayout = (props: any) => {
    const { session } = props;
    const [login, setLogin] = useState(false);
    const [open, setOpen] = useState(false);
    const classes = useStyles();
    const [timerCheck, setTimerCheck] = useState(true);

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        (async () => {
            try {
                const response = await axios(
                    `${Configuration.getConfiguration().getAppAPILocation()}/api/auth/checksession?sId=${session}`,
                );
                if (response.status === 200) {
                    console.log("Session is valid", session);
                    setLogin(true);
                }
            } catch (error) {
                console.log("Context Error", error);
            }
        })();
    }, []);

    const handleClick = () => {
        localStorage.removeItem("state");
        localStorage.removeItem("session");
        window.location.replace(
            `${Configuration.getConfiguration().getWebAppLocation()}`,
        );
    };

    useEffect(() => {
        const handleKeyPress = (event:KeyboardEvent) => {
          if (event.key === 'Enter') {
            handleClick()
          }
        };
    
         // Get reference to the logout button
    const logoutButton:any = document.getElementById('logout');

    // Attach key press event listener to the logout button
    if (logoutButton) {
        logoutButton.addEventListener('keypress', handleKeyPress);
      }

    // Cleanup function to remove event listener
    return () => {
        console.log("logout listner is removed");
        
      logoutButton.removeEventListener('keypress', handleKeyPress);
    };
      },[]);

    useEffect(() => {
        setTimeout(() => {
            setTimerCheck(false);
        }, 1000);
    }, []);

    if (timerCheck)
        return (
            <Backdrop
                style={{
                    zIndex: 1,
                    color: "#fff",
                }}
                open={true}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        );

    if (!login)
        return (
            <>
                <Container maxWidth="sm">
                    <Grid container>
                        <Grid item md={12} sm={12} xs={12}>
                            <img
                                src={sessionsSVG}
                                style={{
                                    display: "block",
                                    marginTop: "30%",
                                    marginLeft: "auto",
                                    marginRight: "auto",
                                    width: "70%",
                                }}
                                alt="Unauthenticated/MultipleSessions"
                            />
                        </Grid>
                        <br />
                        <br />
                        <Grid
                            item
                            md={12}
                            sm={12}
                            xs={12}
                            style={{ textAlign: "center", marginTop: "3%" }}
                        >
                            <Grid>
                                <Grid item md={12} sm={12} xs={12}>
                                    <h2>
                                        No Valid Session found, Please login to
                                        continue.
                                    </h2>
                                </Grid>
                                <br />
                                <Grid
                                    item
                                    md={12}
                                    sm={12}
                                    xs={12}
                                    justify="center"
                                >
                                    <Button
                                        id="logout"
                                        color="primary"
                                        variant="contained"
                                        onClick={handleClick}
                                        
                                    >
                                        {" "}
                                        Login
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Container>
            </>
        );
    const theme = createTheme({
        palette: {
            primary: {
                main: "#FFFFFF",
            },
            secondary: {
                main: "#0073e6",
            },
        },
        overrides: {
            MuiPaper: {
                elevation4: {
                    boxShadow: "4px 6px 4px rgba(2, 62, 125, 0.05)",
                },
                elevation3: {
                    boxShadow: "4px 6px 4px rgba(2, 62, 125, 0.05)",
                },
                elevation5: {
                    border: "1px solid #E9EBEF",
                    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.12)",
                    borderRadius: "4px",
                },
            },
        },
    });
    // const theme = createTheme({
    //     palette: {
    //         primary: {
    //             main: "#FFFFFF",
    //         },
    //         secondary: {
    //             main: "#0073e6",
    //         },
    //     },
    //     overrides: {
    //         MuiPaper: {
    //             elevation4: {
    //                 boxShadow: "4px 6px 4px rgba(2, 62, 125, 0.05)",
    //             },
    //             elevation3: {
    //                 boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.05)",
    //             },
    //         },
    //     },
    // });
    return (
        <ThemeProvider theme={theme}>
            <NavBar Child={<></>} openState={[open, setOpen]} />
            <div
                style={{
                    marginLeft: "7%",
                    maxWidth: "91.5%",
                    minHeight: "80vh",
                }}
                className="DOMMM"
                onClick={handleDrawerClose}
            >
                <ChildComponent />
            </div>
        </ThemeProvider>
    );
};

const mapStateToProps = (state: any) => {
    return {
        session: state.sessionId,
    };
};

export default connect(mapStateToProps)(ApplicationLayout);
