const WorkerCompensationVEN = `
mutation(    
    $entity: String
    $ou: Int
    $service: String
    $User: String
    $languageId: Int
   
    $merrorid: String
    $vendorname: String,
    $vendorcode:String,
){

    WorkerCompensationVEN (  
        entity: $entity
        ou: $ou
        service: $service
        User: $User
        languageId: $languageId
       
        merrorid:$merrorid
        vendorname:$vendorname
        vendorcode:$vendorcode
        ){
        WorkersCompansationApplicable
        WorkersCompansationExpirationDate
        LiabilityInsuranceApplicable
        LiabilityInsuranceExpirationDate
        RegistrationNumber
        NumberValidTill
     
    }
}`;

export default WorkerCompensationVEN;