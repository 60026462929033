const DirectDepositInfoVenQuery = `
mutation(
$suppcode: String
$createDepositDirectInfo: createDepositDirectInfo
){
DirectDepositInfo(
suppcode: $suppcode
createDepositDirectInfo: $createDepositDirectInfo
){
ABA
AccountName
AccountNumber
Bank_name
BICNoSwiftNo
BranchName
CityofBank
Supplier_name
}
}
`;

export default DirectDepositInfoVenQuery;