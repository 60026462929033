const dateFormat = require("dateformat");
// import dateFormat from "dateformat"
const dateFormatter = (type: string, date: Date | undefined | null) => {
    if (date === undefined || date === null) return "";
    const format = {
        dateOnly: "dd/mm/yyyy",
        dateTime: "dd/mm/yyyy,h:MM TT",
        dateMonth: "dS mmmm yyyy",
        sqlDate: "mm/dd/yyyy",
        reversedDate: "yyyy/mm/dd",
        sqldateTime: "mm/dd/yyyy, h:MM TT",
    };
    try {
        if (dateFormat(date, (format as any)[type])) {
            return dateFormat(date, (format as any)[type]);
        }
    } catch {
        return "";
    }
};
export default dateFormatter;