import React, { useCallback, useEffect, useState } from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete, {
    createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import {
    Grid,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from "@material-ui/core";

interface OptionDetail {
    code: string;
    desc: string;
}

interface ComboProps {
    label: string;
    value?: any[];
    header: {
        code: string;
        desc: string;
        api: boolean;
    }[];
    detail: OptionDetail[][];
    handleInput: (key: string, value: string) => void;
    width: string;
    minWidth: string;
    fetchType: [string, React.Dispatch<React.SetStateAction<string>>];
    loading: boolean;
}

interface FilmOptionType {
    title: string;
    year: number;
    language: string;
}

export const Combo = (props: ComboProps) => {
    const {
        label,
        value,
        header,
        detail,
        handleInput,
        width,
        minWidth,
        fetchType,
        loading,
    } = props;
    const [fetchTypes, setFetchTypes] = fetchType;
    const [itemSelected, setItemSelected] = useState<any[]>([]);
    const [lastPropsSelected, setlastPropsSelected] = useState([]);
    const [typedValue, setTypedValue] = useState("");

    useEffect(() => {
        if (value) {
            console.log("Inside Listedit", value);
            setItemSelected(value);
        }
    }, [value]);

    const filterOptions = createFilterOptions({
        matchFrom: "any",
        stringify: (option: OptionDetail[]) => {
            if (option.length === 0 && fetchTypes === "upe") {
                setFetchTypes("ufe");
            }
            return JSON.stringify(option);
        },
    });

    const grid = useCallback(
        (option: OptionDetail[]) => {
            return option[1]?.desc;
        },
        [header],
    );

    const handleChange = () => (event: any, value: any) => {
        if (value) {
            setItemSelected(value);
            const values = header.findIndex((element) => element.api === true);
            console.log(values, value[values]);
            handleInput(header[values]?.code, value[values]?.code);
        }
    };

    const handleTypedChange = (event: any) => {
        setTypedValue((event?.target as any)?.value);
    };

    useEffect(() => {
        if (fetchTypes === "upe" && typedValue !== "") {
            console.log("Values", lastPropsSelected, typedValue);
            const typedLower = typedValue.toLowerCase();
            const refetch = lastPropsSelected.filter(
                (element: OptionDetail) => {
                    if (
                        element?.code.toLowerCase().includes(typedLower) ||
                        element?.desc.toLowerCase().includes(typedLower)
                    )
                        return true;
                    return false;
                },
            );
            if (refetch.length) setFetchTypes("ufe");
        }
    }, [typedValue, lastPropsSelected]);

    return (
        <div className="listedit">
            <Autocomplete
                options={detail}
                value={itemSelected}
                getOptionLabel={grid}
                filterOptions={filterOptions}
                disableClearable
                onChange={handleChange()}
                loading={loading}
                renderOption={(props: any, option: any) => {
                    setlastPropsSelected(props);
                    return (
                        <React.Fragment>
                            {props.map((element: any) => {
                                return (
                                    <Grid
                                        item
                                        xs
                                        component="th"
                                        scope="row"
                                        key={element?.code}
                                    >
                                        <span style={{ fontWeight: 300 }}>
                                            {element.desc}
                                        </span>
                                    </Grid>
                                );
                            })}
                        </React.Fragment>
                    );
                }}
                PaperComponent={({ children }) => (
                    <Paper variant="outlined" style={{ minWidth: minWidth }}>
                        {/* <TableContainer component={Paper} > */}
                        <Table aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <Grid container>
                                        {header.map((element) => {
                                            return (
                                                <Grid
                                                    item
                                                    xs
                                                    key={element?.code}
                                                    style={{
                                                        margin: "2% 0% 0% 0.5%",
                                                    }}
                                                >
                                                    <span className="font-boldest grey2">
                                                        {element?.desc}
                                                    </span>
                                                </Grid>
                                            );
                                        })}
                                    </Grid>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>{children}</TableRow>
                            </TableBody>
                        </Table>
                        {/* </TableContainer> */}
                    </Paper>
                )}
                renderInput={(params: any) => {
                    return (
                        <TextField
                            {...params}
                            size="small"
                            label={label}
                            margin="normal"
                            variant="outlined"
                            color="secondary"
                            onChange={handleTypedChange}
                            style={{ width: width }}
                            InputLabelProps={{
                                shrink: true,
                              }}
                        />
                    );
                }}
            />
        </div>
    );
};
