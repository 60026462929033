import { InputLabel, MenuItem, Select, FormControl, OutlinedInput } from "@material-ui/core";
import React from "react";
import "./Dropdown.scss";
import { createTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/core/styles";

interface ListProps {
    desc: string | number;
    value: string | number;
}

interface DropdownProps {
    label: string;
    id: string;
    value: string | number;
    list: ListProps[];
    handleChange: (e: React.ChangeEvent<{ value: unknown }>) => void;
    width: string;
}

const theme = createTheme({
    palette: {
        primary: {
            main: "#0073e6",
        },
        secondary: {
            main: "#0073e6",
        },
    },
    overrides: {
        MuiSelect: {
            outlined: {
                padding: "12.5px !important",
            },
            selectMenu: {
                fontSize: "0.95rem",
                fontFamily: "OpenSans, sans-serif",
                textAlign: "left",
            },
        },
        MuiListItem: {
            button: {
                fontSize: "0.95rem",
                fontFamily: "OpenSans, sans-serif",
            },
        },

        MuiFormLabel: {
            root: {
                fontSize: "1rem",
                fontFamily: "OpenSans, sans-serif",
            },
        },
        MuiOutlinedInput: {
            notchedOutline: {
                border: "none",
                fontFamily: "OpenSans, sans-serif",
                borderColor: "#fff !important",
            },
        },
        MuiInputBase: {
            root: {
                paddingTop: "0px !important",
            },
        },
        MuiInputLabel: {
            outlined: {
                transform: "translate(14px, 12px) scale(1)",
            },
        },
    },
});
const HiddenDropdown = (props: DropdownProps) => {
    const { label, id, value, list, handleChange, width } = props;
    const inputLabel = React.useRef(null);
    const [labelWidth, setLabelWidth] = React.useState(0);
    React.useEffect(() => {
        if(inputLabel?.current)
        setLabelWidth((inputLabel.current as any).offsetWidth);
    }, []);
    return (
        <ThemeProvider theme={theme}>
            <div className="hidden-dropdown ">
                <FormControl
                    id={id}
                    variant="outlined"
                    color="secondary"
                    style={{ width: width }}
                >
                    <InputLabel
                        id={id}
                        shrink
                        htmlFor={`outlined-${id}-always-notched`}
                        ref={inputLabel}
                    >
                        {label}
                    </InputLabel>
                    <Select
                        id={id}
                        name={id}
                        value={value}
                        onChange={handleChange}
                        label={label}
                        input={
                            <OutlinedInput
                                notched
                                labelWidth={labelWidth}
                                name={id}
                                id={`outlined-${id}-always-notched`}
                            />
                        }
                        variant="outlined"
                        className="text-left"
                        style={{
                            fontSize: "12.5px !important",
                            paddingLeft: "1%",
                        }}
                    >
                        {list.map((element, index) => {
                            return (
                                <MenuItem
                                    value={`${element?.value}`}
                                    key={index}
                                >
                                    {element?.desc}
                                </MenuItem>
                            );
                        })}
                    </Select>
                </FormControl>
            </div>
        </ThemeProvider>
    );
};

export default HiddenDropdown;
