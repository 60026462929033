import { useEffect } from "react";
import Body from "./LoanSummaryVEN/Body";

const LoanSummaryVEN = () => {
    useEffect(() => {
        console.log("LoanSummaryVEN");
    }, []);
    return <Body />;
};

export default LoanSummaryVEN;
