const InvoiceHeaderDetailsCusQuery= `
   mutation(
        $entity:String,
        $ou:Int,
        $service:String,
        $User:String,
        $languageId:Int,
        $customerCode:String,
        $customerName:String,
        $merrorid:String
    ){
        InvoiceHeaderDetailsCus( entity:$entity,
            ou:$ou,
            service:$service,
            User:$User,
            languageId:$languageId,
            customerCode:$customerCode,
            customerName:$customerName,
            merrorid:$merrorid){
            desc
            frequent
            value
            visible
            detail {
                desc
                value
                color
            }
        }
    }
`;

export default InvoiceHeaderDetailsCusQuery;