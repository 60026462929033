const ReplacementSchema = [
    {
        name: "Assignment",
        Label: "Assignment",
        options: {
            filter: true,
            sort: false,
            customHeadLabelRender: () => (
                <span style={{ color: "white" }}>Assignment</span>
            ),
            setCellHeaderProps: () => {
                return { style: { borderRight: "1px solid #D5D5D5" } };
            },
            setCellProps: () => {
                return {
                    style: {
                        borderRight: "1px solid #D5D5D5",
                        textAlign: "center",
                    },
                };
            },
            customBodyRender: (
                value: any,
                tableMeta: any,
                updateValue: any,
            ) => {
                console.log("table data", value,tableMeta.rowData);
                return <b style={{ color: "#0073E6" }} >{value}</b>;
              

            },
        },
    },
    {
        name: "CustomerContract",
        Label: "CustomerContract",
        type: "string",
        options: {
            filter: true,
            sort: false,
            customHeadLabelRender: () => (
                <span style={{ color: "white" }}>Customer Contract</span>
            ),
            setCellHeaderProps: () => {
                return { style: { borderRight: "1px solid #D5D5D5" } };
            },
            setCellProps: () => {
                return {
                    style: {
                        borderRight: "1px solid #D5D5D5",
                        textAlign: "center",
                    },
                };
            },
            customBodyRender: (
                value: any,
                tableMeta: any,
                updateValue: any,
            ) => {
                console.log("table data", value);
                return <b style={{ color: "#5C677D" }}>{value}</b>;
            },
        },
    },
    {
        name: "DateofReplacement",
        Label: "DateofReplacement",
        type: "string",
        options: {
            filter: true,
            sort: false,
            customHeadLabelRender: () => (
                <span style={{ color: "white" }}>Date of Replacement</span>
            ),
            setCellHeaderProps: () => {
                return { style: { borderRight: "1px solid #D5D5D5" } };
            },
            setCellProps: () => {
                return {
                    style: {
                        borderRight: "1px solid #D5D5D5",
                        textAlign: "center",
                    },
                };
            },
            customBodyRender: (
                value: any,
                tableMeta: any,
                updateValue: any,
            ) => {
                console.log("table data", value);
                return <b style={{ color: "#5C677D" }}>{value}</b>;
            },
        },
    },
    {
        name: "Amount",
        Label: "Amount",
        options: {
            filter: true,
            sort: false,
            customHeadLabelRender: () => (
                <span style={{ color: "white" }}> Amount</span>
            ),
            setCellHeaderProps: () => {
                return { style: { borderRight: "1px solid #D5D5D5" } };
            },
            setCellProps: () => {
                return {
                    style: {
                        borderRight: "1px solid #D5D5D5",
                        textAlign: "center",
                    },
                };
            },
            customBodyRender: (
                value: any,
                tableMeta: any,
                updateValue: any,
            ) => {
                console.log("table data", value);
                return <b style={{ color: "#00AE50" }}>{value}</b>;
             
            },
        },
    },
  

];

export default ReplacementSchema;