const CalendarViewDataQuery = `
mutation(
    $entity: String
    $ou: Int
    $service: String
    $User: String
    $languageId: Int
    $suppliercode: String
    $custname: String
    $buildingname: String
    $itemdesc: String
    $inputdate: String
    $callingtab: String
    $callingtype: String
    $sch_week: String
    $sch_month: String
    $merrorid: String
){
    CalendarView(
        entity: $entity
        ou: $ou
        service: $service
        User: $User
        languageId: $languageId
        suppliercode: $suppliercode
        custname: $custname
        buildingname: $buildingname
        itemdesc: $itemdesc
        inputdate: $inputdate
        callingtab: $callingtab
        callingtype: $callingtype
        sch_week: $sch_week
        sch_month: $sch_month
        merrorid: $merrorid
    ){ 
        id
        itemdesc
        date
        typofservice
        customername
        count_service
        buildingname
        contractno
        pono
        sun
        mon
        tue
        wed
        thu
        fri
        sat
        sch_month
        sch_date
        sch_week
        startDate
        endDate
    }
}`;
export default CalendarViewDataQuery;
