import {
    Grid,
    Paper,
    Backdrop,
    CircularProgress,
    makeStyles,
    Theme,
} from "@material-ui/core";
import { useEffect, useState } from "react";
import "Common/Main.scss";
import MuiDatatables from "Common/Mui-DataTables/MuiDatatables";
import {
    invoiceCardHeaderQuery,
    InvoiceDetailsChildTableDataCusQuery,
} from "ReactQuery/Query";
import useGetPosts from "ReactQuery/reactQuery";
import { connect } from "react-redux";
import Icons from "Common/Icons/Icons";
import currencyFormatter from "Common/Utils/currencyFormatter";
import Alert from "Common/Alerts/Alerts";
import { useLocation } from "react-router-dom";
import { storeBreadcrumbs } from "stateManagement/actions/Common/breadcrumbs";

const useStyles = makeStyles((theme: Theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: "#fff",
    },
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
    },
}));

interface InvHeader {
    InvoiceNo: string;
    ContractID: string;
    Buildingadd: {
        Addressdtl: string;
        state: string;
        city: string;
        pin: string;
    };

    status: string;
    PONumber: string;
    InvoiceDate: string;
    DueDate: string;
    TotalAmount: number;
    Balance: number;
    PaymentStatus: string;
}

const Body = (props: any) => {
    const { currentContext, selectedCustomer, currency, setBreadcrumbsData } = props;

    const invoiceData = useLocation<any>();
    const invoiceNo: any = invoiceData?.state?.invoiceSelected;
    const [alert, setAlert] = useState(false);
    const [errr, seterr] = useState({
        code: 0,
        msg: "",
    });
    setBreadcrumbsData([
        {
            value: "Invoices",
            href: "/app/*/Invoices",
        },
        {
            value: "Invoice Details",
            href: "/app/*/InvoiceDet",
        },
    ]);

    const classes = useStyles();

    const [invoiceChildTableData, setInvoiceChildTableData] = useState([]);

    const [invoiceContactheader, setinvoiceContactheader] = useState<
        InvHeader[]
    >([]);
    const {
        data: invoiceContactheaderdata,
        error: invoiceContactheaderError,
        isLoading: invoiceContactheaderLoading,
    } = useGetPosts({
        name: `invoiceCardHeaderQuery_${invoiceNo}`,
        query: invoiceCardHeaderQuery,
        variables: {
            entity: "OW_Cust_portal_InvHdr",
            ou: currentContext?.ouId,
            service: "",
            User: selectedCustomer?.loginUser,
            languageId: currentContext?.langId,
            Status: "",
            customerCode: selectedCustomer?.customerCode,
            customerName: "",
            siteId: "",
            buildName: "",
            InvoiceNo: invoiceNo,
            merrorid: "",
            contractid: "",
        },
    });

    useEffect(() => {
        if (invoiceContactheaderdata?.invoiceContactheadervals) {
            setinvoiceContactheader(
                invoiceContactheaderdata?.invoiceContactheadervals,
            );
        }
    }, [invoiceContactheaderdata]);

    useEffect(() => {
        if (invoiceContactheaderError && !invoiceContactheaderLoading) {
            const data = JSON.parse(JSON.stringify(invoiceContactheaderError));
            const errmsg = data?.response?.errors[0].message;
            seterr({
                code: 400,
                msg: errmsg,
            });
            setAlert(true);
        }
    }, [invoiceContactheaderError, invoiceContactheaderLoading]);

    const handleErrorAlert = (code: number, message: string) => {
        Alert({
            statusCode: code,
            message: message,
            willClose: () => {
                setAlert(false);
                seterr({ code: 0, msg: "" });
            },
        });
    };

    const {
        data: InvoiceDetailsChildTabledata,
        error: InvoiceDetailsChildTabledataError,
        isLoading: InvoiceDetailsChildTabledataLoading,
    } = useGetPosts({
        name: `InvoiceDetailsChildTableDataCusQuery_${invoiceNo}`,
        query: InvoiceDetailsChildTableDataCusQuery,
        variables: {
            entity: "OW_Cust_portal_inv_Contr_Cust",
            ou: currentContext?.ouId,
            service: "",
            User: selectedCustomer?.loginUser,
            languageId: currentContext?.langId,
            Status: "",
            customerCode: selectedCustomer?.customerCode,
            customerName: "",
            siteId: "",
            buildName: "",
            InvoiceNo: invoiceNo,
            merrorid: "",
            contractid: "",
        },
    });

    useEffect(() => {
        if (InvoiceDetailsChildTabledata?.InvoiceChildTableDataCus) {
            setInvoiceChildTableData(
                InvoiceDetailsChildTabledata?.InvoiceChildTableDataCus,
            );

            console.log(InvoiceDetailsChildTabledata?.InvoiceChildTableDataCus);
        }
    }, [InvoiceDetailsChildTabledata]);

    useEffect(() => {
        if (
            InvoiceDetailsChildTabledataError &&
            !InvoiceDetailsChildTabledataLoading
        ) {
            const data = JSON.parse(
                JSON.stringify(InvoiceDetailsChildTabledataError),
            );
            const errmsg = data?.response?.errors[0].message;
            seterr({
                code: 400,
                msg: errmsg,
            });
            setAlert(true);
        }
    }, [
        InvoiceDetailsChildTabledataError,
        InvoiceDetailsChildTabledataLoading,
    ]);

    const InvoiceChildDetailsTableSchema = [
        {
            name: "AssignmnetNumber",
            Label: "AssignmnetNumber",
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => (
                    <span style={{ color: "white" }}>Assignment Number</span>
                ),
                setCellHeaderProps: () => {
                    return { style: { borderRight: "1px solid #D5D5D5" } };
                },
                setCellProps: () => {
                    return {
                        style: {
                            borderRight: "1px solid #D5D5D5",
                            textAlign: "center",
                        },
                    };
                },
                customBodyRender: (
                    value: any,
                    tableMeta: any,
                    updateValue: any,
                ) => {
                    console.log("table data", value, tableMeta.rowData);
                    return <b style={{ color: "#0073E6" }}>{value}</b>;
                },
            },
        },

        {
            name: "CustomerContract",
            Label: "CustomerContract",
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => (
                    <span style={{ color: "white" }}>Customer Contract</span>
                ),
                setCellHeaderProps: () => {
                    return { style: { borderRight: "1px solid #D5D5D5" } };
                },
                setCellProps: () => {
                    return {
                        style: {
                            borderRight: "1px solid #D5D5D5",
                            textAlign: "center",
                        },
                    };
                },
                customBodyRender: (
                    value: any,
                    tableMeta: any,
                    updateValue: any,
                ) => {
                    console.log("table data", value);
                    return <b style={{ color: "#5C677D" }}>{value}</b>;
                },
            },
        },

        {
            name: "BuildingDetails",
            Label: "BuildingDetails",
            type: "string",
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => (
                    <span style={{ color: "white" }}>Building Details</span>
                ),
                setCellHeaderProps: () => {
                    return { style: { borderRight: "1px solid #D5D5D5" } };
                },
                setCellProps: () => {
                    return {
                        style: {
                            borderRight: "1px solid #D5D5D5",
                            textAlign: "center",
                        },
                    };
                },
                customBodyRender: (
                    value: any,
                    tableMeta: any,
                    updateValue: any,
                ) => {
                    console.log("table data", value);
                    return <b style={{ color: "#0073E6" }}>{value}</b>;
                },
            },
        },
        {
            name: "State",
            Label: "State",
            type: "string",
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => (
                    <span style={{ color: "white" }}>State</span>
                ),
                setCellHeaderProps: () => {
                    return { style: { borderRight: "1px solid #D5D5D5" } };
                },
                setCellProps: () => {
                    return {
                        style: {
                            borderRight: "1px solid #D5D5D5",
                            textAlign: "center",
                        },
                    };
                },
                customBodyRender: (
                    value: any,
                    tableMeta: any,
                    updateValue: any,
                ) => {
                    console.log("table data", value);
                    return <b className="grey">{value}</b>;
                },
            },
        },

        {
            name: "StartEndDate",
            Label: "StartEndDate",
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => (
                    <span style={{ color: "white" }}>Start-End Date</span>
                ),
                setCellHeaderProps: () => {
                    return { style: { borderRight: "1px solid #D5D5D5" } };
                },
                setCellProps: () => {
                    return {
                        style: {
                            borderRight: "1px solid #D5D5D5",
                            textAlign: "center",
                        },
                    };
                },
                customBodyRender: (
                    value: any,
                    tableMeta: any,
                    updateValue: any,
                ) => {
                    console.log("table data", value);
                    return <b style={{ color: "#5C677D" }}>{value}</b>;
                },
            },
        },

        {
            name: "ServiceCode",
            Label: "ServiceCode",
            type: "string",
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => (
                    <span style={{ color: "white" }}>Service Type</span>
                ),
                setCellHeaderProps: () => {
                    return { style: { borderRight: "1px solid #D5D5D5" } };
                },
                setCellProps: () => {
                    return {
                        style: {
                            borderRight: "1px solid #D5D5D5",
                            textAlign: "center",
                        },
                    };
                },
                customBodyRender: (
                    value: {
                        name: string;
                        service: string;
                    },
                    tableMeta: any,
                    updateValue: any,
                ) => {
                    console.log("table data", value);
                    return <b className="grey">{value.service}</b>;
                },
            },
        },

        {
            name: "AmountQty",
            Label: "AmountQty",
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => (
                    <span style={{ color: "white" }}>Amount</span>
                ),
                setCellHeaderProps: () => {
                    return { style: { borderRight: "1px solid #D5D5D5" } };
                },
                setCellProps: () => {
                    return {
                        style: {
                            borderRight: "1px solid #D5D5D5",
                            textAlign: "center",
                        },
                    };
                },
                customBodyRender: (
                    value: {
                        amount: any;
                        quantity: any;
                        cost: any;
                    },
                    tableMeta: any,
                    updateValue: any,
                ) => {
                    console.log("table data", value);
                    return (
                        <b
                            style={{
                                color: "#0073E6",
                                display: "flex",
                                justifyContent: "flex-end",
                            }}
                        >
                            {currencyFormatter(value.amount, currency)}
                        </b>
                    );
                },
            },
        },
    ];

    return (
        <>
            {alert ? handleErrorAlert(errr.code, errr.msg) : <></>}

            <Backdrop
                className={classes.backdrop}
                open={
                    invoiceContactheaderLoading ||
                    InvoiceDetailsChildTabledataLoading
                }
            >
                <CircularProgress color="inherit" />
            </Backdrop>

            <Grid container spacing={2} style={{ paddingTop: "2%" }}>
                <Grid item xs={12} md={10}>
                    <Paper elevation={4}>
                        <Grid container className="padding-1-percent">
                            <Grid
                                item
                                xs={5}
                                className="padding-1-percent"
                                style={{
                                    borderRight: "1px dashed #C9CDD6",
                                }}
                            >
                                <Grid container className=" padding-left-1-per">
                                    <Grid item xs={1}>
                                        <Icons Icon={"mailimage"} />
                                    </Grid>

                                    <Grid
                                        item
                                        xs={1}
                                        className=" padding-left-3-per"
                                    >
                                        {invoiceContactheader[0]?.ContractID}
                                    </Grid>
                                </Grid>
                                <Grid
                                    container
                                    className=" padding-left-1-per margin-top-2-per"
                                >
                                    <Grid item xs={1}>
                                        <Icons Icon={"location"} />
                                    </Grid>

                                    <Grid
                                        item
                                        xs={11}
                                        className="text-left padding-left-2-per"
                                    >
                                        {
                                            invoiceContactheader[0]?.Buildingadd
                                                ?.Addressdtl
                                        }{" "}
                                        |{" "}
                                        {
                                            invoiceContactheader[0]?.Buildingadd
                                                ?.city
                                        }{" "}
                                        |{" "}
                                        {
                                            invoiceContactheader[0]?.Buildingadd
                                                ?.state
                                        }{" "}
                                        |{" "}
                                        {
                                            invoiceContactheader[0]?.Buildingadd
                                                ?.pin
                                        }
                                    </Grid>
                                </Grid>
                                <Grid
                                    container
                                    className="padding-left-3-per margin-top-2-per"
                                >
                                    <Grid
                                        item
                                        xs={3}
                                        style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            // alignItems: "center",
                                            justifyContent: "center",
                                            border: `${
                                                invoiceContactheader[0]?.status.toLowerCase() ===
                                                "paid"
                                                    ? "1px solid #00DA64"
                                                    : "1px solid #DD9200"
                                            }`,
                                            borderRadius: "3px",
                                            background: `${
                                                invoiceContactheader[0]?.status.toLowerCase() ===
                                                "paid"
                                                    ? "#EDFDF2"
                                                    : "#FCF5E9"
                                            }`,
                                            alignItems: "center",
                                        }}
                                    >
                                        {invoiceContactheader[0]?.status}
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid item xs={7} className="padding-1-percent">
                                <Grid container>
                                    <Grid item xs={3}>
                                        <Grid
                                            container
                                            className="text-left padding-left-2-per"
                                        >
                                            <Grid
                                                item
                                                xs={12}
                                                className="lightgrey"
                                            >
                                                PO Number
                                            </Grid>
                                            <Grid
                                                item
                                                xs={12}
                                                className="grey1"
                                            >
                                                {
                                                    invoiceContactheader[0]
                                                        ?.PONumber
                                                }
                                            </Grid>
                                        </Grid>

                                        <Grid
                                            container
                                            className="text-left margin-top-4-per  padding-left-2-per"
                                        >
                                            <Grid
                                                item
                                                xs={12}
                                                className="lightgrey"
                                            >
                                                Balance
                                            </Grid>
                                            <Grid
                                                item
                                                xs={12}
                                                className="grey1"
                                            >
                                                {currencyFormatter(
                                                    invoiceContactheader[0]
                                                        ?.Balance,
                                                    "USD",
                                                )}
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={3}>
                                        <Grid
                                            container
                                            className="text-left padding-left-2-per"
                                        >
                                            <Grid
                                                item
                                                xs={12}
                                                className="lightgrey"
                                            >
                                                Invoice Date
                                            </Grid>
                                            <Grid
                                                item
                                                xs={12}
                                                className="grey1"
                                            >
                                                {
                                                    invoiceContactheader[0]
                                                        ?.InvoiceDate
                                                }
                                            </Grid>
                                        </Grid>
                                        <Grid
                                            container
                                            className="text-left margin-top-4-per  padding-left-2-per"
                                        >
                                            <Grid
                                                item
                                                xs={12}
                                                className="lightgrey"
                                            >
                                                Payment Status
                                            </Grid>
                                            <Grid
                                                item
                                                xs={12}
                                                className="grey1"
                                            >
                                                {
                                                    invoiceContactheader[0]
                                                        ?.PaymentStatus
                                                }
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={3} className="text-left ">
                                        <Grid container>
                                            <Grid
                                                item
                                                xs={12}
                                                className="lightgrey"
                                            >
                                                Due Date
                                            </Grid>
                                            <Grid
                                                item
                                                xs={12}
                                                className="grey1"
                                            >
                                                {
                                                    invoiceContactheader[0]
                                                        ?.DueDate
                                                }
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={3} className="text-left ">
                                        <Grid container>
                                            <Grid
                                                item
                                                xs={12}
                                                className="lightgrey"
                                            >
                                                Total Amount
                                            </Grid>
                                            <Grid
                                                item
                                                xs={12}
                                                className="grey1"
                                            >
                                                {currencyFormatter(
                                                    invoiceContactheader[0]
                                                        ?.TotalAmount,
                                                    "USD",
                                                )}
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>

                <Grid item xs={12}>
                    <MuiDatatables
                        title={""}
                        hideAddButton={true}
                        header={InvoiceChildDetailsTableSchema}
                        detail={[
                            invoiceChildTableData,
                            setInvoiceChildTableData,
                        ]}
                        initRow={false}
                    />
                </Grid>
            </Grid>
        </>
    );
};

const mapStateToProps = (state: any) => {
    return {
        currentContext: state.context?.userDefaults,
        selectedCustomer: state.selectedCustomer,
        currency: state.currency,
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        setBreadcrumbsData: (data: any) => {
            dispatch(storeBreadcrumbs(data));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Body);
