const DetAddr = `
mutation(    
    $entity: String
    $ou: Int
    $service: String
    $User: String
    $languageId: Int
    $merrorid: String
    $vendorname: String,
    $vendorcode:String,
    ){

    DetAddr(   
        entity: $entity
        ou: $ou
        service: $service
        User: $User
        languageId: $languageId
       
        merrorid:$merrorid
        vendorname:$vendorname
        vendorcode:$vendorcode) {
        Building{
            name
            id
        }
        Address{
            Area
            City
            State
            Pincode
        }
        Body{
            AdvertisingFee
            NonEntityFee
            TotalDeductionAmount
        }
        

    }
}`;

export default DetAddr;
