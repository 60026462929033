const CompanyDetailsDataCusQuery = `
    mutation(
        $entity: String
        $ou: Int
        $service: String
        $user: String
        $langId: Int
        $customerCode: String
        $customerName: String
        $contactId: String
        $merrorid: String
    ){
        CompanyCardDataCus(
            entity: $entity
            ou: $ou
            service: $service
            user: $user
            langId: $langId
            customerCode: $customerCode
            customerName: $customerName
            contactId: $contactId
            merrorid: $merrorid
        ){
            BillingInfo{
                area
                address
                pincode
                }
            ContactPerson{
                name
                email
                businessnumber
            }
            logourl
            custname
        }
    }
`;

export default CompanyDetailsDataCusQuery;