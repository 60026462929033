const TicketPropertyCUS = `
    query Query($propertyCode : String){
        TicketPropertyComboCUS(propertyCode : $propertyCode){
            desc
            value
        }
    }
`;

export default TicketPropertyCUS;
