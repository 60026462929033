import { STORE_CURRENCY } from "../../actionTypes";
const currency = (data: any) => {
    return {
        type: STORE_CURRENCY,
        payload: data,
    };
};
/**
 * Common File redux
 * @param code
 */
export function storeCurrency<T>(data: any) {
    return (dispatch: any) => {
        dispatch(currency(data));
    };
}