const CreateTicketCUS = `
query Query (

  $customerCode: String
            $buildingId: String
            $ticketType: String
            $contactPerson: String
            $phoneNumber:String
            $subject: String
            $description: String
            $pipeline: String
            $vendorName: String
){
 CreateTicketCUS(
  customerCode:$customerCode,
  buildingId:$buildingId,
  ticketType:$ticketType,
  contactPerson:$contactPerson,
  phoneNumber:$phoneNumber,
  subject:$subject,
  description:$description,
  pipeline:$pipeline,
  vendorName:$vendorName
    ) {
    msg
    }
  }
  `;

export default CreateTicketCUS;
