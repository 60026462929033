import { Divider, Grid, Paper } from "@material-ui/core";
import Button from "Common/Button/Button";
import React, { useState } from "react";
import TextFields from "Common/TextField/TextField";
import { storeSupplier } from "stateManagement/actions/HomePage/storeHiddenSupplier";
import { connect } from "react-redux";
import Dropdown from "Common/Dropdown/Dropdown";
import Search from "./Images/Search.svg";

interface IndexProps {
    currentContext: any;
    setSupplier: any;
    supplierCode: string;
    contact: any;
}
interface EditDet {
    BuildingContractNo: string;
    Building: string;
    City: string;
    PinCode: string;
    PrimaryContact: string;
    AccountManager: string;
    LastInspection: string;
    InspectionFrequency: string;
    SquareFootage: string;
    MonthlyBilling: string;
}

const ContactCard = (props: any) => {
    const {
        currentContext,
        supplierCode,
        name,
        role,
        mail,
        phoneNo,
        handlePortalChange,
        Username,
    } = props;

    return (
        <>
            <Grid item xs={12}>
                <Paper elevation={4}>
                    <Grid
                        container
                        spacing={1}
                        className="padding-2-percent"
                        justifyContent="flex-start"
                        direction="column"
                    >
                        <Grid item xs={12}>
                            <Grid container justifyContent="space-between">
                                <Grid
                                    item
                                    className="blue font-smaller font-boldest "
                                >
                                    {name}
                                </Grid>
                                <Grid
                                    item
                                    className="grey1 font-smaller font-boldest  "
                                >
                                    {role}
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container justifyContent="space-between">
                                <Grid
                                    item
                                    className="lightgrey font-smaller font-bold"
                                >
                                    {mail} | {phoneNo}
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container justifyContent="flex-end">
                                <Grid item>
                                    {" "}
                                    <img
                                        src={Search}
                                        alt="search icon"
                                        className="pointer"
                                        onClick={() => {
                                            localStorage.removeItem(
                                                "RedirectedState",
                                            );
                                            handlePortalChange(name, Username);
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
        </>
    );
};
const mapStateToProps = (state: any) => {
    return {
        supplierCode: state.hiddenSupplier?.supplierCodeOut,
        storeCompanies: state.storeCompanies,
        storeOus: state.storeOus,
        currentContext: state.context?.userDefaults,
        userName: state.context.name,
    };
};
const mapDispatchToProps = (dispatch: any) => {
    return {
        setSupplier: (data: any) => {
            dispatch(storeSupplier(data));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ContactCard);
