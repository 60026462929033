const TicketHeaderCUS = `
    query Query($companyId : String, $pipelineId: String){
        TicketHeaderCUS(companyId : $companyId, pipelineId : $pipelineId ){
            desc
            frequent
            value
            visible
            detail {
                desc
                value
                color
            }
        }
    }
`;

export default TicketHeaderCUS;
