const TicketBuildingDataCUS = `
query Query($companyId : String, $pipelineId : String,$ou: Int
    $service: String
    $user: String
    $langId: Int
    $role: String
    $customerCode: String
    $buildingId : String
    ) {
        TicketBuildingDataCUS(companyId : $companyId, pipelineId: $pipelineId,ou: $ou
        service: $service
        user: $user
        langId: $langId
        role: $role
        customerCode: $customerCode, buildingId : $buildingId) {
        IssueID
        BuildingName{
            name
            id
        }
        IssueType
        CreatedOn
        FollowUpDate
        Owner
        Subject
        priority
        content
        Status{    
    desc
    color
        }
        Source
Stage{    
    desc
    color
        }
        
     }
   
  }
  `;

export default TicketBuildingDataCUS;
