import { STORE_HIDDEN_SUPPLIER } from "../actionTypes";
const initialState = {
    supplier: {},
};
const storeSupplier = (prevState = initialState.supplier, action: any) => {
    switch (action.type) {
        case STORE_HIDDEN_SUPPLIER:
            return action.payload?.loginSupplier;
        default:
            return prevState;
    }
};
export default storeSupplier;