const BuildingComboASQuery = `
mutation (
    $entity:String,
    $ou:Int,
    $service:String,
    $User:String,
    $languageId:Int,
    $suppliercode:String,
    $callingfrom:String,
    $merrorid:String
        )
            {
                BuildingComboAS
                (
                    entity:$entity,
                    ou:$ou,
                    service:$service,
                    User:$User,
                    languageId:$languageId,
                    suppliercode:$suppliercode,
                    callingfrom:$callingfrom,
                    merrorid:$merrorid
                ) 
                    {
                        name
                        recordId
                    }
}`;

export default BuildingComboASQuery;
