const pendingApprovalHeaderQuery = `
mutation
(
    $entity:String
    $ou:Int
    $service:String
    $User:String
    $languageId:Int
    $vendorName:String
    $vendorCode:String
    $merrorid:String

)
    {
        PendingApprovalHeaderQueryVEN
            (
                entity:$entity
                ou:$ou
                service:$service
                User: $User
                languageId:$languageId
                vendorName: $vendorName
                vendorCode: $vendorCode
                merrorid: $merrorid
            )
                {
                    desc
                    frequent
                    value
                    visible
                    detail 
                        {
                            desc
                            code
                            value
                            color
                        }
                }
    }
`;

export default pendingApprovalHeaderQuery;
