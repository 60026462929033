import { Grid, Paper, Theme, Tooltip, withStyles } from "@material-ui/core";
import Alert from "Common/Alerts/Alerts";
import BackdropLoading from "Common/Backdrop/Backdrop";
import Icons from "Common/Icons/Icons";
import MuiDatatables from "Common/Mui-DataTables/MuiDatatables";
import currencyFormatter from "Common/Utils/currencyFormatter";
import dateFormatter from "Common/Utils/dateFormatter";
import React, { useEffect, useState } from "react";
import { Fragment } from "react";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";
import { LoanBalanceDetailsQuery } from "ReactQuery/Query";
import useGetPosts from "ReactQuery/reactQuery";
import { storeBreadcrumbs } from "stateManagement/actions/Common/breadcrumbs";

const LoanDetails = (props: any) => {
    const { currentContext, selectedVendor, currency,setBreadcrumbsData } = props;
    const LoanRedirect = useLocation();
    const LoanRedirectDetails: any = (LoanRedirect as any)?.state;
    const [alert, setAlert] = useState(false);
    const [errr, seterr] = useState({
        code: 0,
        msg: "",
    });
    setBreadcrumbsData([
       
        {
            value: "Loan Summary",
            href: "app/*/LoansVEN",
        },
        {
            value: "Loan Detail Summary",
            href: "app/*/LoansVEN",
        },
    ]);
    const handleErrorAlert = (code: number, message: string) => {
        Alert({
            statusCode: code,
            message: message,
            willClose: () => {
                setAlert(false);
                seterr({ code: 0, msg: "" });
            },
        });
    };
    const [LoanDetails, setLoanDetails] = useState<any>();
    
    // useEffect(() => {
    //     setLoanDetails([]);
    // }, [LoanDetails]);
    const {
        data: LoanDetailsData,
        error: LoanDetailsDataError,
        isLoading: LoanDetailsDataLoading,
    } = useGetPosts({
        name: `LoanBalanceDetailsQuery-DBLD-${LoanRedirectDetails?.loanID}_${selectedVendor?.supplierCode}`,
        query: LoanBalanceDetailsQuery,
        variables: {
            entity: "ow_getcurr_loan_bal_dtl_entity",
            ou: currentContext?.ouId,
            service: "",
            User: selectedVendor?.loginUser,
            languageId: currentContext?.langId,
            supplierCode: selectedVendor?.supplierCode,
            callingfrom: "DBLD",
            status: "",
            loanNo: LoanRedirectDetails?.loanID,
        },
        skip: !LoanRedirectDetails?.loanID,
    });

    useEffect(() => {
        if (LoanDetailsData?.loanBalanceDetails) {
            console.log("LoanDetailsData", LoanDetailsData?.loanBalanceDetails);
            setLoanDetails(LoanDetailsData?.loanBalanceDetails);
        }
    }, [LoanDetailsData]);

    useEffect(() => {
        if (LoanDetailsDataError && !LoanDetailsDataLoading) {
            const data = JSON.parse(JSON.stringify(LoanDetailsDataError));
            const errmsg = data?.response?.errors[0].message;
            seterr({
                code: 400,
                msg: errmsg,
            });
            setAlert(true);
        }
    }, [LoanDetailsDataError, LoanDetailsDataLoading]);

    const LoanDetailsSchema = [
        {
            name: "scheduleNo",
            Label: "Schedule No.",
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => (
                    <span style={{ color: "white" }}>Schedule No.</span>
                ),
                setCellHeaderProps: () => {
                    return { style: { borderRight: "1px solid #D5D5D5" } };
                },
                setCellProps: () => {
                    return {
                        style: {
                            borderRight: "1px solid #D5D5D5",
                            textAlign: "center",
                        },
                    };
                },
                customBodyRender: (
                    value: any,
                    tableMeta: any,
                    updateValue: any,
                ) => {
                    return <b className="blue font-bolder">{value}</b>;
                },
            },
        },
        {
            name: "PurchasedDate",
            Label: "PurchasedDate",
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => (
                    <span style={{ color: "white" }}>Purchase Date</span>
                ),
                setCellHeaderProps: () => {
                    return { style: { borderRight: "1px solid #D5D5D5" } };
                },
                setCellProps: () => {
                    return {
                        style: {
                            borderRight: "1px solid #D5D5D5",
                            textAlign: "center",
                        },
                    };
                },
                customBodyRender: (
                    value: any,
                    tableMeta: any,
                    updateValue: any,
                ) => {
                    if (value === null) return <b className="grey1">---</b>;
                    else
                        return (
                            <b className="grey1">
                                {dateFormatter("sqlDate", value)}
                            </b>
                        );
                },
            },
        },
        {
            name: "EffectiveDate",
            Label: "EffectiveDate",
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => (
                    <span style={{ color: "white" }}>Effective Date</span>
                ),
                setCellHeaderProps: () => {
                    return { style: { borderRight: "1px solid #D5D5D5" } };
                },
                setCellProps: () => {
                    return {
                        style: {
                            borderRight: "1px solid #D5D5D5",
                            textAlign: "center",
                        },
                    };
                },
                customBodyRender: (
                    value: any,
                    tableMeta: any,
                    updateValue: any,
                ) => {
                    if (value === null) return <b className="grey1">---</b>;
                    else
                        return (
                            <b className="grey1">
                                {dateFormatter("sqlDate", value)}
                            </b>
                        );
                },
            },
        },
        {
            name: "PaymentNo",
            Label: "PaymentNo",
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => (
                    <span style={{ color: "white" }}>Payment No.</span>
                ),
                setCellHeaderProps: () => {
                    return { style: { borderRight: "1px solid #D5D5D5" } };
                },
                setCellProps: () => {
                    return {
                        style: {
                            borderRight: "1px solid #D5D5D5",
                            textAlign: "center",
                        },
                    };
                },
                customBodyRender: (
                    value: any,
                    tableMeta: any,
                    updateValue: any,
                ) => {
                    return <b className="grey1">{value}</b>;
                },
            },
        },
        {
            name: "TransAmt",
            Label: "TransAmt",
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => (
                    <span style={{ color: "white" }}>Transaction Amount</span>
                ),
                setCellHeaderProps: () => {
                    return { style: { borderRight: "1px solid #D5D5D5" } };
                },
                setCellProps: () => {
                    return {
                        style: {
                            borderRight: "1px solid #D5D5D5",
                            textAlign: "right",
                        },
                    };
                },
                customBodyRender: (
                    value: any,
                    tableMeta: any,
                    updateValue: any,
                ) => {
                    if (value === null) return <b className="grey1">---</b>;
                    else return <b className="grey1"> {currencyFormatter(value, currency)}</b>;
                },
            },
        },
        {
            name: "principleAmt",
            Label: "principleAmt",
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => (
                    <span style={{ color: "white" }}>Principal Amount</span>
                ),
                setCellHeaderProps: () => {
                    return { style: { borderRight: "1px solid #D5D5D5" } };
                },
                setCellProps: () => {
                    return {
                        style: {
                            borderRight: "1px solid #D5D5D5",
                            textAlign: "right",
                        },
                    };
                },
                customBodyRender: (
                    value: any,
                    tableMeta: any,
                    updateValue: any,
                ) => {
                    return (
                        <b className="green">
                            {currencyFormatter(value, currency)}
                        </b>
                    );
                },
            },
        },
       
      
      
        {
            name: "interestAmount",
            Label: "interestAmount",
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => (
                    <span style={{ color: "white" }}>Interest Amount</span>
                ),
                setCellHeaderProps: () => {
                    return { style: { borderRight: "1px solid #D5D5D5" } };
                },
                setCellProps: () => {
                    return {
                        style: {
                            borderRight: "1px solid #D5D5D5",
                            textAlign: "center",
                        },
                    };
                },
                customBodyRender: (
                    value: any,
                    tableMeta: any,
                    updateValue: any,
                ) => {
                    return (
                        <b className="grey1">
                            {currencyFormatter(value, currency)}
                        </b>
                    );
                },
            },
        },
        {
            name: "balance",
            Label: "balance",
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => (
                    <span style={{ color: "white" }}>Balance</span>
                ),
                setCellHeaderProps: () => {
                    return { style: { borderRight: "1px solid #D5D5D5" } };
                },
                setCellProps: () => {
                    return {
                        style: {
                            borderRight: "1px solid #D5D5D5",
                            textAlign: "right",
                        },
                    };
                },
                customBodyRender: (
                    value: any,
                    tableMeta: any,
                    updateValue: any,
                ) => {
                    return (
                        <b className="red">
                            {currencyFormatter(value, currency)}
                        </b>
                    );
                },
            },
        },
        {
            name: "dueDate",
            Label: "dueDate",
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => (
                    <span style={{ color: "white" }}>Payment Due Date</span>
                ),
                setCellHeaderProps: () => {
                    return { style: { borderRight: "1px solid #D5D5D5" } };
                },
                setCellProps: () => {
                    return {
                        style: {
                            borderRight: "1px solid #D5D5D5",
                            textAlign: "center",
                        },
                    };
                },
                customBodyRender: (
                    value: any,
                    tableMeta: any,
                    updateValue: any,
                ) => {
                    return (
                        <b className="grey1">
                            {dateFormatter("sqlDate", value)}
                        </b>
                    );
                },
            },
        },
        // {
        //     name: "paidStatus",
        //     Label: "paidStatus",
        //     options: {
        //         filter: true,
        //         sort: false,
        //         customHeadLabelRender: () => (
        //             <span style={{ color: "white" }}>Paid</span>
        //         ),
        //         setCellHeaderProps: () => {
        //             return { style: { borderRight: "1px solid #D5D5D5" } };
        //         },
        //         setCellProps: () => {
        //             return {
        //                 style: {
        //                     borderRight: "1px solid #D5D5D5",
        //                     textAlign: "center",
        //                 },
        //             };
        //         },
        //         customBodyRender: (
        //             value: any,
        //             tableMeta: any,
        //             updateValue: any,
        //         ) => {
        //             if (value === "Yes")
        //                 return <b className="green">{value}</b>;
        //             else return <b className="red">{value}</b>;
        //         },
        //     },
        // },
      
      
       
    ];
    const LightTooltip = withStyles((theme: Theme) => ({
        tooltip: {
          backgroundColor: theme.palette.common.white,
          color: 'rgba(0, 0, 0, 0.87)',
          boxShadow: theme.shadows[1],
          padding: theme.spacing(2),
          fontSize: 12,
          maxWidth: 400,
          
        },
       
      }))(Tooltip);
  
    return (
        <>
            {alert ? handleErrorAlert(errr.code, errr.msg) : <></>}
        <Grid container spacing={2}>
            <BackdropLoading openStates={LoanDetailsDataLoading} />
            <Grid item xs={12} style={{ paddingTop: "2%", textAlign: "left" }}>
                <Paper elevation={1}>
                    <Grid container>
                        <Grid
                            item
                            xs={2}
                            style={{
                                margin: "0%",
                                padding: "2%",
                                borderRight: "1px dashed #C9CDD6",
                            }}
                        >
                            <Grid container spacing={1}>
                                <Grid item xs={12}>
                                    <Grid
                                        container
                                        justifyContent="space-between"
                                    >
                                        <Grid
                                            item
                                            className="grey2 font-boldest"
                                            xs={12}
                                        >
                                            <Grid container>
                                                <Grid item xs={2}>
                                                    <Icons Icon="contractDetail" />
                                                </Grid>
                                                <Grid
                                                    item
                                                    style={{
                                                        paddingLeft: "1%",
                                                    }}
                                                    xs={8}
                                                    className="grey1 font-boldest"
                                                >
                                                    {
                                                        LoanRedirectDetails?.loanID
                                                    }
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid
                                    item
                                    xs={5}
                                    style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        border: `${
                                            "Active" === "Active"
                                                ? "1px solid #00DA64"
                                                : "1px solid #DD9200"
                                        }`,
                                        borderRadius: "3px",
                                        background: `${
                                            "Active" === "Active"
                                                ? "#EDFDF2"
                                                : "#FCF5E9"
                                        }`,
                                        margin: "4px 0 0 4px",
                                    }}
                                >
                                    {LoanRedirectDetails?.status}
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={6} style={{ padding: "2% 2% 2% 3.5%" }}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={6}>
                                            <Grid container>
                                                <Grid
                                                    item
                                                    xs={6}
                                                    className="grey"
                                                >
                                                    Total Amount
                                                </Grid>
                                                <Grid
                                                    item
                                                    xs={6}
                                                    className="darkblue font-boldest font-bigger"
                                                >
                                                    {currencyFormatter(
                                                        LoanRedirectDetails?.totalAmount,
                                                        currency,
                                                    )}
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Grid container>
                                                <Grid
                                                    item
                                                    xs={6}
                                                    className="grey"
                                                >
                                                    Balance Amount
                                                </Grid>
                                                <Grid
                                                    item
                                                    xs={6}
                                                    className="red font-bigger"
                                                >
                                                    {currencyFormatter(
                                                        LoanRedirectDetails?.balanceAmount,
                                                        currency,
                                                    )}
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Grid container>
                                                <Grid
                                                    item
                                                    xs={6}
                                                    className="grey"
                                                >
                                                    Paid Amount
                                                </Grid>
                                                <Grid
                                                    item
                                                    xs={6}
                                                    className="green font-bigger"
                                                >
                                                    {currencyFormatter(
                                                        LoanRedirectDetails?.paidAmount,
                                                        currency,
                                                    )}
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Grid container>
                                                <Grid
                                                    item
                                                    xs={6}
                                                    className="grey"
                                                >
                                                    No. Of Schedules
                                                </Grid>
                                                <Grid
                                                    item
                                                    xs={6}
                                                    className="darkblue font-bigger"
                                                >
                                                    {
                                                        LoanRedirectDetails?.noOfSchedule
                                                    }
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid
                            item
                            xs
                            style={{
                                margin: "0%",
                                padding: "2%",
                                borderLeft: "1px dashed #C9CDD6",
                            }}
                        >
                            <Grid container spacing={1}>

                                <Grid item xs={12}>
                                    {/* <Grid
                                        container
                                        justifyContent="space-between"
                                    > */}
                                        {/* <Grid
                                            item
                                            className="grey2 font-boldest"
                                            xs={12}
                                        > */}
                                            <Grid container>
                                                
                                                <Grid
                                                    item
                                                    style={{
                                                        paddingLeft: "1%",
                                                    }}
                                                    xs={12}
                                                    className="grey font-bigger"
                                                >
                                                    
                                                     Loan Description 
                                                    
                                                </Grid>
                                            </Grid>
                                        {/* </Grid> */}
                                    {/* </Grid> */}
                                </Grid>
                                <LightTooltip title={LoanDetails?.[0]?.loandesc ?? ""}>
                                <Grid
                                    item
                                    xs={12}
                                    style={{
                                        display: "flex",
                                        justifyContent: "flex-start",
                                        // border: `${
                                        //     "Active" === "Active"
                                        //         ? "1px solid #00DA64"
                                        //         : "1px solid #DD9200"
                                        // }`,
                                        // borderRadius: "3px",
                                        // background: `${
                                        //     "Active" === "Active"
                                        //         ? "#EDFDF2"
                                        //         : "#FCF5E9"
                                        // }`,
                                        margin: "4px 0 0 4px",
                                    }}
                                >
                                   {/* {LoanDetails?.[0]?.loandesc ?? ""}  */}
                                   {
                                LoanDetails?.[0]?.loandesc
                                    ? 
                                          `${LoanDetails?.[0]?.loandesc.slice(0, 90)}${
                                              LoanDetails?.[0]?.loandesc.length > 90 ? "..." : ""
                                          }`
                                    : "" // If 'desc' is undefined or empty, render nothing
                            }
                            
                                </Grid>
                                </LightTooltip>
                            </Grid>
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
            <Grid item xs={12}>
                <MuiDatatables
                    header={LoanDetailsSchema}
                    detail={[LoanDetails, setLoanDetails]}
                />
            </Grid>
        </Grid>
        </>
    );
};
const mapStateToProps = (state: any) => {
    return {
        currentContext: state.context?.userDefaults,
        userName: state.context?.name,
        selectedVendor: state.selectedVendor,
        currency: state.currency,
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        setBreadcrumbsData: (data: any) => {
            dispatch(storeBreadcrumbs(data));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(LoanDetails);
