import { Breadcrumbs, Link } from "@material-ui/core";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory, useRouteMatch } from "react-router-dom";

function handleClick(event: React.MouseEvent<HTMLDivElement, MouseEvent>) {
    event.preventDefault();
    console.info("You clicked a breadcrumb.");
}
interface breadCrumbProps {
    breadcrumb: { value: string; href: string }[];
    portalKey: boolean;
    adminKey: boolean;
}
function BreadCrumbsComp(props: breadCrumbProps) {
    const { breadcrumb, portalKey, adminKey } = props;
    const [breadcrumbsValue, setBreadcrumbsValue] = useState<
        { value: string; href: string }[]
    >([]);
    const { path, url } = useRouteMatch();
    const history = useHistory();
    console.log(path);
    useEffect(() => {
        if (breadcrumb) setBreadcrumbsValue(breadcrumb);
    }, [breadcrumb]);

    return (
        <div role="presentation" onClick={handleClick}>
            {breadcrumbsValue.length ? (
                <>
                    <Breadcrumbs aria-label="breadcrumb">
                        {adminKey && (
                            <Link
                                className="blue font-smaller font-bolder pointer"
                                href={"/app/*/Admin"}
                                onClick={() => {
                                    history.push("/app/*/Admin");
                                }}
                            >
                                Admin
                            </Link>
                        )}

                        <Link className="blue font-smaller font-bolder pointer">
                            {portalKey ? "Provider Portal" : "Customer Portal"}
                        </Link>
                        <Link
                            className="blue font-smaller font-bolder pointer"
                            href={"/app/*/Dashboard"}
                            onClick={() => {
                                history.push("/app/*/Dashboard");
                            }}
                        >
                            Dashboard
                        </Link>
                        {breadcrumbsValue?.map(
                            (element: any, index: number) => {
                                return (
                                    <Breadcrumbs aria-label="breadcrumb">
                                        {index + 1 ===
                                        breadcrumbsValue.length ? (
                                            <span
                                                className="font-smaller font-bolder grey2"
                                                style={{ cursor: "default" }}
                                            >
                                                {element.value}
                                            </span>
                                        ) : (
                                            <Link
                                                className="blue font-smaller font-bolder pointer"
                                                href={element.href}
                                                onClick={() => {
                                                    history.push(element.href,{data:element?.data ?? ""});
                                                }}
                                            >
                                                {element.value}
                                            </Link>
                                        )}
                                    </Breadcrumbs>
                                );
                            },
                        )}
                    </Breadcrumbs>
                </>
            ) : (
                <Breadcrumbs aria-label="breadcrumb">
                    {adminKey && (
                        <Link
                            className="blue font-smaller font-bolder pointer"
                            href={"/app/*/Admin"}
                            onClick={() => {
                                history.push("/app/*/Admin");
                            }}
                        >
                            Admin
                        </Link>
                    )}
                    <Link className="blue font-smaller font-bolder pointer">
                        {portalKey ? "Provider Portal" : "Customer Portal"}
                    </Link>
                    <span
                        className="font-smaller font-bolder grey2"
                        style={{ cursor: "default" }}
                    >
                        Dashboard
                    </span>
                </Breadcrumbs>
            )}
        </div>
    );
}

const mapStateToProps = (state: any) => {
    return {
        breadcrumb: state?.breadcrumb,
        portalKey: state.portalKey,
        adminKey: state.adminKey,
    };
};

export default connect(mapStateToProps)(BreadCrumbsComp);
