import { STORE_SESSION_ID } from "../../actionTypes";

const storeSession = (id: any) => {
  return {
    type: STORE_SESSION_ID,
    payload: id,
  };
};

/**
 * Common File redux
 * @param code
 */
export function storeSessionId<T>(id: any) {
  return (dispatch: any)  => {
    dispatch(storeSession(id));
  };
};
