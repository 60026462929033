import { STORE_OU_LIST } from "../../actionTypes";

const initialState = null

const storeOuList = (
  prevState = initialState,
  action: any
) => {
  switch (action.type) {
    case STORE_OU_LIST:
      return action.payload;

    default:
      return prevState;
  }
};

export default storeOuList;
