import { Divider, Grid, Paper } from "@material-ui/core";
import Icons from "Common/Icons/Icons";
import dateFormatter from "Common/Utils/dateFormatter";

const CalendarDataCard = (props: any) => {
    const { clickedData } = props;
    console.log(`CalendarDataCard`, clickedData);
    return (
        <Grid
            container
            spacing={2}
            // justifyContent="flex-start"
            alignItems="center"
            style={{ maxWidth: "550px", paddingRight: "5%" }}
        >
            {clickedData?.map((element: any) => {
                return (
                    <Grid item xs={12}>
                        <Paper
                            elevation={4}
                            square
                            style={{
                                borderLeft: "3px solid #68AFFF",
                                // maxWidth: "50%",
                            }}
                        >
                            <Grid
                                container
                                spacing={1}
                                alignItems="center"
                                justifyContent="flex-start"
                                style={{ padding: "2%" }}
                            >
                                <Grid
                                    item
                                    xs={6}
                                    className="grey1 font-bolder font-big"
                                >
                                    {element.itemdesc}
                                </Grid>
                                <Grid
                                    item
                                    xs={6}
                                    className="grey1 text-right font-big"
                                >
                                    {dateFormatter(
                                        "sqlDate",
                                        new Date(element.date),
                                    )}
                                </Grid>
                                <Grid item xs={3} className="grey1">
                                    {element?.typofservice}
                                </Grid>
                                <Grid item xs={9} />
                                <Grid item xs={2} className="grey2">
                                    {element?.customername}
                                </Grid>
                                <Grid item xs={1}>
                                    |
                                </Grid>
                                <Grid item xs={9} className="grey2">
                                    {element?.buildingname}
                                </Grid>
                                <Grid item xs={6}>
                                    <Grid
                                        container
                                        alignItems="center"
                                        justifyContent="flex-start"
                                    >
                                        <Grid item xs={1}>
                                            <Icons Icon="receipts" />
                                        </Grid>
                                        <Grid item xs={5}>
                                            {element?.contractno}
                                        </Grid>
                                        <Grid item xs={1}>
                                            <Icons Icon="setting" />
                                        </Grid>
                                        <Grid item xs={5}>
                                            {element?.contractno}
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={6}>
                                    <Grid
                                        container
                                        alignItems="center"
                                        justifyContent="flex-end"
                                    >
                                        <Grid
                                            item
                                            xs={1}
                                            className={`${
                                                element?.sun === 0
                                                    ? "grey3"
                                                    : "green"
                                            } font-bolder`}
                                        >
                                            S
                                        </Grid>
                                        <Grid
                                            item
                                            xs={1}
                                            className={`${
                                                element?.mon === 0
                                                    ? "grey3"
                                                    : "green"
                                            } font-bolder`}
                                        >
                                            M
                                        </Grid>
                                        <Grid
                                            item
                                            xs={1}
                                            className={`${
                                                element?.tue === 0
                                                    ? "grey3"
                                                    : "green"
                                            } font-bolder`}
                                        >
                                            T
                                        </Grid>
                                        <Grid
                                            item
                                            xs={1}
                                            className={`${
                                                element?.wed === 0
                                                    ? "grey3"
                                                    : "green"
                                            } font-bolder`}
                                        >
                                            W
                                        </Grid>
                                        <Grid
                                            item
                                            xs={1}
                                            className={`${
                                                element?.thu === 0
                                                    ? "grey3"
                                                    : "green"
                                            } font-bolder`}
                                        >
                                            T
                                        </Grid>
                                        <Grid
                                            item
                                            xs={1}
                                            className={`${
                                                element?.fri === 0
                                                    ? "grey3"
                                                    : "green"
                                            } font-bolder`}
                                        >
                                            F
                                        </Grid>
                                        <Grid
                                            item
                                            xs={1}
                                            className={`${
                                                element?.sat === 0
                                                    ? "grey3"
                                                    : "green"
                                            } font-bolder`}
                                        >
                                            S
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                );
            })}
        </Grid>
    );
};

export default CalendarDataCard;
