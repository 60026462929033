import { combineReducers } from "redux";
import storeSwitchContext from "./SwitchContext/switchContext";
import storeCompanyList from "./Common/companyList";
import storeOuList from "./Common/ouList";
import storeDashData from "./Common/dashData";
import storeSessionId from "./storeSessionId";
import storeContext from "./storeContext";
import storeSupplier from "./storeHiddenSupplier";
import breadcrumb from "./Common/breadcrumbs";
import storeSelectedCompany from "./storeStoredCompany";
import storeSelectedCustomer from "./storeCustomerdetails";
import storePortal from "./Common/storePortal";
import storeCurrency from "./Common/storeCurrency";
import storePortalKey from "./Common/portalKey";
import storeSelectedVendor from "./storeVendorDetails";
import storeCommonValues from "./Common/commonAPIValues";
import storeAdminKey from "./Common/adminKey";
import storeReadOnlyAccess from "./Common/readOnlyAccess";
import storeSubcontractorKey from "./Common/subContractorKey";

export default combineReducers({
    storeCommonValues: storeCommonValues,
    currency: storeCurrency,
    sessionId: storeSessionId,
    storeRole: storeSwitchContext,
    storeCompanies: storeCompanyList,
    storeOus: storeOuList,
    storeDashDatas: storeDashData,
    context: storeContext,
    hiddenSupplier: storeSupplier,
    breadcrumb: breadcrumb,
    selectedCompany: storeSelectedCompany,
    selectedCustomer: storeSelectedCustomer,
    selectedVendor: storeSelectedVendor,
    portalData: storePortal,
    portalKey: storePortalKey,
    adminKey: storeAdminKey,
    subContractorKey: storeSubcontractorKey,
    userReadOnlyAccess:storeReadOnlyAccess
});
