import { Divider, Grid, IconButton } from "@material-ui/core";
import MUIButton from "Common/Button/Button";
import Dropdown from "Common/Dropdown/Dropdown";
import React, { useEffect, useState } from "react";
import TextFields from "Common/TextField/TextField";
import Icons from "Common/Icons/Icons";
import { BuildingComboCUS, GetItemSearchComboQuery } from "ReactQuery/Query";
import useGetPosts from "ReactQuery/reactQuery";
import { connect } from "react-redux";
import currencyFormatter from "Common/Utils/currencyFormatter";
import { Combo } from "Common/Listedit/Listedit";
import BackdropLoading from "Common/Backdrop/Backdrop";
import SaleOrderGenerationPostQuery from "ReactQuery/Query/SalesOrderGenerationPostQuery";
import DeleteIcon from "@material-ui/icons/Delete";

const OrderSuppliesDetails = (props: any) => {
    const {
        currentContext,
        selectedVendor,
        selectedCustomer,
        commonValues,
        currency,
        portalKey,
    } = props;

    const [data, setData] = useState<any>({
        ShipTo: "",
        searchsupply: "",
        Quantity: 0,
        code: "",
        uom: "",
        price: 0,
        desc: "",
        apiOutput: {},
    });

    const handleDocList = (e: React.ChangeEvent<{ value: unknown }>) => {
        const name = (e?.target as any).name;
        const value = (e?.target as any).value;
        setData((prevstate: any) => ({
            ...prevstate,
            [name]: value,
            total: value * data?.price,
        }));
    };
    const [error, seterr] = useState({
        code: 0,
        msg: "",
    });

    const handleChange = (e: any) => {
        const name = e.target?.name;
        const value = e?.target.value;
        setData((prevState: any) => ({ ...prevState, [name]: value }));
    };

    const handleListEdit = (key: string, value: any) => {
        console.log("handleListEdit", value);

        setData((prevState: any) => ({
            ...prevState,
            searchsupply: value?.itemcode,
            code: value?.itemcode,
            uom: value?.salesuom,
            price: value?.stdcost,
            desc: value?.itemdesc,
            apiOutput: value,
        }));
    };
    const [NewSupply, setSupplydata] = useState<any>([]);

    //Search supply combo
    const [getItemSearchDetails, setGetItemSearchDetails] = useState({
        header: [],
        detail: [],
    });
    const [headerValues, setHeaderValues] = useState<any>({
        getItemSearch: [],
    });

    const [ufeFetchType, setufeFetchType] = useState("ufe");
    const {
        data: GetItemSearchComboQueryData,
        error: GetItemSearchComboQueryError,
        isLoading: GetItemSearchComboQueryLoading,
    } = useGetPosts({
        name: `GetItemSearchComboQuery_${portalKey
            ? selectedVendor?.supplierCode
            : selectedCustomer?.customerCode}`,
        query: GetItemSearchComboQuery,
        variables: {
            customerCode: portalKey
                ? selectedVendor?.supplierCode
                : selectedCustomer?.customerCode,
            ou: currentContext?.ouId,
            service: "",
            user: portalKey
                ? selectedVendor?.loginUser
                : selectedCustomer?.loginUser,
            langId: currentContext?.langId,
            itemCode: "",
            variantCode: "",
            itemType: "",
            itemDesc: "",
            errorid: "",
        },
    });

    useEffect(() => {
        if (GetItemSearchComboQueryData?.GetItemSearchComboQuery) {
            console.log(
                "GetItemSearchComboQuery =====> ",
                GetItemSearchComboQueryData?.GetItemSearchComboQuery,
            );
            setGetItemSearchDetails(
                GetItemSearchComboQueryData?.GetItemSearchComboQuery,
            );
            setHeaderValues((prevState: any) => ({
                ...prevState,
                getItemSearch:
                    GetItemSearchComboQueryData?.GetItemSearchComboQuery
                        ?.detail[0],
            }));
            const value =
                GetItemSearchComboQueryData?.GetItemSearchComboQuery
                    ?.detail[0][0].code;
            setData((prevState: any) => ({
                ...prevState,
                code: value?.itemcode,
                uom: value?.salesuom,
                price: value?.stdcost,
                desc: value?.itemdesc,
                apiOutput: value,
            }));
        }
    }, [GetItemSearchComboQueryData]);

    useEffect(() => {
        if (GetItemSearchComboQueryError && !GetItemSearchComboQueryLoading) {
            const data = JSON.parse(
                JSON.stringify(GetItemSearchComboQueryError),
            );
            const errmsg = data?.response?.errors[0].message;
            seterr({
                code: 400,
                msg: errmsg,
            });
        }
    }, [GetItemSearchComboQueryError, GetItemSearchComboQueryLoading]);

    // Ship to combo
    const [shipToComboDetails, setShipToComboDetails] = useState([]);
    const {
        data: BuildingComboListData,
        error: BuildingComboListDataError,
        isLoading: BuildingComboLoading,
    } = useGetPosts({
        name: `BuildingIdCombo_orderSupplies`,
        query: BuildingComboCUS,
        variables: {
            customerCode: portalKey
                ? selectedVendor?.supplierCode
                : selectedCustomer?.customerCode,
            ou: currentContext?.ouId,
            service: "",
            user: portalKey
                ? selectedVendor?.loginUser
                : selectedCustomer?.loginUser,
            langId: currentContext?.langId,
            role: currentContext?.roleName,
            buildingId: "",
        },
    });

    useEffect(() => {
        if (BuildingComboListData?.BuildingComboCUS) {
            setShipToComboDetails(BuildingComboListData?.BuildingComboCUS);
            setData((prevState: any) => ({
                ...prevState,
                ShipTo: BuildingComboListData?.BuildingComboCUS[0]?.value,
            }));
        }
    }, [BuildingComboListData]);

    const handleSubmit = () => {
        setSupplydata((prevState: any) => [
            ...prevState,
            {
                code: data?.code,
                uom: data?.uom,
                price: data?.price,
                desc: data?.desc,
                Quantity: data?.Quantity,
                total: data?.total,
                ShipTo: data?.ShipTo,
                apiOutput: data?.apiOutput,
            },
        ]);
    };

    const handleDeleteItem = (index: number) => {
        setSupplydata((prevState: any) =>
            prevState.filter((_: any, i: number) => i !== index),
        );
    };

    const [saleOrderFlag, setSaleOrderFlag] = useState(true);
    const {
        data: SaleOrderPostData,
        error: SaleOrderPostError,
        isLoading: SaleOrderPostLoading,
    } = useGetPosts({
        name: `SaleOrderGenerationPostQuery`,
        query: SaleOrderGenerationPostQuery,
        variables: {
            SALEORDERHDR: {
                OrderType: commonValues?.ord_type,
                Currency: currency,
                TransportationMode: commonValues?.trans_mode,
                PayTermCode: commonValues?.paytermcode,
                CustomerCode: portalKey
                    ? commonValues?.customercode
                    : selectedCustomer?.customerCode,
                OrderDate: new Date(),
                PricingDate: new Date(),
                CustomerPODate: new Date(),
                AddressID: commonValues?.shiptoaddid,
                NumberingSeries: commonValues?.numbering_type,
                Saletype: commonValues?.sale_type,
            },
            SOTCDDTL: [{ BillingEvent: commonValues?.so_freight_bill }],
            SALEORDERDTL: NewSupply?.map((element: any) => {
                const apiOutput = element.apiOutput;
                return {
                    ItemCode: apiOutput?.itemcode,
                    ToShipDate: new Date(),
                    ShiptoId: element?.ShipTo,
                    SalesUOM: apiOutput?.salesuom,
                    PromisedDate: new Date(),
                    ShippingPoint: commonValues?.shippingpoint,
                    Qty: element?.Quantity,
                    UnitItemPrice: element?.price,
                    AddlColumnDate1: new Date(),
                    AddlColumnDate2: new Date(),
                    RequiredDate: new Date(),
                    FinanceBook: commonValues?.fbid,
                    PricingUOM: apiOutput?.salesuom,
                    ShipToCustomer: commonValues?.shiptocustomer,
                };
            }),
        },
        skip: saleOrderFlag,
        header: {
            ou: currentContext?.ouId,
            role: currentContext?.roleName,
            lang: currentContext?.langId,
        },
    });

    useEffect(() => {
        if (SaleOrderPostData?.SaleOrderGenerationPostQuery) {
            seterr({
                code: 200,
                msg: `${SaleOrderPostData?.SaleOrderGenerationPostQuery?.Error_msg} - ${SaleOrderPostData?.SaleOrderGenerationPostQuery?.Tran_no}`,
            });
            setSaleOrderFlag(true);
        }
    }, [SaleOrderPostData]);

    useEffect(() => {
        if (SaleOrderPostError && !SaleOrderPostLoading) {
            const data = JSON.parse(JSON.stringify(SaleOrderPostError));
            const errmsg = data?.response?.errors[0].message;
            seterr({
                code: 400,
                msg: errmsg,
            });
            setSaleOrderFlag(true);
        }
    }, [SaleOrderPostError, SaleOrderPostLoading]);
    return (
        <Grid
            container
            spacing={2}
            alignItems="center"
            style={{ marginLeft: "1%", maxWidth: "550px", paddingTop: "2%" }}
        >
            <BackdropLoading
                openStates={
                    GetItemSearchComboQueryLoading ||
                    BuildingComboLoading ||
                    SaleOrderPostLoading
                }
            />
            <Grid item xs={4}>
                <Dropdown
                    label={"ShipTo"}
                    id={"ShipTo"}
                    value={data?.ShipTo}
                    list={shipToComboDetails}
                    handleChange={handleChange}
                    width={"100%"}
                />
            </Grid>
            <Grid item xs={8}></Grid>
            <Grid item xs={4}>
                <Combo
                    label="Search Supply"
                    header={getItemSearchDetails?.header}
                    detail={getItemSearchDetails?.detail}
                    handleInput={handleListEdit}
                    width={"100%"}
                    value={headerValues?.getItemSearch}
                    minWidth={"400px"}
                    fetchType={[ufeFetchType, setufeFetchType]}
                    loading={GetItemSearchComboQueryLoading}
                />
            </Grid>

            <Grid container xs={8} spacing={1} style={{ paddingLeft: "10%" }}>
                <Grid item xs={3}>
                    <Grid container>
                        <Grid item xs={12} style={{ color: "#7D8597" }}>
                            Item Code
                        </Grid>
                        <Grid item xs={12} style={{ color: "#33415C" }}>
                            {data?.code}
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={3}>
                    <Grid container>
                        <Grid item xs={12} style={{ color: "#7D8597" }}>
                            U/M
                        </Grid>
                        <Grid item xs={12} style={{ color: "#33415C" }}>
                            {data?.uom}
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={3}>
                    <Grid container>
                        <Grid item xs={12} style={{ color: "#7D8597" }}>
                            Price
                        </Grid>
                        <Grid item xs={12} style={{ color: "#00AE50" }}>
                            {currencyFormatter(data?.price, currency)}
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

            <Grid item xs={4}>
                <TextFields
                    label="Quantity"
                    id="Quantity"
                    multiline={false}
                    rows={1}
                    value={data?.Quantity}
                    handleChange={handleDocList}
                    width="100%"
                    type="number"
                />
            </Grid>
            <Grid item xs={8}>
                <Grid container style={{ paddingLeft: "13%" }}>
                    <Grid item xs={12} style={{ color: "#7D8597" }}>
                        Total
                    </Grid>
                    <Grid item xs={12} style={{ color: "#00AE50" }}>
                        {currencyFormatter(
                            data?.Quantity * data?.price,
                            currency,
                        )}
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={9}></Grid>
            {data?.Quantity !== 0 && (
                <Grid item xs={2}>
                    <MUIButton
                        label="Add"
                        onClick={handleSubmit}
                        type="filled"
                        font="fontMedium"
                    />
                </Grid>
            )}

            <Grid item xs={1}></Grid>

            <Grid item xs={12}>
                <Divider />
            </Grid>
            <Grid item xs={12}>
                <Grid container spacing={2} alignItems="center">
                    <Grid item xs={6}>
                        {NewSupply.length} Items Added
                    </Grid>
                    <Grid item xs={12} alignItems="center">
                        {NewSupply?.map((element: any, index: any) => (
                            <Grid
                                container
                                xs={12}
                                spacing={2}
                                sm={11}
                                style={{
                                    border: "1px solid #C9CDD6",
                                    padding: "2%",
                                    margin: "2%",
                                }}
                            >
                                <Grid item xs={3}>
                                    <Grid container>
                                        <Grid
                                            item
                                            xs={12}
                                            style={{
                                                color: "#7D8597",
                                                display: "flex",
                                            }}
                                        >
                                            <Icons Icon={"supplyitemimage"} />
                                            {"#"}
                                            {element?.code}
                                        </Grid>
                                        <Grid
                                            item
                                            xs={12}
                                            style={{
                                                color: "#33415C",
                                                wordBreak: "break-word",
                                            }}
                                        >
                                            {element?.desc}
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={2}>
                                    <Grid container>
                                        <Grid
                                            item
                                            xs={12}
                                            style={{ color: "#7D8597" }}
                                        >
                                            Item Code
                                        </Grid>
                                        <Grid
                                            item
                                            xs={12}
                                            style={{ color: "#33415C" }}
                                        >
                                            {element?.code}
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={1}>
                                    <Grid container>
                                        <Grid
                                            item
                                            xs={12}
                                            style={{ color: "#7D8597" }}
                                        >
                                            U/M
                                        </Grid>
                                        <Grid
                                            item
                                            xs={12}
                                            style={{ color: "#33415C" }}
                                        >
                                            {element?.uom}
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={2}>
                                    <Grid container>
                                        <Grid
                                            item
                                            xs={12}
                                            style={{ color: "#7D8597" }}
                                        >
                                            Price
                                        </Grid>
                                        <Grid
                                            item
                                            xs={12}
                                            style={{ color: "#33415C" }}
                                        >
                                            {currencyFormatter(
                                                element?.price,
                                                currency,
                                            )}
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={2}>
                                    <Grid container>
                                        <Grid
                                            item
                                            xs={12}
                                            style={{ color: "#7D8597" }}
                                        >
                                            Quantity
                                        </Grid>
                                        <Grid
                                            item
                                            xs={12}
                                            style={{ color: "#33415C" }}
                                        >
                                            {element?.Quantity}
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={1} style={{ color: "#00AE50" }}>
                                    {currencyFormatter(
                                        element?.total,
                                        currency,
                                    )}
                                </Grid>
                                <Grid
                                    item
                                    xs={1}
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                    }}
                                >
                                    <IconButton
                                        onClick={() => {
                                            handleDeleteItem(index);
                                        }}
                                    >
                                        <DeleteIcon />
                                    </IconButton>
                                </Grid>
                            </Grid>
                        ))}
                    </Grid>
                    <div
                        style={{
                            position: "relative",
                            bottom: "10px",
                            width: "100%",
                        }}
                    >
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <Divider variant="middle" />
                            </Grid>
                            <Grid item xs={12}>
                                {error.code !== 0 ? (
                                    <Grid
                                        item
                                        xs={12}
                                        style={{
                                            textAlign: "center",
                                            padding: "2%",
                                        }}
                                        className={
                                            error.code === 200
                                                ? "green-light-background"
                                                : "red-light-background"
                                        }
                                    >
                                        <span
                                            className={`${
                                                error?.code === 200
                                                    ? "Success_dot"
                                                    : "Failed_dot"
                                            }`}
                                        ></span>{" "}
                                        <b>{error.msg}</b>
                                    </Grid>
                                ) : (
                                    <Grid
                                        item
                                        xs={12}
                                        style={{
                                            textAlign: "center",
                                            padding: "2%",
                                        }}
                                    >
                                        <span></span> <b></b>
                                    </Grid>
                                )}
                            </Grid>
                            <Grid item xs={8}></Grid>
                            {NewSupply.length !== 0 && (
                                <Grid item xs={4}>
                                    <MUIButton
                                        label="Submit My Order"
                                        onClick={() => {
                                            setSaleOrderFlag(false);
                                        }}
                                        type="filled"
                                        font="fontMedium"
                                    />
                                </Grid>
                            )}
                        </Grid>
                    </div>
                </Grid>
            </Grid>
        </Grid>
    );
};

const mapStateToProps = (state: any) => {
    return {
        currentContext: state.context?.userDefaults,
        userName: state.context?.name,
        supplierCode: state.hiddenSupplier?.supplierCodeOut,
        currency: state.currency,
        selectedVendor: state.selectedVendor,
        selectedCustomer: state.selectedCustomer,
        commonValues: state.storeCommonValues,
        portalKey: state.portalKey,
    };
};

export default connect(mapStateToProps)(OrderSuppliesDetails);
