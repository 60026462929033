import { Grid } from "@material-ui/core";
import Alert from "Common/Alerts/Alerts";
import BackdropLoading from "Common/Backdrop/Backdrop";
import ButtonGroups from "Common/ButtonGroup/ButtonGroup";
import { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { LoanBalanceSummaryQuery } from "ReactQuery/Query";
import useGetPosts from "ReactQuery/reactQuery";
import { storeBreadcrumbs } from "stateManagement/actions/Common/breadcrumbs";
import LoanSummaryCard from "./LoanSummaryCard";
const controlList = [
    {
        desc: "Active / Held",
        value: "AC",
    },
    {
        desc: "Closed",
        value: "CL",
    },
];

const Body = (props: any) => {
    const { currentContext, selectedVendor, setBreadcrumbsData } = props;
    setBreadcrumbsData([
       
        {
            value: "Loan Summary",
            href: "/app/*/Loans",
        },
    ]);
    const [loanID, setloanID] = useState<any>();
    const [loanDetailsRedirect, setLoanDetailsRedirect] = useState(false);
    const [alert, setAlert] = useState(false);
    const [errr, seterr] = useState({
        code: 0,
        msg: "",
    });
    const [activeList, setActiveList] = useState(controlList[0].value);
    const handleDocList = (listType: string) => {
        setActiveList(listType);
    };
    const handleErrorAlert = (code: number, message: string) => {
        Alert({
            statusCode: code,
            message: message,
            willClose: () => {
                setAlert(false);
                seterr({ code: 0, msg: "" });
            },
        });
    };
    const [LoanSummary, setLoadSummary] = useState<any>();
    const {
        data: LoanSummaryData,
        error: LoanSummaryDataError,
        isLoading: LoanSummaryDataLoading,
    } = useGetPosts({
        name: `loanBalanceSummary-DBLS-${activeList}_${selectedVendor?.supplierCode}`,
        query: LoanBalanceSummaryQuery,
        variables: {
            entity: "ow_getcurr_loan_bal_summ_entity",
            ou: currentContext?.ouId,
            service: "",
            User: selectedVendor?.loginUser,
            languageId: currentContext?.langId,
            supplierCode: selectedVendor?.supplierCode,
            callingfrom: "DBLS",
            status: activeList,
        },
    });

    useEffect(() => {
        if (LoanSummaryData?.loanBalanceSummary) {
            console.log("LoanSummaryData", LoanSummaryData?.loanBalanceSummary);
            setLoadSummary(LoanSummaryData?.loanBalanceSummary);
        }
    }, [LoanSummaryData]);

    useEffect(() => {
        if (LoanSummaryDataError && !LoanSummaryDataLoading) {
            const data = JSON.parse(JSON.stringify(LoanSummaryDataError));
            const errmsg = data?.response?.errors[0].message;
            seterr({
                code: 400,
                msg: errmsg,
            });
            // setAlert(true);
            setLoadSummary([])
        }
    }, [LoanSummaryDataError, LoanSummaryDataLoading]);
     console.log(loanID);
     
    if (loanDetailsRedirect) {
        return (
            <Redirect
                to={{
                    pathname: `/app/*/LoanDetails`,
                    state: {
                        loanID: loanID?.loanNo,
                        totalAmount: loanID?.totalAmount,
                        balanceAmount: loanID?.balanceAmount,
                        paidAmount: loanID?.paidAmount,
                        noOfSchedule: loanID?.noOfSchedule,
                        status:loanID?.status
                    },
                }}
            />
        );
    }

    return (
        <>
            {alert ? handleErrorAlert(errr.code, errr.msg) :<></>}
            <BackdropLoading openStates={LoanSummaryDataLoading} />
            <Grid container style={{ paddingTop: "2%" }} spacing={2}>
                <Grid item xs={10} sm={6}>
                    <ButtonGroups
                        controlList={controlList}
                        handleDocList={handleDocList}
                        gridSpacing={2}
                        externalActive={activeList}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={2}>
                        {LoanSummary?.map(
                            (element: {
                                loanNo: string;
                                totalAmount: string;
                                paidAmount: string;
                                balanceAmount: string;
                                noOfSchedule: string;
                                totActLoan: string;
                                status:string;
                            }) => {
                                return (
                                    <Grid
                                        item
                                        xs={6}
                                        md={4}
                                        className="pointer"
                                        onClick={() => {
                                            setLoanDetailsRedirect(true);
                                            setloanID(element);
                                        }}
                                    >
                                        <LoanSummaryCard
                                            LoanSummary={element}
                                            activeList={activeList}
                                        />
                                    </Grid>
                                );
                            },
                        )}
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};

const mapStateToProps = (state: any) => {
    return {
        currentContext: state.context?.userDefaults,
        selectedVendor: state.selectedVendor,
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        setBreadcrumbsData: (data: any) => {
            dispatch(storeBreadcrumbs(data));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Body);
