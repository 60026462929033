const ContractVendorDetailsCusQuery = `
    mutation(
        $entity: String
        $ou: Int
        $service: String
        $User: String
        $languageId: Int
        $contractno: String
        $conttype: String
        $customerCode: String
        $siteId: String
        $buildName: String
        $pono: String
        $merrorid: String
    ){
        VendorDetailsTableDataCus(
            entity: $entity
            ou: $ou
            service: $service
            User: $User
            languageId: $languageId
            contractno: $contractno
            conttype: $conttype
            customerCode: $customerCode
            siteId: $siteId
            buildName: $buildName
            pono: $pono
            merrorid: $merrorid 
        ){
            Vendor
            ContractNumber
            AccountNumber
            Customer
            State
            BuildingID
            Contracts
            StartEndDate
            ServiceCode
            VendorAmountPercent{
               line1
               line2
            }
            Replace
            firstMonthProrate
            LastMonthProrate      
        }
    }
`;
export default ContractVendorDetailsCusQuery;