const InvoiceTableDataCusQuery = `
mutation(
    $entity: String
    $ou: Int
    $service: String
    $User: String
    $languageId: Int
    $Status:String
    $customerCode: String
    $customerName: String
    $siteId: String
    $buildName: String
    $contractNo: String
    $invoiceNo: String
    $fromDate: String
    $toDate: String
    $merrorid: String
  ){
        InvoiceTableDataCus( 
            entity: $entity
            ou: $ou
            service: $service
            User: $User
            languageId: $languageId
            Status:$Status
            customerCode: $customerCode
            customerName: $customerName
            siteId: $siteId
            buildName: $buildName
            contractNo: $contractNo
            invoiceNo: $invoiceNo
            fromDate: $fromDate
            toDate: $toDate
            merrorid: $merrorid
        ){
                
            InvoiceNo
            ContractDetails
            TotalAmount
            BalanceDue
            PaymentStatus 
            DocDate
            PastDueDate
            print        
        }
    }
`;

export default InvoiceTableDataCusQuery;