import { Grid, Paper } from "@material-ui/core";

export default function LoanIndexCard(props: any) {
    return (
        <Paper elevation={3} style={{ minHeight: "calc(100% + 10px)" }}>
            <Grid container spacing={1}>
                <Grid
                    item
                    xs={12}
                    className="text-left font-boldest"
                    style={{
                        margin: "3% 6%",
                        paddingBottom: "3%",
                        height: "100%",
                    }}
                >
                    <Grid container spacing={1}>
                        <Grid
                            item
                            className="font-boldest grey1"
                            style={{
                                background: `#FFEDFA`,
                                borderRadius: "5px",
                            }}
                        >
                            {`Loan Index`}
                        </Grid>
                    </Grid>
                </Grid>
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "100px",
                        width: "100px",
                        margin: "auto",
                        background: "#FFF4EE",
                        border: "1px transparent",
                        borderRadius: "50%",
                    }}
                >
                    <Grid
                        item
                        xs={12}
                        className="chocolate-brown"
                        style={{ fontSize: "15px" }}
                        // style={{ fontSize: "25px" }}
                    >
                        Coming <br></br>
                        soon
                        {/* {props.loanIndex ? props.loanIndex : 0}% */}
                    </Grid>
                </div>
            </Grid>
        </Paper>
    );
}
