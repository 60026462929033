import Add from "./Images/Add.svg";
import AddCircle from "./Images/AddCircle.svg";
import Address from "./Images/Address.svg";
import AlertsNotifs from "./Images/AlertsNotifs.svg";
import ArrowDown from "./Images/ArrowDown.svg";
import BlueAttach from "./Images/Attach.svg";
import Attachments from "./Images/Attachments.svg";
import AttachFileIcon from "./Images/AttachmentsBig.svg";
import Basket from "./Images/Basket.svg";
import BlueFlag from "./Images/BlueFlag.svg";
import Calendar from "./Images/Calendar.svg";
import Calls from "./Images/Calls.svg";
import Catalogue from "./Images/Catalogue.svg";
import Checklist from "./Images/Checklist.svg";
import ClientBuilding from "./Images/ClientBuilding.svg";
import Consignment from "./Images/Consignment.svg";
import Delete from "./Images/Delete.svg";
import Filter from "./Images/Filter.svg";
import FrequentAction from "./Images/FrequentAction.svg";
import GreenTick from "./Images/GreenTick.svg";
import GreyTick from "./Images/GreyTick.svg";
import WhiteTick from "./Images/WhiteTick.svg";
import TimingIcon from "./Images/TimingIcon.svg";
import UserIcon from "./Images/UserIcon.svg";
import WhiteCross from "./Images/WhiteCross.svg";
import GridView from "./Images/GridView.svg";
import GridViewDark from "./Images/GridViewDark.svg";
import Home from "./Images/Home.svg";
import Info from "./Images/Info.svg";
import ListView from "./Images/ListView.svg";
import ListViewDark from "./Images/ListViewDark.svg";
import Options from "./Images/Options.svg";
import PendingActions from "./Images/PendingActions.svg";
import Print from "./Images/Print.svg";
import QRScanner from "./Images/QRScanner.svg";
import Revision from "./Images/Revision.svg";
import Recycle from "./Images/Recycle.svg";
import Search from "./Images/Search.svg";
import SearchDocs from "./Images/SearchDocs.svg";
import Setting from "./Images/Setting.svg";
import SettingMedium from "./Images/SettingMedium.svg";
import SmallArrowRight from "./Images/SmallArrowRight.svg";
import SmallTimer from "./Images/SmallTimer.svg";
import StopWatch from "./Images/StopWatch.svg";
import Timer from "./Images/Timer.svg";
import TurnOver from "./Images/TurnOver.svg";
import Van from "./Images/Van.svg";
import Location from "./Images/Location.svg";
import User from "./Images/User.svg";
import DocumentAttach from "./Images/DocumentAttach.svg";
import Map from "./Images/Map.svg";
import UploadImg from "./Images/UploadWhite.svg";
import ArrowRightCircleBlue from "./Images/ArrowRightCircleBlue.svg";
import EditCircleBlue from "./Images/EditCircleBlue.svg";
import YellowDot from "./Images/YellowDot.svg";
import GreenDot from "./Images/GreenDot.svg";
import TurnoverWhite from "./Images/TurnoverWhite.svg";
import AttachmentsIconBlue from "./Images/AttachmentIconBlue.svg";
import PendingDocs from "./Images/PendingDocs.svg";
import Inquire from "./Images/Inquire.svg";
import StatementAccount from "./Images/StatementAccount.svg";
import RecentPayments from "./Images/RecentPayments.svg";
import HighlightedAttachment from "./Images/HighlightedAttachment.svg";
import Receipts from "./Images/Receipts.svg";
import SearchBlue from "./Images/SearchBlue.svg";
import GreyHouse from "./Images/GreyHouse.svg";
import DocReceipt from "./Images/DocReceipt.svg";
import DocSelected from "./Images/DocSelected.svg";
import CalendarBig from "./Images/CalendarBig.svg";
import Retry from "./Images/Retry.svg";
import More from "./Images/More.svg";
import Edit from "./Images/Edit.svg";
import Confirm from "./Images/Confirm.svg";
import DeleteBlue from "./Images/DeleteBlue.svg";
import PrintWithoutCircle from "./Images/PrintWithoutCircle.svg";
import Tracking from "./Images/Tracking.svg";
import InvoiceSummary from "./Images/InvoiceSummary.svg";
import Notes from "./Images/Notes.svg";
import Request from "./Images/Request.svg";
import ChecklistBlue from "./Images/ChecklistBlue.svg";
import InfoWhiteList from "./Images/InfoWhite.svg";
import CloseGrey from "./Images/CloseGrey.svg";
import Building from "./Images/Building.svg";
import CloseRed from "./Images/CloseRed.svg";
import BuildingDummy from "./Images/BuildingIcon.svg";
import MobileDash from "./Images/MobileDash.svg";
import MailDash from "./Images/MailDash.svg";
import ContractDetail from "./Images/ContractDetail.svg";
import BuildingDetail from "./Images/BuildingDetail.svg";
import VendorContract from "./Images/VendorContract.svg";
import LocationDetail from "./Images/LocationDetail.svg";
import SettingDetail from "./Images/SettingDetail.svg";
import buildingContract from "./Images/buildingContract.svg";
import supplyitemimage from "./Images/supplyitemimage.svg";
import dotimage from "./Images/dot.svg";
import Line from "./Images/Line.svg";
import carriertray from "./Images/carriertray.svg";
import location from "./Images/Location.svg";
import penimage from "./Images/penimage.svg";
import deleteicon from "./Images/deleteicon.svg";
import companylogo from "./Images/companylogo.svg";
import printerImage from "./Images/printerImage.svg";
import mailimage from "./Images/mailimage.svg";
import Book from "./Images/Book.svg";
import ColoredContracts from "./Images/ColoredContracts.svg";
import ColoredInvoices from "./Images/ColoredInvoices.svg";
import ColoredOrders from "./Images/ColoredOrders.svg";
import ColoredTickets from "./Images/ColoredTickets.svg";
import ColoredBuiding from "./Images/ColoredBuiding.svg";
import BlackDot from "./Images/BlackDot.svg";
import CalenderPic from "./Images/CalenderPic.svg";
import Ticket from "./Images/Ticket.svg";
import TickNotes from "./Images/TickNotes.svg";
import EditPic from "./Images/EditPic.svg";
import Description from "./Images/Description.svg";
import Priority2 from "./Images/Priority2.svg";
import Priority1 from "./Images/Priority1.svg";
import BuildPic from "./Images/BuildPic.svg";
import PaymentIcon from "./Images/PaymentIcon.svg";
import StatementIcon from "./Images/StatementIcon.svg";
import Excel from "./Images/Excel.svg";
import Download from "./Images/download.svg";
import GreyColorSearchIcon from "./Images/GreyColorSearchIcon.svg";
import NavMenuIn from "./Images/NavMenuIn.svg";
import NavMenuOut from "./Images/NavMenuOut.svg";
import GreenTableTick from "./Images/GreenTableTick.svg";
import RedTableTick from "./Images/RedTableTick.svg";
import NoTicketsError from "./Images/NoTicketsError.svg";
import NoApprovalsError from "./Images/NoApprovalsError.svg";
import RedirectIcon from "./Images/RedirectIcon.svg";

import VisibilityIcon from "@material-ui/icons/Visibility";
interface IconProps {
    Icon: string;
    info?: string;
    style?: any;
}

const Icons = (props: IconProps) => {
    const { Icon, info, style } = props;
    switch (Icon) {
        case "visible":
            return <VisibilityIcon className={info} />;
        case "redirectIcon":
            return <img alt="" style={style} src={RedirectIcon} />;
        case "buildingContract":
            return <img alt="" style={style} src={buildingContract} />;
        case "NoApprovalsError":
            return <img src={NoApprovalsError} />;
        case "navMenuOut":
            return <img src={NavMenuOut} />;
        case "navMenuIn":
            return <img src={NavMenuIn} />;
        case "noTicketsError":
            return <img alt="" style={style} src={NoTicketsError} />;
        case "settingDetail":
            return <img alt="" style={style} src={SettingDetail} />;
        case "locationDetail":
            return <img alt="" style={style} src={LocationDetail} />;
        case "vendorContract":
            return <img alt="" style={style} src={VendorContract} />;
        case "buildingDetail":
            return <img alt="" style={style} src={BuildingDetail} />;
        case "contractDetail":
            return <img alt="" style={style} src={ContractDetail} />;
        case "mailDash":
            return <img alt="" style={style} src={MailDash} />;
        case "mobileDash":
            return <img alt="" style={style} src={MobileDash} />;
        case "buildingDummy":
            return <img alt="" style={style} src={BuildingDummy} />;
        case "closeRed":
            return <img src={CloseRed} />;
        case "add":
            return <img src={Add} />;
        case "building":
            return <img src={Building} />;
        case "closeGrey":
            return <img src={CloseGrey} className="pointer" />;
        case "infowhite":
            return <img src={InfoWhiteList} />;
        case "checklistBlue":
            return <img src={ChecklistBlue} />;
        case "edit":
            return <img src={Edit} />;
        case "confirm":
            return <img src={Confirm} />;
        case "deleteBlue":
            return <img src={DeleteBlue} />;
        case "printWithoutCircle":
            return <img src={PrintWithoutCircle} />;
        case "tracking":
            return <img src={Tracking} />;
        case "invoiceSummary":
            return <img src={InvoiceSummary} />;
        case "notes":
            return <img src={Notes} />;
        case "request":
            return <img src={Request} />;
        case "calendarBig":
            return <img src={CalendarBig} />;
        case "more":
            return <img src={More} />;
        case "addCircle":
            return <img src={AddCircle} />;
        case "address":
            return <img src={Address} />;
        case "alertsNotifs":
            return <img src={AlertsNotifs} />;
        case "arrowDown":
            return <img src={ArrowDown} />;
        case "attachBlue":
            return <img src={BlueAttach} />;
        case "yellowDot":
            return <img src={YellowDot} />;
        case "greenDot":
            return <img src={GreenDot} />;
        case "editCircleBlue":
            return <img src={EditCircleBlue} />;
        case "arrowRightCircleBlue":
            return <img src={ArrowRightCircleBlue} />;
        case "attachments":
            return <img src={Attachments} />;
        case "attachmentsIcon":
            return <img src={AttachFileIcon} />;
        case "basket":
            return <img src={Basket} />;
        case "blueFlag":
            return <img src={BlueFlag} />;
        case "calendar":
            return <img src={Calendar} />;
        case "consignment":
            return <img src={Consignment} />;
        case "calls":
            return <img src={Calls} />;
        case "catalogue":
            return <img src={Catalogue} />;
        case "checklist":
            return <img src={Checklist} />;
        case "clientBuilding":
            return <img src={ClientBuilding} />;
        case "delete":
            return <img src={Delete} />;
        case "filter":
            return <img src={Filter} />;
        case "frequentAction":
            return <img src={FrequentAction} />;
        case "greenTick":
            return <img src={GreenTick} />;
        case "greyTick":
            return <img src={GreyTick} />;
        case "whiteTick":
            return <img src={WhiteTick} />;
        case "whiteCross":
            return <img src={WhiteCross} />;
        case "timingIcon":
            return <img src={TimingIcon} />;
        case "userIcon":
            return <img src={UserIcon} />;
        case "turnoverWhite":
            return (
                <img
                    src={TurnoverWhite}
                    style={{ height: "15px", paddingTop: "15%" }}
                />
            );
        case "gridView":
            return <img src={GridView} />;
        case "gridViewDark":
            return <img src={GridViewDark} />;
        case "home":
            return <img src={Home} />;
        case "print":
            return <img src={Print} />;
        case "qrScanner":
            return <img src={QRScanner} />;
        case "search":
            return <img src={Search} />;
        case "searchDocs":
            return <img src={SearchDocs} />;
        case "setting":
            return <img src={Setting} />;
        case "settingMedium":
            return <img src={SettingMedium} />;
        case "smallArrowRight":
            return <img src={SmallArrowRight} />;
        case "smallTimer":
            return <img src={SmallTimer} />;
        case "info":
            return <img src={Info} />;
        case "listView":
            return <img src={ListView} />;
        case "listViewDark":
            return <img src={ListViewDark} />;
        case "location":
            return <img src={Location} />;
        case "options":
            return <img src={Options} />;
        case "pendingActions":
            return <img src={PendingActions} />;
        case "revision":
            return <img src={Revision} />;
        case "recycle":
            return <img src={Recycle} />;
        case "stopWatch":
            return <img src={StopWatch} />;
        case "timer":
            return <img src={Timer} />;
        case "van":
            return <img src={Van} />;
        //Van
        case "turnOver":
            return <img src={TurnOver} />;
        case "user":
            return <img src={User} />;
        case "documentAttach":
            return <img src={DocumentAttach} />;
        case "map":
            return <img src={Map} />;
        case "attachmentsIconBlue":
            return <img src={AttachmentsIconBlue} />;
        case "uploadWhite":
            return <img src={UploadImg} style={{ height: "21px" }} />;
        case "pendingDocs":
            return <img src={PendingDocs} />;
        case "inquire":
            return <img src={Inquire} />;
        case "statementAccount":
            return <img src={StatementAccount} />;
        case "recentPayments":
            return <img src={RecentPayments} style={{ height: "21px" }} />;
        case "highlightedAttachment":
            return <img src={HighlightedAttachment} />;
        case "receipts":
            return <img src={Receipts} />;
        case "searchBlue":
            return <img src={SearchBlue} />;
        case "greyHouse":
            return <img src={GreyHouse} />;
        case "docSelected":
            return <img src={DocSelected} />;
        case "docReceipt":
            return <img src={DocReceipt} />;
        case "retry":
            return <img src={Retry} />;

        case "supplyitemimage":
            return <img src={supplyitemimage} />;

        case "dotimage":
            return <img src={dotimage} />;

        case "Line":
            return <img src={Line} />;

        case "carriertray":
            return <img src={carriertray} />;

        case "location":
            return <img src={location} />;

        case "penimage":
            return <img src={penimage} />;
        //Map
        case "deleteicon":
            return <img src={deleteicon} />;

        case "companylogo":
            return <img src={companylogo} />;

        case "printerImage":
            return <img src={printerImage} />;
        case "mailimage":
            return <img src={mailimage} />;
        case "Book":
            return <img src={Book} />;

        case "coloredBuilding":
            return <img src={ColoredBuiding} />;
        case "coloredContracts":
            return <img src={ColoredContracts} />;
        case "coloredTickets":
            return <img src={ColoredTickets} />;
        case "coloredOrders":
            return <img src={ColoredOrders} />;
        case "coloredInvoices":
            return <img src={ColoredInvoices} />;
        case "blackDot":
            return <img src={BlackDot} />;

        case "ticket":
            return <img src={Ticket} />;

        case "tickNotes":
            return <img src={TickNotes} />;

        case "calenderPic":
            return <img src={CalenderPic} />;

        case "BuildPic":
            return <img src={BuildPic} />;

        case "priority1":
            return <img src={Priority1} />;

        case "priority2":
            return <img src={Priority2} />;

        case "editPic":
            return <img src={EditPic} />;

        case "description":
            return <img src={Description} />;

        case "currencyIcon":
            return <img src={PaymentIcon} />;

        case "statementIcon":
            return <img src={StatementIcon} />;

        case "excelIcon":
            return <img src={Excel} />;

        case "download":
            return <img src={Download} />;

        case "GreyColorSearchIcon":
            return <img src={GreyColorSearchIcon} />;
        case "greenTableTick":
            return <img src={GreenTableTick} />;

        case "redTableTick":
            return <img src={RedTableTick} />;
        case "supplyitemimage":
            return <img src={supplyitemimage} />;
        case "dotimage":
            return <img src={dotimage} />;
        case "Line":
            return <img src={Line} />;
        case "carriertray":
            return <img src={carriertray} />;
        case "location":
            return <img src={location} />;
        case "penimage":
            return <img src={penimage} />;
        //Map
        case "deleteicon":
            return <img src={deleteicon} />;
        case "companylogo":
            return <img src={companylogo} />;
        case "printerImage":
            return <img src={printerImage} />;
        case "mailimage":
            return <img src={mailimage} />;
        case "Book":
            return <img src={Book} />;
        case "coloredBuilding":
            return <img src={ColoredBuiding} />;
        case "coloredContracts":
            return <img src={ColoredContracts} />;
        case "coloredTickets":
            return <img src={ColoredTickets} />;
        case "coloredOrders":
            return <img src={ColoredOrders} />;
        case "coloredInvoices":
            return <img src={ColoredInvoices} />;
        case "blackDot":
            return <img src={BlackDot} />;
        default:
            return <></>;
    }
};

export default Icons;
