const ContractHeaderDataCusQuery = `
    mutation(
        $entity:String,
        $ou:Int,
        $service:String,
        $User:String,
        $languageId:Int,
        $customerCode:String,
        $merrorid:String
    ){
        ContractHeaderDataCus(
            entity:$entity,
            ou:$ou,
            service:$service,
            User:$User,
            languageId:$languageId,
            customerCode:$customerCode,
            merrorid:$merrorid
        ){
            desc
            frequent
            value
            visible
            detail {
                desc
                value
                status
            }
        }
    }
`;
export default ContractHeaderDataCusQuery;