import { useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Icons from "Common/Icons/Icons";
import certificate from "./Images/certificate.svg";
import "./DocumentCards.scss";
import pdf from "./Images/pdf.svg";
import doc from "./Images/doc.svg";
import excel from "./Images/excel.svg";
import image from "./Images/image.svg";
import Alert from "Common/Alerts/Alerts";
import MLActionAlert from "Common/Alerts/MultipleActionAlert";
import { connect } from "react-redux";
import { UploadDocumentQuery } from "ReactQuery/Query";
import useGetPosts from "ReactQuery/reactQuery";
interface DocumentCardsProps {
    fileType: string;
    desc: string;
    fileName: string;
    link?: string;
    download?: any;
    serverFileName?: String;
    currentContext?:any;
    supplierCode?:any;
    RefetchAfterDelete?:any
    userReadOnlyAccess:Boolean
}

const DocumentCardsCert = (props: DocumentCardsProps) => {
    const {
        fileType,
        desc,
        fileName,
        link = "temp",
        download,
        serverFileName,
        currentContext,
        supplierCode,
        RefetchAfterDelete,
        userReadOnlyAccess
    } = props;
    const [expand, setExpand] = useState(false);
    // const source = certificate
    const source =
        fileType === "pdf"
            ? pdf
            : fileType === "doc"
            ? doc
            : fileType === "txt"
            ? doc
            : ["png", "jpeg", "jpg"].includes(fileType)
            ? image
            : excel;
    const color =
        fileType === "pdf"
            ? "rgba(229, 90, 90, 0.07)"
            : fileType === "doc"
            ? "#F1F9FF"
            : "rgba(41, 194, 127, 0.08)";

    // doc\eameqp\
    const [ondelete, setOndelete] = useState(false);
    const {
        data: DeleteDocumentData,
        error: DeleteDocumentError,
        isLoading: DeleteDocumentLoading,
    } = useGetPosts({
        name: `DeleteDocumentQuery${fileName}_`,
        query: UploadDocumentQuery,
        variables: {
            // entity: "OW_Cust_portal_getcompprfluser",
            supplierCode,
            callingFrom: "dsb_del",
            fileType: desc,
            docTitle:"",
            fileName: fileName,
            role:currentContext?.roleName,
            langId: currentContext?.langId,
            ou: currentContext?.ouId,
            serverFileName:""
        },
        skip: !ondelete,
        cacheState:true
        // tryForceFetch: false,
    });

    useEffect(() => {
        if (DeleteDocumentData?.uploadDocument) {
            console.log(DeleteDocumentData);
            setOndelete(false);
            RefetchAfterDelete((prev:any)=>prev+1);
        }
    }, [DeleteDocumentData]);

    useEffect(() => {
        if (DeleteDocumentError && !DeleteDocumentLoading) {
            const data = JSON.parse(JSON.stringify(DeleteDocumentError));
            const errmsg = data?.response?.errors[0].message;
            console.log(errmsg);
            console.log(DeleteDocumentError);
            setOndelete(false);
         
            
        }
    }, [DeleteDocumentError, DeleteDocumentLoading]);

    const handleDelete=()=>{
    
            MLActionAlert({
                
                fileName:fileName ?? "",
                willClose: () => {
                    console.log("not deleted")
                    setOndelete(false);
                },
                onConfirm :()=>{
                    console.log("file deleted successfully..!") 
                    setOndelete(true);
                    
                }
            });
        
    }



    return (
        <Paper variant="outlined" className="">
            <Grid container alignItems="center">
                <Grid item xs={2} className={fileType}>
                    <div
                        style={{
                            width: "100%",
                            height: "80px",
                            backgroundColor: link === "DOC" ? "#FFFFFF" : color,
                            textAlign: "center",
                            paddingTop: link === "DOC" ? "10%" : "1px",
                        }}
                    >
                        {link === "DOC" ? (
                            <span style={{ alignItems: "center" }}>
                                <Icons Icon={fileType} info="" />
                            </span>
                        ) : (
                            <img src={source} style={{ paddingTop: "20%" }} />
                        )}
                    </div>
                </Grid>
                <Grid item xs={7}>
                    <Grid
                        container
                        spacing={0}
                        alignItems="center"
                        justifyContent="flex-start"
                        style={{ textAlign: "left", marginLeft: "2%" }}
                    >
                        <Grid
                            item
                            xs={12}
                            className={"mildGrey bolder"}
                            // onClick={() => setExpand(!expand)}
                        >
                            {
                                desc
                                    ? expand
                                        ? desc // Show the full description when 'expand' is true
                                        : // Show a truncated description when 'expand' is false
                                          `${desc.slice(0, 80)}${
                                              desc.length > 40 ? "..." : ""
                                          }`
                                    : "" // If 'desc' is undefined or empty, render nothing
                            }
                        </Grid>
                        <Grid item xs={12} className={"default fontSmall"}>
                            {fileName}
                        </Grid>
                    </Grid>
                </Grid>
                <Grid
                    item
                    xs={1}
                    className="pointer"
                    onClick={() => {
                        download(serverFileName, "doc\\eameqp\\",true);
                    }}
                >
                    <Icons Icon={"visible"} info={"primary"} />
                </Grid>
                <Grid
                    item
                    xs={1}
                    className="pointer"
                    onClick={() => {
                        download(serverFileName, "doc\\eameqp\\",false);
                    }}
                >
                    {/* {link === "DOC" ? (
                <Icons Icon={"visible"} info={"primary"} />
            ) : (
                )} */}
                    <Icons Icon={"download"} info={"primary"} />
                </Grid>
                <Grid item xs={1}  className="pointer"
                    onClick={()=>{
                        if(userReadOnlyAccess){

                            handleDelete()
                        }
                        }}>
                    <Icons Icon={"delete"} info={""} />
                </Grid>
            </Grid>
        </Paper>
    );
};

const mapStateToProps = (state: any) => {
    return {
        defaultContext: state?.defaultContext,
        currentContext: state.context?.userDefaults,
        sessionId: state.sessionId,
        supplierCode: state?.selectedVendor?.supplierCode,
        userReadOnlyAccess: state?.userReadOnlyAccess,
    };
};

export default connect(mapStateToProps)(DocumentCardsCert);
