import React, { ReactElement } from "react";
import Popover from "@material-ui/core/Popover";

interface PopoversProps {
  open: boolean;
  anchorEl: HTMLElement | null;
  Child: ReactElement;
  handleClose ?: () => void
}

const Popovers = (props: PopoversProps) => {
  const { open, anchorEl, Child } = props;
  return (
    <Popover
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={open}
      anchorEl={anchorEl}
    >
      {Child}
    </Popover>
  );
};

export default Popovers;
