const CompanyUsersVenQuery = `
mutation(
$entity: String
$ou: Int
$service: String
$User: String
$languageId: Int
$vendorCode: String
$vendorName: String
$merrorid: String
){
CompanyUsersVen(
entity: $entity
ou: $ou
service: $service
User: $User
languageId: $languageId
vendorCode: $vendorCode
vendorName: $vendorName
merrorid: $merrorid
){   
Name
Email
isActive
MakePayment
OrderSupplies
Admin
MobilePh
Businessph
Username
ReadOnlyAccess
}
}
`;

export default CompanyUsersVenQuery;
